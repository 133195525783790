import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import {Button} from '../../../../Common/Button/index'
import { FetchResource } from "../../../../Common/Search/FetchResource";
import { FetchSubResource } from "../../../../Common/Search/FetchSubResource";
import { showToast } from "../../../../Common/Toast";
import { post } from "../../../../../Model/Network/Config/Axios";
import { useRef,useState } from "react";
import { MainModal } from "../../../../NCommon/Modal";


const Index = (props) => {

    let [type,setType] = useState(undefined);
    let [buttonLoading,setButtonLoading] = useState(false);
    let TITLE=useRef(),DESC=useRef(),GLCODE=useRef(),RESOURCE=useRef(),SUBRESOURCE=useRef();


    return(
     <MainModal
        show={true}
        title="Add Resource"
        desc="Fill up the details and add resource"
        close={props.close}
        open={props.open}
        >

        <FunctionalInput ref={TITLE} title="Name" type="text" />
        <FunctionalInput ref={DESC} title="Description" type="text" />
        <FetchResource title="Resource Type" handleRef={ref=>RESOURCE.current=ref} onChange={(type)=>{
            setType(type)
        }} />
        <FetchSubResource title="Sub Resource Type" type={type}  handleRef={ref=>SUBRESOURCE.current=ref} />
        <FunctionalInput ref={GLCODE} title="Glcode (optional)" placeholder="Enter glcode" type="text" />
        <Button text="Add Resource" loading={buttonLoading} style={{
            marginTop:30
        }} onClick={()=>{
            let name = TITLE.current.value;
            let description = DESC.current.value;
            let resourceType = type;
            let sub_resource_type = SUBRESOURCE.current.getValue() 
            let glcode = GLCODE.current.value;

            if(!name) {
                showToast({
                    type:'error',
                    message:'Please enter the name'
                })
                return
            }
            if(!description) {
                showToast({
                    type:'error',
                    message:"Please enter the description"
                })
                return;
            }
            if(!resourceType) {
                showToast({
                    type:'error',
                    message:'Please select the resource type'
                })
                return
            }
            if(!sub_resource_type) {
                showToast({
                    type:'error',
                    message:"Please select the sub resource type"
                })
                return
            }
            let data = {
                glcode,
                sub_resource_type,
                resourceType,
                description,
                name
            }
            setButtonLoading(true);
            post(`resource/addCategory`,data,(e,r)=>{
                if(r) {
                    showToast({
                        type:'success',
                        message:'Resource Category added successfully'
                    })
                    setButtonLoading(false);
                    props.INIT();
                } else {
                    showToast({
                        type:'error',
                        message:e.data.message
                    })
                }
            })
        }} />
    </MainModal>
    )
}

export default Index;