import { Button } from "../../../../Common/Button";
import { BottomPopUp } from "../../../../NCommon/BottomPopup";


const Index = ({selected}) => {



    return(
        <BottomPopUp show={selected.length}>
            <Button text={`Selected`} />
        </BottomPopUp>
    )
}

export default Index;