import Props from 'prop-types';
import { Portal } from '../Portal';
import { cross } from '../Images';
import './index.css';

const RightModal =(props) => {


    let { show, title } = props;

    let styleHalfModal = {};
    if(props.size==='M') {
        styleHalfModal ={
            width:'50%'
        }
    }
    

    if(!show) {
        return <></>
    }

    return (
            <Portal>
                <div className={`half-modal-background`}>
                    <div className={`half-modal`} style={{...styleHalfModal}}>
                        <div style={{
                            height: 55,
                            borderBottom: '1px solid var(--border-color)',
                            padding: '10px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <div>

                                <h2 style={{
                                    fontWeight: 700,
                                    color: 'var(--text-color)',
                                    fontSize: 20
                                }}>{title}</h2>
                               
                            </div>

                            <div className="cross-hover" style={{
                                height: 36,
                                width: 36,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                position:'absolute',
                                left:-50,
                                top:10,
                                backgroundColor:"#fff",
                                borderRadius:'50%'
                            }} onClick={props.onClose} >
                                <img src={cross} alt="" style={{
                                    height: 14,
                                    width: 14,
                                    cursor: 'pointer',
                                    objectFit: 'contain'
                                }} />
                            </div>
                        </div>
                        <div style={{
                            flex: 1,
                            height: '95vh',
                            overflowY: 'auto',
                            paddingBottom:100
                        }}>
                            <div style={{
                                width:'90%',
                                justifyContent: "center",
                                paddingTop: 20,
                                flex: 1,
                                margin: '0px auto',
                                flexDirection: 'column'
                            }}>
                                {props.children}
                            </div>
                        </div>

                    </div>
                </div>
            </Portal>
        )
}


RightModal.defaultProps = {
    show: undefined,
    size:'S'
}



RightModal.propsTypes = {
    show: Props.oneOfType([Props.bool, Props.string, Props.object, Props.array]),
    isShow: false
}



export { RightModal }