



const CircleTracker = ({percentage}) => {


    if(percentage === 'NaN') {
        percentage = 0;
    }

    return(
        <div className="flex flex-v-center flex-h-center" style={{height:45,width:45,position:'relative',background:'var(--info-light-color)',borderRadius:'50%'}}>
            <div className="flex flex-v-center flex-h-center" style={{height:35,width:35,position:'relative',fontWeight:'700',borderRadius:'50%',background:'#fff',fontSize:11}}>
                    {percentage} %
            </div>
           
        </div>
    )
}

export {CircleTracker}