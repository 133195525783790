import { Route, Switch } from 'react-router-dom';
import Project from './Project';
import ProjectMapping from './ProjectMapping';
import ProjectWbsItem from './ProjectWbsItem';
import SnagTask from './SnagTask/index1';
import ProjectTaskData from './ProjectTaskData';
import Setting from './Setting';
import Adhoc from './Adhoc';
import Images from './Images';
import Drawing from './Drawing';
import Inventory from './Inventory';
import Resource from './Resource';
import Graph from './Analytics';
import Calendar from './Calendar';
import Attendance from './Attendance';
import Procurement from './Procurement';
import FullKitting from './FullKitting/index';
import Hindrance from './Hinderance'

const Index = () => {

        return (
            <>
                <Switch>
                    <Route path="/app/project" component={Project} exact />
                    <Route path="/app/project/fullkitting/:id" component={FullKitting}  />
                    <Route path="/app/project/hindrance/:id" component={Hindrance} />
                    <Route path="/app/project/mapping/:id" component={ProjectMapping} exact />
                    <Route path="/app/project/setting/:id" component={Setting}  />
                    <Route path="/app/project/data/:id" component={ProjectTaskData} exact />
                    <Route path="/app/project/wbs/:id" component={ProjectWbsItem} exact />
                    <Route path="/app/project/snag/:id" component={SnagTask} exact />
                    <Route path="/app/project/adhoc/:id" component={Adhoc} exact />
                    <Route path="/app/project/images/:id" component={Images}  />
                    <Route path="/app/project/drawing/:id" component={Drawing} />
                    <Route path="/app/project/inventory/:id" component={Inventory} />
                    <Route path="/app/project/resource/:id" component={Resource} />
                    <Route path="/app/project/analytics/:id" component={Graph}/>
                    <Route path="/app/project/holiday/:id" component={Calendar} />
                    <Route path="/app/project/attendance/:id" component={Attendance} />
                    <Route path="/app/project/procurement/:id" component={Procurement} />
                </Switch>
            </>
        )
}

export default Index;


