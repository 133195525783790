import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';



class SearchAdhocTask extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            availableTasks: [],
            value: '',
            suggestion: []
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }


    onSearch = (val) => {

          if (val.length > 0) {
            get(`/customAdhoc/search`, (e, r) => {
                if (r) {
                    this.setState({
                        ...this.state,
                        suggestion: r.adhocTasks
                    })
                }
    
            },{
                q:val
            })
           
        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }

    }


    getValue = () => {
        return this.state.value;
    }


    render() {
        return (
            <div style={{
                position: 'relative'
            }}>
                <FloatInput
                    title={this.props.title}
                    type="text"
                    handleRef={ref => this.attribute = ref}
                    onChange={(val) => {
                        this.onSearch(val)
                    }}
                />
                {
                    this.state.suggestion.length > 0 &&
                    <div style={{
                        position: 'absolute',
                        top: 75,
                        border: '1px solid var(--border-color)',
                        height: 200,
                        backgroundColor: '#fff',
                        width: '100%',
                        backgroundColor: "#fff",
                        zIndex: 1,
                        overflowY: 'scroll'

                    }}>
                        {this.state.suggestion.map((item, index) => (
                            <div key={index} style={{
                                height: 70,
                                borderBottom: '1px solid var(--border-color)',
                                cursor: 'pointer',
                                display: 'flex',
                                paddingLeft: 10,
                                fontSize: 14,
                                backgroundColor: '#fff',
                                flexDirection:'column',
                                justifyContent:'center'
                            }} onClick={() => {
                                if(this.props.onChange) {
                                    this.props.onChange(item)
                                }
                                this.setState({
                                    ...this.state,
                                    suggestion: [],
                                    value: item
                                })
                                this.attribute.setValue(`${item.name}`);
                            }}>
                                <p style={{fontSize:15}}>{item.name}</p>
                                <p style={{fontSize:13,color:'var(--text-light-color)'}}>lagDays : {item.lagDays}</p>
                                <p style={{fontSize:13,color:'var(--text-light-color)'}}>Required Days {item.requiredDays} </p>

                            </div>
                        ))}

                    </div>
                }
            </div>
        )
    }
}

export { SearchAdhocTask };