import { useRef, useState } from 'react';
import { post } from '../../../../Model/Network/Config/Axios';
import { Button } from '../../../Common/Button';
import { FunctionalInput } from '../../../Common/FloatInput/FunctionalInput';
import { RightModal } from '../../../Common/Modal/RightModal';
import { showToast } from '../../../Common/Toast';



const Index = (props) => {
    
    let [buttonLoading,setButtonLoading] = useState(false);
    let NAME=useRef(),DESC=useRef(),LAGDAY=useRef(),REQUIRED=useRef();

    return(
        <RightModal
            title="Add"
            desc="Fill up the details and add adhoc category"
            show={true}
            onClose={props.onClose}
        >
            <FunctionalInput title="Name" ref={NAME} type="text" />
            <FunctionalInput title="Description" ref={DESC} type="text" />
            <FunctionalInput title="Lag Day" ref={LAGDAY} type="text" />
            <FunctionalInput title="Required Days" ref={REQUIRED} type="text" />

            <Button
                text="Add"
                style={{
                    marginTop:30
                }}
                loading={buttonLoading}
                onClick={()=>{
                    let name = NAME.current.value;
                    let description = DESC.current.value;
                    let lagDays = LAGDAY.current.value;
                    let requiredDays = REQUIRED.current.value;

                    if(!name) {
                        showToast({
                            type:'error',
                            message:'Please enter the name'
                        })
                        return;
                    }
                    if(!description) {
                        showToast({
                            type:'error',
                            message:'Please enter the description'
                        })
                        return;
                    }
                    if(!lagDays) {
                        showToast({
                            type:'error',
                            message:'Please enter the lag day'
                        })
                        return
                    }
                    if(!requiredDays) {
                        showToast({
                            type:'error',
                            message:'Please enter the required days'
                        })
                        return
                    }

                    let data = {
                        name,
                        description,
                        lagDays:+lagDays,
                        requiredDays:+requiredDays
                    }
                    setButtonLoading(true);
                    post(`customAdhoc/create`,data,(e,r)=>{
                        if(r) {
                            setButtonLoading(false);
                            showToast({
                                type:'success',
                                message:'Adhoc task created successfully'
                            })
                            props.init();
                        }
                    })
                }}
            />
            
        </RightModal>
    )
}

export default Index;