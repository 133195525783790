
import {useLocation,Switch,Route,useParams} from 'react-router-dom';
import Inventory from './Inventory';
import { Header } from '../../../NCommon/Header';
import Store from './Store';
import Pending from './Pending';
import Past from './Past';
import Shuttering from './Shuttering';

const Index = () => {

    let route = useLocation().pathname;
    let {id} =useParams();

    return(
        <>
            <Header items={[
                  {
                    name: 'Inventory',
                    link: `/app/project/inventory/${id}`,
                    active: route === `/app/project/inventory/${id}`
                  },
                  {
                    name:'Store',
                    link:`/app/project/inventory/${id}/store`,
                    active: route === `/app/project/inventory/${id}/store`
                  },
                  {
                    name:'Shuttering',
                    link:`/app/project/inventory/${id}/shuttering`,
                    active: route === `/app/project/inventory/${id}/shuttering`
                  },
                  {
                    name:'Pending Request',
                    link:`/app/project/inventory/${id}/pending`,
                    active: route === `/app/project/inventory/${id}/pending`
                  },
                  {
                    name:'Past Request',
                    link:`/app/project/inventory/${id}/past`,
                    active: route === `/app/project/inventory/${id}/past`
                  }
                ]} />
             <Switch>
                <Route path="/app/project/inventory/:id" component={Inventory} exact />
                <Route path="/app/project/inventory/:id/store" component={Store} />
                <Route path="/app/project/inventory/:id/shuttering" component={Shuttering} />
                <Route path="/app/project/inventory/:id/pending" component={Pending} />
                <Route path="/app/project/inventory/:id/past" component={Past} />
             </Switch>
       </>
    )
}

export default Index;