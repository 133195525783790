import { useState } from 'react';
import {useParams} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Moment from 'moment';
import TaskOpen from './TaskOpen';
import { Link } from 'react-router-dom';
import TaskModal from './TaskModal';
import { useAccess } from '../../Hooks/ReadWriteConfig';

const TaskList = (props) => {


    const [taskModal,setTaskModal] = useState(false);
    let {headerFilter} =props;
    const roleAccess = useAccess();
    let {id} = useParams();


    let mapItem = () => {
        let { item, active ,isDelete} = props;
        let wbs = null;
        wbs = item.wbsItemTaskName.split('.');
        wbs = wbs[2] + "." + wbs[3];

        let predecessor = null;
        if (item.predecessorName) {
            predecessor = item.predecessorCroppedName;
        }
        let details = (
            <div className="table-item" style={{
                minHeight: 70,
                ...isDelete?{
                    backgroundColor:'rgba(245,247,250,0.9)',
                }:{

                }
            }}>
                <ReactTooltip />
                <div style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    height: 70
                }} onClick={() => {
                    props.onTaskOpen();
                }} >
                    <div style={{
                        width: 400
                    }}>
                        <div style={{
                            display: 'flex',
                            ...item.replicatedOtherWbsItem ?
                                {

                                } :
                                {
                                    alignItems: 'center',
                                }

                        }}>
                            <div style={{
                                        height: 14,
                                        width: 14,
                                        borderRadius:2,
                                        border: '1px solid var(--border-color)',
                                        marginRight:5,
                                        ...isDelete ?
                                            {
                                                backgroundColor: 'var(--active-color)',
                                                border: '1px solid var(--active-color)'
                                            } :
                                            {

                                            }
                                    }} onClick={(e) => {
                                        e.stopPropagation();
                                        props.onDeleteSelected()
                                    }}>
                                        <p style={{
                                            visibility: 'hidden'
                                        }}>hi</p>
                            </div>
                           
                            {
                                item.replicatedOtherWbsItem ?
                                    <div style={{
                                        height: 15,
                                        width: 15,
                                        border: '1px solid var(--border-color)',
                                        marginTop: 2,
                                        ...active ?
                                            {
                                                backgroundColor: 'var(--active-color)',
                                                border: '1px solid var(--active-color)'
                                            } :
                                            {

                                            }
                                    }} onClick={(e) => {
                                        e.stopPropagation();
                                        props.onSelect()
                                    }}>
                                        <p style={{
                                            visibility: 'hidden'
                                        }}>hi</p>
                                    </div>
                                    :
                                    <div style={{
                                        width: 20,
                                        marginTop: -2
                                    }}>
                                        <p
                                            data-tip={item.isCriticalTask ? 'Criticial Task' : 'Regular Task'}

                                            style={{
                                                width: 12,
                                                height: 12,
                                                borderRadius: 32,
                                                ...item.isCriticalTask ?
                                                    {
                                                        backgroundColor: 'var(--red-color)'
                                                    } : {
                                                        backgroundColor: 'var(--success-color)'
                                                    }
                                            }} />
                                    </div>
                            }
                            <div style={{
                                marginLeft: 10
                            }}>
                                <p style={{
                                    fontWeight: '600',
                                    fontSize: 14,
                                    color: 'var(--text-color)',
                                    width: 380
                                }} className="hide-text">
                                    {item.croppedName ? item.croppedName : wbs}
                                </p>
                                <p style={{
                                    fontSize: 13,
                                    color: 'var(--text-light-color)',
                                    marginTop: 2,

                                }}>
                                    Predecessor Relation : {item.predecessorRelation}
                                </p>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    {
                                        item.predecessorName ?
                                            <Link style={{
                                                fontWeight: '500',
                                                fontSize: 12,
                                                marginTop: 5,
                                                width: 380,
                                                textDecoration: 'none',
                                                color: 'var(--hover-button-color)'
                                            }} to={`/app/project/task/${id}/${item.predecessorId}/details`} target="_blank" className="hide-text">
                                                Predecessor : {predecessor}
                                            </Link> :
                                            <p style={{
                                                fontSize: 12,
                                                fontWeight: '500',
                                                color: 'var(--text-light-color)',
                                                marginTop: 5,
                                                width: 380,
                                            }}>Predecessor : NA</p>
                                    }

                                    {
                                        props.isPredecessorEdit && roleAccess ?

                                            <div
                                                className="penciledit"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    props.onChangePredecessor()
                                                }}
                                                style={{
                                                    marginLeft: '6px',
                                                    width: 10,
                                                    height: 10,
                                                    marginTop: 6,
                                                    marginLeft: 10,
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                    backgroundSize: 'cover',
                                                    top: -2
                                                }}
                                            />
                                            : null
                                    }


                                </div>

                            </div>

                        </div>

                    </div>

                    <div style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 13
                    }}>
                        {item.originalStartDate ? Moment(item.originalStartDate).format('DD-MM-YYYY') : '-'}
                    </div>
                    <div style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 13

                    }}>
                        {item.originalCompletionDate ? Moment(item.originalCompletionDate).format('DD-MM-YYYY') : '-'}
                    </div>
                    {headerFilter.estimated_start_date ?
                    <div style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 13

                    }}>
                        {item.estimatedStartDate ? Moment(item.estimatedStartDate).format('DD-MM-YYYY') : '-'}
                    </div>:null}
                    {headerFilter.estimated_end_date ?
                    <div style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 13

                    }}>
                        {item.estimatedCompletionDate ? Moment(item.estimatedCompletionDate).format('DD-MM-YYYY') : '-'}
                    </div>:null}
                    {headerFilter.reponsible_person ?
                    <div style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 12,
                        color:'var(--active-color)'

                    }}>
                        {item.responsiblePerson ? item.responsiblePerson.name : 'NA'}
                    </div>:null
                    }
                     {
                        headerFilter.duration?
                        <div style={{width:100,textAlign:'center'}}>
                            {item.totalDaysRequired}
                        </div>:null
                    }
                    {headerFilter.status?
                    <div style={{
                        textAlign: 'center',
                        fontSize: 14,
                        width: 110
                    }}>
                        <div style={{
                            padding: "5px 10px",
                            margin: '0px 10px',
                            borderRadius: 4,
                            fontSize: 12
                        }}
                            className={item.workDone ? `task-success` : item.workStarted ? `task-progress` : `task-incomplete`}>
                            {item.workDone ? "Completed" : item.workStarted ? "Progress" : "Not Started"} 
                        </div>
                    </div>:null}
                   
                </div>
                <div className="table-right" style={{
                    width: 100
                }}>
                    <p className="hoverable" onClick={() => {
                        setTaskModal(item);
                    }}>View</p>
                </div>
            </div >
        )

        if (item.id === props.taskOpen) {
            return (
                <TaskOpen item={item} id={item.id}>
                    {details}
                </TaskOpen>
            )
        }

        return details;
    }

    return (
            <>
                {mapItem()}
                {
                 taskModal ?
                 <TaskModal
                     details={taskModal}
                     projectId={props.projectId}
                     onClose={() => {
                         setTaskModal(false);
                     }}
                     init={props.init}
                 >

                 </TaskModal> : null   
                }
            </>
        )
}

TaskList.defaultProps = {
    isPredecessorEdit: true
}

export { TaskList }