import { useEffect, useRef,useState } from "react"
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput"
import { FetchResource } from "../../../../Common/Search/FetchResource"
import { FetchSubResource } from "../../../../Common/Search/FetchSubResource"
import {Button} from '../../../../Common/Button/index'
import { post } from "../../../../../Model/Network/Config/Axios"
import { RightModal } from "../../../../Common/Modal/RightModal"
import { showToast } from "../../../../Common/Toast"


const Edit = (props) => {

    let [buttonLoading,setButtonLoading] = useState(false);
    let [type,setType] = useState(undefined);
    let TITLE=useRef(),DESC=useRef(),GLCODE=useRef(),RESOURCE=useRef(),SUBRESOURCE=useRef();

    useEffect(()=>{
        if(props.details) {
            let {details} = props;
            TITLE.current.value = details.name;
            DESC.current.value = details.description;
            GLCODE.current.value = details.glCode;
            RESOURCE.current.setValue(details.resourceType);
            SUBRESOURCE.current.setValue(details.subResourceCategory)
            setType(details.resourceType);
        }
    },[])

    return(
        <RightModal
        show={true}
        title="Update Resource Template"
        desc="Edit up the details and update resource template"
        onClose={props.onClose}
         >

        <FunctionalInput ref={TITLE} title="Name" type="text" />
        <FunctionalInput ref={DESC} title="Description" type="text" />
        <FetchResource title="Resource Type" handleRef={ref=>RESOURCE.current=ref} onChange={(type)=>{
            setType(type)
        }} />
        <FetchSubResource title="Sub Resource Type" type={type}  handleRef={ref=>SUBRESOURCE.current=ref} />
        <FunctionalInput ref={GLCODE} title="Glcode (optional)" placeholder="Enter glcode" type="text" />

        <Button text="Update Resource" loading={buttonLoading} style={{
            marginTop:30
        }} onClick={()=>{
            let name = TITLE.current.value;
            let description = DESC.current.value;
            let resourceType = type;
            let sub_resource_type = SUBRESOURCE.current.getValue() 
            let glcode = GLCODE.current.value;

            if(!name) {
                showToast({
                    type:'error',
                    message:'Please enter the name'
                })
                return
            }
            if(!description) {
                showToast({
                    type:'error',
                    message:"Please enter the description"
                })
                return;
            }
            if(!resourceType) {
                showToast({
                    type:'error',
                    message:'Please select the resource type'
                })
                return
            }
            if(!sub_resource_type) {
                showToast({
                    type:'error',
                    message:"Please select the sub resource type"
                })
                return
            }
            let data = {
                glcode,
                sub_resource_type,
                resourceType,
                description,
                name,
                resourceCategoryId:props.details.id
            }
            setButtonLoading(true);
            post(`resource/updateCategory`,data,(e,r)=>{
                if(r) {
                    showToast({
                        type:'success',
                        message:'Resource Category updated successfully'
                    })
                    setButtonLoading(false);
                    props.onUpdate();
                }
            })
        }} />
        
    </RightModal>
    )
}

export default Edit;