import { useEffect, useState } from 'react';
import Moment from 'moment';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import AddProject from './AddProject';
import { PageLoader } from '../../../Common/PageLoader';
import { AccessButton } from '../../../Common/Button';
import { get } from '../../../../Model/Network/Config/Axios';
import {Header} from '../../../NCommon/Header/index'
import { PageWrapper } from '../../../NCommon/PageWrapper';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { Table } from '../../../NCommon/Tables';
import { Card } from '../../../NCommon/Ui/CardLayout';
import {List} from '../../../NCommon/Ui/List';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import { DownloadCsv } from '../../../../Utils/Downloader';
import { ShownFilter } from '../../../NCommon/Filter/ShownFilter';
import { CircleTracker } from '../../../NCommon/Tracker/Circle';
import { MainModal } from '../../../NCommon/Modal';

const AllProject = () => {

    const [loading,setLoading] = useState(true);
    const [addProject,setAddProject] = useState(false);
    const [projects,setProjects] = useState([]);
    const [suggestion,setSuggestion] = useState({});
    const [filters,setFilters] = useState([]);
    let HISTORY = useHistory();


    useEffect(()=>{
       INIT()
    },[])

    let INIT = () => {
        get(`/project/all`, (e, r) => {
            if (r) {
                let obj = {
                    Name:r.projects.map(val=>({first:val.name}))
                };
                setSuggestion(obj);
                setProjects(r.projects);
                setLoading(false);
            }
        })
    }

    let mapCardLayout = (item,index) => {

        const days = Moment(item.estimatedFinishDate).diff(item.originalFinishDate,'days');
        let badgeColor =  'var(--success-color)';
        if(days>0) {
            badgeColor = 'var(--red-color)'
        }

        return(
            <Card 
                 onClick={()=>{
                    HISTORY.push(`/app/project/mapping/${item.projectId}`)
                 }}
                 badge={days>0?`${days} day Delay`:``}
                 badgeColor={badgeColor}
                 key={index} 
                 title={item.name} 
                 height={180}>
                <div style={{marginTop:20,fontSize:11,fontWeight:'600'}}>
                    <span style={{background:'var(--background-color)',padding:'5px 10px',display:'inline-block',borderRadius:12}}>Planned Start Date : {Moment(item.startDate).format('DD-MM-YYYY')}</span><br></br>
                    <span style={{background:'var(--background-color)',padding:'5px 10px',marginTop:5,display:'inline-block',borderRadius:12}}>Planned Completion Date : {Moment(item.plannedFinishDate).format('DD-MM-YYYY')}</span><br></br>
                    <span style={{background:'var(--background-color)',padding:'5px 10px',marginTop:5,display:'inline-block',borderRadius:12}}>Estimated Completion Date : {Moment(item.estimatedFinishDate).format('DD-MM-YYYY')}</span><br></br>
                    <span style={{background:'var(--background-color)',padding:'5px 10px',marginTop:5,display:'inline-block',borderRadius:12}}>Original Completion Date : {Moment(item.originalFinishDate).format('DD-MM-YYYY')}</span><br></br>

                </div>
                <div style={{position:'absolute',right:20,top:20}}>
                    <CircleTracker percentage={((item.completedTasks)/(item.totalTasks)*100).toFixed(0)} />
                </div>
            </Card>
        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index} onClick={()=>{HISTORY.push(`/app/project/mapping/${item.projectId}`)}}>
                <div className="single-line" style={{flex:2}}>
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom single-line">{item.address}</p>
                </div>
                <div className="text-center flex flex-h-center" style={{width:100}}>
                    <CircleTracker percentage={((item.completedTasks)/(item.totalTasks)*100).toFixed(0)} />
                </div>
                <div className="text-center flex-1 table-text">
                    <p>{Moment(item.startDate).format('DD-MM-YYYY')}</p>
                </div>
                <div className="text-center flex-1 table-text">
                    <p>{Moment(item.plannedFinishDate).format('DD-MM-YYYY')}</p>
                </div>
                <div className="text-center flex-1 table-text">
                    <p>{Moment(item.estimatedFinishDate).format('DD-MM-YYYY')}</p>
                </div>
                <div className="text-center flex-1 table-text">
                    <p>{Moment(item.originalFinishDate).format('DD-MM-YYYY')}</p>
                </div>
            </List>
        )
    }


    let download = () => {

        let baseData = [[
            "Name",
            "Planned start Date",
            "Planned End Completion Date",
            "Estimated Completion Date"
        ]]

        projects.forEach(val=>{
            baseData.push([
                val.name,
                Moment(val.startDate).format('DD-MM-YYYY'),
                Moment(val.plannedFinishDate).format('DD-MM-YYYY'),
                Moment(val.estimatedFinishDate).format('DD-MM-YYYY'),
            ]
            )
        })
        DownloadCsv('Projects.csv',baseData);
    }

    let filterData  = (data) => {
        let filterValues = [...filters];
        let filterData = {};
        filterValues.forEach(f => {
            let items = filterData[f.type];
            items = items ? items : [];
            items.push(f.value)
            filterData[f.type] = items;
        })

        data =  data.filter(val=>{
            let NAMEFILTER = filterData["Name"];
            if(NAMEFILTER) {
                if(!_.includes(NAMEFILTER,val.name)) {
                    return false;
                }
            }
            return true;
        })
        return data;
    }

    return (
            <>
                <Header items={[]} title="All Project" />
                {
                    loading ?
                    <PageLoader />:
                    <>
                    <HeaderWrapper onFilterChange={setFilters} suggestion={suggestion} types={["Name"]} staticFilter={true} menuItem={[{name:'Download Csv',onClick:download}]} />
                    <ShownFilter onRemove={(val)=>{
                        let items = [...filters];
                        items = items.filter(item=>item.value !== val.value);
                        setFilters(items);
                    }} items={filters} />
                    <PageWrapper id="pagination-table-header" scroll={true}>
                        <NavigationWrapper items={[{name:`Project (${projects.length})`}]}>
                            <AccessButton onClick={()=>{setAddProject(true)}} addsign={true?1:0} inverted={true?1:0} text="Create Project" style={{padding:'0px 10px'}}/>
                        </NavigationWrapper>
                        <Table 
                            DATA={filterData(projects)}
                            mapCardLayout={mapCardLayout}
                            mapListLayout={mapListLayout}
                            headers={[
                                {
                                    name:'Project Details',
                                    flex:2
                                },
                                {
                                    name:'Progress',
                                    width:100,
                                    style:{textAlign:'center'}
                                },
                                {
                                    name:'Planned Start Date',
                                    flex:1,
                                    style:{textAlign:'center'}
                                },
                                {
                                    name:'Planned Completion Date',
                                    flex:1,
                                    style:{textAlign:'center'}
                                },
                                {
                                    name:'Estimated Completion Date',
                                    flex:1,
                                    style:{textAlign:'center'}
                                },
                                {
                                    name:'Original Completion Date',
                                    flex:1,
                                    style:{textAlign:'center'}
                                },
                              
                            ]}
                        />

                    </PageWrapper>
                    </>
                    
                }
                 <MainModal
                     open={addProject}
                     title="Create Project"
                     desc="Fill up the details and add project to organisation"
                     close={() => {
                         setAddProject(false);
                     }}
                 >
                     <AddProject
                         onComplete={() => {
                             setAddProject(false);
                             INIT();
                         }}
                     />
                 </MainModal>
            </>
        )
}

export default AllProject;