import {Route,Switch} from 'react-router-dom';
import AllVendor from './AllVendor';
import AddVendor  from './AddVendor';


const Index = () => {
    
    return(
        <Switch>
            <Route path="/app/vendor" component={AllVendor} exact />
            <Route path="/app/vendor/new" component={AddVendor} exact />
        </Switch>
    )
}

export default Index;