import { Carousel } from '../../Common/Carousel';


const Index = (details) => {

    let {checklists} = details.details;    
    return (
        <div>
            {Object.keys(checklists).map((val, index) => {
                return (
                    <div key={index} style={{
                        marginBottom: 20
                    }} >
                        <div style={{
                            fontWeight: '800',
                            fontSize: 17,
                            color: 'var(--text-color)',
                            textTransform: 'uppercase'
                        }}>
                            {val}
                        </div>
                        {
                            Object.keys(checklists[val]).map((i, ind) => {
                                let photos = [];
                                photos.push({url:checklists[val][i].photo});
                                checklists[val][i].photos.forEach(val=>{
                                    photos.push({url:val})
                                })
                                return (
                                    <div key={ind} style={{
                                        marginTop: 10,
                                    }}>
                                        <div style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: 'var(--light-color)',
                                            marginBottom: 10
                                        }}>{i}</div>
                                            
                                            <Carousel
                                                items={photos}
                                                showDetails={false}
                                            />
                                        <p style={{
                                            marginTop: 10,
                                            color: 'var(--text-color)',
                                            fontSize: 14
                                        }}>Description : {checklists[val][i].workDesc}</p>

                                    </div>
                                )
                            })
                        }

                    </div>
                )
            })}
        </div>
    )
}

export default Index;