import { useEffect, useRef,useState } from "react";
import { useParams} from 'react-router-dom';
import { get, post } from "../../../../../Model/Network/Config/Axios";
import {  Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { Modal } from "../../../../Common/Modal/Modal";
import { MainModal } from "../../../../NCommon/Modal"
import Select from 'react-select';
import { showToast } from "../../../../Common/Toast";




const Index = ({close,show,init}) => {

    let NAME = useRef(),VALUE=useRef(),KEYNAME=useRef();
    let {id} = useParams();
    let [items,setItems] = useState([]);
    let [basic,setBasic] = useState([]);
    let [modal,setModal] = useState(false);
    let [value,setValue] = useState(undefined);

    useEffect(()=>{
        INIT();
    },[])

    let INIT = () => {
        get(`project/export/taskExport/${id}/keys.all`,(e,r)=>{
            if(r) {
                setItems(r.keys.map(val=>({
                    key:val.key,
                    value:val.value,
                    label:val.value
                })));
            }
        })

        get(`project/export/taskExport/${id}/keys.basic`,(e,r)=>{
            if(r) {
                setBasic(r.basic.map(val=>({
                    key:val.key,
                    value:val.value,
                    label:val.value
                })));
            }
        })
    }

    let mapItem = (item,index) => {
        return(
            <div key={index} style={{display:'flex',width:400,border:'1px solid var(--border-color)',fontWeight:'600',height:35,borderRadius:4,marginBottom:10}}>
                <div style={{width:175,height:'inherit',borderRight:'1px solid var(--border-color)',display:'flex',alignItems:'center',paddingLeft:10,fontSize:14}}>
                    {item.label}
                </div>
                <div style={{width:175,height:'inherit',display:'flex',alignItems:'center',paddingLeft:10,fontSize:14}}>
                    {item.value}
                </div>
                <div onClick={()=>{
                    let nItems = [...items];
                    nItems = nItems.filter(val=>val.key!==item.key);
                    setItems(nItems);
                }} style={{fontSize:10,height:'inherit',color:'var(--red-color)',display:'flex',alignItems:'center',cursor:'pointer'}}>
                    Delete
                </div>
            </div>
        )
    }

    let addTemplate = () => {
        let name = NAME.current.value;
        if(!name) {
            showToast({
                type:'error',
                message:"Please enter the template name"
            })
            return;
        }
        
        post(`project/export/taskExport/${id}`,{name,filters:[],fields:items},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Template created Successfully"
                })
                init()
                close();
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }

    return(
    <>
        <MainModal
            title="Add Template"
            close={close}
            open={show}
        >
            <FunctionalInput title="Template Name"  ref={NAME}/>
            <p style={{fontSize:14,marginTop:10,fontWeight:600,marginBottom:20}}>Field Name</p>
            {items.map(mapItem)}
            <div onClick={()=>{setModal(true)}} style={{fontSize:14,marginTop:10,fontWeight:600,cursor:'pointer',color:'var(--theme-main-color)'}}>+ Add New Field</div>
            <Button onClick={addTemplate} text="Create" style={{marginTop:20,width:350}} />
        </MainModal>
        <Modal 
            show={modal}
            title="Add Field"
            desc="Add Field"
            onClose={()=>{
                setModal(false)
            }}
        >
            <p style={{fontSize:14,fontWeight:'600',marginBottom:5}}>Buildrun Key</p>
            <Select
                options={basic}
                isMulti={false}
                closeMenuOnSelect={true}
                onChange={setValue}
                ref={VALUE}
                />
            <FunctionalInput ref={KEYNAME} title="Name In Export Wizard" />
            <Button onClick={()=>{
                let key = value ?value.key:undefined;
                let val1 = KEYNAME.current.value;
                if(!key) {
                    showToast({
                        type:'error',
                        message:'Please select the key'
                    })
                    return;
                }
                if(!val1) {
                    showToast({
                        type:'error',
                        message:"Please enter the name"
                    })
                    return;
                }
                
                let nItems = items.filter(val=>val.key!==key);
                nItems.push({
                    key,
                    value:val1,
                    label:key
                })
                setItems(nItems);
                setModal(false);
            }} text="Add" style={{marginTop:20}} />
        </Modal>
    </>
    )
}


export default Index;
