import  { useEffect, useState } from 'react';
import { get } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import TaskApprovalTime from '../../../../Common/TaskItem/TaskApprovalTime';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';


const Index = (props) => {
    

    const [approvalDetails,setApprovalDetails] = useState([]);
    const [loading,setLoading] = useState(true);

    let getId = () => {
        return props.match.params.id;
    }

    useEffect(()=>{
        INIT()
    },[])

    let INIT = () => {
        get(`project/task/taskMappingApprovals/${getId()}`, (e, r) => {
            if (r) {
                setApprovalDetails(r.approvalDetails);
                setLoading(false);
            }
        })
    }

    if(loading) {
        return <PageLoader />
    }
    
    return (
        <PageWrapper>
            <NavigationWrapper items={[{name:'Approval Details'}]} />
            <div className="title-container-1"> 
                <TaskApprovalTime approvalDetails ={approvalDetails} />
            </div>
        </PageWrapper>
    )
    
}

export default Index;