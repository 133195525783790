import { useState } from "react";
import { FloatSelect } from "../../../../../Common/FloatInput/FloatSelect";
import { RightModal } from "../../../../../Common/Modal/RightModal"
import {  useDictionaryValue } from "../../../../../Hooks/DictionaryHooks"
import AddTask from './AddTask';
import CreateAdhocTask from './CreateAdhocTask';


const TaskParent = ({show,onClose,id,onComplete}) => {

    const getDictionaryValue = useDictionaryValue();
    const [type,setType] = useState('Regular');

    let onInit = () => {

        onComplete();
        setType('Regular');
    }

    return(
        <RightModal
            show={show}
            size="M"
            title={`Map ${getDictionaryValue('Task')} Item`}
            onClose={onClose}
        >
            <FloatSelect onChange={(value)=>{
                setType(value)}
            } title="Task Type" items = {[{label:'Regular',key:'REGULAR'},{label:'Adhoc',key:'ADHOC'}]} />
            {
                type === 'Regular' ?
                <AddTask id={id} onComplete={onInit}/>
                : <CreateAdhocTask id={id} onComplete={onInit} />
            }
        </RightModal>
    )
}

export {TaskParent}