import  { useRef, useState } from 'react';
import { Button } from '../../../Common/Button';
import { post } from '../../../../Model/Network/Config/Axios';
import { SearchWbsItem } from '../../../Common/Search/SearchWbsItem';
import { showToast } from '../../../Common/Toast';
import { ProjectCustomTag } from '../../../Common/CustomTagging/Project';
import { useDictionaryValue } from '../../../Hooks/DictionaryHooks';



const MapWbsItem = ({ onComplete, id }) => {


    const [buttonLoading,setButtonLoading] = useState(false);
    let wbsItem = useRef(),Custom=useRef();
    const dictionaryValue = useDictionaryValue();
    
    let _onAdd = () => {

        Custom.current.getValue(cb=>{

            let wbsItemId = wbsItem.current.getValue();
            if (!wbsItemId) {
                return;
            }
    
            let data = {
                wbsItemId: wbsItemId.id,
                projectId: Number(id),
            }
            if(cb && cb.length) {
                data.levelItemId = +cb[cb.length-1].levelId
            }
            setButtonLoading(true);
            post(`project/wbs/wbsItemMapping.create`, data, (e, r) => {
                if (r) {
                    showToast({
                        type:'success',
                        message:'Wbs Item map successfully'
                    })
                    onComplete();
                    setButtonLoading(false);
                } else {
                    showToast({
                        type:'error',
                        message:e.data.message
                    })
                    setButtonLoading(false);
                }
            })
        })
     
       
    }

    return (
        <>
            <ProjectCustomTag type="PROJECT" handleRef={ref=>Custom.current=ref} projectId={id}   />
            <SearchWbsItem
                title={`Search ${dictionaryValue('wbs')} Item`}
                handleRef={ref => wbsItem.current = ref}
            />
            <Button
                text="Add"
                style={{
                    marginTop: 35
                }}
                onClick={_onAdd}
                loading={buttonLoading}
            />
        </>
    )
}

export default MapWbsItem;