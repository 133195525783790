import { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import Moment from 'moment';
import {  post } from "../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../Common/PageLoader";
import ViewResource from './ViewResource';
import {  DownloadCsv, DownloadDirectCsv } from "../../../../../Utils/Downloader";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { useProject } from "../../../../Hooks/ProjectDetails";
import { useTableCount } from "../../../../Hooks/TableCount";
import { List } from '../../../../NCommon/Ui/List';
import { check } from "../../../../Common/Images";
import BulkDelete from './BulkDelete';
import { Upload } from "../../../../Common/Upload";
import { TaskDateFilter } from "../../../../NCommon/DateFilters/TaskDateFilter";



const Index = () => {


    const [loading,setLoading] = useState(true);
    const [show,setShow] = useState(false);
    const [filters,setFilters] = useState([]);
    const [upload,setUpload] = useState(false);
    const [dates,setDates] = useState(undefined);
    let [selectedTask,setSelectedTask] = useState([])
    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    let {id} = useParams();
    let details = useProject();
    let count = useTableCount();

    const [status,setStatus] = useState([
        {
            name: "Progress",
            active: false,
            color: "#ebb840",
            key:'PROGRESS'
          },
          {
            name: "Acquired",
            active: false,
            color: "#404959",
            key:'ACQUIRED'
          },
          {
            name: "Not Started",
            active: false,
            color: "#ff944d",
            key:'NOTSTARTED'
          },
          {
            name: "Completed",
            active: false,
            color: "#68d083",
            key:'COMPLETED'
          },
          {
            name: "Critical",
            active: false,
            color: "#ea3a3c",
            key:'CRITICAL'
          },
    ])

    const [resourcesType,setResourceType] = useState([
        {
            name: "Labour",
            active: false,
            color: "#ebb840",
            key:'LABOUR'
          },
          {
            name: "Material",
            active: false,
            color: "#404959",
            key:'MATERIAL'
          },
          {
            name: "Machines",
            active: false,
            color: "#ff944d",
            key:'MACHINES'
          },
    ])
    

    useEffect(()=>{
        INIT({page:0})
    },[count,status,resourcesType,dates])

    let INIT = (data) => {
        if(!data) {
            data={
                page:0
            }
        }
        data = {
            ...data
        }
        if(data.filter) {
            data.orFilters = data.filter;
            delete data.filter
        }
        
        let types = data.orFilters? [...data.orFilters]:[];
        resourcesType.forEach(val=>{
            if(val.active) {
                types.push({key:'RESOURCECATEGORYTYPE',val:val.key})
            }
        })

        data.size = `${count}`;
        data.offset = `${data.page*count}`;
        let otherNodes =[];
        status.forEach(val=>{
            if(val.active) {
              otherNodes.push({key:val.key,val:''})
            }
        })
        if(dates) {
            otherNodes = [...otherNodes,{key:'START',val:dates.startDate},{val:dates.endDate,key:'END'}]
        }

        data.andFilters=otherNodes;
        data.orFilters = types;
        post(`project/resourceView/fetchProjectResourceMappings/${id}`,data,(e,r)=>{
           if(r) {
             setLoading(false);
             setMData({
                 ...mData,
                 page:data.page,
                 total:r.total?r.total:mData.total,
                 items:r.resources
             })
           }
       })
    }

    let INITALLSELECTED = (data) => {
        if(!data) {
            data={
                page:0
            }
        }
        data = {
            ...data
        }
        if(data.filter) {
            data.orFilters = data.filter;
            delete data.filter
        }
        let otherNodes =[];
        status.forEach(val=>{
            if(val.active) {
              otherNodes.push({key:val.key,val:''})
            }
        })

        data.andFilters=otherNodes;
        post(`project/resourceView/fetchProjectResourceMappings/${id}`,data,(e,r)=>{
           if(r) {
               setSelectedTask(r.resources.map(val=>val.resourceMappingId))
           }
       })
    }


    let mapListLayout = (item,index) => {
        let active = _.includes(selectedTask,item.resourceMappingId)
        return(
            <List key={index} onClick={()=>{setShow(item)}} style={{height:75}}> 
              <div style={{width:25}} onClick={(e)=>{
                  e.stopPropagation();
                  let items = [...selectedTask];
                  if(_.includes(items,item.resourceMappingId)) {
                      items = items.filter(val=>val!==item.resourceMappingId)
                  } else {
                    items.push(item.resourceMappingId);
                  }
                  setSelectedTask(items);
                }}>
                    {active?
                    <img src={check} alt="" style={{height:18,width:18,marginTop:5}} />:
                    <div style={{height:16,width:16,border:'1px solid var(--border-color)'}} />
                    }
                </div>
                <div className="flex-2">
                    <p className="table-title">{item.wbsItemTaskName}</p>
                    <p className="table-bottom">{item.name}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.category}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.resourceType}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.subType}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{Moment(item.estimatedStartDate).format('DD-MM-YYYY')}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{Moment(item.estimatedEndDate).format('DD-MM-YYYY')}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.qty} {item.units}</p>
                </div>
            </List>
        )
    }

    let download = () => {
        post(`project/resourceView/resourceMappingCsvDump/${id}`,{},(e,r)=>{
            if(r) {
                DownloadDirectCsv("resouce.csv",r)
            }})
        
    }

    let next = ({page}) => {
        let items = [...filters];
        let filter = [];
        items.forEach(item=>{
            filter.push({
                key:item.type,
                val:item.type==='TASK'?`${item.id}`: item.type === 'WBS' ? `${item.id}`:item.value
              })
        })
        INIT({page,filter})
    }

    return(
        <>
            {
                loading ?
                <PageLoader />:
                <>
                     <HeaderWrapper 
                        filters={[...filters]} 
                        dynamicFilter={true} 
                        onFilterChange={items=>{
                            let filter = [];
                            items.forEach(item=>{
                                filter.push({
                                    key:item.type,
                                    val:item.type==='TASK'?`${item.id}`: item.type === 'WBS' ? `${item.id}`:item.value
                                  })
                            })
                            setFilters(items)
                            INIT({page:0,filter})
                        }} 
                        types={["Resource","Resource Category","Task","Wbs Mapping","Responsible Person","Approver","Custom Text"]} 
                        hideCard={true}
                        status={status}
                        onChangeStatusFilter={setStatus}
                        statusFilter={true}
                        otherStatus={{
                            name:"Category",
                            items:resourcesType
                        }}
                        setOtherStatus={setResourceType}
                        menuItem={[{name:'Download Csv',type:'downloadcsv',onClick:download},{name:'Upload Csv',type:'uploadcsv',onClick:()=>{setUpload(true)}}]} 
                     >
                        <TaskDateFilter onSelect={setDates} />

                     </HeaderWrapper>
                    <PageWrapper defaultView="list" id="pagination-table-header" scroll={true}>
                        <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${details.name}`,path:`/app/project/mapping/${id}`},{name:`Task Resource (${mData.total})`}]}/>
                        <PaginationTable
                              DATA={mData.items}
                              next={(page)=>{
                                next({page})
                              }}
                              mapCardLayout={()=>{}}
                              mapListLayout={mapListLayout}
                              total={mData.total}
                              page={mData.page}
                              headers={[
                                {
                                    name:'checkbox',
                                    width:25,
                                    active:selectedTask.length===mData.total,
                                    onClick:()=>{
                                        if(selectedTask.length===mData.total) {
                                            setSelectedTask([]);
                                        } else {
                                            let items = [...filters];
                                            let filter = [];
                                            items.forEach(item=>{
                                                filter.push({
                                                    key:item.type,
                                                    val:item.value
                                                  })
                                            })
                                            INITALLSELECTED({page:0,filter})

                                        }
                                    }
                                },
                               
                                  {
                                      name:'Details',
                                      flex:2
                                  },
                                  {
                                      name:'Category',
                                      flex:1
                                  },
                                  {
                                      name:'Resource Type',
                                      flex:1
                                  },
                                  {
                                    name:'Sub Type',
                                    flex:1
                                 },
                                  {
                                      name:'Est. Start Date',
                                      flex:1
                                  },
                                  {
                                      name:'Est. End Date',
                                      flex:1
                                  },
                                 
                                  {
                                      name:'Total Quantity',
                                      flex:1
                                  }
                              ]}
                              defaultView={'list'}
                        />
                    </PageWrapper>
                <BulkDelete selected={selectedTask} onComplete={()=>{next({page:0})}} />
                {
                show ?
                     <ViewResource 
                        resource={show}
                        onClose={()=>{
                                setShow(false);
                         }}
                        projectId={id}
                        />
                    : null      
                } 
           
                </>
            }

{
           upload?
            <Upload
            onClose={() => {
                setUpload(false)
            }}
            title="Upload"
            desc="Please upload your csv to create task resource"
            name="Select File"
            endPoint={`project/resourceView/uploadTaskResources/${id}`}
            fileName="resource.csv"
            islink={true}
            type="resource"
            onDownload={(data)=>{
                let baseData=[];
                baseData.push(["Wbs Task Name","Resource Name","Quantity","Error message"]);
                data.forEach(val=>{
                  baseData.push(
                    [
                      val.failedResources.wbstaskname,
                      val.failedResources.resourcename,
                      val.failedResources.qty,
                      val.errorMsg
                    ]
                  )
                })              
  
                DownloadCsv('error.csv',baseData)
              }}
            csvData={[
              [
                "wbstaskname",
                "category",
                "resourcetype",
                "subtype",
                "resource",
                "hsn",
                "units",
                "qty",
                "price",
                "role",
                "vendorcode",
                "empcode"
              ],
            ]}
            onComplete={() => {
                setUpload(false);
                INIT()
            }}
          />
         : null
                }
        </>
    )
}
export default Index;