import { useDictionary } from "../../Hooks/DictionaryHooks";


const data = [
    {
        type: 'title',
        name: 'Dashboard'
    },
    {
        name: 'Project',
        cname: 'project',
        module: 'PROJECTS',
        path: '/app/project',
        link: '/app/project',
        event:'project tab'
    },
    {
        name: 'Employees',
        cname: 'employee',
        module: 'ORGANIZATION',
        path: '/app/employee',
        link: '/app/employee',
        event:'employee tab'
    },
    {
        name: "WBS Template",
        cname: 'category',
        module: 'PROGRAMMES',
        path: '/app/wbs',
        link: '/app/wbs',
        event:'wbs tab'
    },
    {
        name: 'Task Template',
        cname: 'task',
        module: "PROGRAMMES",
        path: '/app/task',
        link: '/app/task',
        event:'task tab'
    },
    {
        name: 'Adhoc Template',
        cname: 'adhoc',
        module: "PROGRAMMES",
        path: '/app/adhoc',
        link: '/app/adhoc',
        event:'adhoc tab'
    },
    {
        type:'title',
        name:'other'
    },
    {
        name: 'Vendor',
        cname: 'vendor',
        module: "VENDOR",
        path: '/app/vendor',
        link: '/app/vendor',
        event:'vendor tab'
    },
    {
        name:'Drawing',
        cname:'adhoc',
        module:'VENDOR',
        path:'/app/others/drawing',
        link:'/app/others/drawing',
        event:'drawing tab'
    },
    {
        name:'Resource',
        cname:'resource',
        module:'VENDOR',
        path:'/app/others/resource',
        link:'/app/others/resource',
        event:'resource tab'
    },
    {
        name: 'Hinderance',
        cname: 'vendor',
        module: "VENDOR",
        path: '/app/others/hinderance',
        link: '/app/others/hinderance',
        event:'hinderance tab'
    },
   
    {
        name:'Access Management',
        cname:'access',
        module:'PROGRAMMES',
        path:'/app/others/access',
        link:'/app/others/access',
        event:'access management tab'
    },
    {
        name:'Dashboard Setting',
        cname:'setting',
        module:'PROJECTS',
        path:'/app/setting',
        link:'/app/setting',
        event:'dashboard setting tab'
    },
    {
        type: 'title',
        name: 'Settings'
    },
    {
        name: 'Logout',
        cname: 'logout',
        module: 'LOGOUT',
        path: '/app/logout',
        link: '/app/logout',
        event:'logout tab',
        onClick: () => {
            window.localStorage.clear()
            window.location.reload()
        }
    }
]

export default data;