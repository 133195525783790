import { forwardRef, useEffect, useImperativeHandle, useRef,useState } from "react";
import { get } from "../../../../../Model/Network/Config/Axios";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";



const StoreSearch = forwardRef((props,ref)=>{

    let INPUT = useRef();
    const [suggestion,setSuggestion] = useState([]);
    const [value,setValue] = useState(undefined);
    const [items,setItems] = useState([]);

    useEffect(()=>{
        get(`project/stores/allStores/${props.id}`,(e,r)=>{
            if(r) {
                setItems(r.stores)
            }
        })
    },[props.id])

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return value
        },
        setValue:(value)=>{
            setValue(value);
        }
    }))

    let search = (val)=>{
        let value = val.toLowerCase();
        let suggestList = items.filter(item=>{
            if(item.name.toLowerCase().includes(value)) {
                return true
            } else if(item.details.toLowerCase().includes(value)) {
                return true;
            }
            return false
        })
        setSuggestion(suggestList);
    }

    return(
        <div style={{position:'relative'}}>
            <FunctionalInput 
                title={props.title}
                placeholder={props.placeholder}
                ref={INPUT}
                onChange={search}
            />
            {suggestion.length>0?
              <div className="suggestion-modal" style={{top:68}}>
                  {suggestion.map((val,index)=>{
                      return(
                          <div key={index} className="suggestion-modal-item" onClick={()=>{
                              setSuggestion([]);
                              setValue(val);
                              INPUT.current.value = val.name;
                          }}>
                              {val.name} <br></br> {val.details}
                          </div>
                      )
                  })}
              </div>
              :null
            }
        </div>
    )
})

export {StoreSearch}