
const Layout = (props, style) => {

    return (
        <div style={{
            padding: 15,
            backgroundColor: 'var(--off-white)',
            ...style
        }}>
            {props.children}
        </div>
    )
}

export { Layout }