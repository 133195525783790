import { useEffect, useState } from "react";
import {useHistory} from 'react-router-dom';
import { AccessButton } from "../../../../Common/Button";
import { get } from "../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../Common/PageLoader";
import Edit from './Edit';
import { useTableCount } from "../../../../Hooks/TableCount";
import { Header } from "../../../../NCommon/Header";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import {PaginationTable} from '../../../../NCommon/Tables/PaginationTable'
import { Card } from "../../../../NCommon/Ui/CardLayout";
import { List } from "../../../../NCommon/Ui/List";
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";
import BulkUploadSubCategory from './BulkUploadSubCategory';
import AddCategory from './AddCategory';

const Index = () => {

    let [add,setAdd] = useState(false);
    let [loading,setLoading] = useState(true);
    let [edit,setEdit] = useState(false);
    const [bulk,setBulk] = useState(false);
    const [filters,setFilters] = useState([]);

    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    let HISTORY = useHistory();
    let count = useTableCount();

    useEffect(()=>{
        INIT({page:0})
    },[count])

    let INIT = (data) => {
        if(!data) {
            data = {
                page:0
            }
        }
        if(data.filter) {
            data = {
                ...data,
                ...data.filter
            }
            delete data.filter
        }

        data.size = `${count}`
        data.offset = (data.page*count).toString();
        get(`resource/allCategories`,(e,r)=>{
            if(r) {
                setMData({
                    ...mData,
                    page:data.page,
                    total:r.total?r.total:data.total,
                    items:r.resourcesCategories
                })
                setLoading(false)
            }
        },{...data})
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card   
                onClick={()=>{
                    HISTORY.push(`/app/others/resource/${item.id}/${item.resourceType}/${item.subResourceCategory}?name=${item.name}&subResourceType=${item.subResourceCategory}`)
                }}
                height={120}
                items={[{name:"Edit",onClick:(e)=>{e.stopPropagation();setEdit(item)}}]}
                key={index} title={item.name} desc={item.description}>

            </Card>
        )
    }

    
    let mapListLayout = (item,index) => {
        return(
            <List key={index} onClick={()=>{
                HISTORY.push(`/app/others/resource/${item.id}/${item.resourceType}/${item.subResourceCategory}?name=${item.name}&subResourceType=${item.subResourceCategory}`)
            }}>
                <div className="flex-1">
                    <div className="table-title">{item.name}</div>
                    <div className="table-bottom">{item.description}</div>
                </div>
                <div className="flex-1">
                    <div className="table-text">{item.resourceType}</div>
                </div>
                <div className="flex-1">
                    <div className="table-text">{item.subResourceCategory}</div>
                </div>
                <TableMenu items={[{name:"Edit",onClick:(e)=>{e.stopPropagation();setEdit(item)}}]}/>
            </List>
        )
    }

  
    return(
        <>
            <Header title="Resource Category" />
            {
                loading ?
                <PageLoader />
                :
                <>
                    <HeaderWrapper 
                        types={["Resource Category"]} 
                        dynamicFilter={true}
                        filters={[...filters]}
                        onFilterChange={items=>{
                           let filter = {};
                           items.forEach(item=>{
                               filter['name'] = item.label;
                           })
                           setFilters(items)
                           INIT({page:0,filter})
                       }}
                        />
                    <PageWrapper>
                        <NavigationWrapper items={[{name:`All Resource (${mData.items.length})`}]}>
                            <AccessButton onClick={()=>{setAdd(true)}} text="Add Category" inverted={true?1:0} addsign={true?1:0} />
                            <AccessButton onClick={()=>{setBulk(true)}} style={{marginLeft:10}} text="Bulk Upload" inverted={true?1:0}  />
                        </NavigationWrapper>
                        <PaginationTable
                                DATA={mData.items}
                                next={(page)=>{
                                    INIT({page})
                                }}
                                mapListLayout={mapListLayout}
                                mapCardLayout={mapCardLayout}
                                total={mData.total}
                                page={mData.page}
                                headers={[
                                    {
                                        name:'Details',
                                        flex:1
                                    },
                                    {
                                        name:'Resource Type',
                                        flex:1
                                    },
                                    {
                                        name:'Sub Category Type',
                                        flex:1
                                    },
                                    {
                                        name:'actions',
                                        width:50
                                    }
                                ]}
                                />
                    </PageWrapper>
                </>
            }
            <AddCategory open={add} close={()=>{setAdd(false)}} INIT={()=>{setAdd(false);INIT()}} />
              {
                bulk?
                    <BulkUploadSubCategory onClose={()=>{setBulk(false)}} />
                :null
            }
             {
                 edit ?
                 <Edit 
                    details={edit}
                    onClose={()=>{
                        setEdit(false)
                    }}
                    onUpdate={()=>{
                        setEdit(false);
                        INIT();
                    }}
                 />
                 : null
             }
        </>
    )
}

export default Index;