import React from 'react';
import { get, post } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';
import { Modal } from '../../Modal/Modal';
import { showToast } from '../../Toast';
import { SearchSelect } from '../SearchSelect/SearchSelect';
import { SearchTaskCategory } from '../SearchTaskCategory';
import  {Button} from '../../../Common/Button/index'


class SearchTaskN extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            tasks: [],
            suggestion: [],
            value:'',
            addTask:false
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
       
    }

    onSearch = (val) => {
        let data = {q:val};
        if(this.props.category) {
            data.category = this.props.category.name;
            data.type = this.props.type
        }
        if (val.length > 0) {
            get(`project/task/task.search`,(e,r)=>{
                if(r) {
                    this.setState({
                        ...this.state,
                        suggestion:r.tasks,
                        value:val,
                        name:undefined
                    })
                    
                }
            },data)

        } else {
            this.setState({
                ...this.state,
                suggestion: [],
                value:'',
                name:undefined
            })
        }
    }

    setValue =(name) => {
        this.setState({
            ...this.state,
            name:undefined
        })
    }

    getValue = () => {
        return this.state.name
    }

    addTask = () => {

       
        let taskNames = this.taskName.getValue();
        if (!taskNames) {
            showToast({
                type: 'error',
                message: 'Please enter the task name'
            })
            return
        }
        let taskDetail = this.taskDetails.getValue();
        if (!taskDetail) {
            showToast({
                type: 'error',
                message: 'Please enter the task details'
            })
            return;
        }



        let data = {
            taskCategoryId: +this.props.category.id,
            taskName: taskNames,
            taskDetails: taskDetail,
            taskType: this.props.type

        }

        post(`project/task/task.create`, data, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Task Item added successfully'
                })
               this.setState({
                   ...this.state,
                   addTask:false
               })

               this.onSearch(taskNames,true);
            }

            if (e) {
                showToast({
                    type: "error",
                    message: e.data.message
                })
            }
        })

    }

    
    render() {
        return (
            <div style={{
                flex: 1,
                position: 'relative',
                ...this.props.style
            }} >
                <FloatInput title={this.props.title} handleRef={ref => this.parent = ref} onChange={val => {
                    if(this.props.categorySearch && !this.props.category) {
                        showToast({
                            type:'error',
                            message:"Please search category first"
                        })
                        this.parent.setValue("");
                        return;
                    } else {
                        this.onSearch(val);
                    }
                }} />
                {
                    this.state.suggestion.length > 0 ?
                        <div className="suggestion-modal" style={{top:68}}>
                            {this.state.suggestion.map((item, index) => (
                                <div key={index} onClick={() => {
                                    if(this.props.onClick) {
                                        this.props.onClick(item)
                                    }
                                    this.setState({
                                        ...this.state,
                                        suggestion: [],
                                        name: item
                                    })
                                    this.parent.setValue(item.taskName);
                                }} style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}} className="suggestion-modal-item">
                                    <p>{item.taskName}</p>
                                    <p style={{fontSize:11,color:'var(--text-light-color)'}}>Category: {item.category}</p>
                                </div>
                                
                            ))}
                             <p onClick={()=>{
                                 this.setState({
                                    ...this.state,
                                    addTask:true
                                },()=>{
                                    this.taskName.setValue(this.parent.getValue())
                                })
                             }} style ={{
                                  color:'var(--active-color)',
                                  fontWeight:'600',
                                  cursor:'pointer',
                                  height:40,
                                  display:'flex',
                                  alignItems:'center',
                                  paddingLeft:10,
                                  position:'sticky',
                                  bottom:0,
                                  background:'#fff',
                                  borderTop:'1px solid var(--border-color)'
                                 }}><span style={{
                                     border:'1px solid var(--active-color)',
                                     height:20,
                                     width:20,
                                     borderRadius:'50%',
                                     display:'flex',
                                     justifyContent:'center',
                                     alignItems:'center',
                                     marginRight:10,
                                     fontSize:16
                                 }}>+</span>Add Above Task</p>

                        </div>
                        :
                        this.state.suggestion.length === 0 && !this.state.name &&  this.state.value.length> 0  ?
                        <div style={{
                            position: 'absolute',
                            top: 65,
                            border: '1px solid var(--border-color)',
                            height: 100,
                            backgroundColor: '#fff',
                            width: '100%',
                            zIndex: 1,
                            overflowY: 'scroll'
                        }}>
                            <p onClick={()=>{
                                this.setState({
                                    ...this.state,
                                    addTask:true
                                },()=>{
                                    this.taskName.setValue(this.parent.getValue())
                                })
                            }} style={{
                                padding:'5px 10px',
                                color:'var(--active-color)',
                                display:'flex',
                                alignItems:'center',
                                fontSize:14,
                                cursor:'pointer'
                            }}><span style={{
                                border:'1px solid var(--active-color)',
                                height:20,
                                width:20,
                                borderRadius:'50%',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                marginRight:10,
                                fontSize:16
                            }}>+</span> Add Task</p>
                        </div>:null
                }

                <Modal
                    title="Add Task"
                    desc="Add new task by selecting category"
                    onClose={()=>{
                        this.setState({
                            ...this.state,
                            addTask:false
                        })
                    }}
                    show={this.state.addTask}
                >
                    <FloatInput type="text" title="Task Name" handleRef={ref => this.taskName = ref} />
                    <FloatInput type="text" title="Task Details" handleRef={ref => this.taskDetails = ref} />
                    <Button onClick={this.addTask} text="Add" style={{marginTop:20}}/>

                     
                </Modal>

            </div >
        )
    }
}


export { SearchTaskN }