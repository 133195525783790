import  { useRef, useState } from 'react';
import { EmployeeSearch } from '../../../Common/Search/SearchEmployee';
import { SearchAdhocTask } from '../../../Common/Search/SearchAdhocTask';
import { Button } from '../../../Common/Button';
import { showToast } from '../../../Common/Toast';
import { post } from '../../../../Model/Network/Config/Axios';
import { RightModal } from '../../../Common/Modal/RightModal';
import { SimpleFlex } from '../../../Common/Flex/SimpleFlex';
import { FunctionalInput } from '../../../Common/FloatInput/FunctionalInput';


const Index = (props) => {

    const [buttonLoading,setButtonLoading] = useState(false);
    let Adhoc=useRef(),ResponsiblePerson=useRef(),ApprovePerson=useRef(),LagDays=useRef(),TotalRequiredDays=useRef();

    let add = () => {
        let type = Adhoc.current.getValue();
        let responsiblePerson = ResponsiblePerson.current.getValue();
        let approverPerson = ApprovePerson.current.getValue();
        let lagDay = LagDays.current.value;
        let totalRequiredDays = TotalRequiredDays.current.value;

        if (!type) {
            showToast({
                type: 'error',
                message: 'Please select the adhoc type'
            })
            return
        }
        if (!responsiblePerson) {
            showToast({
                type: 'error',
                message: 'Please select the responsible person'
            })
            return
        }
        if (!approverPerson) {
            showToast({
                type: 'error',
                message: 'Please select the approver person'
            })
            return
        }
        if(!lagDay) {
            showToast({
                type:'error',
                message:"Please enter the lagday"
            })
            return;
        }
        if(!totalRequiredDays) {
            showToast({
                type:'error',
                message:"Please enter the total required Days"
            })
            return
        }

        let data = {
            customAdhocTaskId: type.id,
            responsiblePerson: responsiblePerson.id,
            approverPerson: approverPerson.id,
            lagDays:+lagDay,
            totalRequiredDays:+totalRequiredDays
        }
        setButtonLoading(true);
        post(`project/addCustomAdhocTask/${props.projectId}`, data, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Adhoc task created successfully'
                })
                props.onComplete();
                setButtonLoading(false);
            } 
        })
    }

    return (
            <RightModal
                show={true}
                title="Add Adhoc Task"
                desc="Fill up the details and add Task"
                onClose={props.onClose}
            >
                
                <SearchAdhocTask
                    title="Search Adhoc Type"
                    handleRef={ref => Adhoc.current = ref}
                    projectId={props.projectId}
                    onChange={(item)=>{
                        LagDays.current.value = item.lagDays;
                        TotalRequiredDays.current.value = item.requiredDays
                    }}
                />
                <SimpleFlex>
                    <FunctionalInput type="text" ref={LagDays} title="Lag Day" style={{marginRight:20}} />
                    <FunctionalInput type="text" ref={TotalRequiredDays} title="Total Required days" />
                </SimpleFlex>
                <SimpleFlex>
                    <EmployeeSearch
                        title="Responsible Person"
                        placeholder="Search responsible person"
                        handleRef={ref => ResponsiblePerson.current = ref}
                        style={{marginRight:20}}
                        />
                    <EmployeeSearch
                        title="Approver"
                        placeholder="Search approver person"
                        handleRef={ref => ApprovePerson.current = ref}
                        />
                </SimpleFlex>

                <Button
                    style={{
                        marginTop: 30,
                        height: 40
                    }}
                    onClick={add}
                    text="Add"
                    loading={buttonLoading}

                />
            </RightModal>
        )
}

export default Index;