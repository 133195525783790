import { useRef, useState } from "react";
import {useParams} from 'react-router-dom';
import { post } from "../../../../../../Model/Network/Config/Axios";
import { DownloadCsv } from "../../../../../../Utils/Downloader";
import { Button } from "../../../../../Common/Button";
import { FunctionalInput } from "../../../../../Common/FloatInput/FunctionalInput";
import { deleteIcon } from "../../../../../Common/Images";
import { Modal } from "../../../../../Common/Modal/Modal";
import { showToast } from "../../../../../Common/Toast";
import TaskWithChanges from './TaskWithChanges';
import { BottomPopUp } from '../../../../../NCommon/BottomPopup';


const Index = ({onComplete,selected}) => {

    const [deleteModal,setDeleteModal] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [taskChanges,setTaskChanges] = useState([]);
    let {id} = useParams();
    let REASON = useRef();

    return(
      <>
        <BottomPopUp show={selected.length}>
          <div style={{marginLeft:20,display:'flex',alignItems:'center'}}>
            <div onClick={()=>setDeleteModal(true)} className="flex flex-v-center" style={{border:'1px solid var(--red-color)',justifyContent:'space-between',cursor:'pointer',padding:5,borderRadius:4}}>
                <img src={deleteIcon} alt="" style={{height:16,width:16}} />
                <p style={{fontSize:13,color:'var(--red-color)',paddingLeft:5,fontWeight:600}}>Delete All</p>
            </div>
            <p style={{marginLeft:20,fontSize:14,fontWeight:600}}>{selected.length} Item Selected</p>
          </div>
        </BottomPopUp>
        <Modal
          title="Delete Task"
          desc="Are you sure want to delete these task"
          show={deleteModal}
          onClose={()=>{setDeleteModal(false)}}
          style={{minHeight:200 }}
        >
            <FunctionalInput ref={REASON} title="Enter Reason" />
            <p style={{marginTop:10,fontSize:14}}>{selected.length} Item are selected</p>
            <Button 
                text="Delete" 
                loading={buttonLoading} 
                style={{marginTop:50}} 
                onClick={()=>{
                    let reason = REASON.current.value;
                    if(!reason) {
                        showToast({
                            type:'error',
                            message:"Please enter the reason"
                        })
                        return;
                    }
                    setButtonLoading(true);
                    post(`project/task/taskMapping.delete/multiple`,{
                            reason,
                            projectId:+id,
                            tasks:selected
                        },(e,r)=>{
                            if(r) {
                                setTaskChanges(r.tasksWithChanges)
                                onComplete();
                                setDeleteModal(false);
                                setButtonLoading(false);
                                if(r.errorMessages.length>0) {
                                    let baseData = [["list"]];
                                    r.errorMessages.forEach(val=>{
                                      baseData.push([
                                        val
                                      ])
                                    })
                                    DownloadCsv('error.csv',baseData)
                                  }
                            } else {
                                setButtonLoading(false)
                            }
                     })
            }} />
        </Modal>
        {
            taskChanges.length ?
            <TaskWithChanges 
                onClose={()=>{
                    setTaskChanges([]);
                }}
                onDelete={onComplete}
                items={taskChanges}
            />: null
        }
      </>
    )
}

export default Index;