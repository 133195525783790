import { MixPanel } from "../../Component/Hooks/Mixpanel"
import { get } from "../../Model/Network/Config/Axios"
import { AuthActions } from "../Reducers"

export function configController() {
    return function (dispatch) {
        get('auth/config', (e, r) => {
            if (r) {
                MixPanel.update({email:r.email,isSuper:r.isSuper,name:r.name,admin:r.admin,modules:r.modules})
                dispatch({
                    type: AuthActions.Config,
                    data: r
                })         
            }
        })
    }
}