import {Switch,Route} from 'react-router-dom';
import Ate from './Ate'

const Index = () => {


    return(
      <Switch>
         <Route path="/app/project/analytics/:id/custom/ate" component={Ate} />
       
      </Switch>
    )
}

export default Index;