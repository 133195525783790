import { useEffect, useRef, useState } from "react";
import { get, post,axios } from "../../../../../Model/Network/Config/Axios";
import { AccessButton ,Button} from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../../Common/Modal/RightModal";
import { PageLoader } from "../../../../Common/PageLoader";
import { showToast } from "../../../../Common/Toast";
import { BottomHeader } from "../../../../Common/TopHeader/BottomHeader";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";



const Index = () => {

    const [loading,setLoading] = useState(true);
    const [items,setItems] = useState([]);
    const [add,setAdd] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    const KEY=useRef(),VALUE=useRef();

    useEffect(()=>{
       INIT();
    },[])

    let INIT = () => {
        get(`setting/dictionary/getItems`,(e,r)=>{
            if(r) {
                setItems(r.items);
                setLoading(false);
            }
        })
    }

    let mapItem = (item,index) => {
        return(
            <div key={index} style={{width:600,display:'flex',height:40,border:'1px solid var(--border-color)',fontSize:14}}>
                <div className="flex flex-v-center flex-h-center" style={{width:200,borderRight:'1px solid var(--border-color)'}}>
                    {item}
                </div>
                <div className="flex flex-v-center flex-h-center" style={{width:200,borderRight:'1px solid var(--border-color)'}}>
                    {items[item]}
                </div>
                <div onClick={()=>{
                     axios.delete(`setting/dictionary/removeItem?name=${item}`, {
                    })
                        .then(() => {
                            INIT();
                            showToast({
                                type: 'success',
                                message: "Key Remove successfully"
                            })
                        })

                
                }} className="flex flex-v-center flex-h-center" style={{color:'var(--red-color)',cursor:'pointer',width:200,fontWeight:600}}>
                      Delete
                </div>
            </div>
        )
    }


    if(loading) {
        return <PageLoader />
    }

    return(
        <div>
            <HeaderWrapper showFilter={false} />
            <PageWrapper>
                <NavigationWrapper items={[{name:'All Setting',path:'/app/setting'},{name:"Dictionary"}]}>
                    <AccessButton inverted={true} text="Add" onClick={()=>{setAdd(true)}} />
                </NavigationWrapper>
                <div className="title-container-1">
                <div style={{
                    width:600,
                    height:40,
                    display:'flex',
                    background:'var(--background-color)'
                }}>
                    <div className="flex flex-v-center flex-h-center" style={{width:200,fontWeight:600,height:'inherit',borderRight:'1px solid var(--border-color)'}}>
                        Key
                    </div>
                    <div className="flex flex-v-center flex-h-center" style={{width:200,fontWeight:600,height:'inherit',borderRight:'1px solid var(--border-color)'}}>
                        Value
                    </div>
                    <div className="flex flex-v-center flex-h-center" style={{width:200,height:'inherit'}}>

                    </div>
                </div>
                <>
                    {Object.keys(items).map(mapItem)}
                </>
            </div>
            </PageWrapper>

        

            {
                add ?
                <RightModal onClose={()=>{setAdd(false)}} show={add} title="Add the key value pair" desc="Fill up the details">
                    <FunctionalInput title="Enter the key" ref={KEY} />
                    <FunctionalInput title="Enter the value" ref={VALUE} />
                    <Button text="Add" style={{marginTop:20}} loading={buttonLoading} onClick={()=>{
                        let key = KEY.current.value;
                        let value = VALUE.current.value;

                        if(!key) {
                            showToast({
                                type:'error',
                                message:'Please enter the key'
                            })
                            return;
                        }

                        if(!value) {
                            showToast({
                                type:'error',
                                message:"Please enter the value"
                            })
                            return;
                        }

                        let data = {
                            name:key,
                            value
                        }
                        setButtonLoading(true);
                        post(`setting/dictionary/addItem`,data,(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"Key value pair added successfully"
                                })
                                INIT();
                                setButtonLoading(false);
                                setAdd(false);

                            } else {
                                setButtonLoading(false);
                            }
                        })
                    }} />
                </RightModal>
                : null
            }
        </div>
    )
}

export default Index;