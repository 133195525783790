import  { useRef, useState } from 'react';
import Moment from 'moment';
import { EmployeeSearch } from '../../../../../Common/Search/SearchEmployee';
import { SearchWbsMappingItemN } from '../../../../../Common/Search/SearchWbsMappingItemN';
import { Button } from '../../../../../Common/Button';
import { Attachment } from '../../../../../Common/Attachment';
import { showToast } from '../../../../../Common/Toast';
import { SimpleFlex } from '../../../../../Common/Flex/SimpleFlex';
import { FunctionalInput } from '../../../../../Common/FloatInput/FunctionalInput';
import { ToggleButton } from '../../../../../Common/Toggle';
import { SearchSelect } from '../../../../../Common/Search/SearchSelect/SearchSelect';
import { WeekDays } from '../../../../../Common/DateRangePicker/WeekDays';
import { DaysList } from '../../../../../Common/DateRangePicker/DaysList';
import { MonthList } from '../../../../../Common/DateRangePicker/MonthList';
import { post } from '../../../../../../Model/Network/Config/Axios';
import { useDictionaryValue } from '../../../../../Hooks/DictionaryHooks';
import { SearchTaskCategory } from '../../../../../Common/Search/SearchTaskCategory';
import { SearchTaskN } from '../../../../../Common/Search/SearchTaskN';


const Index = (props) => {

    const [buttonLoading,setButtonLoading] = useState(false);
    const [category,setCategory] = useState(undefined);
    const [recurring,setRecurring] = useState(false);
    const [type,setType] = useState(undefined);
    const [weekValue,setWeekValue] = useState(undefined);
    const [dayValue,setDayValue] = useState(undefined);
    const [monthValue,setMonthValue] = useState(undefined);
    let getDictionaryValue = useDictionaryValue();
    let Task=useRef(),MappedWbsItem=useRef(),Person=useRef(),ApproverPerson=useRef(),TotalDays=useRef(),TotalLabour=useRef(),PlannedStartDate=useRef(),Attachments=useRef(),AdhocRemarks=useRef()

    let _onAdd = () => {
        let taskIds = Task.current.getValue();
        let wbsItemMappingId = MappedWbsItem.current.getValue();
        let responsiblePerson = Person.current.getValue();
        let approver = ApproverPerson.current.getValue();
        let totalDays = +TotalDays.current.value;
        let totalLabourRequired = +TotalLabour.current.value;
        let plannedStartDate = PlannedStartDate.current.value;
        if (plannedStartDate) {
            plannedStartDate = Moment(plannedStartDate, 'YYYY-MM-DD');
            plannedStartDate = Moment(plannedStartDate).valueOf();
        }
        if (!taskIds) {
            showToast({
                type: 'error',
                message: 'Please enter the task'
            })
            return null;
        }
        if (!wbsItemMappingId) {
            showToast({
                type: 'error',
                message: 'Please mapped the wbs item'
            })
            return;
        }
      
        if(!totalDays) {
            showToast({
                type:'error',
                message:'Please enter the total days'
            })
            return
        }
        if(!plannedStartDate) {
            showToast({
                type:'error',
                message:"Please select the planned start date"
            })
            return;
        }
       
        let data = {
            projectId:+props.id,
            taskId:+taskIds.id,
            wbsItemMappingId:wbsItemMappingId.id,
            responsiblePerson: responsiblePerson? responsiblePerson.employeeCode:null,
            approver: approver? approver.employeeCode:null,
            totalDays,
            totalLabourRequired: totalLabourRequired ? totalLabourRequired : null,
            plannedStartDate,
            adhocRemarks:AdhocRemarks.current.value,
            adhocImage:Attachments.current.getValue() ? Attachments.current.getValue()[0]:null,
            recurring,
        }

        if(recurring) {
            data.recurringType = type;
            if(type === 'WEEKLY') {
                if(!weekValue) {
                    showToast({
                        type:'error',
                        message:"Please select day"
                    })
                    return;
                }
                data.recurringValue = weekValue;
            } else if( type === 'MONTHLY') {
                if(!dayValue) {
                    showToast({
                        type:'error',
                        message:"Please select the date"
                    })
                    return;
                }
                data.recurringValue = dayValue;
            } else if( type === 'ANNUALLY') {
                if(!monthValue) {
                    showToast({
                        type:'error',
                        message:"Please select the month"
                    })
                    return;
                }
                if(!dayValue) {
                    showToast({
                        type:'error',
                        message:"Please select the date"
                    })
                    return;
                }

                data.recurringValue = `${dayValue}-${monthValue.slice(0,3)}`
            }
        }
        
        setButtonLoading(true);
        post(`project/task/adhoc/create`,data,(e,r)=>{
            if(r) {
                props.onComplete();
                showToast({
                    type:'success',
                    message:'Adhoc task created successfully'
                })
                setButtonLoading(false);
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
                setButtonLoading(false);
            }
        })
    }

    let {id} = props;
    return(
            <>
                <SearchWbsMappingItemN 
                            projectId={id} 
                            title={`Mapped ${getDictionaryValue('wbs')} Item`}
                            handleRef={ref => MappedWbsItem.current = ref} 
                            placeholder={`Search mapped ${getDictionaryValue('wbs')} item`}
                            />   
                <SearchTaskCategory
                           title={`Search ${getDictionaryValue('Task')} Category`}
                           onChange={(item)=>{setCategory(item)}}
                /> 
                <SearchTaskN
                            title="Search Adhoc Task"
                            handleRef={ref => Task.current = ref} 
                            style={{marginRight:20}}
                            category={category}
                            categorySearch={true}
                            type="IRREGULAR"
                            />  
                <EmployeeSearch
                            title="Responsible Person (optional)"
                            placeholder="Search Responsible Person"
                            handleRef={ref => Person.current = ref}
                            style={{marginRight:20}}
                        />
                <EmployeeSearch
                            title="Approver (optional)"
                            placeholder="Search Approver"
                            handleRef={ref => ApproverPerson.current = ref}
                        />

                <SimpleFlex>
                        <FunctionalInput 
                            type="text" 
                            title="Total Days" 
                            placeholder="Enter total number of days"
                            ref={TotalDays} 
                            style={{marginRight:20}}
                            />
                        <FunctionalInput 
                            type="text" 
                            title="Total Labour Required (optional)" 
                            placeholder="Enter total labour required"
                            ref={TotalLabour} />
                </SimpleFlex>

                <SimpleFlex>
                        <FunctionalInput 
                            type="date" 
                            title="Planned Start Date" 
                            placeholder="Planned Start Date" 
                            ref={PlannedStartDate} 
                            style={{marginRight:20}}
                            />
                        <FunctionalInput 
                            type="text" 
                            title="Adhoc Remarks (optional)" 
                            placeholder="Enter the adhoc remarks" 
                            ref={AdhocRemarks} 
                            />
                </SimpleFlex>

                <ToggleButton text="Task is Recurring" title="" active={recurring} onChange={setRecurring} />

                {
                    recurring ?
                    <>
                        <SearchSelect title="Type of Recurring" onChange={(type)=>{
                            setType(type)
                        }} items={[{value:'DAILY',key:'DAILY'},{value:"WEEKLY",key:"Weekly"},{key:'FORTNIGHTLY',value:'FORTNIGHTLY'},{value:"MONTHLY",key:'MONTHLY'},{value:"QUARTERLY",key:'QUARTERLY'},{key:'SEMIANNUAL',value:'SEMIANNUAL'},{value:'ANNUALLY',key:'Yearly'}]} />

                        {/* {
                            type==='WEEKLY'?
                            <>
                                <WeekDays select={(val)=>{
                                    setWeekValue(val)
                                }} />
                            </>
                            :type === 'MONTHLY' ?
                            <>
                                <DaysList select={(val)=>{
                                    setDayValue(val)
                                }} />
                            </>
                            : type === 'ANNUALLY' ?
                            <>
                                <MonthList select={(val)=>{
                                    setMonthValue(val)
                                }} />
                                <DaysList select={(val)=>{
                                    setDayValue(val)
                                }} />
                            </>:null
                        } */}
                    </>
                    
                    
                    
                    :null
                }

                <Attachment  
                    handleRef={ref=>Attachments.current=ref}
                />

                <Button
                    text="Add"
                    style={{
                        marginTop: 30
                    }}
                    onClick={_onAdd}
                    loading={buttonLoading}
                />
            </>
        )
}

export default Index;