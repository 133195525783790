import { useEffect, useRef, useState } from "react";
import { post,get,axios } from "../../../Model/Network/Config/Axios";
import { Button } from "../../Common/Button";
import { SimpleFlex } from "../../Common/Flex/SimpleFlex";
import { FunctionalInput } from "../../Common/FloatInput/FunctionalInput";
import { KeyValue } from "../../Common/KeyValue";
import { Modal } from "../../Common/Modal/Modal";
import { RightModal } from "../../Common/Modal/RightModal";
import { PageLoader } from "../../Common/PageLoader";
import { SearchDrawingCategory } from "../../Common/Search/SearchDrawingCategory";
import { showToast } from "../../Common/Toast";




const Index = (props) => {

    const [loading,setLoading] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [items,setItems] = useState([]);
    const [edit,setEdit] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [add,setAdd] = useState(false);
    let CATEGORY = useRef(),LAGDAY=useRef();



    useEffect(()=>{
        INIT();
    },[])

    useEffect(()=>{
        if(edit) {
            LAGDAY.current.value=edit.lagDays;
        }
    },[edit])

    let INIT = () => {
        get(`project/task/drawing/fetchDrawingTemplateMappings/${props.projectId}/${props.taskId}`,(e,r)=>{
            if(r) {
                setItems(r.drawings);
                setLoading(false);
            }
        })
    }

    if(loading) {
        return <PageLoader />
    }

    let mapDrawing = (item,index) => {
        return(
            <div style ={{
                width:'33%',
                padding:5,
                marginTop:30,
                position:'relative'
            }} key={index}>
              <div style={{
                   border:'1px solid var(--border-color)',
                   padding:20,
                   borderRadius:8,
                   marginRight:10
              }}>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--border-color)',
                    padding:'3px 8px',
                    left:20,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600',
                    color:'var(--sidebar-background)'

                }}>
                    Drawing {index+1}
                </div>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--button-color)',
                    padding:'3px 12px',
                    right:90,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600',
                    cursor:'pointer'
                }} onClick={()=>{
                    setEdit(item)
                }}>
                    Edit
                </div>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--red-color)',
                    padding:'3px 12px',
                    right:20,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600',
                    cursor:'pointer'
                }} onClick={()=>{
                    setDeleteModal(item)
                }}>
                    Delete
                </div>

                <SimpleFlex>
                    <KeyValue title="Category" desc={item.drawingCategory} style={{flex:1}} />
                    <KeyValue title="Description" desc={item.drawingCategoryDesc} style={{flex:1}} />
                </SimpleFlex>
                <SimpleFlex>
                    <KeyValue title="LagDay" desc={item.lagDays} style={{flex:1}} />
                    <KeyValue title="Drawing Received" desc={item.drawingReceived?"Yes":"No"} style={{flex:1}} />
                </SimpleFlex>
               
                </div>
            </div>
        )
    }

    return(
        <div>
             <div style={{
                display:'flex',
                justifyContent:'flex-end'
            }}>
                <div style={{
                    height:40,
                    width:40,
                    border:'2px dashed var(--active-color)',
                    borderRadius:'50%',
                    color:'var(--active-color)',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    fontSize:40,
                    paddingBottom:10,
                    cursor:'pointer'
                }} onClick={()=>{
                    setAdd(true)
                }}>
                    +
                </div>

               
            </div>
            <div style={{display:'flex',flexWrap:'wrap'}}>
                    {items.map(mapDrawing)}
            </div>

            {
                add ?
                <RightModal
                    title="Map Drawing"
                    onClose={()=>{
                        setAdd(false);
                    }}
                    show={add}
                >
                    <SearchDrawingCategory title="Drawing Category" handleRef={ref=>CATEGORY.current=ref}  />
                    <FunctionalInput title="Lag Day" type="number" ref={LAGDAY} />
                    <Button loading={buttonLoading} text="Map" onClick={()=>{
                        let drawingCategoryId = CATEGORY.current.getValue()?CATEGORY.current.getValue().id:null;
                        let lagDays = LAGDAY.current.value;

                        if(!drawingCategoryId) {
                            showToast({
                                type:'error',
                                message:"Please select the drawing category"
                            })
                            return;
                        }
                        if(!lagDays) {
                            showToast({
                                type:'error',
                                message:"Please enter the lag days"
                            })
                            return;
                        }

                        setButtonLoading(true);

                        post(`project/task/drawing/addResourceMapping/${props.projectId}`,{drawingCategoryId,lagDays:+lagDays,taskMappingId:props.taskId},(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"Drawing map successfully"
                                })
                                setButtonLoading(false);
                                INIT();
                                setAdd(false);
                            } else {
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                                setButtonLoading(false);
                            }
                        })

                    }} style={{marginTop:30}} />
                </RightModal> 
                : null
            }

              {
                deleteModal?
                <Modal
                    title="Remove Mapping of Drawing"
                    desc={deleteModal.drawingCategory}
                    show={true}
                    onClose={()=>{
                        setDeleteModal(false)
                    }}
                    style={{
                        minHeight:240
                    }}
                >
                    <p style={{
                        fontSize:13,
                        color:'var(--text-light-color)',
                        marginTop:40
                    }}>Are you sure want to remove mapping of {deleteModal.drawingCategory} ?</p>
                    <Button loading={buttonLoading} text="Remove" style={{marginTop:50}} onClick={()=>{
                        setButtonLoading(true);
                         axios.delete(`project/task/drawing/removeResourceMapping/${props.projectId}/${deleteModal.drawingTemplateMappingId}`, {
                            data: {
                            }
                        })
                            .then(res => {
                                setDeleteModal(false);
                                setButtonLoading(false);
                                INIT();
                                showToast({
                                    type: 'success',
                                    message: "Drawing un-tag successfully"
                                })
                            })
                    }} />
                </Modal>
                : null
            }

            {
            edit ?
            <RightModal
                title="Update Drawing"
                desc={`${edit.drawingCategory}`}
                onClose={()=>{
                    setEdit(false)
                }}
                show={true}
                >   
                <FunctionalInput ref={LAGDAY} type="number" title="Lag Day" />
                <Button style={{marginTop:30}} text="Update" loading={buttonLoading} onClick={()=>{
                    let lagDays = LAGDAY.current.value;
                    if(!lagDays){
                        showToast({
                            type:'error',
                            message:"Please enter the lag day"
                        })
                        return;
                    }
                    setButtonLoading(true);

                    post(`project/task/drawing/updateResourceMapping/${props.projectId}/${edit.drawingTemplateMappingId}`,{lagDays:+lagDays},(e,r)=>{
                        if(r) {
                            showToast({
                                type:'success',
                                message:"Drawing update successfully"
                            })
                            INIT();
                            setEdit(false);
                            setButtonLoading(false);

                        } else {
                            setButtonLoading(false);
                        }
                    })
                }} />
            </RightModal>
            : null
            }

        </div>
    )
}

export default Index;