import { useEffect, useRef, useState } from "react";
import {useParams} from 'react-router-dom';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { useProject } from "../../../Hooks/ProjectDetails";
import { Header } from "../../../NCommon/Header";
import { HeaderWrapper } from "../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../NCommon/PageWrapper";
import ViewAttendance from "./ViewAttendance";
import './index.css';
import { get } from "../../../../Model/Network/Config/Axios";
import { Button } from "../../../Common/Button";
import { DownloadDirectCsv } from "../../../../Utils/Downloader";
import { RefObject } from "../../../../Utils/Refrence";


const Attendance = () => {
    const [showAttendance, setShowAttendance] = useState(false);
    const [allAttendance, setAllAttendance] = useState([]);
    const [events, setEvents] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [count,setCount] = useState(0);
    const {id} = useParams();
    const projectDetails = useProject();
    const calendarApi = useRef();


    useEffect(()=>{
        if(calendarApi.current) {
            RefObject.setRef(calendarApi.current.getApi());
        }
    },[calendarApi,calendarApi.current,count])

    useEffect(() => {
        get(`project/labour/fetchLabourAttendance/${id}`,(e,r)=>{
            if(r) {
                setAllAttendance(r.labourAttendances);
                
                let allEvents = r.labourAttendances.reduce((acc, entry) => {
                    const { date, vendor } = entry;
                    let dateEntry = new Date(date).toISOString().slice(0, 10);
                    let vendorEntry = vendor.name;
                     
                    let vendorEntryPresent = acc.find(entry => entry.title === vendorEntry && entry.start === dateEntry);

                    if(!vendorEntryPresent) {
                        vendorEntryPresent = {
                            title: vendorEntry,
                            description: [],
                            start: new Date(dateEntry).toISOString().slice(0, 10),
                            end: new Date(dateEntry).toISOString().slice(0, 10),
                        }; 
                        acc.push(vendorEntryPresent);
                    }

                    vendorEntryPresent.description.push(entry);
                    return acc;
                }, []);

                setEvents(allEvents);

            }
        }, {
            start: 1675189800000, // Feb 01 temporary hardcoded values for February
            end: 1677608999000 // Feb 28
        })
    }, [])

    const eventClick = e => {
        const { _def, _instance } = e;

        let vendorName = _def.title;
        let attendanceDate = _instance.range.start.toISOString().slice(0, 10);
        
        let filteredAttendance = allAttendance.filter(entry => new Date(entry.date).toISOString().slice(0, 10) === attendanceDate && entry.vendor.name === vendorName);
        
        setShowAttendance(true);
        setDisplayData(filteredAttendance);
    }

    const downloadAttendanceCSV = () => {
        let header = "date, count, details, , , , user, , , , vendor, , , , , remarks\n, , id, category, subType, resource, id, code, name, email, id, code, name, email, mobile\n";

        let csvString = allAttendance.reduce((acc, entry) => acc.concat(`"${new Date(entry.date).toUTCString().slice(0, 16)}","${entry.count}","${entry.details.id}","${entry.details.category}","${entry.details.subType}","${entry.details.resource}","${entry.user.id}","${entry.user.code}","${entry.user.name}","${entry.user.email}","${entry.vendor.id}","${entry.vendor.code}","${entry.vendor.name}","${entry.vendor.email}","${(entry.vendor.mobile)}","${entry.remarks}"\n`), "");

        DownloadDirectCsv("Attendance.csv", header + csvString);
    }

    return(
        <>
            <Header title="Attendance Calendar" />
            <HeaderWrapper 
                calendarView={true}
                showFilter={false}
            />
            <PageWrapper>
                <div style={{marginTop:-20}}/>
                <NavigationWrapper 
                    items = {
                        [
                            {
                                name:'All Project',
                                path:'/app/project'
                            },
                            {
                                name:`${projectDetails.name}`,
                                path:`/app/project/mapping/${id}`
                            },
                            {
                                name:`Attendance`
                            }
                        ]
                    }
                >
                    <Button 
                        text="Download CSV"
                        inverted={true}
                        style={{borderRadius: 3}}
                        onClick={downloadAttendanceCSV}
                    />
                </NavigationWrapper>
                <div style={{margin:'10px -20px',background:"#fff"}}>
                    <FullCalendar
                         plugins={[ dayGridPlugin, interactionPlugin ]}
                        ref={ref=>{
                            calendarApi.current=ref
                            setCount(1);
                        }}
                        initialView="dayGridMonth"
                        eventClick={(e) => eventClick(e.event)}
                        events={events}
                    />
                </div>
               
            </PageWrapper>
            {
                showAttendance ?
                    <ViewAttendance 
                        details={{displayData}}
                        close={() => setShowAttendance(false)}
                    /> : null
            }
        </>
    )
}

export default Attendance;
