import React from 'react';
import Moment from 'moment';
import _ from 'lodash';
import { Button } from '../Button';
import { connect } from 'react-redux';
import tick from './tick.png';
import { showToast } from '../Toast';

class Index extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            input: ''
        }
    }



    mapItem = (item, index) => {
        let name = item[this.props.search];
        if (this.props.search.toLowerCase().includes('date')) {
            name = Moment(item[this.props.search]).format('DD-MM-YYYY')
        }
        let active = _.includes(this.state.selected, item[this.props.search]) || this.props.checked;
        return (
            <div style={{
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }} key={index} onClick={() => {
                if (_.includes(this.state.selected, item[this.props.search])) {
                    let selected = this.state.selected.filter(val => val !== item[this.props.search]);
                    this.setState({
                        ...this.state,
                        selected
                    })
                } else {
                    let selected = this.state.selected;
                    selected.push(item[this.props.search]);
                    this.setState({
                        ...this.state,
                        selected
                    })
                }
            }}>
                {
                    active ?
                        <img src={tick} alt="" style={{
                            height: 16,
                            width: 16
                        }} /> : <p></p>
                }
                <div style={{
                    ...active ?
                        { paddingLeft: 24 } :
                        {
                            paddingLeft: 40
                        }
                }}>

                    {name}
                </div>
            </div>
        )
    }

    filterData = (data) => {
        if (this.state.input.length > 0)
            data = data.filter(item => {
                let name = item[this.props.search];
                if (this.props.search.toLowerCase().includes('date')) {
                    name = Moment(item[this.props.search]).format('DD-MM-YYYY')
                }
                if (name.toLowerCase().includes(this.state.input.toLowerCase())) {
                    return true
                }
            })
        return data;
    }

    render() {
        let data = this.props.data;
        data = this.filterData(data);
        return (
            <div style={{
                position: 'absolute',
                top: 20,
                height: 400,
                backgroundColor: "#fff",
                width: 250,
                border: '1px solid var(--border-color)',
                zIndex: 100,
                borderRadius: 2,
                boxShadow: '0 1px 6px rgb(33 33 33 / 6%)',
                padding: 20
            }}>

                <input type="text" onChange={(e) => {
                    this.setState({
                        ...this.state,
                        input: e.target.value
                    })
                }} style={{
                    border: '1px solid var(--border-color)',
                    height: 40,
                    borderRadius: 2,
                    width: '100%',
                    paddingLeft: 5,
                    boxSizing: 'border-box'
                }} placeholder="Search" />

                <div style={{
                    height: 320,
                    overflowY: 'auto',
                    borderBottom: '1px solid var(--border-color)'
                }}>
                    {data.map(this.mapItem)}
                </div>

                <div style={{
                    position: 'absolute',
                    right: -80,
                    width: '100%',
                    paddingTop: 10,
                    display: 'flex'

                }}>

                    <Button
                        text="Cancel"
                        style={{
                            minWidth: 100,
                            marginRight: 10
                        }}
                        onClick={() => {
                            this.props.onclose();
                            this.props.filter({})
                        }}
                        inverted={true?1:0}

                    />
                    <Button
                        text="Ok"
                        style={{
                            minWidth: 80
                        }}

                        onClick={() => {
                            if (this.props.checked) {
                                this.props.filter({
                                    searchKey: this.props.search, values: this.props.data.map((val) => (
                                        val[this.props.search]
                                    ))
                                })
                                this.props.onclose();

                            } else {
                                if (this.state.selected.length === 0) {
                                    showToast({
                                        type: 'error',
                                        message: 'Please select some value for filter'
                                    })
                                    return;
                                }
                                this.props.filter({ searchKey: this.props.search, values: this.state.selected })
                                this.props.onclose();

                            }

                        }}


                    />
                </div>
            </div>
        )
    }
}



const mapDispatchToProps = (dispatch) => {
    return {
        filter: (obj) => { dispatch({ type: 'filter', value: obj }) }
    }
}



export default connect(null, mapDispatchToProps)(Index);