import {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import { post } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import { useProject } from '../../../../Hooks/ProjectDetails';
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import { PDFExport } from "@progress/kendo-react-pdf";
import Main from './Main';


const Index = () => {

    const {id} = useParams();
    const project = useProject();
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [filters,setFilters] = useState([]);
    let PDF = useRef();
    const [TYPEOFFILTER] = useState({
        "TASK":["Name","Wbs category","Task Category","Vendor","Responsible Person"],
        "WBS_CATEGORY":["Wbs category","Task Category","Vendor","Responsible Person"],
        "EMPLOYEE":["Responsible Person","Wbs category","Task Category","Vendor"],
        "TASK_CATEGORY":["Task Category","Wbs category","Vendor","Responsible Person"],
        "WBS":["WBS","Task Category","Wbs category","Vendor","Responsible Person"],
        "VENDOR":["Vendor","Task Category","Wbs category","Responsible Person"],
        "APPROVER":["Responsible Person","Wbs category","Task Category","Vendor"],

    })

    const [status,setStatus] = useState([
        
          {
            name: "By Task_c",
            active: false,
            color: "#ebb840",
            key:'TASK_CATEGORY'
          },
          {
            name: "By Wbs_c",
            active: true,
            color: "#ff944d",
            key:'WBS_CATEGORY'
          },
          {
            name: "By Employee",
            active: false,
            color: "#68d083",
            key:'EMPLOYEE'
          },
          {
            name:'By Vendor',
            active:false,
            color:'#ea3a3c',
            key:'VENDOR'
          },
          {
            name:'By Approver',
            active:false,
            color:'#389940',
            key:'APPROVER'
          }
         
    ])
    const [active,setActive] = useState('WBS_CATEGORY')


    useEffect(()=>{
        INIT({});
    },[active])


    let INIT = (data) => {
        setLoading(true);
        if(data.filter) {
            data.andFilters=[...data.filter];
            delete data.filter;
        }
        post(`/project/task/taskAnalytics/${id}`,{type:active,...data},(e,r)=>{
            if(r) {
                setData(r.tasks);
                setLoading(false);
            }
        })
    }

    useEffect(()=>{
        status.forEach(val=>{
            if(val.active) {
                setActive(val.key);
                return;
            }
        })
    },[status])

    return(
        <>
            <HeaderWrapper
               dynamicFilter={true}
               showFilter={false}
               statusFilter={true}
               status={status}
               onChangeStatusFilter={setStatus}
               singleSelect={true}
               types={TYPEOFFILTER[active]}
               filters={[...filters]}
               onFilterChange={items=>{
                let filter = [];
                items.forEach(item=>{
                    filter.push({
                        key:item.type,
                        val:item.value
                      })
                })
                setFilters(items)
                INIT({filter})
                }}
                menuItem={[{name:'Download Pdf',type:'downloadcsv',onClick:()=>{PDF.current.save()}}]}
            
            />
            <PageWrapper>
                <NavigationWrapper items={[{name:"All Project",path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`${active} Type ( ${Object.keys(data).length} )`}]}>
                </NavigationWrapper>
                {
                    loading ?
                    <PageLoader />
                    :
                    <PDFExport margin={20} padding="30cm" title={`${project.name}`} fileName={`${project.name}&${active}Type.pdf`} ref={r => PDF.current = r}>
                        <Main filter={[...filters]} active={active} data={data} />
                    </PDFExport>
                }
            </PageWrapper>

        </>
    )
}

export default Index;