import { Button } from '../../../Common/Button';
import { post } from '../../../../Model/Network/Config/Axios';
import { useRef } from 'react';
import { FunctionalInput } from '../../../Common/FloatInput/FunctionalInput';
import {toast} from 'react-hot-toast'
import { showToast } from '../../../Common/Toast';
import { RightModal } from '../../../Common/Modal/RightModal';
import { useDictionaryValue } from '../../../Hooks/DictionaryHooks';


const AddCategory = ({ onComplete,onClose }) => {

    let Name = useRef(),Details=useRef();
    let getDictionaryValue = useDictionaryValue();

    let addCategory = () => {
        let categoryName = Name.current.value;
        let categoryDetails = Details.current.value;

        if (!categoryName) {
            toast.error('Please enter the category name')
            return;
        }

        if (!categoryDetails) {
            toast.error('Please enter the wbs category details')
            return;
        }

        let data = {
            name: categoryName,
            details: categoryDetails,
        }


        post(`project/wbs/wbsItemCategory.create`, data, (e, r) => {
            if (r) {
                showToast({
                    type:'success',
                    message:'Category added successfully'
                })
                onComplete()
            }
            if (e) {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })

    }

    return (
        <RightModal
            title={`Add ${getDictionaryValue('wbs')} Category`}
            desc={`Enter the details and add ${getDictionaryValue('wbs')} category`}
            show={true}
            onClose={onClose}
        >
            <FunctionalInput title="Name" type="text" ref={Name}  />
            <FunctionalInput title="Details" type="text" ref={Details} />
           
            <Button
                text="Add Category"
                onClick={addCategory}

                style={{
                    marginTop: 30
                }}
            />
        </RightModal>
    )
}


export default AddCategory;