import { StaticFilter } from "../Filter/StaticFilter";
import { DynamicFilter } from "../Filter/DynamicFilter";
import { Menu } from "../Menu"
import { TableView } from "../Tables/TableView"
import { ShownFilter } from "../Filter/ShownFilter";
import { StatusFilter } from "../Filter/StatusFilter";
import CalendarView from "./CalendarView";
import { GraphView } from "../Tables/GraphView";


const HeaderWrapper = (props) => {

    let {onFilterChange,filters,status,onChangeStatusFilter,hideCard,calendarView,setOtherStatus,otherStatus,singleSelect, selectedDate,
        setSelectedDate} = props;

    return(
        <>
        <div style={{
            height:50,
            borderBottom:'1px solid var(--border-color)',
            background:"#fff",
            borderLeft:'1px solid var(--border-color)',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            padding:'0px 20px'
        }} id="header-wrapper">
            <div className="flex">
                {props.staticFilter?<StaticFilter onFilterChange={onFilterChange} suggestion={props.suggestion} types={props.types} />:null}
                {props.dynamicFilter?<DynamicFilter filters={filters} onFilterChange={onFilterChange}  types={props.types} />:null}
                 
                {props.statusFilter?
                <>
                <div style={{borderLeft:'1px solid var(--border-color)',height:'inherit',margin:'0px 15px'}}/>
                <StatusFilter singleSelect={singleSelect} setOtherStatus={setOtherStatus} otherStatus={otherStatus} status={status} onChangeStatusFilter={onChangeStatusFilter} />
                </>
                :null}
                <CalendarView calendarView={calendarView} />
                {props.children}

            </div>
            <div style={{display:'flex',alignItems:'center',height:'inherit'}}>
                {props.showFilter ?<TableView hideCard={hideCard} /> : null}
                {props.selectDatesDropdown?.length ? (
                    <div
                        style={{
                            borderLeft: "1px solid var(--border-color)",
                            height: "inherit",
                            paddingLeft: 10,
                        }}
                    >
                        <Menu items={props.selectDatesDropdown} title={"Show Data Based on "} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                    </div>
                ) : null}
                {props.graphFilter ?<GraphView  /> : null}
                {props.menuItem.length?
                <div style={{borderLeft:'1px solid var(--border-color)',height:'inherit',paddingLeft:10}}>
                    <Menu items={props.menuItem} />
                </div>
                :null}
            </div>
        </div>
        <ShownFilter 
            onRemove={(val)=>{
                let items = [...filters];
                items = items.filter(item=>item.value !== val.value);
                props.onFilterChange(items);
            }} items={filters} />
      </>
    )
}


HeaderWrapper.defaultProps  = {
    showFilter:true,
    menuItem:[],
    filters:[],
    status:[],
    graphFilter:false
}


export  {HeaderWrapper}