
import { Switch, Route,useParams } from 'react-router-dom';
import WorkflowDetails from './WorkflowDetails';
import Details from './Details';
import Checklist from './Checklist';
import { Header } from '../../../NCommon/Header';


const Index = (props) => {
    let {id,projectId} = useParams();
    return (
            <>
                <Header
                    title="Task Details"
                    desc="All Details of task appear below"
                    items={[
                        {
                            name: 'Details',
                            link: `/app/project/task/${projectId}/${id}/details`,
                            active: props.location.pathname === `/app/project/task/${projectId}/${id}/details`
                        },
                        {
                            name: 'Checklist',
                            link: `/app/project/task/${projectId}/${id}/details/checklist`,
                            active: props.location.pathname === `/app/project/task/${projectId}/${id}/details/checklist`
                        },

                        {
                            name: 'All Approvals',
                            link: `/app/project/task/${projectId}/${id}/details/workflow`,
                            active: props.location.pathname === `/app/project/task/${projectId}/${id}/details/workflow`
                        },
                       

                    ]}
                />
                <Switch>
                    <Route path="/app/project/task/:projectId/:id/details" component={Details} exact />
                    <Route path="/app/project/task/:projectId/:id/details/checklist" component={Checklist} exact />
                    <Route path="/app/project/task/:projectId/:id/details/workflow" component={WorkflowDetails} exact />
                    <Route path="/app/project/task/:projectId/:id/details/resource" component={WorkflowDetails} exact />
                </Switch>
            </>
        )
}

export default Index;