import { useEffect, useState } from 'react';
import _ from 'lodash';
import AddRole from './AddRole';
import { get,deleteApi } from '../../../../../../Model/Network/Config/Axios';
import { AccessButton, Button } from '../../../../../Common/Button';
import { PageLoader } from '../../../../../Common/PageLoader';
import { BottomHeader } from '../../../../../Common/TopHeader/BottomHeader';
import { Modal } from '../../../../../Common/Modal/Modal';
import { showToast } from '../../../../../Common/Toast';
import { HeaderWrapper } from '../../../../../NCommon/HeaderWrapper';
import { PageWrapper } from '../../../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../../../NCommon/NavigationWrapper';
import {Table} from '../../../../../NCommon/Tables/index';
import { Card } from '../../../../../NCommon/Ui/CardLayout';
import {List} from '../../../../../NCommon/Ui/List';
import { TableMenu } from '../../../../../NCommon/Tables/TableMenu';


const Index = () => {

    const [loading,setLoading] = useState(true);
    const [roles,setRoles] = useState([]);
    const [addRole,setAddRole] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);

    useEffect(()=>{
        INIT()
    },[])

    let INIT = () => {
        get(`roles/all`, (e, r) => {
            if (r) {
                
                setRoles(r.roles);
                setLoading(false);
            }
        })
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card height={120} items={[{name:'Delete',onClick:()=>{setDeleteModal(item)}}]} title={item.name} desc={item.description} key={index} />
        )
    }

    let mapListLayout = (item,index) =>{
        return(
            <List key={index}>
                <div style={{flex:1}}>
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.description}</p>
                </div>
                <TableMenu items={[{name:'Delete',onClick:()=>{setDeleteModal(item)}}]} />
            </List>
        )
    }



   let deletes = () => {
       setButtonLoading(true);
        deleteApi(`roles/delete/${deleteModal.id}`, (e, r) => {
            setButtonLoading(false);
            setDeleteModal(false);
            INIT();
            showToast({
                type:'success',
                message:'Role deleted successfully'
            })
        })
    }


    return (
        <div>
            {
              loading ?
                    <PageLoader />
                    :
                    
                    <>
                        <HeaderWrapper />
                        <PageWrapper>
                            <NavigationWrapper items={[{name:`All Roles (${roles.length})`}]}>
                                <AccessButton
                                    text="Add Role"
                                    onClick={() => {
                                        setAddRole(true);
                                    }}
                                    inverted={true?1:0}
                                    addsign={true?1:0}
                                />
                            </NavigationWrapper>
                            <Table
                                mapCardLayout={mapCardLayout}
                                mapListLayout={mapListLayout}
                                DATA={roles}
                                headers={[
                                    {
                                        name:'Details',
                                        flex:1
                                    },
                                    {
                                        name:'actions',
                                        width:50
                                    }
                                ]}

                            />
                        </PageWrapper>
                       
                        

                        {
                            addRole ?
                                    <AddRole
                                        onSubmit={() => {
                                            setAddRole(false);
                                            INIT();
                                        }}
                                        onClose={()=>{
                                            setAddRole(false)
                                        }}
                                    />
                                : null
                        }

                        {
                            deleteModal ?
                                <Modal
                                    show={deleteModal}
                                    title="Delete Role"
                                    desc="By clicking button below delete selected Role"
                                    style={{
                                        minHeight: 200
                                    }}
                                    onClose={() => {
                                        setDeleteModal(false);
                                    }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        marginTop: 30
                                    }}>
                                        <p style={{
                                            fontSize: 18,
                                            color: 'var(--light-color)'
                                        }}>
                                            By clicking below you will be deleting selected role and data related to this role
                                        </p>

                                        <Button
                                            text="Delete"
                                            style={{
                                                backgroundColor: 'var(--red-color)',
                                                borderColor: 'var(--red-color)',
                                                marginTop: 30
                                            }}
                                            loading={buttonLoading}
                                            onClick={deletes}
                                        />
                                    </div>
                                </Modal>
                                : null
                        }
                    </>
            }
        </div>
    )
}

export default Index;