import {useLocation,Route,Switch,useParams} from 'react-router-dom';
import { Header } from '../../../NCommon/Header';
import Setting from './setting';
import Whatsapp from './Whatsapp';
import Connector from './Connector/index';


const Index = () => {
    let {id} = useParams();
    let {pathname} = useLocation();
    return(
        <>
            <Header
                    items={[
                        {
                            name:'All',
                            link:`/app/project/setting/${id}`,
                            active:pathname===`/app/project/setting/${id}`
                        },
                        {
                            name:'Whatsapp configuration',
                            link:`/app/project/setting/${id}/whatsapp`,
                            active:pathname===`/app/project/setting/${id}/whatsapp`
                        },
                        {
                            name:'Connector',
                            link:`/app/project/setting/${id}/connector`,
                            active:pathname===`/app/project/setting/${id}/connector`
                        }

                    ]}
                />
            <Switch>
                <Route path="/app/project/setting/:id" component={Setting} exact />
                <Route path="/app/project/setting/:id/connector" component={Connector} exact />
                <Route path="/app/project/setting/:id/whatsapp" component={Whatsapp} exact />
            </Switch>
        </>
    )
}

export default Index;