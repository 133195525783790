import  { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { get } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import { Table } from '../../../../Common/Tables';
import { BottomHeader } from '../../../../Common/TopHeader/BottomHeader';
import { TaskList } from '../../../../Common/TaskItem/TaskList';


const Index = (props) => {

    const [taskMappings,setTaskMappings] = useState([]);
    const [loading,setLoading] = useState(true);
    const [taskOpen,setTaskOpen] = useState(undefined);
    let {id} = useParams();

    useEffect(()=>{
        INIT(id)
    },[id])


    let INIT = (employeeCode) => {
        get(`employees/tasksAsResponsiblePerson/${employeeCode}`, (e, r) => {
            if (r) {
                setTaskMappings(r.tasksMappings);
                setLoading(false);
            }
        })
    }



   let mapItem = (item, index) => {
        return (
            <TaskList
                {...props}
                key={index}
                item={item}
                taskOpen={taskOpen}
                isPredecessorEdit={false}
                onTaskOpen={() => {
                    if (item.id === taskOpen) {
                        setTaskOpen(undefined);
                    } else {
                        setTaskOpen(item.id);
                    }
                }}
                onChangePredecessor={() => {
                    
                }}
            />
        )
    }


        if (loading) {
            return <PageLoader />
        }
        return (
            <div style={{
                flex: 1,
                height: 'calc( 100vh - 150px )',
                position: 'relative'
            }}>
                <BottomHeader
                    title="All  task found"
                    desc={`${taskMappings.length}  task found`}
                />
                <div className="container" style={{
                    padding: 25
                }}>
                    <Table
                        data={taskMappings}
                        mapItem={mapItem}
                        header={[
                            {
                                name: "Details",
                                width: 300,
                                search: "croppedName",
                              },
                
                              {
                                name: "Planned Start Date",
                                flex: 1,
                                style: {
                                  textAlign: "center",
                                },
                                
                              },
                              {
                                name: "Planned End Date",
                                flex: 1,
                                style: {
                                  textAlign: "center",
                                },
                                
                              },
                              {
                                name: "Estimated Start Date",
                                flex: 1,
                                style: {
                                  textAlign: "center",
                                },
                              
                              },
                              {
                                name: "Estimated End Date",
                                flex: 1,
                                style: {
                                  textAlign: "center",
                                },
                                
                              },
                              {
                                name: "Responsible Person",
                                flex: 1,
                                style: {
                                  textAlign: "center",
                                },
                              },
                
                              {
                                name: "Work Status",
                                width: 110,
                                style: {
                                  textAlign: "center",
                                },
                              },
                              {
                                name: "",
                                width: 80,
                              },
                        ]}
                    />
                </div>

            </div>
        )
}

export default Index;