import { useRef, useState } from 'react';
import { post } from '../../../../../../Model/Network/Config/Axios';
import { Button } from '../../../../../Common/Button';
import { RightModal } from '../../../../../Common/Modal/RightModal';
import { SearchExistingWbsItemN } from '../../../../../Common/Search/SearchExistingWbsItemN';
import { SearchWbsMappingItemN } from '../../../../../Common/Search/SearchWbsMappingItemN';
import { showToast } from '../../../../../Common/Toast';
import { useDictionaryValue } from '../../../../../Hooks/DictionaryHooks';



const CloneTask = (props) => {

    const [buttonLoading,setButtonLoading] = useState(false);
    let existingItem = useRef(),ptaskId=useRef();
    let getDictionaryValue = useDictionaryValue();

    let _onAdd = () => {

        let existingWbsItemMappingName = existingItem.current.getValue();
        let newWbsItemMappingId = ptaskId.current.getValue();
        if (!existingWbsItemMappingName) {
            showToast({
                type: 'error',
                message: 'Please select the existing wbs item'
            })
            return;
        }

        if (!newWbsItemMappingId) {
            showToast({
                type: "error",
                message: 'Please select mapped wbs item'
            })
            return;
        }
        let data = {
            newWbsItemMappingId: +newWbsItemMappingId.id,
            existingWbsItemMappingName,
            projectId: +props.id

        }
       setButtonLoading(true);

        post(`project/task/taskMapping.replicate`, data, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Task replicate successfully'
                })
                setButtonLoading(false);
                props.onComplete();
            } else {
                setButtonLoading(false);
                showToast({
                    type:'error',
                    message:e.data.message
                })

               
            }
        })
    }

    return (
        <RightModal
            title={`Clone ${getDictionaryValue('Task')}`}
            desc={`Create the clone ${getDictionaryValue('Task')} by using previous ${getDictionaryValue('Task')} mapping`}
            show={props.show}
            onClose={props.onClose}
        >
            <SearchExistingWbsItemN
                    title="Existing mapping"
                    handleRef={ref => existingItem.current = ref}
                    placeholder="Search existing mapping"
                    id={props.id}
                    />
            <SearchWbsMappingItemN
                    handleRef={ref => ptaskId.current = ref}
                    projectId={props.id}
                    placeholder="Search Mapped wbs item"
                    title="Mapped Wbs Item" />

            <Button
                text="Add"
                style={{
                    marginTop: 30
                }}
                onClick={_onAdd}
                loading={buttonLoading}
            />   
        </RightModal>
    )     
}

export default CloneTask;