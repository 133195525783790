import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { get } from "../../../../Model/Network/Config/Axios";
import { FunctionalInput } from "../../FloatInput/FunctionalInput"




const SearchTag = forwardRef((props,ref) => {

    const [value,setValue] = useState('');
    const [items,setItems] = useState([]);
    const [item,setItem] = useState([]);
    let INPUT = useRef();

    useEffect(()=>{
       if(value.length>0) {
            get(`setting/miscConfig/tags/searchTags`,(e,r)=>{
                if(r) {
                    setItems(r.tags)
                }
            },{q:value})
       }
    },[value])

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return item
        },
    }))
  

    return(
        <div style={{position:'relative'}}>
            <FunctionalInput title="Search Tag" ref={INPUT} onChange={val=>{
                setValue(val)
            }} />
            {
                items.length>0 &&
                <div className="suggestion-modal" style={{top:68}}>
                     {
                         items.map((item,index)=>{
                            return(
                                <div onClick={(e)=>{
                                    e.stopPropagation();
                                    INPUT.current.value = item.name;
                                    setItem(item);
                                    setItems([]);
                                }} key={index} style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}} className="suggestion-modal-item">
                                    {item.name}
                                </div>
                            )
                        })
                    
                     }
                </div>
            }
           
            
        </div>
    )
})

export {SearchTag}