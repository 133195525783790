import { useEffect, useRef } from "react";
import { patch } from "../../../../../Model/Network/Config/Axios";
import { Attachment } from "../../../../Common/Attachment";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../../Common/Modal/RightModal";
import { SearchDrawingCategory } from "../../../../Common/Search/SearchDrawingCategory";
import { showToast } from "../../../../Common/Toast";
import {Button} from '../../../../Common/Button/index';


const Edit = (props) => {

    let Title = useRef(),DrawingNumber=useRef(),DrawingSubCategory=useRef(),DrawingCategory=useRef(),RevisionNumber=useRef(),RelatedDrawingId=useRef(),attachment=useRef(null);

    useEffect(()=>{
        let {item} = props;
         Title.current.value  = item.title?item.title:'';
         DrawingNumber.current.value = item.drawingNumber;
         RevisionNumber.current.value = item.revisionNumber;
         RelatedDrawingId.current.value = item.relatedDrawingId ? item.relatedDrawingId:'';
         DrawingCategory.current.setRef(item.drawingCategory);
         DrawingSubCategory.current.value = item.subCategory?item.subCategory:''

    },[])

    return(
        <RightModal
            title="Edit drawing"
            desc="Fill up the details and edit drawing"
            onClose={props.onClose}
            show={true}
        >
            <FunctionalInput title="Title" type="text"  ref={Title}  />
            <FunctionalInput title="Drawing Number" type="text"  ref={DrawingNumber}  />
            <SearchDrawingCategory  title="Category" projectId={props.projectId} handleRef={ref=>DrawingCategory.current=ref} />
            <FunctionalInput title="Sub Category" type="text" ref={DrawingSubCategory} />
            <FunctionalInput title="Revision Number" type="text" ref={RevisionNumber} />
            <FunctionalInput title="Related Drawing Number" type="text" ref={RelatedDrawingId} />
            <Attachment files={props.item.files} isDelete={true}  addTitle={true} handleRef={ref=>attachment.current=ref} />
            <Button text = "Update Drawing" style={{marginTop:20}} onClick={()=>{
                        
                        let title = Title.current.value;
                        let drawingNumber = DrawingNumber.current.value;
                        let drawingCategory = DrawingCategory.current.getValue();
                        let subCategory = DrawingSubCategory.current.value;
                        let revisionNumber = RevisionNumber.current.value;
                        let relatedDrawingId = +RelatedDrawingId.current.value?+RelatedDrawingId.current.value:null;
                        let files = attachment.current.getFiles().map((val)=>{
                            return {title:val.title,url:val.url}
                        })

                        if(!title) {
                            showToast({
                                type:'error',
                                message:"Please enter the title"
                            })
                            return
                        }

                     
                        if(!drawingNumber) {
                            showToast({
                                type:'error',
                                message:"Please enter the drawing number"
                            })
                            return
                        }

                        if(!drawingCategory) {
                            showToast({
                                type:'error',
                                message:'Please enter the  category'
                            })
                            return
                        }
                        if(!subCategory) {
                            showToast({
                                type:'error',
                                message:'Please enter the sub category'
                            })
                            return
                        }

                        if(!revisionNumber) {
                            showToast({
                                type:'error',
                                message:'Please enter the revision number'
                            })
                            return
                        }

                        if(files.length ===0) {
                            showToast({
                                type:'error',
                                message:"Please select atleast one drawing"
                            })
                            return
                        }

                        let data = {
                            title,
                            drawingCategory,
                            drawingNumber,
                            files,
                            relatedDrawingId,
                            revisionNumber,
                            file:files[0].url,
                            subCategory
                        }

                        patch(`project/drawing/drawing.update/${props.projectId}/${props.item.drawingId}`,data,(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"Drawing update successfully"
                                })
                                props.onClose();
                                props.init();
                            }
                        })
                    }} />
        </RightModal>
    )
}

export  default Edit;