import { Switch, Route } from 'react-router-dom';
// import ResetPassword from './ResetPassword';
import DashboardControl from './DashboardControl';

const Index = () => {

    return (
        <Switch>
            <Route path="/app/setting" component={DashboardControl} />
            {/* <Route path="/app/setting/reset" component={ResetPassword} exact /> */}
        </Switch>
    )
}

export default Index;