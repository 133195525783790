import mixpanel from 'mixpanel-browser';



class mixPanel {

    constructor(p) {
        this.company = {};
    }


    update = (val) => {
        this.company=val
    }
   

    init = (track,source={}) => {
        mixpanel.track(`${track}`,{
            ...source,
            'details':{...this.company}
        })
    }

}


const MixPanel = new mixPanel();

export {MixPanel}
