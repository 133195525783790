import { ThreeDots } from "react-loader-spinner";

const PageLoader = ({style}) => {
        return (
            <div style={{
                flex: 1,
                width: '100%',
                padding: 30,
                ...style
            }}>
                <div style={{
                    margin: '0 auto',
                    display:'flex',
                    justifyContent:'center'
                }}>
                    <ThreeDots
                        height="60"
                        width="60"
                        color="var(--theme-main-color)"
                    />
                </div>
            </div>
        )
    
}

export {
    PageLoader
}
