import { get } from "../../Utils/Crypto";



const useProject = () => {

    let project = get('PROJECT_DETAILS');

    return project;
}

export {useProject}