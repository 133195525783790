import { DownloadCsv } from "../../../../../../Utils/Downloader";
import { Upload } from "../../../../../Common/Upload"


const UploadTask = (props) => {



    if(!props.show) {
        return( <></>)
    }   

    return(
        <Upload
            onClose={props.onClose}
            onDownload={(data)=>{
              let baseData=[];
              baseData.push(["WbsItemName","Task Name","Task Category","Error message"]);
              data.forEach(val=>{
                baseData.push(
                  [
                    val.failedTasks.wbsitemname,
                    val.failedTasks.taskname,
                    val.failedTasks.taskcategory,
                    val.errorMsg
                  ]
                )
              })              

              DownloadCsv('error.csv',baseData)
            }}
            title="Upload"
            desc="Please upload your csv to create task Mapping"
            name="Select File"
            type="task"
            endPoint={`project/task/taskMapping.upload/${props.id}`}
            fileName="taskMapping.csv"
            csvData={[
              [
                "tasktype",
                "taskCategory",
                "taskname",
                "wbsitemname",
                "predecessortasks",
                "predecessorrelation",
                "predecessorandtype",
                "responsibleperson",
                "responsiblevendor",
                "approver",
                "lagday",
                "actualstartdate",
                "actualenddate",
                "plannedstartdate",
                "totaldaysrequired",
                "buffer",
                "totallaboursrequired",
                "fullkitting",
                "adhoctasks",
                "isrecurring",
                "recurringtype",
                "recurringvalue"
              ],
              [
                "Regular",
                "Services",
                "Plumbing",
                "Jewel Of India Phase -2.J Tower JOI2.J001",
                "Jewel Of India Phase -2.J Tower JOI2.J001.Plumbing",
                "FINISH",
                "AND",
                "SPPL-1452",
                "V001",
                "DIR-001",
                "-30",
                "10-09-2021",
                "20-09-2021",
                "20-09-2021",
                10,
                2,
                4,
                "NO",
                "TENDER",
                "YES",
                "WEEKLY",
                "Wed"
              ],
            ]}
            islink={true}
            onComplete={props.onComplete}
          />
    )
}

export default UploadTask