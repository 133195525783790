import { useEffect, useRef, useState } from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import AddCategory from './AddCategory';
import { get, post } from '../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../Common/PageLoader';
import { AccessButton, Button } from '../../../Common/Button';
import { RightModal } from '../../../Common/Modal/RightModal';
import { showToast } from '../../../Common/Toast/index';
import { Modal } from '../../../Common/Modal/Modal';
import { FunctionalInput } from '../../../Common/FloatInput/FunctionalInput';
import { useDictionaryValue } from '../../../Hooks/DictionaryHooks';
import { Header } from '../../../NCommon/Header';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { PageWrapper } from '../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import { Card } from '../../../NCommon/Ui/CardLayout';
import {List} from '../../../NCommon/Ui/List';
import {PaginationTable} from '../../../NCommon/Tables/PaginationTable';
import { useTableCount } from '../../../Hooks/TableCount';
import { TableMenu } from '../../../NCommon/Tables/TableMenu';
import { ViewTag } from '../../../NCommon/Tag';
import { AddTag } from '../../../Common/Tag/AddTag';


const Index = () => {

    const [loading,setLoading] = useState(true);
    const [addCategoryModal,setAddCategoryModal] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [filters,setFilters] = useState([]);
    const [sorted,setSorted] = useState('NAME')
    const [up,setUp] = useState(true);
    const [edit,setEdit] = useState(false);
    const [tag,setTag] = useState(false);
    const [updateId,setUpdateId] = useState(1);
    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    let NAME=useRef(),DETAILS=useRef();
    let HISTORY = useHistory();
    let getDictionaryValue = useDictionaryValue();
    let count = useTableCount();
  
    useEffect(()=>{
        INIT()
    },[count,up])

    let INIT = (data) => {
        if(!data) {
            data ={
                page:0
            }
        }
        if(data.filter) {
             data = {
                 ...data,
                 ...data.filter
             }
            delete data.filter
        }
        data.size = `${count}`
        data.offset = data.page.toString() ? (data.page*count).toString():"0";
        data.ascending = up;
        data.sortedBy = sorted;
        get(`project/wbs/wbsItemCategory.all`, (e, r) => {
            if (r) {
                setMData({
                    ...mData,
                    page:data.page,
                    total:r.total?r.total:data.total,
                    items:r.categories
                })
                setLoading(false);
            }
        },{
            ...data
        })
    }

    let next = (page) => {
        INIT({page})
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index} onClick={() => HISTORY.push(`/app/wbs/${item.id}?name=${item.name}`)}>
                <div style={{flex:1}}>
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.name}</p>
                </div>
                <div style={{flex:1}}>
                    <ViewTag update={updateId} id={item.id} type={'WBS_CATEGORY'} add={()=>{setTag(item.id)}}/>
                </div>
                <TableMenu items={[{name:'Edit',onClick:(e)=>{e.stopPropagation();setEdit(item)}},{name:'Delete',onClick:(e)=>{e.stopPropagation();setDeleteModal(item)}}]} />
            </List>

        )
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card isOpen={true} 
                  items={[{name:'Edit',onClick:(e)=>{e.stopPropagation();setEdit(item)}},{name:'Delete',onClick:(e)=>{e.stopPropagation();setDeleteModal(item)}}]}
                  key={index} 
                  onClick={() => HISTORY.push(`/app/wbs/${item.id}?name=${item.name}`)}
                  title={item.name} 
                  desc={item.details}>
                <div style={{marginTop:"auto"}}>
                    <div style={{borderTop:'0.5px solid var(--border-color)',height:22,marginLeft:-12,marginRight:-12,display:'flex',alignItems:'center'}}>
                        <ViewTag update={updateId} id={item.id} type={'WBS_CATEGORY'} add={()=>{setTag(item.id)}}/>
                    </div>
                </div>
            </Card>
        )
    }

    useEffect(()=>{
        if(edit) {
            NAME.current.value = edit.name;
            DETAILS.current.value = edit.details;
        }
      
    },[edit])

 

   let deleteItem = () => {
        axios.delete(`project/wbs/wbsItemCategory.delete?id=${deleteModal.id}`, {})
            .then(res => {
                setDeleteModal(false);
                setButtonLoading(false);
                INIT({page:mData.page});
                showToast({
                    type: 'success',
                    message: "Wbs Category delete successfully"
                })
            })
            .catch(res => {
                setButtonLoading(false);
                showToast({
                    type: 'error',
                    message: res.data.message
                })
            })
    }

    let handleSort = (sortType) => {
        if(sortType === sorted) {
            setUp(!up);
        } else {
            setSorted(sortType);
            setUp(true)
        }
    }

    return (
            <>
                <Header title={`${getDictionaryValue('wbs')} Category`} items={[]}/>
                {
                    loading?
                        <PageLoader />
                        :
                        <>
                            <HeaderWrapper dynamicFilter={true} filters={[...filters]} types={["Wbs category"]} 
                                onFilterChange={items=>{
                                    let filter = {};
                                    items.forEach(item=>{
                                        filter['name'] = item.label;
                                    })
                                    setFilters(items)
                                    INIT({page:0,filter})
                                }}
                            />
                          
                            <PageWrapper id="pagination-table-header" scroll={true}>
                                <NavigationWrapper items={[{name:`${getDictionaryValue('wbs')} category (${mData.total})`}]}>
                                    <AccessButton onClick={()=>{setAddCategoryModal(true)}} addsign={true?1:0} inverted={true?1:0} text="Create Category" style={{padding:'0px 10px'}}/>
                                </NavigationWrapper>
                                <PaginationTable
                                    DATA={mData.items}
                                    next={(page)=>{
                                        next(page)
                                    }}
                                    mapListLayout={mapListLayout}
                                    mapCardLayout={mapCardLayout}
                                    total={mData.total}
                                    page={mData.page}
                                    headers={[
                                        {
                                            name:'Details',
                                            flex:1,
                                            sort:sorted==='NAME',
                                            onSortClick:()=>handleSort('NAME'),
                                            up
                                        },
                                        {
                                            name:'Tag',
                                            flex:1
                                        },
                                        {
                                            name:'actions',
                                            width:50
                                        }
                                    ]}
                                />
                            </PageWrapper>

                        </>
                }
                {
                    deleteModal ?
                        <Modal
                            title={`Delete Wbs Category`}
                            desc={`Delete ${deleteModal.name}`}
                            show={deleteModal}
                            onClose={() => {
                                setDeleteModal(false);
                            }}
                            style={{
                                minHeight: 150
                            }}
                        >
                            <p style={{
                                marginTop: 50
                            }}>Are you sure want to delete <span style={{
                                fontWeight: 600
                            }}>
                                    {deleteModal.name}
                                </span> ? </p>

                            <Button
                                text="Delete"
                                style={{
                                    marginTop: 50,
                                    backgroundColor: 'var(--red-color)',
                                    borderColor: 'var(--red-color)'
                                }}
                                onClick={() => {
                                    setButtonLoading(true);
                                    deleteItem();
                                }}
                                loading={buttonLoading}
                            />
                        </Modal>
                        : null

                }


                <RightModal
                    title={`Edit ${edit.name}`}
                    desc="Edit the details of wbs category"
                    show={edit}
                    onClose={() => {
                        setEdit(false);
                    }}
                 >

                    <FunctionalInput type="text" ref={NAME} title="Name"/>
                    <FunctionalInput type="text" ref={DETAILS} title="Details" />
                    <Button
                        text="Update"
                        style={{
                            marginTop: 20
                        }}
                        loading={buttonLoading}
                        onClick={() => {
                            let name = NAME.current.value;
                            let details = DETAILS.current.value;

                            if (!name) {
                                showToast({
                                    type: 'error',
                                    message: 'Please enter the name'
                                })
                                return
                            }
                            if (!details) {
                                showToast({
                                    type: 'error',
                                    message: "please enter the details"
                                })
                                return
                            }

                            setButtonLoading(true);
                            post(`project/wbs/wbsItemCategory.update`, {
                                id: edit.id,
                                name,
                                details,
                                parentCategoryId: edit.parentCategoryId
                            }, (e, r) => {
                                if (r) {
                                    showToast({
                                        type: 'success',
                                        message: "Wbs Category Details update successfully"
                                    })
                                    INIT();
                                    setEdit(false);
                                    setButtonLoading(false);
                                }
                                if (e) {
                                    showToast({
                                        type: "error",
                                        message: e.data.message
                                    })
                                    setButtonLoading(false);
                                }
                            })
                        }}

                    />

                </RightModal>
                {
                    addCategoryModal ?
                        <AddCategory
                                onClose={()=>{setAddCategoryModal(false)}}
                                onComplete={() => {
                                    setAddCategoryModal(false);
                                    INIT()
                                }}
                            />
                        : null
                }

                {
                    tag?
                    <AddTag referenceId={tag} type="WBS_CATEGORY" init={()=>{
                        setTag(false);
                        setUpdateId(updateId+1)
                        INIT({page:mData.page})
                    }} close={()=>setTag(false)} />
                    :null
                }


            </>
        )

}

export default Index;