import { useState,useEffect} from 'react';
import _ from 'lodash';
import {useParams} from 'react-router-dom';
import { get, post } from '../../../../../Model/Network/Config/Axios';
import { useProject } from '../../../../Hooks/ProjectDetails';
import { useTableCount } from '../../../../Hooks/TableCount';
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { TaskListLayout } from '../../../../NCommon/ProjectTask/TaskItem';
import { PaginationTable } from '../../../../NCommon/Tables/PaginationTable';
import { PageLoader } from '../../../../Common/PageLoader';
import MarkFullKit from './SubTask/MarkFulkit';
import { Button } from '../../../../Common/Button';
import { showToast } from '../../../../Common/Toast';


const Index = () => {

    const [filters,setFilters] = useState([]);
    const [loading,setLoading] = useState(true);
    let [selectedTask,setSelectedTask] = useState([])

    const [mData,setMData] = useState({
        items:[],
        page:0,
        total:0
    });
    const [up,setUp] = useState(true);
    const [sorted,setSorted] = useState('PLANNED_START')
    let count = useTableCount();
    let {id} = useParams();
    let project = useProject();


    useEffect(()=>{
        INIT({page:0})
    },[count])

    useEffect(()=>{
        next({page:mData.page})
    },[sorted,up])


    let mapListLayout = (item,index) => {
        let active = _.includes(selectedTask,item.id);
        return(
            <TaskListLayout
             onCheckBoxClick={(e)=>{
                e.stopPropagation();
                let allSelectedTask = [...selectedTask];
                if(active) {
                    allSelectedTask = allSelectedTask.filter(i=>i!==item.id);
                } else {
                    allSelectedTask.push(item.id);
                }
                setSelectedTask(allSelectedTask);
            }} 
            fullkitting={true}
            item={item} 
            active={active}
            init={()=>{INIT({page:0})}}
            key={index}
           
            />
        )
    }


    let INIT = (data) => {
        if(!data) {
            data = {page:0}
        }
        data.size = `${count}`;
        data.offset = (data.page*count).toString();
        if(data.filter) {
            data.orFilters=[...data.filter];
            delete data.filter;
        }

        data.ascending = up;
        data.sortedBy = sorted;
        post(`project/task/fullKitting/pendingTasks/${id}`,data,(e,r)=>{
            if(r) {
                setMData({
                    items:r.tasksMappings,
                    total:r.total,
                    page:data.page
                })
                setLoading(false);
            }
        })
    }


    let next = ({page}) => {
        let items = [...filters];
        let filter = [];
        items.forEach(item=>{
            filter.push({
                key:item.type,
                val:item.value
              })
        })
        INIT({page,filter})
    }


    let handleSort = (key) => {
        if(key===sorted) {
            setUp(!up)
        } else {
            setSorted(key);
            setUp(true)
        }
    }

    let initSelectAll = () => {
        let filter = [];
        filters.forEach(item=>{
            filter.push({
                key:item.type,
                val:item.value
              })
        })
        initSelect({page:0,filter})
    }

    let initSelect = (data) => {
        if(!data) {
            data = {page:0}
        }
        if(data.filter) {
            data.orFilters=[...data.filter];
            delete data.filter;
        }
        setLoading(true);
        post(`project/task/fullKitting/pendingTasks/${id}`,data,(e,r)=>{
            if(r) {
               setSelectedTask(r.tasksMappings.map(val=>val.id));
               setLoading(false)
            } else {
                setLoading(false);
            }
        })
    }
    

    let sync = () => {
        setLoading(true);
        get(`project/task/fullKitting/runFullKittingAutomation/${id}`,(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:'Fullkitting manager Sync successfully'
                })
                setLoading(false);
            }
        })
    }

    if(loading) {
        return <PageLoader />
    }

    return(
        <>
          <HeaderWrapper 
              dynamicFilter={true} 
              types={['Name','Task',"Task Category",'Wbs Mapping','Responsible Person',"Custom"]} 
              filters={[...filters]} 
              onFilterChange={items=>{
                let filter = [];
                items.forEach(item=>{
                    filter.push({
                        key:item.type,
                        val:item.value
                      })
                })
                setFilters(items)
                INIT({page:0,filter})
            }}
            hideCard={true}

              />
          <PageWrapper defaultView={'list'} style={{paddingBottom:100}} id="pagination-table-header" scroll={true}>
                <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`FullKit Task (${mData.total})`}]} >
                    <Button text="Sync" onClick={sync} />
                </NavigationWrapper>
                <PaginationTable
                    DATA={mData.items}
                    total={mData.total}
                    page={mData.page}
                    mapCardLayout={()=>{}}
                    mapListLayout={mapListLayout}
                    next={(page)=>{
                        next(page)
                    }}
                    defaultView={'list'}
                    headers={
                        [
                            {
                                name:'checkbox',
                                width:25,
                                active:selectedTask.length===mData.total,
                                onClick:()=>{
                                    if(selectedTask.length===mData.total) {
                                        setSelectedTask([]);
                                    } else {
                                        initSelectAll();
                                    }
                                }
                            },
                            {
                                name:'Details',
                                width:400
                            },
                           
                            {
                                name:'Orignal Planned Start Date',
                                flex:1,
                                sort:sorted==='PLANNED_START',
                                onSortClick:()=>handleSort('PLANNED_START'),
                                up
                            },
                            {
                                name:'Original Planned End Date',
                                flex:1,
                                sort:sorted==='PLANNED_END',
                                onSortClick:()=>handleSort('PLANNED_END'),
                                up

                            },
                            {
                                name:'Estimated Start Date',
                                flex:1,
                                sort:sorted==='ESTIMATED_START',
                                onSortClick:()=>handleSort('ESTIMATED_START'),
                                up

                            },
                            {
                                name:'Estimated End Date',
                                flex:1,
                                sort:sorted==='ESTIMATED_END',
                                onSortClick:()=>handleSort('ESTIMATED_END'),
                                up

                            },
                            {
                                name:'Work Status',
                                flex:1
                            },
                            {
                                name:'Responsible Person',
                                flex:1
                            },
                           
                        ]
                    }
                />
          </PageWrapper>
          <MarkFullKit selected={selectedTask} onComplete={()=>{initSelectAll()}}  />

        </>
    )
}

export default Index;