import { useRef,useState } from "react";
import papa from 'papaparse';
import { TableSkeleton } from "../../../NCommon/TableView";
import { DownloadCsv } from "../../../../Utils/Downloader";
import { showToast } from "../../../Common/Toast";
import { RightModal } from "../../../Common/Modal/RightModal";
import { FunctionalFile } from "../../../Common/FloatInput/FunctionalFile";
import { Button } from "../../../Common/Button";
import axios from "../../../../Model/Network/Config/Axios";
import { Progress } from "../../../NCommon/ProgressBar";
import { MainModal } from "../../../NCommon/Modal";



const Index = (props) => {


    const [uploading,setUploading] = useState(false);
    const [start,setStart] = useState(false);
    const [length,setLength] = useState(0);
    const [completed,setCompleted] = useState(0);
    const [done,setDone] = useState(false);
    const [items,setItems] = useState([]);
    let FILEVALUE = useRef();

    let upload = () => {
        let file = FILEVALUE.current.getValue();
        if(!file) {
            showToast({
                type:'error',
                message:"Please select file"
            })
        } else {
            papa.parse(file, {
                download: true,
                delimiter: ",",	// auto-detect
                newline: "",
                header: true,
                transformHeader: undefined,
                dynamicTyping: false,
                preview: 0,
                encoding: "",
                worker: false,
                comments: false,
                complete: (result, file) => {
                   let final_sheet = []
                   final_sheet = result.data.map(val => {
                       let {name,vendor_code,email,mobile,address,city,pincode,state,gstin,benName,bankName,ifsc,account,pan,vpa} = val;                      
                       return {
                        name,
                        code:vendor_code,
                        email,
                        mobile,
                        address:{
                            address,
                            city,
                            state,
                            pincode,
                            gstin
                        },
                        bank:{
                            benName,
                            bankName,
                            ifsc,
                            account,
                            vpa
                        },
                        pan,
                        logo:""
                       }
                   })
                   console.log(final_sheet)
                   bulkUpload(final_sheet)
                   setUploading(true);
                },
                delimitersToGuess: [',', '\t', '|']
            })
        }
    }

    let bulkUpload =  (data) => {
        startUpload(data);  
        setStart(true);
        setLength(data.length);  
    }

    let startUpload = async  (data) => {
        let count = 0;
        let items = [];
        for(const item in data) {
         const res =  await init(data[item]);
         if(typeof res ==='string') {
            items.push({
                name:data[item].name,
                error:res
            })
         } else {
            count = res+count;
            setCompleted(count)
         }
        }
        setItems(items);
        setUploading(false);
        setDone(true);
        showToast({
            type:'success',
            message:"Resource Mapping successfully"
        })
    }

    let init = async (data) => {
      let res= await axios({
            method:'POST',
            url:`https://api.buildrun.app/main/admin/vendor/add`,
            data:{
                ...data
            }
        })
        .then(()=>{
            return 1;
        })
        .catch(e=>{
            return e.data.message;
        })
        return res;
      }


    let mapItem = (item,index) => {
        return(
            <div key={index} className="flex flex-v-center" style={{border:'1px solid var(--border-color)',fontSize:14,minHeight:45}}>
                <div style={{flex:1,paddingLeft:10,minHeight:'inherit',borderRight:'1px solid var(--border-color)'}} className="flex flex-v-center">
                    {item.name}
                </div>
                <div style={{flex:2,padding:10}} className="flex flex-v-center">
                    {item.error}
                </div>
            </div>
        )
    }

    let download = () => {
        let baseData = [[
            "Name",
            "Error"
        ]]

        items.forEach(val=>{
            baseData.push([
                val.name,
                val.error
            ])
        })

        DownloadCsv("Error.csv",baseData);
    }

    return(
        <MainModal width={`40%`} close={props.close} title="Upload" desc="Bulk Upload Resource Item" open={props.open}>
            <FunctionalFile ref={FILEVALUE} title="Upload Csv" />
            <Button loading={uploading} onClick={upload} style={{marginTop:20,width:350}} text="Upload" />
            <p style={{fontSize:12,marginTop:10,cursor:'pointer'}} onClick={()=>{
                let baseData = [["name","vendor_code","email","mobile","address","city","pincode","state","gstin","benName","bankName","ifsc","account","pan","vpa"]];

                DownloadCsv('vendor.csv',baseData)
            }}>Download Csv for format</p>
            <div style={{marginTop:20}}>
               {
                   start ?
                   <>  
                    <p style={{fontSize:14,marginBottom:10,fontWeight:600}}>Percentage Completed</p>
                    <Progress percentage={(completed*100)/length} />
                   </>
                   :null
               }
            </div>
            {
                done?
                <div className="flex" style={{marginTop:20}}>
                    <div  style={{width:200,height:80,borderRadius:4,border:'1px solid var(--border-color)',flexDirection:'column',padding:20}} className="flex  flex-h-center box-shadow">
                        <p style={{color:'var(--text-light-color)',fontSize:16}}>Total</p>
                        <p style={{fontWeight:'600',fontSize:28}}>{length} Items</p>
                    </div>
                    <div  style={{width:200,height:80,borderRadius:4,border:'1px solid var(--border-color)',flexDirection:'column',marginLeft:20,padding:20}} className="flex  flex-h-center box-shadow">
                        <p style={{color:'var(--text-light-color)',fontSize:16}}>Completed</p>
                        <p style={{fontWeight:'600',fontSize:28}}>{completed} Items</p>
                    </div>
                </div>
                :null
            }

           {items.length!==0?
           <>
            <div style={{display:'flex',justifyContent:'flex-end'}}>
                <div onClick={download} style={{fontSize:14,color:'var(--red-color)',fontWeight:'bold',cursor:'pointer'}}>
                    Download Report
                </div>
            </div>
            <TableSkeleton 
                    items={items}
                    mapItem={mapItem}
                    header={[
                    {
                        name:"Name",
                        style:{
                            flex:1
                        }
                    }, 
                    {
                        name:"Error",
                        style:{
                            flex:2
                        }
                    }
                ]} />   
            </>
            :null} 

        </MainModal>
    )
}

export default Index;
