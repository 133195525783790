import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Zoom from 'react-medium-image-zoom';
import { Button } from '../Button';
import {Modal} from '../Modal/Modal';
import { showToast } from '../Toast/index';
import { pdf } from '../Images';
import { FunctionalInput } from '../FloatInput/FunctionalInput';


const Attachment = (props) => {

    const [modal,setModal] = useState(false);
    const [files,setFiles] = useState(props.files?props.files:[]);
    const [buttonLoading,setButtonLoading] = useState(false);
    let Title = useRef(),file=useRef();


    useEffect(()=>{
        props.handleRef({
            getValue:()=>props.attachments,
            getFiles:()=>files,
        })
    },[files])

    let onChange = () => {
        let fileValue = file.current.files[0];

        if(props.addTitle) {
            if(!Title.current.value) {
                showToast({
                    type:'error',
                    message:"Please enter the title"
                })
                return;
            }
        }
        if(fileValue) {
            const formData = new FormData();
            formData.append('file', fileValue);

            setButtonLoading(true);
            axios({
                method: 'post',
                url: `https://api.buildrun.app/main/admin/auth/upload`,
                data: formData,
                
            })
                .then(res => {
                    if (res.data.status === 'success') {
                        let allFiles = files;
                        if(props.addTitle) {
                            Title.current.value=''
                            allFiles = [...files,{url:res.data.url,title:Title.current.value}]
                        } else {
                            allFiles = [...files,res.data.url]
                        }
                        setFiles(allFiles);
                        setButtonLoading(false);
                        setModal(false);
                        showToast({
                            type: 'success',
                            message: 'file upoad successfully'
                        })
                    }

                })
                .catch(res => {
                    setButtonLoading(false);
                    showToast({
                        type: 'error',
                        message: res.message
                    })
                })

        } else {
            if(!Title.current.value) {
                showToast({
                    type:'error',
                    message:"Please enter the title first"
                })
                return;
            } else {
                showToast({
                    type:'error',
                    message:"Please select the valid file"
                })
            }
           
        }
    }


    let deletes = (item) => {
        let allFiles = files;
        allFiles = allFiles.filter(val=>val.url !== item.url);
        setFiles(allFiles);
    }

   let mapItem = (item,index) => {
        let singleValue =  props.addTitle ? item.url:item;
        return(
            <div key={index} style = {{
                width:100
            }}>
                {
                    singleValue.toLowerCase().includes('pdf') ?
                    <div style={{marginRight:12}}>
                     <a href={singleValue} target="_blank">
                         <img src={pdf}  alt=""
                             style ={{
                             height:100,
                             width:100,
                             cursor:'pointer',
                             marginRight:12,
                             borderRadius:16
                             }}
                             key={index} />
                      </a>
                      <p onClick={()=>deletes(item)} style={{display:'block',textAlign:'right',fontSize:12,fontWeight:600,color:'red',cursor:'pointer'}}>Delete</p>
                     </div>
                    : 
                    <div style={{marginRight:12}}>
                    <Zoom>
                    <img
                      alt="buildrun"
                      src={singleValue}
                      style={{
                          height:100,
                          width:100,
                          borderRadius:8
                      }}
                    />
                  </Zoom>
                  <p onClick={()=>deletes(item)} style={{display:'block',textAlign:'right',fontSize:12,fontWeight:600,color:'red',cursor:'pointer'}}>Delete</p>
                  </div>
                }
            </div>
        )
    }


    return(
            <>
                <p style={{
                      fontSize: 12,
                      color: 'var(--title-color)',
                      fontWeight: 600,
                      marginTop: 15,
                      marginBottom:10
                }}>Attachment</p>
                <div style ={{
                    display:'flex',
                    flexWrap:'wrap',
                    flex:1
                }}>
                    {files.map(mapItem)}
                    <div style ={{
                         height:100,
                         border:'1px dashed var(--text-light-color)',
                         width:100,
                         borderRadius:16,
                        flexDirection:'column',
                        cursor:'pointer',
                        }} className="flex flex-v-center flex-h-center" onClick={()=>{
                            setModal(true);
                        }}>
                           <p style={{fontSize:22,paddingBottom:2,fontWeight:'600',color:'#fff',backgroundColor:'var(--theme-main-color)',height:25,width:25,borderRadius:32,display:'flex',justifyContent:'center',alignItems:'center'}}>+</p>
                    </div>

                </div>

                {
                    <Modal
                        title="Upload Image"
                        desc="select the image you want to upload"
                        show={modal}
                        onClose={()=>{
                            setModal(false);
                        }}
                        style={{
                            minHeight:200
                        }}
                    >
                        { props.addTitle ? <FunctionalInput title="Title" type="text" ref={Title} />: null}
                        <p style={{
                            fontSize: 12,
                            color: 'var(--title-color)',
                            fontWeight: 600,
                            marginBottom:10,
                            marginTop:30
                        }}>Select File</p>
                        <input 
                            type="file"
                            ref={ref=>file.current=ref} 
                            style={{
                            border:'1px solid var(--border-color)',
                            flex:1,
                            padding:'10px',
                            width:'100%',
                            boxSizing:'border-box'
                        }} onChange={onChange}  />

                        <Button
                            text="Close"
                            style ={{ marginTop:20  }}
                            onClick={()=>{setModal(false)}}
                            loading={buttonLoading}
                        />
                    </Modal>
                }
                
            </>
        )
}


Attachment.defaultProps = {
    files:[]
}


export {Attachment}


