import Moment from 'moment';
import { check } from "../../../../Common/Images";
import { TableMenu } from '../../../../NCommon/Tables/TableMenu';
import { List } from "../../../../NCommon/Ui/List";


const RequestItem = ({item,active,checkbox,tableItems,onClick}) => {

    return(
       <List>
           {checkbox?
            <div style={{width:25}} onClick={onClick}>
                {active?
                    <img  src={check} alt="" style={{height:18,width:18,marginTop:5}} />:
                    <div style={{height:16,width:16,border:'1px solid var(--border-color)'}} />
                 }
            </div>
            :null
           }
           <div className="flex-1">
                <p className="table-title">{item.inventoryDetails.name}</p>
                <p className="table-bottom">{item.resourceCategoryDetails.description}</p>
           </div>
           <div className="flex-1">
                <p className="table-text">{item.storeDetails.name}</p>
           </div>
           <div style={{flex:0.5}}>
                <p className="table-text">{item.qty.toFixed(2)} {item.inventoryDetails.units}</p>
           </div>
           <div style={{flex:0.5}}>
                <p className="table-text">INR {item.price}</p>
           </div>
           <div style={{flex:0.5}}>
                <p className="table-text">{Moment(item.deliveryDate).format('DD-MM-YYYY')}</p>
           </div>
           <TableMenu items={tableItems} />
       </List>
    )
}

const PastRequestItem = ({item,active,checkbox,tableItems,onClick}) => {

     return(
        <List onClick={onClick}>
            {checkbox?
             <div style={{width:25}} onClick={()=>{}}>
                 {active?
                     <img src={check} alt="" style={{height:18,width:18,marginTop:5}} />:
                     <div style={{height:16,width:16,border:'1px solid var(--border-color)'}} />
                  }
             </div>
             :null
            }
            <div className="flex-1">
                 <p className="table-title">{item.inventoryDetails.name}</p>
                 <p className="table-bottom">{item.resourceCategoryDetails.description}</p>
            </div>
            <div className="flex-1">
                 <p className="table-text">{item.storeDetails.name}</p>
            </div>
            <div style={{flex:0.5}}>
                 <p className="table-text">{item.qty} {item.inventoryDetails.units}</p>
            </div>
            <div style={{flex:0.5}}>
               <p className="table-text">{item.grnDone?'True':'False'}</p>
            </div>
            <div style={{flex:0.5}}>
                 <p className="table-text">INR {item.price}</p>
            </div>
            <div style={{flex:0.5}}>
                 <p className="table-text">{Moment(item.deliveryDate).format('DD-MM-YYYY')}</p>
            </div>
            <TableMenu items={tableItems} />
        </List>
     )
 }

RequestItem.defaultProps = {
    tableItems:[]
}

export {RequestItem,PastRequestItem}