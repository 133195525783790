import React from 'react';
import { downarrow } from '../Images';


class MultiSearchSelect extends React.Component {

    
    constructor(props){
        super(props);
        this.state = {
            shown:false,
            selected:[]
        }
    }

    componentDidMount() {
        if(this.props.handleRef){
            this.props.handleRef(this);
        }
        window.addEventListener('click',this.handleClick)
    }

    componentWillUnmount() {
        window.removeEventListener('click',this.handleClick)
    }


    handleClick = (e) => {
        if (this.props.items.length>0 && !this.click.contains(e.target)) {
            this.setState({
                ...this.state,
                shown:false
            })
        }  else if(this.click.contains(e.target)){
            this.input.focus();
            if(this.input.value.length===0) {
                this.props.onChange(" ");
                this.setState(({
                    ...this.state,
                    shown:true
                }))
            }
        }
    }
    

    mapItem=(item,index) => {
        return(
            <div key={index} style ={{
                backgroundColor:'rgba(0,0,0,0.2)',
                padding:'1px 10px',
                fontSize:11,
                borderRadius:2,
                display:'flex',
                alignItems:'center',
                marginRight:5,
                height:22,
                marginBottom:5,
                marginTop:5,
                marginLeft:5
            }}>
                <p style ={{
                    color:'#444'
                }}>{item.label}</p>

                <p style ={{
                    marginLeft:8,
                    fontWeight:700,
                    fontSize:20,
                    cursor:'pointer',
                    marginTop:-5
                }} onClick={()=>{
                    let selected = this.state.selected;
                    selected = selected.filter(val=>val.value !== item.value);
                    
                    if(this.props.onSelect) {
                        this.props.onSelect(selected)
                    }
                    this.setState({
                        ...this.state,
                        selected
                    })

                }}>x</p>
            </div>
        )
    }

    getValue = () => {
        return this.state.selected;
    }

    prefill = (value)=>{
        let selected = this.state.selected;
        if(value instanceof Array) {
            selected = selected.filter(val=>{
                value.forEach(item=>{
                    if(item.id!==val.id) {
                        return true
                    }
                    return false
                })
            })
             selected.push(...value)
         }
        else if(typeof value === 'object') {
            selected.push(value);
        }  else {
            selected.push(...value)
        }
        this.setState({
            selected
        })
    }

    render() {
        return(
            <div className="input" ref={ref=>this.click=ref} style={{position:'relative'}}>
                <p style={{
                    fontSize: 12,
                    color: 'var(--title-color)',
                    fontWeight: 600,
                    marginTop: 15
                }}>
                    {this.props.title} :
                </p>

                 <div  style={{
                    minHeight:30,
                    marginTop: 8,
                    width: '100%',
                    borderRadius: 4,
                    position:'relative',
                    border: '1px solid var(--border-color)',
                    width: '100%',
                    padding:'0px 5px',
                    boxSizing:'border-box'
                }} className="flex flex-v-center finput" onClick={this.props.onClick ? this.props.onClick : () => { }}>
                    
                 <div style ={{
                     position:'relative',
                     display:'flex',
                     flexWrap:'wrap',
                     minWidth:'0%'
                    }}>
                     {this.state.selected.map(this.mapItem)}
                </div>
                    <input ref={ref => this.input = ref} type={this.props.type} {...this.props.inputProps} style={{
                        height: 40,
                        borderRadius: 4,
                        fontSize: 16,
                        cursor:'pointer',
                        flex:1,
                        border:'none'
                    }}
                    placeholder={this.props.placeholder?this.props.placeholder:this.props.title}
                    onFocus={(e)=>{
                        e.target.placeholder=""
                    }}
                        onChange={e => {
                            if (this.props.onChange) {
                                this.props.onChange(e.target.value)
                                this.setState({
                                    ...this.state,
                                    shown:true
                                })
                            }
                        }}
                        value={this.props.value}
                        onClick={() => {
                            if (this.props.onClick) {
                                this.props.onClick()
                            }
                        }}
                        autoComplete="off"
                    />
                   
                    <div style={{
                        position:'absolute',
                        right:10
                    }}>
                        <img src={downarrow} alt="" style ={{
                            height:20
                        }} onClick={(e)=>{
                            e.preventDefault();
                            this.setState({
                                ...this.state,
                                shown:false
                            })
                        }} />
                    </div>
                </div>
                {this.props.desc?
                        <p style={{fontSize:13,marginTop:5,color:'var(--theme-main-color)'}}>** {this.props.desc}</p>
                        :null
                    }
            {
                this.props.items.length>0 && this.state.shown?
                    <div className="suggestion-modal" style={{top:68}}>
                            {this.props.items.map((item, index) => (
                                <div key={index} className="suggestion-modal-item" onClick={() => {
                                    let selected = this.state.selected;
                                    selected = selected.filter(val=>val.value !== item.value);
                                    selected.push(item);
                                    if(this.props.onSelect) {
                                        this.props.onSelect(selected)
                                    }
                                    this.setState({
                                        ...this.state,
                                        selected,
                                    })
                                    this.input.value = '';
                                }}>
                                    {item.label}
                                </div>
                            ))}

                        </div>
                        : 
                        null
            }

            </div>
        )
    }
}

MultiSearchSelect.defaultProps = {
    onFocus:false
}
export  {MultiSearchSelect}