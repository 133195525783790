import { Portal } from "../Portal";
import cross from './close.png';
import './index.css';



const CenterModal = (props) => {

    return(
        <Portal>
        <div className="half-modal-background" style ={{
            background:'rgba(0,0,0,0.4)',
            zIndex:10
        }}>
            <div className="half-center-modal" >
                <div style={{
                        position: 'relative'
                    }}>
                        <div style={{
                            height: 60,
                            borderBottom: '1px solid var(--border-color)',
                            padding: '10px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            position: 'fixed',
                            left:'2%',
                            right:'2%',
                            backgroundColor: '#fff',
                            zIndex: 1,
                            borderTopLeftRadius:16,
                            borderTopRightRadius:16,
                            backgroundColor:'#eff0f3'
                        }}>
                            <h2 style={{
                                    fontWeight: 700,
                                    color: 'var(--text-color)',
                                    fontSize: 18,
                            }}>{props.header}</h2>
                            <div className="cross-hover" style={{
                                height: 45,
                                width: 45,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }} onClick={() => {
                                props.onClose();
                            }} >
                                <img src={cross} alt="" style={{
                                    height: 18,
                                    width: 18,
                                    cursor: 'pointer',
                                    objectFit: 'contain'
                                }} />
                            </div>
                        </div>
                </div>
                {props.children}
            </div>
        </div>
        </Portal>
    )
}

export {CenterModal}