import {useLocation,Switch,Route,useParams} from 'react-router-dom';
import { Header } from '../../../NCommon/Header';
import All from './All';

const Index = () => {
    let route = useLocation().pathname;
    let {id} =useParams();
    return(
        <>
            <Header
                    items={[
                        {
                            name: 'All Drawing',
                            link: `/app/project/drawing/${id}`,
                            active: route === `/app/project/drawing/${id}`
                        }
                    ]}
            />
            <Switch>
                <Route path="/app/project/drawing/:id" component={All} exact />
            </Switch>
        </>
    )

}

export default Index;