import { useRef } from 'react';
import { Button } from '../../../Common/Button';
import { post } from '../../../../Model/Network/Config/Axios';
import { showToast } from '../../../Common/Toast';
import { FunctionalInput } from '../../../Common/FloatInput/FunctionalInput';
import { RightModal } from '../../../Common/Modal/RightModal';
import { ProjectCustomTag } from '../../../Common/CustomTagging/Project';



const AddTaskCategory = ({ onComplete,onClose }) => {

    let TaskCategoryName = useRef(), Details = useRef(),Custom=useRef();

    let _addTaskCategory = () => {
       Custom.current.getValue(cb=>{
        let taskCategoryName = TaskCategoryName.current.value;
        if (!taskCategoryName) {
            showToast({
                type: 'error',
                message: "Please enter the category name"
            })
            return;
        }
        let details = Details.current.value;
        if (!details) {
            showToast({
                type: 'error',
                message: "Please enter the description"
            })
            return;
        }

        let data = {
            taskCategoryName,
            details
        }

        if(cb && cb.length) {
            data.levelItemId = +cb[cb.length-1].levelId
        }

        post(`project/task/taskCategory.create`, data, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Category added successfully'
                })
                onComplete();
            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.message
                })
                
            }
        })
       })

    }


    return (
        <RightModal
            show={true}
            onClose={onClose}
            title="Add Task Category"
            desc="Fill up the details and add task category"
        >
            <ProjectCustomTag type="TASK_CATEGORY" handleRef={ref=>Custom.current=ref}    />
            <FunctionalInput type="text" title="Task Category Name" ref={TaskCategoryName} />
            <FunctionalInput type="text" title="Description" ref={Details} />

            <Button
                text="Add Task Category"
                style={{
                    marginTop: 35
                }}
                onClick={_addTaskCategory}
            />
        </RightModal>
    )
}


export default AddTaskCategory;