

const LineHeader = ({items,style}) => {
    return(
        <div style ={{
            display:'flex',
            marginBottom:20,
            borderBottom:'1px solid var(--border-color)',
            marginTop:20,
            ...style
        }}>
            {items.map((item,index)=>(
                <div 
                    key={index} 
                    style={{
                        marginRight:15,
                        marginBottom:0,
                        fontSize:15,
                        cursor:'pointer',
                        ...item.active ? {
                            color:'var(--theme-main-color)',
                            fontWeight:'600',
                            borderBottom:'2px solid var(--theme-main-color)',
                            paddingBottom:12
                        }: 
                        {
                            paddingBottom:14,

                        }
                    }} 
                    onClick={item.onClick}>
                    {item.name}
                </div>
            ))}
        </div>
    )
}


export {LineHeader}