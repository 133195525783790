import { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Moment from 'moment';
import { AccessButton, Button } from '../../../Common/Button';
import { post, get } from '../../../../Model/Network/Config/Axios';
import { Modal } from '../../../Common/Modal/Modal';
import { PageLoader } from '../../../Common/PageLoader';
import { set } from '../../../../Utils/Crypto';
import { FunctionalInput } from '../../../Common/FloatInput/FunctionalInput';
import { showToast } from '../../../Common/Toast';
import { useDictionaryValue } from '../../../Hooks/DictionaryHooks';
import { Header } from '../../../NCommon/Header';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { PageWrapper } from '../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import { Table } from '../../../NCommon/Tables';
import { Card } from '../../../NCommon/Ui/CardLayout';
import {List} from '../../../NCommon/Ui/List';




const ProjectMapping = (props) => {

    const [initial, setInitial] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState(null);
    const [count,setCount] = useState(0);
    const [days,setDays] = useState(undefined);
    const getDictionaryValue = useDictionaryValue();
    let DateValue = useRef(),EndDate=useRef(),Management=useRef();
    let HISTORY = useHistory();
    let { id } = useParams();

   

    const [pages] = useState([
        {
            name: `${getDictionaryValue('Wbs')} Item`,
            desc: `List of all ${getDictionaryValue('wbs')} item`,
            path: `/app/project/wbs/${id}`,
        },
        {
            name: `${getDictionaryValue('Task')} Details`,
            desc: `List of all ${getDictionaryValue('Task')}`,
            path: `/app/project/task/${id}`,
        },
        {
            name:'Fullkitting Manager',
            desc:"Full kitting manager",
            path:`/app/project/fullkitting/${id}`
        },
        // {
        //     name: `Snag  ${getDictionaryValue('Task')} Details`,
        //     desc: `List of all snag ${getDictionaryValue('Task')}`,
        //     path: `/app/project/snag/${id}`,
        // },
        {
            name: 'Adhoc Configuration',
            desc: 'Create adhoc task',
            path: `/app/project/adhoc/${id}`
        },
        {
            name: 'Images',
            desc: 'All Images of project',
            path: `/app/project/images/${id}/timeline`
        },
        {
            name:'Procurement',
            desc:"See all Procurement Request Here",
            path:`/app/project/procurement/${id}`
        },
        
        {
            name:'Inventory',
            desc:'All Inventory of project',
            path:`/app/project/inventory/${id}`
        },
        {
            name:'Resource',
            desc:'All resource of project',
            path:`/app/project/resource/${id}`
        },
        {
            name:'Analytics & Reports',
            desc:'All analytics and reports of project',
            path:`/app/project/analytics/${id}`
        },
        // {
        //     name:'Attendance',
        //     desc:'attendance',
        //     path:`/app/project/attendance/${id}`
        // },
        {
            name:'Holiday',
            desc:'List of holiday',
            path:`/app/project/holiday/${id}`
        },
        {
            name:'Drawing',
            desc:'All drawing of project',
            path:`/app/project/drawing/${id}`
        },
        {
            name:"Hindrance Mapping",
            desc:"All hindrance mapping",
            path:`/app/project/hindrance/${id}`
        },
        {
            name:"Attendance",
            desc:"All Days Attendance",
            path:`/app/project/attendance/${id}`
        }
       
    ])

    useEffect(() => {
       INIT();
    }, [])

    let INIT=  () => {
        get(`project/details/${id}`, (e, r) => {
            if (r) {
                set('PROJECT_DETAILS',r)              
                setDetails(r);
                setLoading(false);
            }
        })
    }

    useEffect(()=>{

        if(initial) {
            let days = Moment(details.clientEndDate).diff(details.managementEndDate,'days')
            setDays(days);
            Management.current.value = days
        }
    },[initial])

    let mapCardLayout = (item,index) => {
        return(
            <Card height={120} onClick={()=>{HISTORY.push(item.path)}} title={item.name} desc={item.desc} key={index} />
        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index} onClick={()=>HISTORY.push(item.path)}>
                <div className="flex-1">
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.desc}</p>
                </div>
            </List>
        )
    }


    let initiate = () => {

        let value = DateValue.current.value;
        if(!value) {
            showToast({
                type:'error',
                message:'Please select the start date'
            })
            return;
        }

        
        let clientEndDate = Moment(EndDate.current.value).valueOf();;
        if(!clientEndDate) {
            showToast({
                type:'error',
                message:"Please select the client end date"
            })
            return;
        }
        let managementEndDate = +Management.current.value;
        if(EndDate) {
            clientEndDate = Moment(EndDate.current.value).valueOf();
        }

        if(managementEndDate && clientEndDate) {
            managementEndDate = Moment(clientEndDate).subtract(managementEndDate,'days').valueOf();
        }
        
        setButtonLoading(true);
        post(`/project/initialize/${id}`, {
            startDate: Moment(value).valueOf(),
            criticalPathFactor: 0,
            clientEndDate,
            managementEndDate
        }, (e, r) => {
            if (r) {
                showToast({
                    type:'success',
                    message:"Project initialize successfully"
                })
                setInitial(false);
                INIT();
            }
            setButtonLoading(false);
        })
    }

    if (loading) {
        return <PageLoader />
    }
    return (
        <>
          <Header items={[]} title="Modules" />
          <HeaderWrapper />
          <PageWrapper>
              <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${details.name}`}]}>
                    <AccessButton
                        place="top"
                        data-tip="Change start date of project and resync"
                        text="Reschedule"
                        onClick={
                            () => {
                                setInitial(true)
                            }
                        }
                        inverted={true?1:0}
                    />
                    <Button
                        text="Setting"
                        onClick={() => {
                            props.history.push(`/app/project/setting/${id}`)
                        }}
                        style={{
                            marginLeft: 10,
                            height:38
                        }}
                        inverted={true?1:0}
                    />
              </NavigationWrapper>
              <Table
                    DATA={pages}
                    mapCardLayout={mapCardLayout}
                    mapListLayout={mapListLayout}
                    headers={[
                        {
                            name:'Details',
                            flex:1
                        },
                        {

                        }
                    ]}
                    pagination={false}
              />
          </PageWrapper>
          
            <Modal
                show={initial}
                title="Initiate Project"
                desc="By clicking button below initiate project"
               
                onClose={() => {
                    setInitial(false);
                }}
            >
                <div>
                    <FunctionalInput desc={details.startDate?`Current Value is ${Moment(details.startDate).format('DD-MM-YYYY')}`:''} type="date" ref={DateValue} title="Start Date" />
                    <FunctionalInput desc={details.clientEndDate?`Current Value is ${Moment(details.clientEndDate).format('DD-MM-YYYY')}`:''} defaultValue={details.clientEndDate?Moment(details.clientEndDate).toDate():undefined} onChange={()=>{setCount(count+1)}} type="date" ref={EndDate} title="Client End Date (optional)" />
                    <FunctionalInput onChange={val=>setDays(val)} title="Management Buffer Days"  ref={Management} desc={`${days && EndDate.current && EndDate.current.value ? `Management End Date is ${Moment(EndDate.current.value).subtract('days',days).format('DD-MM-YYYY')}`:details.clientEndDate && details.estimatedFinishDate ? `Current Value is ${Moment(details.clientEndDate).diff(details.managementEndDate,'days')} days`:``}`} />

                    <Button
                        text="Initiate"
                        style={{marginTop: 30 }}
                        loading={buttonLoading}
                        onClick={initiate}
                    />
                </div>
            </Modal>
           
        </>
    )
}

export default ProjectMapping;