import {useParams} from 'react-router-dom';
import { TimeLine } from '../../../../Common/TimeLine';
import { useProject } from '../../../../Hooks/ProjectDetails';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';

const Index = () => {
    let {id} = useParams();
    let project = useProject();
    return(
        <PageWrapper style={{height:'calc(100vh - 50px)'}}>
             <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:'Timeline'}]} />
             <TimeLine url={`/project/wbs/photos/common/${id}`} />
        </PageWrapper>
    )
}

export default Index;