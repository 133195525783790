import { PieGraph } from "../../../../../Common/Graphs";
import Container from "../../../../../Common/Graphs/Container"



const TaskPieGraph = ({task}) => {

    let keys = ["Total","Completed","Progress"];
    let values = [];
    values.push(task["total"])
    values.push(task["completed"])
    values.push(task["started"])


    return(
        <Container>
            <PieGraph 
                height={400} title="By Tasks"                             // Chart by Tasks
				data={values}                               
				items={keys}
					/> 
        </Container>
    )
}

export default TaskPieGraph;