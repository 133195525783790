import  { useRef, useState } from 'react';

/* Api */
import { post } from '../../../../../../Model/Network/Config/Axios';

/* common */
import { Button } from '../../../../../Common/Button';
import { FunctionalInput } from '../../../../../Common/FloatInput/FunctionalInput';
import { RightModal } from '../../../../../Common/Modal/RightModal';
import { showToast } from '../../../../../Common/Toast';




const AddRole = (props) => {
    

    const [buttonLoading,setButtonLoading] = useState(false);
    let Title= useRef(),Description=useRef();

    let addRole = () => {
        let title = Title.current.value;
        let description = Description.current.value;

        if (!title) {
            showToast({
                type:'error',
                message:'Please enter the title of role'
            })
            return;
        }
        if (!description) {
            showToast({
                type:'error',
                message:"Please enter the description of role"
            })
            return;
        }
        setButtonLoading(true);

        post(`roles/add`, { name: title, description }, (e, r) => {
            if (r) {
                showToast({
                    type:'success',
                    message:"Role added successfully"
                })
                props.onSubmit();
            }else {
               showToast({
                   type:'error',
                   message:e.data.message
               })
            }
            setButtonLoading(false);
        })
    }

    return (
            <RightModal
               title="Create Roles"
               desc="Fill Up the details and add create roles in the organisation"
               show={true}
               onClose={props.onClose}
                >
               
                <FunctionalInput title="Title" type="text" ref={Title} style={{marginRight:20}} />
                <FunctionalInput title="Description" type="text" ref={Description} />
                
                <Button
                    style={{
                        marginTop:30
                    }}
                    text="Add Role"
                    loading={buttonLoading?1:0}
                    onClick={addRole}
                />

            </RightModal>
        )
    
}

export default AddRole;