import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom'
import { get, post } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import { showToast } from '../../../../Common/Toast';
import { useProject } from '../../../../Hooks/ProjectDetails';
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { Card } from '../../../../NCommon/Ui/CardLayout';



const Index = () => {

    let {id} = useParams();
    const project = useProject();
    const [app,setApp] = useState({});
    const [loading,setLoading] = useState(true);
    const [apps,setApps] = useState([]);

    useEffect(()=>{
        get(`marketplace/availableApps`,(e,r)=>{
            if(r) {
                setApp(r.availableApps);
                setLoading(false);
            }
        })
        get(`marketplace/installedApps/${id}`,(e,r)=>{
            if(r) {
                setApps(r.apps);
            }
        })
    },[])


    let mapApp = (i,index) => {
        let item = app[i];
        return(
            <Card key={index} title={item.name} desc={item.description}>
                <div onClick={()=>{
                    post(`marketplace/installApp/${id}`,{app:i,companyCode:'NYKAATEST'},(e,r)=>{
                        if(r) {
                            showToast({
                                type:'success',
                                message:'App Install Successfully'
                            })
                        } else {
                            showToast({
                                type:'error',
                                message:e.data.message
                            })
                        }
                    })
                }} style={{fontSize:14,fontWeight:'600',marginTop:5,color:'var(--theme-main-color)'}}>Install</div>
            </Card>
        )
    }

    let mapInstallApp = (i,index) => {
        return(
            <Card key={index} title={i.appDetails.name} desc={i.appDetails.description}>
                <p className="single-line" style={{fontWeight:'400',fontSize:12,color:'var(--text-light-color)'}}>ClientId : {i.clientId}</p>
                <p className="single-line" style={{fontWeight:'400',fontSize:12,color:'var(--text-light-color)'}}>Client Secret : {i.clientSecret}</p>

            </Card>
        )
    }

    if(loading) {
        return <PageLoader />
    }

    return(
        <>
          <PageWrapper style={{height:'calc(100vh - 50px)'}}>
              <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`Connector`}]} />
              <p style={{fontSize:16,fontWeight:'600',marginTop:10,marginBottom:10}}>Installed Apps</p>
               <div className="flex flex-wrap">
                    {apps.map(mapInstallApp)}
               </div>
               <p style={{fontSize:16,fontWeight:'600',marginTop:10,marginBottom:10}}>All Apps</p>
              <div className="flex flex-wrap">
                    {Object.keys(app).map(mapApp)}
              </div>
          </PageWrapper>
        </>
    )
}

export default Index;