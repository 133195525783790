import { useSelector,useDispatch } from "react-redux"
import { AuthActions } from "../../../Redux/Reducers";
import { set } from "../../../Utils/Crypto";
import { useTableCount } from "../../Hooks/TableCount";
import TableLayout from './PaginationTableLayout';



const PaginationTable = ({DATA,next,page,total,mapCardLayout,mapListLayout,headers,pagination,defaultView,tableStyle}) => {

    let view = useSelector(config=>config.config.tableview)
    view = defaultView ? defaultView:view;
    let dispatch = useDispatch();
    let count = useTableCount();
    let start = (page*count)+1;
    let end = (page*count)+Number(count)

    if(end>total) {
        end = total;
    }
 
    let type = () => {
        if(view === 'card') {
            return(
                <div style={{display:'flex',flexWrap:'wrap',marginTop:15}}>
                    {DATA.map(mapCardLayout)}
                </div>
            )
        } else if(view === 'list') {
            return(
                <TableLayout 
                    data={DATA}
                    mapItem={mapListLayout}
                    headers={headers}
                    tableStyle={tableStyle}

                />
            )
        }
    }

    return(
        <>
            {type()}
            {pagination?
            <div style={{height:50,marginTop:10,background:'',margin:10,display:'flex',alignItems:'center',justifyContent:'space-between',padding:10}}>
                <div style={{fontSize:14}}>
                    Data Per Page 
                    <select onChange={e=>{
                        let value = e.target.value;
                        set('table-count',value)
                        dispatch({
                            type:AuthActions.Count,
                            data:value
                        })
                    }} value={count} style={{marginLeft:10}}>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={150}>150</option>
                        <option value={200}>200</option>
                    </select>
                </div>
                <div style={{fontSize:15}}>
                    Showing <span style={{fontWeight:'600',marginLeft:5}}>{start}</span> - <span style={{fontWeight:'600',marginRight:5}}>{end}</span> of <span style={{fontWeight:600}}>{total}</span>
                    <span style={{
                        background:'#fff',
                        margin:'0px 5px',
                        padding:"3px 15px",
                        fontWeight:'600',
                        borderRadius:2,
                        cursor:'pointer'
                    }} onClick={()=>{
                        console.log(page);
                       if(page>0) {
                           next(page-1)
                       }
                    }}>Prev</span>
                    <span style={{
                        background:'#fff',
                        margin:'0px 5px',
                        padding:"3px 15px",
                        fontWeight:'600',
                        borderRadius:2,
                        cursor:'pointer'
                    }} className="box-shadow" onClick={()=>{
                       if(total>end) {
                           next(page+1)
                       }
                    }}>Next</span>
                </div>
               
            </div>
            :null}
        </>
    )
}

PaginationTable.defaultProps = {
    pagination:true,
    DATA:[],
    tableStyle:{}
}

export {PaginationTable}