import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";



const Index = () => {
    
    return(
        <>
            <HeaderWrapper />
        </>
    )
}

export default Index;