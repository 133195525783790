import { useState,useEffect, useRef } from "react";
import Moment from 'moment';
import { RefObject } from "../../../Utils/Refrence";
import right from './next.png';



const CalendarView = ({calendarView}) => {

    
    let CalendarRef = RefObject.getRef();
    let [date,setDate] = useState(Moment());


    useEffect(()=>{ 
        if(CalendarRef) {
            console.log(CalendarRef)
            setCurrentDate(CalendarRef.getDate());
        }
    },[CalendarRef])


    let setCurrentDate = (date) => {
        setDate(date)
    }



    let type = () => {
        if(calendarView) {
            return(
                <div style={{padding:'0px 10px',display:'flex',alignItems:'center'}}>
                    <p style={{cursor:"pointer",paddingRight:10}} onClick={()=>{
                        CalendarRef.today();
                        setCurrentDate(CalendarRef.getDate());
                        }}>Today</p>
                    <div style={{borderLeft:'1px solid var(--border-color)',borderRight:'1px solid var(--border-color)',padding:'0px 10px'}}>
                        <img src={right} onClick={()=>{
                            CalendarRef.prev();
                            setCurrentDate(CalendarRef.getDate());
                            }} alt="" style={{height:12,cursor:'pointer',transform:'rotate(180deg)'}} />
                        <img src={right} alt="" onClick={()=>{
                            CalendarRef.next();
                            setCurrentDate(CalendarRef.getDate());
                        }} style={{height:12,cursor:'pointer',marginLeft:10}} />
                    </div>
                    <div style={{padding:'0px 10px'}}>
                        {Moment(date).format('MMM YYYY')}
                    </div>
                </div>
            )
        }
    }

    return(
        <>
            {type()}
        </>
    )
}

export default CalendarView