import {useHistory} from 'react-router-dom';
import { connect } from 'react-redux';
import data from './data';
import { WithModule } from '../../../Utils/Module';
import forward from './forward.png';
import buildrun from '../Images/buildrun_logo2.png';
import { MixPanel } from '../../Hooks/Mixpanel';
import { useDictionary } from '../../Hooks/DictionaryHooks';

const Index = (props) => {

   let HISTORY = useHistory();
   let dictionary = useDictionary();
   let  mapItem = (item, index) => {
        if (item.type === 'title' && props.expanded) {
            return (
                <div style={{
                    fontWeight: 800,
                    fontSize: 10,
                    marginBottom: 10,
                    paddingLeft: 30,
                    color: 'var(--sidebar-inactive-color)',
                    textTransform: 'uppercase',
                    letterSpacing: 0.5,
                    marginTop:20
                }} key={index}>
                    {item.name}
                </div>
            )
        }
        if (item && item.developmentMode) {
            return;
        }
        if (!WithModule(props.config.config.modules, item.module)) {
            return;
        }
        let active = props.location.pathname.includes(item.link);

        return (
            <div style={{
                cursor: 'pointer',
                height: 50,
                display: 'flex',
                alignItems: 'center',
                ...active ? {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: 12
                } : {

                    }
            }} className={`sidebar-single-item  ${active ? 'sidebar-single-active' : 'sidebar-single-inactive'}`} key={index} onClick={() => {
                MixPanel.init(item.event)
                HISTORY.push(item.link)
                if (item.onClick) {
                    item.onClick();
                }
            }} >
                <div className={`sidebar-image ${item.cname}`} style={{marginLeft:22}} />
                {
                  props.expanded ?
                        <p style={{
                            fontSize: 13,
                            paddingLeft: 13,
                            fontWeight: 600,
                            letterSpacing:1.2,
                            ...active ?
                                {
                                    color: 'var(--sidebar-active-color)',
                                    fontWeight: 700
                                } : {
                                    color: 'var(--sidebar-inactive-color)'
                                }
                        }}>{dictionary[item.name]?dictionary[item.name]:item.name}</p>
                        : null
                }
            </div >
        )
    }


        return (
            <div className={`sidebar ${props.expanded ? 'expand' : 'minimize'}`} style={{
                position: 'relative',
                ...props.expanded ? {
                 
                    background:"#212121",
                }:
                {
                 
                }
            }}>
                <div style ={{
                    height:100,
                    display:'flex',
                    paddingLeft:props.expanded ?30:20,
                    alignItems:'center'
                }}>
                  <img src={buildrun} alt="" style={{height:45}} />
                  {props.expanded ?
                  <p style={{
                      color:'#fff',
                      marginTop:20,
                      fontSize:21,
                      fontWeight:'900',
                      textTransform:'uppercase',
                      marginLeft:15,
                      letterSpacing:'1px'
                  }}>
                      Buildrun
                  </p>:null}
                </div>
                {data.map(mapItem)}
                <div>

                </div>
                <div className="hide-bar" style={{height:60,background:'#212121'}} onClick={props.onClick}>
                    {props.expanded ?
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <img src={forward} alt="" style={{
                                height: 20,
                                width: 20,
                                transform: 'rotate(180deg)'
                            }} />
                            <p style={{
                                marginLeft: 10,
                                fontWeight: 600
                            }}>Collapse Sidebar</p>
                        </div>
                        :
                        <p>
                            <img src={forward} alt="" style={{
                                height: 20,
                                width: 20
                            }} />
                        </p>
                    }
                </div>
            </div>
        )
    
}



const mapStateToProps = (state) => {
    return {
        config: state.config
    }
}


const Sidebar = connect(mapStateToProps, null)(Index);


export { Sidebar };