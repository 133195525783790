import React from "react"
import { SearchSelect } from "../Search/SearchSelect/SearchSelect"


class DaysList extends React.Component {

    
componentDidMount() {
    if(this.props.handleRef) {
        this.props.handleRef(this);
    }
}


setValue = (value) => {
    this.select.setValue({value})
}

render() {
    let items = [];
    for(let i=1;i<=28;i++) {
        if(i<10) {
            items.push({
                 key:`0${i}`,
                value:`0${i}`
            })
        } else {
            items.push({
                key:`${i}`,
                value:`${i}`
            })
        }   
    }
    return(
        <>
        <SearchSelect   
            handleRef={ref=>this.select=ref}
            title="Select Day" 
            onChange={(val)=>{this.props.select(val)}} items={items} />
        </>
    )
    }
}

export {DaysList}