import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import { Button } from '../../../../Common/Button';
import { useDictionaryValue } from '../../../../Hooks/DictionaryHooks';
import { useProject } from '../../../../Hooks/ProjectDetails';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import {  TaskListLayout } from '../../../../NCommon/ProjectTask/TaskItem';
import { PaginationTable } from '../../../../NCommon/Tables/PaginationTable';
import { post } from '../../../../../Model/Network/Config/Axios';
import BulkDelete from './BulkDelete/index';
import ChangePredecessor from './SubTask/ChangePredecessor';
import { TaskParent } from './SubTask/TaskParent';
import { PageLoader } from '../../../../Common/PageLoader';



const Index = ({data,init,filters,up,sorted,setUp,setSorted,status}) => {

    let {id} = useParams();
    let details = useProject();
    let getDictionaryValue  = useDictionaryValue();
    let [selectedTask,setSelectedTask] = useState([])
    let [loading,setLoading] = useState(false);
    let [addTask,setAddTask] = useState(false)
    let [changePredecessor,setChangePredecessor] = useState(undefined);



    useEffect(()=>{
       next();
    },[up,sorted])

    let next = (page) => {
        if(!page) {
            page = 0
        }
        init({page})
    }

    let INITALLDATA = (data) => {
        if(!data) {
            data = {page:0}
        }
        let otherNodes = [];

        if(data.filter) {
            data.orFilters=[...data.filter.filter(item=>item.key!=='NAME')];
            let NAMEFILTER = data.filter.filter(item=>item.key==='NAME');
            otherNodes = [...NAMEFILTER]
            delete data.filter;
           
        }
        status.forEach(val=>{
            if(val.active) {
              otherNodes.push({key:val.key,val:''})
            }
        })

        if(otherNodes.length) {
            data.andFilters=otherNodes
        }
        setLoading(true);
        post(`project/task/taskMapping/${id}`,data,(e,r)=>{
            if(r) {
               setSelectedTask(r.tasksMappings.map(val=>val.id));
               setLoading(false)
            } else {
                setLoading(false);
            }
        })
    }

    let INIT = () => {
        let filter = [];
        filters.forEach(item=>{
            filter.push({
                key:item.type,
                val:item.value
              })
        })
        INITALLDATA({page:0,filter})
    }
    


    let mapListLayout = (item,index) =>{
        let active = _.includes(selectedTask,item.id);
        return(
            <TaskListLayout
             onCheckBoxClick={(e)=>{
                e.stopPropagation();
                let allSelectedTask = [...selectedTask];
                if(active) {
                    allSelectedTask = allSelectedTask.filter(i=>i!==item.id);
                } else {
                    allSelectedTask.push(item.id);
                }
                setSelectedTask(allSelectedTask);
            }} 
            item={item} 
            active={active}
            init={()=>{init({page:0})}}
            key={index}
            onClickPredecessor={(e)=>{
                e.stopPropagation();
                setChangePredecessor(item)
            }}
            />
        )
    }

    let handleSort = (key) => {
        if(key===sorted) {
            setUp(!up)
        } else {
            setSorted(key);
            setUp(true)
        }
    }

    if(loading) {
        return <PageLoader />
    }

    return(
       <>
        <PageWrapper defaultView={'list'} style={{paddingBottom:100}} id="pagination-table-header" scroll={true}>
                <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${details.name}`,path:`/app/project/mapping/${id}`},{name:`${getDictionaryValue('Task')} (${data.total})`}]}>
                    <Button onClick={()=>{setAddTask(true)}} inverted={true?1:0} text={`Add ${getDictionaryValue('task')}`} addsign={true?1:0} style={{padding:'0px 10px',marginRight:10}}  />
                </NavigationWrapper>
                <PaginationTable
                    DATA={data.items}
                    total={data.total}
                    page={data.page}
                    mapCardLayout={()=>{}}
                    mapListLayout={mapListLayout}
                    next={(page)=>{
                        next(page)
                    }}
                    defaultView={'list'}
                    tableStyle={{
                    }}
                    headers={
                        [
                            {
                                name:'checkbox',
                                width:25,
                                active:selectedTask.length===data.total,
                                onClick:()=>{
                                    if(selectedTask.length===data.total) {
                                        setSelectedTask([]);
                                    } else {
                                        INIT();
                                    }
                                }
                            },
                            {
                                name:'Details',
                                width:400
                            },
                           
                            {
                                name:'Orig. Planned Start Date',
                                flex:1,
                                sort:sorted==='PLANNED_START',
                                onSortClick:()=>handleSort('PLANNED_START'),
                                up
                            },
                            {
                                name:'Orig. Planned End Date',
                                flex:1,
                                sort:sorted==='PLANNED_END',
                                onSortClick:()=>handleSort('PLANNED_END'),
                                up

                            },
                            {
                                name:'Esti. Start Date',
                                flex:1,
                                sort:sorted==='ESTIMATED_START',
                                onSortClick:()=>handleSort('ESTIMATED_START'),
                                up

                            },
                            {
                                name:'Esti. End Date',
                                flex:1,
                                sort:sorted==='ESTIMATED_END',
                                onSortClick:()=>handleSort('ESTIMATED_END'),
                                up

                            },
                            {
                                name:'Work Status',
                                flex:1
                            },
                            {
                                name:'Assign',
                                flex:0.5
                            },
                            {
                                name:'Vendor',
                                flex:0.5
                            },
                            {
                                name:'Tag',
                                flex:0.5
                            }
                           
                        ]
                    }
                />
        </PageWrapper>
        <BulkDelete selected={selectedTask} onComplete={()=>{next(0);setSelectedTask([])}} />
        <TaskParent show={addTask} id={id} onClose={()=>{setAddTask(false)}} onComplete={()=>{setAddTask(false);init({page:0})}} />
        <ChangePredecessor show={changePredecessor?true:false}  predecessor={changePredecessor? changePredecessor.predecessorId ? changePredecessor.predecessorId : true:true} id={id} taskMappingId={changePredecessor} close={() => {setChangePredecessor(undefined);init({page:0}) }}/>
      </>
    )
}



export default Index;