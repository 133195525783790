import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Sidebar } from '../Common/Sidebar/index';
import { PageLoader } from '../Common/PageLoader';
import ReactToolTip from 'react-tooltip';
import Project from './Project';
import Employee from './Employees';
import Setting from './Setting';
import Policy from './Policy';
import Wbs from './Wbs';
import Task from './Task';
import Vendor from './Vendor';
import ProjectTaskDetails from './Project/ProjectTaskDetails'
import AllTask from './AllTaskNew';
import { get, set } from '../../Utils/Crypto';
import AccessManagement from './Others/AccessManagement';
import Others from './Others';
import Adhoc from './Adhoc';
import '../Common/index.css';
import 'react-medium-image-zoom/dist/styles.css'
import mixpanel from 'mixpanel-browser';


const Index=(props) => {

    const [expanded,setExpanded] = useState(false);
    useEffect(()=>{
        let expand = get(`expanded`);
        if(expand) {
            expand = true;
            set('expanded', true)
        }
        setExpanded(expand);
    },[])

    useEffect(()=>{
        mixpanel.init('899742c4468b17f03efc74006d11b375',{debug:false})
        mixpanel.track('dashboard init',{
            'source':props.config.name
        })
    },[])


    if (!props.config.config) {
        return <PageLoader />
    }

    return (
            <div style={{
                display: 'flex',
                flex: 1
            }}>
                <ReactToolTip />
                <Sidebar
                    {...props}
                    expanded={expanded}
                    onClick={() => {
                        set('expanded', !expanded)
                        setExpanded(!expanded);
                    }}
                />
               
                <div style={{height: '100vh',flex:1,overflowY:'auto'}}>
                    <Switch>
                        <Route path="/app/project/task/:projectId/:id/details" component={ProjectTaskDetails} />
                        <Route path='/app/project/task/:projectId' component={AllTask} />
                        <Route path="/app/project" component={Project} />
                        <Route path="/app/employee" component={Employee} />
                        <Route path="/app/wbs" component={Wbs} />
                        <Route path="/app/policy" component={Policy} />
                        <Route path="/app/setting" component={Setting} />
                        <Route path="/app/task" component={Task} />
                        <Route path="/app/vendor" component={Vendor} />
                        <Route path="/app/others/access" component={AccessManagement} />
                        <Route path="/app/others" component={Others} />
                        <Route path="/app/adhoc" component={Adhoc} />
                    </Switch>
                </div>
            </div>
        )
}

export default connect(state => {
    return {
        config: state.config
    }
}, null)(Index);