import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import Moment from 'moment';
import { BottomHeader } from '../../../../Common/TopHeader/BottomHeader';
import { SearchSelect } from '../../../../Common/Search/SearchSelect/SearchSelect';
import { EmployeeSearch } from '../../../../Common/Search/SearchEmployee';
import { FloatInput } from '../../../../Common/FloatInput';
import { Button } from '../../../../Common/Button';
import { post } from '../../../../../Model/Network/Config/Axios';
import { showToast } from '../../../../Common/Toast';
import { SearchTaskMappingItemN } from '../../../../Common/Search/SearchTaskMappingItemN';




class Index extends React.Component {


    constructor(props) {
        super(props);
        let { details } = this.props;
        details = details.tasksMapping;
        this.state = {
            date1: details.actualStartDate ? Moment(details.actualStartDate).format('DD-MM-YYYY') : undefined,
            date2: details.actualCompletionDate ? Moment(details.actualCompletionDate).format('DD-MM-YYYY') : undefined
        }
    }



    componentDidMount() {
        let item = this.props.details.tasksMapping;
        this.lagDay.setValue(item.lagDay);
        this.totalDays.setValue(item.totalDaysRequired);
    }


    update = () => {
        let item = this.props.details.tasksMapping;
        let predecessorRelation = this.pRelation.getValue();
        let responsiblePerson = this.responsiblePerson.getValue();
        let approver = this.approver.getValue();
        let predecessorTaskId = this.ptaskId.getValue();
        let lagDay = this.lagDay.getValue();
        let totalDays = this.totalDays.getValue();
        let totalLabourRequired = this.totalLabour.getValue();
        let pred = predecessorTaskId.map(val => val.value);



        let actualStartDate = undefined;
        let actualEndDate = undefined;

        if (this.state.date1) {
            actualStartDate = Moment(this.state.date1, 'DD-MM-YYYY');
            actualStartDate = Moment(actualStartDate).valueOf();
        }
        if (this.state.date2) {
            actualEndDate = Moment(this.state.date2, 'DD-MM-YYYY');
            actualEndDate = Moment(actualEndDate).valueOf();
        }

        if (this.state.date1 && this.state.date2) {
            let c1 = Moment(this.state.date1, 'DD-MM-YYYY');
            let c2 = Moment(this.state.date2, 'DD-MM-YYYY');
            if (Moment(c1).valueOf() > Moment(c2).valueOf()) {
                showToast({
                    type: 'error',
                    message: 'Start Date is not to be greater than end date'
                })
                return;
            }
        }


        let data = {
            predecessorRelation: predecessorRelation.key,
            responsiblePerson: responsiblePerson.code ? responsiblePerson.code : responsiblePerson.employeeCode,
            approver: approver.code ? approver.code : approver.employeeCode,
            predecessorTaskId: pred,
            lagDay: +lagDay,
            totalDays: +totalDays,
            totalLabourRequired: +totalLabourRequired,
            projectId: +this.props.id,
            actualStartDate,
            actualEndDate
        }


        post(`project/task/taskMapping.update/${item.id}`, data, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Task update successfully'
                })
                this.props.onClose();

            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.message
                })
            }
        })


    }

    render() {
        let { details, id } = this.props;
        let item = details.tasksMapping;
        let pid = null;
        pid = item.predecessorTask ? {label:item.predecessorTask.wbsItemTaskName,value:item.predecessorTask.id}  : null;
        return (
            <div style={{
                flex: 1,
                height: 'calc( 100vh - 150px)',
                position: 'relative'
            }}>
                <BottomHeader
                    title="Edit Details"
                    desc="Edit Details of task will be appear here"
                />
                <div className="container" style={{
                    padding: 20
                }}>
                    <div style={{
                        display: 'flex'
                    }}>
                        <div style={{
                            flex: 1,
                            marginRight: 20
                        }}>

                            <SearchSelect
                                title="Predecessor Relation"
                                value={{
                                    key: item.predecessorRelation,
                                    value: item.predecessorRelation
                                }}
                                handleRef={ref => this.pRelation = ref}
                                items={[
                                    {
                                        key: 'START',
                                        value: 'START'
                                    },
                                    {
                                        key: 'FINISH',
                                        value: 'FINISH'
                                    },
                                    {
                                        key: 'NONE',
                                        value: 'NONE'
                                    }
                                ]}
                            />
                        </div>
                        <div style={{
                            flex: 1,
                        }}>
                            <EmployeeSearch
                                title="Responsible Person"
                                value={item.responsiblePerson}
                                handleRef={ref => this.responsiblePerson = ref}
                            />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <div style={{
                            flex: 1,
                            marginRight: 20
                        }}>
                            <EmployeeSearch
                                value={item.approver}
                                title="Approver"
                                handleRef={ref => this.approver = ref}
                            />
                        </div>
                        <div style={{
                            flex: 1,
                        }}>
                            <FloatInput
                                handleRef={ref => this.lagDay = ref}
                                title="+/- days wrt predecessor"
                            />

                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                    }}>
                        <div style={{
                            flex: 1,
                            marginRight: 20
                        }}>
                            <DatePicker
                                TextFieldComponent={text => {
                                    return (
                                        <FloatInput
                                            title="Actual Start Date"
                                            onClick={
                                                text.onClick
                                            }
                                            value={this.state.date1}
                                        />

                                    )
                                }}
                                value={this.state.date1} onChange={(date1) => {
                                    let date = Moment(date1).format('DD-MM-YYYY')
                                    this.setState({
                                        ...this.state,
                                        date1: date
                                    })
                                }} />
                        </div>
                        <div style={{
                            flex: 1,

                        }}>
                            <DatePicker
                                TextFieldComponent={text => {
                                    return (
                                        <FloatInput
                                            title="Actual End Date"
                                            onClick={
                                                text.onClick
                                            }
                                            value={this.state.date2}
                                        />

                                    )
                                }}
                                value={this.state.date2} onChange={(date2) => {
                                    let date = Moment(date2).format('DD-MM-YYYY')
                                    this.setState({
                                        ...this.state,
                                        date2: date
                                    })
                                }} />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <div style={{
                            flex: 1,
                            marginRight: 20
                        }}>
                            <FloatInput
                                handleRef={ref => this.totalDays = ref}
                                title="Total Days"
                            />
                        </div>
                        <div style={{
                            flex: 1,
                        }}>
                            <FloatInput
                                handleRef={ref => this.totalLabour = ref}
                                title="Total Labour required"
                            />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>

                        <div style={{
                            display: 'flex',
                            flex: 1
                        }}>
                            <SearchTaskMappingItemN
                                handleRef={ref => this.ptaskId = ref}
                                projectId={id}
                                title="Predecessor Task Id"
                                prefill={pid}

                            />
                        </div>
                    </div>

                    <div>
                        <Button
                            text="Update Details"
                            style={{
                                marginTop: 30
                            }}
                            onClick={() => {
                                this.update()
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Index;