import GaugeChart from 'react-gauge-chart'




const Gauge = ({percent,level}) => {

    return(
        <GaugeChart
            id="gauge-chart1"
            percent={percent}
            nrOfLevels={level} 
            textColor={"#000"}
            colors={['rgb(235, 184, 64)','rgb(104, 208, 131)']}
            animate={false}
        />
    )
}

Gauge.defaultProps = {
    level:20
}

export default Gauge;