import {useParams,useLocation} from 'react-router-dom';
import { TimeLine } from "../../../../../Common/TimeLine";
import { useProject } from '../../../../../Hooks/ProjectDetails';
import { NavigationWrapper } from '../../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../../NCommon/PageWrapper';



const Index = () => {
    let {id,code} = useParams();
    let project = useProject();
    let {search} = useLocation();
    let name = new URLSearchParams(search).get('name')
    return(
        <PageWrapper style={{height:'calc(100vh - 50px)'}}>
            <NavigationWrapper items={[{name:'All Projects',path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:'Images',path:`/app/project/images/${id}/employee`},{name}]} />
            <TimeLine url={`/project/wbs/photos/employeeWise/${id}/${code}`} />
        </PageWrapper>
    )
}

export default Index;