import { Switch, Route } from 'react-router-dom';
import WbsCategory from './WbsCategory';
import WbsItem from './WbsItem';

const Index = () => {

    return (
        <Switch>
            <Route path="/app/wbs" component={WbsCategory} exact />
            <Route path="/app/wbs/:id" component={WbsItem} exact />
        </Switch>
    )
}

export default Index;