import { useEffect, useState } from "react";
import { useParams} from 'react-router-dom';
import { get } from "../../../../../Model/Network/Config/Axios";
import { AccessButton } from "../../../../Common/Button";
import { PageLoader } from "../../../../Common/PageLoader";
import { useProject } from "../../../../Hooks/ProjectDetails";
import { useTableCount } from "../../../../Hooks/TableCount";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";
import { List } from "../../../../NCommon/Ui/List";
import MapHindrance from './MapHindrance';
import UpdateHindrance from './UpdateHindrance';


const Index = () => {

    const [loading,setLoading] = useState(true);
    const [add,setAdd] = useState(false);
    const [update,setUpdate] = useState(false);
    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    let {id} = useParams();
    let count = useTableCount();
    const details = useProject();



    useEffect(()=>{
        INIT();
    },[count])

    let INIT = (data) => {
        if(!data) {
            data = {
                page:0
            }
         }
         data ={...data};
         data.size = `${count}`;
         data.offset =data.page.toString() ? (data.page*count).toString():'0'

        get(`project/hindrance/getHindrances/${id}`,(e,r)=>{
            if(r) {
                setMData({
                    page:data.page,
                    total:r.total?r.total:data.total,
                    items:r.hindrances
                 })
                setLoading(false);
            }
        },data)
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.hindrance.title}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.employee.name}</p>
                </div>
                <TableMenu items={[{name:'Update',onClick:()=>{setUpdate(item)}}]} />
            </List>
        )
    }


    if(loading) {
        return <PageLoader />
    }

    return(
        <>
            <HeaderWrapper showFilter={false} />
            <PageWrapper>
                <NavigationWrapper items={[{name:"All Project",path:'/app/project'},{name:`${details.name}`,path:`/app/project/mapping/${details.id}`},{name:'All Hindrance Mapping'}]}>
                    <AccessButton text="+ Add Mapping" inverted={true?1:0} onClick={()=>{setAdd(true)}}/>
                </NavigationWrapper>
                <PaginationTable
                      DATA={mData.items}
                      total={mData.total}
                      page={mData.page}
                      mapCardLayout={()=>{}}
                      mapListLayout={mapListLayout}
                      next={(page)=>{
                        INIT(page)
                      }}
                      defaultView={'list'}
                      headers={[
                        {
                            name:'Hindrance Name',
                            flex:1
                        },
                        {
                            name:'Employee Name',
                            flex:1
                        },
                        {
                            name:'',
                            width:60
                        }]}
                />
            </PageWrapper>
            <MapHindrance open={add} close={()=>{setAdd(false)}} complete={()=>{setAdd(false);INIT({page:mData.page})}} />
            <UpdateHindrance item={update} open={update} close={()=>{setUpdate(false)}} complete={()=>{setUpdate(false);INIT({page:mData.page})}} />

        </>
    )
}

export default Index;