import { useEffect, useRef, useState } from "react";
import { SimpleFlex } from "../../Common/Flex/SimpleFlex";
import { FunctionalInput } from "../../Common/FloatInput/FunctionalInput";
import { KeyValue } from "../../Common/KeyValue";
import { RightModal } from "../../Common/Modal/RightModal";
import { Modal } from "../../Common/Modal/Modal";
import { PageLoader } from "../../Common/PageLoader";
import { SearchResourceCategory } from "../../Common/Search/SearchResourceCategory";
import { showToast } from "../../Common/Toast";
import {ToggleButton} from '../../Common/Toggle/index'
import { SearchVendor } from "../../Common/Search/SeachVendor";
import { EmployeeSearch } from "../../Common/Search/SearchEmployee";
import { Button } from "../../Common/Button";
import axios,{ post,get } from "../../../Model/Network/Config/Axios";


const Index = (props) => {

    const [loading,setLoading] = useState(true);
    const [add,setAdd] = useState(false);
    const [edit,setEdit] = useState(false);
    const [buttonloading,setButtonLoading] = useState(false);
    const [resources,setResource] = useState([]);
    const [aquire,setAquire] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [resourceType,setResourceType] = useState(undefined);

    let PRICE = useRef(),QUANTITTY=useRef(),RESOURCE=useRef(),VENDOR=useRef(),EMPLOYEE=useRef();

    useEffect(()=>{
       INIT()
    },[])

    useEffect(()=>{
        if(edit) {
            QUANTITTY.current.value = edit.qty;
            PRICE.current.value = edit.price;
            if(edit.vendorMappingId) {
                VENDOR.current.setValue({
                    name:edit.vendorCode,
                    id:edit.vendorMappingId
                })
            }
            if(edit.resourceType === 'LABOUR' && edit.subType === 'SKILLED') {
                setResourceType(edit)
            }
            setAquire(edit.acquired)
            // setResourceType(edit.resourceType);
            

        }
    },[edit])

    useEffect(()=>{
        if(resourceType === 'LABOUR' && edit.employeeId) {
           EMPLOYEE.current.setValue({
               value:edit.employeeName,
               id:edit.employeeId
           })
           EMPLOYEE.current.setName(edit.employeeName)
        }
    },[resourceType])



    let INIT = () => {
        get(`project/task/resource/fetchResourcesMapping/${props.projectId}/${props.taskId}`,(e,r)=>{
            if(r) {
                setLoading(false);
                setResource(r.resources);
            }
        })
    }

    let mapResource = (item,index) => {
        return(
            <div style ={{
                width:'33%',
                padding:5,
                marginTop:30,
                position:'relative'
            }} key={index}>
              <div style={{
                   border:'1px solid var(--border-color)',
                   padding:20,
                   borderRadius:8,
                   marginRight:10
              }}>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--border-color)',
                    padding:'3px 8px',
                    left:20,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600',
                    color:'var(--sidebar-background)'

                }}>
                    Resource {index+1}
                </div>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--button-color)',
                    padding:'3px 12px',
                    right:90,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600',
                    cursor:'pointer'
                }} onClick={()=>{
                    setEdit(item)
                }}>
                    Edit
                </div>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--red-color)',
                    padding:'3px 12px',
                    right:20,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600',
                    cursor:'pointer'
                }} onClick={()=>{
                    setDeleteModal(item)
                }}>
                    Delete
                </div>

                <SimpleFlex>
                    <KeyValue title="Resource Name" desc={item.name} style={{flex:1}} />
                    <KeyValue title="Units" desc={item.units} style={{flex:1}} />
                </SimpleFlex>
                <SimpleFlex>
                    <KeyValue title="Resource Type" desc={item.resourceType} style={{flex:1}} />
                    <KeyValue title="Resource Sub Type" desc={item.subType} style={{flex:1}} />
                </SimpleFlex>
                <SimpleFlex>
                    <KeyValue title="Resource Category" desc={item.category} style={{flex:1}} />
                    <KeyValue title="Quantity" desc={item.qty} style={{flex:1}} />
                </SimpleFlex>
                <SimpleFlex>
                    <KeyValue title="Price Per unit" desc={item.price} style={{flex:1}} />
                    <KeyValue title="Total Amount" desc={item.amount} style={{flex:1}} />
                </SimpleFlex>
                </div>
            </div>
        )
    }

    if(loading) {
        return <div style={{marginTop:20}}><PageLoader /></div>
    }
    return(
        <>
            <div style={{
                display:'flex',
                justifyContent:'flex-end'
            }}>
                <div style={{
                    height:40,
                    width:40,
                    border:'2px dashed var(--active-color)',
                    borderRadius:'50%',
                    color:'var(--active-color)',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    fontSize:40,
                    paddingBottom:10,
                    cursor:'pointer'
                }} onClick={()=>{
                    setAdd(true)
                }}>
                    +
                </div>
            </div>
            <div style={{
                display:'flex',
                flexWrap:'wrap'
            }}>
                 {
                     resources.map(mapResource)
                 }
            </div>
            
            {
                deleteModal?
                <Modal
                    title="Remove Mapping of Resource"
                    desc={deleteModal.name}
                    show={true}
                    onClose={()=>{
                        setDeleteModal(false)
                    }}
                    style={{
                        minHeight:240
                    }}
                >
                    <p style={{
                        fontSize:13,
                        color:'var(--text-light-color)',
                        marginTop:40
                    }}>Are you sure want to remove mapping of {deleteModal.name} ?</p>
                    <Button loading={buttonloading} text="Remove" style={{marginTop:50}} onClick={()=>{
                        setButtonLoading(true);
                         axios.delete(`project/task/resource/removeResourceMapping/${props.projectId}/${deleteModal.resourceMappingId}`, {
                            data: {
                            }
                        })
                            .then(res => {
                                setDeleteModal(false);
                                setButtonLoading(false);
                                INIT();
                                showToast({
                                    type: 'success',
                                    message: "Resource un-tag successfully"
                                })
                            })
                    }} />
                </Modal>
                : null
            }

            {
                add ?
                <RightModal
                    title="Map Resource"
                    desc="Fill up the details and map resource"
                    onClose={()=>{
                        setAdd(false)
                    }}
                    show={true}
                >
                    <SearchResourceCategory onSelect={(item)=>{
                        setResourceType(item);
                    }} type="text" title="Resource" placeholder="Search the resource" handleRef={ref=>RESOURCE.current=ref} />
                    <FunctionalInput defaultValue={'0'} type="number" title="Quantity" ref={QUANTITTY} />
                    <FunctionalInput defaultValue={'0'} type="text" title="Price Per Unit" ref={PRICE} />
                    { 
                    resourceType && resourceType.resourceType === 'LABOUR' && resourceType.subType === 'SKILLED' ?
                    <EmployeeSearch title="Search Employee (optional)" handleRef={ref=>EMPLOYEE.current=ref} />
                    : null 
                    }
                   

                    <SearchVendor title="Search Vendor (optional)" handleRef={ref=>VENDOR.current=ref} />
                    
                    <Button text="Add Resource" loading={buttonloading} style={{marginTop:25}} onClick={()=>{
                        let price = PRICE.current.value;
                        let qty =  QUANTITTY.current.value;
                        let resourceId = RESOURCE.current.getValue() ? RESOURCE.current.getValue().id: undefined;
                        let employee = null
                        if(resourceType && resourceType.resourceType === 'LABOUR') {
                             employee = EMPLOYEE.current.getValue()?EMPLOYEE.current.getValue().id:undefined;
                            
                        }
                        let vendor = VENDOR.current.getValue()?VENDOR.current.getValue().vendorMappingId:undefined;
                        if(!price) {
                            showToast({
                                type:'error',
                                message:'Please enter the name'
                            })
                            return
                        }
                        if(!qty) {
                            showToast({
                                type:'error',
                                message:'Please enter the quantity'
                            })
                            return;
                        }
                        if(!resourceId) {
                            showToast({
                                type:'error',
                                message:"Please enter the resource category"
                            })
                            return;
                        }
                        
                        setButtonLoading(true);
                        let data = {
                            price:+price,
                            qty:+qty,
                            resourceId,
                            projectId:+props.projectId,
                            taskMappingId:props.taskId,
                            vendorMappingId:vendor,
                            employeeId:employee
                        }
                        if(employee) {
                            data.employeeId = employee
                        }
                        post(`project/task/resource/addResourceMapping`,data,(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"Resource map successfully"
                                })
                                setAdd(false);
                                INIT();
                                setButtonLoading(false);
                            } else {
                                setButtonLoading(false);
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                    }} />
                </RightModal>
                : null
            }
            {
                edit ?
                <RightModal
                    title={`${edit.name}`}
                    desc={`${edit.name}`}
                    onClose={()=>{
                        setEdit(false)
                    }}
                    show={true}
                >
                    <FunctionalInput type="number" title="Quantity" ref={QUANTITTY} />
                    <FunctionalInput type="text" title="Price Per unit" ref={PRICE} />
                    <SearchVendor title="Vendor" handleRef={ref=>VENDOR.current=ref} />
                    { 
                    resourceType && resourceType.type === 'LABOUR' && resourceType.subType ==='SKILLED'?
                    <EmployeeSearch title="Search Employee (optional)" handleRef={ref=>EMPLOYEE.current=ref} />
                    : null 
                    }
                   

                    <ToggleButton title="Issued Resource" text="Do you want to issue resource" active={aquire} onChange={setAquire} />

                    <Button text="Update Resource" loading={buttonloading} style={{marginTop:25}} onClick={()=>{
                        let price = PRICE.current.value;
                        let qty =  QUANTITTY.current.value;
                        let employee = null;

                        if(!price) {
                            showToast({
                                type:'error',
                                message:'Please enter the name'
                            })
                            return
                        }
                        if(!qty) {
                            showToast({
                                type:'error',
                                message:'Please enter the quantity'
                            })
                            return;
                        }

                        if(resourceType && resourceType === 'LABOUR') {
                            employee = EMPLOYEE.current.getValue()?EMPLOYEE.current.getValue().id:undefined;
                           
                       }
                       let vendor = VENDOR.current.getValue()?VENDOR.current.getValue().id:undefined;

                      
                        setButtonLoading(true);
                        let data = {
                            price:+price,
                            qty:+qty,
                            resourceMappingId:edit.resourceMappingId,
                            projectId:+props.projectId,
                            taskMappingId:props.taskId,
                            acquired:aquire,
                            employeeId:employee,
                            vendorMappingId:vendor
                        }

                        post(`project/task/resource/updateResourceMapping`,data,(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"Resource updated successfully"
                                })
                                setEdit(false);
                                INIT();
                                setButtonLoading(false);
                            }
                        })
                    }} />
                </RightModal>
                : null
            }
        </>
    )
}

export default Index;