import { useRef } from "react";
import { post } from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../../Common/Modal/RightModal"
import { showToast } from "../../../../Common/Toast";



const Index = (props) => {

    const TITLE=useRef(),DESC=useRef(),LAGDAY=useRef();

    let add = () => {
        let name = TITLE.current.value;
        let desc= DESC.current.value;
        if(!name) {
            showToast({
                type:'error',
                message:'Please enter the title'
            })
            return;
        }

        if(!desc) {
            showToast({
                type:'error',
                message:"Please enter the description"
            })
            return;
        }
       
        let data = {
            name,
            desc,
        }

        post(`project/drawing/addDrawingCategory`,data,(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:'Category added successfully'
                })
                props.init();
            }
        })

    }
    return(
        <RightModal
            show={true}
            title="Add Category"
            onClose={props.close}
        >
            <FunctionalInput title="Enter title" ref={TITLE}  />
            <FunctionalInput title="Enter Description" ref={DESC} />

            <Button text="Add" style={{marginTop:25}} onClick={add} />

        </RightModal>
    )
}

export default Index;