import Zoom from 'react-medium-image-zoom';
import { RightModal } from "../../../Common/Modal/RightModal";
import { pdf } from "../../../Common/Images";
import { KeyValue } from '../../../Common/KeyValue';


const Show = (props) => {


    let {item} = props;

    return(
        <RightModal
            title="Drawing Details"
            desc="All drawing details"
            onClose={props.onClose}
            show={true}
        >

            {item.wbsItemName ?
                <KeyValue title="Wbs Item" desc={item.wbsItemName} />
                : null
            }
            <KeyValue title="Drawing Title" desc={item.title?item.title:item.drawingTitle} />

            <KeyValue title="Category" desc={item.drawingCategory} />
            <KeyValue title="Sub Category" desc={item.subCategory?item.subCategory:'-'} />
            <KeyValue title="Drawing Number" desc={item.drawingNumber} />
            <KeyValue title="Revision Number" desc={item.revisionNumber} />


            <p style={{
                fontSize:14,
                color:'var(--text-light-color)',
                fontWeight:600,
                marginBottom:20
            }}>All Drawing</p>
            <div style ={{
                        display:'flex',
                        flex:1,
                        flexWrap:'wrap'
                    }}>
                    {item.files.map((val,index)=>{
                         if(val.url.toLowerCase().includes('pdf')) {
                            return(
                             <a href={val.url} target="_blank">
                                 <img 
                                 src={pdf}
                                 alt=""
                                 style ={{
                                     height:100,
                                     width:100,
                                     cursor:'pointer',
                                     marginRight:12
                                 }}
                                
                                 key={index}
                               />
                             </a>
                              
                            )
                        } else {
                            return(
                                <Zoom>
                                    <img 
                                        src={val.url} 
                                        style ={{
                                            height:100,
                                            width:100,
                                            marginRight:12
                                        }}

                                    />
                                </Zoom>
                            )
                        }
                    })}
                    </div>
        </RightModal>
    )
}

export default Show;