import {useParams,useLocation,Switch,Route} from 'react-router-dom';
import { useDictionaryValue } from "../../Hooks/DictionaryHooks";
import { Header } from "../../NCommon/Header";
import Task from './Task';
import Resource from './Resource';
import Gantt from './OtherGanttChart'
// import Gantt from './GiantChart';

const Index = () => {

    let getDictionaryValue = useDictionaryValue();
    let {projectId} = useParams();
    let {pathname} = useLocation();

    let task = `/app/project/task/${projectId}`;
    let resource = `/app/project/task/${projectId}/resource`;
    let gantt = `/app/project/task/${projectId}/gantt`

    return(
        <>
            <Header items={[
                {
                    name:`${getDictionaryValue('Task')}`,
                    link:task,
                    active: pathname===task
                },
                {
                    name:`Resource`,
                    link:resource,
                    active: pathname===resource
                },
                {
                    name:'Gantt Chart',
                    active:pathname==gantt,
                    link:gantt
                }
                ]} />
            <Switch>
                <Route path="/app/project/task/:id" component={Task} exact />
                <Route path="/app/project/task/:id/resource" component={Resource} exact />
                <Route path="/app/project/task/:id/gantt" component={Gantt} exact />
            </Switch>
        </>
    )
}

export default Index;