import React from 'react';
import Moment from 'moment';
import { BottomHeader } from '../../../Common/TopHeader/BottomHeader';
import { get, post } from '../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../Common/PageLoader';
import { TaskList } from '../../../Common/TaskItem/TaskList';
import { FloatInput } from '../../../Common/FloatInput';
import { AccessButton, Button } from '../../../Common/Button';
import { showToast } from '../../../Common/Toast';
import { Modal } from '../../../Common/Modal/Modal';
import axios from 'axios';
import { PageWrapper } from '../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lockProject: false,
            projectDetails: {},
            loading: true,
            tasksMappings: [],
            taskOpen: undefined,
            ccEmails: [],
            delete: false
        }
    }

    getId = () => {
        return this.props.match.params.id
    }


    componentDidMount() {
        this.init();
    }

    init = () => {
        get(`project/details/${this.getId()}`, (e, r) => {
            if (r) {
                this.setState({
                    ...this.state,
                    ...r,
                    projectDetails: r,
                    loading: false
                })
            }
        })
        get(`project/meta/criticalTasks/${this.getId()}`, (e, r) => {
            if (r) {
                this.setState({
                    ...this.state,
                    tasksMappings: r.tasksMappings
                })
            }
        })
    }

    delete = () => {
        this.setState({
            ...this.state,
            buttonLoading: true
        })
        axios.delete(`project/delete/${this.getId()}`, {

        })
            .then(res => {
                this.setState({
                    ...this.state,
                    delete: false,
                    buttonLoading: false
                })
                showToast({
                    type: 'success',
                    message: "Project delete successfully"
                })
                this.props.history.push(`/app/project`)
            })
            .catch(res => {
                this.setState({
                    ...this.state,
                    buttonLoading: false
                })
                showToast({
                    type: 'error',
                    message: res.data.message
                })
            })
    }

    toggle = () => {
        get(`project/lockToggle/${this.getId()}`, (e, r) => {
            if (r) {
                this.init();
            }
        })
    }



    mapItem = (item, index) => {
        return (
            <TaskList
                {...this.props}
                headerFilter = {{ 
                    details:true,
                    planned_start_date:true,
                    planned_end_date:true,
                    estimated_start_date:true,
                    estimated_end_date:true,
                    reponsible_person:true,
                    duration:false,
                    task_type:false,
                    status:true,
                    column:true}}
                key={index}
                item={item}
                taskOpen={this.state.taskOpen}

                onTaskOpen={() => {
                    if (item.id === this.state.taskOpen) {
                        this.setState({
                            ...this.state,
                            taskOpen: undefined
                        })
                    } else {

                        this.setState({
                            ...this.state,
                            taskOpen: item.id
                        })
                    }
                }}
                onChangePredecessor={() => {
                    this.setState({
                        ...this.state,
                        predecessorTask: item.predecessorTask ? item.predecessorTask : true,
                        taskMappingId: item
                    })
                }}
            />
        )
    }


    dates = () => {
        return (
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 10
            }}>
                <div style={{
                    width: '25%',
                    padding: 10,
                    boxSizing: 'border-box'
                }}>
                    <div style={{
                        border: '1px solid var(--border-color)',
                        padding: 18
                    }}>
                        <p style={{
                            fontSize: 14,
                            color: 'var(--text-light-color)'
                        }}>Budget</p>
                        <p style={{
                            marginTop: 8,
                            fontWeight: 600,
                            fontSize: 22,
                            color: 'var(--text-color)'
                        }}>
                            INR {this.state.projectDetails.budget}
                        </p>
                    </div>
                </div>
                <div style={{
                    width: '25%',
                    padding: 10,
                    boxSizing: 'border-box'

                }}>
                    <div style={{
                        border: '1px solid var(--border-color)',
                        padding: 18
                    }}>
                        <p style={{
                            fontSize: 14,
                            color: 'var(--text-light-color)'
                        }}>Advance Delay</p>
                        <p style={{
                            marginTop: 8,
                            fontWeight: 600,
                            fontSize: 22,
                            color: 'var(--text-color)'
                        }}>
                            {this.state.projectDetails.delayAdvanceBy}
                        </p>
                    </div>
                </div>
                <div style={{
                    width: '25%',
                    padding: 10,
                    boxSizing: 'border-box'
                }}>
                    <div style={{
                        border: '1px solid var(--border-color)',
                        padding: 18
                    }}>
                        <p style={{
                            fontSize: 14,
                            color: 'var(--text-light-color)'
                        }}>Management End Date</p>
                        <p style={{
                            marginTop: 8,
                            fontWeight: 600,
                            fontSize: 22,
                            color: 'var(--text-color)'
                        }}>
                            {Moment(this.state.projectDetails.managementEndDate).format('DD-MM-YYYY')}
                        </p>
                    </div>
                </div>
                <div style={{
                    width: '25%',
                    padding: 10,
                    boxSizing: 'border-box'

                }}>
                    <div style={{
                        border: '1px solid var(--border-color)',
                        padding: 18
                    }}>
                        <p style={{
                            fontSize: 14,
                            color: 'var(--text-light-color)'
                        }}>Total New Task Added</p>
                        <p style={{
                            marginTop: 8,
                            fontWeight: 600,
                            fontSize: 22,
                            color: 'var(--text-color)'
                        }}>
                            {this.state.projectDetails.totalNewTasks}
                        </p>
                    </div>
                </div>

            </div>
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <PageLoader />
            )
        }
        return (
            <>
                <PageWrapper style={{height:'calc(100vh - 50px)'}}>
                <NavigationWrapper items={[{name:`All Project`,path:'/app/project'},{name:`${this.state.projectDetails.name}`,path:`/app/project/mapping/${this.getId()}`},{name:'Setting'}]} />
                <div className="title-container-1">
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: 10
                    }}>
                       
                        <div style={{
                            width: '25%',
                            padding: 10,
                            boxSizing: 'border-box'

                        }}>
                            <div style={{
                                border: '1px solid var(--border-color)',
                                padding: 18
                            }}>
                                <p style={{
                                    fontSize: 14,
                                    color: 'var(--text-light-color)'
                                }}>Start Date</p>
                                <p style={{
                                    marginTop: 8,
                                    fontWeight: 600,
                                    fontSize: 22,
                                    color: 'var(--text-color)'
                                }}>
                                    {Moment(this.state.projectDetails.startDate).format('DD-MM-YYYY')}
                                </p>
                            </div>
                        </div>
                        <div style={{
                            width: '25%',
                            padding: 10,
                            boxSizing: 'border-box'

                        }}>
                            <div style={{
                                border: '1px solid var(--border-color)',
                                padding: 18
                            }}>
                                <p style={{
                                    fontSize: 14,
                                    color: 'var(--text-light-color)'
                                }}>Planned Finish Date</p>
                                <p style={{
                                    marginTop: 8,
                                    fontWeight: 600,
                                    fontSize: 22,
                                    color: 'var(--text-color)'
                                }}>
                                    {Moment(this.state.projectDetails.plannedFinishDate).format('DD-MM-YYYY')}
                                </p>
                            </div>
                        </div>
                        <div style={{
                            width: '25%',
                            padding: 10,
                            boxSizing: 'border-box'

                        }}>
                            <div style={{
                                border: '1px solid var(--border-color)',
                                padding: 18
                            }}>
                                <p style={{
                                    fontSize: 14,
                                    color: 'var(--text-light-color)'
                                }}>Estimate Finish Date</p>
                                <p style={{
                                    marginTop: 8,
                                    fontWeight: 600,
                                    fontSize: 22,
                                    color: 'var(--text-color)'
                                }}>
                                    {Moment(this.state.projectDetails.estimatedFinishDate).format('DD-MM-YYYY')}
                                </p>
                            </div>
                        </div>
                        <div style={{
                            width: '25%',
                            padding: 10,
                            boxSizing: 'border-box'
                        }}>
                            <div style={{
                                border: '1px solid var(--border-color)',
                                padding: 18
                            }}>
                                <p style={{
                                    fontSize: 14,
                                    color: 'var(--text-light-color)'
                                }}>Client End Date</p>
                                <p style={{
                                    marginTop: 8,
                                    fontWeight: 600,
                                    fontSize: 22,
                                    color: 'var(--text-color)'
                                }}>
                                    {this.state.projectDetails.clientEndDate ? Moment(this.state.projectDetails.clientEndDate).format('DD-MM-YYYY'):'-'}
                                </p>
                            </div>
                        </div>


                    </div>
                    {this.dates()}
                </div>

                <BottomHeader
                    title="Add Email"
                    desc="Add Email to send DPR"
                />

                <div className="title-container-1">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <FloatInput
                            title="Add Email"
                            handleRef={ref => this.email = ref}
                        />
                        <AccessButton
                            text="Add"
                            style={{
                                marginTop: 32,
                                marginLeft: 20
                            }}
                            onClick={() => {
                                let value = this.email.getValue();
                                if (!value) {
                                    showToast({
                                        type: 'error',
                                        message: 'Please enter the email'
                                    })
                                    return
                                }

                                let cc = this.state.ccEmails;
                                cc = cc.filter(val => val.toLowerCase() !== value.toLowerCase());
                                cc.push(this.email.getValue());

                                post(`project/emails.update/${this.getId()}`, {
                                    email: this.email.getValue(),
                                    cc
                                }, (e, r) => {
                                    if (r) {
                                        this.init();
                                        this.email.setValue('');
                                        showToast({
                                            type: 'success',
                                            message: "Email update successfully"
                                        })
                                    }
                                })
                            }}
                        />
                    </div>

                    <div style={{
                        marginTop: 20
                    }}>
                        {
                            this.state.ccEmails.map((val, index) => {
                                return (
                                    <div key={index} style={{
                                        marginBottom: 10,
                                        fontWeight: 600,
                                        borderBottom: "1px solid var(--border-color)",
                                        paddingBottom: 15,
                                        display:'flex',
                                        justifyContent:'space-between'

                                    }}>
                                        <div>
                                            {val}
                                        </div>
                                        <div style={{
                                            fontSize:13,
                                            color:'var(--red-color)',
                                            cursor:'pointer'
                                        }} onClick={()=>{
                                            let cc = this.state.ccEmails;
                                            cc = cc.filter(i =>i!== val);
                                            post(`project/emails.update/${this.getId()}`, {
                                                email: cc[0]?cc[0]:'',
                                                cc
                                            }, (e, r) => {
                                                if (r) {
                                                    this.init();
                                                    showToast({
                                                        type: 'success',
                                                        message: "Email update successfully"
                                                    })
                                                }
                                            })
                                        

                                        }}>
                                            Delete
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>


                </div>

                <BottomHeader
                    title="Daily Report"
                    desc="Trigger daily report "
                />
                <div className="title-container-1">
                    <Button
                        text="Trigger"
                        onClick={() => {
                            post(`/project/dailyReport.trigger/${this.getId()}`, {}, (e, r) => {
                                if (r) {
                                    showToast({
                                        type: 'success',
                                        message: "Daily report sent successfully"
                                    })
                                }
                            })
                        }}
                    />
                </div>


                <BottomHeader
                    title="Lock Project"
                    desc="Lock Project so no further changes is made"
                />

                <div className="title-container-1">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 10
                    }}>
                        <div>
                            <p style={{
                                fontWeight: 600,
                                fontSize: 18
                            }}>Project Lock</p>
                            <p style={{
                                fontWeight: 600,
                                fontSize: 13,
                                color: 'var(--light-color)'
                            }}>You can enable and disable project from here</p>
                        </div>
                        <div className="portlet-switch" onClick={() => {
                            this.toggle()
                        }}>
                            <div
                                className={`check ${this.state.projectDetails.isLocked ? 'on' : 'off'}`} />
                        </div>

                    </div>
                </div>

                <BottomHeader
                    title="Fullkitting "
                    desc="Enable Fullkitting"
                />


                <div className="title-container-1">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 10
                    }}>
                        <div>
                            <p style={{
                                fontWeight: 600,
                                fontSize: 18
                            }}>Fullkitting</p>
                            <p style={{
                                fontWeight: 600,
                                fontSize: 13,
                                color: 'var(--light-color)'
                            }}>You can enable fullkitting  from here</p>
                        </div>
                        <div className="portlet-switch" onClick={() => {
                            get(`project/fullKittingToggle/${this.getId()}`,(e,r)=>{
                                if(r) {
                                    this.init();
                                }
                            })
                        }}>
                            <div
                                className={`check ${this.state.projectDetails.fullKitting ? 'on' : 'off'}`} />
                        </div>

                    </div>
                </div>

                <div className="title-container-1">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 10
                    }}>
                        <div>
                            <p style={{
                                fontWeight: 600,
                                fontSize: 18
                            }}>Default Saturday Off</p>
                            <p style={{
                                fontWeight: 600,
                                fontSize: 13,
                                color: 'var(--light-color)'
                            }}>You can enable saturday off/on from here</p>
                        </div>
                        <div className="portlet-switch" onClick={() => {
                            post(`project/update/${this.getId()}`,{saturdayOff:!this.state.projectDetails.saturdayOff},(e,r)=>{
                                if(r) {
                                    this.init();
                                }
                            })
                        }}>
                            <div
                                className={`check ${this.state.projectDetails.saturdayOff ? 'on' : 'off'}`} />
                        </div>

                    </div>
                </div>

                <div className="title-container-1">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 10
                    }}>
                        <div>
                            <p style={{
                                fontWeight: 600,
                                fontSize: 18
                            }}>Default Sunday Off</p>
                            <p style={{
                                fontWeight: 600,
                                fontSize: 13,
                                color: 'var(--light-color)'
                            }}>You can enable sunday off/on from here</p>
                        </div>
                        <div className="portlet-switch" onClick={() => {
                            post(`project/update/${this.getId()}`,{sundayOff:!this.state.projectDetails.sundayOff},(e,r)=>{
                                if(r) {
                                    this.init();
                                }
                            })
                        }}>
                            <div
                                className={`check ${this.state.projectDetails.sundayOff ? 'on' : 'off'}`} />
                        </div>

                    </div>
                </div>

                <BottomHeader
                    title="Delete Project"
                    desc="Delete Project permanently"
                />

                <div className="title-container-1">


                    <AccessButton
                        text="Delete"
                        style={{
                            backgroundColor: 'var(--red-color)',
                            borderColor: 'var(--red-color)',
                            width: 230
                        }}
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                delete: true
                            })
                        }}
                    />

                    {
                        this.state.delete ?
                            <Modal
                                title={`Delete Project`}
                                desc={`Delete ${this.state.name}`}
                                show={this.state.delete}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        delete: false
                                    })
                                }}
                                style={{
                                    minHeight: 150
                                }}
                            >
                                <p style={{
                                    marginTop: 50
                                }}>Are you sure want to delete <span style={{
                                    fontWeight: 600
                                }}>
                                        {this.state.name} Project
                                    </span> ? </p>

                                <Button
                                    text="Delete"
                                    style={{
                                        marginTop: 50,
                                        backgroundColor: 'var(--red-color)',
                                        borderColor: 'var(--red-color)'
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            buttonLoading: true
                                        })
                                        this.delete()
                                    }}
                                    loading={this.state.buttonLoading}
                                />
                            </Modal>
                            : null

                    }
                </div>
                </PageWrapper>



            </>
        )
    }
}

export default Index;