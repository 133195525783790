import {Route,Switch,useLocation,useParams} from 'react-router-dom';
import Resource from './Resource';
import Conflict from './ConflictInventory';
import TaskLevelResource from './TaskLevelResource';
import UploadResource from './UploadResource';
import { Header } from '../../../NCommon/Header';
import ResourceOverview from '../../AllTaskNew/Resource/index';

const Index = () => {
    let {id} = useParams();
    let {pathname} = useLocation();
    return(
        <>
         <Header
                items={[
                    {
                        name:'Task Level Resource',
                        link:`/app/project/resource/${id}`,
                        active:pathname===`/app/project/resource/${id}`
                    },
                    {
                        name:'Resource OverView',
                        link:`/app/project/resource/${id}/overview`,
                        active:pathname===`/app/project/resource/${id}/overview`
                    },
                    {
                        name:'Conflict Resource',
                        link:`/app/project/resource/${id}/conflict`,
                        active:pathname===`/app/project/resource/${id}/conflict`
                    },
                    {
                        name:'Upload Resource',
                        link:`/app/project/resource/${id}/upload`,
                        active:pathname===`/app/project/resource/${id}/upload`
                    }
                   
                ]}
             />
        <Switch>
            <Route path="/app/project/resource/:id" component={TaskLevelResource} exact />
            <Route path="/app/project/resource/:id/conflict" component={Conflict} exact />
            <Route path="/app/project/resource/:id/overview" component={ResourceOverview} />
            <Route path="/app/project/resource/:id/task" component={Resource} exact />
            <Route path="/app/project/resource/:id/upload" component={UploadResource} exact />

        </Switch>
        </>
    )
}

export default Index;