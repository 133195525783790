import {useState} from 'react';
import {useLocation} from'react-router-dom';
import { useSelector } from "react-redux"
import './index.css';
import { patch } from '../../../Model/Network/Config/Axios';
import { showToast } from '../../Common/Toast';
import syncIcon from './sync.png';

const Profile = () => {

    let name =  useSelector(name=>name.config.name);
    const [sync,setSync] = useState(false);


    let {pathname} = useLocation();


    let syncProject = () => {
        let id = pathname.split("/");
            id = id[4] ? +id[4] : null;
            setSync(true);
            showToast({
                type:'success',
                message:"Project sync is running in background"
            })
            setTimeout(()=>{
                patch(`project/sync/${id}`, {}, (e, r) => {
                    if (r) {
                      showToast({
                        type: "success",
                        message: "Project sync succesfully",
                      });
                     setSync(false);
                    }
                  });
            },3000)
    }

   
    return(
        <div style={{display:'flex',alignItems:'center'}}>
         {
            pathname.includes('/app/project/') ?
               <>
                 <div className="flex flex-v-center flex-h-center" >
                    <img src={syncIcon}  onClick={syncProject} alt="" style={{height:18,width:18,marginRight:10,cursor:'pointer'}}  className={sync ? "image" : null} />
                    <p style={{ marginRight: 20,fontSize:15,fontWeight:600,cursor:'pointer'}} onClick={syncProject}>
                        Sync Task
                    </p>
                </div>
               
                </>
                : null
             }
        <div className="profile">
            {name}
        </div>
        </div>
    )
}

export {Profile}