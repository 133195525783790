import { useEffect, useRef, useState } from "react";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../../Common/Modal/RightModal";
import { Button } from "../../../../Common/Button";
import { showToast } from "../../../../Common/Toast";
import { post } from "../../../../../Model/Network/Config/Axios";


const Index = ({details,projectId,init,onClose}) => {

    let [buttonLoading,setButtonLoading] = useState(false);
    let QTY = useRef(),PRICE=useRef(),MAX=useRef();

    useEffect(()=>{
        PRICE.current.value = details.price;
        MAX.current.value = details.max;
    },[])


    let update = () => {
        let availableQty = QTY.current.value;
        let price = PRICE.current.value;
        let max = MAX.current.value;

        if(!availableQty) {
            showToast({
                type:'error',
                message:"Please enter the available quantity"
            })
            return;
        }
        if(!price) {
            showToast({
                type:'error',
                message:"Please enter the price"
            })
            return
        }

        if(!max) {
            showToast({
                type:'error',
                message:"Please enter the max quanity"
            })
            return;
        }
        let data = {
            availableQty:+availableQty+details.availableQty,
            price:+price,
            projectId:+projectId,
            inventoryId:details.inventoryId,
            max:+max
        }
        setButtonLoading(true);

        post(`project/inventory/updateInventory`,data,(e,r)=>{
            if(r) {
                setButtonLoading(false)
                showToast({
                    type:'success',
                    message:"Inventory updated successfully"
                })
                init();
            }
        })
    }
    return(
        <RightModal
            title="Edit Details"
            desc={`Change the details of ${details.name}`}
            show={true}
            onClose={onClose}
        >
            <FunctionalInput ref={QTY} title="Quantity" />
            <FunctionalInput ref={PRICE} title="Price" />
            <FunctionalInput title="Max Quantity" ref={MAX}/>
            <Button text="Update" style={{marginTop:30}} onClick={update} loading={buttonLoading}/>
        </RightModal>
    )
}

export default Index;