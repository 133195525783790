import { useRef } from "react";
import { useParams} from 'react-router-dom';
import { post } from "../../../../../../Model/Network/Config/Axios";
import { AccessButton } from "../../../../../Common/Button";
import { FunctionalInput } from "../../../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../../../Common/Modal/RightModal"
import { showToast } from "../../../../../Common/Toast";



const Index = (props) => {

    let {id} = useParams();

    const NAME = useRef(),DETAILS=useRef(),ADDRESS=useRef();


    let createStore = () => {
        let name = NAME.current.value;
        let details = DETAILS.current.value;
        let address = ADDRESS.current.value;
        if(!name) {
            showToast({
                type:'success',
                message:"Please enter the name"
            })
            return;
        }
        if(!details) {
            showToast({
                type:'error',
                message:"Please enter the details"
            })
            return
        }
        if(!address) {
            showToast({
                type:'error',
                message:"Please enter the address"
            })
            return
        }

        let data = {
            name,
            details,
            address,
            lat:72.13,
            lng:101.13
        }

        post(`project/stores/createStore/${id}`,data,(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Store Create successfully"
                })
                props.init();
            }
        })
    }



    return(
        <RightModal
            title="Add Store"
            show={true}
            onClose={props.onClose}
        >
            <FunctionalInput title="Name" ref={NAME} />
            <FunctionalInput title="Details" ref={DETAILS} />
            <FunctionalInput title="Address" ref={ADDRESS} />
            <AccessButton style={{marginTop:25}} text="Add" onClick={createStore} />
        </RightModal>
    )
}

export default Index;