import { useRef, useState } from "react";
import {useParams} from 'react-router-dom';
import { axios } from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { deleteIcon } from "../../../../Common/Images";
import { Modal } from "../../../../Common/Modal/Modal";
import { showToast } from "../../../../Common/Toast";
import { BottomPopUp } from "../../../../NCommon/BottomPopup";



const Index = ({onComplete,selected}) => {

    const [deleteModal,setDeleteModal] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    let {id} = useParams();
    let REASON = useRef();

    return(
      <>
        <BottomPopUp show={selected.length}>
          <div style={{marginLeft:20,display:'flex',alignItems:'center'}}>
            <div onClick={()=>setDeleteModal(true)} className="flex flex-v-center" style={{border:'1px solid var(--red-color)',justifyContent:'space-between',cursor:'pointer',padding:5,borderRadius:4}}>
                <img src={deleteIcon} alt="" style={{height:16,width:16}} />
                <p style={{fontSize:13,color:'var(--red-color)',paddingLeft:5,fontWeight:600}}>Delete All</p>
            </div>
            <p style={{marginLeft:20,fontSize:14,fontWeight:600}}>{selected.length} Item Selected</p>
          </div>
        </BottomPopUp>
        <Modal
          title="Delete Resource"
          desc="Are you sure want to delete this resource"
          show={deleteModal}
          onClose={()=>{setDeleteModal(false)}}
          style={{minHeight:200 }}
        >
            <FunctionalInput ref={REASON} title="Enter Reason" />
            <p style={{marginTop:10,fontSize:14}}>{selected.length} Item are selected</p>
            <Button 
                text="Delete" 
                loading={buttonLoading} 
                style={{marginTop:50}} 
                onClick={()=>{
                    let reason = REASON.current.value;
                    if(!reason) {
                        showToast({
                            type:'error',
                            message:"Please enter the reason"
                        })
                        return;
                    }
                    setButtonLoading(true);
                    let count = 0;
                    selected.forEach(val=>{
                      axios.delete(`project/task/resource/removeResourceMapping/${id}/${val}`, {
                        data: {
                        }
                     })
                        .then(res => {
                        })
                        .catch(res=>{
                        })
                        count+=1
                    })
                    console.log(count,selected.length);
                    if(count===selected.length) {
                      setButtonLoading(false);
                      setDeleteModal(false);
                      onComplete();
                      showToast({
                        type:'success',
                        message:'Resource Delete Successfully'
                      })
                    }


                  
                   
            }} />
        </Modal>
     
      </>
    )
}

export default Index;