import { SimpleFlex } from "../../../Common/Flex/SimpleFlex";
import { KeyValue } from "../../../Common/KeyValue";


const Index = ({item}) => {
    
    console.log(item);

    return(
        <div style={{flex:1}}>
            <p style={{fontSize:14,fontWeight:600}}>Role</p>
            {
                item.role ?
                <SimpleFlex>
                    <KeyValue style={{flex:1}} title={"Name"} desc={item.role.name}/>
                    <KeyValue style={{flex:1}} title="Description" desc={item.role.description} />
                </SimpleFlex>
                :<p style={{fontSize:14,marginTop:5,marginBottom:5}}>No Role is Selected</p>
            }
            <p style={{fontSize:14,fontWeight:600}}>Custom Values</p>
            <div style={{display:'flex',flexWrap:'wrap',flex:2,marginTop:10}}>
                {
                    Object.keys(item.customValues).length?
                    Object.keys(item.customValues).map((val,index)=>(
                        <SimpleFlex key={index}>
                             <KeyValue style={{flex:1}} title={val} desc={item.customValues[val]}/>
                        </SimpleFlex>
                    ))
                 : <p></p>
              }
            </div>
        </div>
    )
}

export default Index;