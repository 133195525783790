import { memo, useEffect, useState } from "react";
import { useParams} from 'react-router-dom';
import { post } from "../../../../../../Model/Network/Config/Axios";
import { CenterModal } from "../../../../../Common/Modal/CenterModal";
import { PageLoader } from "../../../../../Common/PageLoader";
import SecondMain from './SecondMain';

const SubType = memo((props) => {

    const {id} = useParams();
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState({});
    

    useEffect(()=>{
        INIT({});
    },[])

    let INIT = (data) => {
        setLoading(true);
        data.andFilters=[{key:props.active,val:props.item}];
        if(props.filter.length) {
             let filter = [];
             props.filter.forEach(item=>{
                filter.push({
                    key:item.type,
                    val:item.value
                  })
            })
            data.andFilters = [...data.andFilters,...filter]
        }
        post(`/project/task/taskAnalytics/${id}`,{type:props.type,...data},(e,r)=>{
            if(r) {
                setData(r.tasks);
                setLoading(false);
            }
        })
    }
    

    return(
        <CenterModal
            header={props.item}
            onClose={props.onClose}
        >
            <div style={{marginTop:70}}>
                 <div style={{padding:20}}>
                    {loading ? 
                        <PageLoader />:
                        <SecondMain data={data} />
                    }
                 </div>
            </div>
        </CenterModal>
    )
})

export default SubType;