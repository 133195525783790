import { useState } from 'react';
import Moment from 'moment';
import TaskModal from './TaskModal';
import { List } from "../Ui/List";
import StatusTile from "./StatusTile";
import { check } from "../../Common/Images";
import edit from '../Images/edit.png';
import './TaskItem.css';
import { User } from '../Component/User';
import { Vendor } from '../Component/Vendor';
import { ViewTag } from '../Tag';
import { AddTag } from '../../Common/Tag/AddTag';

const TaskListLayout = ({item,active,onCheckBoxClick,init,onClickPredecessor,fullkitting}) => {

    const [tag,setTag] = useState(false);
    const [updateId,setUpdateId] = useState(1);

    let [taskModal,setTaskModal] = useState(false)
    let predecessor = null;
    if (item.predecessorName) {
        predecessor = item.predecessorCroppedName;
    }
    
    return(
          <>
             <List className="task-list" style={{minHeight:80}} onClick={()=>{setTaskModal(item)}}>
                <div style={{width:25}} onClick={onCheckBoxClick}>
                    {active?
                    <img src={check} alt="" style={{height:18,width:18,marginTop:5}} />:
                    <div style={{height:16,width:16,border:'1px solid var(--border-color)'}} />
                    }
                </div>
                <div style={{width:400}}>
                    <p className='table-title' style={{width:380,fontSize:14}}>{item.croppedName}</p>
                    {!fullkitting?<p className='predecessor table-bottom single-line' style={{width:360,position:'relative'}}>
                        {item.predecessorName ? <>Pred. : <span style={{color:'var(--button-color)',cursor:'pointer',width:340}}>{predecessor}</span></>:'NA'}
                        <img onClick={onClickPredecessor} className='predecessor-image' src={edit} style={{height:14,width:14,position:'absolute',right:0}} />
                    </p>:null}
                    {fullkitting ?
                        <p className='table-bottom'>
                            {item.nonFullKittedReason}
                        </p>
                        :null
                    }
                </div>
                
                <div className="flex-1">
                    <p className="table-text">{item.originalStartDate ? Moment(item.originalStartDate).format('DD-MM-YYYY') : '-'} </p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.originalCompletionDate ? Moment(item.originalCompletionDate).format('DD-MM-YYYY') : '-'} </p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.estimatedStartDate ? Moment(item.estimatedStartDate).format('DD-MM-YYYY') : '-'} </p>
                </div>
                <div className="flex-1">
                    <p className="table-text" >{item.estimatedCompletionDate ? Moment(item.estimatedCompletionDate).format('DD-MM-YYYY') : '-'} </p>
                </div>
                <div style={{flex:1}}>
                    <StatusTile item={item} />
                </div>
                <div style={{flex:0.5}}>
                    {item.responsiblePerson?<User name={item.responsiblePerson.name} />:<p className='table-text'>-</p>}
                </div>
                <div style={{flex:0.5}}>
                    {item.vendor ? <Vendor name={item.vendor.name} />:<p className='table-text'>-</p>}
                </div>
                <div style={{flex:0.5}}>
                    <ViewTag update={updateId} type='WBS_TASK' id={item.id} add={()=>{
                        setTag(item.id)
                    }} />
                </div>
              
            </List>
            {taskModal?
            <TaskModal
                details={taskModal}
                onClose={()=>{setTaskModal(false)}}
                init={init}
                />:
            null}

             {
                    tag?
                    <AddTag referenceId={tag} type="WBS_TASK" init={()=>{
                        setTag(false);
                        setUpdateId(updateId+1)
                        init()
                    }} close={()=>setTag(false)}  />
                    :null
                }
            

         </>
    )
}



export {TaskListLayout}