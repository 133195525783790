import _ from 'lodash';
import { DEVELOPMENT_MODE } from '../Model/Network/Api/config';


const WithModule = (modules, singleModule) => {

    if (singleModule === 'LOGOUT' || singleModule === 'CATEGORY' || singleModule === 'TASK') {
        return true;
    }

    if (_.includes(modules, singleModule)) {
        return true
    }
    return false;
}

const isDevelopment = (item) => {

    if (DEVELOPMENT_MODE && item.developmentMode) {
        return
    }
}

export { WithModule, isDevelopment }