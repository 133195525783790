import { useEffect, useRef, useState } from "react";
import { useParams} from 'react-router-dom';
import { post } from "../../../Model/Network/Config/Axios";
import { Button } from "../../Common/Button";
import { KeyValue } from "../../Common/KeyValue";
import { RightModal } from "../../Common/Modal/RightModal";
import { EmployeeSearch } from "../../Common/Search/SearchEmployee";
import { SearchHindrance } from "../../Common/Search/SearchHindrance";
import { showToast } from "../../Common/Toast";



const Index = ({tasksMapping,init}) => {

    const {hindrance,hindrancePerson} = tasksMapping;
    let itemId = tasksMapping.id;
    const [update,setUpdate] = useState(false);
    let HINDRANCE = useRef(),EMPLOYEE=useRef();
    let {id} = useParams();

    useEffect(()=>{ 
        if(update) {
            HINDRANCE.current.setValue(hindrance.title);
            HINDRANCE.current.setItem(hindrance)
            if(hindrancePerson) {
                EMPLOYEE.current.setName(hindrancePerson.name)
                EMPLOYEE.current.setValue(hindrancePerson)
            }
        }
    },[update])


    return(
        <>
            {
                hindrance ?
                <>
                     <div style={{display:'flex',alignItem:'center'}}>
                        <KeyValue title="Hindrance Name" desc={hindrance.title} />
                        <p style={{
                            marginLeft:10,
                            background:'var(--button-color)',
                            borderRadius:2,
                            color:'#fff',
                            height:20,
                            fontSize:12,
                            fontWeight:'bold',
                            padding:'0px 5px',
                            marginTop:5,
                            cursor:'pointer'
                        }} onClick={()=>{setUpdate(true)}} className='flex flex-v-center flex-h-center'>Update</p>
                    </div>
                    <KeyValue title="Hindrance Description" desc={hindrance.description} />
                    <div style={{display:'flex',alignItem:'center'}}>
                        <KeyValue title="Responsible Person" desc={hindrancePerson?hindrancePerson.name:null} />
                    </div>
                </>
                :null
            }

            <RightModal
                title="Edit Hindrance"
                show={update}
                onClose={()=>{
                    setUpdate(false);
                }}
            >   
                <SearchHindrance ref={HINDRANCE} />
                <EmployeeSearch  title="Search Employee" handleRef={ref=>EMPLOYEE.current=ref} />

                <Button style={{marginTop:20}} text="Update" onClick={()=>{
                    let hindranceId = HINDRANCE.current.getValue();
                    let employee = EMPLOYEE.current.getValue();

                    if(!hindranceId) {
                        showToast({
                            type:'error',
                            message:"Please select the hindrance "
                        })
                        return;
                    }

                    if(!employee) {
                        showToast({
                            type:'error',
                            message:"Please select the employee"
                        })
                        return;
                    }

                    console.log(hindranceId,employee)
                    let data = {
                        hindranceId:hindranceId.id,
                        hindrancePersonId:employee.id,
                        projectId:+id,
                        totalDays:+tasksMapping.totalDaysRequired,
                        predecessorRelation:tasksMapping.predecessorRelation
                    }

                    post(`project/task/taskMapping.update/${itemId}`,data,(e,r)=>{
                        if(r) {
                            setUpdate(false);
                            init();
                            showToast({
                                type:'success',
                                message:"Hindrance update successfully"
                            })
                        }
                    })


                }} />
            </RightModal>

        </>
    )
}

export default Index;