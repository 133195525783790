import { useRef, useState } from 'react';
import {useParams} from 'react-router-dom';
import { get } from '../../../../../../Model/Network/Config/Axios';
import { Button } from '../../../../../Common/Button';
import { FunctionalInput } from '../../../../../Common/FloatInput/FunctionalInput';
import { showToast } from '../../../../../Common/Toast';
import { BottomPopUp } from '../../../../../NCommon/BottomPopup';
import { MainModal } from '../../../../../NCommon/Modal';


const Index = ({onComplete,selected}) => {

    const {id} = useParams();
    const [modal,setModal] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    let REMARKS = useRef();
   
    return(
      <>
        <BottomPopUp show={selected.length}>
            {selected.length} Task Mark FullKitted
            <Button text="Mark FullKitted" style={{marginLeft:20}} onClick={()=>{setModal(true)}} />
        </BottomPopUp>
        <MainModal
            open={modal}
            title="Mark FullKitted"
            close={()=>{setModal(false)}}
        >
            <FunctionalInput ref={REMARKS} title="Enter Remarks" />
            <Button loading={buttonLoading} text="Submit" style={{marginTop:20}} onClick={()=>{
                let remarks = REMARKS.current.value;
                if(!remarks) {
                    showToast({
                        type:'error',
                        message:"Please enter the remarks"
                    })
                    return;
                }

                setButtonLoading(true);
                let count=0;
                selected.forEach(item=>{
                    get(`project/task/fullKitting/markFullKit/${id}/${item}?remarks=${remarks}`,(e,r)=>{
                        console.log('hi outside')
                        if(r) {
                            count +=1;
                            if(count===selected.length) {
                                setButtonLoading(false);
                                showToast({
                                    type:'success',
                                    message:"Task FullKitted Successfully"
                                })
                                setModal(false);
                                onComplete();
                            }
                        }
                    })
                })
              
            }} />
        </MainModal>
      </>
    )
}

export default Index;