import { useState } from 'react';
import {useParams} from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useProject } from '../../../../../Hooks/ProjectDetails';
import { NavigationWrapper } from '../../../../../NCommon/NavigationWrapper';
import { Button } from '../../../../../Common/Button';
import { get, post } from '../../../../../../Model/Network/Config/Axios';
import { showToast } from '../../../../../Common/Toast';
import { DownloadDirectCsv } from '../../../../../../Utils/Downloader';

const Index = () =>{

    const [loading,setLoading] = useState(false);
    const [loading1,setLoading1] = useState(false);
    let {id} = useParams();
    let project = useProject();

    let onClick = () => {
        setLoading(true);
        post(`reports/custom/ate/reports/mprReport/${id}`,{},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:'Report export successfully'
                })
                DownloadDirectCsv("MPR_REPORT.csv",r)
                
            }
            setLoading(false);
        })
    }

    return(
        <div>
            <NavigationWrapper items={[{name:`All Project`,path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`MPR Report`}]} />
            <div className="title-container-1" style={{display:'flex'}}>
                <Button loading={loading} onClick={onClick} text="Export Report" style={{width:350,marginTop:20}} />
                <Button loading={loading1} onClick={()=>{
                    setLoading1(true);
                    get(`reports/custom/ate/reports/gSheet/mprSync/${id}`,(e,r)=>{
                        if(r) {
                            if(r.authenticationRequired) {
                                post(`reports/custom/ate/reports/authenticationToken/${id}`,{},(e,res)=>{
                                    if(res) {
                                        setLoading1(false);
                                        window.open(res.authenticationUrl,'_blank')
                                    }
                                })
                            }
                        }
                    })
                }} text="Sync MPR" style={{width:350,marginTop:20,marginLeft:20}} />
            </div>
        </div>
    )
}

export default Index;