
const Chips = (props) => {
        let item = props.val
        return (
            <span style={{
                borderRadius: 4,
                padding: "6px 18px",
                marginRight: 20,
                cursor: 'pointer',
                fontSize: 13,
                fontWeight: '600',
                border: '1px solid #fff',
                borderColor: item.color,
                textAlign:'center',
                marginBottom:10,
                width:145,
                ...item.active ?
                    {
                        backgroundColor: item.color,
                        color: '#fff'
                    }
                    : {

                    }
            }} onClick={props.onClick}>
                {item.name}
            </span>
        )
}

export { Chips }