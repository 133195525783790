import { useState } from "react";
import { useParams} from 'react-router-dom';
import { post } from "../../../../../Model/Network/Config/Axios";
import { DownloadDirectCsv } from "../../../../../Utils/Downloader";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import BulkUpload from './BulkUpload.js';
import Spreadsheet from "react-spreadsheet";
import { PageLoader } from "../../../../Common/PageLoader";
import './index.css';
import { TaskDateFilter } from "../../../../NCommon/DateFilters/TaskDateFilter";


const Index = () => {

    const [filters,setFilters] = useState([]);
    const [filterData,setFilterData] = useState([]);
    const [upload,setUpload] = useState(false);
    const [mData,setMData] = useState(undefined);
    const [dates,setDates] = useState(undefined);
    const [loading,setLoading] = useState(false);
    let {id} = useParams();

    const [status,setStatus] = useState([
        {
            name: "Progress",
            active: false,
            color: "#ebb840",
            key:'PROGRESS'
          },
          {
            name: "Acquired",
            active: false,
            color: "#404959",
            key:'ACQUIRED'
          },
          {
            name: "Not Started",
            active: false,
            color: "#ff944d",
            key:'NOTSTARTED'
          },
          {
            name: "Completed",
            active: false,
            color: "#68d083",
            key:'COMPLETED'
          },
          {
            name: "Critical",
            active: false,
            color: "#ea3a3c",
            key:'CRITICAL'
          },
    ])

    const [resourcesType,setResourceType] = useState([
        {
            name: "Labour",
            active: false,
            color: "#ebb840",
            key:'LABOUR'
          },
          {
            name: "Material",
            active: false,
            color: "#404959",
            key:'MATERIAL'
          },
          {
            name: "Machines",
            active: false,
            color: "#ff944d",
            key:'MACHINES'
          },
    ])
    

    let download = () => {
        let data = {};

        if(filterData && filterData.length) {
            data.orFilters = [...filterData];
        }
        let types = data.orFilters? [...data.orFilters]:[];
        resourcesType.forEach(val=>{
            if(val.active) {
                types.push({key:'RESOURCECATEGORYTYPE',val:val.key})
            }
        })
        let otherNodes =[];
        status.forEach(val=>{
            if(val.active) {
              otherNodes.push({key:val.key,val:''})
            }
        })
        if(dates) {
            otherNodes = [...otherNodes,{key:'START',val:dates.startDate},{val:dates.endDate,key:'END'}]
        }
        data.andFilters=otherNodes;
        data.orFilters=types;
        setLoading(true);
        post(`project/resourceView/horizontalResourcesCsvDump/${id}`,data,(e,r)=>{
            if(r) {
                let items = [];
                let column = [];
                let mTemp = r.split('\n');
                mTemp.forEach((val,index)=>{
                    if(index===0) {
                        let sItem = val.split(',');
                        sItem.forEach(i=>{
                            column.push(i);
                        })
                    } else {
                        let item = [];
                        let sItem = val.split(',');
                        sItem.forEach(i=>{
                            item.push({value:i});
                        })
                        items.push(item)
                    }
                }) 
                setMData({
                    items,
                    column
                })     
                setLoading(false);       
                DownloadDirectCsv('resource.csv',r)
            } else {
                setLoading(false);
            }
        })
    }

    return(
        <>
            <HeaderWrapper 
                showFilter={false} 
                dynamicFilter={true} 
                types={["Resource","Resource Category","Task","Wbs Mapping","Responsible Person","Approver","Custom Text"]}
                filters={[...filters]} 
                onFilterChange={items=>{
                    let filter = [];
                    items.forEach(item=>{
                        filter.push({
                            key:item.type,
                            val:item.type==='TASK'?`${item.id}`: item.type === 'WBS' ? `${item.id}`:item.value
                          })
                    })
                    setFilters(items)
                    setFilterData(filter)
                }} 
                status={status}
                onChangeStatusFilter={setStatus}
                statusFilter={true}
                otherStatus={{
                    name:"Category",
                    items:resourcesType
                }}
                setOtherStatus={setResourceType}
                menuItem={[{name:'Download Csv',type:'downloadcsv',onClick:download},{name:'Upload',type:'uploadcsv',onClick:()=>{setUpload(true)}}]}
                >
                    <TaskDateFilter onSelect={setDates} />
                </HeaderWrapper>
            {
                loading?
                <PageLoader />:
                mData?
                <div style = {{overflowX:'auto',padding:20,fontSize:14}}>
                    <Spreadsheet columnLabels={mData.column} data={mData.items} />
                </div>
                :null
            }

            {
                upload ?
                <BulkUpload 
                    onClose={()=>{setUpload(false)}} 
                    id={id}
                    />
                :null
            }
        </>
    )
}

export default Index;