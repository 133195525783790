import React from 'react';
import { Button } from '../../../Common/Button';
import { FloatInput } from '../../../Common/FloatInput';
import { MeasurementType } from '../../../Common/Search/MeasurementType';
import { SearchAttributeType } from '../../../Common/Search/SearchAttributeType';
import { SearchWbsItem } from '../../../Common/Search/SearchWbsItem';
import { post } from '../../../../Model/Network/Config/Axios';
import { showToast } from '../../../Common/Toast';



class AddWbsItem extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            customFields: []
        }
    }


    getId = () => {
        return +this.props.match.params.id;
    }


    createWbsItem = () => {
        let wbsItemName = this.name.getValue();
        let description = this.details.getValue();


        if (!wbsItemName) {
            return;
        }
        if (!description) {
            return;
        }

        let data = {
            wbsItemName,
            description,
            attributes:[],
            customFields:{},
            wbsItemCategoryId: this.getId()
        }

     

        post(`project/wbs/wbsItem.create`, data, (e, r) => {
            if (r) {
                showToast({
                    type:'success',
                    message:"Wbs Item created Successfully"
                })
                this.props.onComplete()
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })

    }


    getCustomField = () => {
        let customFields = {};
        this.state.customFields.forEach(val => {
            let key = this[`key${val.index}`].getValue();
            let value = this[`value${val.index}`].getValue();

            if (!key) {
                return;
            }

            if (!value) {
                return
            }

            customFields[key] = value

        })

        return customFields;
    }

    getAttributes = () => {
        let attributes = [];
        this.state.attributes.forEach(val => {
            let name = this[`attributeName${val.index}`].getValue();
            let attributeType = this[`attributeType${val.index}`].getValue();
            let measurementType = this[`measurementType${val.index}`].getValue();
            let cost = this[`cost${val.index}`].getValue();
            let units = this[`units${val.index}`].getValue();

            if (!name) {
                showToast({
                    type:'error',
                    message:'Please enter the name'
                })
                return;
            }

            if (!attributeType) {
                showToast({
                    type:'error',
                    message:'Please select the attribute type'
                })
                return;
            }

            if (!measurementType) {
                showToast({
                    type:'error',
                    message:'Please select the measurement type'
                })
                return;
            }

            if (!cost) {
                showToast({
                    type:'error',
                    message:'Please enter the cost'
                })
                return;
            }

            if (!units) {
                showToast({
                    type:'error',
                    message:'Please enter the units'
                })
                return;
            }

            let data = {
                name,
                attributeType,
                measurementType,
                cost: Number(cost),
                units: Number(units)
            }
            attributes.push(data);
        })

        return attributes;
    }

    mapAttributes = (item, index) => {
        return (
            <div key={index}>
                <div style={{
                    display: 'flex',
                    marginTop: 10,
                }}>
                    <p style={{
                        fontSize: 14
                    }}>Attribute {index + 1}</p>
                    <p style={{
                        marginLeft: 'auto',
                        color: 'var(--red-color)',
                        fontWeight: '600',
                        cursor: 'pointer'
                    }} onClick={() => {
                        let attributes = this.state.attributes;
                        attributes = attributes.filter(i => i.index !== item.index);
                        this.setState({
                            ...this.state,
                            attributes
                        })
                    }}>Remove</p>
                </div>
                <div className="flex" style={{
                    flex: 1
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 30
                    }}>
                        <FloatInput title={`Attribute Name ${index + 1}`} type="text" handleRef={ref => this[`attributeName${item.index}`] = ref} />
                    </div>

                    <div style={{
                        flex: 1,
                    }}>
                        <SearchAttributeType title={`Attribute Type ${index + 1}`} type="text" handleRef={ref => this[`attributeType${item.index}`] = ref} />
                    </div>
                </div>
                <div className="flex" style={{
                    flex: 1
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 30

                    }}>
                        <MeasurementType title={`Measurement Type ${index + 1} (optional)`} type="text" handleRef={ref => this[`measurementType${item.index}`] = ref} />
                    </div>
                    <div style={{
                        flex: 1,
                    }}>
                        <FloatInput title={`Costs ${index + 1}`} type="text" handleRef={ref => this[`cost${item.index}`] = ref} />
                    </div>


                </div>
                <div className="flex" style={{
                    flex: 1
                }}>

                    <div style={{
                        flex: 1,
                        marginRight: 30
                    }}>
                        <FloatInput title={`Units ${index + 1}`} type="text" handleRef={ref => this[`units${item.index}`] = ref} />
                    </div>

                    <div style={{
                        flex: 1
                    }} />

                </div>
            </div>
        )
    }


    render() {
        return (
            <>
                <FloatInput title="Name" type="text" handleRef={ref => this.name = ref} />
                <FloatInput title="Details" type="text" handleRef={ref => this.details = ref} />
                <Button
                    text="Create Wbs Item"
                    style={{
                        marginTop: 30
                    }}
                    onClick={() => {
                        this.createWbsItem();
                    }}
                />
            </>
        )
    }
}


export default AddWbsItem;