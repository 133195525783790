import  { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { PageLoader } from '../../../Common/PageLoader';
import { AccessButton, Button } from '../../../Common/Button';
import { get,axios, post } from '../../../../Model/Network/Config/Axios';
import AddTaskCategory from './AddTaskCategory';
import { Upload } from '../../../Common/Upload';
import { useDictionaryValue } from '../../../Hooks/DictionaryHooks';
import { Header } from '../../../NCommon/Header';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { PageWrapper } from '../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import { useTableCount } from '../../../Hooks/TableCount';
import {PaginationTable} from '../../../NCommon/Tables/PaginationTable';
import { Card } from '../../../NCommon/Ui/CardLayout';
import {List} from '../../../NCommon/Ui/List';
import { Modal } from '../../../Common/Modal/Modal';
import { showToast } from '../../../Common/Toast';
import { TableMenu } from '../../../NCommon/Tables/TableMenu';
import BulkUploadResourceMapping from './BulkUploadResourceMapping';
import { AddTag } from '../../../Common/Tag/AddTag';
import { ViewTag } from '../../../NCommon/Tag';
import { RightModal } from '../../../Common/Modal/RightModal';
import { FunctionalInput } from '../../../Common/FloatInput/FunctionalInput';



const Index = () => {

    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [csv, setCsv] = useState(false);
    const [filters,setFilters] = useState([]);
    const [edit,setEdit] = useState(false);
    const [deleteModal,setDeleteModal] = useState(undefined);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [sorted,setSorted] = useState('NAME');
    const [bulk,setBulk] = useState(false);
    const [up,setUp] = useState(true);
    const [tag,setTag] = useState(false);
    const [updateId,setUpdateId] = useState(1);
    let NAME=useRef(),DETAILS=useRef();


    const getDictionaryValue = useDictionaryValue();
    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    let HISTORY = useHistory();
    let count = useTableCount();

    useEffect(() => {
       INIT();
    }, [count,up,sorted])

    let INIT = (data) => {
        if(!data) {
            data={page:0}
        }
        if(data.filter) {
            data = {
                ...data,
                ...data.filter
            }
            delete data.filter
        }
        data.size = `${count}`;
        data.offset = (data.page*count).toString();
        data.ascending = up;
        data.sortedBy = sorted;
        get(`project/task/taskCategory.all`, (e, r) => {
            if (r) {
                setMData({
                    ...mData,
                    page:data.page,
                    total:r.total?r.total:data.total,
                    items:r.taskCategoryDetails
                })
                setLoading(false);
            }
        },data)
    }


    let mapListLayout = (item,index) => {
        return(
            <List key={index} onClick={() => HISTORY.push(`/app/task/${item.id}?name=${item.name}`)}>
                <div style={{flex:1}}>
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.name}</p>
                </div>
                <div style={{flex:1}}>
                    <ViewTag update={updateId} id={item.id} type={'TASK_CATEGORY'} add={()=>{setTag(item.id)}}/>
                </div>
                <TableMenu items={[{name:'Edit',onClick:(e)=>{e.stopPropagation();setEdit(item)}},{name:'Delete',onClick:(e)=>{e.stopPropagation();setDeleteModal(item)}},{name:'Add Tag',onClick:()=>{}}]} />
            </List>

        )
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card 
                key={index} 
                onClick={() => {
                    HISTORY.push(`/app/task/${item.id}?name=${item.name}`)
                }}
                title={item.name} 
                desc={item.details} 
                items={[{name:'Edit',onClick:(e)=>{e.stopPropagation();setEdit(item)}},{name:'Delete',onClick:(e)=>{e.stopPropagation();setDeleteModal(item)}}]}
                >
                <div style={{marginTop:"auto"}}>
                    <div style={{borderTop:'0.5px solid var(--border-color)',height:22,marginLeft:-12,marginRight:-12,display:'flex',alignItems:'center'}}>
                        <ViewTag update={updateId} id={item.id} type={'TASK_CATEGORY'} add={()=>{setTag(item.id)}}/>
                    </div>
                </div>
            </Card>
        )
    }
   

    let deleteTask = () => {
        axios.delete(`/project/task/taskCategory.delete/${deleteModal.id}`, {

        })
            .then(res => {
                setButtonLoading(false);
                setDeleteModal(false);
                INIT({page:0})
                showToast({
                    type: 'success',
                    message: `Item Deleted Successfully`
                })
            })
            .catch(res => {
                showToast({
                    type: 'error',
                    message: res.data.message
                })
                setButtonLoading(false);
            }) }


     let handleSort = (sortType) => {
            if(sortType === sorted) {
                setUp(!up);
            } else {
                setSorted(sortType);
                setUp(true)
            }
        }       
    return (
        <>
            <Header title={`${getDictionaryValue('Task')} Category`} items={[]} />
           
            {
                loading ?
                    <PageLoader />
                    :
                    <>
                        <HeaderWrapper
                             types={["Task Category","Task Item"]}
                             dynamicFilter={true}
                             filters={[...filters]}
                             onFilterChange={items=>{
                                let filter = {};
                                items.forEach(item=>{
                                    filter['name'] = item.label;
                                })
                                setFilters(items)
                                INIT({page:0,filter})
                            }}
                            menuItem={[
                                {name:`${getDictionaryValue('Task')} Upload Csv`,type:'uploadcsv',onClick:()=>{setCsv(true)}},
                                {name:`Bulk Resource Upload`,type:'uploadcsv',onClick:()=>{setBulk(true)}},
                            ]}
                        />
                        <PageWrapper scroll={true} id="pagination-table-header">
                            <NavigationWrapper items={[{name:`${getDictionaryValue('Task')} (${mData.total})`}]}>
                                <AccessButton text="Add Category" onClick={()=>{setModal(true)}} inverted={true?1:0} addsign={true?1:0} style={{padding:'0px 10px'}} />
                            </NavigationWrapper>
                            <PaginationTable
                                    DATA={mData.items}
                                    next={(page)=>{
                                        INIT({page})
                                    }}
                                    mapListLayout={mapListLayout}
                                    mapCardLayout={mapCardLayout}
                                    total={mData.total}
                                    page={mData.page}
                                    headers={[
                                        {
                                            name:'Details',
                                            flex:1,
                                            sort:sorted==='NAME',
                                            onSortClick:()=>handleSort('NAME'),
                                            up
                                        },
                                        {
                                            name:'Tags',
                                            flex:1
                                        },
                                        {
                                            name:'actions',
                                            width:50
                                        }
                                    ]}
                                />
                        </PageWrapper>
                    </>
            }
            {
                tag ?
                 <AddTag type="TASK_CATEGORY" referenceId={tag.id} close={()=>{setTag(false)}} init={()=>{
                    INIT({page:mData.page});
                    setTag(false);

                 }}  />
                :null

            }
            {
                bulk?
                <BulkUploadResourceMapping onClose={()=>{setBulk(false)}} />
                : null
            }
            {
                modal ?
                     <AddTaskCategory
                            onComplete={() => {
                                INIT();
                                setModal(false)
                            }}
                            onClose={()=>{
                                setModal(false);
                            }}
                        />
                    : null
            }

             <RightModal
                    title={`Edit ${edit.name}`}
                    desc="Edit the details of Task category"
                    show={edit}
                    onClose={() => {
                        setEdit(false);
                    }}
                 >

                    <FunctionalInput type="text" ref={NAME} title="Name"/>
                    <FunctionalInput type="text" ref={DETAILS} title="Details" />
                    <Button
                        text="Update"
                        style={{
                            marginTop: 20
                        }}
                        loading={buttonLoading}
                        onClick={() => {
                            let name = NAME.current.value;
                            let details = DETAILS.current.value;

                            if (!name) {
                                showToast({
                                    type: 'error',
                                    message: 'Please enter the name'
                                })
                                return
                            }
                            if (!details) {
                                showToast({
                                    type: 'error',
                                    message: "please enter the details"
                                })
                                return
                            }

                            setButtonLoading(true);
                            post(`project/task/taskCategory.update/${edit.id}`, {
                                name,
                                details,
                            }, (e, r) => {
                                if (r) {
                                    showToast({
                                        type: 'success',
                                        message: "Task Category Details update successfully"
                                    })
                                    INIT();
                                    setEdit(false);
                                    setButtonLoading(false);
                                }
                                if (e) {
                                    showToast({
                                        type: "error",
                                        message: e.data.message
                                    })
                                    setButtonLoading(false);
                                }
                            })
                        }}

                    />

                </RightModal>

            {
                csv ?
                    <Upload
                        onClose={() => {
                            setCsv(false);
                        }}
                        title="Upload"
                        desc="Please upload your csv to create task"
                        name="Select File"
                        type="task"
                        endPoint="project/task/task.upload"
                        fileName="task.csv"
                        csvData={[
                            ["taskcategory", "taskname", "details", "tasktype"],
                            ["Plumbing", "Plumbing", "Plumbing Task for Floor", "REGULAR"]
                        ]}
                        islink={true}
                        onComplete={() => {
                            INIT();
                            setCsv(false);
                        }}
                    />
                    : null
            }

                {
                    deleteModal ?
                        <Modal
                            title={`Delete Task Category`}
                            desc={`Delete ${deleteModal.name}`}
                            show={deleteModal}
                            onClose={() => {
                                setDeleteModal(false);
                            }}
                            style={{
                                minHeight: 150
                            }}
                        >
                            <p style={{
                                marginTop: 50
                            }}>Are you sure want to delete <span style={{
                                fontWeight: 600
                            }}>
                                    {deleteModal.name}
                                </span> ? </p>

                            <Button
                                text="Delete"
                                style={{
                                    marginTop: 50,
                                    backgroundColor: 'var(--red-color)',
                                    borderColor: 'var(--red-color)'
                                }}
                                onClick={() => {
                                    setButtonLoading(true);
                                    deleteTask();
                                }}
                                loading={buttonLoading}
                            />
                        </Modal>
                        : null

                }

                {
                    tag?
                    <AddTag referenceId={tag} type="TASK_CATEGORY" init={()=>{
                        setTag(false);
                        setUpdateId(updateId+1)
                        INIT({page:mData.page})
                    }} close={()=>setTag(false)} />
                    :null
                }

        </>
    )
}


export default Index;