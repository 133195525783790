import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import axios from '../../../Model/Network/Config/Axios';
import { showToast } from '../Toast';


const FunctionalFile = forwardRef((props,ref)=>{

    let file = useRef();
    let [url,setUrl] = useState(undefined);
  

    let onChange = () => {
        let fileValue = file.current.files[0];
        if(fileValue) {
            const formData = new FormData();
            formData.append('file', fileValue);
            axios({
                method: 'post',
                url: `https://api.buildrun.app/main/admin/auth/upload`,
                data: formData,
                
            })
                .then(res => {
                    if (res.data.status === 'success') {
                        setUrl(res.data.url);
                    }

                })
                .catch(res => {
                    showToast({
                        type: 'error',
                        message: res.message
                    })
                })

        } else {
            showToast({
                type:'error',
                message:"Please select the valid file"
            })   
        }
    }

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return url
        }
    }))
  

     return (
            <div  style={{flex: 1,...props.style}}>
                <p style={{ fontSize: 12, color: 'var(--title-color)',  fontWeight: 600, marginTop: 15}}>{props.title} </p>
                <input ref={ref=>file.current=ref} className="finput" disabled={props.disabled?props.disabled:false} type="file" {...props.inputProps} 
                        placeholder={props.placeholder ? props.placeholder : props.title}
                        autoComplete="off"
                        onChange={onChange}
                    />
            </div>
        )
    
})



export {FunctionalFile}