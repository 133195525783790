import  { useEffect, useState } from 'react';
import _ from 'lodash';
import { PageLoader } from '../../../../Common/PageLoader';
import { get, post } from '../../../../../Model/Network/Config/Axios';
import { DownloadCsv } from '../../../../../Utils/Downloader';
import { showToast } from '../../../../Common/Toast';
import { HeaderWrapper } from '../../../../NCommon/HeaderWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { Card } from '../../../../NCommon/Ui/CardLayout';
import { List } from '../../../../NCommon/Ui/List';
import {Table} from '../../../../NCommon/Tables/index';
import { TableMenu } from '../../../../NCommon/Tables/TableMenu';


const Index = () => {

    const [employees,setEmployees] = useState([]);
    const [loading,setLoading] = useState(true);


    useEffect(()=>{
        INIT()
    },[])

    let INIT = () => {
        get(`employees/inactiveEmployees`, (e, r) => {
            if (r) {
                setEmployees(r.employees);
                setLoading(false);
            }
        })
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card height={120} items={[{name:'Restore',onClick:(e)=>{e.stopPropagation();restore(item.id)}}]}   icon={true} key={index} title={item.name} desc={item.email}>
                <div style={{marginLeft:55}}>
                    <p className="tiny-text">{item.employeeCode}</p>
                </div>
            </Card>
        )
    }

   

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.email}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.employeeCode}</p>
                </div>
                <TableMenu items={[{name:'Restore',onClick:(e)=>{e.stopPropagation();restore(item.id)}}]} />
            </List>
        )
    }

    let restore = (id) => {
        post(`/employees/activateEmployee/${id}`,{},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:'Employee restored successfully'
                })
                INIT();
            }
        })
    }


   let downloadCsv = () => {
        let baseData = [[
            "Name",
            "Email",
            "Code",
            "Mobile",
            "Dob",
            "Role",
            "Manager",
            "Created At"

        ]]
        employees.forEach(val => {
            baseData.push([
                val.name,
                val.email,
                val.employeeCode,
                val.mobile,
                val.dob,
                val.role,
                val.manager,
                val.createdAt

            ])
        })

        DownloadCsv(`employee.csv`, baseData);
    }


     return (
            <>
                {
                    loading ?
                        <PageLoader />
                        :
                        <>
                            <HeaderWrapper  menuItem={[{name:'Download Csv',type:'downloadcsv',onClick:downloadCsv}]}/>
                            <PageWrapper id="pagination-table-header" scroll={true}>
                                <NavigationWrapper items={[{name:`Deleted Employees (${employees.length})`}]}/>
                                <Table 
                                    DATA={employees}
                                    mapCardLayout={mapCardLayout}
                                    mapListLayout={mapListLayout}
                                    headers={[
                                        {
                                            name:'Details',
                                            flex:1
                                        },
                                        {
                                            name:'Employee Code',
                                            flex:1
                                        },
                                        {
                                            name:'actions',
                                            width:50
                                        }
                                    ]}
                                    />
                            </PageWrapper>
                        </>
                }
            </>
        )
}

export default Index;