import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Login from './Login';
import TwoFactor from './TwoFactor/index';
import Reset from './Reset';
import AuthenticatedController from './AuthenticatedController';
import storeData from '../Redux/index';
import { ToastContainer } from '../Component/Common/Toast/ToastContainer';
import { Toaster } from 'react-hot-toast';


const store = createStore(storeData, applyMiddleware(thunk));

const Index = () => {


    return (
        <>
        <ToastContainer />
        <Toaster />
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route path="/" component={Login} exact />
                    <Route path="/reset" component={Reset} exact />
                    <Route path="/twofactor" component={TwoFactor} exact />
                    <Route path="/app" component={AuthenticatedController} />
                </Switch>
            </BrowserRouter>
        </Provider>
        </>
    )
}

export default Index;