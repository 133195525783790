import { useState } from 'react';
import {useParams,useHistory} from 'react-router-dom';
import { Modal } from '../../../../Common/Modal/Modal';
import { AccessButton, Button } from '../../../../Common/Button';
import { deleteApi } from '../../../../../Model/Network/Config/Axios';
import { showToast } from '../../../../Common/Toast';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';



const Index = () => {

    const [deleteModal,setDeleteModal] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);

    let {id} = useParams();
    let HISTORY = useHistory();


   let deleteEmployee = () => {
        deleteApi(`employees/${id}`, (e, r) => {
            if(r) {
                showToast({
                    type:'success',
                    message:'User Delete Successfully'
                })
                setDeleteModal(false);
                HISTORY.push(`/app/employee`)
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
          
        })
    }


    return (
            <>
                <PageWrapper>
                    <NavigationWrapper items={[{name:"All Employees",path:'/app/employee'},{name:'Setting'}]} />
                    <div className="title-container-1" style={{padding: 25}}>
                        <p style={{
                            fontWeight: '700',
                            fontSize: 22
                        }}>Delete Employee</p>
                        <p style={{
                            fontSize: 16,
                            marginTop: 5
                        }}>
                            Employee can only be deleted if not tagged in any task or after finishing task
                        </p>

                        <AccessButton
                            text="Delete Employee"
                            style={{
                                backgroundColor: 'var(--red-color)',
                                borderColor: 'var(--red-color)',
                                width: 300,
                                marginTop: 30
                            }}
                            onClick={() => {
                                setDeleteModal(id)
                            }}
                        />
                    </div>
                </PageWrapper>
                {
                       deleteModal ?
                            <Modal
                                show={deleteModal}
                                title="Delete Employee"
                                desc="By clicking button below delete selected Employee"
                                style={{
                                    minHeight: 200
                                }}
                                onClose={() => { setDeleteModal(false) }}
                            >

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginTop: 30
                                }}>

                                    <p style={{
                                        fontSize: 18,
                                        color: 'var(--light-color)'
                                    }}>
                                        By clicking below you will be deleting selected employee and data related to this employee
                                      </p>

                                    <Button
                                        text="Delete"
                                        style={{
                                            backgroundColor: 'var(--red-color)',
                                            borderColor: 'var(--red-color)',
                                            marginTop: 30
                                        }}
                                        loading={buttonLoading}
                                        onClick={deleteEmployee}
                                    />
                                </div>
                            </Modal>
                            : null
                    }
                    
            </>
        )
}

export default Index;