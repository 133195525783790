import  {useEffect} from "react";
import {setCb} from "./index";
import toast from 'react-hot-toast'


const ToastContainer = () => {
	useEffect(()=>{
		setCb((message,type)=>{
			if(type){
				toast.success(message,{
					position: "top-center",
					
				})
			}else{
				toast.error(message,{
					position: "top-center",
					
				})
			}
		})
		return () => {
			setCb(undefined)
		}
	},[])
	return(
		<div/>
	)

}

export {
	ToastContainer
}
