import { BarGraph } from "../../../../Common/Graphs";
import Container from "../../../../Common/Graphs/Container";


const Index = ({data}) => {

    let completed = [];
    let delay = [];
    let started = [];
    let total = [];
    let categories = [];

    Object.keys(data).forEach(val=>{
        categories.push(val);
        completed.push(data[val]["acquiredQty"]);
        delay.push(data[val]["wastageQty"]);
        started.push(data[val]["usedQty"]);
        total.push(data[val]["totalQty"])
    })

    return(
        <Container>
            <BarGraph 
                title="Resource Overview"
                categories={categories}
                data={[
                    {
                        name:'Acquired Quantity',
                        data:completed
                    },
                    {
                        name:'Wastage Quantity',
                        data:delay
                    },
                    {
                        name:'Used Quantity',
                        data:started
                    },
                    {
                        name:"Total Quantity",
                        data:total
                    }
                ]
                }
                position="top"
                height={categories.length>2?180*categories.length:300}
                dataLabels={{
                    enabled: false,
                    formatter: function (val) {
                        return val;
                    },
                    offsetY: 0,
                    style: {
                        fontSize: '12px',
                        colors: ["#fff"]
                    }
                }}
                colors={["#03fc98","#ff6347","#e7a918","#0073ea"]}
                
                />
        </Container>
    )
}

export default Index;