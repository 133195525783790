import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';



class MeasurementType extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            attributeTypes: [],
            value: '',
            suggestion: []
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
        get(`project/wbs/measurementType`, (e, r) => {
            if (r) {
                this.setState({
                    ...this.state,
                    attributeTypes: r.types
                })
            }

        })
    }


    onSearch = (val) => {
        let suggestion = this.state.attributeTypes.filter(name => name.toLowerCase().includes(val.toLowerCase()));
        if (val.length > 0) {
            this.setState({
                ...this.state,
                suggestion
            })
        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }

    }


    getValue = () => {
        return this.state.value;
    }


    render() {
        return (
            <div style={{
                position: 'relative'
            }}>
                <FloatInput
                    title={this.props.title}
                    type="text"
                    handleRef={ref => this.attribute = ref}
                    onChange={(val) => {
                        this.onSearch(val)
                    }}
                />
                {
                    this.state.suggestion.length > 0 &&
                    <div style={{
                        position: 'absolute',
                        top: 65,
                        border: '1px solid var(--border-color)',
                        height: 200,
                        backgroundColor: '#fff',
                        width: '100%',
                        overflowY: 'scroll',
                        backgroundColor: "#fff"
                    }}>
                        {this.state.suggestion.map((item, index) => (
                            <div key={index} style={{
                                height: 40,
                                borderBottom: '1px solid var(--border-color)',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: 10,
                                fontSize: 14,
                                backgroundColor: '#fff',

                            }} onClick={() => {

                                this.setState({
                                    ...this.state,
                                    suggestion: [],
                                    value: item
                                })
                                this.attribute.setValue(item);
                            }}>
                                {item}
                            </div>
                        ))}

                    </div>
                }
            </div>
        )
    }
}

export { MeasurementType };