
const List = (props) => {
    
    return(
        <div className={props.className}  onClick={props.onClick?()=>props.onClick():{}} style={{display:'flex',borderBottom:'1px solid var(--border-color)',cursor:'pointer',paddingLeft:13,height:60,alignItems:'center',flex:1,...props.style}}>
            {props.children}
        </div>
    )
}

List.defaultProps = {
    onClick:()=>{},
    className:''
}

export {List}