import {useHistory} from 'react-router-dom';


const Tab = ({items,title}) => {

    let HISTORY = useHistory();

    let mapItem = (item,index) => {
        let style = {
            cursor:'pointer',
            fontSize:15,
            marginRight:10,
            border:'2px solid var(--theme-main-color)',
            borderRadius:4,
            height:40,
            display:'flex',
            alignItems:'center',
            padding:'0px 10px',
            marginBottom:20
        }
        if(item.active) {
            style.fontWeight = '700';
            style.backgroundColor = 'var(--theme-main-color)';
            style.color='#fff'
        }
        return(
            <div onClick={()=>HISTORY.push(item.link)} key={index} style={style}>
                {item.name}
            </div>
        )
    }

    return(
        <div style={{display:'flex',alignItems:'flex-end',width:'100%',overflowX:'auto'}}>
            {items.length===0?<p style={{fontWeight:'700',fontSize:22,paddingBottom:22}}>{title}</p>:items.map(mapItem)}
        </div>
    )
}

Tab.defaultProps = {
    items:[]
}

Tab.propsTypes = {


}
export {Tab}


