import {  useState } from "react";
import { LineHeader } from "../../../Common/LineHeader";
import { CenterModal } from "../../../Common/Modal/CenterModal";
import TaskDetails from './TaskDetails';
import ViewResource from './ViewResource';
import ViewDrawing from './ViewDrawing';

const Index = (props) => {

    let [key,setKey] = useState('details');


    return(
    <>
        <CenterModal
            onClose={props.onClose}
            header={props.item.taskName}
        >
         <div style={{padding:25,marginTop:60}}>
           <LineHeader 
                items={[
                       {
                        name:"Details",
                        active:key==='details',
                        onClick:()=>{setKey('details')}
                       },
                       {
                        name:'Resource',
                        active:key === 'resource',
                        onClick:()=>{
                            setKey('resource')
                        }
                       },
                       {
                        name:'Drawing',
                        active:key === 'drawing',
                        onClick:()=>{
                            setKey('drawing')
                        }
                       }
                    ]
                }
           />
           {
               key==='details'?<TaskDetails {...props} />:null
           }
           {
               key === 'resource'?<ViewResource {...props} />:null
           }
           {
                key === 'drawing' ?<ViewDrawing {...props} />:null
           }
         </div>
          

        </CenterModal>
       
    </>
    )
}

export default Index;