import {useLocation,Switch,Route,useParams} from 'react-router-dom';
import { Header } from "../../../NCommon/Header";
import Pending from './Pending';
import Past from './Past';


const Index = () => {

    let route = useLocation().pathname;
    let {id} =useParams();

    return(
        <>
            <Header items={[
                  {
                    name: 'Pending Request',
                    link: `/app/project/procurement/${id}`,
                    active: route === `/app/project/procurement/${id}`
                  },
                  {
                    name:'Past Request',
                    link:`/app/project/procurement/${id}/past`,
                    active: route === `/app/project/procurement/${id}/past`
                  }
                ]} />
            <Switch>
                <Route path="/app/project/procurement/:id" component={Pending} exact />
                <Route path="/app/project/procurement/:id/past" component={Past} exact />
            </Switch>
        </>
    )
}

export default Index;