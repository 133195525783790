const pallete = {
		a: "#C62828",
		b: "#AD1457",
		c: "#6A1B9A",
		d: "#4527A0",
		e: "#283593",
		f: "#1565C0",
		g: "#0277BD",
		h: "#00838F",
		i: "#00695C",
		j: "#2E7D32",
		k: "#558B2F",
		l: "#9E9D24",
		m: "#F57F17",
		n: "#E65100",
		o: "#4E342E",
		p: "#424242",
		q: "#37474F",
		r: "#884EA0",
		s: "#239B56",
		t: "#626567",
		u: "#C62828",
		v: "#880E4F",
		w: "#4A148C",
		x: "#4527A0",
		y: "#5D4037",
		z: "#212121"
};


export {pallete}