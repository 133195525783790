import { useRef, useState } from "react";
import FilterSearch from "./FilterSearch";
import Types from './Types';

const StaticFilter = (props) => {

    let {suggestion} = props;
    const [type,setType] = useState(props.types[0]);
    const [filterData,setFilterData] = useState([]);
    const [filters,setFilters] = useState([]);
    let searchKey = useRef();

    let search = (val) => {
        let value = val.toLowerCase();
        let data = suggestion[type];
        let result = data.filter(item=>{
            if(item.first.toLowerCase().includes(value)) {
                return true
            };
            return false;
        })
        setFilterData(result)
    }

    let mapSuggestionItem = (item,index) => {
        return(
            <div onClick={()=>{
                addAndRemoveFilter({type,value:item.first},'add');
                setFilterData([]);
                searchKey.current.value ='';
            }} className="suggestion-modal-item" key={index}>{item.first}</div>
        )
    }


    let addAndRemoveFilter = (item,key) => {
        let items = [...filters];
        if(key==='add') {
            items = items.filter(val=>val.value!==item.value);
            items.push(item);
            props.onFilterChange(items)
            setFilters(items);
        } else {
            items = items.filter(val=>val.value!==item.value);
            props.onFilterChange(items)
            setFilters(items);
        }
    }


    return(
        <div style={{position:'relative',width:316}}>
            <div style={{display:'flex',position:'relative'}}>
                <FilterSearch ref={searchKey} type={type} onChange={search} />
                <Types onChangeType={setType} type={type} types={props.types} />
            </div>
            {
                filterData.length>0 && searchKey.current.value.length>0?
                <div className="suggestion-modal" style={{marginTop:-13,border:'none'}}>
                    {filterData.map(mapSuggestionItem)}
                </div>
                :null
            }
        </div>
    )
}

StaticFilter.defaultProps = {
    types:[]
}

export {StaticFilter}