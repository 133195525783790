


const Options = (props) => {


    let {title,desc,options} = props;
    return(
        <div style ={{marginTop:20,padding:15,border:'1px solid var(--border-color)',borderRadius:12}}>
            <div style ={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
            }}>
                <div style={{marginBottom:20}}>
                     <p style={{fontSize:18,fontWeight:700,color:'var(--text-color)'}}>{title}</p>
                     <p style={{fontSize:12,color:'var(--text-light-color)'}}>{desc}</p>
                </div>

                <div style ={{display:'flex',alignItems:'center'}}>
                    <p  onClick={()=>{
                        let list = options;
                        list = list.map(i=>{
                            return {
                                ...i,
                                active:true
                            }
                        })
                        props.onChange(list);
                    }}
                        style ={{fontWeight:700,fontSize:14,color:'var(--primary-color)',cursor:'pointer'}}>Select All</p>
                    <p onClick={()=>{
                         let list = options;
                         list = list.map(i=>{
                             return {
                                 ...i,
                                 active:false
                             }
                         })
                         props.onChange(list);
                    }}style={{fontWeight:700,fontSize:14,color:'red',marginLeft:10,cursor:"pointer"}}>Deselect All</p>
                </div>
            </div>
            

            <div style={{display:'flex',flexWrap:'wrap'}}>
                {options.map((val,index)=>{
                    let active = val.active;
                    return(
                        <div style ={{
                            width:'33%',
                            display:'flex',
                            alignItems:'center',
                            marginTop:15,
                            

                        }} key={index}>
                            <div style={{
                                border:'1px solid var(--border-color)',
                                height:15,
                                width:15,
                                cursor:'pointer',
                                ...active ?
                                {
                                    backgroundColor:'var(--active-color)'
                                }:
                                {
                                    backgroundColor:'#fff'
                                }
                            }} onClick={()=>{
                                let list = options;
                                list = list.map(i=>{
                                    if(i.key === val.key ) {
                                        return {
                                            ...i,
                                            active:!i.active
                                        }
                                    }
                                    return {
                                        ...i
                                    }
                                })

                                props.onChange(list);
                            }}/>
                            <p style={{fontSize:14,flex:1,marginLeft:5,overflow:'hidden',whiteSpace:'nowrap',textOverflow:"ellipsis"}}>{val.name}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


export default Options;