import {useLocation,Switch,Route,useParams} from 'react-router-dom';
import { Header } from '../../../NCommon/Header';
import List from './AllList';

const Index = () => {
    let route = useLocation().pathname;
    let {id} =useParams();
    return(
        <>
            <Header
                    items={[
                        {
                            name: 'Mapped Hindrance',
                            link: `/app/project/hindrance/${id}`,
                            active: route === `/app/project/hindrance/${id}`
                        }
                    ]}
            />
            <Switch>
                  <Route path="/app/project/hindrance/:id" component={List} exact />
            </Switch>
        </>
    )

}

export default Index;