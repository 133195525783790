import { useEffect, useState } from "react";
import { get } from "../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../Common/PageLoader";



const Index = ({projectId,inventoryId}) => {

    const [loading,setLoading] = useState(true);
    const [ledgers,setLedgers] = useState([]);

    useEffect(()=>{
        get(`project/inventory/fetchInventoryLedger/${projectId}/${inventoryId}`,(e,r)=>{
            if(r) {
                setLedgers(r.ledgers);
                setLoading(false);
            }
        })
    },[])

    if(loading) {
        return <PageLoader />
    }

    let mapLedger = (item,index) => {
        return(
            <div key={index} style={{
                display:'flex',
                flex:1,
                height:60,
                alignItems:'center',
                borderBottom:'1px solid var(--border-color)',
                padding:8
            }}>
                <div style={{flex:1}}>
                    {item.initialQty} <br></br>
                    <p style={{fontSize:12,color:'var(--text-light-color)'}}>{item.remarks}</p>
                </div>
                <div style={{flex:1,textAlign:'center',color:'var(--button-color)'}}>
                    {item.type !== 'DEBIT' ? item.qty:'-'}
                </div>
                <div style={{flex:1,textAlign:'center',color:'var(--red-color)'}}>
                    {item.type === 'DEBIT' ? item.qty:'-'}
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                    {item.currentQty}
                </div>
            </div>

        )
    }

    return(
        <div>
             <div style={{
                display:'flex',
                height:50,
                background:'var(--background-color)',
                marginBottom:10,
                borderRadius:8,
                alignItems:'center',
                fontSize:14,
                padding:'10px 15px',
                color:'var(--text-light-color)'
            }}>
                 <div style ={{
                    flex:1,
                }}>
                    Initial Quantity
                </div>
                <div style={{
                    flex:1,
                    textAlign:'center'
                }} className="single-line">
                    Credit
                </div>

                <div style={{flex:1,textAlign:'center'}}>
                   Debit
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                    Current Quantity
                </div>
            </div>
            {ledgers.length ?
            ledgers.map(mapLedger)
            : 

            <div>
                No Ledger Found
            </div>
        }
        </div>
    )
}

export default Index;