import tag from './tag.png';
import { useEffect, useState } from 'react';
import { get } from '../../../Model/Network/Config/Axios';
import './index.css';

const ViewTag = (props) => {

    let {type,id} = props;
    const [items,setItems] = useState([]);
    const [open,setOpen] = useState(false);
    

    useEffect(()=>{
        INIT()
    },[props.update,id])


    let INIT = () => {
        let data = {};
        data.type = type;
        data.referenceId = id;
        get(`setting/miscConfig/tags/getMappedTagging`,(e,r)=>{
            if(r) {
                setItems(r.tags)
            }
        },data)
    }

    let mapItem = (item,index) => {
        return(
            <div onClick={(e)=>{
                e.stopPropagation();
                console.log(e.clientX,e.clientY);
                if(open) {
                    setOpen(false);
                } else {
                    setOpen(items)
                    
                }
            }} key={index} style={{fontSize:10,background:'#bb99ff',padding:'2px 5px',borderRadius:4,marginRight:5,color:'#fff',fontWeight:'bold'}}>
                {item.name}
            </div>
        )
    }

    return(
        <div style={{position:'relative'}}>
          <div style={{display:'flex',alignItems:'center',height:22,marginTop:10,paddingLeft:5}}>
            {items.map(mapItem)}    
           <div onClick={(e)=>{
                e.stopPropagation();
                props.add();
           }} className='tag' style={{width:26,height:26,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%'}}>
                <img src={tag} style={{height:12,width:12}} />
            </div>
         </div>
         
        </div>
           
    )
}

export {ViewTag}