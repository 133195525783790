import { useSelector } from "react-redux"

const useAccess = () => {
 let {access} = useSelector(state=>{
     return {
         access:state.config.config.isFullWrite 
     }
 })
 return access;
}


export {useAccess}