import './PaginationTableLayout.css';
import { check, sortarrow } from '../../Common/Images';


const Index = ({data,mapItem,headers,tableStyle}) => {


   let mapHeader = (item,index) => {

        let style = {fontWeight:'600',position:'relative',color:'var(--text-light-color)',fontSize:12,...item.style};
        if(item.flex) {
            style.flex = item.flex;
        } 
        if(item.width) {
            style.width=item.width;
        }

        if(item.name==='checkbox') {
            return(
                <div style={style} onClick={item.onClick} key={index}>
                    {item.active?
                        <img src={check} alt="" style={{height:18,width:18,marginTop:5}} />:
                        <div style={{height:18,width:18,cursor:'pointer',border:'2px solid var(--border-color)'}} />
                    }
                </div>
            )
        }
        if(item.onSortClick) {
            return(
                <div style={{...style}} key={index} className="table-sort">
                    <span onClick={item.onSortClick} style={{padding:'5px 10px',cursor:'pointer',borderRadius:4,...item.sort?{background:'#dfe4ec',fontWeight:'700'}:{}}}>
                        {item.name}
                        {item.sort?<img src={sortarrow} alt="" style={{height:12,width:12,...item.up?{transform:'rotate(-90deg)'}:{transform:'rotate(90deg)'}}} />:null}
                    </span>
                   
                </div>
            )
        }
        return(
            <div key={index} style={style}>
                {item.name}
            </div>
        )
    }

    return(
        <div style={{background:'#fff',borderRadius:4,margin:10,marginTop:20,width:'inherit',position:'relative',...tableStyle}}  className="box-shadow">
            <div id="pagination-table-header" style={{height:50,display:'flex',alignItems:'center',flex:1,paddingLeft:12,borderBottom:'1px solid var(--border-color)'}}>
                {headers.map(mapHeader)}
            </div>
            <div>
                {data.map(mapItem)}
            </div>
        </div>
    )
}

Index.defaultProps ={
    headers:[]
}

export default Index;