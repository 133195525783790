import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { MultiSearchSelect } from '../../MultiSearchSelect';


class SearchTaskMappingItemN extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            items:[],
            selected:[]
        }
    }


    componentDidMount() {
        if(this.props.handleRef) {
            this.props.handleRef(this);
        }
        if(this.props.prefill){
            this.multi.prefill(this.props.prefill)
        }
    }


    onChange = (val) => {
        if(val.length>0) {
            get(`project/task/taskMapping.search/${this.props.projectId}`,(e,r)=>{
                if(r) {
                    this.setState({
                        ...this.state,
                        items:r.tasks.map(val=>({
                            label:val.taskMappingName,
                            value:val.id
                        }))
                    })
                }
            },{
                q:val
            })
        } else {
            this.setState({
                ...this.state,
                items:[]
            })
        }
    }


    getValue = () => {
        return this.multi.getValue();
    }

    render() {
        return(
            <div style ={{
                flex:1
            }}>
                <MultiSearchSelect
                    title={this.props.title}
                    items={this.state.items}
                    onChange={this.onChange}
                    selected={this.state.selected}
                    setItems={()=>{
                        this.setState({
                            ...this.state,
                            items:[]
                        })
                    }}
                    handleRef={ref=>this.multi=ref}
                    onFocus={false}
                />
            </div>
        )
    }
}


export {SearchTaskMappingItemN}