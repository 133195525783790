import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';



class SearchWbsItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            wbsItems: [],
            suggestion: [],
        }
    }



    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
       
    }



    onSearch = (val) => {
        if (val.length > 0) {
            get(`project/wbs/wbsItem.search`, (e, r) => {
                if (r) {
                    this.setState({
                        ...this.state,
                        suggestion:r.wbsItems
                    })
                }
            },{
                q:val
            })
        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }
    }



    getValue = () => {
        return this.state.name
    }

    render() {

        return (
            <div style={{
                flex: 1,
                position: 'relative'
            }} >
                <FloatInput title={this.props.title} handleRef={ref => this.parent = ref} onChange={val => {

                    this.onSearch(val);
                }} />


                {
                    this.state.suggestion.length > 0 ?
                        <div style={{
                            position: 'absolute',
                            top: 77,
                            border: '1px solid var(--border-color)',
                            height: 200,
                            backgroundColor: '#fff',
                            width: '100%',
                            zIndex: 1,
                            overflowY: 'auto'
                        }}>
                            {this.state.suggestion.map((item, index) => (
                                <div key={index} style={{
                                    height: 40,
                                    borderBottom: '1px solid var(--border-color)',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 10,
                                    fontSize: 14,
                                    backgroundColor: '#fff',
                                }} onClick={() => {

                                    this.setState({
                                        ...this.state,
                                        suggestion: [],
                                        name: item
                                    })
                                    this.parent.setValue(item.wbsItemName);
                                }}>
                                    {item.wbsItemName}
                                </div>
                            ))}

                        </div>
                        :
                        null
                }

            </div >
        )
    }
}


export { SearchWbsItem }