import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';



class SearchWbsMappingItemN extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            itemMappingDetails: [],
            suggestion: [],
        }
    }



    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
       
        
    }



    onSearch = (val) => {
        if (val.length > 0) {
            get(`project/wbs/wbsItemMapping.search/${this.props.projectId}`,(e,r)=>{
                if(r) {
                    this.setState({
                        ...this.state,
                        suggestion:r.itemMappingDetails
                    })
                }
            },{
            q:val
            })
           

        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }
    }



    getValue = () => {
        return this.state.name
    }

    render() {

        return (
            <div style={{
                flex: 1,
                position: 'relative'
            }} >
                <FloatInput 
                    title={this.props.title} 
                    placeholder={this.props.placeholder}
                    handleRef={ref => this.parent = ref} 
                    onChange={val => {
                    this.onSearch(val);
                }} />


                {
                    this.state.suggestion.length > 0 ?
                        <div className="suggestion-modal" style={{top:68}}>
                            {this.state.suggestion.map((item, index) => (
                                <div key={index} style={{
                                    height: 40,
                                    borderBottom: '1px solid var(--border-color)',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 10,
                                    fontSize: 14,
                                    backgroundColor: '#fff',

                                }} onClick={() => {
                                    if(this.props.onClick) {
                                        this.props.onClick(item)
                                    }
                                    this.setState({
                                        ...this.state,
                                        suggestion: [],
                                        name: item
                                    })
                                    this.parent.setValue(item.name);
                                }}>
                                    {item.name}
                                </div>
                            ))}

                        </div>
                        :
                        null
                }

            </div >
        )
    }
}


export { SearchWbsMappingItemN }