import  { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import {  post } from '../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../Common/PageLoader';
import { DownloadCsv } from '../../../../Utils/Downloader';
import { get as fetch_details } from '../../../../Utils/Crypto';
import { Header } from '../../../NCommon/Header';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import { useProject } from '../../../Hooks/ProjectDetails';
import { PageWrapper } from '../../../NCommon/PageWrapper';


const ProjectTaskData = () => {

    const [data,setData] = useState('');
    const [loading,setLoading] = useState(true);
    const [filters,setFilters] = useState([]);
    const project = useProject();
    const {id} = useParams();


    useEffect(()=>{
        INIT();
    },[])

  
    let INIT = (mData={}) => {
        if(!mData) {
            mData = {}
        }
        if(mData.filter) {
            let filters = mData.filter;
            delete mData.filter;
            mData.orFilters = filters;
        }
        post(`project/task/taskStatusDataDump/${id}`,{...mData}, (e, r) => {
            if (r) {
                setData(r);
                setLoading(false);
            }
        })
    }

   let downloadCsv = () => {
        let mData = [];
        mData.push(data.headers);

        data.rows.forEach(val=>{
            let item = [];
            val.forEach(i=>{
                item.push(i.item)
            })
            mData.push(item);
        })
        DownloadCsv('mis.csv',mData);
    
    }


  let  mapHeader = (item, index) => {
        return (
            <div key={index} style={{
                fontWeight: 600,
                borderRight:'1px solid #dedede',
                display: 'flex',
                width:250,
                height:40,
                fontSize:12,
                textAlign:'center'
            }} className="flex flex-v-center flex-h-center">
               
                {item}
            </div>
        )
    }



   let mapItem = (item, index) => {
        let fontWeight = 400;
        return (
            <div key={index} style={{
                backgroundColor:'#fff',
                display: 'flex',
                borderLeft:'1px solid #dedede',
                borderBottom:'1px solid #dedede'
            }}>
                {
                    item.map((row, _row) => {
                        let backgroundColor =  row.colour === 'GREEN' ? 'rgba(3, 252, 152, .4)': 
                                                row.colour === 'YELLOW' ? 'rgba(235, 184, 64, .4)': row.colour === 'RED' ? 'rgba(234, 58, 60, .4)':'#fff'
                        return (
                            <p 
                                className="flex flex-v-center flex-h-center"
                                style={{ 
                                    width:250, 
                                    overflow: 'hidden', 
                                    fontSize: 12, 
                                    borderRight: '1px solid #dedede', 
                                    fontWeight, textAlign: 'center' ,
                                    height:40,
                                    backgroundColor,
                                    }} 
                                key={_row}>
                                {row.item ?  row.item:'-'}
                            </p>
                        )
                    })
                }
            </div>
        )
    }

   
    if (loading) {
            return <PageLoader />
    }
    let expanded = fetch_details(`expanded`);


    return (
            <>
                <HeaderWrapper 
                    showFilter={false} 
                    filters={filters}
                    dynamicFilter={true}
                    types={['Name','Task',"Task Category",'Wbs Mapping',"Vendor",'Responsible Person',"Custom"]}  
                    onFilterChange={items=>{
                        let filter = [];
                        items.forEach(item=>{
                            filter.push({
                                key:item.type,
                                val:item.value
                              })
                        })
                        setFilters(items)
                        INIT({page:0,filter})
                    }} 
                    menuItem={[{name:'Download Csv',type:'downloadcsv',onClick:downloadCsv}]}
                    />
                <PageWrapper>
                    <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:"Mis Report"}]} />
                    <div className="title-container-1" style={{
                        width: expanded ?'calc( 100vw - 240px)':'calc( 100vw - 80px)',
                        position:'relative',
                        overflowX:'auto',
                        padding:20
                    }}>
                    <div style ={{
                        display:'flex',
                        alignItems:'center',
                        height:40,
                        backgroundColor:'var(--background-color)',
                        border:'1px solid var(--border-color)',
                        minWidth:data.headers.length*250,
                    }}>
                        {data.headers.map(mapHeader)}
                    </div>

                    
                    <div style ={{
                        minWidth:data.headers.length*250,
                    }}>
                        {data.rows.map(mapItem)}
                    </div>
                </div>
                </PageWrapper>

                
            </>
        )
}

export default ProjectTaskData;