import { Route, Switch,useParams,useLocation } from 'react-router-dom';
import Details from './Details';
import Task from './Task';
import Manager from './Manager';
import Setting from './Setting';
import { Header } from '../../../NCommon/Header';



const Index = () => {

        let {id} = useParams();
        let route = useLocation().pathname

        return (
            <div style={{
                flex: 1
            }}>
                <Header
                    title="Employee Details"
                    desc="All Details of Employee appear below"
                    items={[
                        {
                            name: 'Details',
                            link: `/app/employee/details/${id}`,
                            active: route === `/app/employee/details/${id}`
                        },
                        {
                            name: 'Manager',
                            link: `/app/employee/details/${id}/manager`,
                            active: route === `/app/employee/details/${id}/manager`
                        },
                        {
                            name: 'Task',
                            link: `/app/employee/details/${id}/task`,
                            active: route === `/app/employee/details/${id}/task`
                        },
                        {
                            name: 'Setting',
                            link: `/app/employee/details/${id}/setting`,
                            active: route === `/app/employee/details/${id}/setting`
                        }
                    ]}
                />

                <Switch>
                    <Route path="/app/employee/details/:id" component={Details} exact />
                    <Route path="/app/employee/details/:id/manager" component={Manager} exact />
                    <Route path="/app/employee/details/:id/task" component={Task} exact />
                    <Route path="/app/employee/details/:id/setting" component={Setting} exact />
                </Switch>
            </div>

        )
}

export default Index;