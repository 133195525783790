import { Switch, Route } from 'react-router-dom';
import TaskCategory from './TaskCategory';
import TaskItem from './TaskItem';

const Index = () => {

    return (
        <Switch>
            <Route path="/app/task" component={TaskCategory} exact />
            <Route path="/app/task/:id" component={TaskItem} exact />
        </Switch>
    )
}


export default Index;