
function Container(props) {
	return (
		<div style={{
			width: `calc(${props.width?props.width:"33.333333%"} - 30px)`,
			margin:12,
			borderRadius:10,
			padding:10,
			border:'1px solid var(--border-color)',
			boxShadow:'2px 1px 1px var(--border-color)',
			background:"var(--white-color)",
			cursor:"pointer",
			...props.style.container
		}} onClick={props.onClick?()=>props.onClick():()=>{}}>
			{props.children}
		</div>
	)
}

Container.propTypes = {

}

Container.defaultProps={
	style : {
		container : {

		}
	}
}

export default Container;
