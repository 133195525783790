import React from 'react';
import { get } from '../../../Model/Network/Config/Axios';

class CitySearch extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.state = {
			cname: '',
			city: [],
			selectCity: ''
		}
	}


	componentDidMount() {
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	getValue = () => {
		return this.state.cname;
	}

	setValue = (cname) => {
		this.setState({
			...this.state,
			cname
		})
	}

	handleChange = (e) => {
		this.setState({
			cname: e.target.value
		}, () => this.handleCall(this.state.cname))
	}


	mapItem = (item, index) => {
		return (
			<div key={index} onClick={() => {
				this.setState({
					cname: item.name,
					city: []
				})
				this.props.onCitySelect(item);
			}
			} style={{
				borderBottom: '1px solid var(--border-color)',
				padding: '8px 15px',
				cursor: 'pointer'
			}}>{item.name}</div>
		)
	}

	handleCall = (name) => {
		if (name.length > 0) {
			get(`/project/cities?q=${name}`, (e, r) => {
				if (r) {
					this.setState({
						city: r.cities
					})
				}
			})
		} else {
			this.setState({
				city: [],
				cname: ''
			})
		}
	}


	render() {
		return (
			<div style={{ ...this.props.style,flex:1 }} className="input">
				<p style={{
					color: 'var(--text-color)',
					fontSize: 13,
					fontWeight: 600,
					marginTop: 15
				}}>City:</p>
				<div style={{
					position: 'relative',
				}}>
					<input className = "finput" type="text" value={this.state.cname} onChange={this.handleChange}
						autoComplete="None"
						placeholder="Search Your city here" />

					{
						this.state.city.length > 0 && this.state.cname.length > 0 ?
							<div className="suggestion-modal">
								{this.state.city.map(this.mapItem)}
							</div>
							: null
					}
				</div>
			</div>
		)
	}
}

export { CitySearch }
