import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'

/*
	<StackGraph height={400} title="Graph" data={[{name:"Hello",data:[121,222]]} items={["Min Price","Max Price"]}/>
*/

function StackGraph(props) {
	return (
		<div>
			<ReactApexChart options={{
				chart: {
					type: 'bar',
					height: props.height,
					toolbar: {
						tools: {
							download: false,
							selection: false,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true,
							customIcons: []
						},
						autoSelected: 'zoom'
					}
				},
				title: {
					text: props.title,
					align: 'left'
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '55%',
						endingShape: 'rounded'
					},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},grid: {
					row: {
						colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
						opacity: 0.5
					},
				},
				xaxis: {
					categories: props.items,
				},
				fill: {
					opacity: 1
				},
			}} series={props.data} type="bar" height={props.height} />
		</div>
	)
}


StackGraph.propTypes = {
	data : Props.array.isRequired,
	items : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired
}

StackGraph.defaultProps = {
	y: "Price",
	title:'Price Movement',
	height:350
}

export {
	StackGraph
}

export default StackGraph
