
import { useEffect, useRef, useState } from 'react';
import Data from './data';
import './index.css';

const Menu = (props) => {

    let REF = useRef();

    const [show, setShow] = useState(false);
    const [isClearable, setIsClearable] = useState(true);


    useEffect(() => {
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('click', handleClick)
        }
    }, [])


    let handleClick = (e) => {
        if (!show && REF.current && !REF.current.contains(e.target)) {
            setShow(false);
        }
    }

    let mapItem = (item, index) => {
        return (
            <div key={index} onClick={() => {
                setShow(false);
                item.onClick();
            }} className="menu-list">
                {Data[item.type] ? <img src={Data[item.type]} style={{ height: 16, width: 16, objectFit: 'contain', marginRight: 10 }} /> : null}
                {item.name}
            </div>
        )
    }

    return (
        <div style={{ position: 'relative', height: 'inherit', display: 'flex', alignItems: 'center' }} ref={REF}>
            {props?.title ? <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: 4, padding: '10px 5px', }}><p style={{ paddingRight: 10 }}> Show Data Based on </p>
                <select id="lang" onChange={(e) => props.setSelectedDate(e.target.value)} style={{ padding: 5, border: "1px solid var(--border-color)", borderRadius: 4 }}>
                    {props.items.map((val, index) => <option key={index} value={val.key}>{val.label}</option>)}
                </select>
            </span>

                : <span onClick={() => {
                    setShow(!show)
                }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: 4, padding: '10px 5px', ...show ? { background: 'var(--background-color)' } : {} }}>
                    <span className="single-dot" />
                    <span className="single-dot" />
                    <span className="single-dot" />
                </span>}
            {
                show ?
                    <div className={`menu ${show ? 'menu-show' : ''}`}>
                        {props.items.map(mapItem)}
                    </div>
                    : null
            }

        </div>
    )
}

Menu.defaultProps = {
    items: []
}

export { Menu }