import { useEffect, useRef, useState } from 'react';
import Moment from 'moment';
import {DateRangePicker} from 'react-date-range';
import calendar from './calendar.png';



const TaskDateFilter = (props) => {


    const [value, setValue] = useState({startDate:Moment().subtract(30,'days').toDate(),endDate:Moment().add(60,'days').toDate(),key:'selection'})
    const [open,setOpen] = useState(false);
    const [current,setCurrent] = useState(undefined);


    let DATEREF= useRef();

    useEffect(()=>{
        let onClick = (event) =>{
            if(open){
				if (DATEREF && DATEREF.current && !DATEREF.current.contains(event.target)) {
					setOpen(false)
					document.removeEventListener('click', onClick)
				}
			}
        }
        if(open) {
            window.addEventListener('click',onClick)
        } else {
            window.removeEventListener('click',onClick)
        }

        return ()=> {
            window.removeEventListener('click',onClick)
        }
    },[open])

    useEffect(()=>{
        if(props.handleRef) {
            props.handleRef({
                startDate:`${Moment(value.startDate).valueOf()}`,
                endDate:`${Moment(value.endDate).valueOf()}`
            })
        }
    },[])


    let onChange = ({selection}) => {
        setValue(selection)
       
    }

    let onSelect = () => {
        if(value && value.startDate!==null && value.endDate!==null) {
            setCurrent(true);
            setOpen(false);
            props.onSelect({
                startDate:`${Moment(value.startDate).valueOf()}`,
                endDate:`${Moment(value.endDate).valueOf()}`
            })
      }
    }


    return(
        <div style ={{display:'flex',alignItems:'center',position:'relative',cursor:'pointer',marginLeft:10}} ref={DATEREF}>
            <div>
            <div onClick={()=>{setOpen(true)}} style={{border:'1px solid var(--border-color)',borderRadius:2,fontWeight:'600',fontSize:14,padding:'4px 12px'}}>
                {
                    current ?
                    <div className="flex flex-v-center">
                        {`${Moment(value.startDate).format('DD-MM-YYYY')} - ${Moment(value.endDate).format('DD-MM-YYYY')}`}
                    </div>:
                    <div className="flex flex-v-center">
                        <img src={calendar} alt="" style={{height:14,width:14}} />
                        <p style={{marginLeft:5}}>Date Filter</p>
                    </div>
                }
            </div>
            {
                open?
                <div style={{position:'absolute',left:-200,top:40,zIndex:2,border:'1px solid var(--border-color)'}}>
                     <DateRangePicker 
                        onChange={onChange}
                        ranges={[value]}
                    />
                    <div className="flex" style={{padding:10,background:'#fff',borderTop:'1px solid var(--border-color)'}}>
                        <div onClick={()=>{setOpen(false);setCurrent(undefined);props.onSelect(undefined)}} className="flex flex-v-center flex-h-center" style={{height:40,marginRight:20,flex:1,cursor:'pointer',background:'var(--red-color)',color:'#fff',fontWeight:'600',borderRadius:4 }}>Cancel</div>
                        <div onClick={onSelect} className="flex flex-v-center flex-h-center" style={{height:40,flex:1,cursor:'pointer',background:'var(--theme-main-color)',color:'#fff',fontWeight:'600',borderRadius:4 }}>Apply</div>
                    </div>
                </div>
                : null
            }
            </div>     
        </div>
       
    )

}


export {TaskDateFilter};