import { useEffect, useRef, useState } from "react";
import axios, { get, post } from "../../../../../Model/Network/Config/Axios";
import { AccessButton, Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { PageLoader } from "../../../../Common/PageLoader";
import { showToast } from "../../../../Common/Toast";
import { useTableCount } from "../../../../Hooks/TableCount";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { MainModal } from "../../../../NCommon/Modal";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";
import { Card } from "../../../../NCommon/Ui/CardLayout";
import { List } from "../../../../NCommon/Ui/List";
import { Modal } from "../../../../Common/Modal/Modal";



const Index = () => {

    const [add,setAdd] = useState(false);
    const [loading,setLoading] = useState(true);
    const [deleteModal,setDeleteModal] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    let TITLE = useRef(),DESCRIPTION=useRef();
    let count = useTableCount();

    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })

    useEffect(()=>{
        INIT({page:0})
    },[count])

    

    let INIT =  (data) => {
        if(!data) {
            data = {
                page:0
            }
        }
        data.size = `${count}`
        data.offset = (data.page*count).toString();
        get(`setting/miscConfig/tags/fetchTags`,(e,r)=>{
            if(r) {
                setMData({
                    ...mData,
                    page:data.page,
                    total:r.total?r.total:data.total,
                    items:r.tags
                })
                setLoading(false);
            }
        },data)
    }

    let addTag = () => {
        let name = TITLE.current.value;
        let desc = DESCRIPTION.current.value;

        if(!name) {
            showToast({
                type:'error',
                message:"Please enter the title"
            })
            return;
        }

        if(!desc) {
            showToast({
                type:"error",
                message:"Please enter the description"
            })
            return;
        }

        let data = {name,desc};
        post(`setting/miscConfig/tags/createTag`,data,(e,r)=>{
            if(r) {
                setAdd(false);
                showToast({
                    type:'success',
                    message:"Tag added successfully"
                })
                INIT();
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="table-title flex-1">
                    <p>{item.name}</p>
                </div>
                <div className="table-text flex-1">
                    <p>{item.desc}</p>
                </div>
                <TableMenu items={[{name:"Delete",onClick:(e)=>{e.stopPropagation();setDeleteModal(item)}}]}/>
            </List>
        )
    }


    let mapCardLayout = (item,index) => {
        return(
            <Card onDelete={()=>{
                setDeleteModal(item)
            }} key={index} title={item.name} desc={item.desc} isOpen={true}>

            </Card>
        )
    }


    if(loading) {
        return<PageLoader />
    }

    return(
        <>
            <HeaderWrapper />
            <PageWrapper>
                <NavigationWrapper items={[{name:'All Tagging',path:'/app/setting'},{name:"Tagging"}]}>
                    <AccessButton inverted={true} text="Add" onClick={()=>{setAdd(true)}} />
                </NavigationWrapper>
                <PaginationTable
                            DATA={mData.items}
                            next={(page)=>{
                                INIT({page})
                            }}
                            mapListLayout={mapListLayout}
                            mapCardLayout={mapCardLayout}
                            total={mData.total}
                            page={mData.page}
                            headers={[
                                {
                                    name:'Name',
                                    flex:1
                                },
                                {
                                    name:'Description',
                                    flex:1
                                },
                                {
                                    name:'actions',
                                    width:50
                                }
                            ]}
                        />
            </PageWrapper>
            <MainModal open={add} close={()=>{setAdd(false)}} title="Add Tagging">
                <FunctionalInput ref={TITLE} title="Enter the title"  />
                <FunctionalInput ref={DESCRIPTION} title="Enter the description" />
                <Button text="Add" style={{marginTop:20}} onClick={addTag} />
            </MainModal>
            {
                deleteModal?
                <Modal
                    title="Remove Tagging"
                    desc={deleteModal.name}
                    show={true}
                    onClose={()=>{
                        setDeleteModal(false)
                    }}
                    style={{
                        minHeight:240
                    }}
                >
                    <p style={{
                        fontSize:13,
                        color:'var(--text-light-color)',
                        marginTop:40
                    }}>Are you sure want to remove tag of {deleteModal.name} ?</p>
                    <Button loading={buttonLoading} text="Remove" style={{marginTop:50}} onClick={()=>{
                        setButtonLoading(true);
                         axios.delete(`setting/miscConfig/tags/removeTag/${deleteModal.id}`, {
                            data: {
                            }
                        })
                            .then(res => {
                                setDeleteModal(false);
                                setButtonLoading(false);
                                INIT({page:mData.page});
                                showToast({
                                    type: 'success',
                                    message: "Tag  remove Successfully"
                                })
                            })
                            .catch(e=>{
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                                setButtonLoading(false);
                            })
                    }} />
                </Modal>
                : null
            }
        </>
    )
}


export default Index;