import './index.css';

const ToggleButton = ({title,text,active,onChange}) => {

    return(
        <div style={{marginTop:20}}>
            <p style={{fontSize:18,fontWeight:700,color:'var(--text-color)'}}>{title}</p>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:10}}>
                <p style={{fontSize:15,color:'var(--text-color)'}}>{text}</p>
                <div className={`toggle ${active ? 'toggle-active':'toggle-inactive'}`} onClick={()=>{onChange(!active)}}>
                    <span className="circle" />
                </div>
            </div>
        </div>
    )
}

export {ToggleButton}