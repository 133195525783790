import { useEffect, useState } from 'react';
import {useParams,useLocation} from 'react-router-dom';
import _ from 'lodash';
import { get } from '../../../../Model/Network/Config/Axios';
import {  AccessButton, Button } from '../../../Common/Button';
import { RightModal } from '../../../Common/Modal/RightModal';
import { PageLoader } from '../../../Common/PageLoader';
import AddWbsItem from './AddWbsItem';
import { Modal } from '../../../Common/Modal/Modal';
import axios from 'axios';
import { showToast } from '../../../Common/Toast/index';
import { DownloadCsv } from '../../../../Utils/Downloader';
import {  useDictionaryValue } from '../../../Hooks/DictionaryHooks';
import { Header } from '../../../NCommon/Header';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { useTableCount } from '../../../Hooks/TableCount';
import { PageWrapper } from '../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import { PaginationTable } from '../../../NCommon/Tables/PaginationTable'
import { Card } from '../../../NCommon/Ui/CardLayout';
import { List} from '../../../NCommon/Ui/List';
import { TableMenu } from '../../../NCommon/Tables/TableMenu';
import { ViewTag } from '../../../NCommon/Tag';
import { AddTag } from '../../../Common/Tag/AddTag';

const Index = (props) => {

    const [loading,setLoading] = useState(true);
    const [addWbsItem,setAddWbsItem] = useState(false);
    const [filters,setFilters] = useState([]);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    let getDictionaryValue = useDictionaryValue();
    const [sorted,setSorted] = useState('NAME')
    const [up,setUp] = useState(true);
    const [tag,setTag] = useState(false);
    const [updateId,setUpdateId] = useState(1);

    let [mData,setMData] = useState({
        items:[],
        page:0,
        total:0
    })
   let count = useTableCount();
   let {id} = useParams();
   let {search} = useLocation();
   let queryParams = new URLSearchParams(search);
   let name = queryParams.get('name')

   useEffect(()=>{
    INIT({page:0})
   },[count,up,sorted])


  let INIT = (data) => {
    
    if(!data) {
        data  = {page:0}
    }
    data.size = `${count}`;
    data.offset = (data.page*count).toString();
    data.ascending = up;
    data.sortedBy = sorted;

    get(`project/wbs/wbsItem.category/${id}`, (e, r) => {
        if (r) {
            setMData({
                items:r.wbsItems,
                total:r.total,
                page:data.page
            })
            setLoading(false);
        }
    },data)    
}

let mapCardLayout = (item,index) => {
    return(
        <Card items={[{name:'Delete',onClick:()=>{setDeleteModal(item)}}]} height={140} isOpen={true} title={item.wbsItemName} desc={item.description} key={index}>
            <div style={{marginTop:"auto"}}>
                <div style={{borderTop:'0.5px solid var(--border-color)',height:22,marginLeft:-12,marginRight:-12,display:'flex',alignItems:'center'}}>
                    <ViewTag update={updateId} id={item.id} type={'WBS'} add={()=>{setTag(item.id)}}/>
                </div>
            </div>
        </Card>
    )
}

let mapListLayout = (item,index) => {
    return(
        <List key={index}>
            <div className="flex-1">
                <p className="table-title">{item.wbsItemName}</p>
                <p className="table-bottom">{item.description}</p>
            </div>
            <div style={{flex:1}}>
                <ViewTag update={updateId} id={item.id} type={'WBS'} add={()=>{setTag(item.id)}}/>
            </div>
            <TableMenu items={[{name:'Delete',onClick:()=>{setDeleteModal(item)}}]} />
        </List>
    )
}



    let deleteWbsItem = () => {
        axios.delete(`/project/wbs/wbsItem.delete/${deleteModal.id}`, {

        })
            .then(res => {
                setDeleteModal(false);
                setButtonLoading(false);
                INIT({page:mData.page});
                showToast({
                    type: 'success',
                    message: "Task delete successfully"
                })
            })
            .catch(res => {
                setButtonLoading(false);
                showToast({
                    type: 'error',
                    message: res.data.message
                })
            })
    }

  let  downloadCsv = () => {
        let baseData = [[
            "Id",
            "Wbs Item Name",
            "Description",

        ]]
        let data = mData.items;
        data.forEach(val => {
            baseData.push([
                val.id,
                val.wbsItemName,
                val.description
            ])
        })
        DownloadCsv('wbsItem.csv', baseData);
    }

    let handleSort = (sortType) => {
        if(sortType === sorted) {
            setUp(!up);
        } else {
            setSorted(sortType);
            setUp(true)
        }
    }

    return (
            <>
                <Header title={`${getDictionaryValue('Wbs Item')}`} />
                { 
                    loading ?
                    <PageLoader />
                    :
                    <>
                        <HeaderWrapper dynamicFilter={true} 
                            types={["Wbs Item"]} 
                            filters={[...filters]} 
                            menuItem={[{name:'Download Csv',onClick:downloadCsv,type:'downloadcsv'}]} 
                            onFilterChange={items=>{
                                let filter = {};
                                items.forEach(item=>{
                                    filter[item.type] = item.value;
                                })
                                setFilters(items)
                                INIT({page:0,filter})
                            }} 
                            />
                        <PageWrapper id="pagination-table-header" scroll={true}>
                             <NavigationWrapper items={[{name,path:`/app/wbs`},{name:`${getDictionaryValue('Wbs Item')} (${mData.total})`}]}>
                                <AccessButton text="Add Item" onClick={()=>{setAddWbsItem(true)}} addsign={true?1:0} inverted={true?1:0} />
                             </NavigationWrapper>
                             <PaginationTable
                                mapCardLayout={mapCardLayout}
                                mapListLayout={mapListLayout}
                                DATA={mData.items}
                                total={mData.total}
                                page={mData.page}
                                headers={[
                                    {
                                        name:'Details',
                                        flex:1,
                                        sort:sorted==='NAME',
                                        onSortClick:()=>handleSort('NAME'),
                                        up
                                    },
                                    {
                                        name:'Tag',
                                        flex:1
                                    },
                                    {
                                        name:'actions',
                                        width:50
                                    }
                                ]}
                             />
                        </PageWrapper>
                    </>
                }
                {
                    addWbsItem ?
                        <RightModal
                            show={addWbsItem}
                            onClose={() => {
                                setAddWbsItem(false);
                            }}
                            title="Add Wbs Item"
                            desc="Enter the details and add wbs item here"
                        >
                            <AddWbsItem
                                {...props}
                                onComplete={() => {
                                    setAddWbsItem(false);
                                    INIT({page:0});
                                }}
                            />
                        </RightModal>
                        : null
                }

                {
                    deleteModal ?
                        <Modal
                            title={`Delete Wbs Item`}
                            desc={`Delete ${deleteModal.wbsItemName}`}
                            show={deleteModal}
                            onClose={() => {
                                setDeleteModal(false);
                            }}
                            style={{
                                minHeight: 150
                            }}
                        >
                            <p style={{
                                marginTop: 50
                            }}>Are you sure want to delete 
                            <span style={{  fontWeight: 600 }}>{deleteModal.wbsItemName} </span> ? </p>

                            <Button
                                text="Delete"
                                style={{
                                    marginTop: 20,
                                    backgroundColor: 'var(--red-color)',
                                    borderColor: 'var(--red-color)'
                                }}
                                onClick={() => {
                                    setButtonLoading(true);
                                    deleteWbsItem();
                                }}
                                loading={buttonLoading}
                            />
                        </Modal>
                        : null
                }
                {
                    tag?
                    <AddTag referenceId={tag} type="WBS" init={()=>{
                        setTag(false);
                        setUpdateId(updateId+1)
                        INIT({page:mData.page})
                    }} close={()=>setTag(false)} />
                    :null
                }

            </>
        )
}

export default Index;