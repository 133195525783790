import { useRef } from 'react';
import { Button } from '../Button';
import {Modal} from '../Modal/Modal';
import { EmployeeSearch } from '../Search/SearchEmployee';
import { showToast } from '../Toast';

const EmployeeModal = (props) => {

    let employee=useRef();
    return(
        <Modal
            title="Search Employee"
            show={true}
            onSave={()=>{}}
            desc="Enter the name and select employee"
            onClose={props.onClose}
            style={{
                minHeight:260
            }}
        >
            <EmployeeSearch title="Name" handleRef={ref=>employee.current=ref}/>
            <Button text="Add" style={{marginTop:40}} onClick={()=>{
                let value = employee.current.getValue();
                if(!value) {
                    showToast({
                        type:'error',
                        message:'Please select the employee first'
                    })
                }
                props.onSelect(value);
            }} />
        </Modal>
    )
}
export {EmployeeModal}