import React from 'react';
import Props from 'prop-types';
import { FloatInput } from '../../FloatInput';


class SearchSelect extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            list: [],
            open: false,
            suggestion: [],
            value: this.props.value ? this.props.value : undefined
        }
    }



    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
        if (this.props.value) {
            this.input.setValue(this.props.value.value);
        }
        window.addEventListener('click', this.handleClick);
        this.setState({
            list: this.props.items,
            suggestion: this.props.items
        })
    }

    componentDidUpdate(props) {
        if(props.items !== this.props.items) {
            this.setState({
                list: this.props.items,
                suggestion: this.props.items
            })
        }
    }


    handleClick = (e) => {
        if (this.state.open && !this.search.contains(e.target)) {
            this.setState({
                ...this.state,
                open: false
            })
        }
    }

    mapItem = (item, index) => {
        return (
            <div key={index} className="suggestion-modal-item" onClick={() => {
                this.input.setValue(item.value);
                this.setState({
                    open: false,
                    suggestion: this.state.list,
                    value: item
                })
                if(this.props.onChange) {
                    this.props.onChange(item.value)
                }
            }}>
                {item.value}
            </div>
        )
    }


    getValue = () => {
        return this.state.value;
    }

    setValue = (value) => {
        this.input.setValue(value.value)
    }

    onChange = (val) => {
        let list = [...this.state.list];
        let suggestion = list.filter(i => i.value.toLowerCase().includes(val.toLowerCase()));
        this.setState({
            ...this.state,
            suggestion
        })
    }

    render() {
        let { title } = this.props;
        return (
            <div style={{
                position: 'relative',
                flex:1,
                ...this.props.style
            }} ref={ref => this.search = ref}>
                <FloatInput 
                    type='text' 
                    title={title}
                    placeholder={this.props.placeholder}
                    ref={ref => this.input = ref}
                    onChange={(val) => {
                        this.setState({
                            open: true
                        }, () => this.onChange(val))
                    }} 
                    onClick={() => { this.setState({ open: true }) }} />

                {this.state.open && this.state.suggestion.length > 0 ?
                    <div className="suggestion-modal" style={{top:70}}>
                        {this.state.suggestion.map(this.mapItem)}
                    </div> :
                    null
                }
            </div>
        )
    }
}

SearchSelect.propsTypes = {
    items: Props.array,
    title: Props.string
}

SearchSelect.defaultProps = {
    items: [],
    title: ''
}
export { SearchSelect }