import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';



class EmployeeSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            tasks: [],
            suggestion: [],
            code: undefined
        }

    }



    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }

        if (this.props.value) {
            this.setValue(this.props.value);
            this.parent.setValue(this.props.value.name);
        }
    }





    onSearch = (val) => {
        if (val.length > 0) {
            get(`employees/search`, (e, r) => {
                if (r) {
                    this.setState({
                        suggestion: r.employees
                    })
                }
            }, {
                q: val
            })

        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }
    }

    setName=(name)=>{
        this.parent.setValue(name);
    }


    setValue = (name) => {
        this.setState({
            name
        })
    }


    getValue = () => {
        return this.state.name
    }

    render() {


        return (
            <div style={{
                flex: 1,
                position: 'relative',
                ...this.props.style
            }} >
                <FloatInput placeholder={this.props.placeholder} title={this.props.title} handleRef={ref => this.parent = ref} onChange={val => {this.onSearch(val); }} />
                {
                    this.state.suggestion.length > 0 ?
                        <div className="suggestion-modal" style={{top:82}}>
                            {this.state.suggestion.map((item, index) => (
                                <div key={index}  className="suggestion-modal-item" onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        suggestion: [],
                                        name: item
                                    })
                                    this.setValue(item);
                                    this.parent.setValue(item.name);
                                    if(this.props.onClick) {
                                        this.props.onClick(item)
                                    }
                                }}>
                                    {item.name} ({item.employeeCode})
                                </div>
                            ))}

                        </div>
                        :
                        null
                }

            </div >
        )
    }
}


export { EmployeeSearch }