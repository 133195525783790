import { useRef } from "react";
import Moment from 'moment';
import { FunctionalInput } from "../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../Common/Modal/RightModal"
import {Button} from '../../../Common/Button';
import { post } from "../../../../Model/Network/Config/Axios";
import { showToast } from "../../../Common/Toast";

const Index = (props) => {

    let TITLE= useRef(),DESC=useRef();

    let add = () => {
        let title = TITLE.current.value;
        let description = DESC.current.value;
        let date = Moment(props.details.date).add('hours',6).valueOf();
        if(!title) {
            showToast({
                type:'error',
                message:"Please enter the title"
            })
            return;
        }

        if(!description) {
            showToast({
                type:'error',
                message:"Please enter the description"
            })
            return
        }
        post(`project/holiday/addHoliday/${props.id}`,{title,description,date},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Event added successfully"
                })
                props.init()
            }
        })

    }
    return(
        <RightModal
            title="Add Event"
            desc="Fill Up the details"
            show={true}
            onClose={props.close}
        >
            <FunctionalInput title="Title" ref={TITLE} />
            <FunctionalInput title="Description" ref={DESC} />
            
            <Button text="Add" onClick={add} style={{marginTop:25}} />
            
        </RightModal>
    )
}

export default Index;