import { useRef } from "react";
import { useParams } from 'react-router-dom';
import { deleteApi } from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { Modal } from "../../../../Common/Modal/Modal"
import { showToast } from "../../../../Common/Toast";



const DeleteRequest = ({show,close,INIT}) => {

    let REMARKS = useRef();
    let {id} = useParams();

    if(!show) {
        return <></>
    }


    return(
        <Modal
            title="Delete Request"
            desc={""}
            show={show}
            onClose={close}
            style={{
                minHeight:200
            }}
        >

            <FunctionalInput title="Reason**" ref={REMARKS} />
            <p style={{marginTop:5}}>Are you sure want to delete <b>{show.inventoryDetails.name}</b> ?</p>
            <Button 
                text="Delete Request"
                onClick={()=>{
                    let remarks = REMARKS.current.value;
                    if(!remarks) {
                        showToast({
                            type:'error',
                            message:'Please enter the remarks'
                        })
                        return;
                    }
                    deleteApi(`project/inventory/procure/remove/${id}/${show.id}?remarks=${remarks}`,(e,r)=>{
                        if(r) {
                            showToast({
                                type:'success',
                                message:'Item Delete Successfully'
                            })
                            INIT();
                        }
                    })

                }}
                style={{
                    backgroundColor:'var(--red-color)',
                    borderColor:'var(--red-color)',
                    marginTop:30
                 }} />
        </Modal>
    )
}

export default DeleteRequest;