import {  useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import { get } from "../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../Common/PageLoader";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { NormalDateFilter } from "../../../../NCommon/DateFilters/NormalDateFilter";
import { useTableCount } from "../../../../Hooks/TableCount";
import { useProject } from "../../../../Hooks/ProjectDetails";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import {PastRequestItem} from "../Common/index";
import { MainModal } from "../../../../NCommon/Modal";
import CompleteGrn from './Grn';


const Index = () => {

    const [loading,setLoading] = useState(true);
    const [range,setRange] = useState(undefined);
    const [open,setOpen] = useState(false);
    const [complete,setComplete] = useState(false);
    const [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    });
    const count = useTableCount();
    const project = useProject();
    let {id} = useParams();

    let onMount = (range) => {
        setRange(range);
    }

    useEffect(()=>{
        if(range) {
            INIT({page:0},range);
        }
    },[count,range])

    let INIT = (data,range) => {
         if(!data) {
             data = {page:0}
         }
         data.size = `${count}`
         data.offset = (data.page*count).toString();
        get(`project/inventory/procure/pastRequests/${id}?start=${range.startDate}&end=${range.endDate}&size=${data.size}&offset=${data.offset}`,(e,r)=>{
            if(r) {
                setMData({
                    items:r.requests,
                    total:r.total,
                    page:data.page
                })
                setLoading(false);
            }
        })
    }

    let mapListLayout = (item,index) => {
        let items = [];
        if(!item.grnDone) {
            items.push({
                name:'Grn',
                onClick:(e)=>{e.stopPropagation();setComplete(item)}
            })
        }
        return(
            <PastRequestItem
                key={index}
                checkbox={false}
                item={item}
                onClick={()=>{
                    setOpen(true)
                }}
                tableItems={items}
            />
        )
    }


    return(
        <>
            <HeaderWrapper 
                dynamicFilter={true}
                hideCard={true}
                >
                <NormalDateFilter handleRef={onMount} />
            </HeaderWrapper>
            { loading ?
             <PageLoader />:
            <PageWrapper defaultView={'list'} style={{paddingBottom:100}} id="pagination-table-header" scroll={true}>
                <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{path:`/app/project/mapping/${id}`,name:`${project.name}`},{name:`Pending Request (${mData.total})`}]}>
                </NavigationWrapper>
                <PaginationTable
                    DATA={mData.items}
                    mapListLayout={mapListLayout}
                    mapCardLayout={()=>{}}
                    defaultView={'list'}
                    page={mData.page}
                    total={mData.total}
                    headers={[
                      
                        {
                            name:'Resource Details',
                            flex:1
                        },
                        {
                            name:'Store Name',
                            flex:1,
                        },
                        {
                            name:'Quantity',
                            flex:0.5
                        },
                        {
                            name:'Grn Status',
                            flex:0.5
                        },
                        {
                            name:'Price',
                            flex:0.5
                        },
                        {
                            name:'Delivery Date',
                            flex:0.5
                        },
                        {
                            name:'actions',
                            width:50
                        }
                    ]}
                />
            </PageWrapper>
             }
             <MainModal open={open} close={()=>{setOpen(false)}} />
             <CompleteGrn INIT={()=>{INIT({page:mData.page},range);setComplete(false)}} show={complete} close={()=>{setComplete(false)}}/>

        </>
    )
}

export default Index;