import { useEffect, useState } from "react";
import { get } from "../../../../../Model/Network/Config/Axios";
import { LineHeader } from "../../../../Common/LineHeader";
import { CenterModal } from "../../../../Common/Modal/CenterModal"
import { PageLoader } from "../../../../Common/PageLoader";
import Details from './Details';
import Mapping from './Mapping';


const Index = (props) => {

    const [loading,setLoading] = useState(true);
    const [resource,setResource] = useState([]);
    const [key,setKey] = useState('details');

    useEffect(()=>{
        get(`project/resourceView/fetchProjectResourceDetails/${props.projectId}/${props.resource.resourceId}`,(e,r)=>{
            if(r) {
                setResource(r.resources);
                setLoading(false);
            }
        })
    },[])

    return(
        <>
         <CenterModal
            header={props.resource.name}
            onClose={props.onClose}
         >
             {
                 loading ?
                 <PageLoader style={{marginTop:50}} />
                 : 
                 <div style={{marginTop:60,padding:25}}>
                    <LineHeader
                        items={[
                            {
                                name:'Details',
                                active:key==='details',
                                onClick:()=>{setKey('details')}
                            },
                           
                        ]}
                    />
                    {key==='details' ? <Details details={props.resource} />:null}
                    {key==='mapping' ? <Mapping projectId={props.projectId} details={resource} />:null}
                 </div>
             }
         </CenterModal>
        </>
    )
}

export default Index;