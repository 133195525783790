import React, { forwardRef, useEffect,useRef,useState } from "react";
import {useParams} from 'react-router-dom';
import { get, post } from "../../../../Model/Network/Config/Axios";
import { Button } from "../../Button";
import { FunctionalInput } from "../../FloatInput/FunctionalInput";
import { Modal } from "../../Modal/Modal";
import { showToast } from "../../Toast";




class CustomSearch extends React.Component {

    constructor(p) {
        super(p);
        this.state = {
            value:'',
            items:[],
            suggestion:[],
            add:false,
            select:undefined,
        }
        this.NAME = React.createRef();
        this.DESCRIPTION=React.createRef();
        this.INPUT = React.createRef();

    }

    getId = () => {
        if(this.props.type === 'PROJECT') {
            return this.props.projectId;
        } else {
            return this.props.id;
        }
    }
   


    componentDidMount() {
        if(this.props.handleRef) {
            this.props.handleRef(this);
        }
        this.INIT();
    }

    componentDidUpdate(props) {
        console.log(props,this.props);
        if(this.props.parentId!==props.parentId) {
            console.log('hi karan inside')
            this.INIT();
        }
    }

     INIT = () => {
         let data = {};
         if(this.props.parentId) {
            data.parentId = this.props.parentId
        }
        get(`${this.props.path}`,(e,r)=>{
            if(r) {
                this.setState({
                    ...this.state,
                    items:r.hierarchyItems.map((val)=>({
                        name:val.name,
                        levelId:val.levelItemId
                    }))
                })
            }
        },data)
    }

    getValue = () => {
        return this.state.select;
    }

    setValues = (select) => {
        this.setState({
            ...this.state,
            select
        })
    }



    mapSuggestion = (item,index) => {
        return(
            <div className="suggestion-modal-item" onClick={()=>{
                if(this.props.onClick) {
                    this.props.onClick(item);
                }
                this.setState({
                    ...this.state,
                    value:'',
                    select:item
                })
                this.INPUT.current.value = item.name;
            }} key={index}>{item.name}</div>
        )
    }

   
     addItem = ()=> {
        return(
            <p onClick={()=>{
                this.setState({
                    ...this.state,
                    add:true
                },()=>{
                    this.NAME.current.value = this.INPUT.current.value;
                    this.DESCRIPTION.current.value= this.INPUT.current.value;
                })
            }} style ={{
                 color:'var(--active-color)',
                 fontWeight:'600',
                 cursor:'pointer',
                 height:40,
                 display:'flex',
                 alignItems:'center',
                 paddingLeft:10,
                 position:'sticky',
                 bottom:0,
                 background:'#fff',
                 borderTop:'1px solid var(--border-color)'
                }}><span style={{
                    border:'1px solid var(--active-color)',
                    height:20,
                    width:20,
                    borderRadius:'50%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    marginRight:10,
                    fontSize:16
                }}>+</span>Add Above Value</p>
        )
    }

     addTask = () => {
        let name = this.NAME.current.value;
        let description = this.DESCRIPTION.current.value;
        if(!name) {
            showToast({
                type:'error',
                message:"Please enter the name"
            })
            return;
        }
        if(!description) {
            showToast({
                type:'error',
                message:"Please enter the description"
            })
            return;
        }

        let data = {
            name,
            description,
            hierarchyId:+this.props.id
        }
        if(this.props.parentId) {
            data.parentId = this.props.parentId
        }

        post(`${this.props.post}`,data,(e,r)=>{
            if(r) {
                this.INIT();
                this.setState({
                    ...this.state,
                    suggestion:[],
                    add:false,
                })
                this.INPUT.current.value = undefined
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })

    }


render() {
    return(
        <div style={{
            position:'relative'
        }}>
            <FunctionalInput ref={this.INPUT}  title={this.props.title} onChange={(value)=>{
                this.setState({
                    ...this.state,
                    value
                },()=>{
                    if(this.state.value.length>0) {
                        let item = this.state.items.filter(val=>val.name.toLowerCase().includes(value.toLowerCase()));
                        this.setState({
                            ...this.state,
                            suggestion:item
                        })
                    }
                })
            }} />
            {
            this.state.suggestion && this.state.suggestion.length && this.state.value.length ?
            <div className="suggestion-modal" style={{top:68}}>
                {this.state.suggestion.map(this.mapSuggestion)}
                {this.addItem()}
            </div>
            :this.state.suggestion.length === 0 && this.state.value.length ?
            <div className="suggestion-modal" style={{top:68}}>
                {this.addItem()}
            </div>
            : null
            }

             <Modal
                    title={`Add ${this.props.title}`}
                    desc={`Add new value ${this.props.title}`}
                    onClose={()=>{
                        this.setState({
                            add:false
                        })
                    }}
                    show={this.state.add}
                >
                    <FunctionalInput title="Name" ref={this.NAME} />
                    <FunctionalInput title="Description" ref={this.DESCRIPTION} />
                    <Button onClick={this.addTask} text="Add" style={{marginTop:20}}/>

                </Modal>
        </div>
    )
}
}

export default CustomSearch;