import { Tab } from "../../../../../NCommon/Tabs";
import {useParams,useLocation,Route,Switch} from 'react-router-dom';
import ThreeMonthMajorConsumable from './majorConsumable';
import ThreeMonthMinorConsumable from './MinorConsumable';
import ThreeMonthShutterReport from './Shutter';
import NonConsumable from './NonConsumable';
import Steel from './Steel';
import PlantandMachinery from './PlantandMachinary';
import ThreeMonthDrawing from './DrawingThreeMonth';
import Mpr from './Mpr';
import FullKitting from './FullKitting';
import MPRAP from './MprAp';
import Ims from './Ims';

const Index = () => {


    const {id} = useParams();
    const location = useLocation().pathname;


    return(
        <div style={{margin:20}}>
            <Tab
                items={[
                    {
                        name:'3 M. Major Consumable',
                        link:`/app/project/analytics/${id}/custom/ate`,
                        active:location===`/app/project/analytics/${id}/custom/ate`
                    },
                    {
                        name:'3 M. Minor Consumable',
                        link:`/app/project/analytics/${id}/custom/ate/minor`,
                        active:location===`/app/project/analytics/${id}/custom/ate/minor`
                    },
                    {
                        name:'3 M. non Consumable',
                        link:`/app/project/analytics/${id}/custom/ate/nonconsumable`,
                        active:location===`/app/project/analytics/${id}/custom/ate/nonconsumable`
                    },
                    {
                        name:'3 M. Shutter',
                        link:`/app/project/analytics/${id}/custom/ate/shutter`,
                        active:location===`/app/project/analytics/${id}/custom/ate/shutter`
                    },
                    {
                        name:'3 M. Structural Steel',
                        link:`/app/project/analytics/${id}/custom/ate/steel`,
                        active:location===`/app/project/analytics/${id}/custom/ate/steel`
                    },
                    {
                        name:'3 M. Plant and Machinery',
                        link:`/app/project/analytics/${id}/custom/ate/plant`,
                        active:location===`/app/project/analytics/${id}/custom/ate/plant`
                    },
                    {
                        name:'3 M. Drawing',
                        link:`/app/project/analytics/${id}/custom/ate/drawing`,
                        active:location===`/app/project/analytics/${id}/custom/ate/drawing`
                    },
                    {
                        name:'MPR Report',
                        link:`/app/project/analytics/${id}/custom/ate/mpr`,
                        active:location===`/app/project/analytics/${id}/custom/ate/mpr`
                    },
                    {
                        name:'Fullkitting',
                        link:`/app/project/analytics/${id}/custom/ate/fullkitting`,
                        active:location===`/app/project/analytics/${id}/custom/ate/fullkitting`
                    },
                    {
                        name:'MPR Ap Report',
                        link:`/app/project/analytics/${id}/custom/ate/mprap`,
                        active:location===`/app/project/analytics/${id}/custom/ate/mprap`
                    },
                    {
                        name:'Ims  Report',
                        link:`/app/project/analytics/${id}/custom/ate/ims`,
                        active:location===`/app/project/analytics/${id}/custom/ate/ims`
                    }

                ]}
            />
            <Switch>
                <Route path="/app/project/analytics/:id/custom/ate" component={ThreeMonthMajorConsumable} exact />
                <Route path="/app/project/analytics/:id/custom/ate/minor" component={ThreeMonthMinorConsumable} exact />
                <Route path="/app/project/analytics/:id/custom/ate/nonconsumable" component={NonConsumable} exact />
                <Route path="/app/project/analytics/:id/custom/ate/shutter" component={ThreeMonthShutterReport} exact />
                <Route path="/app/project/analytics/:id/custom/ate/steel" component={Steel} exact />
                <Route path="/app/project/analytics/:id/custom/ate/plant" component={PlantandMachinery} exact />
                <Route path="/app/project/analytics/:id/custom/ate/drawing" component={ThreeMonthDrawing} exact />
                <Route path="/app/project/analytics/:id/custom/ate/mpr" component={Mpr} exact />
                <Route path="/app/project/analytics/:id/custom/ate/fullkitting" component={FullKitting} exact />
                <Route path="/app/project/analytics/:id/custom/ate/mprap" component={MPRAP} exact />
                <Route path="/app/project/analytics/:id/custom/ate/ims" component={Ims} exact />

            </Switch>
        </div>
    )
}


export default Index;