

const Index = ({details,projectId}) => {
    
    let mapItem = (item,index) => {
        return(
            <div style = {{
                padding:'10px 15px',
                border:'1px solid var(--border-color)',
                borderRadius:8,
                display:'flex',
                alignItems:'center',
                minHeight:60,
                fontSize:14,
                
            }} key={index}>
                <div style ={{
                    width:40
                }}>
                    <div style={{
                        background:'var(--border-color)',
                        height:20,
                        width:20,
                        borderRadius:'50%',
                        fontSize:12,
                        fontWeight:'bold'
                    }} className="flex flex-v-center flex-h-center">
                        {index+1}
                    </div>
                </div>
                <div style={{
                    flex:2
                }} className="single-line">
                    <p style={{fontSize:14,fontWeight:600}}>{item.wbsItemTaskName}</p>
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                    <p>{item.resourceType}</p>
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                    <p>{item.subType}</p>
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                    <p>{item.qty} {item.units}</p>
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                    <p>INR {item.amount}</p>
                </div>

                <div style={{
                    width:70,
                    fontSize:14,
                    color:'var(--button-color)',
                    fontWeight:'600',
                    textAlign:'center',
                    cursor:'pointer'
                }} onClick={()=>{
                    window.open(`/app/project/task/${projectId}/${item.taskMappingId}/details`,'_blank')
                }}>
                    View Task
                </div>

            </div>  
        )
    }
    return(
        <div>
            <div style={{
                display:'flex',
                height:50,
                background:'var(--background-color)',
                marginBottom:10,
                borderRadius:8,
                alignItems:'center',
                fontSize:14,
                padding:'10px 15px',
                color:'var(--text-light-color)'
            }}>
                 <div style ={{
                    width:40,
                }} className="flex flex-v-center flex-h-center">
                    No
                </div>
                <div style={{
                    flex:2
                }} className="single-line">
                    Name
                </div>

                <div style={{flex:1,textAlign:'center'}}>
                   Resource type
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                   Resource Sub Type
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                   Qty
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                   Amount
                </div>
                <div style={{
                    width:70,
                    fontSize:14,
                    textAlign:'center',
                    cursor:'pointer'
                }} >

                </div>
            </div>
            {details.map(mapItem)}
        </div>
    )
}
export default Index;