import {useLocation,Switch,Route,useParams} from 'react-router-dom';
import { Header } from '../../../NCommon/Header';
import Pending from './Pending'

const Index = () => {
    let route = useLocation().pathname;
    let {id} =useParams();
    return(
        <>
            <Header
                items={[
                    {
                        name: 'Pending FullKitting Task',
                        link: `/app/project/fullkitting/${id}`,
                        active: route === `/app/project/fullkitting/${id}`
                    }
                ]}    
            />
            <Switch>
                <Route path="/app/project/fullkitting/:id" component={Pending} exact />
            </Switch>
        </>
    )

}

export default Index;