import { useRef } from "react";
import { useParams } from 'react-router-dom';
import { post } from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { Modal } from "../../../../Common/Modal/Modal"
import { showToast } from "../../../../Common/Toast";



const DeleteRequest = ({show,close,INIT}) => {

    let QTTY = useRef();
    let {id} = useParams();

    if(!show) {
        return <></>
    }


    return(
        <Modal
            title="Done Grn"
            desc={""}
            show={show}
            onClose={close}
            style={{
                minHeight:200
            }}
        >

            <FunctionalInput title="Enter Qtty**" ref={QTTY} />
            <Button 
                text="Complete Grn"
                onClick={()=>{
                    let qtty = QTTY.current.value;
                    if(!qtty) {
                        showToast({
                            type:'error',
                            message:'Please enter the qtty'
                        })
                        return;
                    }
                    post(`project/inventory/procure/grnDone/${id}/${show.id}`,{qty:+qtty},(e,r)=>{
                        if(r) {
                            showToast({
                                type:'success',
                                message:'GRN done successfully'
                            })
                            INIT();
                        }
                    })

                }}
                style={{marginTop:30 }} />
        </Modal>
    )
}

export default DeleteRequest;