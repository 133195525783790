import { useEffect, useState } from "react";
import {useParams,useLocation} from 'react-router-dom';
import { get } from "../../../../../Model/Network/Config/Axios";
import {AccessButton} from '../../../../Common/Button/index'
import Add from './Add';
import { PageLoader } from "../../../../Common/PageLoader";
import Edit from './Edit';
import ViewResource from './ViewResource';
import { useTableCount } from "../../../../Hooks/TableCount";
import { Header } from "../../../../NCommon/Header";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { Card } from "../../../../NCommon/Ui/CardLayout";
import { List } from "../../../../NCommon/Ui/List";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";
import { DownloadCsv } from "../../../../../Utils/Downloader";


const SubCategory = () => {


    const [loading,setLoading] = useState(true);
    const [add,setAdd] = useState(false);
    const [edit,setEdit] = useState(false);
    const [show,setShow] = useState(false);
    const [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    let {id,type,subType} = useParams();
    let {search} = useLocation();
    let count = useTableCount();
    let queryParams = new URLSearchParams(search)
    let  name = queryParams.get('name')

    useEffect(()=>{
        INIT({page:0})
    },[count])

    let INIT = (data) => {
        if(!data) {
            data = {
                page:0
            }
        }
        data.size = count;
        data.offset = (data.page*count).toString();
        get(`resource/allResources/${id}`,(e,r)=>{
            if(r) {
                setMData({
                    ...mData,
                    page:data.page,
                    total:r.total?r.total:data.total,
                    items:r.resources
                })
                setLoading(false)
            }
        },data)
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card 
                 key={index} 
                 height={120}
                 title={item.name} 
                 desc={item.description}
                 items={[{name:'View',onClick:(e)=>{e.stopPropagation();setShow(item)}},{name:'Edit',read:true,onClick:(e)=>{e.stopPropagation();setEdit(item)}}]}
                 />

        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List>
                <div className="flex-2" key={index}>
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.name}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.resourceType}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.subType}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.units}</p>
                </div>
                <TableMenu items={[{name:'View',onClick:(e)=>{e.stopPropagation();setShow(item)}},{name:'Edit',read:true,onClick:(e)=>{e.stopPropagation();setEdit(item)}}]} />
            </List>
        )
    }

    let downloadCsv = () => {
        get(`resource/allResources/${id}`,(e,r)=>{
            if(r) {
               let baseData = [
                [
                    "Category",
                    "Resource Name",
                    "Description",
                    "GlCode",
                    "Type",
                    "SubType",
                    "Units",
                    "Major",
                    "Role",
                    "bufferLeadTime",
                    "gstRate",
                    "poGrnLeadTime",
                    "prPoLeadTime",
                    "shutterAge",
                    "wastageRatio"
                ]
               ]

               r.resources.forEach(val=>{
                 baseData.push(
                    [
                        val.category,
                        val.name,
                        val.description,
                        val.glCode,
                        val.resourceType,
                        val.subType,
                        val.units,
                        val.major?"Yes":"No",
                        val.role?val.role:'-',
                        val.bufferLeadTime,
                        val.gstRate,
                        val.poGrnLeadTime,
                        val.prPoLeadTime,
                        val.shutterAge,
                        val.wastageRatio
                    ]
                 )
               })

               DownloadCsv("Resource_data.csv",baseData)
            }
        })
    }


    return(
        <>
            <Header title="Resource"  items={[]}/>
            {
                loading?
                <PageLoader />
                : 
                <>
                  <HeaderWrapper menuItem={[{name:'Download Csv',type:'downloadcsv',onClick:downloadCsv}]} />
                  <PageWrapper>
                      <NavigationWrapper items={[{name,path:'/app/others/resource'},{name:`Resource (${mData.total})`}]}>
                            <AccessButton onClick={()=>{setAdd(true)}} text="Add Resource" inverted={true?1:0} addsign={true?1:0} />
                      </NavigationWrapper>
                      <PaginationTable
                                DATA={mData.items}
                                next={(page)=>{
                                    INIT({page})
                                }}
                                mapListLayout={mapListLayout}
                                mapCardLayout={mapCardLayout}
                                total={mData.total}
                                page={mData.page}
                                headers={[
                                    {
                                        name:'Details',
                                        flex:2
                                    },
                                    {
                                        name:'Resource Type',
                                        flex:1
                                    },
                                    {
                                        name:'Sub Category Type',
                                        flex:1
                                    },
                                    {
                                        name:'Units',
                                        flex:1
                                    },
                                    {
                                        name:'actions',
                                        width:50
                                    }
                                ]}
                                />
                  </PageWrapper>
                </>
            }

           <Add open={add} onClose={()=>{setAdd(false)}} id={id} init={()=>{INIT();setAdd(false)}} type={type} subType={subType} />
           <Edit name={name} type={type} subType={subType} id={edit.id} open={edit} init={()=>{INIT();setEdit(false)}} onClose={()=>{setEdit(false)}} />
            {
                show ?
                <ViewResource 
                    onClose={()=>{
                        setShow(false);
                    }}
                    edit={show}
                />
                : null 
            }
        </>
    )
}

export default SubCategory;