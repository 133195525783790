import { forwardRef, useImperativeHandle, useRef } from "react";
import './FloatSelect.css';

const FloatSelect = forwardRef((props,ref)=>{

    let SELECTREF=useRef();

    
    useImperativeHandle(ref,()=>({
        getValue:()=>SELECTREF.current.value
    }))

    return(
      <>
        <p style={{ fontSize: 12, color: 'var(--title-color)',  fontWeight: 600, marginTop: 15}}>{props.title} </p>
        <select className="select" style={{flex:1,padding:'0px 5px',width:'100%',marginTop:10,height:40,border:'1px solid var(--border-color)',borderRadius:4}} onChange={(e)=>{
            if(props.onChange) {
                props.onChange(e.target.value)
            }
        }} ref={SELECTREF}>
            {props.items.map((item,index)=>{
                return(
                    <option key={index} value={item.value}>{item.label}</option>
                )
            })}
        </select>
    </>
    )
})


FloatSelect.defaultProps = {
    items:[]
}

export {FloatSelect}