import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { post } from '../../../../../Model/Network/Config/Axios';
import { Button } from "../../../../Common/Button";
import { FunctionalInput } from '../../../../Common/FloatInput/FunctionalInput';
import { Modal } from "../../../../Common/Modal/Modal"
import { showToast } from "../../../../Common/Toast";



const MarkComplete = ({show,close,INIT}) => {

    let {id} = useParams();
    let QTY = useRef();

    useEffect(()=>{
        if(show) {
            QTY.current.value = show.qty;
        }
    },[show])


    if(!show) {
        return <></>
    }

    return(
        <Modal
            title="Complete Request"
            desc={""}
            show={show}
            onClose={close}
            style={{
                minHeight:200
            }}
        >

            <p style={{marginTop:5}}>Are you sure want to Mark Complete <b>{show.inventoryDetails.name}</b> ?</p>
            <FunctionalInput ref={QTY} title="Enter Quantity"/>
            <Button 
                text="Mark Complete"
                onClick={()=>{
                    post(`project/inventory/procure/update/${id}/${show.id}`,{qty:+QTY.current.value},(e,r)=>{
                        if(r) {
                            post(`project/inventory/procure/prDone/${id}/${show.id}`,{},(e,r)=>{
                                if(r) {
                                    showToast({
                                        type:'success',
                                        message:'Item Mark Completed Successfully'
                                    })
                                    INIT();
                                }
                            })
        
                        } else {
                            showToast({
                                type:'error',
                                message:e.data.message
                            })
                        }
                    })
                   
                }}
                style={{ marginTop:30}} />
        </Modal>
    )
}

export default MarkComplete;