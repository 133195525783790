import { useEffect, useState } from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { AccessButton } from '../../../Common/Button';
import { PageLoader } from '../../../Common/PageLoader';
import { Header } from '../../../NCommon/Header';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../NCommon/PageWrapper';
import AddAdhoc from './AddAdhoc';
import {Table} from '../../../NCommon/Tables/index';
import { Card } from '../../../NCommon/Ui/CardLayout';
import { List } from '../../../NCommon/Ui/List';


const Index = () => {

    const [add,setAdd] = useState(false);
    const [adhoc,setAdhoc] = useState([]);
    const [loading,setLoading] = useState(true);
    
    useEffect(()=>{
        INIT()
    },[])

    let INIT = () => {
        get(`customAdhoc/fetchAll`,(e,r)=>{
            if(r) {
                setAdhoc(r.adhocTasks);
                setLoading(false);
            }
        })
    }

    let mapCardLayout = (item,index) =>{
        return(
            <Card key={index} title={item.name} desc={item.description} />
        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div style={{flex:1}}>
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.name}</p>
                </div>
            </List>
        )
    }


    if(loading) {
        return <PageLoader />
    }

    return(
        <>
            <Header title="Adhoc Template" />
            <HeaderWrapper />
            <PageWrapper id="pagination-table-header" scroll={true}>
                <NavigationWrapper items={[{name:`Adhoc Task (${adhoc.length})`}]}>
                     <AccessButton 
                        inverted={true?1:0}
                        text="Add Category"
                        addsign={true?1:0}
                        style={{padding:'0px 10px'}}
                        onClick={()=>{
                            setAdd(true)
                        }}
                     />
                </NavigationWrapper>
                <Table
                    DATA={adhoc}
                    mapCardLayout={mapCardLayout}
                    mapListLayout={mapListLayout}
                    headers={[
                        {
                            name:'Details',
                            flex:1
                        }
                    ]}
                />
            </PageWrapper>
            {
                add ?
                <AddAdhoc 
                    onClose={()=>{
                        setAdd(false)
                    }}
                    init={()=>{
                        INIT();
                        setAdd(false)
                    }}
                    />
                : null
            }
        </>
    )
}

export default Index;