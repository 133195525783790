import { Switch, Route } from 'react-router-dom';


/* Pages */
import RolesList from './RolesList';


const Index = () => {

    return (
        <Switch>
            <Route path="/app/employee/roles" component={RolesList} exact />
        </Switch>
    )
}


export default Index;