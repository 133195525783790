const Info = ({text,style,textStyle}) => {
    return(
        <div style={{
            backgroundColor:'#fff8df',
            height:30,
            ...style
        }} className="flex flex-v-center flex-h-center">
            <p style ={{
                fontSize:14,
                fontWeight:600,
                ...textStyle
            }}>{text}</p>
        </div>
    )
}

export {Info}