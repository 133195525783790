import { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import { deleteApi, get, post } from "../../../../../Model/Network/Config/Axios";
import { AccessButton, LineItemButton } from "../../../../Common/Button";
import { PageLoader } from "../../../../Common/PageLoader";
import AddResource from './Add';
import ViewInventory from './ViewInventory';
import EditInventory from './EditInventory';
import { Modal } from "../../../../Common/Modal/Modal";
import { showToast } from "../../../../Common/Toast";
import { Button } from "../../../../Common/Button";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { useTableCount } from "../../../../Hooks/TableCount";
import { useProject } from "../../../../Hooks/ProjectDetails";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import { List } from "../../../../NCommon/Ui/List";
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";
import EditMax from './EditMax';

const Index = () => {

    const [loading,setLoading] = useState(true);
    const [add,setAdd] = useState(false);
    const [edit,setEdit] = useState(false);
    const [editMax,setEditMax] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [viewInventory,setViewInventory] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    let projectDetails = useProject();
    let count = useTableCount();
    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    let {id} = useParams();
    

    useEffect(()=>{
        INIT({page:0})
    },[count])

    let INIT = (data) => {
        if(!data) {
            data={
                page:0
            }
        }

        data.size = `${count}`
        data.offset = (data.page*count).toString();
        get(`project/inventory/fetchAll/${id}`,(e,r)=>{
           if(r) {
             setLoading(false);
             setMData({
                 ...mData,
                 page:data.page,
                 total:r.total?r.total:data.total,
                 items:r.resourceInventories
             })
           }
       },{...data})
    }

    let deleteInventory = () => {
        setButtonLoading(true);
        deleteApi(`project/inventory/removeInventory/${id}/${deleteModal.inventoryId}`, (e, r) => {
            showToast({
                type:'success',
                message:'Inventory Delete Successfully'
            })
            setDeleteModal(false);
            INIT({page:mData.page})
        })
    }

    let syncInventory = () => {
        setLoading(true);
        post(`project/machineryNonConsumable.conflict.sync/${id}`,{},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Inventory sync successfully"
                })
                setLoading(false);
            }
        })
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-2">
                    <p className="table-title">{item.name}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.availableQty} {item.units}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.price}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.max} {item.units}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.confirmationRequired ? 'Yes':"No"}</p>
                </div>
                <TableMenu items={[{name:'View',onClick:(e)=>{e.stopPropagation();setViewInventory(item)}},{name:'Add Quantity',read:true,onClick:(e)=>{e.stopPropagation();setEdit(item)}},{name:'Edit Max Quantity',read:true,onClick:(e)=>{e.stopPropagation();setEditMax(item)}},{name:'Delete',read:true,onClick:(e)=>{e.stopPropagation();setDeleteModal(item)}}]} />
            </List>
        )
    }


    return(
        <>
           
            {
                loading ?
                <PageLoader />:
                <>
                <HeaderWrapper hideCard={true}  />
                <PageWrapper defaultView={'list'} id="pagination-table-header" scroll={true}>
                    <NavigationWrapper items={[{name:'All Projects',path:'/app/project'},{name:`${projectDetails.name}`,path:`/app/project/mapping/${id}`},{name:`Inventory (${mData.total})`}]}>
                        <AccessButton onClick={()=>{setAdd(true)}} text="Add New Resource" addsign={true?1:0} inverted={true?1:0} />
                        <Button onClick={syncInventory} text="Conflict Sync" inverted={true?1:0} style={{marginLeft:10}} />
                    </NavigationWrapper>
                    <PaginationTable
                        DATA={mData.items}
                        total={mData.total}
                        page={mData.page}
                        next={(page)=>{
                            INIT({page})
                        }}
                        mapCardLayout={()=>{}}
                        mapListLayout={mapListLayout}
                        headers={[
                            {
                                flex:2,
                                name:'Details',
                                style:{
                                    justifyContent:'flex-start'
                                }
                            },
                            {
                                flex:1,
                                name:"Available Quantity"
                            },
                            {
                                flex:1,
                                name:'Price'
                            },
                            {
                                flex:1,
                                name:'Max. Storage Qtty'
                            },
                            {
                                flex:1,
                                name:'Confirmation Required'
                            },
                          
                            {
                                width:50,
                                name:'actions'
                            }
                        ]}
                        defaultView={'list'}
                    />
                </PageWrapper>
                
                 {
                    add ?
                     <AddResource
                        id={id}
                        onClose={()=>{
                            setAdd(false);
                        }}
                        init={()=>{
                            INIT({
                                page:mData.page
                            });
                            setAdd(false);
                        }}
                     />
                     : null
                 }
                </>
            }
            {
                viewInventory ?
                <ViewInventory
                    onClose={()=>{
                        setViewInventory(false);
                    }}
                    projectId={id}
                    inventory={viewInventory}
                />:
                null
            }
            {
                edit ?
                <EditInventory
                    onClose={()=>{
                        setEdit(false)
                    }}
                    details={edit}
                    projectId={id}
                    init={()=>{
                        INIT({page:mData.page});
                        setEdit(false);
                    }}

                />
                : null
            }
             {
                editMax ?
                <EditMax
                    onClose={()=>{
                        setEditMax(false)
                    }}
                    details={editMax}
                    projectId={id}
                    init={()=>{
                        INIT({page:mData.page});
                        setEditMax(false);
                    }}

                />
                : null
            }

             {
                       deleteModal ?
                            <Modal
                                show={deleteModal}
                                title="Delete Inventory"
                                desc="By clicking button below delete selected Inventory"
                                style={{
                                    minHeight: 200
                                }}
                                onClose={() => { setDeleteModal(false) }}
                            >

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginTop: 30
                                }}>

                                    <p style={{
                                        fontSize: 18,
                                        color: 'var(--light-color)'
                                    }}>
                                        By clicking below you will be deleting selected Inventory and data related to this inventory
                                      </p>

                                    <Button
                                        text="Delete"
                                        style={{
                                            backgroundColor: 'var(--red-color)',
                                            borderColor: 'var(--red-color)',
                                            marginTop: 30
                                        }}
                                        loading={buttonLoading}
                                        onClick={deleteInventory}
                                    />
                                </div>
                            </Modal>
                            : null
                    }
        </>
    )
}
export default Index;