import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {useParams} from 'react-router-dom';
import { get, post } from '../../../../../Model/Network/Config/Axios';
import { AccessButton, Button } from '../../../../Common/Button';
import { PageLoader } from '../../../../Common/PageLoader';
import { showToast } from '../../../../Common/Toast';
import { useProject } from '../../../../Hooks/ProjectDetails';
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper"
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper"
import { Table } from '../../../../NCommon/Tables';
import { List } from '../../../../NCommon/Ui/List';
import AddShuttering from './AddShuttering';
import EditShuttering from './EditShuttering';


const Index = () => {

    const [loading,setLoading] = useState(true);
    const projectDetails = useProject();
    const [mData,setMData] = useState([]);
    const [add,setAdd] = useState(false);
    const [edit,setEdit] = useState(false);
    const {id} = useParams();

    const automation = useSelector(state=>state.config.config.organization.shutteringAutomation);
    console.log(automation);

    useEffect(()=>{
        INIT()
    },[id])

    let INIT = () => {
        get(`project/resourceView/shuttering/shutterConfigs/${id}`,(e,r)=>{
            if(r) {
                setMData(r.shutteringConfigs);
                setLoading(false);
            }
        })
    }

    let syncInventory = () => {
        setLoading(true);
        post(`project/shutter.max.sync/${id}`,{},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Shuttering sync successfully"
                })
                setLoading(false);
            }
        })
    }

    let shutterAutomation = () => {
        setLoading(true);
        post(`project/shutter.automation.run/${id}`,{},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Shuttering automation done successfully"
                })
                setLoading(false);
            }
        })
    }
    

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className='flex-1'>
                    <p className='table-title'>{item.resource.name}</p>
                    <p className='table-bottom'>{item.resource.description}</p>
                </div>
                <div className='flex-2' style={{textAlign:'left'}}>
                    <p className='table-text'>{item.task.name}</p>
                    <p className='table-text' style={{fontSize:12}}>{item.task.taskType}</p>
                </div>
                
                <div className='flex-1' style={{textAlign:'left'}}>
                    <p className='table-text'>{item.responsiblePerson.name}</p>
                </div>
                <div className='flex-1' style={{textAlign:'left'}}>
                    <p className='table-text'>{item.approver?item.approver.name:'-'}</p>
                </div>
                <div style={{width:50}}>

                </div>
            </List>
        )
    }


    if(loading) {
        return <PageLoader />
    }

    return(
       <>
          <HeaderWrapper hideCard={true} />
          <PageWrapper defaultView={'list'} id="pagination-table-header" scroll={true} >
              <NavigationWrapper items={[{name:'All Projects',path:'/app/project'},{name:`${projectDetails.name}`,path:`/app/project/mapping/${id}`},{name:`Pending (${mData.length})`}]}>
                 <AccessButton onClick={()=>{setAdd(true)}} inverted={true?1:0} addsign={true?1:0} text="Add" />
                 <Button onClick={syncInventory} text="Sync Max Quantity" inverted={true?1:0} style={{marginLeft:10,padding:'0px 10px'}} />
                  {automation?<Button onClick={shutterAutomation} text="Shutter Automation" inverted={true?1:0} style={{marginLeft:10,padding:'0px 10px'}} />:null}
             </NavigationWrapper>
            <Table
                defaultView={'list'}
                mapCardLayout={()=>{}}
                mapListLayout={mapListLayout}
                DATA={mData}
                headers={[
                    {
                        name:'Resource',
                        flex:1
                    },
                    {
                        name:'Task',
                        flex:2,
                        style:{
                            textAlign:'left'
                        }
                    },
                   
                    {
                        name:'Responsible',
                        flex:1,
                        style:{
                            textAlign:'left'
                        }
                    },
                    {
                        name:'Approver',
                        flex:1,
                        style:{
                            textAlign:'left'
                        }
                    },
                    {
                        name:'actions',
                        width:50
                    }
                ]}
                />
          </PageWrapper>
          <AddShuttering complete={()=>{INIT();setAdd(false)}} show={add} close={()=>{setAdd(false)}} />

       </>
    )
}

export default Index;