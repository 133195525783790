import { useEffect, useState } from 'react';
import EditDetails from './EditDetails';
import { get } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import TaskDetails from '../../../../Common/TaskItem/TaskDetails';
import { AccessButton } from '../../../../Common/Button';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';


const Index = (props) => {

    const [details,setDetails] = useState(undefined);
    const [loading,setLoading] = useState(true);
    const [images,setImages] = useState([]);
    const [editModal,setEditModal] = useState(false);

    let getId = () => {
        return props.match.params.id;
    }

    let getProjectId = () => {
        return props.match.params.projectId;
    }

    useEffect(()=>{
        init();
    },[])

   let  init = () => {
        get(`project/task/taskMappingDetails/${getId()}`, (e, r) => {
            if (r) {
                let images = [];
                Object.keys(r.checklists).forEach(val => {
                    Object.keys(r.checklists[val]).forEach(i => {
                        images.push(...r.checklists[val][i].photos.map(item=>{
                            return {
                                url:item,
                                taskName:r.tasksMapping.task.name,
                                date:r.checklists[val][i].date,
                                remarks:r.checklists[val][i].workDesc
                            }
                        }))
                        images.push({
                            url:r.checklists[val][i].photo,
                            taskName:r.tasksMapping.task.name,
                            date:r.checklists[val][i].date,
                            remarks:r.checklists[val][i].workDesc
                        })
                    })
                })
                setImages(images);
                setDetails(r);
                setLoading(false);
            }
        })
    }


   let edit = () => {
        return (
            <EditDetails
                id={getProjectId()}
                details={details}
                onClose={() => {
                    init();
                    setEditModal(false);
                }}
            />
        )
    }

    if(loading) {
        return <PageLoader  />
    }

    if(editModal) {
        return edit()
    }

    return(
        <PageWrapper style={{height:'calc(100vh - 50px)'}}>
            <NavigationWrapper items={[{name:`${details.tasksMapping.wbsItemTaskName}`}]}>
                <AccessButton text="Edit Details" onClick={()=>{setEditModal(true)}} inverted={true?1:0} />
            </NavigationWrapper>
             
            <div className="title-container-1">
                <TaskDetails images={images} tasksMapping={details.tasksMapping} />
            </div>
        </PageWrapper>
    )

  
}

export default Index;