import { useRef } from "react";
import { useSelector } from "react-redux";


const PageWrapper = (props) =>{

    let config = useSelector(config=>config.config.tableview)
    config = props.defaultView?props.defaultView:config
    let PageRef = useRef();
    return(
        <div style = {{
            height:'calc(100vh - 120px)',
            background:'var(--background-color)',
            padding:20,
            overflowY:'scroll',
            position:'relative',
            ...props.style
        }} ref={PageRef} onScroll={(e)=>{
            if(props.scroll && config==='list') {
                let scrollTop = PageRef.current.scrollTop;
                let upperTop = document.getElementById('header-wrapper').offsetHeight + document.getElementById('header-wrapper').offsetTop;
                if(scrollTop>upperTop) {
                    document.getElementById(props.id).className="table-header-fixed";
                } else {
                    document.getElementById(props.id).className="table-header-normal";
                }
            }   
        }}>
            {props.children}
        </div>
    )
}

export {PageWrapper}