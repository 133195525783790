import { useRef, useState } from "react";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../../Common/Modal/RightModal"
import { SearchResourceCategory } from "../../../../Common/Search/SearchResourceCategory";
import { Button } from "../../../../Common/Button";
import { showToast } from "../../../../Common/Toast";
import { post } from "../../../../../Model/Network/Config/Axios";


const Index = (props) => {

    let [buttonLoading,setButtonLoading] = useState(false);
    let RESOURCE = useRef(),QTY=useRef(),PRICE=useRef(),MAX =useRef(),MIN=useRef();


    let add = () => {
        let resource = RESOURCE.current.getValue()?RESOURCE.current.getValue().id:null;
        let availableQty = QTY.current.value;
        let price = PRICE.current.value;
        let max = MAX.current.value;
        let min = MIN.current.value;

        if(!resource) {
            showToast({
                type:'error',
                message:'Please search the resource'
            })
            return;
        }

        if(!availableQty) {
            showToast({
                type:'error',
                message:"Please enter the available qtty"
            })
            return;
        }

        if(!price) {
            showToast({
                type:'error',
                message:"Please enter the price"
            })
            return;
        }

        if(!max) {
            showToast({
                type:'error',
                message:"Please enter the max quanity"
            })
            return;
        }

        let data = {
            projectId:+props.id,
            resourceId:resource,
            availableQty:+availableQty,
            price:+price,
            max:+max,
            min:+min
        }
        setButtonLoading(true);
        post(`project/inventory/addInventory`,data,(e,r)=>{
            if(r) {
                setButtonLoading(false);
                props.init();
                showToast({
                    type:'success',
                    message:"Inventory successfully added"
                })
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
                setButtonLoading(false);
            }
        })


    }

    return(
        <RightModal
            title="Add Inventory"
            desc="Fill up the details to add inventory"
            show={true}
            onClose={props.onClose} 
            >
            <SearchResourceCategory placeholder="Search Resource" title="Resource" handleRef={ref=>RESOURCE.current=ref} />
            <FunctionalInput title="Availabe Quantity" ref={QTY} />
            <FunctionalInput title="Price" ref={PRICE}/>
            <FunctionalInput title="Safety Stock (optional)" ref={MIN}/>
            <FunctionalInput title="Max Store Quantity" ref={MAX}/>

            <Button text="Add" style={{marginTop:25}} onClick={add} loading={buttonLoading} />
        </RightModal>
    )
}

export default Index;