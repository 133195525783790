import { useRef } from "react";
import {useParams} from 'react-router-dom';
import { SearchHindrance } from "../../../../Common/Search/SearchHindrance";
import { MainModal } from "../../../../NCommon/Modal";
import { EmployeeSearch } from "../../../../Common/Search/SearchEmployee";
import { Button } from "../../../../Common/Button";
import { showToast } from "../../../../Common/Toast";
import { post } from "../../../../../Model/Network/Config/Axios";

const Index = (props) => {

    let HINDRANCE  = useRef(),EMPLOYEE=useRef();
    let {id} = useParams();

    let add = () => {
        let hindrance = HINDRANCE.current.getValue();
        let employeeId = EMPLOYEE.current.getValue();

        if(!hindrance) {
            showToast({
                type:'error',
                message:'Please select the hindrance'
            })
            return;
        }

        if(!employeeId) {
            showToast({
                type:'error',
                message:"Please select the employee"
            })
            return;
        }

        let data = {
            hindranceId:hindrance.hindranceId,
            empId:employeeId.id
        }

        post(`project/hindrance/mappedHindrance/${id}`,data,(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Mapping done successfully"
                })
                props.complete();
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }

    return(
        <MainModal
            open={props.open}
            title="Map Hindrance Person"
            close={props.close}
        >
            <SearchHindrance ref={HINDRANCE} />
            <EmployeeSearch title="Employee" handleRef={ref=>EMPLOYEE.current=ref} />

            <Button style={{marginTop:20}} text="Add" onClick={add} />
            
        </MainModal>
    )
}

export default Index;