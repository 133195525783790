import {Switch,Route} from 'react-router-dom';
import Adhoc from './Category'

const Index = () => {

    return(
        <Switch>
            <Route path="/app/adhoc" component={Adhoc} />
        </Switch>
    )
}

export default Index;