import { useEffect, useState } from 'react';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import { useParams} from 'react-router-dom';
import Moment from 'moment';
import { get } from '../../../../Model/Network/Config/Axios';
import { useProject } from "../../../Hooks/ProjectDetails";
import { NavigationWrapper } from "../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../NCommon/PageWrapper";
import "gantt-task-react/dist/index.css";
import { PageLoader } from '../../../Common/PageLoader';



const Index = () =>{

    const details = useProject();
    let {id} = useParams();
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        INIT();
    },[])

    let INIT = () => {
        get(`project/meta/ganttChart/${id}`, (e, r) => {
            if (r) {

                let tasks = r.tasks.map((val, index) => {

                    let obj = {
                       id:val.id,
                       name:val.name,
                       type:'task',
                       start: val.start? new Date(Moment(val.start, 'YYYY-MM-DD')):new Date(),
                       end: val.end?new Date(Moment(val.end, 'YYYY-MM-DD')):new Date(),
                       progress:val.progress,
                       fontSize:12,
                       dependencies:val.dependencies,
                       key:index,
                       viewMode:'Year'
                    }

                    return obj;
                })


                console.log(tasks);

                setData(tasks);
                setLoading(false);

            
            }
            if (e) {
               setLoading(false);
            }
        })
    }

    if(loading) {
        return <PageLoader />
    }

    return(
        <PageWrapper>
            <NavigationWrapper items={[{name:'All Projects',path:'/app/project'},{name:`${details.name}`,path:`/app/project/mapping/${id}`},{name:`Gantt`}]}/>
            <div className="gantt-container" style={{background:'#fff'}}>
                <Gantt
                    tasks={data}
                    viewMode={"Month"}
                    onDateChange={()=>{}}
                    onTaskDelete={()=>{}}
                    onProgressChange={()=>{}}
                    onDoubleClick={()=>{}}
                    onClick={()=>{}}
            />  
            </div>
        </PageWrapper>
    )
}

export default Index;