import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { post } from "../../../../../Model/Network/Config/Axios";
import { Carousel } from "../../../../Common/Carousel";
import { KeyValue } from "../../../../Common/KeyValue";
import { PageLoader } from "../../../../Common/PageLoader";
import { useProject } from "../../../../Hooks/ProjectDetails";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import TaskModal from "../../../../NCommon/ProjectTask/TaskModal";
import { PDFExport } from "@progress/kendo-react-pdf";
import { DownloadCsv } from "../../../../../Utils/Downloader";

const Dpr = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [photos, setPhotos] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedDate, setSelectedDate] = useState("estimate")
  const project = useProject();
  const [status, setStatus] = useState([
    {
      name: "Progress",
      active: false,
      color: "#ebb840",
      key: "PROGRESS",
    },
    {
      name: "Not Started",
      active: false,
      color: "#404959",
      key: "NOTSTARTED",
    },
    {
      name: "Delay",
      active: false,
      color: "#ff944d",
      key: "DELAY",
    },
    {
      name: "Completed",
      active: false,
      color: "#68d083",
      key: "COMPLETED",
    },
    {
      name: "Critical Task",
      active: false,
      color: "#ea3a3c",
      key: "CRITICAL",
    },
  ]);
  const DPR = useRef();

  useEffect(() => {
    INIT({});
  }, []);

  useEffect(() => {
    let filter = [];
    filters.forEach((item) => {
      filter.push({
        key: item.type,
        val: item.value,
      });
    });
    INIT({ filter });
    if (selectedDate) {
      INIT({ filter })
    }
  }, [status, selectedDate])

  let INIT = (data) => {
    let otherNodes = [];
    if (data.filter) {
      data.orFilters = [...data.filter.filter((item) => item.key !== "NAME")];
      data.orFilters = [
        ...data.filter.filter((item) => item.key !== "RESPONSIBLE"),
      ];

      let NAMEFILTER = data.filter.filter((item) => item.key === "NAME");
      let RESPONSIBLEFILTER = data.filter.filter(
        (item) => item.key === "RESPONSIBLE"
      );

      otherNodes = [...NAMEFILTER, ...RESPONSIBLEFILTER];
      delete data.filter;
    }

    status.forEach((val) => {
      if (val.active) {
        otherNodes.push({ key: val.key, val: "" });
      }
    });

    if (otherNodes.length) {
      data.andFilters = otherNodes;
    }
    if (selectedDate) {
      data.dropDown = selectedDate.key
    }

    post(`project/mis/dprData/${id}`,data,(e,r)=>{
      if(r) {
        let obj = {};
        setData(r);
        r.updates.forEach(val=>{
          let items = [];
          val.tasks.forEach(item=>{
            item.checklists.forEach(item1=>{
              let singleItem = {
                url:item1.url,
                date:item1.date,
                remarks:item1.text,
                taskName:item.taskName
              }
              items.push(singleItem);
            })
          })
          obj[`${val.wbsItem}`]=items;
        })
        setPhotos(obj);
        setLoading(false);
      }
    })
  };

  const handleChangeStatusFilter = (key) =>{
    let items = [...status];
    items = items.map(val=>{
      if(key === val.key) {
        return {...val,active:!val.active}
      }
      return {...val}
    })
    setStatus(items)
  }

  let square = (title, value, backgroundColor, textColor, key) => {
    return (
      <div style={{ height: 120, width: "16.66%",cursor:"pointer" }} onClick={()=>handleChangeStatusFilter(key)}>
        <div
          className="flex flex-v-center flex-h-center"
          style={{
            margin: 10,
            flexDirection: "column",
            textAlign: "center",
            color: textColor,
            borderRadius: 4,
            fontWeight: 600,
            backgroundColor,
            height: "inherit",
          }}
        >
          <p style={{ fontSize: 14 }}>
            <span style={{ fontSize: 20, fontWeight: 800, paddingRight: 5 }}>
              {value}
            </span>{" "}
            task
          </p>
          <p style={{ fontSize: 14 }}>{title}</p>
        </div>
      </div>
    );
  };

  let header = (item, style) => {
    return (
      <div
        className="flex flex-v-center flex-h-center"
        style={{
          height: "inherit",
          padding: 2,
          fontWeight: "600",
          fontSize: 13,
          borderRight: "1px solid var(--border-color)",
          ...style,
        }}
      >
        {item}
      </div>
    );
  };

  let row = (item, style, onClick, isImage) => {
    return (
      <div
        onClick={() => {
          if (onClick) {
            openTask(item);
          }
        }}
        className={`${
          isImage && photos[item] ? "" : "flex flex-v-center flex-h-center"
        }`}
        style={{
          fontSize: 14,
          padding: 2,
          borderRight: "1px solid var(--border-color)",
          ...style,
        }}
      >
        {
            !isImage && <div>{item}</div>
        }
        {isImage && (
          <>
            <Carousel
              showDetails={true}
              showOuterDetails={false}
              items={photos[item] || []}
              sizeIncr={true}
              // items={[
              //   {
              //     url: "https://static.buildrun.app/upload/8/employees/SPPL-1627/21_05_2023/d1bad3b2-a2d5-4940-9f77-b25a205a69ed.jpg",
              //     remarks:
              //       "Malba cleaning work done by plumbing vendor and handed over to waterproofing team ",
              //     date: "21-05-2023",
              //     type: "WORKDONE",
              //     taskName:
              //       "JOI2.P Tower.P501.PLumbing.Cleaning of Malba by plumbing vendor in kitchen + Handover",
              //     wbsItemName: "JOI2.P Tower.P501",
              //     employeeName: "Sanjay Kumar Mallick",
              //   },
              // ]}
            />
          </>
        )}
      </div>
    );
  };

  let openTask = (val) => {
    let data = {
      /* */ orFilters: [{ key: "NAME", val }],
      page: 0,
      size: "50",
      sortedBy: "PLANNED_START",
      ascending: true,
      offset: "0",
    };
    post(`project/task/taskMapping/${id}`, data, (e, r) => {
      if (r) {
        setOpen({
          id: r.tasksMappings[0].id,
          wbsItemTaskName: r.tasksMappings[0].wbsItemTaskName,
        });
      }
    });
  };

  let download = () => {
    DPR.current.save();
  };

  let DownloadDprCsv = (data) => {
    let csvData = [];

    csvData.push(["Project Completion Date"]);
    csvData.push(["Today Estimated", data.today]);
    csvData.push(["Yesterday Estimated", data.yesterday]);

    csvData.push([" "]);
    csvData.push(["Task Status"]);
    csvData.push(["Task in Progress", data.inProgress+"/"+data.TotalinProgress]);
    csvData.push(["Task New Started", data.newStarted+"/"+data.EstnewStarted]);
    csvData.push(["Task Completed", data.completed+"/"+data.Estcompleted]);
    // csvData.push(["Task Completed", data.completed]);
    csvData.push(["Task Not Started", data.notStarted]);
    csvData.push(["Task Delay in Finish", data.delayedInFinish]);
    // csvData.push(["Task Delay", data.delayed]);
    // csvData.push(["Task Updated", data.totalUpdated]);
    csvData.push(["Task Added Today", data.newlyAdded]);

    csvData.push([" "]);
    csvData.push(["MIS Score"]);
    csvData.push([
      "Employee Name",
      "Live Task Updated",
      "Actually Started/ Due Start",
      "Actually Finished/Due Finished",
    ]);
    data.misScore.forEach((score) => {
      csvData.push([
        score.dueFinishActuallyFinished,
        score.dueStartActuallyStarted,
        score.empName,
        score.liveTaskUpdate,
      ]);
    });

    csvData.push([" "]);
    csvData.push(["Task Updates"]);
    csvData.push([
      "Task",
      "Doer",
      "ES/AS",
      "EF/AF",
      "Started",
      "Updated",
      "Completed",
      "Not Started",
      "Not Updated",
      "Not Completed",
      "Critical",
    ]);

    Object.keys(data.uniqueTasks).forEach((task) => {
      csvData.push([
        data.uniqueTasks[task].taskName,
        data.uniqueTasks[task].person,
        data.uniqueTasks[task].startDate
          ? data.uniqueTasks[task].startDate
          : "",
        data.uniqueTasks[task].endDate ? data.uniqueTasks[task].endDate : "",
        data.uniqueTasks[task].started ? data.uniqueTasks[task].started : "",
        data.uniqueTasks[task].updates ? data.uniqueTasks[task].updates : "",
        data.uniqueTasks[task].completed
          ? data.uniqueTasks[task].completed
          : "",
        data.uniqueTasks[task].taskNotStarted
          ? data.uniqueTasks[task].taskNotStarted
          : "",
        data.uniqueTasks[task].taskNotUpdated
          ? data.uniqueTasks[task].taskNotUpdated
          : "",
        data.uniqueTasks[task].taskNotCompleted
          ? data.uniqueTasks[task].taskNotCompleted
          : "",
        data.uniqueTasks[task].critical ? data.uniqueTasks[task].critical : "",
      ]);
    });

    DownloadCsv(`${data.projectName} dpr.csv`, csvData);
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <HeaderWrapper
        showFilter={false}
        types={[
          "Name",
          "Task",
          "Task Category",
          "Wbs category",
          "Wbs Mapping",
          "Vendor",
          "Responsible Person",
          "Custom",
        ]}
        dynamicFilter={true}
        filters={[...filters]}
        statusFilter={true}
        onFilterChange={(items) => {
          let filter = [];
          items.forEach((item) => {
            filter.push({
              key: item.type,
              val: item.value,
            });
          });
          setFilters(items);
          INIT({ filter });
        }}
        status={status}
        onChangeStatusFilter={setStatus}
        menuItem={[
          { name: "Download DPR", type: "downloadcsv", onClick: download },
          {
            name: "Download CSV",
            type: "downloadcsv",
            onClick: () => DownloadDprCsv(data),
          },
        ]}
        selectDatesDropdown={[
          { label: 'Estimate Date', key: "estimate" },
          { label: 'Original Date', key: "original" },
          { label: 'Workfront Date', key: "workfront" }
        ]}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
      />
      <PageWrapper>
        <NavigationWrapper
          items={[
            { name: "All Project", path: "/app/project" },
            { name: `${project.name}`, path: `/app/project/mapping/${id}` },
            { name: "Dpr" },
          ]}
        />
        <PDFExport ref={DPR} fileName={`${project.name}.pdf`}>
          <div className="flex">
            <div
              style={{ flex: 1, marginRight: 20 }}
              className="title-container-1"
            >
              <p style={{ fontWeight: 600, fontSize: 15, marginBottom: 20 }}>
                Project Completion Date
              </p>
              <KeyValue title={"Today Estimated"} desc={data.today} />
              <KeyValue title={"Yesterday Estimated"} desc={data.yesterday} />
            </div>
            <div
              style={{ flex: 2, paddingLeft: 10, paddingRight: 10 }}
              className="title-container-1"
            >
              <p style={{ fontWeight: 600, fontSize: 15, marginBottom: 10 }}>
                Task Status
              </p>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {square('In Progress', data.inProgress+"/"+data.TotalinProgress, 'rgba(235, 184, 64, .1)', '#e7a918', "PROGRESS")}
                {/*{square('Delay', data.delayed, 'rgba(234, 58, 60, .1)', '#ea3a3c', "NOTSTARTED")}*/}
                {square('New Started', data.newStarted+"/"+data.EstnewStarted, 'rgb(247, 255, 229)', '#36b056')}
                {square('Completed', data.completed+"/"+data.Estcompleted, 'rgba(104, 208, 131, .1)', '#36b056', "COMPLETED")}
                {square('Not Started', data.notStarted, 'rgba(234, 58, 60, .1)', '#ea3a3c', "NOTSTARTED")}
                {square('Delay in Finish', data.delayedInFinish, 'rgba(234, 58, 60, .1)', '#ea3a3c', "DELAY")}
                {/*{square('Updated', data.totalUpdated, 'rgba(255, 148, 77, .1)', '#ff944d', "CRITICAL")}*/}
                {square('Added Today', data.newlyAdded, 'rgba(51, 204, 204,.1)', '#33cccc')}
              </div>
            </div>
          </div>

          <div className="title-container-1">
            <p style={{ fontWeight: 600, fontSize: 15, marginBottom: 20 }}>
              MIS Score
            </p>
            <div
              style={{
                border: "1px solid var(--border-color)",
                display: "flex",
                height: 40,
                background: "var(--background-color)",
              }}
            >
              {header("Employee Name", { flex: 1 })}
              {header("Live Task Updated", { flex: 1 })}
              {header("Actually Started/ Due Start", { flex: 1 })}
              {header("Actually Finished/Due Finished", { flex: 1 })}
            </div>
            {data.misScore.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    border: "1px solid var(--border-color)",
                    display: "flex",
                    height: 50,
                  }}
                >
                  {row(item.empName, { flex: 1 })}
                  {row(item.liveTaskUpdate, { flex: 1 })}
                  {row(item.dueStartActuallyStarted, { flex: 1 })}
                  {row(item.dueFinishActuallyFinished, { flex: 1 })}
                </div>
              );
            })}
          </div>

          <div className="title-container-1">
            <p style={{ fontWeight: 600, fontSize: 15, marginBottom: 20 }}>
              Task Updates
            </p>
            <div
              style={{
                border: "1px solid var(--border-color)",
                display: "flex",
                height: 40,
                background: "var(--background-color)",
              }}
            >
              {header("Task", { flex: 1.5 })}
              {header("Doer", { flex: 1 })}
              {header("ES/AS", { flex: 0.7 })}
              {header("EF/AF", { flex: 0.7 })}
              {header("Started", { flex: 0.5 })}
              {header("Updated", { flex: 0.5 })}
              {header("Completed", { flex: 0.5 })}
              {header("Not Started", { flex: 0.5 })}
              {header("Not Updated", { flex: 0.5 })}
              {header("Not Completed", { flex: 0.5 })}
              {header("Critical", { flex: 0.5 })}
              {header("Images", { flex: 0.5 })}
            </div>
            {Object.keys(data.uniqueTasks).map((val, index) => {
              return (
                <div
                  key={index}
                  style={{
                    border: "1px solid var(--border-color)",
                    display: "flex",
                    height: "fit-content",
                  }}
                >
                  {row(
                    val,
                    {
                      flex: 1.5,
                      fontSize: 13,
                      justifyContent: "flex-start",
                      cursor: "pointer",
                      fontWeight: "600",
                    },
                    true,
                  )}
                  {row(data.uniqueTasks[val].person, { flex: 1 })}
                  {row(data.uniqueTasks[val].startDate, { flex: 0.7 })}
                  {row(data.uniqueTasks[val].endDate, { flex: 0.7 })}
                  {row(data.uniqueTasks[val].started, {
                    flex: 0.5,
                    fontSize: 13,
                  })}
                  {row(data.uniqueTasks[val].updates, {
                    flex: 0.5,
                    fontSize: 13,
                  })}
                  {row(data.uniqueTasks[val].completed, {
                    flex: 0.5,
                    fontSize: 13,
                  })}
                  {row(data.uniqueTasks[val].taskNotStarted, {
                    flex: 0.5,
                    fontSize: 13,
                  })}
                  {row(data.uniqueTasks[val].taskNotUpdated, {
                    flex: 0.5,
                    fontSize: 13,
                  })}
                  {row(data.uniqueTasks[val].taskNotCompleted, {
                    flex: 0.5,
                    fontSize: 13,
                  })}
                  {row(data.uniqueTasks[val].critical, {
                    flex: 0.5,
                    fontSize: 13,
                  })}
                  {row(val, {
                    flex: 0.5,
                    fontSize: 13,
                    justifyContent: "flex-start",
                    cursor: "pointer",
                  }, false, true)}
                </div>
              );
            })}
          </div>
          {/* {Object.keys(photos).map((val, index) => {
            return (
              <div className="title-container-1" key={index}>
                <p style={{ fontWeight: 600, fontSize: 15, marginBottom: 20 }}>
                  {val}
                </p>
                <Carousel showDetails={true} items={photos[val]} />
              </div>
            );
          })} */}
        </PDFExport>
      </PageWrapper>
      {open ? (
        <TaskModal
          onClose={() => {
            setOpen(false);
          }}
          details={{
            id: open.id,
            wbsItemTaskName: open.wbsItemTaskName,
          }}
          init={() => {
            setLoading(true);
            let filter = [];
            filters.forEach((item) => {
              filter.push({
                key: item.type,
                val: item.value,
              });
            });
            INIT({ filter });
            // INIT({filter:filters})
          }}
        />
      ) : null}
    </>
  );
};

export default Dpr;
