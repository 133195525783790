import { useEffect, useState } from "react";
import { get,axios } from "../../../../../Model/Network/Config/Axios";
import { AccessButton,Button } from "../../../../Common/Button";
import { Modal } from "../../../../Common/Modal/Modal";
import { PageLoader } from "../../../../Common/PageLoader";
import { showToast } from "../../../../Common/Toast";
import { useTableCount } from "../../../../Hooks/TableCount";
import { Header } from "../../../../NCommon/Header";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import AddCategory from './AddCategory';
import {PaginationTable} from '../../../../NCommon/Tables/PaginationTable';
import { Card } from '../../../../NCommon/Ui/CardLayout';
import {List} from '../../../../NCommon/Ui/List';
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";


const Index = () =>  {

    const [loading,setLoading] = useState(true);
    const [add,setAdd] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [filters,setFilters] = useState([]);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [data,setData] = useState({
        items:[],
        page:0,
        total:0
    });
    let count = useTableCount();

    useEffect(()=>{
      INIT({page:0});
    },[])

    let INIT = (mData) =>{
        if(!mData) {
            mData = {page:0}
        }
        mData.size=`${count}`;
        mData.offset = (mData.page*count).toString();
        get(`project/drawing/getDrawingCategories`,(e,r)=>{
            if(r) {
                setData({
                    ...data,
                    items:r.categories,
                    total:r.total?r.total:data.total,
                    page:mData.page
                })
                setLoading(false);
            }
        },{...mData})
    }


    let mapCardLayout = (item,index) => {
        return(
            <Card height={120} items={[{name:'Delete',onClick:()=>{setDeleteModal(item)}}]} onDelete={()=>{setDeleteModal(item)}} key={index} title={item.name} desc={item.description} />
        )
    }


    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <div className="table-title">{item.name}</div>
                    <div className="table-bottom">{item.description}</div>
                </div>
                <TableMenu items={[{name:'Delete',onClick:()=>{setDeleteModal(item)}}]}  />
            </List>
        )
    }

    return(
        <>
            <Header title="Drawing Category" />
            {
                loading ?
                <PageLoader />
                :
                <>
                    <HeaderWrapper
                         types={["Drawing Name"]}
                         dynamicFilter={true}
                         filters={[...filters]}
                         onFilterChange={items=>{
                            let filter = {};
                            items.forEach(item=>{
                                filter['name'] = item.label;
                            })
                            setFilters(items)
                            INIT({page:0,filter})
                        }}
                    />
                    <PageWrapper>
                        <NavigationWrapper items={[{name:`Drawing category (${data.total})`}]}>
                            <AccessButton style={{padding:'0px 10px'}} onClick={()=>{setAdd(true)}} inverted={true?1:0} addsign={true?1:0} text="Add Drawing Category" />
                        </NavigationWrapper>
                        <PaginationTable
                                DATA={data.items}
                                next={(page)=>{
                                    INIT({page})
                                }}
                                mapListLayout={mapListLayout}
                                mapCardLayout={mapCardLayout}
                                total={data.total}
                                page={data.page}
                                headers={[
                                    {
                                        name:'Details',
                                        flex:1
                                    }
                                ]}
                                />
                    </PageWrapper>
                </>
            }

            {
                add ?
                <AddCategory 
                    close={()=>{
                        setAdd(false);
                    }}
                    init={()=>{
                        INIT();
                        setAdd(false)
                    }}
                />
                : null
            }
              {
                deleteModal?
                <Modal
                    title="Remove Drawing Template"
                    desc={deleteModal.name}
                    show={true}
                    onClose={()=>{
                        setDeleteModal(false)
                    }}
                    style={{
                        minHeight:240
                    }}
                >
                    <p style={{
                        fontSize:13,
                        color:'var(--text-light-color)',
                        marginTop:40
                    }}>Are you sure want to remove mapping of {deleteModal.name} ?</p>
                    <Button loading={buttonLoading} text="Remove" style={{marginTop:50}} onClick={()=>{
                        setButtonLoading(true);
                         axios.delete(`project/task/drawing/removeDrawingTemplate/${deleteModal.id}`, {
                            data: {
                            }
                        })
                            .then(res => {
                                setDeleteModal(false);
                                setButtonLoading(false);
                                INIT();
                                showToast({
                                    type: 'success',
                                    message: "Drawing Template remove Successfully"
                                })
                            })
                            .catch(e=>{
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                                setButtonLoading(false);
                            })
                    }} />
                </Modal>
                : null
            }
        </>
    )
}

export default Index;