import React from 'react';
import Moment from 'moment';
import { Button } from '../../../../../Common/Button';
import { withDictionaryHook } from '../../../../../Hooks/DictionaryHooks';
import { MixPanel } from '../../../../../Hooks/Mixpanel';
import { SimpleFlex } from '../../../../../Common/Flex/SimpleFlex';
import { SearchTaskMappingItemN } from '../../../../../Common/Search/SearchTaskMappingItemN';
import { SearchTaskN } from '../../../../../Common/Search/SearchTaskN';
import { SearchWbsMappingItemN } from '../../../../../Common/Search/SearchWbsMappingItemN';
import { AddRoles } from '../../../../../Common/Roles';
import { MultiAdhocTask } from '../../../../../Common/Search/MultiAdhocTask';
import { showToast } from '../../../../../Common/Toast';
import { FloatInput } from '../../../../../Common/FloatInput';
import { EmployeeSearch } from '../../../../../Common/Search/SearchEmployee';
import { SearchSelect } from '../../../../../Common/Search/SearchSelect/SearchSelect';
import { post } from '../../../../../../Model/Network/Config/Axios';
import { SearchTaskCategory } from '../../../../../Common/Search/SearchTaskCategory';
import { SearchVendor } from '../../../../../Common/Search/SeachVendor';



class MapTaskItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonLoading: false,
            showFullKiting:false,
            type:undefined,
            role:undefined,
            category:undefined
        }
    }


    componentDidMount() {
        MixPanel.init('Add Task Page Open')
    }

    _onAdd = () => {

        let { id } = this.props
        let actualStartDate = this.actualStartDate.getValue()?this.actualStartDate.getValue():null;
        let actualEndDate = this.actualEndDate.getValue()?this.actualEndDate.getValue():null;
        let vendor = this.vendor.getValue();

        if (actualStartDate) {
            actualStartDate = Moment(actualStartDate, 'YYYY-MM-DD');
            actualStartDate = Moment(actualStartDate).valueOf();
        }
        if (actualEndDate) {
            actualEndDate = Moment(actualEndDate, 'YYYY-MM-DD');
            actualEndDate = Moment(actualEndDate).valueOf();
        }
        if (actualStartDate && actualEndDate) {
            let c1 = Moment(this.actualStartDate.getValue(), 'YYYY-MM-DD');
            let c2 = Moment(this.actualEndDate.getValue(), 'YYYY-MM-DD');
            if (Moment(c1).valueOf() > Moment(c2).valueOf()) {
                showToast({
                    type: 'error',
                    message: 'Start Date is not to be greater than end date'
                })
                return;
            }
        }



        let taskIds = this.task.getValue();
        let wbsItemMappingId = this.mappedWbsItem.getValue();
        let predecessorRelation = this.pRelation.getValue();
        let predecessorAndType = this.pType.getValue();
        let responsiblePerson = this.person.getValue();
        let predecessorTaskId = this.pred.getValue();
        let approver = this.approverPerson.getValue();

        if (!taskIds) {
            showToast({
                type: 'error',
                message: 'Please enter the task'
            })
            return null;
        }

        if (!wbsItemMappingId) {
            showToast({
                type: 'error',
                message: 'Please mapped the wbs item'
            })
            return;
        }


       

        if(predecessorTaskId.length>0 && !predecessorRelation) {
            showToast({
                type:'error',
                message:"Please select the precedessor relation"
            })
            return;
        }

        if(predecessorTaskId.length>0 && predecessorRelation && predecessorRelation.key === 'NONE') {
            showToast({
                type:'error',
                message:"Please select the valid precedessor relation"
            })
            return;
        }

        let totalDay = this.totalDays.getValue();


        let lagDays = this.lagDay.getValue();


        let data = {
            projectId: +id,
            taskId: +taskIds.id,
            wbsItemMappingId: +wbsItemMappingId.id,
            predecessorRelation:predecessorTaskId.length===0 ?'NONE':predecessorRelation.key,
            responsiblePerson:responsiblePerson? responsiblePerson.employeeCode:null,
            predecessorTaskId: predecessorTaskId.length > 0 ? predecessorTaskId.map(val => val.value) : null,
            totalDays: Number(totalDay),
            lagDay: lagDays ? Number(lagDays) : null,
            approver: approver? approver.employeeCode : null,
            actualEndDate,
            actualStartDate,
            predecessorAndType: predecessorAndType ?predecessorAndType.key === 'OR' ? false:true:null,
            buffer:this.buffer.getValue()?+this.buffer.getValue():0,
            isFullKitting:this.state.showFullKiting ? this.isFullKiting.getValue().key   :false
        }

        let adhoc = this.adhoc.getValue();
        if (adhoc && adhoc.length > 0) {
            data.requiredAdhocTasks = adhoc.map(val => val.label);
        }

        if(this.state.role) {
            data.roleId=this.state.role
        }

        if(vendor) {
            data.vendorId = vendor.vendorMappingId;
        }

        this.setState({
            ...this.state,
            buttonLoading: true
        })

        MixPanel.init(`Add Task Init`)
        post(`project/task/taskMapping.create`, data, (e, r) => {
            if (r) {
                this.setState({
                    ...this.state,
                    buttonLoading: false
                })
                showToast({
                    type: 'success',
                    message: "Task Created Successfully"
                })
                this.props.onComplete();
            }
            if (e) {
                this.setState({
                    ...this.state,
                    buttonLoading: false
                })
                showToast({
                    type: 'error',
                    message: e.data.message
                })
            }
        })
    }


    render() {
        let { id,getDictionaryValue } = this.props;
        return (
            <>
                 <SearchWbsMappingItemN 
                    projectId={id} 
                    title={`Mapped ${getDictionaryValue('wbs')} Item`}
                    handleRef={ref => this.mappedWbsItem = ref} 
                    placeholder={`Search mapped ${getDictionaryValue('wbs')} item`}
                    />
                <SearchTaskCategory
                    title={`Search ${getDictionaryValue('Task')} Category`}
                    handleRef={ref=>this.taskCategory=ref}
                    onChange={(item)=>{
                        this.setState({
                            ...this.state,
                            category:item
                        })
                    }}
                />
                <SearchTaskN 
                    title={`Search ${getDictionaryValue('Task')}`}
                    handleRef={ref => this.task = ref} 
                    category={this.state.category}
                    categorySearch={true}
                    type="REGULAR"
                    />
               
                <div style={{
                    display: 'flex',
                    flex: 1
                }}>
                    <FloatInput 
                            type="text" 
                            title="Total Days" 
                            placeholder="Enter total number of days"
                            handleRef={ref => this.totalDays = ref} 
                            style={{flex:1,marginRight:30}}
                            />
                    <FloatInput 
                            type="number"
                            title="Buffer (optional)"
                            placeholder="Enter the buffer"
                            handleRef={ref=>this.buffer=ref}
                            style={{flex:1}}
                            />
                </div>

                <SimpleFlex>
                        <EmployeeSearch
                            title="Responsible Person(optional)"
                            placeholder="Search Responsible Person"
                            handleRef={ref => this.person = ref}
                            style={{
                                marginRight:30
                            }}
                        />
                        <EmployeeSearch
                            title="Approver(optional)"
                            placeholder="Search Approver"
                            handleRef={ref => this.approverPerson = ref}
                        />
                </SimpleFlex>

                <SimpleFlex>
                       <FloatInput 
                            type="date" 
                            title="Actual Start Date (optional)" 
                            placeholder="Actual Start Date"
                            handleRef={ref => this.actualStartDate = ref} 
                            style={{
                                marginRight:30
                            }}
                            />
                          <FloatInput 
                            type="date" 
                            title="Actual End Date (optional)" 
                            placeholder="Actual End Date"
                            handleRef={ref => this.actualEndDate = ref} />  
                </SimpleFlex>
                <SimpleFlex>   
                    <SearchSelect
                        title="Predecessor Type (optional)"
                        handleRef={ref => this.pType = ref}
                        placeholder="Select predecessor Type"
                        items={[
                            {
                                key: 'OR',
                                value: 'OR'
                            },
                            {
                                key: 'AND',
                                value: 'AND'
                            },
                            ]}
                        style={{
                            flex:1,
                            marginRight:30
                        }}
                        />
                     <SearchSelect
                            title="Predecessor Relation (optional)"
                            handleRef={ref => this.pRelation = ref}
                            placeholder="Select predecessor relation"
                            value={{
                                key:'NONE',
                                value:'NONE'
                            }}
                            items={[
                                {
                                    key: 'START',
                                    value: 'START'
                                },
                                {
                                    key: 'FINISH',
                                    value: 'FINISH'
                                },
                                {
                                    key: 'NONE',
                                    value: 'NONE'
                                }
                            ]}
                        />
                </SimpleFlex>
               

                <SearchTaskMappingItemN
                        title="Select Predecessor (optional)" 
                        handleRef={ref=>this.pred=ref}
                        projectId={id}
                      />

                 <FloatInput 
                        type="text" 
                        title="+/- days wrt predecessor (optional)" 
                        placeholder="Enter total number of lag day"
                        handleRef={ref => this.lagDay = ref} 
                        />
                <MultiAdhocTask
                            title="Select Adhoc Type (optional)"
                            handleRef={ref => this.adhoc = ref}
                            onSelect={(option)=>{
                                if(option.length>0) {
                                    this.setState({
                                        ...this.state,
                                        showFullKiting:true
                                    })
                                } else {
                                    this.setState({
                                        ...this.state,
                                        showFullKiting:false
                                    })
                                }
                            }}
                            projectId={+id}
                        />
                    

                    {this.state.showFullKiting ?
                    <SearchSelect
                            title="is Full kitting"
                            handleRef={ref => this.isFullKiting = ref}
                            placeholder="is Full Kitting"
                            value={{
                                key:false,
                                value:"FALSE"
                            }}
                            items={[
                                {
                                    key: true,
                                    value: 'TRUE'
                                },
                                {
                                    key: false,
                                    value: 'FALSE'
                                },
                               
                            ]}
                        />
                        : null}
                <AddRoles title="Search Role (optional)" onSelect={role=>{this.setState({...this.state,role})}} />
                <SearchVendor title="Search Vendor(optional)" ref={ref=>this.vendor=ref} />
               
                <Button
                    text="Add"
                    style={{
                        marginTop: 36
                    }}
                    onClick={this._onAdd}
                    loading={this.state.buttonLoading}
                />
            </>
        )
    }
}

export default withDictionaryHook(MapTaskItem);