import { Switch, Route } from 'react-router-dom';
import Employee from './Employee';
import EmployeeDetails from './EmployeeDetails';

const Index = () => {

        let employee = `/app/employee`;
        let employeeDetails = `/app/employee/details/:id`

        return (
            <Switch>
                 <Route path={employeeDetails} component={EmployeeDetails} />
                 <Route path={employee} component={Employee} />
            </Switch>
        )
}

export default Index;