import React from 'react';
import { connect } from 'react-redux';
import Pager from '../Component/Pages/index';
import { configController } from '../Redux/Actions/Config';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

class AuthenticatedController extends React.Component {



    componentDidMount() {
        this.props.init();
    }


    render() {
        if (!this.props.config.authenticated) {
            this.props.history.push('/')
        }
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Pager {...this.props} />
            </MuiPickersUtilsProvider>
        )
    }
}



const mapDispatchToProps = (disptach) => {
    return {
        init: () => {
            disptach(configController())
        },
    }
}


const mapStateToProps = (state) => {
    return {
        config: state.config
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedController);
