import { useRef, useState } from "react";
import { post } from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { showToast } from "../../../../Common/Toast";
import { MainModal } from "../../../../NCommon/Modal";


const AddHinderance = (props) => {

    const [buttonLoading,setButtonLoading] = useState(false);
    let TITLE=useRef(),DESC=useRef();

    return(
            <MainModal
                title="Add Hinderance Type"
                close={props.close}
                open={props.open}
            >
                <FunctionalInput type="text" ref={TITLE} title="Title" />
                <FunctionalInput type="text" ref={DESC} title="Description" />
                <Button loading={buttonLoading} text="Submit"  onClick={()=>{
                    let title = TITLE.current.value;
                    let description  = DESC.current.value;
                    if(!title) {
                        showToast({
                            type:'error',
                            message:'Please enter the title'
                        })
                        return;
                    }
                    if(!description) {
                        showToast({
                            type:'error',
                            message:"Please enter the description"
                        })
                        return;
                    }

                    let data = {
                        title,description
                    }

                    setButtonLoading(true);

                    post(`setting/hindrance/addHindrance`,data,(e,r)=>{
                        if(r) {
                            setButtonLoading(false);
                            props.close();
                            showToast({
                                type:'success',
                                message:"Hinderance added successfully"
                            })
                        } else {
                            setButtonLoading(false);
                            showToast({
                                type:'error',
                                message:e.data.message
                            })
                        }
                    })
                }} style={{marginTop:25}} /> 

            </MainModal>
          
    )
}

export default AddHinderance;