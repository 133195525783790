import { useEffect, useRef, useState } from "react";
import downarrow from './downarrow.png';
import './Types.css';


const Types = (props) => {

    const [type,setType] = useState(props.type);
    const [show,setShow] = useState(false);
    let Ref = useRef();

    useEffect(()=>{
        window.addEventListener('click',handleClick);
        return ()=>{
            window.removeEventListener('click',handleClick)
        }
    },[])

    let handleClick = (e) => {
        if(!show && Ref.current && !Ref.current.contains(e.target)) {
            setShow(false)
        }
    }

    let mapList = (item,index) => {
        let active = props.type === item;
        return(
            <div onClick={()=>{props.onChangeType(item)}} className={`show-type-list`} key={index}>
                {item}
                <div className="flex-v-center" style={{width:35,background:'#dedede',borderRadius:16,height:18,position:'relative',paddingLeft:5,paddingRight:5,...active?{background:'var(--theme-main-color)',justifyContent:'flex-end'}:{}}}>
                    <div style={{background:'#fff',height:11,width:11,borderRadius:'50%'}} />
                </div>
            </div>
        )
    }

    return(
        <div ref={Ref}>
            <div onClick={()=>{setShow(!show)}} className="flex-v-center" style={{background:'var(--background-color)',cursor:'pointer',borderLeft:'1px solid var(--border-color)',height:35,width:30}}>
                <img src={downarrow} alt="" style={{height:30,width:30}} />
            </div>

            {
                show ?
                <div className="show-type">
                    <p style={{fontWeight:'600',fontSize:14,marginBottom:5,marginLeft:5}}>Search In</p>
                    {props.types.map(mapList)}
                </div>
                :null
            }
            
        </div>
    )
}

export default Types;