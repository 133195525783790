import { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import { post } from '../../../../../Model/Network/Config/Axios';
import { DownloadCsv } from '../../../../../Utils/Downloader';
import { PageLoader } from '../../../../Common/PageLoader';
import { useProject } from '../../../../Hooks/ProjectDetails';
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { Table } from '../../../../NCommon/Tables';
import { List } from '../../../../NCommon/Ui/List';



const Vendor = () => {

    let {id} = useParams();
    const [dump,setDump] = useState({});
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [filters,setFilters] = useState([]);
    const [up,setUp] = useState(false);
    const [sorted,setSorted] = useState('QTY')
    const project = useProject();

    const [status,setStatus] = useState([
        {
            name: "Estimated",
            active: false,
            color: "#ebb840",
            key:'ESTIMATED'
        },
        {
            name: "Baseline",
            active: false,
            color: "#0073ea",
            key:'ORIGINAL'
        },
    ])

    useEffect(()=>{
        setLoading(true)
        INIT();
    },[])
    

    useEffect(()=>{
        let filter = [];
        filters.forEach(item=>{
            filter.push({
                key:item.type==='TASK'?'TASK_NAME':item.type,
                val:item.type === 'WBS'?item.id:item.value
              })
        })
        INIT(filter)
    },[filters,up,status])

    let INIT = (filter=[]) => {
        let data = {
        };
        if(status[0].active) {
            data.estimated=true;
        }
        if(status[1].active) {
            data.original=true;
        }
        if(filter.length) {
            data.andFilters=[...filter]
        }
        post(`project/task/vendorProgressTracking/${id}`,data,(e,r)=>{
            if(r) {
                let tItems = Object.keys(r.tasks).sort((a,b)=>{
                    if(sorted==='QTY') {
                        if(up) {
                            return r.tasks[a].thisMonthPlanned- r.tasks[b].thisMonthPlanned;
                        } else {
                            return r.tasks[b].thisMonthPlanned- r.tasks[a].thisMonthPlanned;
                        }
                    }  else if(sorted==='TOTAL') {
                        if(up) {
                            return r.tasks[a].total- r.tasks[b].total;
                        } else {
                            return r.tasks[b].total- r.tasks[a].total;
                        }
                    } else if(sorted==='PREVIOUSBALANCE') {
                        if(up) {
                            return r.tasks[a].previousBalance- r.tasks[b].previousBalance;
                        } else {
                            return r.tasks[b].previousBalance- r.tasks[a].previousBalance;
                        }
                    } else if(sorted==='DELAYINSTART') {
                        if(up) {
                            return r.tasks[a].delayInStart- r.tasks[b].delayInStart;
                        } else {
                            return r.tasks[b].delayInStart- r.tasks[a].delayInStart;
                        }
                    } else if(sorted==='DELAYINFINISH') {
                        if(up) {
                            return r.tasks[a].delayInFinish- r.tasks[b].delayInFinish;
                        } else {
                            return r.tasks[b].delayInFinish- r.tasks[a].delayInFinish;
                        }
                    } else if(sorted==='PREVIOUSCOMPLETED') {
                        if(up) {
                            return r.tasks[a].previousCompleted- r.tasks[b].previousCompleted;
                        } else {
                            return r.tasks[b].previousCompleted- r.tasks[a].previousCompleted;
                        }
                    } else if(sorted==='PREVIOUSCOMPLETEDPER') {
                        if(up) {
                            return r.tasks[a].previousCompletedPer- r.tasks[b].previousCompletedPer;
                        } else {
                            return r.tasks[b].previousCompletedPer- r.tasks[a].previousCompletedPer;
                        }
                    }  else if(sorted==='BALANCECOMPLETED') {
                        if(up) {
                            return r.tasks[a].balanceCompleted- r.tasks[b].balanceCompleted;
                        } else {
                            return r.tasks[b].balanceCompleted- r.tasks[a].balanceCompleted;
                        }
                    }  else if(sorted==='BALANCECOMPLETEDPER') {
                        if(up) {
                            return r.tasks[a].balanceCompletedPer- r.tasks[b].balanceCompletedPer;
                        } else {
                            return r.tasks[b].balanceCompletedPer- r.tasks[a].balanceCompletedPer;
                        }
                    }  else if(sorted==='THISMONTHTILLTODAYPLANNED') {
                        if(up) {
                            return r.tasks[a].thisMonthTillTodayPlanned- r.tasks[b].thisMonthTillTodayPlanned;
                        } else {
                            return r.tasks[b].thisMonthTillTodayPlanned- r.tasks[a].thisMonthTillTodayPlanned;
                        }
                    }  else if(sorted==='THISMONTHCOMPLETED') {
                        if(up) {
                            return r.tasks[a].thisMonthCompleted- r.tasks[b].thisMonthCompleted;
                        } else {
                            return r.tasks[b].thisMonthCompleted- r.tasks[a].thisMonthCompleted;
                        }
                    }  else if(sorted==='THISMONTHCOMPLETEDPER') {
                        if(up) {
                            return r.tasks[a].thisMonthCompletedPer- r.tasks[b].thisMonthCompletedPer;
                        } else {
                            return r.tasks[b].thisMonthCompletedPer- r.tasks[a].thisMonthCompletedPer;
                        }
                    }  else if(sorted==='CUMULATIVECOMPLETED') {
                        if(up) {
                            return r.tasks[a].cumulativeCompleted- r.tasks[b].cumulativeCompleted;
                        } else {
                            return r.tasks[b].cumulativeCompleted- r.tasks[a].cumulativeCompleted;
                        }
                    }  else if(sorted==='CUMULATIVECOMPLETEDPER') {
                        if(up) {
                            return r.tasks[a].cumulativeCompletedPer- r.tasks[b].cumulativeCompletedPer;
                        } else {
                            return r.tasks[b].cumulativeCompletedPer- r.tasks[a].cumulativeCompletedPer;
                        }
                    }  else if(sorted==='THISMONTHTILLTODAYPLANNEDPER') {
                        let aa = 0;
                        if (r.tasks[a].thisMonthTillTodayPlanned) {
                            aa = r.tasks[a].thisMonthCompleted/r.tasks[a].thisMonthTillTodayPlanned;
                        }
                        let bb = 0;
                        if (r.tasks[b].thisMonthTillTodayPlanned) {
                            bb = r.tasks[b].thisMonthCompleted/r.tasks[b].thisMonthTillTodayPlanned;
                        }
                        if(up) {
                            return aa-bb;
                        } else {
                            return bb-aa;
                        }
                    }
                })
                setData(tItems);
                setDump(r.tasks);
                setLoading(false);
            }
        })
    }


    let mapListLayout = (item,index) => {
        let i = dump[item];
        let backgroundColor= '';
        let showPercentage = '-'
        if(i.thisMonthTillTodayPlanned>0) {
            let colorPercentage = ((i.thisMonthCompleted)/(i.thisMonthTillTodayPlanned)*100).toFixed(2);
             showPercentage= colorPercentage;
            if(colorPercentage<50) {
                backgroundColor='#ffcccc'
            } else if(colorPercentage>=50 && colorPercentage<=99) {
                backgroundColor="#ffffcc"
            } else {
                backgroundColor="#ccffcc"
            }
        }
        
        return(
            <List key={index} style={{minHeight:75,backgroundColor,position:'relative'}}>
                <div style={{width:320,position:'sticky',left:0,zIndex:1,background:backgroundColor?backgroundColor:'#fff',minHeight:72,borderRight:'1px solid var(--border-color)',display:'flex',alignItems:'center'}}>
                    <p className='table-title' style={{fontSize:14}}>{item}</p>
                </div>
                <div  style={{textAlign:'center',width:100}}>
                    <p className='table-text'>{i.total}</p>
                </div>
                <div style={{textAlign:'center',width:100}}>
                    <p className='table-text'>{i.previousBalance}</p>
                </div>
                <div style={{textAlign:'center',width:100}}>
                    <p className='table-text'>{i.delayInStart}</p>
                </div>
                <div style={{textAlign:'center',width:100}}>
                    <p className='table-text'>{i.delayInFinish}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{i.previousCompleted}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{i.previousCompletedPer.toFixed(2)}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{i.balanceCompleted}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{i.balanceCompletedPer.toFixed(2)}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{i.thisMonthPlanned}</p>
                </div>
               
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{i.thisMonthCompleted}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{i.thisMonthCompletedPer.toFixed(2)}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{i.thisMonthTillTodayPlanned}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{showPercentage}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                    <p className='table-text'>{i.cumulativeCompleted}</p>
                </div>
                <div style={{width:100,textAlign:'center'}}>
                     <p className='table-text'>{i.cumulativeCompletedPer.toFixed(2)}</p>
                </div>
            </List>
        )
    }

    let download = () => {
        let baseData = [
            [
                "Task Name",
                "Total Task",
                "Delay in Start",
                "Delay in Finish",
                "Previous Comp.",
                "Previous comp.%",
                "Balance up to",
                "Balance %",
                "This Month plan",
                "This month comp",
                "This month comp. %",
                "Cumulative comp",
                "Cumulative comp. %"
            ]
        ]

         data.forEach(val=>{
            let item = dump[val];
            baseData.push(
                [
                    val,
                    item.total,
                    item.delayInStart,
                    item.delayInFinish,
                    item.previousCompleted,
                    item.previousCompletedPer.toFixed(2),
                    item.balanceCompleted,
                    item.balanceCompletedPer.toFixed(2),
                    item.thisMonthPlanned,
                    item.thisMonthCompleted ,
                    item.thisMonthCompletedPer.toFixed(2),
                    item.cumulativeCompleted,
                    item.cumulativeCompletedPer.toFixed(2)
                ]
            )
        })

        DownloadCsv("TaskProgress.csv",baseData);
    }

    let onClick = (key) =>{
        setSorted(key);
        setUp(!up)
    }

    if(loading) {
        return <PageLoader />
    }

    return(
        <>  
            <HeaderWrapper 
                 hideCard={true} 
                 dynamicFilter={true} 
                 types={["Task","Vendor",
                     "Name",
                     "Task Category",
                     "Wbs category",
                     "Wbs Mapping",
                     "Responsible Person",
                     "Custom",
                 ]}

                 onFilterChange={(items) => {
                     let filter = [];
                     items.forEach((item) => {
                         filter.push({
                             key: item.type,
                             val: item.value,
                         });
                     });
                     setFilters(items);
                     // INIT({ filter });
                 }}

                //  onFilterChange={items=>{
                //     setFilters(items)
                // }}
                statusFilter={true}
                status={status}
                onChangeStatusFilter={setStatus}
                filters={[...filters]}
                menuItem={[{name:'Download Csv',type:'downloadcsv',onClick:download}]}
            />
            <PageWrapper defaultView={'list'}  id="pagination-table-header" scroll={true}>
            <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:'Contract Performance Matrix'}]} >
                <div style={{background:'#fff',padding:10,border:'1px solid var(--border-color)',borderRadius:4, marginRight: 10}}>
                    <div className='flex' style={{flex:1}}>
                        <p style={{width:15,height:15,background:'#ffcccc'}}></p>
                        <p style={{fontWeight:600,marginLeft:5}}>% completion less than 50</p>
                    </div>
                    <div className='flex' style={{flex:1}}>
                        <p style={{width:15,height:15,background:'#ffffcc'}}></p>
                        <p style={{fontWeight:600,marginLeft:5}}>% completion between 50 and 99</p>
                    </div>
                    <div className='flex' style={{flex:1}}>
                        <p style={{width:15,height:15,background:'#ccffcc'}}></p>
                        <p style={{fontWeight:600,marginLeft:5}}>% completion greater than 99</p>
                    </div>
                </div>
            </NavigationWrapper>
            <div style={{marginTop:20}} />
            <Table
                    DATA={data}
                    mapCardLayout={()=>{}}
                    defaultView={'list'}
                    mapListLayout={mapListLayout}
                    tableStyle={{
                        // width: 2040,
                    }}
                    headers={[
                      {
                        name:'Task Name',
                        width:320,
                        style:{
                            position:'sticky',
                            left:0,
                            background:'#fff',
                            zIndex:1,
                            height:50,
                            display:'flex',
                            alignItems:'center'
                        }
                      },
                      {
                        name:'Total Task',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='TOTAL',
                        onSortClick:()=>{
                            onClick('TOTAL')
                        },
                        up
                      },
                        {
                            name:'Delay in Start',
                            width:100,
                            style:{
                                textAlign:'center'
                            },
                            sort:sorted==='DELAYINSTART',
                            onSortClick:()=>{
                                onClick('DELAYINSTART')
                            },
                            up
                        },
                        {
                            name:'Delay in Finish',
                            width:100,
                            style:{
                                textAlign:'center'
                            },
                            sort:sorted==='DELAYINFINISH',
                            onSortClick:()=>{
                                onClick('DELAYINFINISH')
                            },
                            up
                        },
                      {
                        name:'Previous balance',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='PREVIOUSBALANCE',
                        onSortClick:()=>{
                            onClick('PREVIOUSBALANCE')
                        },
                        up
                      },
                      {
                        name:'Previous comp.',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='PREVIOUSCOMPLETED',
                        onSortClick:()=>{
                            onClick('PREVIOUSCOMPLETED')
                        },
                        up
                      },
                      {
                        name:'Previous comp.%',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='PREVIOUSCOMPLETEDPER',
                        onSortClick:()=>{
                            onClick('PREVIOUSCOMPLETEDPER')
                        },
                        up
                      },
                      {
                        name:'Balance up to',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='BALANCECOMPLETED',
                        onSortClick:()=>{
                            onClick('BALANCECOMPLETED')
                        },
                        up
                      },
                      {
                        name:'Balance %',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='BALANCECOMPLETEDPER',
                        onSortClick:()=>{
                            onClick('BALANCECOMPLETEDPER')
                        },
                        up
                      },
                      {
                        name:'This Month plan',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='QTY',
                        onSortClick:()=>{
                          setSorted('QTY');
                          setUp(!up)
                        },
                        up
                      },
                      {
                        name:'This month comp',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='THISMONTHCOMPLETED',
                        onSortClick:()=>{
                            onClick('THISMONTHCOMPLETED')
                        },
                        up
                      },
                      {
                        name:'This month comp. %',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='THISMONTHCOMPLETEDPER',
                        onSortClick:()=>{
                            onClick('THISMONTHCOMPLETEDPER')
                        },
                        up
                      },
                      {
                        name:'Plan till today for current month',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='THISMONTHTILLTODAYPLANNED',
                        onSortClick:()=>{
                            onClick('THISMONTHTILLTODAYPLANNED')
                        },
                        up
                      },

                      {
                        name:'exp. %  for current month',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='THISMONTHTILLTODAYPLANNEDPER',
                          onSortClick:()=>{
                              onClick('THISMONTHTILLTODAYPLANNEDPER')
                          },
                          up
                      },
                      {
                        name:'Cumulative comp.',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='CUMULATIVECOMPLETED',
                        onSortClick:()=>{
                            onClick('CUMULATIVECOMPLETED')
                        },
                        up
                      },
                      {
                        name:'Cumulative comp. %',
                        width:100,
                        style:{
                            textAlign:'center'
                        },
                        sort:sorted==='CUMULATIVECOMPLETEDPER',
                        onSortClick:()=>{
                            onClick('CUMULATIVECOMPLETEDPER')
                        },
                        up
                      },

                    ]}
                />
            </PageWrapper>
        </>
    )
}

export default Vendor;
