import { useRef, useState } from 'react';
import {useParams} from 'react-router-dom';
import { Button } from '../../../Common/Button';
import { SearchSelect } from '../../../Common/Search/SearchSelect/SearchSelect';
import { post } from '../../../../Model/Network/Config/Axios';
import { showToast } from '../../../Common/Toast/index';
import { FunctionalInput } from '../../../Common/FloatInput/FunctionalInput';
import { RightModal } from '../../../Common/Modal/RightModal';
import { ProjectCustomTag } from '../../../Common/CustomTagging/Project';
import { AddRoles } from '../../../Common/Roles';


const AddTask = (props) => {

    const [buttonLoading,setButtonLoading] = useState(false);
    const [role,setRole] = useState(null);
    const {id} = useParams();

    let TaskName=useRef(),TaskDetail=useRef(),TaskType=useRef(),Custom=useRef();


    let _addTask = () => {

        Custom.current.getValue(cb=>{

            let taskName = TaskName.current.value;
            if (!taskName) {
                showToast({
                    type: 'error',
                    message: 'Please enter the task name'
                })
                return
            }
            let taskDetails = TaskDetail.current.value;
            if (!taskDetails) {
                showToast({
                    type: 'error',
                    message: 'Please enter the task details'
                })
                return;
            }
    
    
            let data = {
                taskCategoryId: +id,
                taskName,
                taskDetails,
                taskType: 'REGULAR',
                roleId:role
    
            }
            if(cb && cb.length) {
                data.levelItemId = +cb[cb.length-1].levelId
            }
            setButtonLoading(true);
            post(`project/task/task.create`, data, (e, r) => {
                if (r) {
                    setButtonLoading(false);
                    showToast({
                        type: 'success',
                        message: 'Task Item added successfully'
                    })
                    props.onComplete()
                }
                if (e) {
                    showToast({
                        type: "error",
                        message: e.data.message
                    })
                    setButtonLoading(false)
                }
            })
    

        })

       
    }


    return (
            <RightModal
                show={true}
                onClose={props.onClose}
                title="Add Task"
                desc="Fill up the all details and add task"
            >
                <ProjectCustomTag type="TASK" handleRef={ref=>Custom.current=ref}    />
                <FunctionalInput type="text" title="Task Name" ref={TaskName}/>
                <FunctionalInput type="text" title="Task Details" ref={TaskDetail} />
                <AddRoles title="Search Role (optional)" onSelect={(id)=>{
                    setRole(id)
                }} />

                <Button
                    text="Add Task"
                    style={{
                        marginTop: 20
                    }}
                    onClick={_addTask}
                    loading={buttonLoading}

                />

            </RightModal>
        )
}

export default AddTask