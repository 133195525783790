import  { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';
import { arrow } from '../Images';



const Index = (props) => {
    const [active,setActive] = useState(false);
    let Profile = useRef();
    let HISTORY = useHistory();
    let {data} = props
   
    useEffect(()=>{
        window.addEventListener('click',onClick)

    },[]) 

   let onClick = (e) => {
        if (active && !Profile.current.contains(e.target)) {
            setActive(false);
        }
    }
    
    return (
            <div className="profile" ref={Profile}>
                <div style={{
                    height: 35,
                    width: 35,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...active ?
                        {
                            backgroundColor: 'rgba(92, 103, 138, .1)'

                        } : {
                        }
                }}>
                    <div onClick={() => { setActive(!active); }}
                        style={{
                            cursor: 'pointer',
                        }}
                        className={`sidebar-image ${active ? 'active' : 'inactive'}`} />
                </div>

                {active &&
                    <div style={{
                        position: 'absolute',
                        top: 55,
                        right: 0,
                        zIndex: 2,
                        backgroundColor: '#fff',
                        height: 200,
                        width: 300,
                        border: '1px solid var(--border-color)',
                        boxShadow: '0 2px 6px rgb(0 0 0 / 18%)',
                        padding: 15,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <div style={{
                            borderBottom: '1px dashed var(--border-color)',
                            paddingBottom: 10
                        }}>
                            <h2 style={{
                                fontSize: 14,
                                textTransform: 'uppercase',
                                color: 'var(--text-color)'
                            }}>{data.name}</h2>
                            <p style={{
                                fontSize: 12,
                                color: 'var(--text-light-color)'
                            }}>{data.email}</p>
                        </div>

                        <div style={{
                            paddingTop: 10,
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }} onClick={() => {
                            HISTORY.push('/app/setting/reset');
                            setActive(false);
                        }}>
                            <p style={{
                                fontWeight: 600,
                                color: 'var(--button-color)',
                                fontSize: 12,
                            }}>Reset Password</p>
                            <img src={arrow} alt="" style={{
                                height: 10,
                                width: 10,
                                marginLeft: 5
                            }} />
                        </div>

                        <Button style={{marginTop: 'auto'}}
                            text="Logout"
                            onClick={() => {
                                window.localStorage.clear()
                                window.location.reload()
                            }}
                        />
                    </div>}
            </div>
        )
}

export default Index;