import  { useEffect, useState,useRef } from 'react';
import {useParams} from 'react-router-dom';
import Moment from 'moment';
import { get, patch } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import { KeyValue } from '../../../../Common/KeyValue';
import { AccessButton, Button } from '../../../../Common/Button';
import { FunctionalInput } from '../../../../Common/FloatInput/FunctionalInput';
import { showToast } from '../../../../Common/Toast';
import { MultipleRoles } from '../../../../Common/Search/MultipleRoles';
import { EmployeeSearch } from '../../../../Common/Search/SearchEmployee';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import { MultipleProject } from '../../../../Common/Search/MultipleProject';
import { SearchSelect } from '../../../../Common/Search/SearchSelect/SearchSelect';



const Index = () => {

    const [loading,setLoading] = useState(true);
    const [edit,setEdit] = useState(false);
    const [details,setDetails] = useState({});
    const [broadCast,setBroadCast] = useState(false);
    const [broadCastValue,setBroadCastValue] = useState({key:'True',value:'True'})
   
    let {id} = useParams();
    let fName=useRef(),lName=useRef(),Email=useRef(),Mobile=useRef(),Manager=useRef(),Role = useRef(),Project=useRef();

    useEffect(()=>{
        INIT(id)
    },[id])


   let INIT = (employeeCode) => {
        get(`/employees/details/${employeeCode}`, (e, r) => {
            if (r) {
                if(r.employeeData.broadcast) {
                    setBroadCastValue({key:'True',value:"True"})
                } else {
                    setBroadCastValue({key:'False',value:"False"})
                }
                setDetails(r);
                setLoading(false);
            }
        })
    }

   let updateDetails = () => {

        let firstName = fName.current.value;
        let lastName = lName.current.value;
        let email = Email.current.value;
        let mobile = Mobile.current.value;
        let manager = Manager.current.getValue()?Manager.current.getValue().employeeCode:undefined;
        let dob = Moment(details.dob).valueOf();
        let name = firstName + " " + lastName;

        let roles = Role.current.getValue();
        let projects = Project.current.getValue();
        let data = {
            name,
            firstName,
            lastName,
            email,
            mobile,
            manager,
            dob,
            broadcast:broadCast
        }

        if(roles.length) {
            data.role = roles[0].value;
            data.roles = roles.map(val=>val.value)
        }

        if(projects.length) {
            data.projects = projects.map(val=>val.value);
        } else {
            data.projects =[];
        }

        patch(`/employees/${id}`, data, (e, r) => {
            if (r) {
                setEdit(false);
                showToast({
                    type:'success',
                    message:'Employee Details Updated Successfully'
                })
                INIT(id);
            }
        })
    }

    let editDetails = () => {
        return (
            <div>
                <FunctionalInput
                    inputProps={{
                        defaultValue: details.employeeData.firstName
                    }}
                    title="First Name"
                    ref={fName}
                />
                <FunctionalInput
                    inputProps={{
                        defaultValue: details.employeeData.lastName
                    }}
                    title="Last Name"
                    ref={lName}
                />
                <FunctionalInput
                    inputProps={{
                        defaultValue: details.email
                    }}
                    title="Email"
                    ref={Email}
                />

                <FunctionalInput
                    inputProps={{
                        defaultValue: details.mobile
                    }}
                    title="Mobile"
                    ref={Mobile}
                />
                <EmployeeSearch 
                    title="Manager"
                    ref={Manager}
                    value={{
                        name:details.manager?details.manager:'',
                        employeeCode:details.manager?details.manager:''
                    }}
                />
                 <SearchSelect 
                    title="BroadCast Type" 
                    onChange={(val)=>{
                            setBroadCast(val==='True'?true:false)
                    }} 
                    value={broadCastValue}
                    items={[{value:"True",key:'True'}, {value:"False",key:'False'},]} />
                <MultipleRoles prefill={details.roles.map(val=>({label:val.name,value:val.id}))} handleRef={ref=>Role.current=ref} title="Select Roles (optional)" />
                <MultipleProject prefill={details.projects.map(val=>({label:val.name,value:val.id}))} handleRef={ref=>Project.current=ref} title="Select Roles (optional)" />

                <Button
                    text="Update Details"
                    style={{
                        marginTop: 30
                    }}
                    onClick={updateDetails}
                />
            </div>
        )
    }

    if (loading) {
        return <PageLoader />
    }
     return (
            <>
                <PageWrapper>
                    <NavigationWrapper items={[{name:'All Employees',path:'/app/employee'},{name:`${details.name}`}]}>
                        {edit?null:<AccessButton text="Edit Details" inverted={true?1:0} onClick={()=>{setEdit(true)}} />}
                    </NavigationWrapper>
                    <div className="title-container-1">
                    {
                        edit ?
                            editDetails() :
                            <>
                                <KeyValue title="Name" desc={details.name} />
                                <KeyValue title="Code" desc={details.employeeCode} />
                                <KeyValue title="Email" desc={details.email} />
                                <KeyValue title="Mobile" desc={details.mobile} />
                                <KeyValue title="BroadCast" desc={details.employeeData.broadcast?"Enabled":"False"} />
                                <KeyValue title="Role" desc={details.role?details.role.name:'Not available'} />
                                <p style={{fontSize:14,color:'var(--text-light-color)',marginBottom:5}}>All Roles</p>
                                {details.roles.map((val,index)=>(<KeyValue title={`Role ${index+1}`} desc={val.name} />))}
                                {details.roles.length===0?<p style={{fontSize:16,marginBottom:5}}>No Role Found</p>:null}
                                <KeyValue title="Manager" desc={details.manager ? details.manager : 'No Manager Assigned'} />
                                <p style={{fontSize:14,color:'var(--text-light-color)',marginBottom:5}}>All Project</p>
                                {details.projects.map((val,index)=>(<KeyValue title={`Project ${index+1}`} desc={val.name} />))}

                            </>
                    }
                    </div>
                </PageWrapper>
            </>
        )
}

export default Index;