import { useEffect, useRef, useState } from "react"
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { Modal } from "../../../../Common/Modal/Modal"
import { Button } from "../../../../Common/Button";
import { post } from "../../../../../Model/Network/Config/Axios";
import { showToast } from "../../../../Common/Toast";
import Transfer from './Transfer';

const Index = ({details,projectId,init}) => {

    const [show,setShow] = useState(false);
    const [transfer,setTransfer] = useState(false);
    let QTY = useRef();

    useEffect(()=>{
        if(show) {
            QTY.current.value = show.qty;
        }
    },[show])
    
    let mapItem = (item,index) => {
        return(
            <div style = {{
                padding:'10px 15px',
                border:'1px solid var(--border-color)',
                borderRadius:8,
                display:'flex',
                alignItems:'center',
                minHeight:60,
                fontSize:14,
                marginBottom:20
            }} key={index}>
                <div style ={{
                    width:40
                }}>
                    <div style={{
                        background:'var(--border-color)',
                        height:20,
                        width:20,
                        borderRadius:'50%',
                        fontSize:12,
                        fontWeight:'bold'
                    }} className="flex flex-v-center flex-h-center">
                        {index+1}
                    </div>
                </div>
                <div style={{
                    flex:2
                }} className="single-line">
                    <p style={{fontSize:14,fontWeight:600}}>{item.wbsItemTaskName}</p>
                </div>
                
                <div style={{flex:1,textAlign:'center'}}>
                    <p>{item.qty} {details.units}</p>
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                    <p>{item.actualAcquiredQty} {details.units}</p>
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                    <p>{item.actualUsedQty} {details.units}</p>
                </div>
              

                <div style={{
                    width:70,
                    fontSize:14,
                    color:'var(--button-color)',
                    fontWeight:'600',
                    textAlign:'center',
                    cursor:'pointer'
                }} onClick={()=>{
                    window.open(`/app/project/task/${projectId}/${item.taskMappingId}/details`,'_blank')
                }}>
                    View Task
                </div>
                <div style={{
                    width:150,
                    fontSize:14,
                    color:'var(--red-color)',
                    fontWeight:'600',
                    textAlign:'center',
                    cursor:'pointer'
                }} onClick={()=>{
                    if(item.refundPending) {
                        setShow(item);
                    }
                }}>
                    {item.refundPending ?
                    "Confirm Return":''
                      }
                </div>

                <div style={{
                    width:70,
                    fontSize:14,
                    color:'var(--button-color)',
                    fontWeight:'600',
                    textAlign:'center',
                    cursor:'pointer'
                }} onClick={()=>{
                    if(item.refundPending) {
                        setTransfer(item)
                    } 
                }}>
                    {item.refundPending ?"Transfer":""}
                </div>

            </div>  
        )
    }
    return(
        <div style={{
            marginTop:50
        }}>
            <div style={{
                display:'flex',
                height:50,
                background:'var(--background-color)',
                marginBottom:10,
                borderRadius:8,
                alignItems:'center',
                fontSize:14,
                padding:'10px 15px',
                color:'var(--text-light-color)'
            }}>
                 <div style ={{
                    width:40,
                }} className="flex flex-v-center flex-h-center">
                    No
                </div>
                <div style={{
                    flex:2
                }} className="single-line">
                    Name
                </div>

                <div style={{flex:1,textAlign:'center'}}>
                   Planned  Qty
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                    Acquired Qty
                </div>
                <div style={{flex:1,textAlign:'center'}}>
                   Used Qty
                </div>
                <div style={{
                    width:220,
                    fontSize:14,
                    textAlign:'center',
                    cursor:'pointer'
                }} >

                </div>
            </div>
            {details.taskMappings.map(mapItem)}

            {
                transfer ?
                <Transfer
                     projectId={projectId}
                     inventoryId={details.inventoryId}
                     details={transfer}
                     onClose={()=>{
                         setTransfer(false);
                     }}
                     init={()=>{
                         setTransfer(false);
                         init();
                     }}
                />
                : null
                
            }

            {
                show ?
                <Modal
                    title="Update Quantity"
                    desc="Enter the quantity"
                    onClose={()=>{
                        setShow(false);
                    }}
                    show={show}

                >
                    <FunctionalInput title="Quantity" type="text" ref={QTY} style={{marginTop:50}}  />
                    <Button text="Update" style={{marginTop:50}} onClick={()=>{
                        let qty = QTY.current.value;
                        if(!qty) {
                            showToast({
                                type:'error',
                                message:'Please enter the qty'
                            })
                            return;
                        }
                        qty = +qty;
                        post(`project/inventory/confirmPendingReturn`,{
                            qty,
                            projectId:+projectId,
                            inventoryId:details.inventoryId,
                            resourceMappingId:show.resourceMappingId
                        },(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"Return confirm successfully"
                                })
                                setShow(false);
                                init();
                            } else {
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                    }} />
                </Modal>
                : null
            }
        </div>
    )
}
export default Index;