import { useEffect, useRef, useState } from "react";
import { get,axios, post } from "../../../../Model/Network/Config/Axios";
import { Button } from "../../../Common/Button";
import { SimpleFlex } from "../../../Common/Flex/SimpleFlex";
import { FunctionalInput } from "../../../Common/FloatInput/FunctionalInput";
import { KeyValue } from "../../../Common/KeyValue";
import { Modal } from "../../../Common/Modal/Modal";
import { RightModal } from "../../../Common/Modal/RightModal";
import { PageLoader } from "../../../Common/PageLoader";
import { SearchDrawingCategory } from "../../../Common/Search/SearchDrawingCategory";
import { showToast } from "../../../Common/Toast";
import MapDrawing from './MapDrawing';

const Index = (props) => {

    let [loading,setLoading] = useState(true);
    let [drawingTemplates,setDrawingTemplates] = useState([]);
    let [deleteModal,setDeleteModal] = useState(false);
    let [buttonLoading,setButtonLoading] = useState(false);
    let [add,setAdd] = useState(false);
    let [edit,setEdit] = useState(false);
    let LAGDAY = useRef(),CATEGORY=useRef();

    useEffect(()=>{
        INIT()
    },[])

    useEffect(()=>{
        if(edit) {
            LAGDAY.current.value = edit.lagDays;
            CATEGORY.current.setValue({
                name:edit.drawingCategoryName,
                id:edit.drawingCategoryId
            })
            CATEGORY.current.setRef(edit.drawingCategoryName)
        }
    },[edit])

    let INIT = () => {
        get(`project/task/drawing/drawingTemplates/${props.item.id}`,(e,r)=>{
            if(r) {
                setDrawingTemplates(r.drawingTemplates);
                setLoading(false);
            }
        })
    }


    let mapDrawing = (item,index) => {
        return(
            <div style ={{
                width:'33%',
                padding:5,
                marginTop:30,
                position:'relative'
            }} key={index}>
              <div style={{
                   border:'1px solid var(--border-color)',
                   padding:20,
                   borderRadius:8,
                   marginRight:10
              }}>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--border-color)',
                    padding:'3px 8px',
                    left:20,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600',
                    color:'var(--sidebar-background)'

                }}>
                    Drawing {index+1}
                </div>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--button-color)',
                    padding:'3px 12px',
                    right:90,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600',
                    cursor:'pointer'
                }} onClick={()=>{
                    setEdit(item)
                }}>
                    Edit
                </div>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--red-color)',
                    padding:'3px 12px',
                    right:20,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600',
                    cursor:'pointer'
                }} onClick={()=>{
                    setDeleteModal(item)
                }}>
                    Delete
                </div>

                <SimpleFlex>
                    <KeyValue title="Category" desc={item.drawingCategory} style={{flex:1}} />
                    <KeyValue title="Description" desc={item.drawingCategoryDesc} style={{flex:1}} />
                </SimpleFlex>
                <SimpleFlex>
                    <KeyValue title="Task Name" desc={item.taskName} style={{flex:1}} />
                    <KeyValue title="Task Type" desc={item.taskType} style={{flex:1}} />
                </SimpleFlex>
                <SimpleFlex>
                    <KeyValue title="Lag Day" desc={item.lagDays} style={{flex:1}} />

                </SimpleFlex>
               
                </div>
            </div>
        )
    }



    return(
    <>
       
           {
               loading ?
               <PageLoader style={{marginTop:50}} />
               : 
               <>
               <div>
                  <div style={{display:'flex',justifyContent:'flex-end'}}>
                   <div style={{
                    height:40,
                    width:40,
                    border:'2px dashed var(--active-color)',
                    borderRadius:'50%',
                    color:'var(--active-color)',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    fontSize:40,
                    paddingBottom:10,
                    cursor:'pointer'
                    }} onClick={()=>{
                         setAdd(true)
                     }}>
                         +
                    </div>
                   </div>
                   <div style={{
                       display:'flex',
                       flexWrap:'wrap'
                   }}>
                       {drawingTemplates.map(mapDrawing)}
                   </div>
               </div>
               </>
           }
        {
            add ?
            <MapDrawing 
                init={()=>{
                    setAdd(false);
                    INIT()
                }}
                onClose={()=>{
                    setAdd(false);
                }}
                id={props.item.id}
            />
            : null
        }

            {
                deleteModal?
                <Modal
                    title="Remove Mapping of Drawing"
                    desc={deleteModal.drawingCategoryName}
                    show={true}
                    onClose={()=>{
                        setDeleteModal(false)
                    }}
                    style={{
                        minHeight:240
                    }}
                >
                    <p style={{
                        fontSize:13,
                        color:'var(--text-light-color)',
                        marginTop:40
                    }}>Are you sure want to remove mapping of {deleteModal.drawingCategoryName} ?</p>
                    <Button loading={buttonLoading} text="Remove" style={{marginTop:50}} onClick={()=>{
                        setButtonLoading(true);
                         axios.delete(`project/task/drawing/removeDrawingTemplate/${deleteModal.resourceTemplateId}`, {
                            data: {
                            }
                        })
                            .then(res => {
                                setDeleteModal(false);
                                setButtonLoading(false);
                                INIT();
                                showToast({
                                    type: 'success',
                                    message: "Drawing un-tag successfully"
                                })
                            })
                    }} />
                </Modal>
                : null
            }

            {
                edit ?
                <RightModal
                    title={`Update ${edit.drawingCategoryName}`}
                    onClose={()=>{
                        setEdit(false);
                    }}
                    show={true}

                >
                    <SearchDrawingCategory title="Drawing Category" handleRef={ref=>CATEGORY.current=ref} />
                    <FunctionalInput title="Lag Day" type="number" ref={LAGDAY} />
                    <Button loading={buttonLoading} text="Update" style={{marginTop:30}} onClick={()=>{
                        let lagDays = LAGDAY.current.value;
                        let drawingTemplateId = CATEGORY.current.getValue()?CATEGORY.current.getValue().id:null;

                        if(!drawingTemplateId) {
                            showToast({
                                type:'error',
                                message:"Please selec the drawing category"
                            })
                            return
                        }

                        if(!lagDays) {
                            showToast({
                                type:'error',
                                message:"Please enter the lagday"
                            })
                            return;
                        }

                        

                        let data = {
                            lagDays:+lagDays,
                            drawingTemplateId
                        }

                        setButtonLoading(true);
                        post(`project/task/drawing/updateDrawingTemplate`,data,(e,r)=>{
                            if(r) {
                                INIT();
                                setEdit(false);
                                showToast({
                                    type:'success',
                                    message:"Drawing updated successfully "
                                })
                                setButtonLoading(false);
                            } else {
                                setButtonLoading(false);
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                    }} />
                </RightModal>
                :
                null
            }
    </>
    )
}

export default Index;