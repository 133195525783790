import { useEffect, useRef } from "react";
import { useParams} from 'react-router-dom';
import { post } from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { EmployeeSearch } from "../../../../Common/Search/SearchEmployee";
import { showToast } from "../../../../Common/Toast";
import { MainModal } from "../../../../NCommon/Modal"



const Index = ({item,open,close,complete}) => {

    let EMPLOYEE = useRef();
    let {id} = useParams();

    useEffect(()=>{
        if(item) {
            EMPLOYEE.current.setName(item.employee.name);
            EMPLOYEE.current.setValue(item.employee)
        }
    },[item])

    let update = () => {
        let employee = EMPLOYEE.current.getValue();
        if(!employee) {
            showToast({
                type:'error',
                message:'Please enter the employee'
            })
            return;
        }

        post(`project/hindrance/updateHindrance/${item.id}/${id}`,{empId:employee.id},(e,r)=>{
            if(r) {
                showToast({
                    type:"success",
                    message:"Update done successfully",
                })
                complete();
            }
        })
    }

    return(
        <MainModal
            title={`Update ${item.hindrance?item.hindrance.title:'-'}`}
            open={open}
            close={close}
        >
            <EmployeeSearch title="Employee" handleRef={ref=>EMPLOYEE.current=ref} />

            <Button style={{marginTop:20}} text="Update" onClick={update}>
                Update
            </Button>
        </MainModal>
    )
}

export default Index;