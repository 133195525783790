import Zip from 'jszip'
import Utils from 'jszip-utils'
import Saver from 'file-saver';

const DownloadZip = (lineItem,cb) => {

	let zip = new Zip();
	let count = 0;
	let files = lineItem;
	let zipFilename = "wbs.zip";



	files.forEach(item => {
		Utils.getBinaryContent(item.path, function (err, data) {
			if (err) {
				zip.file(`${item.name}.png`, data, { binary: true });
				count++;
				if (count === files.length) {
					zip.generateAsync({ type: "blob" })
						.then(function (content) {
							Saver.saveAs(content, zipFilename);
						});
				}
			}
			else {
				zip.file(`${item.name}.png`, data, { binary: true });
				count++;
				if (count === files.length) {
					zip.generateAsync({ type: "blob" })
						.then(function (content) {
							Saver.saveAs(content, zipFilename);
                            cb();
						});
				}
			}


		});

	})
}

export { DownloadZip }
