import React from 'react';
import Props from 'prop-types';
import './index.css';

class Input extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }

    getValue = () => {
        return this.state.value;
    }

    setValue = (value) => {
        this.setState({
            ...this.state,
            value
        })
    }


    render() {
        return (
            <div style={{
                marginBottom: 25,
                width: '100%'
            }}>
                <p style={{
                    fontSize: 12,
                    fontWeight: 600,
                    marginBottom: 10,
                    color: 'var(--text-color)',
                    letterSpacing: 0.7
                }}>{this.props.title}:</p>
                <div style={{
                    height: 50,
                    backgroundColor: "#fff",
                    border: '1px solid var(--border-color)',
                    boxShadow: '0px 3px 2px -3px rgba(0,0,0,0.15)',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px 10px',
                    boxSizing:'border-box',
                    borderRadius:4,
                }} className="unique">
                    <input
                        type={this.props.type}
                        style={{
                            backgroundColor: "#fff",
                            fontSize: 16,
                            border: 'none',
                            width: '93%'

                        }}
                        placeholder={this.props.placeholder ? this.props.placeholder : this.props.title}
                        value={this.state.value}
                        onChange={(e) => {
                            this.setState({
                                ...this.state,
                                value: e.target.value
                            })
                        }}
                        autoComplete="off"

                    />


                </div>

            </div>
        )
    }
}

Input.propsType = {
    title: Props.string.isRequired,
    type: Props.string

}

Input.defaultProps = {
    title: "Enter Text",
    type: 'text'
}

export default Input;