import { useRef, useState } from "react";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { SearchUnit } from "../../../../Common/Search/FetchUnit";
import {Button} from '../../../../Common/Button/index'
import { showToast } from "../../../../Common/Toast";
import { post } from "../../../../../Model/Network/Config/Axios";
import { AddRoles } from "../../../../Common/Roles";
import { ProjectCustomTag } from "../../../../Common/CustomTagging/Project";
import { SearchTaskN } from "../../../../Common/Search/SearchTaskN";
import { useDictionaryValue } from "../../../../Hooks/DictionaryHooks";
import { SearchSelect } from "../../../../Common/Search/SearchSelect/SearchSelect";
import { MainModal } from "../../../../NCommon/Modal";



const Add = (props) => {

    let NAME = useRef(),DESCRIPTION=useRef(),HSN=useRef(),SKU=useRef(),PRICE=useRef(),UNIT=useRef(),GST=useRef(),CODE=useRef(),PRPOLEADTIME=useRef(),POGRNLEADTIME=useRef();
    let Custom = useRef(),WASTERATIO=useRef(),AGE=useRef(),TASK=useRef(),MAJOR=useRef(),BUFFERLEADTIME=useRef();
    const [role,setRole] = useState(undefined);
    let getDictionaryValue = useDictionaryValue()

    let submit = () => {
        let name = NAME.current.value;
        let description = DESCRIPTION.current.value;
        let hsn = HSN.current.value;
        let sku = SKU.current.value;
        let price = PRICE.current.value;
        let code = CODE.current.value;
        let gstRate = +GST.current.value;
        let units = UNIT.current.getValue();
        let prPoLeadTime = PRPOLEADTIME.current.value;
        let poGrnLeadTime = POGRNLEADTIME.current.value;
        let wastageRatio = WASTERATIO.current.value;
        let major = MAJOR.current.getValue();
        let bufferLeadTime = +BUFFERLEADTIME.current.value;

        if(!name) {
            showToast({
                type:'error',
                message:'Please enter the name'
            })
            return
        }
        if(!description) {
            showToast({
                type:'error',
                message:'Please enter the description'
            })
            return
        }
        if(!price) {
            showToast({
                type:'error',
                message:'Please enter the price'
            })
            return
        }
        if(!units) {
            showToast({
                type:'error',
                message:'Please enter the units'
            })
            return
        }
        if(!wastageRatio) {
            showToast({
                type:'error',
                message:"Please enter the wastage ratio"
            })
            return;
        }

        if(!bufferLeadTime) {
            showToast({
                type:'error',
                message:"Please enter the buffer lead time"
            })
            return;
        }

        if(!major) {
            showToast({
                type:'error',
                message:"Please select the major material"
            })
            return;
        }

        

        

        Custom.current.getValue(cb=>{
            
            let data = {
                name,description,hsn,sku,price:+price,code,gstRate,units,resourceCategoryId:+props.id,poGrnLeadTime:+poGrnLeadTime,prPoLeadTime:+prPoLeadTime,
                roleId:role?role:null,resourceId:+props.id,
                wastageRatio:+wastageRatio,
                bufferLeadTime,
                major:MAJOR.current.getValue().value==='True'?true:false
            }
            if(cb && cb.length) {
                data.levelItemId = +cb[cb.length-1].levelId
            }



            if(wastageRatio) {
                data.wastageRatio = +wastageRatio
            }

            if(props.subType === 'SHUTTER') {
                let age  = +AGE.current.value;
                if(age) {
                   data.age=age;
                }
                let taskId = TASK.current.getValue();
                if(taskId) {
                    data.taskId = taskId.id;
                }
            }
    
            post(`resource/addResource`,data,(e,r)=>{
                if(r) {
                    showToast({
                        type:'success',
                        message:"Resource subcategroy added successfully"
                    })
                    props.init();
                } else {
                    showToast({
                        type:'error',
                        message:e.data.message
                    })
                }
            })
        })
 
       
       
    }


    return(
        <MainModal
            title="Add Resource"
            desc="Fill up the details and add resource"
            close={props.onClose}
            open={props.open}
        >
            <ProjectCustomTag type="RESOURCE" handleRef={ref=>Custom.current=ref}  />
            <FunctionalInput ref={NAME} title="Name" />
            <FunctionalInput ref={DESCRIPTION} title="Description" />
            <FunctionalInput ref={HSN} title="Hsn (optional)" />
            <FunctionalInput ref={SKU} title="Sku (optional)" />
            <FunctionalInput ref={PRICE} title="Price"  />
            <SearchUnit title="Unit" handleRef={ref=>UNIT.current=ref} />
            <FunctionalInput ref={GST} title="Gst Percentage (optional)" type="number" />
            <FunctionalInput ref={CODE} title="Material Code (optional)" type="text" />
            <FunctionalInput ref={PRPOLEADTIME} title="Pr to po lead time (optional)" type="text" />
            <FunctionalInput ref={POGRNLEADTIME} title="Po to grn lead time (optional)" type="text" />
            <FunctionalInput ref={BUFFERLEADTIME} title="Buffer Lead time" type='number' />
            <FunctionalInput ref={WASTERATIO} title="Wastage Ratio" type="number" />
            <SearchSelect handleRef={ref=>MAJOR.current=ref} title="Is Major Material ?" items={[{key:true,value:'True'},{key:false,value:'False'}]} />
            { props.type==='LABOUR' && props.subType==='SKILLED'?
            <AddRoles placeholder="Search role in the company directory" title="Role" onSelect={setRole}/>
            : null
            }
            {
                props.subType ==='SHUTTER' ?
                <>
                    <FunctionalInput ref={AGE} title="Age(optional)" type="number"  />
                    <SearchTaskN
                        title={`Search ${getDictionaryValue('Task')}(optional)`}
                        handleRef={ref=>TASK.current=ref} 
                        type="REGULAR"
                />
                </>
                :null
            }

            <Button text="Add" style={{marginTop:30}} onClick={submit} />


        </MainModal>
    )
}

export default Add;