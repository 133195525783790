import React from 'react';
import { Button } from '../../../Common/Button';


/* Common */
import { PageLoader } from '../../../Common/PageLoader';
import { BottomHeader } from '../../../Common/TopHeader/BottomHeader';
import { TopHeader } from '../../../Common/TopHeader/TopHeader';




class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }


    componentDidMount() {
        setTimeout(() => {
            this.setState({
                ...this.state,
                loading: false
            })
        }, 1000)
    }


    render() {

        return (
            <div style={{
                flex: 1,
            }}>
                <TopHeader
                    title="Policy Program"
                    desc="Create your policy program"
                />

                {
                    this.state.loading ?
                        <PageLoader />
                        :
                        <div style={{
                            position: 'relative'
                        }}>
                            <BottomHeader>
                                <div style={{
                                    position: 'absolute',
                                    top: 20,
                                    right: 20
                                }}>
                                    <Button
                                        text="Create Policy"
                                    />
                                </div>
                            </BottomHeader>
                            <div className="container">
                            </div>
                        </div>
                }


            </div>
        )
    }
}

export default Index;