import { useState } from "react";
import {useParams} from 'react-router-dom'
import axios from 'axios';
import { RightModal } from "../../../../../Common/Modal/RightModal";
import { handleError,showToast } from "../../../../../Common/Toast";




const TaskWithChanges = (props) => {

    const [items,setItems] = useState(props.items);
    let {id} = useParams();

    let mapItem = (item,index) => {
        return(
            <div style ={{
                border:'1px solid var(--border-color)',
                minHeight:45,
                flex:1,
                display:'flex',
                alignItems:'center',
                fontSize:14,
                boxSizing:"border-box"
            }} key={index}>
                <div style={{flex:1,boxSizing:'content-box',borderRight:'1px solid var(--border-color)',minHeight:45,display:'flex',alignItems:'center'}}>
                    <p style={{paddingLeft:10}}>{item.task.croppedName}</p> 
                    <span style ={{color:'red',marginLeft:10,cursor:"pointer"}} onClick={()=>{
                         axios.delete(`/project/task/taskMapping.delete/${item.task.id}?reason=${'NA'}&projectId=${id}`, {
                            data: {
                                reason: 'NA'
                            }
                        })
                            .then(res => {
                                let nItems = items;
                                nItems = nItems.filter(val=>val.task.id !== item.task.id);
                                setItems(nItems);
                                showToast({
                                    type: 'success',
                                    message: "Task delete successfully"
                                })
                                props.onDelete()
                            })
                            .catch(e=>{
                                handleError(e);
                            })
                    }}>Delete</span>
                </div>
                <div style={{flex:1,minHeight:45,borderLeft:'1px solid var(--border-color)'}}>
                    {item.successorTasks.map((val,index)=>{
                        return(
                            <div key={index} style={{borderBottom:"1px solid var(--border-color)",height:45,display:'flex',alignItems:'center'}}>
                                <a target="_blank" href={`/app/project/task/${id}/${val.id}/details`} style={{paddingLeft:10,textDecoration:'none',color:'var(--active-color)',cursor:'pointer'}}>{val.croppedName}</a>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return(
        <RightModal
            title="Task Not Deleted"
            desc="Task not deleted due to successors"
            onClose={props.onClose}
            show={true}
        >   

            <div style ={{
                height:40,
                backgroundColor:'var(--background-color)',
                marginTop:20,
                border:'1px solid var(--border-color)',
                display:'flex',
                alignItems:'center',
                flex:1
            }}>
                <div style={{flex:1,borderRight:'1px solid var(--border-color)',display:'flex',alignItems:'center',justifyContent:'center',height:45,fontSize:16,fontWeight:600}}>Task Name</div>
                <div style={{flex:1,display:'flex',alignItems:'center',justifyContent:'center',height:45,fontSize:16,fontWeight:600}}>Successor Name</div>
            </div>

            <div>
                {items.map(mapItem)}
            </div>

            <p style ={{
                fontSize:12,
                color:'var(--text-light-color)',
                marginTop:10
            }}>** Please change the predecessor first then delete the current task</p>

        </RightModal>
    )
}

export default TaskWithChanges;