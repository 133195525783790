import { useState } from "react";
import close from "./close.png";
import left from "./left.png";

const ImageModal = ({ items, showDetails, ...props }) => {
  const [index, setIndex] = useState(props.index);

  let showDetail = () => {
    return (
      <>
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.6)",
            fontSize: 12,
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            opacity: 0.9,
            fontWeight: 700,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            zIndex: 1,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {items[index].taskName}
        </div>

        <div
          style={{
            position: "absolute",
            top: 50,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.6)",
            fontSize: 12,
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            opacity: 0.9,
            fontWeight: 700,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          Submitted By : {items[index].employeeName}
        </div>

        <div
          style={{
            position: "absolute",
            top: 90,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.6)",
            fontSize: 12,
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            opacity: 0.9,
            fontWeight: 700,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          Date : {items[index].date}
        </div>
        <div
          style={{
            position: "absolute",
            top: 130,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.6)",
            fontSize: 12,
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
            padding: "10px 20px",
            opacity: 0.9,
            fontWeight: 700,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          Comment : {items[index].remarks}
        </div>
      </>
    );
  };

  return (
    <div
      onClick={props.close}
      style={{
        position: "fixed",
        right: 0,
        top: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.9)",
        zIndex: 100,
      }}
    >
      {index !== 0 ? (
        <div
          style={{
            position: "absolute",
            left: 20,
            display: "flex",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div
            style={{
              height: 50,
              width: 50,
              backgroundColor: "rgba(255,255,255,0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIndex(index - 1);
            }}
          >
            <img
              src={left}
              alt=""
              style={{
                height: 30,
                width: 30,
              }}
            />
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            width: "80%",
            // backgroundColor: "#fff",
            height: "75vh",
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              height: 40,
              width: 40,
              backgroundColor: "rgba(255,255,255,0.5)",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={props.close}
          >
            <img
              src={close}
              alt=""
              style={{
                height: 15,
                width: 15,
              }}
            />
          </div>
          <img
            src={items[index].url}
            style={{
              height: "100%",
              // width: "100%",
              fontSize: "14px",
              borderRadius: 4,
            }}
            onClick={(e) => e.stopPropagation()}
          />
          {showDetails ? showDetail() : null}
        </div>

        {items.length !== index + 1 ? (
          <div
            style={{
              color: "#fff",
              position: "absolute",
              right: 20,
            }}
          >
            <div
              style={{
                height: 50,
                width: 50,
                backgroundColor: "rgba(255,255,255,0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (items.length >= index + 1) setIndex(index + 1);
              }}
            >
              <img
                src={left}
                alt=""
                style={{
                  height: 30,
                  width: 30,
                  transform: "rotate(180deg)",
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

ImageModal.defaultProps = {
  showDetails: false,
};

export { ImageModal };
