import Moment from 'moment';
import { KeyValue } from '../../../Common/KeyValue';
import { RightModal } from "../../../Common/Modal/RightModal"
import {Button} from '../../../Common/Button';
import { deleteApi } from '../../../../Model/Network/Config/Axios';
import { showToast } from '../../../Common/Toast';


const ViewEvent = ({details,id,close,init}) => {


    return(
        <RightModal
            show={true}
            title={`${Moment(details._instance.range.start).format('DD-MM-YYYY')} Holiday`}
            onClose={close}
        >
            <KeyValue title="Title" desc={details._def.title}  />
            <KeyValue title="Description" desc={details._def.extendedProps.description} />
            <Button onClick={()=>{
                deleteApi(`project/holiday/removeHoliday/${id}/${details._def.publicId}`,(e,r)=>{
                    if(r) {
                        showToast({
                            type:'success',
                            message:'Holiday remove successfully'
                        })
                        init();
                    }
                })
            }} text="Remove Holiday" style={{marginTop:25,backgroundColor:'var(--red-color)',borderColor:'var(--red-color)'}} />

        </RightModal>
    )
}

export default ViewEvent;