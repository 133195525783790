import  { useEffect, useRef, useState } from 'react';
import { get, post } from '../../../../../../Model/Network/Config/Axios';
import {Button} from '../../../../../Common/Button';
import Option from './Options';
import { showToast } from '../../../../../Common/Toast';
import { FunctionalInput } from '../../../../../Common/FloatInput/FunctionalInput';
import {ToggleButton} from '../../../../../Common/Toggle/index'
import { validateEmail } from '../../../../../../Utils/ValidateEmail';
import { MainModal } from '../../../../../NCommon/Modal';

const AddUser = (props) => {

    const [projects,setProjects] = useState([]);
    const [module,setModules] = useState([]);
    const [write,setWrite] = useState(false);
    const [superUser,setSuperUser] = useState(false);


    let Name = useRef(),Email =useRef(),Mobile=useRef();

    useEffect(()=>{
        get(`setting/accessManagement/modules`,(e,r)=>{
            if(r) {
                setModules(r.modules.filter(val=>val!=='NONE').map(val=>{
                    return {
                        ...val,
                        name:val,
                        key:val,
                        active:false
                    }
                }))
            }
        })
        get(`project/all`,(e,r)=>{
            if(r) {
                setProjects(r.projects.map(val=>{
                    return{
                        ...val,
                        key:val.projectId,
                        active:false
                    }
                }))
            }
        })

    },[])


 let addUser = () => {

    let name = Name.current.value;
    let email = Email.current.value;
    let mobile = Mobile.current.value;
    let roles = module.filter(val=>val.active).map(val=>val.name)
    let project = projects.filter(val=>val.active).map(val=>val.key);

    if(!name) {
        showToast({
            type:'error',
            message:"Please enter the name"
        })
        return;
    }

    if(!email || !validateEmail(email)) {
        showToast({
            type:'error',
            message:"Please enter the valid email address"
        })
        return;
    }



    if(!mobile) {
        showToast({
            type:'error',
            message:"Please enter the valid mobile number"
        })
        return
    }

    if(mobile.length!==10) {
        showToast({
            type:'error',
            message:"Please enter the  valid mobile number"
        })
        return;
    }

    if(roles.length  === 0) {
        showToast({
            type:'error',
            message:"Select atleast one module"
        })
        return
    }

    if(project.length === 0) {
        showToast({
            type:'error',
            message:"Select atleast one organisation"
        })
        return;
    }

    let data = {
        email,
        name,
        mobile,
        roles,
        projects:project,
        write,
        writeModules:roles
    }

    post(`setting/accessManagement/create`,data,(e,r)=>{
        if(r) {
            showToast({
                type:'success',
                message:"User added successfully"
            })
            props.init();
        }
        if(e) {
            showToast({
                type:'error',
                message:e.data.message
            })
        }
    })

 }

    

   

    return(
        <MainModal 
            open={props.open}
            title="Add User"
            desc="Fill up the details and add user"
            close={()=>props.onClose(false)}
            >
                <FunctionalInput title="Name" type="text" ref={Name} />
                <FunctionalInput type="text" title="Email" ref={Email} />
                <FunctionalInput type="number" title="Mobile" ref={Mobile} />
                <Option title="Select Project" desc="Select project to give access" options={projects} onChange={setProjects}  />
                <Option title="Select Module" desc="Select Module to give access" options={module} onChange={setModules}  />
                <ToggleButton title="Read/write Access" text="Do you want to enable write access" active={write} onChange={setWrite} />
                <ToggleButton title="Super User" text="Do you want to make this user as super" active={superUser} onChange={setSuperUser} />
                <Button text="Add User" onClick={addUser} style={{marginTop:20}} /> 
        </MainModal>
    )

}

export default AddUser;