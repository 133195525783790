import Props from 'prop-types';
import { NameLayout } from './NameLayout';
import './card.css';
import { TableMenu } from '../Tables/TableMenu';


const Card = (props) => {

    let width = 100/props.layout;

    let height = props.height?props.height:150;

    if(props.vertical === 'M') {
        height=160
    }

    return(
        <div style={{width:`${width}%`,position:'relative'}} className="card">
            <div onClick={()=>{
                if(props.onClick) {
                    props.onClick();
                } 
            }} className="box-wrapper-shadow" style={{background:'var(--white-color)',display:'flex',flexDirection:'column',border:'1px solid var(--border-color)',cursor:'pointer',height,borderRadius:12,width:'calc(100% - 16px)',margin:'12px 5px',padding:12}}>
                {
                    props.badge ?
                    <div style={{
                        background: props.badgeColor?props.badgeColor:'var(--border-color)',
                        position:'absolute',
                        zIndex:1,
                        top:-8,
                        borderTopLeftRadius:4,
                        borderTopRightRadius:4,
                        minWidth:50,
                        fontSize:12,
                        textAlign:'center',
                        padding:2,
                        paddingLeft:4,
                        paddingRight:4,
                        color:'#fff',
                        fontWeight:'bold'
                    }}>
                        {props.badge}
                    </div>
                    :null
                }
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        {props.icon?<NameLayout title={props.title} />:null}
                        <div style={{flex:1}}>
                            <p style={{fontWeight:600,fontSize:15}}>{props.title}</p>
                            <p  style={{fontWeight:'400',fontSize:12,color:'var(--text-light-color)'}}>{props.desc}</p>
                        </div>
                    </div>
                    {
                        props.items ?
                        <TableMenu items={props.items} />
                        :null
                    }
                </div>
                {props.children}
            </div>
        </div>
    )
}


Card.defaultProps = {
    layout:4,
    vertical:'S',
    icon:false,
    onClick:()=>{}
}

Card.propsTypes={
    layout:Props.any,
    title:'AA',
    icon:Props.bool
}


export {Card};