import { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import {  post } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';


const Index = () => {

    let {id} = useParams();
    const [data,setData] = useState({});
    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        INIT();
    },[])

    let INIT = () => {
        post(`project/task/taskAnalytics/${id}`,{type:"TASK"},(e,r)=>{
            if(r) {
                setData(r.tasks);
                setLoading(false);
            }
        })
    }

    let mapItem = (item,index) => {
        let sItem = data[item];
        if(sItem.avgActualCompletionDays-sItem.avgPlannedCompletionDays>0 && (sItem.avgPlannedCompletionDays*6>sItem.avgActualCompletionDays)) {
            return(
                <div key={index} style={{width:'100%',fontSize:14,display:'flex',borderBottom:'1px solid var(--border-color)',minHeight:45,paddingTop:5,paddingBottom:5,alignItems:'center'}}>
                    {singleItem(item)}
                    {singleItem(`${sItem.avgPlannedCompletionDays} days`)}
                    {singleItem(`${sItem.avgActualCompletionDays} days`)}
                </div>
            )
        }
       return(
        <div key={index}></div>
       )
    }


    let mapItem1 = (item,index) => {
        let sItem = data[item];
        if(sItem.avgPlannedCompletionDays-sItem.avgActualCompletionDays>0 && sItem.avgActualCompletionDays!==0) {
            return(
                <div key={index} style={{width:'100%',fontSize:14,display:'flex',borderBottom:'1px solid var(--border-color)',minHeight:45,paddingTop:5,paddingBottom:5,alignItems:'center'}}>
                    {singleItem(item)}
                    {singleItem(`${sItem.avgPlannedCompletionDays} days`)}
                    {singleItem(`${sItem.avgActualCompletionDays} days`)}
                </div>
            )
        }
       return(
        <div key={index}></div>
       )
    }

   

    let mapItem3 = (item,index) => {
        let sItem = data[item];
        if(sItem.expectedCompletedButNotDone>0 || sItem.expectedStartedButNotStarted>0) {
            return(
            <div key={index} style={{width:'100%',fontSize:14,display:'flex',borderBottom:'1px solid var(--border-color)',minHeight:45,paddingTop:5,paddingBottom:5,alignItems:'center'}}>
                {singleItem(item)}
                {singleItem(`${sItem.expectedCompletedButNotDone} task`)}
                {singleItem(`${sItem.expectedStartedButNotStarted} task`)}
            </div>
            )
        }
       
        return <div key={index} />
    }

    let singleItem = (item,style) => {
        return(
            <div style={{flex:1 ,...style}}>
                {item}
            </div>
        )
    }


    let header = () => {
        return(
            <div style={{height:45,display:'flex',alignItems:'center',fontWeight:'600',flex:1,borderBottom:'1px solid var(--border-color)'}}>
                {singleItem('Item')}
                {singleItem('Planned Completion Days')}
                {singleItem('Actual Completion Day')}
            </div>
        )
    }


    if(loading) {
        return <PageLoader />
    }



    return(
        <>
            <div style={{display:'flex'}}>
                <div style={{flex:1}}>
                    <p style={{fontWeight:600}}>What went Well</p>
                    <div className='title-container-1' style={{width:'100%'}}>
                        {header()}
                        <div style={{height:500,overflowY:'auto'}}>
                            {Object.keys(data).map(mapItem1)}
                        </div>
                    </div>
                </div>
                <div style={{marginLeft:20,flex:1}}>
                    <p style={{fontWeight:600}}>What did not go Well</p>
                     <div className='title-container-1' style={{width:'100%'}}>
                        {header()}
                        <div style={{height:500,overflowY:'auto'}}>
                            {Object.keys(data).map(mapItem)}
                        </div>
                    </div>
                </div>
            </div>
            
           
              
        </>
    )
}

export default Index;