import { useEffect, useState } from "react";
import { get } from "../../../../../Model/Network/Config/Axios";
import { LineHeader } from "../../../../Common/LineHeader";
import { CenterModal } from "../../../../Common/Modal/CenterModal"
import { PageLoader } from "../../../../Common/PageLoader";
import Details from './Details';
import Mapping from './Mapping';
import Ledger from './Ledger';

const Index = (props) => {

    const [loading,setLoading] = useState(true);
    const [details,setDetails] = useState({});
    const [key,setKey] = useState('details');

    useEffect(()=>{
        INIT();
    },[])

    let INIT = () => {
        get(`project/inventory/fetchInventoryDetails/${props.projectId}/${props.inventory.inventoryId}`,(e,r)=>{
            if(r) {
                setDetails(r);
                setLoading(false);
            }
        })
    }

    console.log(details);
    return(
        <>
            <CenterModal
                onClose={props.onClose}
                header={props.inventory.name}
            >
                {
                    loading?
                    <PageLoader style={{marginTop:50}} />
                    : 
                    <div style={{marginTop:60,padding:25,flex:1}}>
                        <LineHeader
                            items={[
                                {
                                    name:'Details',
                                    active:key==='details',
                                    onClick:()=>{
                                        setKey('details')
                                    }
                                },
                                {
                                    name:'Task Mapping',
                                    active: key==='task',
                                    onClick:()=>{setKey('task')}
                                },
                                {
                                    name:'Ledger',
                                    active: key==='ledger',
                                    onClick:()=>{setKey('ledger')}
                                }
                            ]}
                        />
                        {key === 'details' ? <Details details={details} />:null}
                        {key === 'task' ? <Mapping projectId={props.projectId} init={()=>{
                            INIT();
                            setKey('details');
                        }} details={details} />:null}
                        {key==='ledger' ? <Ledger projectId={props.projectId} inventoryId={props.inventory.inventoryId}  />:null}
                       
                    </div>  
                }
            </CenterModal>
        </>
    )
}

export default Index;