import  { useRef, useState } from 'react';
import { Button } from '../../../Common/Button';
import { CitySearch } from '../../../Common/CitySearch/View';
import { FindPlaces } from '../../../Common/FindPlaces';
import Moment from 'moment';
import { post } from '../../../../Model/Network/Config/Axios';
import { showToast } from '../../../Common/Toast';
import { FunctionalInput } from '../../../Common/FloatInput/FunctionalInput';
import { MixPanel } from '../../../Hooks/Mixpanel';
import { ToggleButton } from '../../../Common/Toggle';
import { HourList } from '../../../Common/DateRangePicker/HourList';
import { useSelector } from 'react-redux';


const AddProject = (props) => {

    const [city,setCity] = useState(undefined);
    const [addressNode,setAddressNode] = useState(undefined);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [days,setDays] = useState(undefined);
    const [count,setCount] = useState(0)
    const [hourBased,setHourBased] = useState(false);
    const [hourvalue,setHourValue] = useState(null);

    let isManagementDate = !useSelector(val=>(val.config.config.organization.managementDate))

    

    let Name=useRef(),Description=useRef(),Address=useRef(),StartDate=useRef(),EndDate=useRef(),Budget=useRef(),ManagementEndDate=useRef()

   let addProject = () => {

        let name = Name.current.value;
        let description = Description.current.value;
        let address = addressNode ? addressNode : Address.current.getValue();
        let startDate = Moment(StartDate.current.value).valueOf();
        let budgets = Budget.current.value;
        let clientEndDate = null;
        let managementEndDate = ManagementEndDate.current.value;

        if(EndDate) {
            clientEndDate = Moment(EndDate.current.value).valueOf();
        }
        if(managementEndDate) {
            managementEndDate = Moment(EndDate.current.value).subtract(managementEndDate,'days').valueOf();
        }

        if (!name) {
            showToast({
                type: 'error',
                message: 'Please enter the project name'
            })
            return;
        }
        if (!description) {
            showToast({
                type: 'error',
                message: 'Please enter the project description'
            })
            return;
        }

        if (!city) {
            showToast({
                type: 'error',
                message: 'Please enter the city name'
            })
            return
        }

        if (!address) {
            showToast({
                type: 'error',
                message: 'Please enter the address'
            })
            return
        }

        if (!startDate) {
            showToast({
                type: 'error',
                message: 'Please select the start date'
            })
            return
        }

        if(!clientEndDate) {
            showToast({
                type:'error',
                message:"Please enter the client end date"
            })
            return;
        }

        if(isManagementDate && !managementEndDate) {
            showToast({
                type:'error',
                message:"Please enter the management Buffer days"
            })
            return;
        }

      
        let lat = "23.324";
        let lng = "34.34";
        let country = "India"

        let data = {
            name,
            description,
            city,
            address,
            startDate,
            budget:!budgets?0: Number(budgets), lat: Number(lat), lng: Number(lng), country,
            criticalPathFactor: 0,
            clientEndDate,
            managementEndDate:managementEndDate?managementEndDate:null
        }

        data.hourBased=hourBased
        if(hourBased) {
            data.shiftHours = +hourvalue;
        }
        setButtonLoading(true);
        MixPanel.init(`Add Project`)
        post(`project/create`, data, (e, r) => {
            if (r) {
                showToast({
                    type:'success',
                    message:'Project Added Successfully'
                })
               props.onComplete();
            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.message
                })
            }
            setButtonLoading(false);
        })
    }


    return (
            <>
                <FunctionalInput title="Name" type="text" ref={Name}/>
                <FunctionalInput title="Description" type="text" ref={Description} />
                <CitySearch
                   onCitySelect={(item) => {
                        setCity(item.name);
                    }}
                 />         
                <FindPlaces
                    handleRef={ref=>Address.current=ref}
                    onCitySelect={(item) => {
                        if (item) {
                            setAddressNode(item);
                        } else {
                            setAddressNode(Address.current.getValue())
                        }

                    }}        
                />
                <FunctionalInput title="Start Date" type="date" ref={StartDate}  />
                <FunctionalInput onChange={()=>{setCount(count+1)}} title="Client End Date" type="date" ref={EndDate}  />
                <FunctionalInput onChange={val=>{setDays(val)}} desc={`${days && EndDate.current.value ? `Management End Date is ${Moment(EndDate.current.value).subtract('days',days).format('DD-MM-YYYY')}`:""}`} title={`${isManagementDate?`Management Buffer Day`:`Management Buffer Day (optional) `}`}type="number" ref={ManagementEndDate}  />
                <FunctionalInput title="Budget (optional)" type="text" ref={Budget} />
                <ToggleButton active={hourBased} onChange={setHourBased} title="Hour Based" text="Enable Hour Based Project "/>
                {
                    hourBased ?
                    <HourList select={val=>{
                        setHourValue(val)
                    }} />
                    : null
                }
                <Button
                    style={{
                        marginTop: 30,
                        height: 40
                    }}
                    loading={buttonLoading}
                    text="Add Project"
                    onClick={addProject}
                />
            </>
        )
    
}

export default AddProject;