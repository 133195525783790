import { useRef } from 'react';
import { useParams} from 'react-router-dom';
import Moment from 'moment';
import { Button } from '../../../../Common/Button';
import { FunctionalInput } from '../../../../Common/FloatInput/FunctionalInput';
import { RightModal } from "../../../../Common/Modal/RightModal"
import { SearchResourceCategory } from "../../../../Common/Search/SearchResourceCategory";
import { showToast } from '../../../../Common/Toast';
import { Attachment } from '../../../../Common/Attachment';
import { StoreSearch } from '../../../../NCommon/FunctionalSearch/Resource/StoreSearch';
import { post } from '../../../../../Model/Network/Config/Axios';
import { MainModal } from '../../../../NCommon/Modal';



const Index = (props) => {

    const {id} = useParams();

    let RESOURCE = useRef(),QUANTITY=useRef(),DETAILS=useRef(),DELIVERYDATE=useRef(),STORE=useRef(),ATTACHMENT=useRef();


    let generate = () => {
        let resource = RESOURCE.current.getValue();
        let storeId = STORE.current.getValue();
        let qty = +QUANTITY.current.value;
        let details = DETAILS.current.value;
        let deliveryDate = DELIVERYDATE.current.value;

        if(!resource) {
            showToast({
                type:'error',
                message:"Please select the resource"
            })
            return;
        }

        if(!qty) {
            showToast({
                type:'error',
                message:"Please enter the quantity"
            })
            return;
        }
        if(!details) {
            showToast({
                type:'error',
                message:"Please enter the details"
            })
            return
        }

        if(!deliveryDate) {
            showToast({
                type:'error',
                message:"Please enter the deliverydate"
            })
            return
        }

        let data = {
            qty,
            details,
            deliveryDate:Moment(Moment(deliveryDate, 'YYYY-MM-DD')).valueOf(),
            storeId:storeId.id,
            file:""
        }

        post(`project/inventory/procure/generateRequest/${id}/${resource.id}`,data,(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Request Generated Successfully"
                })
                props.INIT();
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })

    }

    return(
        <MainModal
            title="Generate Request"
            open={props.open}
            close={props.close}
        >
            <SearchResourceCategory ref={RESOURCE} title="Search Resource" />
            <StoreSearch id={id} ref={STORE} title="Search Store" />
            <FunctionalInput ref={QUANTITY} type="number" title="Quantity" />
            <FunctionalInput ref={DETAILS} title="Details" />
            <FunctionalInput type="date" ref={DELIVERYDATE} title="Delivery Date" />
            <Attachment handleRef={ref=>ATTACHMENT.current=ref} />
            <Button text="Generate Request" onClick={generate} style={{marginTop:20}} />
            
        </MainModal>
    )
}

export default Index;