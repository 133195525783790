import { KeyValue } from "../../Common/KeyValue"
import { Layout } from "../../Common/Layout"
import Moment from 'moment';
import Zoom from 'react-medium-image-zoom';

const Index = (props) => {

    let {approvalDetails} = props

    return(
        <>
        {approvalDetails.map((item, index) => {
            return (
            <div style={{
                marginBottom: 20
            }}>
                <Layout key={index} style={{
                    marginBottom: 20
                }}>
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}>
                        <div style={{
                            flex: 1
                        }}>
                            <KeyValue
                                title="Approver Name"
                                desc={item.approver.name}

                            />
                        </div>
                        <div style={{
                            flex: 1
                        }}>
                            <KeyValue
                                title="Status"
                                desc={item.status}

                            />
                        </div>
                        <div style={{
                            flex: 1
                        }}>
                            <KeyValue
                                title="Action Date"
                                desc={item.declinedAt ? Moment(item.declinedAt).format('DD-MM-YYYY hh:mm a') : item.approvedAt ? Moment(item.approvedAt).format('DD-MM-YYYY hh:mm a') : 'NA'}

                            />
                        </div>
                    </div>

                    <p style={{
                        marginBottom: 10,
                        fontWeight: 600,
                        color: 'var(--light-color)'
                    }}>Images</p>
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',

                    }}>
                        {item.markedImages.map((item, index) => {
                            return (
                                <div key={index} data-tip={item.remarks}>
                                    <Zoom>
                                        <img
                                            src={item.image}
                                            alt=""
                                            style={{
                                                height: 100,
                                                width: 100,
                                                borderRadius: 4,
                                                marginRight: 10
                                            }}
                                        />

                                    </Zoom>
                                </div>
                            )
                        })}
                    </div>

                </Layout>
            </div>

        )
    })}
        </>
    )
}

export default Index;