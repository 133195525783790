import { memo, useState } from "react";
import Moment from 'moment';
import { useParams} from  'react-router-dom';
import { BarGraph } from "../../../../Common/Graphs";
import Container from "../../../../Common/Graphs/Container";
import Gauge from "../../../../Common/Graphs/GaugeChart";
import SubType from './SubType/SeconLayoutView';


const Index = memo(({data,active,filter}) => {


    const [show,setShow] = useState(false);
    const {id} = useParams();
    const [SECONDARYTYPE] = useState({
        "WBS_CATEGORY":"WBS",
        "TASK_CATEGORY":"TASK",
        "TASK":"TASK",
        "WBS":"TASK",
    })
    const [ANDFILTERTYPE] = useState({
        "WBS_CATEGORY":"WBS_CATEGORY",
        "TASK_CATEGORY":"TASK_CATEGORY_NAME",
        "TASK":"TASK_NAME",
        "WBS":"WBS_ITEM",
    })

    const [pathparamsType] = useState({
        "TASK_CATEGORY":"TASK_CATEGORY",
        "WBS_CATEGORY":"WBS_CATEGORY",
        "EMPLOYEE":"RESPONSIBLE_NAME",
        "VENDOR":"VENDOR_NAME",
        "APPROVER":"APPROVER_NAME"
    })



    let barGraph = (item,index) => {
        let tItem = data[item];
        let categories = [`Total`,"Completed","In Progress","Yet To Start","Should Have Started","Should Have Finished"]
        let items = [];
        items.push(tItem["total"]);
        items.push(tItem["completed"]);
        items.push(tItem["total"]-tItem["completed"]-tItem["notStarted"]);
        items.push(tItem["notStarted"]);
        items.push(tItem["expectedStartedButNotStarted"]);
        items.push(tItem["expectedCompletedButNotDone"])

        let onTime = 0;
        if(tItem.total===tItem.notStarted) {
            onTime=0;
        } else {
           onTime = 1-(tItem.delayed)/(tItem.total-tItem.notStarted)
        }

        return(
          <Container key={index} width={'50%'} onClick={()=>{
                if(active!=='EMPLOYEE' && active!=='APPROVER')
                  setShow(item)   
             }}>
               <div style={{display:"flex"}}>
                <div style={{flex:2}}>
                  <BarGraph 
                    download={false}
                    height={350} 
                    title={item} 
                    categories={categories} 
                    data = {[          // Chart by Approver Person
                        {
                            name:'Tasks',
                            data :items,
                        },
                    ]} 
                    onClick={(index)=>{
                        let allFilter = [...filter];
                        allFilter = allFilter.filter(item=>item.type!==active)
                        allFilter.push({
                            type:pathparamsType[active],
                            value:(pathparamsType[active]==='VENDOR_NAME'||pathparamsType[active]==='RESPONSIBLE_NAME'||pathparamsType[active]==='APPROVER_NAME')?item.split("(")[0]:item
                        })    
                        let i = encodeURIComponent(JSON.stringify(allFilter));
                        console.log(i);
                        if(index===0) {
                            window.open(`/app/project/task/${id}?type=${pathparamsType[active]}&value=${item}&status=NONE&filter=${i}`,"_blank")
                        } else if(index===1) {
                            window.open(`/app/project/task/${id}?type=${pathparamsType[active]}&value=${item}&status1=COMPLETED&filter=${i}`,"_blank")
                        } else if( index===2) {
                            window.open(`/app/project/task/${id}?type=${pathparamsType[active]}&value=${item}&status1=PROGRESS&filter=${i}`,"_blank")
                        }  else if( index===3) {
                            window.open(`/app/project/task/${id}?type=${pathparamsType[active]}&value=${item}&status1=NOTSTARTED&filter=${i}`,"_blank")
                        }   else if(index===4) {
                            window.open(`/app/project/task/${id}?type=${pathparamsType[active]}&value=${item}&status1=SHOULD_STARTED&filter=${i}`,"_blank")
                        } else if(index===5) {
                            window.open(`/app/project/task/${id}?type=${pathparamsType[active]}&value=${item}&status1=SHOULD_COMPLETED&filter=${i}`,"_blank")
                        }
                    }}
                    colors={["#0073ea","#03fc98","#ff6347","#33cccc"]}
                    distributed={false}
                    horizontal={false}
                    dataLabels={{
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                        offsetY: 0,
                        style: {
                            fontSize: '11px',
                            colors: ["#fff"]
                        }
                    }}
                    />  
                </div>

               </div>
                <div style={{display:'flex',marginTop:20}}>
                    <div style={{width:'23%',textAlign:'center'}}>
                         <Gauge level={5} percent={(tItem.completed)/(tItem.total)} />
                         <p style={{fontSize:11,fontWeight:700}}>Percentage Completed</p>
                    </div>
                    <div style={{width:'23%',textAlign:'center'}}>
                         <Gauge level={5} percent={(tItem.expectedCompletedButNotDone)/(tItem.total)} />
                         <p style={{fontSize:11,fontWeight:700}}>Expected Completed</p>
                    </div>
                    <div style={{width:'23%',textAlign:'center'}}>
                         <Gauge level={5} percent={onTime} />
                         <p style={{fontSize:11,fontWeight:700}}>on Time Performance</p>
                    </div>
                    <div style={{width:'31%',textAlign:'center',fontSize:12}}>
                        <span style={{background:'var(--background-color)',padding:'5px 10px',display:'inline-block',borderRadius:12,border:'1px solid var(--border-color)'}}>Original Start Date : {Moment(tItem["minOriginalDate"]).format('DD-MM-YYYY')}</span><br></br>
                        <span style={{background:'var(--background-color)',padding:'5px 10px',display:'inline-block',borderRadius:12,border:'1px solid var(--border-color)',marginTop:5}}>Original End Date : {Moment(tItem["maxOriginalDate"]).format('DD-MM-YYYY')}</span><br></br>
                        <span style={{background:'var(--background-color)',padding:'5px 10px',display:'inline-block',borderRadius:12,border:'1px solid var(--border-color)',marginTop:5}}>Estimated Start Date : {Moment(tItem["minEstimatedDate"]).format('DD-MM-YYYY')}</span><br></br>
                        <span style={{background:'var(--background-color)',padding:'5px 10px',display:'inline-block',borderRadius:12,border:'1px solid var(--border-color)',marginTop:5}}>Estimate End Date : {Moment(tItem["maxEstimatedDate"]).format('DD-MM-YYYY')}</span><br></br>

                    </div>
                </div>

         </Container>
        )
    }


    return(
        <div className="flex" style={{flexWrap:'wrap'}}>
            {Object.keys(data).map(barGraph)}
            { show ?<SubType filter={filter} type={SECONDARYTYPE[active]} active={ANDFILTERTYPE[active]} item={show} data={data[show]} onClose={()=>{setShow(false)}} />:null}
        </div>
    )
})

export default Index;
