import { Profile } from "../Profile"
import {useHistory} from 'react-router-dom';
import { MixPanel } from "../../Hooks/Mixpanel";

const Header = ({items,title}) => {

    let HISTORY = useHistory();

    let mapItem = (item,index) => {
        let style = {
            margin:'0px 12px',
            cursor:'pointer',
            paddingBottom:17,
            fontSize:15
        }
        if(item.active) {
            style.color = 'var(--theme-main-color)';
            style.fontWeight = '700';
            style.borderBottom = '2px solid var(--theme-main-color)';
            style.paddingBottom = 15;
        }
        return(
            <div onClick={()=>{
                HISTORY.push(item.link);
                MixPanel.init(item.name,'tab')
            }} key={index} style={style}>
                {item.name}
            </div>
        )
    }

    return(
        <div style={{
                position:'relative',
                borderBottom:"1px solid var(--border-color)",
                background:'var(--white-color)',
                height:60,
                borderLeft:"1px solid var(--border-color)",
                padding:'0px 20px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
                }}>
            <div style={{display:'flex',height:'inherit',alignItems:'flex-end'}}>
                {items.length===0?<p style={{fontWeight:'700',fontSize:22,paddingBottom:22}}>{title}</p>:items.map(mapItem)}
            </div>
            <Profile />
        </div>
    )
}

Header.defaultProps = {
    items:[]
}

Header.propsTypes = {


}
export {Header}