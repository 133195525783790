import { useEffect, useState } from "react";
import { get } from "../../../../Model/Network/Config/Axios";
import { SimpleFlex } from "../../../Common/Flex/SimpleFlex";
import { KeyValue } from "../../../Common/KeyValue";
import { PageLoader } from "../../../Common/PageLoader";
import MapResource from './MapResource'

const Index = (props) => {

    let [loading,setLoading] = useState(true);
    let [resourceTemplates,setResourceTemplates] = useState([]);
    let [add,setAdd] = useState(false);

    useEffect(()=>{
        INIT()
    },[])

    let INIT = () => {
        get(`project/task/resource/resourceTemplate/${props.item.id}`,(e,r)=>{
            if(r) {
                setResourceTemplates(r.resourceTemplates);
                setLoading(false);
            }
        })
    }


    let mapResource = (item,index) => {
        return(
            <div style ={{
                width:'33%',
                padding:5,
                marginTop:20,
                position:'relative'
            }} key={index}>
              <div style={{
                   border:'1px solid var(--border-color)',
                   padding:20,
                   borderRadius:8,
                   marginRight:10
              }}>
                <div style={{
                    position:'absolute',
                    top:-15,
                    background:'var(--button-color)',
                    padding:'3px 6px',
                    left:20,
                    fontSize:12,
                    color:'#fff',
                    borderTopLeftRadius:4,
                    borderTopRightRadius:4,
                    fontWeight:'600'

                }}>
                    Resource {index+1}
                </div>
                <SimpleFlex>
                    <KeyValue title="Name" desc={item.name} style={{flex:1}} />
                    <KeyValue title="Units" desc={item.units} style={{flex:1}} />
                </SimpleFlex>
                <SimpleFlex>
                    <KeyValue title="Resource Type" desc={item.resourceType} style={{flex:1}} />
                    <KeyValue title="Resource Sub Type" desc={item.subType} style={{flex:1}} />
                </SimpleFlex>
                <SimpleFlex>
                    <KeyValue title="Resource Category" desc={item.category} style={{flex:1}} />
                    <KeyValue title="Quantity" desc={"0"} style={{flex:1}} />
                </SimpleFlex>
                <SimpleFlex>
                    <KeyValue title="Code" desc={item.code} style={{flex:1}} />
                    <KeyValue title="Details" desc={item.details} style={{flex:1}} />
                </SimpleFlex>
                </div>
            </div>
        )
    }


    return(
    <>
       
           {
               loading ?
               <PageLoader style={{marginTop:50}} />
               : 
               <>
               <div>
                  <div style={{display:'flex',justifyContent:'flex-end'}}>
                   <div style={{
                    height:40,
                    width:40,
                    border:'2px dashed var(--active-color)',
                    borderRadius:'50%',
                    color:'var(--active-color)',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    fontSize:40,
                    paddingBottom:10,
                    cursor:'pointer'
                    }} onClick={()=>{
                         setAdd(true)
                     }}>
                         +
                    </div>
                   </div>
                   <div style={{
                       display:'flex',
                       flexWrap:'wrap'
                   }}>
                       {resourceTemplates.map(mapResource)}
                   </div>
               </div>
               </>
           }
        {
            add ?
            <MapResource 
                init={()=>{
                    setAdd(false);
                    INIT()
                }}
                onClose={()=>{
                    setAdd(false);
                }}
                id={props.item.id}
            />
            : null
        }
    </>
    )
}

export default Index;