import {  sortarrow } from '../../Common/Images';


const Index = ({data,mapItem,headers,style}) => {


   let mapHeader = (item,index) => {
        let style = {
            fontWeight:'600',
            color:'var(--text-light-color)',
            fontSize: 13, 
            textAlign: "center",
            padding: 3, 
            borderRadius: 5,
            ...item.style
        };
        if(item.flex) {
            style.flex = item.flex;
        } 
        if(item.width) {
            style.width=item.width;
        }

        if(item.onSortClick) {
            return(
                <div style={{...style, ...item.sort?{background:'#dfe4ec', fontWeight:'700'}:{}}} key={index} className="table-sort">
                    <span onClick={item.onSortClick} style={{padding:'0px 0px',cursor:'pointer',borderRadius:4,...item.sort?{}:{}}}>
                        {item.name}
                        {item.sort?<img src={sortarrow} alt="" style={{height:12,width:12,...item.up?{transform:'rotate(-90deg)'}:{transform:'rotate(90deg)'}}} />:null}
                    </span>
                </div>
            )
        }
        
        return(
            <div key={index} style={style}>
                {item.name}
            </div>
        )
    }

    return(
        <div style={{background:'#fff',borderRadius:4,margin:10,marginTop:20,...style}} className="box-shadow">
            <div id="pagination-table-header" style={{minHeight:50,display:'flex',alignItems:'center',flex:1,paddingLeft:20,borderBottom:'1px solid var(--border-color)'}}>
                {headers.map(mapHeader)}
            </div>
            <div>
                {data.map(mapItem)}
            </div>
        </div>
    )
}

Index.defaultProps ={
    headers:[]
}

export default Index;