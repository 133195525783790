import { useEffect, useRef } from "react";
import { post } from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { showToast } from "../../../../Common/Toast";
import { MainModal } from "../../../../NCommon/Modal"



const Approve = ({show,close,id,INIT}) =>{

    console.log(show);
    let QTY = useRef();

    useEffect(()=>{
        if(show) {
            QTY.current.value = show.qty;
        }
    },[show])

    return(
        <MainModal
            open={show}
            close={close}
            title="Approve Request"
        >
            <FunctionalInput title="Quantity" ref={QTY} />
            <Button onClick={()=>{
                post(`project/inventory/receivingRequest/${id}/approve/${show.receivingRequestId}`,{qty:+QTY.current.value},(e,r)=>{
                    if(r) {
                        showToast({
                            type:'success',
                            message:'Quantity update successfully'
                        })
                        INIT();
                    } else {
                        showToast({
                            type:'error',
                            message:e.data.message
                        })
                    }
                })
            }} text="Approve" style={{marginTop:20}} />
        </MainModal>
    )
}

export default Approve;