import { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import {  get, post } from "../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../Common/PageLoader";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { useTableCount } from "../../../../Hooks/TableCount";
import { useProject } from "../../../../Hooks/ProjectDetails";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import { List } from "../../../../NCommon/Ui/List";
import Moment from 'moment';
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";
import Approve from './Approve';

const Index = () => {

    const [loading,setLoading] = useState(true);
    const [deleteModal,setDeleteModal] = useState(false);
    const [approve,setApprove] = useState(false);
    let projectDetails = useProject();
    let count = useTableCount();
    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    let {id} = useParams();
    

    useEffect(()=>{
        INIT({page:0})
    },[count])

    let INIT = (data) => {
        if(!data) {
            data={
                page:0
            }
        }
        data.size = `${count}`
        data.offset = (data.page*count).toString();
        get(`project/inventory/receivingRequest/${id}/getAllRequests?pending=${true}&approved=${false}`,(e,r)=>{
           if(r) {
             setLoading(false);
             setMData({
                 ...mData,
                 page:data.page,
                 total:r.total?r.total:data.total,
                 items:r.receivingRequests
             })
           }
       },{...data})
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
               <div className="flex-2">
                    <div className="table-title">{item.resourceInventory.resourceDetails.name}</div>
                    <div className="table-bottom"></div>
               </div>
               <div className="flex-1">
                    <div className="table-text">{item.qty}</div>
               </div>
               <div className="flex-1">
                    <div className="table-text">{item.data.actualReceivedQty}</div>
               </div>
               <div className="flex-1">
                    <div className="table-text">{Moment(item.createdAt).format('DD-MM-YYYY')}</div>
               </div>
               <TableMenu items={[{name:'Approve',onClick:(e)=>{
                    e.stopPropagation();
                   setApprove(item)
               }}]} />
            </List>
        )
    }

    return(
        <>
            {
                loading ?
                <PageLoader />:
                <>
                <HeaderWrapper hideCard={true}  />
                <PageWrapper defaultView={'list'} id="pagination-table-header" scroll={true}>
                    <NavigationWrapper items={[{name:'All Projects',path:'/app/project'},{name:`${projectDetails.name}`,path:`/app/project/mapping/${id}`},{name:`Pending Request (${mData.total})`}]}/>
                    <PaginationTable
                        DATA={mData.items}
                        total={mData.total}
                        page={mData.page}
                        mapCardLayout={()=>{}}
                        mapListLayout={mapListLayout}
                        next={(page)=>{
                            INIT({page})
                        }}
                        headers={[
                            {
                                flex:2,
                                name:'Details',
                                style:{
                                    justifyContent:'flex-start'
                                }
                            },
                            {
                                flex:1,
                                name:"Request Quantity"
                            },
                            {
                                flex:1,
                                name:'Actual Received Quantiy'
                            },
                            {
                                flex:1,
                                name:'Created At'
                            },
                          
                            {
                                width:50,
                                name:'actions'
                            }
                        ]}
                        defaultView={'list'}
                    />
                </PageWrapper>
              </>
                
            }
            <Approve 
                show={approve} 
                id={id}
                close={()=>{setApprove(false)}}
                INIT={()=>{
                    INIT({page:mData.page});
                    setApprove(false)
                }}
                />
            
        </>
    )
}
export default Index;