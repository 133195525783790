import { forwardRef, useEffect } from 'react';


const FunctionalInput = forwardRef((props,ref)=>{


    useEffect(()=>{
        if(props.defaultValue) {
            ref.current.value = props.defaultValue;
        }
    },[])

     return (
            <div  style={{flex: 1,...props.style}}>
                <p style={{ fontSize: 12, color: 'var(--title-color)',  fontWeight: 600, marginTop: 15}}>{props.title} </p>
                <input ref={ref} className="finput" disabled={props.disabled?props.disabled:false} type={props.type} {...props.inputProps} 
                        placeholder={props.placeholder ? props.placeholder : props.title}
                        onChange={e => {
                            if (props.onChange) {
                                props.onChange(e.target.value)
                            }

                        }}
                        value={props.value}
                        onClick={() => {
                            if (props.onClick) {
                                props.onClick()
                            }
                        }}
                        autoComplete="off"
                    />
                {props.children}
                    
                {props.desc ?
                        <p style ={{color:'var(--theme-main-color)',fontWeight:'600',fontSize:12,marginTop:5}}>** {props.desc}</p>
                        : null
                    }
            </div>
        )
    
})



export {FunctionalInput}