import { useEffect, useRef, useState } from "react";
import {useParams} from 'react-router-dom';
import {  post,axios } from "../../../../Model/Network/Config/Axios";
import { DownloadCsv } from "../../../../Utils/Downloader";
import { DownloadZip } from "../../../../Utils/DownloadZip";
import { AccessButton, Button } from "../../../Common/Button";
import { PageLoader } from "../../../Common/PageLoader";
import { showToast } from "../../../Common/Toast";
import { useDictionaryValue } from "../../../Hooks/DictionaryHooks";
import { useProject } from "../../../Hooks/ProjectDetails";
import { useTableCount } from "../../../Hooks/TableCount";
import { Header } from "../../../NCommon/Header";
import { HeaderWrapper } from "../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../NCommon/PageWrapper";
import { PaginationTable } from '../../../NCommon/Tables/PaginationTable';
import { List } from "../../../NCommon/Ui/List";
import { Card } from '../../../NCommon/Ui/CardLayout';
import MapWbsItem from "./MapWbsItem";
import { CheckBox } from "../../../NCommon/Ui/CheckBox/Checkbox";
import { FunctionalInput } from "../../../Common/FloatInput/FunctionalInput";
import { Modal } from "../../../Common/Modal/Modal";
import { PDFExport } from "@progress/kendo-react-pdf";
import { TableMenu } from "../../../NCommon/Tables/TableMenu";
import { Upload } from "../../../Common/Upload";
import { MainModal } from "../../../NCommon/Modal";
import { AddTag } from "../../../Common/Tag/AddTag";
import { ViewTag } from "../../../NCommon/Tag";



const Index = () => {

    const [loading,setLoading] = useState(true);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [modal,setModal] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [checkbox,setCheckBox] = useState(false);
    const [qr,setQr] = useState(false);
    const [item,setItem] = useState(false);
    const [ascending,setAcending] = useState(true);
    const [upload,setUpload] = useState(false);
    const [sorted,setSorted] = useState('NAME')
    const [tag,setTag] = useState(false);
    const [updateId,setUpdateId] = useState(1);
    const [data,setData] = useState({
        items:[],
        page:0,
        total:0
    })
    const [filters,setFilters] = useState([]);
    let  getDictionaryValue  = useDictionaryValue();
    let REMARKS = useRef(),PDF=useRef();
    let count = useTableCount();
    let {id} = useParams();
    let details = useProject();



    useEffect(()=>{
        INIT({page:0})
    },[count,ascending])

    let INIT = (mData) => {
        if(!mData) {
            mData = {page:0}
        }
        mData.size = `${count}`;
        mData.offset = (count*mData.page).toString();
        if(mData.filter) {
            mData.orFilters=[...mData.filter];
            delete mData.filter;
        }
        mData.sortedBy = sorted;
        mData.ascending = ascending;
        post(`project/wbs/wbsItemMapping.all/${id}`,mData,(e,r)=>{
            if(r) {
                setData({
                    items:r.itemMappingDetails,
                    page:mData.page,
                    total:r.total
                })
                setLoading(false);
            }
        })
        
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.description}</p>
                </div>
                <div className="flex-1">
                    <ViewTag update={updateId} id={item.id} type={'WBS_MAPPING'} add={()=>{setTag(item.id)}}/>
                </div>
                <TableMenu items={[{name:'View Qr',onClick:()=>{viewQr(item)}},{name:'Delete Item',onClick:()=>setDeleteModal(item)}]} />
            </List>
        )
    }


    let mapCardLayout = (item,index) =>{
        return(
            <Card 
                isOpen={true} 
                onCustom={()=>{viewQr(item)}} 
                custom="View Qr" 
                onDelete={()=>{setDeleteModal(item)}} 
                key={index} 
                title={item.name} 
                desc={item.description}
                items={[{name:'View Qr',onClick:()=>{viewQr(item)}},{name:'Delete Item',onClick:()=>setDeleteModal(item)}]}
                >
                 <div style={{marginTop:"auto"}}>
                    <div style={{borderTop:'0.5px solid var(--border-color)',height:22,marginLeft:-12,marginRight:-12,display:'flex',alignItems:'center'}}>
                        <ViewTag update={updateId} id={item.id} type={'WBS_MAPPING'} add={()=>{setTag(item.id)}}/>
                    </div>
                </div>
            </Card>
        )
    }


    let viewQr = (item) => {
        post(`project/wbs/generateQr/${id}/${item.id}`, {}, (e, r) => {
            if (r) {
                setQr("data:image/png;base64," + r.qrImgBase64)
                setItem(item);
            }
        })
    }

    let download = () => {
        let baseData = [[
            "id",
            "name",
            "description"
        ]]
        data.items.forEach(val => {
            baseData.push([
                val.id,
                val.name,
                val.description
            ])
        })
        DownloadCsv('projectWbsItem.csv', baseData);
    }

    let downloadQr = () => {
        post(`project/wbs/generateAllQr/${id}`,{},(e,r)=>{
            if(r) {
                let result = [];
                r.qrCodes.forEach(item=>{
                    result.push({
                        name:item.wbsItem,
                        path:`data:image/png;base64,${item.qrImgBase64}`
                    })
                })
                DownloadZip(result,()=>{
                    showToast({
                        type:'success',
                        message:'All qr code downloaded successfully'
                    })
                    setButtonLoading(false);
                });
            } else {
                   setButtonLoading(false);
            }
        })
    }

    let deleteItem = () => {
            let url = `/project/wbs/wbsItemMapping.delete/${deleteModal.id}`;
            if(checkbox) {
                let remarks = REMARKS.current.value;
                if(!remarks) {
                    showToast({
                        type:'error',
                        message:"Please enter the remarks"
                    })
                    setButtonLoading(false);
                    return;
                }
                url = `${url}?hard=true&reason=${remarks}`
            }
            axios.delete(url, {})
                .then(() => {
                    setButtonLoading(false);
                    setDeleteModal(false);
                    INIT({page:0})
                    showToast({
                        type: 'success',
                        message: "Wbs mapping item delete successfully"
                    })
                })
                .catch(res => {
                    showToast({
                        type: 'error',
                        message: res.data.message
                    })
                    setButtonLoading(false);
                })
    }

    let handleSort = (key) => {
        if(key===sorted) {
            setAcending(!ascending)
        } else {
            setSorted(key);
            setAcending(true)
        }
    }

    return(
        <>
            <Header title={`${getDictionaryValue('wbs')} Item`} />
            {
                loading ?
                <PageLoader />
                :
                <>
                    <HeaderWrapper
                         types={["Wbs Mapping","Custom Wbs"]}
                         dynamicFilter={true}
                         filters={[...filters]}
                         onFilterChange={items=>{
                            let filter = [];
                            items.forEach(item=>{
                                filter.push({
                                    key:item.type==='WBS'?'NAME':item.type==='CUSTOMWBS'?'CUSTOM':null,
                                    val:item.label
                                  })
                            })
                            setFilters(items)
                            INIT({page:0,filter})
                        }}
                        menuItem={[{name:'Upload Csv',onClick:()=>{setUpload(true)},type:'uploadcsv'},{name:'Download Csv',type:'downloadcsv',onClick:download},{name:'Download All Qr',onClick:downloadQr}]}
                    />
                    <PageWrapper id="pagination-table-header" scroll={true}>
                        <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${details.name}`,path:`/app/project/mapping/${id}`},{name:`${getDictionaryValue('wbs')} (${data.total})`}]}>
                            <AccessButton onClick={()=>{setModal(true)}} inverted={true?1:0}  text={`Map ${getDictionaryValue('wbs')}`}  />
                        </NavigationWrapper>
                        <PaginationTable
                                    DATA={data.items}
                                    next={(page)=>{
                                        INIT({page})
                                    }}
                                    mapListLayout={mapListLayout}
                                    mapCardLayout={mapCardLayout}
                                    total={data.total}
                                    page={data.page}
                                    headers={[
                                        {
                                            name:'Details',
                                            flex:1,
                                            sort:sorted==='NAME',
                                            onSortClick:()=>handleSort('NAME'),
                                            up:ascending
                                        },
                                        {
                                            name:'action',
                                            width:50
                                        }
                                    ]}
                                />
                    </PageWrapper>
                </>
            }

           <MainModal
                title={`Map ${getDictionaryValue('wbs')} Item`}
                desc={`Search the ${getDictionaryValue('wbs')} item category and map in this project`}
                open={modal}
                close={() => {setModal(false)}}
                        >
                <MapWbsItem
                    id={id}
                    onComplete={() => {
                        INIT({page:0})
                        setModal(false)
                    }}
                />                        
            </MainModal>  
                        
            
            {
                    deleteModal ?
                        <Modal
                            title={`Delete Wbs Mapping Item`}
                            desc={`Delete ${deleteModal.name}`}
                            show={deleteModal}
                            onClose={()=>{setDeleteModal(false)}}
                            style={{minHeight: 150}}
                            >
                            <p style={{marginTop: 50}}>Are you sure want to delete <span style={{fontWeight: 600}}>{deleteModal.name}</span> ? </p>
                            <div style={{
                                display:'flex',
                                alignItems:'center',
                                marginTop:30
                            }}>
                               <CheckBox onChange={setCheckBox} />
                                <p style ={{
                                    marginLeft:15,
                                    fontSize:13
                                }}>Delete Permanently</p>
                            </div>
                                {
                                    checkbox ?
                                    <FunctionalInput 
                                        title="Remarks"
                                        type="text"
                                        ref={REMARKS}
                                        desc="** All the task tag to this wbs item are deleted permanently and cannot be retrieved back."
                                        />
                                    : null
                                }

                            <Button
                                text="Delete"
                                style={{
                                    marginTop: 50,
                                    backgroundColor: 'var(--red-color)',
                                    borderColor: 'var(--red-color)'
                                }}
                                onClick={() => {
                                    setButtonLoading(true);
                                    deleteItem();
                                }}
                                loading={buttonLoading}
                            />
                        </Modal>
                        : null
                }
                 {
                     upload ?
                        <Upload
                            title="Upload"
                            desc="Please select a flte to create wbs item"
                            name="Select File"
                            type="wbs"
                            endPoint={`project/wbs/wbsItems.upload/${id}`}
                            fileName="Wbs.csv"
                            csvData={[
                                ["wbsitemcategory", "wbsitemname", "description"],
                                ["J Tower JOI2", "J001", "J001 Apartment in J Tower JOI2"]
                            ]}
                            onClose={() => {
                                setUpload(false);
                            }}
                            accept=".xlsx,.csv"
                            islink={true}
                            onComplete={() => {
                                INIT({page:0})
                                setUpload(false);
                            }}
                        />
                        : null
                }

                 {
                    qr ?
                        <Modal
                            show={qr}
                            title="Qr Code"
                            desc="Download qr code to scan by employees"
                            onClose={() => {setQr(false)}}
                        >
                            <PDFExport padding="20cm" title="qr code" fileName={`${item.name}.pdf`} ref={r => PDF.current = r}>
                                <div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <img src={qr} alt="" style={{
                                            height: 300,
                                            width: 300
                                        }} />
                                    </div>
                                    <div style={{
                                        textAlign: 'center',
                                        fontSize: 20,
                                        fontWeight: '600'
                                    }}>{item.name}</div>
                                </div>
                            </PDFExport>

                            <Button
                                text="Download Qr"
                                style={{
                                    marginTop: 20
                                }}
                                onClick={() => {
                                    PDF.current.save();
                                }}
                            />

                        </Modal>
                        : null
                }
                  {
                    tag?
                    <AddTag referenceId={tag} type="WBS_MAPPING" init={()=>{
                        setTag(false);
                        setUpdateId(updateId+1)
                        INIT({page:data.page})
                    }} close={()=>setTag(false)} />
                    :null
                }

        </>
    )
}

export default Index;