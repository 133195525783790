import  { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { get } from '../../../Model/Network/Config/Axios';
import { Carousel } from '../Carousel';
import { PageLoader } from '../PageLoader';




const TimeLine = (props) => {
    const [photos,setPhotos]=useState({});
    const [loading,setLoading] = useState(true);
    const [page,setPage] = useState(0);
    const [total,setTotal] = useState(0);
    const {url} = props;

    useEffect(()=>{
        INIT(url)
    },[url,page])
  

    let INIT = (path) =>  {
        get(path,(e,r)=>{
            if(r) {
                let combine = {...photos,...r.photosItems}
                setPhotos(combine);
                setTotal(r.total);
                setLoading(false);
            }
        },{
            size:100,
            offset:page*100,
            page
        })
    }
     
   let fetch = () => {
        if(total>=page*100) {
            setPage(page+1);
        }
    }

    if(loading) {
        return <PageLoader />
    }
    return(
            <>   
                <InfiniteScroll
                    dataLength={page+1*100}
                    next={fetch}
                    hasMore={true}
                    height={'calc(100vh - 50px)'}
                  >
                   {Object.keys(photos).map((item,index)=>{
                       return(
                           <div key={index}>
                              <div style = {{
                                  fontWeight:'900',
                                  fontSize:22,
                                  padding:'20px 0px'
                              }} >
                                    {item}
                              </div>

                              <div style ={{backgroundColor:'#fff',borderRadius:4,padding:20,border:'1px solid var(--border-color)'}}>
                                  <Carousel
                                     items={photos[item]}
                                     showDetails={true}
                                  />
                              </div>
                           </div>
                       )
                   })}
                </InfiniteScroll>
          </>
    )
}

export {TimeLine}