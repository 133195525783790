import  { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import AddEmployee from './AddEmployee';
import { PageLoader } from '../../../../Common/PageLoader';
import { AccessButton } from '../../../../Common/Button';
import { get } from '../../../../../Model/Network/Config/Axios';
import { DownloadCsv } from '../../../../../Utils/Downloader';
import { HeaderWrapper } from '../../../../NCommon/HeaderWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import {PaginationTable} from '../../../../NCommon/Tables/PaginationTable';
import { Card } from '../../../../NCommon/Ui/CardLayout';
import { List } from '../../../../NCommon/Ui/List';
import { useTableCount } from '../../../../Hooks/TableCount';
import BulkUpload from './BulkUpload';

const EmployeeList = () => {

    const [employees,setEmployees] = useState({
        page:0,
        total:0,
        items:[]
    });
    const [loading,setLoading] = useState(true);
    const [addEmployee,setAddEmployee] = useState(false);
    const [filters,setFilters] = useState([]);
    const [bulk,setBulk] = useState(false);
    const HISTORY = useHistory();
    let count = useTableCount();

    useEffect(()=>{
        INIT({page:0})
    },[count])

    let INIT = (data) => {
        if(!data) {
            data = {page:0}
        }

        if(data.filter){
            data = {
                ...data,
                ...data.filter
            }
            delete data.filter;
        }
        data.size = `${count}`;
        data.offset = data.page ? (data.page*count).toString():"0"
        get(`employees/all`, (e, r) => {
            if (r) {
                setEmployees({
                    items:r.employees,
                    page:data.page,
                    total:r.total?r.total:employees.total
                });
                setLoading(false);
            }
        },data)
    }
    
    let mapCardLayout = (item,index) => {
        return(
            <Card height={120} onClick={()=>{HISTORY.push(`/app/employee/details/${item.id}`)}} icon={true} key={index} title={item.name} desc={item.email}>
                <div style={{marginLeft:55}}>
                    <p className="tiny-text">{item.employeeCode}</p>
                </div>
            </Card>
        )
    }

    let next = (page) => {
        INIT({page})
    }

    let mapListLayout = (item,index) => {
        return(
            <List onClick={()=>{HISTORY.push(`/app/employee/details/${item.id}`)}}  key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.email}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.employeeCode}</p>
                </div>
            </List>
        )
    }


   let download = () => {
        let baseData = [[
            "Name",
            "Email",
            "Code",
            "Mobile",
            "Dob",
            "Role",
            "Manager",
            "Created At"

        ]]

        get(`employees/all`, (e, r) => {
            if (r) {
                r.employees.forEach(val => {
                    baseData.push([
                        val.name,
                        val.email,
                        val.employeeCode,
                        val.mobile,
                        val.dob,
                        val.role,
                        val.manager,
                        val.createdAt
        
                    ])
                })
        
                DownloadCsv(`employee.csv`, baseData);
            }
        },{})
    }


     return (
            <>
                {
                    loading ?
                        <PageLoader />
                        :
                        <>
                            <HeaderWrapper filters={[...filters]} dynamicFilter={true} onFilterChange={items=>{
                                let filter = {};
                                items.forEach(item=>{
                                    filter[item.type] = item.value;
                                })
                                setFilters(items)
                                INIT({page:0,filter})
                            }} types={["Employee Name"]} menuItem={[{name:'Download Csv',type:'downloadcsv',onClick:download}]}  />
                            <PageWrapper id="pagination-table-header" scroll={true}>
                                <NavigationWrapper items={[{name:`All Employees (${employees.total})`}]}>
                                    <AccessButton
                                        text="Add Employee"
                                        addsign={true?1:0}
                                        inverted={true?1:0}
                                        style={{padding:'0px 10px'}}
                                        onClick={()=>{setAddEmployee(true)}}
                                    />
                                    <AccessButton 
                                        text="Bulk Upload" 
                                        inverted={true?1:0} 
                                        style={{marginLeft:10}} 
                                        onClick={()=>{setBulk(true)}} 
                                    />
                                </NavigationWrapper>
                                <PaginationTable
                                    DATA={employees.items}
                                    next={(page)=>{
                                        next(page)
                                    }}
                                    mapListLayout={mapListLayout}
                                    mapCardLayout={mapCardLayout}
                                    total={employees.total}
                                    page={employees.page}
                                    headers={[
                                        {
                                            name:'Details',
                                            flex:1
                                        },
                                        {
                                            name:'Employee Code',
                                            flex:1
                                        }
                                    ]}
                                />
                            </PageWrapper>
                            {
                                addEmployee ?
                                    <AddEmployee
                                        onClose={() => {
                                            setAddEmployee(false);
                                            INIT();
                                        }}
                                     />
                                    : null
                            }
                            <BulkUpload open={bulk} close={()=>{setBulk(false)}} />
                        </>
                }
            </>
        )
}

export default EmployeeList;
