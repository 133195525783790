import { useState,useEffect } from "react";
import { get } from "../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../Common/PageLoader";
import {AccessButton} from '../../../Common/Button/index';
import AddVendor from '../AddVendor';
import { useTableCount } from "../../../Hooks/TableCount";
import { Header } from "../../../NCommon/Header";
import { HeaderWrapper } from "../../../NCommon/HeaderWrapper";
import { PageWrapper } from "../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../NCommon/NavigationWrapper";
import {List} from '../../../NCommon/Ui/List';
import {PaginationTable} from '../../../NCommon/Tables/PaginationTable';
import BulkUpload from './BulkUpload'; 
import { TableMenu } from "../../../NCommon/Tables/TableMenu";
import EditDetail from './EditDetails';
import { DownloadCsv } from "../../../../Utils/Downloader";

const AllVendor = () => {

    const [mData,setMData] = useState({
        items:[],
        page:0,
        total:0
    });
    const [loading,setLoading] = useState(true)
    const [filters,setFilters] = useState([]);
    const [modal,setModal] = useState(false);
    const [bulk,setBulk] = useState(false);
    const [edit,setEdit] = useState(false);
    let count = useTableCount();

    useEffect(()=>{
        INIT({page:0});
    },[count])


    let INIT = (data) => {
        if(!data) {
            data ={
                page:0
            }
        }
        data.size = `${count}`;
        data.offset = (data.page*count).toString();
        get(`vendor/all`,(e,r)=>{
            if(r) {
                setMData({
                    items:r.vendorDetails,
                    total:r.total,
                    page:data.page
                })
                setLoading(false);
            }
        },data)
    }
    

    let download = () => {

        let baseData = [
            [
                "name",
                "email",
                "mobile",
                "pan",
                "vendorcode"
            ]
            
        ]

        get(`vendor/all`,(e,r)=>{
            if(r) {
                r.vendorDetails.forEach(val=>{
                    baseData.push(
                        [
                            val.name,
                            val.email,
                            val.mobile,
                            val.pan,
                            val.vendorCode
                        ]
                    )
                })
                DownloadCsv("vendor.csv",baseData)

            }
        })

    }

    let mapListLayout = (item,index) => {
        return(
            <List  key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.name}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.email}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.vendorCode}</p>
                </div>
                <TableMenu items={[{name:'Edit',onClick:()=>{setEdit(item)}}]} />
            </List>
        )
    }

    return(
        <>
            <Header title="All Vendor" />
             {
                 loading ?
                 <PageLoader />
                 :
                 <>
                    <HeaderWrapper
                         types={["Vendor Name"]}
                         dynamicFilter={true}
                         filters={[...filters]}
                         onFilterChange={items=>{
                            let filter = {};
                            items.forEach(item=>{
                                filter['name'] = item.label;
                            })
                            setFilters(items)
                            INIT({page:0,filter})
                        }}
                        hideCard={true}
                        menuItem={[{name:'Download Csv',type:'downloadcsv',onClick:download}]}
                    />
                    <PageWrapper defaultView={'list'} style={{paddingBottom:100}} id="pagination-table-header" scroll={true}>
                        <NavigationWrapper items={[{name:`Vendor ${mData.total}`}]}>
                            <AccessButton text="Add Vendor" inverted={true?1:0} addsign={true?1:0} onClick={()=>{setModal(true)}} />
                            <AccessButton text="Bulk Upload" inverted={true?1:0} style={{marginLeft:10}} onClick={()=>{setBulk(true)}} />
                        </NavigationWrapper>
                        <PaginationTable
                                DATA={mData.items}
                                next={(page)=>{
                                    INIT({page})
                                }}
                                defaultView={'list'}
                                mapListLayout={mapListLayout}
                                total={mData.total}
                                page={mData.page}
                                headers={[
                                    {
                                        name:'Details',
                                        flex:1,
                                    },
                                    {
                                        name:'Email',
                                        flex:1
                                    },
                                    {
                                        name:'Vendor Code',
                                        flex:1
                                    },
                                    {
                                        name:'',
                                        width:50
                                    }
                                ]}
                                />
                    </PageWrapper>
                 </>
             }
            <BulkUpload open={bulk} close={()=>{setBulk(false)}} />
            <EditDetail open={edit} close={()=>{setEdit(false)}} init={()=>{INIT({page:0})}}  />
             { modal ? <AddVendor onComplete={()=>{INIT(); setModal(false);}}onClose={()=>{setModal(false)}} /> : null}
             

        </>
    )
}

export default AllVendor;