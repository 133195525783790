import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {useParams} from 'react-router-dom';
import { get } from "../../../../../Model/Network/Config/Axios";
import { SearchSelect } from "../../../../Common/Search/SearchSelect/SearchSelect";





const SearchInstallApp = forwardRef((props,ref) => {

    const [items,setItems] = useState([]);
    let {id} = useParams();
    let APPVALUE = useRef();

    useEffect(()=>{
        get(`marketplace/installedApps/${id}`,(e,r)=>{
            if(r) {
                setItems(r.apps.map(val=>({value:val.app,id:val.id,})))
            }
        })
    },[])

    useImperativeHandle(ref,()=>({
        getValue:()=>{
            return APPVALUE.current.getValue()
        }
    }))

    return(
        <SearchSelect title={props.title} items={items} handleRef={ref=>APPVALUE.current=ref}  />
    )

})



export {SearchInstallApp}