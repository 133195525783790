import  { useEffect, useState } from 'react';
import Moment from 'moment';
import { KeyValue } from '../KeyValue';
import { Layout } from '../Layout';
import { PageLoader } from '../PageLoader';
import { get } from '../../../Model/Network/Config/Axios';


const TaskOpen = ({id,...props}) => {

    const  [details,setDetails]=useState(null);
    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        get(`project/task/taskMappingDetails/${id}`,(e,r)=>{
            if(r) {
                setDetails(r.tasksMapping);
                setLoading(false);
            }
        })
    },[])
   
    let item = details;
    return (
        <div>
            {props.children}
            {loading ? 
            <Layout>
                <PageLoader />
            </Layout>
             :
            <Layout>

                <div style={{
                    flex: 5,
                    display: 'flex',
                    marginBottom: 15
                }}>
                    <KeyValue title="Task Name" desc={item.task.name} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Task Details" desc={item.task.details} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Task Type" desc={item.task.taskType} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Work Started" desc={item.workStarted ? "Yes" : "No"} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Work Finished" desc={item.workDone ? "Yes" : "No"} style={{
                        flex: 1
                    }} />
                </div>
                <div style={{
                    flex: 5,
                    display: 'flex',
                    marginBottom: 15
                }}>

                    <KeyValue title="Wbs Item Mapping" desc={item.wbsItemMapping.name} style={{
                        flex: 1
                    }} />
                    <KeyValue title="+/- days wrt predecessor" desc={item.lagDay} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Total Days Required" desc={item.totalDaysRequired} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Reponsible Person" desc={item.responsiblePerson?item.responsiblePerson.name:'NA'} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Reponsible Person Code" desc={item.responsiblePerson ?item.responsiblePerson.code:'NA'} style={{
                        flex: 1
                    }} />
                </div>

                <div style={{
                    flex: 5,
                    display: 'flex',
                    marginBottom: 15
                }}>

                    <KeyValue title="Original Planned Start Date" desc={item.originalStartDate ? Moment(item.originalStartDate).format('DD-MM-YYYY') : 'NA'} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Original Planned end Date" desc={item.originalCompletionDate ? Moment(item.originalCompletionDate).format('DD-MM-YYYY') : 'NA'} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Actual Start Date" desc={item.actualStartDate ? Moment(item.actualStartDate).format('DD-MM-YYYY') : '-'} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Actual End Date" desc={item.actualCompletionDate ? Moment(item.actualCompletionDate).format('DD-MM-YYYY') : '-'} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Approver Name" desc={item.approver ? item.approver.name : '-'} style={{
                        flex: 1
                    }} />
                </div>

                <div style={{
                    flex: 5,
                    display: 'flex',
                    marginBottom: 15
                }}>

                    <KeyValue title="Approver Code" desc={item.approver ? item.approver.code : '-'} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Estimated Start Date" desc={item.estimatedStartDate ? Moment(item.estimatedStartDate).format('DD-MM-YYYY') : '-'} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Estimated Finish Date" desc={item.estimatedCompletionDate ? Moment(item.estimatedCompletionDate).format('DD-MM-YYYY') : '-'} style={{
                        flex: 1
                    }} />
                     <KeyValue title="Buffer" desc={item.buffer ? item.buffer : '0'} style={{
                        flex: 1
                    }} />

                  
                    <div style={{
                        flex: 1
                    }} />
                </div>
            </Layout>
            
            }
        </div>
    )
}

export default TaskOpen;