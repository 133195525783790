import { useEffect, useState } from "react";
import {useParams,useHistory} from 'react-router-dom';
import { PageLoader } from "../../../../../Common/PageLoader";
import { useDictionaryValue } from "../../../../../Hooks/DictionaryHooks";
import { useProject } from "../../../../../Hooks/ProjectDetails";
import { HeaderWrapper } from "../../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../../NCommon/PageWrapper";
import { PaginationTable } from '../../../../../NCommon/Tables/PaginationTable';
import { List } from "../../../../../NCommon/Ui/List";
import { Card } from '../../../../../NCommon/Ui/CardLayout';
import { TableMenu } from "../../../../../NCommon/Tables/TableMenu";
import { useTableCount } from "../../../../../Hooks/TableCount";
import { post } from "../../../../../../Model/Network/Config/Axios";




const Index = () => {

    const [loading,setLoading] = useState(true);
    const [ascending,setAcending] = useState(true);
    const [sorted,setSorted] = useState('NAME')
    const [data,setData] = useState({
        items:[],
        page:0,
        total:0
    })
    const [filters,setFilters] = useState([]);
    let  getDictionaryValue  = useDictionaryValue();
    let count = useTableCount();
    let {id} = useParams();
    let details = useProject();
    let HISTORY = useHistory();



    useEffect(()=>{
        INIT({page:0})
    },[count,ascending])

    let INIT = (mData) => {
        if(!mData) {
            mData = {page:0}
        }
        mData.size = `${count}`;
        mData.offset = (count*mData.page).toString();
        if(mData.filter) {
            mData.orFilters=[...mData.filter];
            delete mData.filter;
        }
        mData.sortedBy = sorted;
        mData.ascending = ascending;
        post(`project/wbs/wbsItemMapping.all/${id}`,mData,(e,r)=>{
            if(r) {
                setData({
                    items:r.itemMappingDetails,
                    page:mData.page,
                    total:r.total
                })
                setLoading(false);
            }
        })
        
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.description}</p>
                </div>
                <TableMenu items={[{name:'View',onClick:()=>{HISTORY.push(`/app/project/images/${id}/wbs/${item.id}/details?name=${item.name}`)}}]} />
            </List>
        )
    }


    let mapCardLayout = (item,index) =>{
        return(
            <Card isOpen={true} onClick={()=>{HISTORY.push(`/app/project/images/${id}/wbs/${item.id}/details?name=${item.name}`)}} custom="View" key={index} title={item.name} desc={item.description} />
        )
    }




    let handleSort = (key) => {
        if(key===sorted) {
            setAcending(!ascending)
        } else {
            setSorted(key);
            setAcending(true)
        }
    }

    return(
        <>
            {
                loading ?
                <PageLoader />
                :
                <>
                    <HeaderWrapper
                         types={["Wbs Mapping","Custom Wbs"]}
                         dynamicFilter={true}
                         filters={[...filters]}
                         onFilterChange={items=>{
                            let filter = [];
                            items.forEach(item=>{
                                filter.push({
                                    key:item.type==='WBS'?'NAME':item.type==='CUSTOMWBS'?'CUSTOM':null,
                                    val:item.label
                                  })
                            })
                            setFilters(items)
                            INIT({page:0,filter})
                        }}
                    />
                    <PageWrapper id="pagination-table-header" scroll={true}>
                        <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${details.name}`,path:`/app/project/mapping/${id}`},{name:`${getDictionaryValue('wbs')} (${data.total})`}]} />
                        <PaginationTable
                                    DATA={data.items}
                                    next={(page)=>{
                                        INIT({page})
                                    }}
                                    mapListLayout={mapListLayout}
                                    mapCardLayout={mapCardLayout}
                                    total={data.total}
                                    page={data.page}
                                    headers={[
                                        {
                                            name:'Details',
                                            flex:1,
                                            sort:sorted==='NAME',
                                            onSortClick:()=>handleSort('NAME'),
                                            up:ascending
                                        },
                                        {
                                            name:'action',
                                            width:50
                                        }
                                    ]}
                                />
                    </PageWrapper>
                </>
            }
        </>
    )
}

export default Index;