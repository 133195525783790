import {Switch,Route} from 'react-router-dom';
import Category from './Category';

const Index = () => {

    return(
        <Switch>
            <Route path="/app/others/drawing" component={Category} exact />
        </Switch>
    )
}

export default Index;