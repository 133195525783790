import { post } from "../../Model/Network/Config/Axios"
import Moment from 'moment';
import * as Crypto from "../../Utils/Crypto";
import { AuthActions } from '../Reducers/index'
import { showToast } from "../../Component/Common/Toast";


export function attempLogin(obj) {
    return function (dispatch) {
        let credential = {
            username: obj.email,
            password: obj.password
        }

        post(`/auth/login`, credential, (e, data) => {
            if (data) {
                if (data.isTwoAuthRequired) {
                    dispatch({
                        type: AuthActions.TwoFactor,
                        data: {
                            isTwoAuthRequired: true
                        }
                    })
                } else {

                    Crypto.set('token', data.accessToken.access_token);
                    Crypto.set('refresh_token', data.accessToken.refresh_token);
                    Crypto.set("lastlogin", Moment().valueOf())
                    Crypto.set("loggedin", "yes");
                    Crypto.set('name', data.accessToken.name);

                    dispatch({
                        type: AuthActions.Login,
                        data: {
                            name: data.name,
                            login: Moment()
                        }
                    })
                }
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }
}