


const TableSkeleton = ({items,header,mapItem}) => {


    let mapHeader = (item,index) => {
        
        let style = {
            height:'inherit',
            borderRight:'1px solid var(--border-color)',
            fontSize:14,
            fontWeight:'600',
            paddingLeft:10
        };
        if(item.style) {
            style={...style,...item.style};
        }
        return(
            <div key={index} className="flex flex-v-center" style={style}>
                {item.name}
            </div>
        )
    }


    return(
        <>
            <div className="flex flex-v-center" style={{background:'var(--background-color)',marginTop:10,border:'1px solid var(--border-color)',borderRadius:4,height:40}}>
                {header.map(mapHeader)}
            </div>
            {items.map(mapItem)}
        </>
    )
}

export {TableSkeleton}