import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'
/*
<PieGraph height={400} title="Graph" data={[{name:"Hello",data:[121,222]]} items={["Min Price","Max Price"]}/>
*/
function PieGraph(props) {
		return (
			<div>
				<ReactApexChart options={{
					labels:props.items,
					xaxis: {
						categories: props.items,
					},
					title: {
						text: props.title,
						align: 'left'
					},
					legend: {
						position: 'bottom'
					},
					dataLabels: {
						enabled: true,
						
					},
					chart: {
						width: props.height,
						type: props.type?props.type:"donut",
					},
					plotOptions:{
						pie:{
							donut:{
								labels:{
									name:{
										fontSize:14,
										show:true,
										formatter:()=>{
											return props.title
										},
										fontWeight:'700'
										
									},
									show:true,
									value:{
										show:true,
										fontSize:14,
										formatter:(val)=>{
											return val
										},
										fontWeight:'600'
									},
									total:{
										show:true,
										label:'Total Task'
									},
								}
							}
						}
					},
					responsive: [{
						breakpoint: 480,
						options: {
							chart: {
								width: 200
							},
							legend: {
								show: false
							}
						}
					}],
				}} series={props.data} type={ props.type?props.type:"donut"} height={props.height} />
			</div>
		)
}



PieGraph.propTypes = {
	data : Props.array.isRequired,
	items : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired
}

PieGraph.defaultProps = {
	y: "Price",
	title:'Price Movement',
	height:350
}

export {
	PieGraph
}

export default PieGraph
