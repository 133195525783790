import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';


// Here we use this component to clone task and search existing 



class SearchExistingWbsItemN extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            taskMappedWbsItems: [],
            suggestion: [],
        }
    }



    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }



    onSearch = (val) => {
        if (val.length > 0) {
           get(`project/task/alreadyMappedWbsItems.search/${this.props.id}`,(e,r)=>{
               if(r) {
                    this.setState({
                        ...this.state,
                        suggestion:r.taskMappedWbsItems
                    })
               }
           },{
               q:val
           })

        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }
    }



    getValue = () => {
        return this.state.name
    }

    render() {

        return (
            <div style={{
                flex: 1,
                position: 'relative'
            }} >
                <FloatInput 
                    title={this.props.title} 
                    handleRef={ref => this.parent = ref} 
                    placeholder={this.props.placeholder}
                    onChange={val => {
                    this.onSearch(val);
                    }} 
                    />


                {
                    this.state.suggestion.length > 0 ?
                        <div style={{
                            position: 'absolute',
                            top: 65,
                            border: '1px solid var(--border-color)',
                            height: 250,
                            backgroundColor: '#fff',
                            width: '100%',
                            zIndex: 1,
                            overflowY: 'auto'
                        }}>
                            {this.state.suggestion.map((item, index) => (
                                <div key={index} style={{
                                    height: 50,
                                    borderBottom: '1px solid var(--border-color)',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingLeft: 10,
                                    fontSize: 12,
                                    flexDirection: 'column',
                                    backgroundColor: '#fff',

                                }} onClick={() => {

                                    this.setState({
                                        ...this.state,
                                        suggestion: [],
                                        name: item
                                    })
                                    this.parent.setValue(item);
                                }}>
                                    <p style={{
                                        fontSize: 15,
                                    }}>{item}</p>

                                </div>
                            ))}

                        </div>
                        :
                        null
                }

            </div >
        )
    }
}


export { SearchExistingWbsItemN }