import { useEffect, useRef, useState } from "react";
import {useParams} from 'react-router-dom'
import { get, post } from "../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../Common/PageLoader";
import {AccessButton, Button} from '../../../../Common/Button/index';
import { RightModal } from "../../../../Common/Modal/RightModal";
import { Attachment } from "../../../../Common/Attachment";
import { SearchDrawingCategory } from "../../../../Common/Search/SearchDrawingCategory";
import { showToast } from "../../../../Common/Toast";
import { MultipleWbsItem } from "../../../../Common/Search/MultipleWbsItem";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { useProject } from "../../../../Hooks/ProjectDetails";
import {PaginationTable} from '../../../../NCommon/Tables/PaginationTable';
import { List } from "../../../../NCommon/Ui/List";
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";
import Edit from "./Edit";
import Show from '../Show';


const Index = () => {

    const [loading,setLoading] = useState(true);
    const [addDrawing,setAddDrawing] = useState(false);
    const [show,setShow] = useState(false);
    const [edit,setEdit] = useState(false);
    const details = useProject();
    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    const {id} = useParams();

    let Title=useRef(), DrawingNumber=useRef(),RevisionNumber=useRef(),RelatedDrawingId=useRef(),attachment=useRef(),DrawingCategory=useRef(),DrawingSubCategory=useRef(),wbs=useRef();

    useEffect(()=>{
        next();
    },[])

    let next = (data) => {
        if(!data) {
           data = {
               page:0
           }
        }
        data ={...data};
        data.size = '50';
        data.offset =data.page.toString() ? (data.page*50).toString():'0'
        get(`project/drawing/allDrawings/${id}`,(e,r)=>{
           if(r) {
               setMData({
                  page:data.page,
                  total:r.total?r.total:data.total,
                  items:r.drawings
               })
               setLoading(false);
           }
       },data)
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div style={{flex:1}}>
                    <p className="table-title">{item.title?item.title:'-'}</p>
                </div>
                <div style={{flex:1}}>
                    <p className="table-text">{item.drawingCategory}</p>
                </div>
                <div style={{flex:1}}>
                    <p className="table-top">{item.subCategory?item.subCategory:'-'}</p>
                </div>
                <div style={{flex:1}}>
                    <p className="table-top">{item.revisionNumber}</p>
                </div>
                <TableMenu items={[{name:'View',onClick:()=>{setShow(item)}},{name:'Edit',onClick:()=>setEdit(item)}]} />
            </List>
        )
    }


    if(loading)  {
        return <PageLoader />
    }

    return(
        <>
            <HeaderWrapper  hideCard={true} />
            <PageWrapper defaultView={'list'} id="pagination-table-header" scroll={true}>
                <NavigationWrapper items={[{name:"All Project",path:'/app/project'},{name:`${details.name}`,path:`/app/project/mapping/${details.id}`},{name:'Drawing'}]}>
                    <AccessButton text="+ Add Drawing" inverted={true?1:0} onClick={()=>{setAddDrawing(true)}}/>
                </NavigationWrapper>
                <PaginationTable
                      DATA={mData.items}
                      total={mData.total}
                      page={mData.page}
                      mapCardLayout={()=>{}}
                      mapListLayout={mapListLayout}
                      next={(page)=>{
                        next(page)
                      }}
                      defaultView={'list'}
                      headers={[
                        {
                            name:'Drawing Number',
                            flex:1
                        },
                        {
                            name:'Category',
                            flex:1
                        },
                        {
                            name:'Sub Category',
                            flex:1
                        },
                        {
                            name:'Revision Number',
                            flex:1
                        },
                        {
                            name:'',
                            width:60
                        }]}
                />
            </PageWrapper>

            {show? <Show item={show} onClose={()=>{setShow(false)}} />:null}
            {
                addDrawing ?
                <RightModal
                    title="Add Drawing"
                    desc="Fill up the details and add drawing"
                    onClose={()=>{
                        setAddDrawing(false);
                    }}
                    show={addDrawing}
                >
                    <FunctionalInput title="Title" type="text"  ref={Title}  />
                    <MultipleWbsItem projectId={id} title="Wbs Item (select one or more)" handleRef={ref=>wbs.current=ref} />
                    <FunctionalInput title="Drawing Number" type="text"  ref={DrawingNumber}  />
                    <SearchDrawingCategory title="Category" projectId={id} handleRef={ref=>DrawingCategory.current=ref} />
                    <FunctionalInput title="Sub Category" type="text" ref={DrawingSubCategory} />
                    <FunctionalInput title="Revision Number" type="text" ref={RevisionNumber} />
                    <FunctionalInput title="Related Drawing Number (optional)" placeholder="Enter the related drawing number" type="text" ref={RelatedDrawingId} />
                    <Attachment  addTitle={true} handleRef={ref=>attachment.current=ref} />
                   
                    <Button text = "Add Drawing" style={{marginTop:20}} onClick={()=>{
            

                        let title = Title.current.value;
                        let w = wbs.current.getValue().map(val=>val.value)
                        let drawingNumber = DrawingNumber.current.value;
                        let drawingCategory = DrawingCategory.current.getValue();
                        let subCategory = DrawingSubCategory.current.value;
                        let revisionNumber = RevisionNumber.current.value;
                        let relatedDrawingId = RelatedDrawingId.current.value ?+RelatedDrawingId.current.value:null;
                        let files = attachment.current.getFiles().map((val)=>{
                            return {title:val.title,url:val.url}
                        })

                        if(!title) {
                            showToast({
                                type:'error',
                                message:"Please enter the title"
                            })
                            return
                        }

                        if(w.length === 0) {
                            showToast({
                                type:'error',
                                message:"Please select the wbs item"
                            })
                            return;
                        }
                        if(!drawingNumber) {
                            showToast({
                                type:'error',
                                message:"Please enter the drawing number"
                            })
                            return
                        }

                        if(!drawingCategory) {
                            showToast({
                                type:'error',
                                message:'Please enter the drawing category'
                            })
                            return
                        }
                        if(!subCategory) {
                            showToast({
                                type:'error',
                                message:'Please enter the drawing sub category'
                            })
                            return
                        }


                        if(!revisionNumber) {
                            showToast({
                                type:'error',
                                message:'Please enter the revision number'
                            })
                            return
                        }

                      

                        if(files.length ===0) {
                            showToast({
                                type:'error',
                                message:"Please select atleast one drawing"
                            })
                            return
                        }

                        let data = {
                            title,
                            drawingCategory:drawingCategory.name,
                            drawingNumber,
                            files,
                            relatedDrawingId,
                            revisionNumber,
                            file:files[0].url,
                            wbsItems:w,
                            projectId:+id,
                            subCategory
                        }

                        post(`project/drawing/add`,data,(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:"Drawing added successfully"
                                })
                                next();
                                setAddDrawing(false);
                            }
                            if(e) {
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                    }} />

                </RightModal>: null
            }
            {
                edit ?
                <Edit 
                 item={edit}
                 projectId={id}
                 onClose={()=>{
                    setEdit(false);
                }} 
                init={()=>{
                    next()
                }}
                
                />:
                null
            }
        </>
    )
}


export default Index; 