import {useHistory} from 'react-router-dom';

const NavigationWrapper = (props) => {


    let HISTORY = useHistory();
    let mapNavigation = (item,index) => {
        let style = {fontWeight:'600',cursor:'pointer',fontSize:15,paddingRight:5};
        let name = `${item.name}`
        if((index+1)===props.items.length) {
            style.color = 'var(--text-light-color)';
        } else {
            name = `${item.name} /`
        }
        return(
            <div onClick={item.path?()=>{HISTORY.push(item.path)}:null} key={index} style={style}>
                {name}
            </div>
        )
    }
    return(
        <div style={{height:50,display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <div style={{display:'flex',marginLeft:5}}>
                {props.items.map(mapNavigation)}
            </div>
            <div style={{display:'flex'}}>            
              {props.children}
            </div>
        </div> 
    )
}

NavigationWrapper.defaultProps = {
    items:[]
}

export {NavigationWrapper}