

const ShownFilter = ({items,onRemove}) => {

    function trimLabel(val) {
       return val
    }

    if(!items.length) {
        return <></>
    }
    return(
        <div 
            className="flex-v-center" 
            style={{
                background:'#fff',
                padding: "5px 10px",
                flexWrap: "wrap"
            }}
        >
            {items.map((val,index)=>{
                return(
                    <span 
                        key={index} 
                        style={{
                            border:'1px solid var(--theme-main-color)',
                            marginRight:10,
                            marginBottom: 5,
                            borderRadius:4,
                            fontSize:13,
                            padding:'5px 10px',
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <span style={{fontWeight:600, fontSize: 11  }}>{val.type}</span>:&nbsp;
                            <span>{val.label? trimLabel(val.label) : trimLabel(val.value)}</span>
                        </span>
                        <span onClick={()=>{onRemove(val)}} style={{ cursor:'pointer',fontWeight:'bold', fontSize: 18}}>&times;</span>
                    </span>
                )
            })}
        </div>
    )
}

ShownFilter.defaultProps = {
    items:[]
}
export {ShownFilter}