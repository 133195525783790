import { memo } from "react";
import Moment from 'moment';
import { BarGraph } from "../../../../../Common/Graphs";
import Container from "../../../../../Common/Graphs/Container";
import Gauge from "../../../../../Common/Graphs/GaugeChart";


const Index = memo(({data}) => {


    let barGraph = (item,index) => {
        let tItem = data[item];
        let categories = [`Total`,"Completed","In Progress","Yet To Start","Should Have Started","Should Have Finished"]
        let items = []
        items.push(tItem["total"]);
        items.push(tItem["completed"]);
        items.push(tItem["total"]-tItem["completed"]-tItem["notStarted"]);
        items.push(tItem["notStarted"]);
        items.push(tItem["expectedStartedButNotStarted"]);
        items.push(tItem["expectedCompletedButNotDone"]);
        let onTime = 0;
        if(tItem.total===tItem.notStarted) {
            onTime=0;
        } else {
           onTime = 1-(tItem.delayed)/(tItem.total-tItem.notStarted)
        }
        return(
          <Container key={index} width={'50%'}>
               <BarGraph 
                    download={false}
                    height={350} 
                    title={item} 
                    categories={categories} 
                    data = {[          // Chart by Approver Person
                        {
                            name:'Tasks',
                            data :items,
                        },
                    ]} 
                    colors={["#0073ea","#03fc98","#ff6347","#33cccc"]}
                    distributed={false}
                    horizontal={false}
                    dataLabels={{
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                        offsetY: 0,
                        style: {
                            fontSize: '11px',
                            colors: ["#fff"]
                        }
                    }}
                    />  
                   <div style={{display:'flex',marginTop:20}}>
                    <div style={{width:'23%',textAlign:'center'}}>
                         <Gauge level={5} percent={(tItem.completed)/(tItem.total)} />
                         <p style={{fontSize:11,fontWeight:700}}>Percentage Completed</p>
                    </div>
                    <div style={{width:'23%',textAlign:'center'}}>
                         <Gauge level={5} percent={(tItem.expectedCompletedButNotDone)/(tItem.total)} />
                         <p style={{fontSize:11,fontWeight:700}}>Expected Completed</p>
                    </div>
                    <div style={{width:'23%',textAlign:'center'}}>
                         <Gauge level={5} percent={onTime} />
                         <p style={{fontSize:11,fontWeight:700}}>on Time Performance</p>
                    </div>
                    <div style={{width:'31%',textAlign:'center',fontSize:12}}>
                        <span style={{background:'var(--background-color)',padding:'5px 10px',display:'inline-block',borderRadius:12,border:'1px solid var(--border-color)'}}>Original Start Date : {Moment(tItem["minOriginalDate"]).format('DD-MM-YYYY')}</span><br></br>
                        <span style={{background:'var(--background-color)',padding:'5px 10px',display:'inline-block',borderRadius:12,border:'1px solid var(--border-color)',marginTop:5}}>Original End Date : {Moment(tItem["maxOriginalDate"]).format('DD-MM-YYYY')}</span><br></br>
                        <span style={{background:'var(--background-color)',padding:'5px 10px',display:'inline-block',borderRadius:12,border:'1px solid var(--border-color)',marginTop:5}}>Estimated Start Date : {Moment(tItem["minEstimatedDate"]).format('DD-MM-YYYY')}</span><br></br>
                        <span style={{background:'var(--background-color)',padding:'5px 10px',display:'inline-block',borderRadius:12,border:'1px solid var(--border-color)',marginTop:5}}>Estimate End Date : {Moment(tItem["maxEstimatedDate"]).format('DD-MM-YYYY')}</span><br></br>
                    </div>
                </div>
         </Container>
        )
    }

    return(
        <div className="flex" style={{flexWrap:'wrap'}}>
            {Object.keys(data).map(barGraph)}
        </div>
    )
})

export default Index;