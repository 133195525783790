
import { useEffect, useRef, useState } from 'react';
import { ReadAccess } from '../../../Common/Button';
import './index.css';


const TableMenu = (props) => {

    let REF = useRef();

    const [show,setShow] = useState(false);

    useEffect(()=>{
        window.addEventListener('click',handleClick);
        return () =>{
            window.removeEventListener('click',handleClick)
        }
    },[])


    let handleClick = (e) => {
        if(!show  && REF.current && !REF.current.contains(e.target)) {
            setShow(false);
        }
    }

    let  mapItem = (item,index) => {
        if(item.read) {
         <ReadAccess  key={index}>
            <div onClick={item.onClick} className="menu-list">
                {item.name}
            </div>
          </ReadAccess>
        }
        return(
            <div key={index} onClick={item.onClick} className="menu-list">
                {item.name}
            </div>
        )
    }

    return(
        <div className='table-list' style={{position:'relative',height:'inherit',display:'flex',justifyContent:'center',alignItems:'center',width:50}} ref={REF}>
            <span className='single-combine' style={{display:'flex',alignItems:'center',cursor:'pointer',borderRadius:4,padding:'8px 4px'}}>
                <span className="single-dot" />
                <span className="single-dot" />
                <span className="single-dot" />
            </span>
            <div className={`menu`}>
                {props.items.length>0?props.items.map(mapItem):<></>}
            </div>
            
            
        </div>
    )
}

TableMenu.defaultProps = {
    items:[]
}

export {TableMenu}