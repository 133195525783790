import { useEffect, useRef, useState } from "react";
import { post } from "../../../../Model/Network/Config/Axios";
import { Button } from "../../../Common/Button";
import { FunctionalInput } from "../../../Common/FloatInput/FunctionalInput";
import { showToast } from "../../../Common/Toast";
import { MainModal } from "../../../NCommon/Modal";




const Index = (props) => {


    let NAME = useRef(),CODE=useRef(),EMAIL=useRef(),MOBILE=useRef();
    let [data,setData] =  useState({})

    useEffect(()=>{
        if(props.open) {
            let item = props.open;
            NAME.current.value = item.name;
            CODE.current.value = item.vendorCode;
            EMAIL.current.value = item.email;
            MOBILE.current.value = item.mobile;
        }

    },[props.open])


    let update = () => {
        let code = CODE.current.value;
        let name = NAME.current.value;

       

        if(!code) {
            showToast({
                type:'error',
                message:'Please enter the vendor code'
            })
            return;
        }
       
        if(!name) {
            showToast({
                type:'error',
                message:'Please enter the vendor name'
            })
            return;
        }

        post(`vendor/update/${props.open.vendorMappingId}`,{...data},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Vendor Update Successfully"
                })
                props.close();
                props.init();
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }

    return(
        <MainModal
            title={props.open?props.open.name:'-'}
            open={props.open}
            close={props.close}
        >
            <FunctionalInput title="Vendor Name" ref={NAME} onChange={(name)=>{
                setData({
                    ...data,
                    name
                })
            }} />
            <FunctionalInput title="Code" ref={CODE} onChange={(code)=>{
                 setData({
                    ...data,
                    code
                })
            }} />
            <FunctionalInput title="Email" ref={EMAIL} onChange={(email)=>{
                 setData({
                    ...data,
                    email
                })
            }} />
            <FunctionalInput title="Mobile" ref={MOBILE} onChange={(mobile)=>{
                 setData({
                    ...data,
                    mobile
                })
            }} />
            <Button text="Update" style={{marginTop:20}} onClick={update} />
        </MainModal>
    )
}

export default Index;