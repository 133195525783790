import  { useEffect, useState } from 'react';
import {deleteApi, get, post } from '../../../../../Model/Network/Config/Axios';
import {AccessButton, Button} from '../../../../Common/Button';
import { PageLoader } from '../../../../Common/PageLoader';
import AddUser from './AddUser';
import Edit from './AddUser/Edit';
import Moment from 'moment';
import { HeaderWrapper } from '../../../../NCommon/HeaderWrapper';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import { Table } from '../../../../NCommon/Tables/index';
import { Card } from '../../../../NCommon/Ui/CardLayout';
import { List } from '../../../../NCommon/Ui/List';
import { TableMenu } from '../../../../NCommon/Tables/TableMenu';
import { showToast } from '../../../../Common/Toast';
import { Modal } from '../../../../Common/Modal/Modal';




const Active = () => {
    

    const [loading,setLoading] = useState(true);
    const [users,setUsers] = useState([]);
    const [add,setAdd] = useState(false);
    const [edit,setEdit] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);

    useEffect(()=>{
      INIT();
    },[])

    let INIT = () => {
        get(`setting/accessManagement/allUsers`,(e,r)=>{
            if(r) {
                setUsers(r.users);
                setLoading(false);
            }
        })
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card  
                items={[{name:'Edit',read:true,onClick:()=>{setEdit(item)}},{name:"DeActivate",read:true,onClick:()=>{setDeleteModal(item)}}]} 
                key={index} 
                title={item.name} 
                desc={item.mobile}>
                <div style={{marginLeft:0}}>
                    <p className="tiny-text">{item.email}</p>
                    <p className="tiny-text">Last Active At : {Moment(item.lastActiveAt).format('DD-MM-YYYY hh:mm:ss a')}</p>
                </div>
            </Card>
        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.mobile}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.email}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{Moment(item.lastActiveAt).format('DD-MM-YYYY hh:mm:ss a')}</p>
                </div>
                <TableMenu items={[{name:'Edit',read:true,onClick:()=>{setEdit(item)}},{name:"DeActivate",read:true,onClick:()=>{setDeleteModal(item)}}]} />
            </List>
        )
    }

   
    return(
        <>
            <HeaderWrapper />
            {
                !loading ?
                <PageWrapper>
                    <NavigationWrapper items={[{name:`Active User (${users.length})`}]}>
                        <AccessButton text="Add User" inverted={true?1:0} onClick={()=>{setAdd(true)}} />
                    </NavigationWrapper>
                    <Table
                        DATA={users}
                        mapCardLayout={mapCardLayout}
                        mapListLayout={mapListLayout}
                        headers={[
                            {
                                name:'Details',
                                flex:1
                            },
                            {
                                name:'Email',
                                flex:1
                            },
                            {
                                name:"Last Active At",
                                flex:1
                            },
                            {
                                name:'actions',
                                width:50
                            }
                        ]}
                        
                    />
                </PageWrapper>
                :<PageLoader />
            }
            <AddUser onClose={setAdd} open={add} init={()=>{setAdd(false);INIT()}} />
            <Edit onClose={setEdit} item={edit} init={()=>{setEdit(false);INIT()}} />

            {
                    deleteModal ?
                        <Modal
                            title={`Delete User`}
                            desc={`Delete ${deleteModal.name}`}
                            show={deleteModal}
                            onClose={() => {
                                setDeleteModal(false);
                            }}
                            style={{
                                minHeight: 150
                            }}
                        >
                            <p style={{
                                marginTop: 50
                            }}>Are you sure want to delete <span style={{
                                fontWeight: 600
                            }}>
                                    {deleteModal.name}
                                </span> ? </p>

                            <Button
                                text="Delete"
                                style={{
                                    marginTop: 50,
                                    backgroundColor: 'var(--red-color)',
                                    borderColor: 'var(--red-color)'
                                }}
                                onClick={() => {
                                    setButtonLoading(true);
                                    deleteApi(`setting/accessManagement/remove/${deleteModal.id}`,(e,r)=>{
                                            showToast({
                                                type:'success',
                                                message:'User Deactive successfully'
                                            })
                                            INIT();
                                            setDeleteModal(false);
                                            setButtonLoading(false);
                                        
                                    })
                                }}
                                loading={buttonLoading}
                            />
                        </Modal>
                        : null

                }
        </>
    )
}

export default Active;