import { SimpleFlex } from "../../../../Common/Flex/SimpleFlex";
import { KeyValue } from "../../../../Common/KeyValue";


const Index = ({details}) => {
    
    return(
        <div>
            <SimpleFlex>
                <KeyValue title="Name" desc={details.name} style={{flex:1}} />
                <KeyValue title="Description" desc={details.description} style={{flex:1}} />
            </SimpleFlex>
            <SimpleFlex>
                <KeyValue title="Resource Type" desc={details.resourceType} style={{flex:1}} />
                <KeyValue title="Resource Sub Type" desc={details.subType} style={{flex:1}} />
            </SimpleFlex>
            <SimpleFlex>
                <KeyValue title="Category" desc={details.category} style={{flex:1}} />
                <KeyValue title="Units" desc={details.code} style={{flex:1}} />
            </SimpleFlex>
            <SimpleFlex>
                <KeyValue title="Total Quantity" desc={details.totalQty} style={{flex:1}} />
                <KeyValue title="Total Amount" desc={details.totalAmount} style={{flex:1}} />
            </SimpleFlex>
            <SimpleFlex>
                <KeyValue title="Total Pending Quantity" desc={details.totalPendingQty} style={{flex:1}} />
                <KeyValue title="Total Acquired Quanity" desc={details.totalAcquiredQty} style={{flex:1}} />
            </SimpleFlex>
        </div>
    )
}
export default Index;