import React from 'react';
import { KeyValue } from '../../../Common/KeyValue';
import { Layout } from '../../../Common/Layout';
import Moment from 'moment';


const TaskOpen = (props) => {
    let { item } = props;
    return (
        <div>
            {props.children}
            <Layout>

                <div style={{
                    flex: 5,
                    display: 'flex',
                    marginBottom: 15
                }}>
                    <KeyValue title="Task Name" desc={item.task.name} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Task Details" desc={item.task.details} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Task Type" desc={item.task.taskType} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Work Started" desc={item.workStarted ? "Yes" : "No"} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Work Finished" desc={item.workDone ? "Yes" : "No"} style={{
                        flex: 1
                    }} />
                </div>
                <div style={{
                    flex: 5,
                    display: 'flex',
                    marginBottom: 15
                }}>

                    <KeyValue title="Wbs Item Mapping" desc={item.wbsItemMapping.name} style={{
                        flex: 1
                    }} />
                    <KeyValue title="+/- days wrt predecessor" desc={item.lagDay} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Total Days Required" desc={item.totalDaysRequired} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Reponsible Person" desc={item.responsiblePerson.name} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Reponsible Person Code" desc={item.responsiblePerson.code} style={{
                        flex: 1
                    }} />
                </div>

                <div style={{
                    flex: 5,
                    display: 'flex',
                    marginBottom: 15
                }}>

                    <KeyValue title="Planned Start Date" desc={Moment(item.plannedStartDate).format('DD-MM-YYYY')} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Planned END Date" desc={Moment(item.plannedEndDate).format('DD-MM-YYYY')} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Actual Start Date" desc={item.actualStartDate ? Moment(item.actualStartDate).format('DD-MM-YYYY') : '-'} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Actual End Date" desc={item.actualEndDate ? Moment(item.actualEndDate).format('DD-MM-YYYY') : '-'} style={{
                        flex: 1
                    }} />
                    <KeyValue title="Approver Name" desc={item.approver ? item.approver.name : '-'} style={{
                        flex: 1
                    }} />
                </div>

                <div style={{
                    flex: 5,
                    display: 'flex',
                    marginBottom: 15
                }}>

                    <KeyValue title="Approver Code" desc={item.approver ? item.approver.code : '-'} style={{
                        flex: 1
                    }} />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </Layout>
        </div>
    )
}

export default TaskOpen;