import  { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { AccessButton, Button } from '../../../Common/Button';
import AddAdhoc from './AddAdhoc';
import { Modal } from '../../../Common/Modal/Modal';
import { get,axios } from '../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../Common/PageLoader';
import { showToast } from '../../../Common/Toast';
import { useProject } from '../../../Hooks/ProjectDetails';
import { Header } from '../../../NCommon/Header';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { PageWrapper } from '../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import {Table} from '../../../NCommon/Tables/index';
import { Card } from '../../../NCommon/Ui/CardLayout';
import { List} from '../../../NCommon/Ui/List';
import { TableMenu } from '../../../NCommon/Tables/TableMenu';


const Index = () => {

    const [add,setAdd] = useState(false);
    const [loading,setLoading] = useState(false);
    const [allTasks,setAllTasks] = useState([]);
    const [deletemodal,setDeleteModal] = useState(false);
    const project = useProject(); 
    const {id} = useParams();


    useEffect(()=>{
        init();
    },[])

    let init = () => {
        get(`project/availableCustomAdhocTaskMapping/${id}`, (e, r) => {
            if (r) {
                setLoading(false);
                setAllTasks(r.mappedCustomAdhocTasks);
            }
        })
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card isOpen={true} height={140} onDelete={(e)=>{e.stopPropagation();setDeleteModal(item)}} key={index} title={item.name} desc={`Lag Day : ${item.lagDays}`}>
                <div style={{fontWeight:'400',fontSize:12,color:'var(--text-light-color)'}}>
                    <p>Required Days : {item.totalRequiredDays} days</p>
                    <p>Responsible: {item.responsiblePerson?item.responsiblePerson.name:'-'}</p>
                    <p>Approver : {item.approver?item.approver.name:'-'}</p>
                </div>
            </Card>
        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">Lag Day : {item.lagDays} days</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.totalRequiredDays} days</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.responsiblePerson?item.responsiblePerson.name:'-'}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.approver?item.approver.name:'-'}</p>
                </div>
                <TableMenu items={[{name:'Delete',read:true,onClick:()=>{setDeleteModal(item)}}]} />
            </List>
        )
    }

    if (loading) {
            return (<PageLoader />)
      }

    return (
            <>
                <Header title={`Adhoc Configration`} />
                <HeaderWrapper />
                <PageWrapper>
                    <NavigationWrapper items={[{name:"All Project",path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`Adhoc Configuration (${allTasks.length})`}]}>
                        <AccessButton text="Create Adhoc Task" inverted={true?1:0} addsign={true?1:0} onClick={()=>{setAdd(true)}} />
                    </NavigationWrapper>
                    <Table
                        DATA={allTasks}
                        mapCardLayout={mapCardLayout}
                        mapListLayout={mapListLayout}
                        headers={[
                            {
                                name: 'Task Name',
                                flex: 1,
                            },
                            {
                                name: 'Total Required Day',
                                flex: 1,
                            },
                            {
                                name: 'Responsible Person',
                                flex: 1,
                            },
                            {
                                name: 'Approver Person',
                                flex: 1,
                            },
                            {
                                name: 'actions',
                                width: 50
                            }
                        ]}
                    />
                </PageWrapper>
                {
                    add ?
                        <AddAdhoc
                            onComplete={() => {
                                setAdd(false);
                                init();
                             }}
                            onClose={()=>{setAdd(false)}}
                            projectId={id}

                            />
                        : null
                }

                {
                    deletemodal ?
                    <Modal
                        show={deletemodal}
                        title="Delete Adhoc Type"
                        desc={``} 
                        onClose={()=>{
                            setDeleteModal(false);
                        }}
                        style={{
                            minHeight:180
                        }}
                        >
                        <p style ={{marginTop:40}}>Are you sure want to delete {deletemodal.taskName} ?</p>
                        <Button text="Delete" style = {{
                            marginTop:40,
                            backgroundColor:'var(--red-color)',
                            borderColor:'var(--red-color)'
                        }} onClick={()=>{
                            axios.delete(`project/removeAdhoc/${id}?type=${deletemodal.type}`)
                            .then((r)=>{
                                if(r) {
                                    showToast({
                                        type:'success',
                                        message:'Adhoc task deleted successfully'
                                    })
                                    init();
                                    setDeleteModal(false);
                                }
                            })
                        }}/>
                    </Modal>
                    : null
                }
            </>
        )
}

export default Index;