import { useEffect, useRef, useState } from 'react';
import { useParams} from 'react-router-dom';
import Moment from 'moment';
import {DateRangePicker} from 'react-date-range';
import { AccessButton, Button } from '../../../../Common/Button';
import { useProject } from "../../../../Hooks/ProjectDetails";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import AddTemplate from './AddTemplate';
import 'react-date-range/dist/theme/default.css'; // theme css file
import { SearchSelect } from '../../../../Common/Search/SearchSelect/SearchSelect';
import { get } from '../../../../../Model/Network/Config/Axios';
import { showToast } from '../../../../Common/Toast';
import { DownloadDirectCsv } from '../../../../../Utils/Downloader';


const Index = () => {
    
    let project = useProject();
    let {id} = useParams();
    const [show,setShow] = useState(false);
    const [value, setValue] = useState({startDate:Moment().subtract(30,'days').toDate(),endDate:Moment().add(60,'days').toDate(),key:'selection'});
    const [items,setItems] = useState([]);
    const [loading,setLoading] = useState(false)
    let TEMPLATE = useRef(),TYPE=useRef();

    useEffect(()=>{
        INIT();
    },[])

    let INIT = () => {
        get(`project/export/taskExport/${id}`,(e,r)=>{
            if(r) {
                setItems(r.excels.map(val=>({key:val.id,value:val.title})))
            }
        })
    }

    let onChange = ({selection}) => {
        setValue(selection)
    }


    let add = () => {
        let tempId = TEMPLATE.current.getValue();
        let type = TYPE.current.getValue();
        if(!tempId) {
            showToast({
                type:'error',
                message:'Please select the template'
            })
            return
        }
        if(!type) {
            showToast({
                type:'error',
                message:"Please select the type"
            })
            return;
        }
        setLoading(true);
        get(`project/export/taskExport/${id}/csv?template=${tempId.key}&type=${type}&start=${Moment(value.startDate).valueOf()}&end=${Moment(value.endDate).valueOf()}`,(e,r)=>{
            if(r) {
                DownloadDirectCsv("Report.csv",r);
                setLoading(false);
            }
        })
    }


    return(
     <>
        <PageWrapper style={{height:'calc(100vh - 55px)'}}>
            <NavigationWrapper items={[{name:`All Project`,path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`Template`}]}>
                <AccessButton inverted={true?1:0} onClick={()=>{setShow(true)}} text="Add Template" addsign={true?1:0} />
            </NavigationWrapper>
            <div className='title-container-1'>
                <SearchSelect ref={TEMPLATE} items={items} title="Select Template" style={{marginBottom:20}} />
                <SearchSelect ref={TYPE} items={[{key:'creation',value:'creation'},{key:'planned',value:'planned'},{key:'original',value:'original'}]} title="Select Date Type" style={{marginBottom:20}} />
                <p style={{fontSize:14,marginBottom:10,fontWeight:'600'}}>Select Date Range</p>
                <DateRangePicker 
                    className="border-daterange"
                    onChange={onChange}
                    ranges={[value]}
                />
                <Button loading={loading} onClick={add} text="Export Report" style={{width:350,marginTop:20}} />
            </div>
        </PageWrapper>
        <AddTemplate init={()=>{INIT()}} show={show} close={()=>{setShow(false)}} />
    </>
    )
}

export default Index;