import {  useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import { post } from "../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../Common/PageLoader";
import { HeaderWrapper } from "../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../NCommon/PageWrapper";
import { useProject } from "../../../Hooks/ProjectDetails";
import { Table } from "../../../NCommon/Tables/index";
import { List } from "../../../NCommon/Ui/List";
import ViewDetails from './ViewDetails';
import { TaskDateFilter } from "../../../NCommon/DateFilters/TaskDateFilter";
import { DownloadCsv } from "../../../../Utils/Downloader";

const Index = () => {

    const [loading,setLoading] = useState(true);
    const [filters,setFilters] = useState([]);
    const [data,setData] = useState([]);
    const [modal,setModal] = useState(false);
    const [dates,setDates] = useState(undefined);
    const [up,setUp] = useState(true);
    const [sorted,setSorted] = useState('QTY')

    let details = useProject();
    let {id} = useParams();

    const [status,setStatus] = useState([
         {
            name: "Progress",
            active: false,
            color: "#ebb840",
            key:'PROGRESS'
          },
          {
            name: "Acquired",
            active: false,
            color: "#404959",
            key:'ACQUIRED'
          },
          {
            name: "Not Started",
            active: false,
            color: "#ff944d",
            key:'NOTSTARTED'
          },
          {
            name: "Completed",
            active: false,
            color: "#68d083",
            key:'COMPLETED'
          },
          {
            name: "Critical",
            active: false,
            color: "#ea3a3c",
            key:'CRITICAL'
          },
    ])

    const [resourcesType,setResourceType] = useState([
        {
            name: "Labour",
            active: false,
            color: "#ebb840",
            key:'LABOUR'
          },
          {
            name: "Material",
            active: false,
            color: "#404959",
            key:'MATERIAL'
          },
          {
            name: "Machines",
            active: false,
            color: "#ff944d",
            key:'MACHINES'
          },
    ])
    

    useEffect(()=>{
            setLoading(true)
            INIT()
    },[dates,resourcesType,status,up])



    let INIT = (filter=[]) => {
        let data = {};
        let andFilters = [];
        status.forEach(val=>{
            if(val.active) {
                andFilters.push({key:val.key,val:''})
            }
        })
        if(filter.length) {
            data.orFilters=[...filter]
        }
        let types = data.orFilters?[...data.orFilters]:[];
        resourcesType.forEach(val=>{
            if(val.active) {
                types.push({key:'RESOURCECATEGORYTYPE',val:val.key})
            }
        })
        if(dates) {
            andFilters = [...andFilters,{key:'START',val:dates.startDate},{val:dates.endDate,key:'END'}]
        }
        data.orFilters = types;
        data.andFilters = andFilters
        
        post(`project/resourceView/resourceEvaluation/${id}`,data,(e,r)=>{
            if(r) {
                let resouces = r.resources.sort((a,b)=>{
                    if(up) {
                        return a.totalQty-b.totalQty;
                    } else {
                        return b.totalQty-a.totalQty;
                    }
                })
                setData(r.resources)
                setLoading(false);
            }
        })
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index} style={{height:70}} onClick={()=>{setModal(item)}}>
                <div style={{width:500}}>
                    <p className="table-title">{item.name}</p>
                </div>
                <div style={{width:200}}>
                    <p className="table-text">{item.category}</p>
                </div>
                <div style={{width:200}}>
                    <p className="table-text">{item.maxWastageRatio}</p>
                </div>
                <div style={{width:200}}>
                    <p className="table-text">{item.avgWastageRatio}</p>
                </div>
                <div style={{width:200}}>
                    <p className="table-text">{item.totalReceivedInventory} {item.units}</p>
                </div>
                <div style={{width:200}}>
                    <p className="table-text">{item.totalConsumedInventory} {item.units}</p>
                </div>
                <div style={{width:200}}>
                    <p className="table-text">{item.totalQty.toFixed(2)} {item.units}</p>
                </div>
                <div style={{width:200}}>
                     <p className="table-text">{item.balanceInventory} {item.units}</p>
                </div>
                <div style={{width:200}}>
                    <p className="table-text">INR {item.totalAmount.toFixed(2)}</p>
                </div>
                <div style={{width:200}}>
                    <p className="table-text">{item.resourceType}</p>
                </div>
                <div style={{width:200}}>
                    <p className="table-text">{item.subType}</p>
                </div>
                <div style={{width:300}}>
                    <p className="table-text">{item.description}</p>
                </div>
                <div style={{width:100}}>
                    <p className="table-text">{item.prPoLeadTime}</p>
                </div>
                <div style={{width:100}}>
                    <p className="table-text">{item.poGrnLeadTime}</p>
                </div>
            </List>
        )
    }

    let download = () => {
        let data = {};
        let andFilters = [];
        let filter = [];
        filters.forEach(item=>{
            filter.push({
                key:item.type,
                val:item.type === 'WBS'?item.id:item.value
              })
        })
        status.forEach(val=>{
            if(val.active) {
                andFilters.push({key:val.key,val:''})
            }
        })
        if(filter.length) {
            data.orFilters=[...filter]
        }
        let types = data.orFilters?[...data.orFilters]:[];
        resourcesType.forEach(val=>{
            if(val.active) {
                types.push({key:'RESOURCECATEGORYTYPE',val:val.key})
            }
        })
        if(dates) {
            andFilters = [...andFilters,{key:'START',val:dates.startDate},{val:dates.endDate,key:'END'}]
        }
        data.orFilters = types;
        data.andFilters = andFilters
        
        post(`project/resourceView/resourceEvaluation/${id}`,data,(e,r)=>{
            if(r) {
                let baseData = [
                    [
                        "Resource",
                        "Category",
                        "Proposed Waste",
                        "Averagge Waste Ratio",
                        "Total Quantity In",
                        "Total Quantity Out",
                        "Total Quantity",
                        "Total Inventory Qty",
                        "Amount",
                        "Resource Type",
                        "Resource Sub type",
                        "Resource Description",
                        "Pr Po Lead Time",
                        "Po Grn Lead Time"
                    ]
                ]

                r.resources.forEach(val=>{
                    baseData.push(
                        [
                            val.name,
                            val.category,
                            val.maxWastageRatio,
                            val.avgWastageRatio,
                            val.totalReceivedInventory,
                            val.totalConsumedInventory,
                            val.totalQty.toFixed(2),
                            val.balanceInventory,
                            val.totalAmount.toFixed(2),
                            val.resourceType,
                            val.subType,
                            val.description,
                            val.prPoLeadTime,
                            val.poGrnLeadTime
                        ]
                    )
                })

                DownloadCsv("Resource_overview.csv",baseData)


            }
        })
    }


    return(
        <>
            <HeaderWrapper 
                hideCard={true} 
                dynamicFilter={true} 
                types={["Resource","Resource Category","Wbs Mapping","Task","Responsible Person","Approver","Custom Text"]} 
                status={status}
                onChangeStatusFilter={setStatus}
                statusFilter={true}
                otherStatus={{
                    name:"Category",
                    items:resourcesType
                }}
                setOtherStatus={setResourceType}
                onFilterChange={items=>{
                    let filter = [];
                    items.forEach(item=>{
                        filter.push({
                            key:item.type,
                            val:item.type === 'WBS'?item.id:item.value
                          })
                    })
                    setFilters(items)
                    INIT(filter)
                }}
                filters={[...filters]}
                menuItem={[{name:'Download Csv',type:'downloadcsv',onClick:download}]}

            >
                <TaskDateFilter onSelect={setDates} />
            </HeaderWrapper>
            {
                loading ?
                <PageLoader />
                :
                <PageWrapper defaultView={'list'}  id="pagination-table-header" scroll={true}>
                <NavigationWrapper items={[{name:'All Projects',path:'/app/project'},{name:`${details.name}`,path:`/app/project/mapping/${id}`},{name:`Resource (${data.length})`}]}/>
                <Table 
                    DATA={data}
                    mapCardLayout={()=>{}}
                    defaultView={'list'}
                    mapListLayout={mapListLayout}
                    tableStyle={{
                        width:2800,
                        overflowX:'visible'
                    }}
                    headers={[
                       {
                           name:'Resource',
                           width:500,
                       },
                       {
                           name:'Category',
                           width:200
                       },
                       {
                           name:'Proposed Waste',
                           width:200
                       },
                       {
                           name:'Average Waste Ratio',
                           width:200
                       },
                       {
                           name:'Total Quantity In',
                           width:200
                       },
                       {
                            name:'Total Quantity Out',
                            width:200
                       },
                       {
                           name:'Total Quantity',
                           width:200,
                           sort:sorted==='QTY',
                           onSortClick:()=>{
                            setSorted('QTY');
                            setUp(!up)
                           },
                           up
                       },
                       {
                           name:'Total Inventory Qty',
                           width:200
                       },
                      
                       {
                           name:'Amount',
                           width:200
                       },
                       {
                           name:'Resource Type',
                           width:200
                       },
                       {
                           name:'Resource sub Type',
                           width:200
                       },
                       {
                           name:'Resource Description',
                           width:300
                       },
                       {
                           name:'Pr Po Lead Time',
                           width:100
                       },
                       {
                           name:'Po Grn Lead Time',
                           width:100
                       }
                    ]}
                />
            </PageWrapper>
            }
            {
                modal?
                <ViewDetails 
                    item={modal}
                    projectId={id}
                    close={()=>{setModal(false)}} 
                    />
                :null
            }
        </>
    )
}

export default Index;