import { useEffect, useRef, useState } from "react";
import { BottomHeader } from "../../../../../Common/TopHeader/BottomHeader";
import {AccessButton, Button} from '../../../../../Common/Button/index'
import { RightModal } from "../../../../../Common/Modal/RightModal";
import { FunctionalInput } from "../../../../../Common/FloatInput/FunctionalInput";
import { showToast } from "../../../../../Common/Toast";
import { post,get ,axios} from "../../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../../Common/PageLoader";
import { HeaderWrapper } from "../../../../../NCommon/HeaderWrapper";
import { PageWrapper } from "../../../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../../../NCommon/NavigationWrapper";


const Index = () => {

    const [add,setAdd] = useState(false);
    const [buttonloading,setButtonLoading] = useState(false);
    const [loading,setLoading] = useState(true);
    const [levels,setLevels] = useState([]);
    let NAME=useRef();


    useEffect(()=>{
      INIT();  
    },[])

    let INIT = () => {
        get(`/setting/miscConfig/getHierarchyLevel/RESOURCE`,(e,r)=>{
            if(r) {
                setLevels(r.hierarchyLevels);
                setLoading(false);
            }
        })
    }

   
    let mapItem = (item,index) => {
        return(
            <div key={index} style={{width:600,display:'flex',height:40,border:'1px solid var(--border-color)',fontSize:14}}>
                <div className="flex flex-v-center flex-h-center" style={{width:200,borderRight:'1px solid var(--border-color)'}}>
                    {item.rank}
                </div>
                <div className="flex flex-v-center flex-h-center" style={{width:200,borderRight:'1px solid var(--border-color)'}}>
                    {item.name}
                </div>
                <div onClick={()=>{
                     axios.delete(`setting/miscConfig/removeNewLevel/${item.hierarchyLevelId}`, {
                    })
                        .then(() => {
                            INIT();
                            showToast({
                                type: 'success',
                                message: "Key Remove successfully"
                            })
                        })

                
                }} className="flex flex-v-center flex-h-center" style={{color:'var(--red-color)',cursor:'pointer',width:200,fontWeight:600}}>
                      Delete
                </div>
            </div>
        )
    }

    if(loading) {
        return <PageLoader />
    }
    
    return(
       <>
       <HeaderWrapper showFilter={false}/>
       <PageWrapper>
           <NavigationWrapper items={[{name:'All Setting',path:'/app/setting'},{name:"Resource View"}]}>
                <AccessButton onClick={()=>{setAdd(true)}} inverted={true?1:0} addsign={true?1:0} text="Add" />
           </NavigationWrapper>
           <div className="title-container-1">
            <div style={{
                    width:600,
                    height:40,
                    display:'flex',
                    background:'var(--background-color)'
                }}>
                    <div className="flex flex-v-center flex-h-center" style={{width:200,fontWeight:600,height:'inherit',borderRight:'1px solid var(--border-color)'}}>
                        Index
                    </div>
                    <div className="flex flex-v-center flex-h-center" style={{width:200,fontWeight:600,height:'inherit',borderRight:'1px solid var(--border-color)'}}>
                        Value
                    </div>
                    <div className="flex flex-v-center flex-h-center" style={{width:200,height:'inherit'}}>

                    </div>
            </div>
            {levels.map(mapItem)}
        </div>
       </PageWrapper>


        
        {
            add?
            <RightModal
                title="Add Level"
                show={true}
                onClose={()=>{
                    setAdd(false)
                }}
            >
                <FunctionalInput title="Name" ref={NAME}  />
                <Button onClick={()=>{
                    let name = NAME.current.value;
                    if(!name) {
                        showToast({
                            type:'error',
                            message:"Please enter the name"
                        })
                        return;
                    }
                    setButtonLoading(true);
                    post(`setting/miscConfig/addNewLevel/RESOURCE`,{name},(e,r)=>{
                        if(r) {
                            showToast({
                                type:'success',
                                message:"Heirarchy added successfully"
                            })
                            setButtonLoading(false);
                            INIT();
                            setAdd(false);
                        }
                    })

                }} text="Add" loading={buttonloading} style={{marginTop:30}} />
            </RightModal>
            : null
        }
       </>
    )
}

export default Index;