import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import { post } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import { useProject } from '../../../../Hooks/ProjectDetails';
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import CommonRender from './CommonRender';

const Index = () => {

    const {id} = useParams();
    const project = useProject();
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [filters,setFilters] = useState([]);

    const [status,setStatus] = useState([
        {
            name: "By Resource",
            active: true,
            color: "#ebb840",
            key:'RESOURCE'
          },
          {
            name: "By Resource_c",
            active: false,
            color: "#ebb840",
            key:'RESOURCE_CATEGORY'
          },
          {
            name: "By Task",
            active: false,
            color: "#404959",
            key:'TASK'
          },
          {
            name: "By Task Cate.",
            active: false,
            color: "#ff944d",
            key:'TASK_CATEGORY'
          },
          {
            name: "By Wbs",
            active: false,
            color: "#68d083",
            key:'WBS'
          },
          {
            name:'By Vendor',
            active:false,
            color:'#ea3a3c',
            key:'VENDOR'
          },
          {
            name:'By Approver',
            active:false,
            color:'#389940',
            key:'APPROVER'
          },
          {
            name: "By Employee",
            active: false,
            color: "#f99e4d",
            key:'EMPLOYEE'
          },
          {
            name: "By Res. Vendor",
            active: false,
            color: "#68d083",
            key:'RESOURCE_VENDOR'
          },
         
    ])
    const [active,setActive] = useState('RESOURCE')


    useEffect(()=>{
        INIT({});
    },[active])


    let INIT = (data) => {
        setLoading(true);
        if(data.filter) {
            data.orFilters=[...data.filter];
            
            delete data.filter;
        }
        post(`/project/resourceView/resourceAnalytics/${id}`,{type:active,...data},(e,r)=>{
            if(r) {
                setData(r.tasks);
                setLoading(false);
            }
        })

    }

    useEffect(()=>{
        status.forEach(val=>{
            if(val.active) {
                setActive(val.key);
            }
        })

    },[status])


    return(
        <>
            <HeaderWrapper
               dynamicFilter={true}
               showFilter={false}
               statusFilter={true}
               status={status}
               onChangeStatusFilter={setStatus}
               singleSelect={true}
               types={["Name"]}
               filters={[...filters]}
               onFilterChange={items=>{
                let filter = [];
                items.forEach(item=>{
                    filter.push({
                        key:item.type,
                        val:item.value
                      })
                })
                setFilters(items)
                INIT({filter})
            }}
            />
            <PageWrapper>
                <NavigationWrapper items={[{name:"All Project",path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`${active} Type`}]} />
                {
                    loading ?
                    <PageLoader />
                    :
                    <>
                        <CommonRender data={data} />
                    </>
                }
            </PageWrapper>

        </>
    )
}

export default Index;