
const initalValue = () => {

        return {
            project:{}
         }
}



export const Filter = (state = initalValue(), action) => {



    switch (action.type) {

        case 'SET_PROJECT_DETAILS':
            return {
                project:{
                    ...action.r
                }
            }

        default:
            return state;
    }



}

