import { useEffect, useState } from "react";
import { get } from "../../../../../Model/Network/Config/Axios";
import { SimpleFlex } from "../../../../Common/Flex/SimpleFlex";
import { KeyValue } from "../../../../Common/KeyValue";
import { CenterModal } from "../../../../Common/Modal/CenterModal"
import { PageLoader } from "../../../../Common/PageLoader";


const Index = (props) => {

    const [details,setDetails] = useState({});
    const [loading,setLoading] = useState(true);

    useEffect(()=>{
        get(`resource/resourceDetails/${props.edit.id}`,(e,r)=>{
            if(r) {
                setDetails(r);
                setLoading(false);
            }
        })


    },[])
    return(
        <CenterModal
            onClose={props.onClose}
            header={props.edit.name}
        >
            {
                loading ?
                <PageLoader style={{marginTop:50}}/>
                : 
                <div style={{marginTop:70,padding:25}}>
                    <SimpleFlex>
                        <KeyValue title="Name" desc={details.name} style={{flex:1}} />
                        <KeyValue title="Description" desc={details.description} style={{flex:1}} />
                    </SimpleFlex>
                    <SimpleFlex>
                        <KeyValue title="Category" desc={details.category} style={{flex:1}} />
                        <KeyValue title="Glcode" desc={details.glCode?details.glCode:'-'} style={{flex:1}} />
                    </SimpleFlex>
                    <SimpleFlex>
                        <KeyValue title="HSN" desc={details.hsn ?details.hsn:'-'} style={{flex:1}} />
                        <KeyValue title="SKU" desc={details.sku?details.sku:'-'} style={{flex:1}} />
                    </SimpleFlex>
                    <SimpleFlex>
                        <KeyValue title="Resource Type" desc={details.resourceType} style={{flex:1}} />
                        <KeyValue title="subType" desc={details.subType} style={{flex:1}} />
                    </SimpleFlex>
                    <SimpleFlex>
                        <KeyValue title="Price" desc={details.price} style={{flex:1}} />
                        <KeyValue title="Gst Percentage" desc={details.gstRate} style={{flex:1}} />
                    </SimpleFlex>
                    <SimpleFlex>
                        <KeyValue title="Pr to po lead time" desc={`${details.prPoLeadTime} days`} style={{flex:1}} />
                        <KeyValue title="Po to grn lead time" desc={`${details.poGrnLeadTime} days`} style={{flex:1}} />
                    </SimpleFlex>
                    <SimpleFlex>
                        <KeyValue title="Material Type" desc={`${details.major?"Major Material":"Minor Material"}`} style={{flex:1}} />
                        <KeyValue title="Buffer lead time" desc={`${details.bufferLeadTime} days`} style={{flex:1}} />
                    </SimpleFlex>
                    <SimpleFlex>
                        <KeyValue title="Wastage Ratio" desc={`${details.wastageRatio}`} style={{flex:1}} />
                    </SimpleFlex>
                </div>
            }
        </CenterModal>
    )
}

export default Index;