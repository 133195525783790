import { useEffect, useRef, useState } from 'react';
import {Transition} from 'react-transition-group';
import { Portal } from '../../Common/Portal';
import { cross } from '../../Common/Images';
import './index.css';


const defaultStyle = {
    transition:`width 200ms ease-in-out`,
    width:0,
    position:'fixed',
    background:'#fff',
    height:'100vh',
    right:0,
    borderLeft:'0.5px solid var(--border-color)',
    zIndex:9

}


const MainModal = ({open,close,title,children,width}) => {

    let NodeRef = useRef(),ModalRef=useRef();

    const transitionStyle = {
        entering:{width:0},
        entered:{width},
        exited:{width:0}
    }
    
    useEffect(()=>{
       if(open) {
         window.addEventListener('click',handleClick);
         return () => {
             window.removeEventListener('click',handleClick);
         }
       }
    },[open])

    let handleClick = (e) => {
        if(ModalRef.current && !ModalRef.current.contains(e.target)) {
        }
    }

    return(
      <Portal>
        <Transition
            in={open}
            unmountOnExit
            ref={NodeRef}
            timeout={120}
        >
            {state=>(
                <div className="background-container">
                    <div ref={ModalRef} className="modal-container" style={{
                        ...defaultStyle,
                        ...transitionStyle[state]
                    }}>
                         <div style={{
                            height: 55,
                            borderBottom: '1px solid var(--border-color)',
                            padding: '10px 30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <h2 style={{fontWeight: 700,color: 'var(--text-color)',fontSize: 20}}>{title}</h2>
                            <div className="cross-hover" style={{
                                height: 36,
                                width: 36,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                position:'absolute',
                                left:-50,
                                top:10,
                                backgroundColor:"#fff",
                                borderRadius:'50%'
                            }} onClick={close} >
                                <img src={cross} alt="" style={{
                                    height: 14,
                                    width: 14,
                                    cursor: 'pointer',
                                    objectFit: 'contain'
                                }} />
                            </div>
                        </div>
                        <div style={{height:'100vh',overflowY:'scroll',padding:'10px 20px',paddingBottom:100}}>
                           {children}
                        </div>
                    </div>
                </div>
            )}
        </Transition>
    </Portal>
    )
}

MainModal.defaultProps = {
    open:false,
    width:600
}


export {MainModal}