import { useRef } from "react"
import { post } from "../../../Model/Network/Config/Axios";
import { MainModal } from "../../NCommon/Modal"
import { Button } from "../Button";
import { SearchTag } from "../Search/SearchTag"
import { showToast } from "../Toast";




const AddTag = ({type,referenceId,close,init}) => {

    let TAG=useRef();
    let add = () => {
        let tagId = TAG.current.getValue();
        let data = {
            type,
            referenceId:+referenceId,
            tagId:tagId.id
        }

        post(`setting/miscConfig/tags/addTagMapping`,data,(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:'Tag added successfully'
                })
                init();
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }

    return(
        <MainModal open={true} title="Add Tag" close={close}>
              <SearchTag ref={TAG} />
              <Button style={{marginTop:20}} text="Add" onClick={add} />
        </MainModal>    
    )
}

export {AddTag}