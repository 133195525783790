import { useEffect, useState } from "react"

const CheckBox = (props) => {

    const [check,setCheck] = useState(props.check?props.check:false);

    useEffect(()=>{
        if(props.onChange) {
            props.onChange(check)
        }
    },[check])

    return(
        <div onClick={()=>{
            setCheck(!check);
        }} style={{height:18,width:18,border:'2px solid var(--border-color)',...check?{background:'var(--theme-main-color)',borderColor:'var(--theme-main-color)'}:{}}} />
    )
}

export {CheckBox}