import React from 'react';
import { CSVLink } from "react-csv";
import Lottie from 'react-lottie';
import { axios } from '../../../Model/Network/Config/Axios';
import { showToast } from '../Toast';
import cross from './close.png';
import upload from './upload.png';



class Upload extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    _onChange = () => {
        let file = this.file.files[0];
        const fileSize = this.file.files[0].size / 1024 / 1024; // in MiB
        if(fileSize>50) {
            showToast({
                type:'error',
                message:"You cannot upload image more than 50 mb"
            })
            return
        } else {

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            this.setState({
                ...this.state,
                loading: true
            })

            axios({
                method: 'post',
                url: `https://api.buildrun.app/main/admin/${this.props.endPoint}`,
                data: formData
            })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    if (res) {
                        showToast({
                            type: 'success',
                            message: res.data.message
                        })
                        if(this.props.onDownload && res.data.failedTasks && res.data.failedTasks.length>0) {
                            this.props.onDownload(res.data.failedTasks)
                        }
                        this.props.onComplete();
                    }

                })
                .catch(res => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    showToast({
                        type: 'error',
                        message: res.data.message
                    })
                })
        } else {
            showToast({
                type: 'error',
                message: 'Please select a file'
            })
        }
        }
    }




    render() {
        let { title, desc } = this.props;
        return (
            <div style={{
                position: 'fixed',
                inset: 0,
                backgroundColor:'rgba(0,0,0,0.5)',
                zIndex:10
            }}>
              <div style ={{
              }}>
                <div style={{
                    margin: "0px auto",
                    marginTop: 40,
                    backgroundColor: '#fff',
                    minHeight: 350,
                    width: 600,
                    borderRadius: 16,
                    padding: 20,
                    position:'relative',
                    zIndex:10,
                    ...this.props.style
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <div>
                            <h2 style={{
                                fontWeight: 700,
                                color: 'var(--text-color)',
                                fontSize: 24
                            }}>{title}</h2>
                            <p style={{
                                fontSize: 13,
                                color: 'var(--text-color-light)',
                                fontWeight: 500
                            }}>{desc}</p>
                        </div>

                        <div className="cross-hover" style={{
                            height: 40,
                            width: 40,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.props.onClose();
                        }} >
                            <img src={cross} alt="" style={{
                                height: 16,
                                width: 16,
                                cursor: 'pointer',
                                objectFit: 'contain'
                            }} />
                        </div>
                    </div>

                    <input 
                        ref={ref => this.file = ref} 
                        type='file' 
                        hidden 
                        id="upload" 
                        onChange={this._onChange} 
                        accept={this.props.accept}
                        />
                    <div style={{
                        height: 200,
                        border: '2px dashed #dedede',
                        marginTop: 30,
                        borderRadius: 4,
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }} onClick={() => {
                        document.getElementById('upload').click()
                    }}>
                        {
                            this.state.loading ?
                                <div>
                                    <Lottie
                                        options={{
                                            autoplay: true,
                                            animationData: require('../Lotties/upload.json'),
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid slice"
                                            }
                                        }}
                                        width={'100%'}
                                        height={'100px'}

                                    />
                                </div>
                                :

                                <>
                                    <img src={upload} alt="" style={{
                                        height: 50,
                                        width: 50,
                                        marginBottom: 20
                                    }} />
                                    <div style={{
                                        backgroundColor:'var(--button-color)',
                                        color:'#fff',
                                        padding:'8px 20px',
                                        borderRadius:4,
                                        fontWeight:'bold'
                                    }}>
                                        Browse File
                                    </div>
                                    
                                </>
                        }

                    </div>


                {
                   this.props.islink ?
                    <CSVLink
                        filename={this.props.fileName}
                        style={{
                            textDecoration: 'none',
                            color: 'var(--button-color)',
                            paddingTop: 20,
                            display: 'block',
                            fontSize: 13,
                            textTransform: 'uppercase',
                            letterSpacing: 0.5,
                            fontWeight: 600
                        }}
                        data={this.props.csvData}>Download Sample Csv</CSVLink>
                    : null
                    }

                    {
                        this.props.islink  ?
                            <p style={{
                                marginTop: 10,
                                marginBottom: 10,
                                fontSize: 16,
                                fontWeight: 600
                            }}>OR</p> : null

                    }


                    {
                        this.props.islink ?
                            <a
                                href={`https://docs.google.com/spreadsheets/d/1dEIoj4jNzZgqvccCRRh3abPboLQ3HsryePvKoTLBbwU/edit#gid=265638345`}
                                style={{
                                    textDecoration: 'none',
                                    color: 'var(--primary-color)',
                                    fontSize:16
                                }}
                                target="_blank"

                            >
                                click on this link for more details
                            </a>
                            : null
                    }
                </div>
                </div>
            </div>
        )
    }
}


Upload.defaultProps = {
    csvData: [],
    accept:".jpg,.jpeg,.pdf,.png,.xlsx,.csv"
}


export { Upload };