import ReactDOM from "react-dom/client";
import './index.css';
import './n.css';
import App from './Controller';
import reportWebVitals from './reportWebVitals';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App />
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
