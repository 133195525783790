import React from 'react';
import { get, post } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';
import { showToast } from '../../Toast';



class SearchUnit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            suggestion: [],
            add:false,
            search:undefined
        }

    }



    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }

    }



    onSearch = (val) => {
        if (val.length > 0) {
            get(`resource/units`, (e, r) => {
                if (r) { 
                    if(r.units.length>0) {
                        this.setState({
                            ...this.state,
                            suggestion:r.units.map(val=>({
                                name:val.name,
                            })),
                            search:val,
                            name:undefined
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            suggestion:[],
                            search:val,
                            name:undefined
                        })
                    }
                   
                }
            }, {
                q: val
            })

        } else {
            this.setState({
                ...this.state,
                suggestion: [],
                search:val,
                name:undefined
            })
        }
    }

    setRef= (name) => {
        this.parent.setValue(name);
    }

    setValue = (name) => {
        this.parent.setValue(name);
        this.setState({
            name
        })
    }


    getValue = () => {
        return this.state.name ;
    }

    add = () => {
        let unit = this.parent.getValue();
        if(!unit) {
            showToast({
                type:'error',
                message:'Please enter the value of unit'
            })
            return
        }
        post(`resource/addUnit`,{ name:unit,desc:unit},(e,r)=>{
            if(r) {
                get(`resource/units`,(e,r)=>{
                    if(r) {
                       this.setState({
                           ...this.state,
                           name:r.units[0].name
                       })
                    }
                },{
                    q:unit
                })
    
            }
        })
    }

    render() {


        return (
            <div style={{
                flex: 1,
                position: 'relative'
            }} >
                <FloatInput title={this.props.title} handleRef={ref => this.parent = ref} onChange={val => {
                    this.onSearch(val);
                }} />
                {
                    this.state.suggestion.length > 0 ?
                        <div style={{
                            position: 'absolute',
                            top: 70,
                            border: '1px solid var(--border-color)',
                            height: 200,
                            backgroundColor: '#fff',
                            width: '100%',
                            zIndex: 1,
                            overflowY: 'scroll'
                        }}>
                            {this.state.suggestion.map((item, index) => (
                                <div key={index} style={{
                                    height: 40,
                                    borderBottom: '1px solid var(--border-color)',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: 10,
                                    fontSize: 14,
                                    backgroundColor: '#fff',
                                }} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        suggestion: [],
                                        name: item.name
                                    })
                                    if(this.props.onChange) {
                                        this.props.onChange(item.name);
                                    }
                                    this.parent.setValue(item.name);
                                }}>
                                    {item.name}
                                </div>
                            ))}

                        </div>
                        :
                        (this.state.search && !this.state.name && this.state.search.length) >0 ?
                        <div style ={{
                            position: 'absolute',
                            top: 65,
                            border: '1px solid var(--border-color)',
                            height: 100,
                            backgroundColor: '#fff',
                            width: '100%',
                            zIndex: 1,
                            padding:10,
                            boxSizing:'border-box'
                        }}>
                            <p style={{fontSize:14,color:'var(--text-color'}}>No Unit  found</p>
                            <p onClick={()=>{this.add()}} style ={{color:'var(--active-color)',fontWeight:'600',cursor:'pointer',marginTop:10}}>+ Add Unit</p>
                        </div>
                        :null
                }

               

            </div >
        )
    }
}


export { SearchUnit }