import { Switch, Route,useLocation } from 'react-router-dom';
import EmployeeList from './EmployeeList';
import Roles from './Roles';
import Inactive from './InactiveEmployees';
import { Header } from '../../../NCommon/Header';


const Index = () => {

    let { pathname } = useLocation();
    let employee = `/app/employee`;
    let roles = '/app/employee/roles';
    let inactive = `/app/employee/inactive`
    return (
        <>
            <Header
                items={[
                    {
                        name: 'Employees',
                        link: '/app/employee',
                        active: pathname === employee
                    },
                    {
                        name:'Inactive Employee',
                        link:'/app/employee/inactive',
                        active:pathname === inactive
                    },
                    {
                        name: 'Roles',
                        link: '/app/employee/roles',
                        active: pathname === roles
                    }
                ]}
            />
             <Switch>
                <Route path="/app/employee" component={EmployeeList} exact />
                <Route path='/app/employee/roles' component={Roles} exact />
                <Route path="/app/employee/inactive" component={Inactive} exact />
             </Switch>
        </>
    )
}
export default Index;