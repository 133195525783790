import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';



class SearchDrawingCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            suggestion: [],
        }

    }



    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }

        if (this.props.value) {
            this.setValue(this.props.value);
            this.parent.setValue(this.props.value.name);
        }
    }





    onSearch = (val) => {
        if (val.length > 0) {

            get(`project/drawing/searchDrawingCategory`, (e, r) => {
                if (r) { 
                    if(r.categories.length>0) {
                        this.setState({
                            ...this.state,
                            suggestion:r.categories,
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            suggestion:[]
                        })
                    }
                   
                }
            }, {
                q: val
            })

        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }
    }

    setRef= (name) => {
        this.parent.setValue(name);
    }

    setValue = (name) => {
        this.setState({
            name
        })
    }


    getValue = () => {
        return this.state.name;
    }

    render() {


        return (
            <div style={{
                flex: 1,
                position: 'relative'
            }} >
                <FloatInput title={this.props.title} handleRef={ref => this.parent = ref} onChange={val => {
                    this.onSearch(val);
                }} />

                {
                    this.state.suggestion.length > 0 ?
                        <div className="suggestion-modal" style={{marginTop:18}}>
                            {this.state.suggestion.map((item, index) => (
                                <div key={index} className="suggestion-modal-item" onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        suggestion: [],
                                        name: item
                                    })
                                    this.parent.setValue(item.name);
                                }}>
                                    {item.name}
                                </div>
                            ))}

                        </div>
                        :
                        null
                }

            </div >
        )
    }
}


export { SearchDrawingCategory }