import  { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { get } from '../../../Model/Network/Config/Axios';
import { FunctionalInput } from '../FloatInput/FunctionalInput';



const AddRoles = forwardRef((props,ref) => {

    const [roles,setRoles] = useState([]);
    const [suggestion,setSuggestion] = useState([]);
    const [name,setName] = useState('');
    let NAME=useRef();


    useEffect(()=>{
        get(`/roles/all`, (e, r) => {
            if (r) {
              setRoles(r.roles);
            }
        })
        if(props.handleRef) {
            props.handleRef({getValue,setValue})
        }
        
    },[])

    
    useImperativeHandle(ref,()=>({
        setValue:(value)=>{
            NAME.current.value = value;
        },
        getValue:()=>{
            return NAME.current.value
        }
    }))


    let getValue = () => {
        return NAME.current.value;
    }

    let setValue = (value) => {
        NAME.current.value = value;
    }
    
   


    let getSuggestion = (val) => {
        if (val.length > 0) {
            let suggestion = roles.filter(item => item.name.toLowerCase().includes(val.toLowerCase()));
            setName(val);
            setSuggestion(suggestion);
        } else {
            setSuggestion([]);
            setName('');
        }
    }


        return (
            <div style={{
                position: 'relative',
                flex:1
            }}>
                <FunctionalInput 
                        title={props.title}
                        type="text" 
                        placeholder={props.placeholder?props.placeholder:props.title} 
                        onChange={(val)=>{
                            getSuggestion(val)
                        }}
                        ref={NAME}
                        autoComplete="off"
                        />
                    {
                    suggestion.length > 0 && (
                        <div  className="suggestion-modal" style={{top:68}}>
                            {suggestion.map((item, index) => {
                                return (
                                    <div key={index} className="suggestion-modal-item" style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}} onClick={() => {
                                        setSuggestion([]);
                                        props.onSelect(item.id);
                                        NAME.current.value = item.name
                                        setName('');
                                    }}>
                                        <p style={{
                                            fontSize: 14,
                                            color: 'var(--text-color)',
                                            fontWeight: 600
                                        }}>{item.name}</p>
                                        <p style={{
                                            fontSize: 12,
                                            color: "var(--text-light-color)",
                                        }}>{item.description}</p>
                                    </div>
                                )
                            })}
                        </div>)
                    }

                    {
                        name.length>0 && suggestion.length===0 && (
                            <div className="suggestion-modal" style={{top:68}}>
                                <p style={{padding:10,fontSize:14}}>No Role found,Please search valid Role</p>
                            </div>
                        )
                    }
            </div>
        )
})

export { AddRoles };