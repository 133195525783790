

class CalendarReference {

    constructor() {
        this.ref = undefined;
    }
    getRef = () =>{
        return this.ref;
    }

    setRef = (ref) => {
        this.ref = ref
    }
}

const RefObject = new CalendarReference();

export {RefObject}