import { Link } from 'react-router-dom';
import Moment from 'moment';
import { KeyValue } from '../../Common/KeyValue';
import { Carousel } from '../../Common/Carousel';
import { useDictionaryValue } from '../../Hooks/DictionaryHooks';

const Details = (props) => {

    let {tasksMapping,images} = props;
    let { predecessorTask } =tasksMapping;
    const getDictionaryValue = useDictionaryValue();


    let predecessor = () => {
        return (
            <div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10

                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <p style={{
                            fontSize: 13,
                            color: 'var(--text-light-color)',

                        }}>{`${getDictionaryValue('Wbs')} Item Name`}</p>
                        <Link style={{
                            fontWeight: '500',
                            fontSize: 14,
                            marginTop: 5,
                            width: 300,
                            textDecoration: 'none',
                            color: 'var(--hover-button-color)'
                        }} to={`/app/project/task/${props.getId}/${predecessorTask.id}/details`} target="_blank" className="hide-text">
                            {predecessorTask.wbsItemTaskName}
                        </Link>
                    </div>

                    <KeyValue
                        title="Total Days Required"
                        desc={predecessorTask ? predecessorTask.totalDaysRequired : 'NA'}
                        style={{
                            flex: 1
                        }}
                    />
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10

                }}>

                    <KeyValue
                        title="Actual Start Date"
                        desc={predecessorTask ? predecessorTask.actualStartDate ? Moment(predecessorTask.actualStartDate).format('DD-MM-YYYY') : 'NA' : 'NA'}
                        style={{
                            flex: 1,
                            marginRight: 20
                        }}
                    />

                    <KeyValue
                        title="Actual Completion Date"
                        desc={predecessorTask ? predecessorTask.actualCompletionDate ? Moment(predecessorTask.actualCompletionDate).format('DD-MM-YYYY') : 'NA' : 'NA'}
                        style={{
                            flex: 1
                        }}
                    />
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10

                }}>

                    <KeyValue
                        title="Original Start Date"
                        desc={predecessorTask ? predecessorTask.originalStartDate ? Moment(predecessorTask.originalStartDate).format('DD-MM-YYYY') : 'NA' : 'NA'}
                        style={{
                            flex: 1,
                            marginRight: 20
                        }}
                    />

                    <KeyValue
                        title="Original Completion Date"
                        desc={predecessorTask ? predecessorTask.originalCompletionDate ? Moment(predecessorTask.originalCompletionDate).format('DD-MM-YYYY') : 'NA' : 'NA'}
                        style={{
                            flex: 1
                        }}
                    />
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10

                }}>

                    <KeyValue
                        title="Estimated Start Date"
                        desc={predecessorTask ? predecessorTask.estimatedStartDate ? Moment(predecessorTask.estimatedStartDate).format('DD-MM-YYYY') : 'NA' : 'NA'}
                        style={{
                            flex: 1,
                            marginRight: 20
                        }}
                    />

                    <KeyValue
                        title="Estimated Completion Date"
                        desc={predecessorTask ? predecessorTask.estimatedCompletionDate ? Moment(predecessorTask.estimatedCompletionDate).format('DD-MM-YYYY') : 'NA' : 'NA'}
                        style={{
                            flex: 1
                        }}
                    />
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10

                }}>

                    <KeyValue
                        title="+/- days wrt predecessor"
                        desc={predecessorTask.lagDay}
                        style={{
                            flex: 1,
                            marginRight: 20
                        }}
                    />
                    <div style={{
                        flex: 1
                    }} />
                </div>

            </div>
        )
    }
    return (
        <div>
            <div style={{
                flex: 1,
                display: 'flex'
            }}>
                <KeyValue
                    title={`${getDictionaryValue('wbs')} Item Name`}
                    desc={tasksMapping.wbsItemTaskName}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />


                <KeyValue
                    title="Wbs Item Mapping"
                    desc={tasksMapping.wbsItemMapping.name}
                    style={{
                        flex: 1
                    }}
                />
            </div>


            <div style={{
                flex: 1,
                display: 'flex',
                marginTop: 10
            }}>
                <KeyValue
                    title="Task Name"
                    desc={tasksMapping.task.name}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Task Details"
                    desc={tasksMapping.task.details}
                    style={{
                        flex: 1
                    }}
                />
            </div>

            <div style={{
                flex: 1,
                display: 'flex',
                marginTop: 10

            }}>
                <KeyValue
                    title="Total days required"
                    desc={tasksMapping.totalDaysRequired}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Predecessor Relation"
                    desc={tasksMapping.predecessorRelation}
                    style={{
                        flex: 1
                    }}
                />
            </div>


            <div style={{
                flex: 1,
                display: 'flex',
                marginTop: 10

            }}>
                <KeyValue
                    title="Original Start Date"
                    desc={tasksMapping.originalStartDate ? Moment(tasksMapping.originalStartDate).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Orignal Completion Date"
                    desc={tasksMapping.originalCompletionDate ? Moment(tasksMapping.originalCompletionDate).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1
                    }}
                />
            </div>



            <div style={{
                flex: 1,
                display: 'flex',
                marginTop: 10

            }}>

                <KeyValue
                    title="Actual Start Date"
                    desc={tasksMapping.actualStartDate ? Moment(tasksMapping.actualStartDate).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Actual Completion Date"
                    desc={tasksMapping.actualCompletionDate ? Moment(tasksMapping.actualCompletionDate).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1
                    }}
                />
            </div>
            <div style={{
                flex: 1,
                display: 'flex',
                marginTop: 10

            }}>

                <KeyValue
                    title="Estimated Start Date"
                    desc={tasksMapping.estimatedStartDate ? Moment(tasksMapping.estimatedStartDate).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Estimated Completion Date"
                    desc={tasksMapping.estimatedCompletionDate ? Moment(tasksMapping.estimatedCompletionDate).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1
                    }}
                />
            </div>
             <div style={{
                flex: 1,
                display: 'flex',
                marginTop: 10

            }}>

                <KeyValue
                    title="Planned Start Date"
                    desc={tasksMapping.plannedStartDate ? Moment(tasksMapping.plannedStartDate).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Planned Completion Date"
                    desc={tasksMapping.plannedCompletionDate ? Moment(tasksMapping.plannedCompletionDate).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1
                    }}
                />
            </div>


            <div style={{
                flex: 1,
                display: 'flex',
                marginTop: 10

            }}>

                <KeyValue
                    title="+/- days wrt predecessor"
                    desc={tasksMapping.lagDay}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Task Type"
                    desc={tasksMapping.task.taskType}
                    style={{
                        flex: 1,
                    }}
                />
            </div>

            <div style={{
                flex: 1,
                display: 'flex',
                marginTop: 10

            }}>

                <KeyValue
                    title="Buffer (in days)"
                    desc={tasksMapping.buffer?tasksMapping.buffer:'0'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <div style={{flex:1}} />
            </div>
            {
                tasksMapping.recurring ?
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10
    
                }}>
    
                    <KeyValue
                        title="Recurring Type"
                        desc={tasksMapping.recurringType}
                        style={{
                            flex: 1,
                            marginRight: 20
                        }}
                    />
    
                    <KeyValue
                        title="Recurring Value"
                        desc={tasksMapping.recurringValue}
                        style={{
                            flex: 1,
                        }}
                    />
                </div>
                :null

            }
             <div style={{marginBottom:10,marginTop:10}}>
                <p style={{fontWeight: '800',fontSize: 16,color: 'var(--text-color)',textTransform: 'uppercase'}}>Holiday Days</p>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10
                }}>
                <KeyValue
                    title="Original Start Holiday"
                    desc={tasksMapping.originalStartHolidays||'-'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

               <KeyValue
                    title="Original End Holiday"
                    desc={tasksMapping.originalEndHolidays||'-'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10
                }}>
                <KeyValue
                    title="Planned Start Holiday"
                    desc={tasksMapping.plannedStartHolidays||'-'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />


                <KeyValue
                    title="Planned End Holiday"
                    desc={tasksMapping.plannedEndHolidays||'-'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10
                }}>
                <KeyValue
                    title="Estimated Start Holiday"
                    desc={tasksMapping.estimatedStartHolidays||'-'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Estimated End Holiday"
                    desc={tasksMapping.estimatedEndHolidays||'-'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                </div>
               
            </div>

            <div style={{marginBottom:10,marginTop:10}}>
                <p style={{fontWeight: '800',fontSize: 16,color: 'var(--text-color)',textTransform: 'uppercase'}}>Holiday Date</p>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10
                }}>
                <KeyValue
                    title="Original Date Holiday"
                    desc={tasksMapping.originalDateHolidays ? Moment(tasksMapping.originalDateHolidays).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Planned Date Holidays"
                    desc={tasksMapping.plannedDateHolidays ? Moment(tasksMapping.plannedDateHolidays).format('DD-MM-YYYY') : 'NA'}
                    style={{
                        flex: 1
                    }}
                />
                </div>
                <div>
                    <KeyValue
                        title="Estimated Date Holiday"
                        desc={tasksMapping.estimatedDateHolidays ? Moment(tasksMapping.estimatedDateHolidays).format('DD-MM-YYYY') : 'NA'}
                        style={{
                            flex: 1,
                            marginRight: 20
                         }}
                    />
                </div>
            </div>

            <div style={{
                marginBottom: 10,
                marginTop: 10

            }}>
                <p style={{
                    fontWeight: '800',
                    fontSize: 16,
                    color: 'var(--text-color)',
                    textTransform: 'uppercase'

                }}>Vendor Details</p>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    marginTop: 10
                }}>
                <KeyValue
                    title="Vendor Name"
                    desc={tasksMapping.vendor ? tasksMapping.vendor.name: 'NA'}
                    style={{
                        flex: 1,
                        marginRight: 20
                    }}
                />

                <KeyValue
                    title="Vendor Code"
                    desc={tasksMapping.vendor ?tasksMapping.vendor.vendorCode : 'NA'}
                    style={{
                        flex: 1
                    }}
                />
            </div>
            </div>



            <div style={{
                marginBottom: 10,
                marginTop: 10

            }}>
                <p style={{
                    fontWeight: '800',
                    fontSize: 16,
                    color: 'var(--text-color)',
                    textTransform: 'uppercase'

                }}>Predecessor Task Details</p>
            </div>

            {predecessorTask ? predecessor() : <div>No Predecessor Assigned </div>}



            <div style={{
                display: 'flex'
            }}>

                <div style={{
                    flex: 1
                }}>
                    <div style={{
                        marginBottom: 20,
                        marginTop: 10

                    }}>
                        <p style={{
                            fontWeight: '800',
                            fontSize: 16,
                            color: 'var(--text-color)',
                            textTransform: 'uppercase'
                        }}>Reponsible Person Details</p>
                    </div>

                    <KeyValue
                        title="Name"
                        desc={tasksMapping.responsiblePerson ? tasksMapping.responsiblePerson.name : 'NA'}
                    />

                    <KeyValue
                        title="Code"
                        desc={tasksMapping.responsiblePerson ? tasksMapping.responsiblePerson.code : 'NA'}
                    />

                    <KeyValue
                        title="Email"
                        desc={tasksMapping.responsiblePerson ? tasksMapping.responsiblePerson.email : 'NA'}
                    />

                    <KeyValue
                        title="Mobile Number"
                        desc={tasksMapping.responsiblePerson ? tasksMapping.responsiblePerson.mobile : 'NA'}
                    />
                </div>
                <div style={{
                    flex: 1
                }}>
                    <div style={{
                        marginTop: 10,
                        marginBottom: 20
                    }}>
                        <p style={{
                            fontWeight: '800',
                            fontSize: 16,
                            color: 'var(--text-color)',
                            textTransform: 'uppercase'
                        }}>Approver Person Details</p>
                    </div>

                    <KeyValue
                        title="Name"
                        desc={tasksMapping.approver ? tasksMapping.approver.name : 'NA'}
                    />

                    <KeyValue
                        title="Code"
                        desc={tasksMapping.approver ? tasksMapping.approver.code : 'NA'}
                    />

                    <KeyValue
                        title="Email"
                        desc={tasksMapping.approver ? tasksMapping.approver.email : 'NA'}
                    />

                    <KeyValue
                        title="Mobile Number"
                        desc={tasksMapping.approver ? tasksMapping.approver.mobile : 'NA'}
                    />
                </div>
            </div>
            <div style={{
                marginTop: 10,
                marginBottom: 20
            }}>
                <p style={{
                    fontWeight: '800',
                    fontSize: 16,
                    color: 'var(--text-color)',
                    textTransform: 'uppercase'
                }}>
                    All Images
                    </p>
            </div>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap'
            }}>
                {images.length === 0 ?
                    <div style={{
                        fontSize: 16,
                        fontWeight: 500,
                    }}>No Image Found</div>

                    : <Carousel
                        items={images}
                        showDetails={true}
                    />}
            </div>
        </div>
    )
}

export default Details;