import {useLocation,Switch,Route} from 'react-router-dom';
import { Header } from '../../../NCommon/Header';
import Active from './Active';
import Inactive  from './Inactive';


const  AccessManagement  = () => {
    const route = useLocation().pathname;
    return(
        <>
         <Header 
                items={[
                    {
                        name:'Active Users',
                        link: `/app/others/access`,
                        active: route === `/app/others/access`
                    },
                    {
                        name:'Inactive Users',
                        link:'/app/others/access/inactive',
                        active: route === '/app/others/access/inactive'
                    }
                ]}
            />
         <Switch>
            <Route path="/app/others/access" component={Active} exact />
            <Route path="/app/others/access/inactive" component={Inactive} exact />
        </Switch>  
        </>
    )
}

export  default AccessManagement;