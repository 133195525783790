import React from 'react';
import axios from 'axios';
import { showToast } from '../Toast';


class FloatInput extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : undefined
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
        if(this.props.defaultValue)  {
            this.input.value = this.props.defaultValue;
        }
    }


    getValue = () => {
        return this.input.value
    }

    setValue = (value) => {
        this.input.value = value
    }


    render() {
        return (
            <div style={{
                flex: 1,
                ...this.props.style
            }}>
                   <p style={{ fontSize: 12, color: 'var(--title-color)',  fontWeight: 600, marginTop: 15}}>{this.props.title} </p>
                    <input className="finput"  ref={ref => this.input = ref} disabled={this.props.disabled?this.props.disabled:false} type={this.props.type} {...this.props.inputProps} 
                        placeholder={this.props.placeholder ? this.props.placeholder : this.props.title}
                        onChange={e => {
                            if (this.props.onChange) {
                                this.props.onChange(e.target.value)
                            }

                        }}
                        value={this.props.value}
                        onClick={() => {
                            if (this.props.onClick) {
                                this.props.onClick()
                            }
                        }}
                        autoComplete="off"
                    />
                    {this.props.children}
                     {this.props.desc ?
                        <p style ={{color:'var(--text-color)',fontSize:13,marginTop:10}}>{this.props.desc}</p>
                        : null
                    }   
            </div>
        )
    }
}




class FloatFile extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : '',
            file:undefined
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }


    onChange = () => {
        let file = this.file.files[0];

        if(file) {
            const formData = new FormData();
            formData.append('file', file);

            this.setState({
                ...this.state,
                buttonLoading: true
            })

            axios({
                method: 'post',
                url: `https://api.buildrun.app/main/admin/auth/upload`,
                data: formData,
                
            })
                .then(res => {
                   
                    if (res.data.status === 'success') {
                        
                        this.setState({
                            ...this.state,
                            file:res.data.url
                        })
                        showToast({
                            type: 'success',
                            message: 'file upoad successfully'
                        })
                    }

                })
                .catch(res => {
                    this.setState({
                        ...this.state,
                        buttonLoading: false
                    })
                    showToast({
                        type: 'error',
                        message: res.message
                    })
                })

        } else {
            showToast({
                type:'error',
                message:"Please select the valid file"
            })
        }
    }


    getFileUrl = () => {
        return this.state.file;
    }

    getValue = () => {
        return this.file.value
    }

    setValue = (value) => {
        this.file.value = value
    }


    render() {
        return (
            <div className="input" style={{
                flex: 1
            }}>
                <p style={{
                    fontSize: 12,
                    color: 'var(--title-color)',
                    fontWeight: 600,
                    marginTop: 15
                }}>
                    {this.props.title} :
                </p>

                <div style={{
                    height: 40,
                    border: '1px solid var(--border-color)',
                    marginTop: 8,
                    width: '100%'
                }}>
                    <input ref={ref => this.file = ref} type="file" {...this.props.inputProps} style={{
                        width: '94%',
                        border: 'none',
                        height: '94%',
                        padding: '10px',
                        fontSize: 16
                    }}
                        placeholder={this.props.placeholder ? this.props.placeholder : this.props.title}
                        onChange={e => {
                            this.onChange()
                            if (this.props.onChange) {
                                this.props.onChange(e.target.value)
                            }

                        }}
                        onClick={() => {
                            if (this.props.onClick) {
                                this.props.onClick()
                            }
                        }}
                        autoComplete="off"
                    />
                </div>
               

            </div>
        )
    }
}


export { FloatInput, FloatFile }