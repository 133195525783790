import './StatusTile.css';

const StatusTile = ({item}) => {
    return(
        <p  className={`statustile ${item.workDone?'task-success-n':item.workStarted?'task-progress-n':'task-incomplete-n'}`}>
            {item.workDone?"Completed":item.workStarted?"Progress":"Not Started"}
        </p>
    )
}

export default StatusTile;