import {  useState } from "react";
import { useDispatch } from "react-redux";
import card from './card.png';
import list from './list.png';
import calendar from './calendar.png';
import './index.css';
import { AuthActions } from "../../../Redux/Reducers";
import { get,set } from "../../../Utils/Crypto";


const TableView = ({hideCalendar,hideCard}) => {

    const [type,setType] = useState(get('table-view')?get('table-view'):'card');
    let dispatch = useDispatch();

    return(
        <div style={{display:'flex'}}>
            {!hideCard?
            <div onClick={()=>{
                set('table-view','card');
                dispatch({
                    type:AuthActions.Table,
                    data:'card'
                })
                setType('card')
                }} className={`tableview ${type==='card'?'active':null}`} style={{marginRight:10}}>
                <img src={card}  alt="" />
            </div>
            :null}
            <div onClick={()=>{
                set('table-view','list')
                dispatch({
                    type:AuthActions.Table,
                    data:'list'
                })
                setType('list')
                }} className={`tableview ${type==='list'?'active':null}`} style={{marginRight:10}}>
                <img src={list}  alt=""  />
            </div>
            {
            hideCalendar?null:
            <div onClick={()=>{
                set('table-view','calendar')
                dispatch({
                    type:AuthActions.Table,
                    data:'calendar'
                })
                setType('calendar')
                }} className={`tableview ${type==='calendar'?'active':null}`}>
                <img src={calendar}  alt=""  />
            </div>
            }
        </div>
    )
}

TableView.defaultProps = {
    hideCalendar:true
}




export {TableView}