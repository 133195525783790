import  { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {useParams,useLocation} from 'react-router-dom';
import { PageLoader } from '../../../Common/PageLoader';
import { AccessButton, Button, LineItemButton } from '../../../Common/Button';
import { get } from '../../../../Model/Network/Config/Axios';
import { Modal } from '../../../Common/Modal/Modal';
import { showToast } from '../../../Common/Toast/index';
import { DownloadCsv } from '../../../../Utils/Downloader';
import { useTableCount } from '../../../Hooks/TableCount';
import { Header } from '../../../NCommon/Header';
import { useDictionaryValue } from '../../../Hooks/DictionaryHooks';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { PageWrapper } from '../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import AddTask from './AddTask';
import Edit from './EditTask';
import ViewTaskItem from './ViewTaskItem';
import { Card } from '../../../NCommon/Ui/CardLayout';
import {List} from '../../../NCommon/Ui/List';
import {PaginationTable} from '../../../NCommon/Tables/PaginationTable';
import { TableMenu } from '../../../NCommon/Tables/TableMenu';
import { ViewTag } from '../../../NCommon/Tag';
import { AddTag } from '../../../Common/Tag/AddTag';





const TaskItem = () => {
    
    let [loading,setLoading] = useState(true);
    let [view,setView] = useState(false);
    let [modal,setModal] = useState(false);
    let [edit,setEdit] = useState(false);
    let [filters,setFilters] = useState([]);
    let [buttonLoading,setButtonLoading] = useState(false);
    const [sorted,setSorted] = useState('NAME')
    const [up,setUp] = useState(true);
    const [tag,setTag] = useState(false);
    const [updateId,setUpdateId] = useState(1);

    let [mData,setMData] = useState({
        items:[],
        page:0,
        total:0
    })
    let [deleteModal,setDeletemodal] = useState(false);
    let getDictionaryValue = useDictionaryValue();
    let {search} = useLocation();
    let queryParams = new URLSearchParams(search);
    let name = queryParams.get('name')
    let count = useTableCount();
    let {id} = useParams();


    useEffect(()=>{
        INIT({page:0})
    },[count,up,sorted])   
  
    let INIT = (data) => {
        if(!data) {
            data = {page:0}
        }
        data.size = `${count}`;
        data.offset =  (data.page*count).toString();
        data.ascending = up;
        data.sortedBy = sorted;
        get(`project/task/task.category/${id}`, (e, r) => {
            if (r) {
                setMData({
                    items:r.tasks,
                    page:data.page,
                    total:r.total
                })
                setLoading(false);
            }
        },data)
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card 
                onClick={()=>{setView(item)}}                
                height={140} 
                key={index} 
                items={[{name:'Edit',onClick:(e)=>{e.stopPropagation();setEdit(item)}},{name:'Delete',onClick:(e)=>{e.stopPropagation();setDeletemodal(item)}}]}
                title={item.taskName} 
                desc={item.details}>
                  <div style={{fontSize:12,marginTop:5,color:'var(--text-light-color)'}}>
                     {item.taskType}
                  </div>
                  <div style={{marginTop:"auto"}}>
                     <div style={{borderTop:'0.5px solid var(--border-color)',height:22,marginLeft:-12,marginRight:-12,display:'flex',alignItems:'center'}}>
                        <ViewTag update={updateId} id={item.id} type={'TASK'} add={()=>{setTag(item.id)}}/>
                    </div>
                 </div>
            </Card>
        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List onClick={()=>{setView(item)}} key={index}>
                <div className="flex-1">
                    <div className="table-title">{item.taskName}</div>
                    <div className="table-bottom">{item.details}</div>
                </div>
                <div className="flex-1">
                    <div className="table-text">{item.taskType}</div>
                </div>
                <div className='flex-1'>
                    <ViewTag update={updateId} id={item.id} type={'TASK'} add={()=>{setTag(item.id)}}/>
                </div>
                <TableMenu items={[{name:'View',onClick:()=>{}},{name:'Edit',onClick:(e)=>{e.stopPropagation();setEdit(item)}},{name:'Delete',onClick:(e)=>{e.stopPropagation();setDeletemodal(item)}}]} />
            </List>
        )
    }




    let deleteTask = () => {
        axios.delete(`/project/task/task.delete/${deleteModal.id}`, {

        })
            .then(res => {
                setButtonLoading(false);
                setDeletemodal(false);
                INIT({page:0})
                showToast({
                    type: 'success',
                    message: `Item Deleted Successfully`
                })
            })
            .catch(res => {
                showToast({
                    type: 'error',
                    message: res.data.message
                })
                setButtonLoading(false);
            })
    }

  let  downloadCsv = () => {
        let baseData = [[
            "id",
            "Name",
            "Details",
            "Type"
        ]]
        
        mData.items.forEach(val => {
            baseData.push([
                val.id,
                val.taskName,
                val.details,
                val.taskType

            ])
        })
        DownloadCsv('TaskItem.csv', baseData);
    }

    let handleSort = (sortType) => {
        if(sortType === sorted) {
            setUp(!up);
        } else {
            setSorted(sortType);
            setUp(true)
        }
    }


    return (
            <>
                <Header title={`${getDictionaryValue('Task')} Item`}  items={[]} />
                {
                    loading?
                    <PageLoader />
                    :
                    <>
                        <HeaderWrapper
                            dynamicFilter={true}
                            types={["Task Items"]} 
                            filters={[...filters]} 
                            menuItem={[{name:'Download Csv',onClick:downloadCsv,type:'downloadcsv'}]} 
                            onFilterChange={items=>{
                                 let filter = {};
                                 items.forEach(item=>{
                                     filter[item.type] = item.value;
                                 })
                                 setFilters(items)
                                 INIT({page:0,filter})
                             }} 
                        />
                        <PageWrapper scroll={true} id="pagination-table-header">
                            <NavigationWrapper items={[{name,path:'/app/task'},{name:`${getDictionaryValue('Task')} (${mData.total})`}]}>
                                <AccessButton onClick={()=>setModal(true)} text={`Add ${getDictionaryValue('Task')}`} addsign={true?1:0} inverted={true?1:0} style={{padding:'0px 10px'}} />
                            </NavigationWrapper>
                            <PaginationTable
                                    DATA={mData.items}
                                    next={(page)=>{
                                        INIT({page})
                                    }}
                                    mapListLayout={mapListLayout}
                                    mapCardLayout={mapCardLayout}
                                    total={mData.total}
                                    page={mData.page}
                                    headers={[
                                        {
                                            name:'Details',
                                            flex:1,
                                            sort:sorted==='NAME',
                                            onSortClick:()=>handleSort('NAME'),
                                            up
                                        },
                                        {
                                            name:'Task Type',
                                            flex:1
                                        },
                                        {
                                            name:'Tag',
                                            flex:1
                                        },
                                        {
                                            name:'actions',
                                            width:50
                                        }
                                    ]}
                                />
                        </PageWrapper>
                    </>
                }

                {
                    modal ?
                        <AddTask
                            onClose={()=>{
                                setModal(false);
                            }}
                            onComplete={() => {
                                INIT({page:0});
                                setModal(false);
                            }}
                            />
                        : null
                }
                {
                    deleteModal ?
                        <Modal
                            title={`Delete Task`}
                            desc={`Delete ${deleteModal.taskName}`}
                            show={deleteModal}
                            onClose={() => {
                                setDeletemodal(false);
                            }}
                            style={{
                                minHeight: 150
                            }}
                        >
                            <p style={{
                                marginTop: 50
                            }}>Are you sure want to delete <span style={{
                                fontWeight: 600
                            }}>
                                    {deleteModal.taskName}
                                </span> ? </p>

                            <Button
                                text="Delete"
                                style={{
                                    marginTop: 50,
                                    backgroundColor: 'var(--red-color)',
                                    borderColor: 'var(--red-color)'
                                }}
                                onClick={() => {
                                    setButtonLoading(true);
                                    deleteTask();
                                }}
                                loading={buttonLoading}
                            />
                        </Modal>
                        : null

                }
                {
                    view ?
                    <ViewTaskItem
                        onClose={()=>{
                            setView(false)
                        }}
                        item={view}
                    />
                    : null
                }

                {
                    edit?
                    <Edit
                        item={edit}
                         onClose={()=>{
                            setEdit(false);
                        }}
                        onComplete={() => {
                            INIT({page:mData.page});
                            setEdit(false);
                         }}
                    />
                    : null
                }

                {
                    tag?
                    <AddTag referenceId={tag} type="TASK" init={()=>{
                        setTag(false);
                        setUpdateId(updateId+1)
                        INIT({page:mData.page})
                    }} close={()=>setTag(false)} />
                    :null
                }

            </>
        )
}

export default TaskItem;