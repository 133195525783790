import { useEffect, useState } from "react";
import {useParams,useLocation} from 'react-router-dom';
import {  post } from "../../../../Model/Network/Config/Axios";
import { DownloadDirectCsv } from "../../../../Utils/Downloader";
import { PageLoader } from "../../../Common/PageLoader";
import { useDictionaryValue } from "../../../Hooks/DictionaryHooks";
import { useProject } from "../../../Hooks/ProjectDetails";
import { useTableCount } from "../../../Hooks/TableCount";
import { TaskDateFilter } from "../../../NCommon/DateFilters/TaskDateFilter";
import { HeaderWrapper } from "../../../NCommon/HeaderWrapper";
import TaskMode from './TaskMode';
import CloneTask from "./TaskMode/SubTask/CloneTask";
import UploadTask from "./TaskMode/SubTask/UploadTask";
import querySearch from 'stringquery';

const Index = () => {

    const [mData,setMData] = useState({
        items:[],
        page:0,
        total:0
    })
    const [loading,setLoading] = useState(true);
    const [filters,setFilters] = useState([]);
    const [clone,setClone] = useState(false);
    const [uploadcsv,setUploadCsv] = useState(false);
    const [dates,setDates] = useState(undefined);
    const [up,setUp] = useState(true);
    const [status,setStatus] = useState([
        {
            name: "Progress",
            active: false,
            color: "#ebb840",
            key:'PROGRESS'
          },
          {
            name: "Not Started",
            active: false,
            color: "#404959",
            key:'NOTSTARTED'
          },
          {
            name: "Delay",
            active: false,
            color: "#ff944d",
            key:'DELAY'
          },
          {
            name: "Completed",
            active: false,
            color: "#68d083",
            key:'COMPLETED'
          },
          {
            name: "Critical Task",
            active: false,
            color: "#ea3a3c",
            key:'CRITICAL'
          },
          {
            name: "Task Verified",
            active: false,
            color: "#33cccc",
            key:'VERIFYTASK'
          },
          {
            name: "Hindrance",
            active: false,
            color: "#22eecc",
            key:'HINDRANCE'
          },
         
          {
            name: "Sho. Started",
            active: false,
            color: "#62fec9",
            key:'SHOULD_STARTED'
          },
          {
            name: "Sho. Completed",
            active: false,
            color: "#42feca",
            key:'SHOULD_COMPLETED'
          },
    ])
    const [sorted,setSorted] = useState('PLANNED_START')
    let count = useTableCount();
    let details = useProject();
    let getDictionaryValue = useDictionaryValue();
    let {id} = useParams();
    let location = useLocation();
    let pathparams = querySearch(location.search);

    //  Redirecting filter handling 

    useEffect(()=>{
        if(pathparams) {
           let {type,value,status1,filter} = pathparams;
           if(filter) {
            filter = JSON.parse(decodeURIComponent(filter))
           }
           if(type) {

                let items = [];
                if(filter) {
                    items = [...filter]
                }
                let statusItems = [...status];
                if(status1) {
                    statusItems = statusItems.map(val=>{
                        if(val.key===status1) {
                            return {
                                ...val,
                                active:true
                            }
                        }  else {
                            return val
                        }
                    })
                }
                setLoading(true);
                setStatus(statusItems);
                setFilters(items); 
            }
        } 
    },[])

    // Redirecting filter End

  

    useEffect(()=>{
        INIT({page:0})
    },[count])

    useEffect(()=>{
        setLoading(true);
        next({page:mData.page})
    },[sorted,up,status,dates])



    let INIT = (data) => {
        if(!data) {
            data = {page:0}
        }
        data.size = `${count}`;
        let otherNodes = [];

        data.offset = (data.page*count).toString();
        if(data.filter) {
            data.orFilters=[...data.filter.filter(
                    item=>item.key!=='NAME' &&
                    item.key!=='WBS_CATEGORY' &&
                    item.key!=='TASK_CATEGORY_NAME' &&
                    item.key!=='RESPONSIBLE_NAME' &&
                    item.key!=='APPROVER_NAME' && 
                    item.key!=='VENDOR_NAME'
                    )];
            let andFilterExtra = data.filter.filter(
                item=>item.key==='NAME' ||
                    item.key==='WBS_CATEGORY' ||
                    item.key==='TASK_CATEGORY_NAME' ||
                    item.key==='RESPONSIBLE_NAME' ||
                    item.key==='APPROVER_NAME' || 
                    item.key==='VENDOR_NAME'
            )
            // let NAMEFILTER = data.filter.filter(item=>item.key==='NAME');
            // let WBSFILTER = data.filter.filter(item=>item.key==='WBS_CATEGORY');
            // let TASKCATEGORYFILTER = data.filter.filter(item=>item.key==='TASK_CATEGORY_NAME');
            otherNodes = [...andFilterExtra]
            delete data.filter;
        }
        
        status.forEach(val=>{
            if(val.active && val.key!=='HINDRANCE') {
              otherNodes.push({key:val.key,val:''})
            } else if(val.active) {
                if(data.orFilters) {
                    data.orFilters = [...data.orFilters,{key:val.key,val:''}]
                } else {
                    data.orFilters = [{key:val.key,val:''}]
                }
            }
        })

        data.ascending = up;
        data.sortedBy = sorted;
        if(otherNodes.length) {
            data.andFilters=otherNodes
        }
        if(dates) {
            data.andFilters = [...otherNodes,{key:'START',val:dates.startDate},{val:dates.endDate,key:'END'}]
        }
        post(`project/task/taskMapping/${id}`,data,(e,r)=>{
            if(r) {
                setMData({
                    items:r.tasksMappings,
                    total:r.total,
                    page:data.page
                })
                setLoading(false);
            }
        })
    }

    let next = ({page}) => {
        let items = [...filters];
        let filter = [];
        items.forEach(item=>{
            filter.push({
                key:item.type,
                val:item.value
              })
        })
        INIT({page,filter})
    }


    let download = () => {
        let items = [...filters];
        let filter = [];
        items.forEach(item=>{
            filter.push({
                key:item.type,
                val:item.value
              })
        })
        let data = {},otherNodes=[];

        if(filter) {
            data.orFilters=[...filter.filter(item=>item.key!=='NAME'&&item.key!=='WBS_CATEGORY')];
            let NAMEFILTER = filter.filter(item=>item.key==='NAME');
            let WBSFILTER = filter.filter(item=>item.key==='WBS_CATEGORY');
            otherNodes = [...NAMEFILTER,...WBSFILTER]
        }
        
        status.forEach(val=>{
            if(val.active && val.key!=='HINDRANCE') {
              otherNodes.push({key:val.key,val:''})
            } else if(val.active) {
                if(data.orFilters) {
                    data.orFilters = [...data.orFilters,{key:val.key,val:''}]
                } else {
                    data.orFilters = [{key:val.key,val:''}]
                }
            }
        })

        if(otherNodes.length) {
            data.andFilters=otherNodes
        }
        if(dates) {
            data.andFilters = [...otherNodes,{key:'START',val:dates.startDate},{val:dates.endDate,key:'END'}]
        }

        post(`project/task/taskMapping.csv.dump/${id}`,data,(e,r)=>{
            if(r) {
                DownloadDirectCsv(`${details.name}.csv`,r)
            }
        })
    }


    
    if(loading) {
        return <PageLoader />
    }




    return(
        <>
            <HeaderWrapper 
                dynamicFilter={true} 
                types={['Name','Project Task',"Task Category",'Wbs Mapping',"Vendor",'Responsible Person',"Custom"]}  
                filters={[...filters]}
                statusFilter={true}
                onFilterChange={items=>{
                    let filter = [];
                    items.forEach(item=>{
                        filter.push({
                            key:item.type,
                            val:item.value
                          })
                    })
                    setFilters(items)
                    INIT({page:0,filter})
                }}
                status={status}
                onChangeStatusFilter={setStatus}
                hideCard={true}
                menuItem={[{name:`Clone ${getDictionaryValue(`task`)}`,type:'clone',onClick:()=>{setClone(true)}},{name:`Upload Csv`,type:'uploadcsv',onClick:()=>{setUploadCsv(true)}},{name:'Download Csv',type:'downloadcsv',onClick:download}]}
            >   
                <TaskDateFilter onSelect={setDates} />
            </HeaderWrapper>
            <TaskMode status={[...status]} filters={[...filters]} up={up} sorted={sorted} setUp={setUp} setSorted={setSorted}  data={mData} init={next} />
            <CloneTask show={clone} id={id} onClose={()=>{setClone(false)}} onComplete={()=>{setClone(false);next({page:0})}} />
            <UploadTask id={id} show={uploadcsv} onClose={()=>{setUploadCsv(false)}} onComplete={()=>{setUploadCsv(false);next({page:0})}} />
        </>
    )
}

export default Index;