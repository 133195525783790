import { useRef } from "react";
import Moment from 'moment';
import { Attachment } from "../../../../Common/Attachment";
import { Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { showToast } from "../../../../Common/Toast";
import { SearchInstallApp } from "../../../../NCommon/FunctionalSearch/ThirdParty/SearchInstallApp";
import {axios } from "../../../../../Model/Network/Config/Axios";
import { MainModal } from "../../../../NCommon/Modal";



const Index = (props) => {

    let APP = useRef(),TITLE=useRef(),DATE=useRef(),ATTACHMENT=useRef();

    let create = () => {
        let {item} =props;
        let app = APP.current.getValue();
        let title = TITLE.current.value;
        let deliveryDate = DATE.current.value;
        if(!app) {
            showToast({
                type:'error',
                message:"Please select the app first"
            })
            return;
        }
        if(!title) {
            showToast({
                type:'error',
                message:"Please enter the title"
            })
            return;
        }

        if(!deliveryDate) {
            showToast({
                type:'error',
                message:"Please select the delivery date"
            })
            return;
        }

        let data = {
            deliveryDate:Moment(Moment(deliveryDate, 'YYYY-MM-DD')).valueOf(),
            title,
            storeId:item.storeDetails.id,
            resourceCategoryId:item.resourceCategoryDetails.id,
            inventoryRequests:[item.id],
            files:[...ATTACHMENT.current.getFiles(),]
        }

        axios({
            method:'post',
            url:`https://api.buildrun.app/main/admin/marketplace/dice/createPr`,
            headers:{
                app:app.value,
                marketPlaceAppId:app.id
            },
            data:{
                prRequest:data
            },
            
        })
        .then(res=>{
            if(res.data.status === 200) {
                showToast({
                    type:'success',
                    message:"Pr Request successfully generate in "
                })
            }
            props.INIT();

        })
        .catch(err=>{
            showToast({
                type:'error',
                message:err.data.message
            })
        })
    }

    return(
        <MainModal
            title="Create Pr"
            open={props.open}
            close={props.close}
        >
            <SearchInstallApp title="Search App" ref={APP} />
            <FunctionalInput title="Title" ref={TITLE} />
            <FunctionalInput title="Delivery Date" type="date" ref={DATE} />
            <Attachment handleRef={ref=>ATTACHMENT.current=ref} />
            <Button text="Create" style={{marginTop:20}} onClick={create} />
        </MainModal>
    )
}

export default Index;