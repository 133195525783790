import { pallete } from "../Colors"
import ReactTooltip from 'react-tooltip';


const Vendor = ({name}) => {
    let n = name.split(' ');
    return(
        <>
            <ReactTooltip  />
            <div className="flex flex-v-center flex-h-center" data-tip={`${name}`} style={{backgroundColor:pallete[n[0].slice(0,1).toLowerCase()],height:35,width:35,borderRadius:'50%',color:'#fff',fontSize:14,fontWeight:'600'}}>
                {n[0].slice(0,1)}{n[1]?n[1].slice(0,1):''}
             </div>
        </>
        
    )
}


export {Vendor}