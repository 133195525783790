import { useRef } from "react";
import { useParams} from 'react-router-dom'
import { post } from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { showToast } from "../../../../Common/Toast";
import { MainModal } from "../../../../NCommon/Modal"



const ResolveConflict = (props) => {

    let {id} = useParams();
    let REASON = useRef();


    let resolve = () => {
        let reason = REASON.current.value;
        if(!reason) {
            showToast({
                type:'success',
                message:'Enter the reason'
            })
            return;
        }

        post(`project/resourceView/resolveConflict/${id}/${props.show.resourceMappingId}`,{remarks:reason},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Conflict resolve successfully"
                })
                props.close();
            }
        })
    }


    return(
        <MainModal
            title={`${props.show.name}`}
            open={props.show}
            close={props.close}
        >
            <FunctionalInput ref={REASON} title="Enter the Reason" />

            <Button onClick={resolve} text="Resolve" style={{marginTop:20}} />
        </MainModal>
    )
}

export default ResolveConflict;