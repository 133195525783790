import ProgressBar from "@ramonak/react-progress-bar"
import './index.css';

const Progress = ({percentage}) => {
    return(
        <ProgressBar 
            completed={percentage}
            />
    )
}

export {Progress}