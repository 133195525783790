import { useEffect, useRef, useState } from "react";
import {useParams,useHistory} from 'react-router-dom';
import { get } from "../../../Model/Network/Config/Axios";
import FilterSearch from "./FilterSearch";
import Types from './Types';
import './filter.css';

const DynamicFilter = (props) => {

    const [type,setType] = useState(props.types[0]);
    const [filterData,setFilterData] = useState([]);
    const {id} = useParams();
    let HISTORY = useHistory();
    let searchKey = useRef();

    useEffect(()=>{
        setType(props.types[0])
    },[props.types])


    let search = (val) => {
        let value = val.toLowerCase();
        if(type === 'Employee Name' || type ==='Responsible Person' || type==='Approver') {
            get(`employees/search`,(e,r)=>{
                if(r) {
                  let suggestions = r.employees.map(val=>({
                      label:val.name,
                      value:`${val.id}`,
                      des:val.email,
                      type: type === 'Employee Name'? 'employeeId' :type==='Responsible Person'?'RESPONSIBLE':'APPROVER'
                  }))
                  setFilterData(suggestions);
                }
            },{
                q:value
            })
        } else if(type === 'Wbs category') {
            get(`project/wbs/wbsItemCategory.search`,(e,r)=>{
                if(r) {
                  let suggestions = r.categories.map(val=>({
                    label:val.name,
                    value:`${val.name}`,
                    type: 'WBS_CATEGORY'
                  }))
                  setFilterData(suggestions);
                }
            },{
                q:value
            })
        } else if(type === 'Task Category') {
            get(`project/task/taskCategory.search`,(e,r)=>{
                if(r) {
                    let suggestions = r.taskCategories.map(val=>({
                        label:val.name,
                        value:`${val.id}`,
                        type: 'TASKCATEGORY',
                        des:val.details
                    }))
                  setFilterData(suggestions);
                }
            },{
                q:value
            })
        } else if(type === 'Wbs Mapping') {
            get(`project/wbs/wbsItemMapping.search/${id}`,(e,r)=>{
                if(r) {
                    let  suggestions = r.itemMappingDetails.map(val=>({
                        label:val.name,
                        value:val.name,
                        id:val.id,
                        type:'WBS'
                    }))
                   setFilterData(suggestions);
                }
            },{
                q:value
            })
        } 
        else if(type === 'Project Task') {
            get(`project/task/task.search/${id}`,(e,r)=>{
                if(r) {
                  let suggestions = r.tasks.map(val=>({
                      label:val.taskName,
                      value:val.taskName,
                      des:`category : ${val.category}`,
                      type:'TASK',
                      id:val.id,
                      other:val.details
                  }))
                  setFilterData(suggestions)

                }
            },{
                q:value
            })
        }
        
        else if(type === 'Task') {
            get(`project/task/task.search`,(e,r)=>{
                if(r) {
                  let suggestions = r.tasks.map(val=>({
                      label:val.taskName,
                      value:val.taskName,
                      des:`category : ${val.category}`,
                      type:'TASK',
                      id:val.id,
                      other:val.details
                  }))
                  setFilterData(suggestions)

                }
            },{
                q:value
            })
        }  else if(type === 'Task Item') {
            get(`project/task/task.search`,(e,r)=>{
                if(r) {
                  let suggestions = r.tasks.map(val=>({
                      label:val.taskName,
                      value:val.taskName,
                      des:`category : ${val.category}`,
                      categoryId:val.categoryId,
                      categoryName:val.category,
                      type:'TASKITEM',
                      url:true
                  }))
                  setFilterData(suggestions)
                }
            },{
                q:value
            })
        }
        else if(type === 'Task Items') {
            get(`project/task/task.search`,(e,r)=>{
                if(r) {
                  let suggestions = r.tasks.map(val=>({
                      label:val.taskName,
                      value:val.taskName,
                      des:`category : ${val.category}`,
                      categoryId:val.categoryId,
                      categoryName:val.category,
                      type:'TASKITEM',
                      url:false
                  }))
                  setFilterData(suggestions)
                }
            },{
                q:value
            })
        }
        
        else if(type === 'Wbs' || type==='WBS') {
            get(`project/wbs/wbsItem.search`,(e,r)=>{
                if(r) {
                    let suggestions = r.wbsItems.map(val=>({
                        label:val.wbsItemName,
                        value:val.id,
                        des:val.description,
                        type: type==='WBS'?'WBS':'WBSITEM'
                    }))
                    setFilterData(suggestions);
             }
         },{
             q:value
         })
         } else if(type === 'Name') {
            get(`project/task/taskMapping.search/${id}`,(e,r)=>{
                if(r) {
    
                  let suggestions = r.tasks.map(val=>({
                      label:val.taskMappingName,
                      value:val.taskMappingName,
                      type:'NAME'
                  }))
                  setFilterData(suggestions);
                }
            },{
                q:value
            })
        }  else if(type === 'Resource') {
            get(`resource/searchResource`,(e,r)=>{
            if(r) {
              let suggestions = r.resources.map(val=>({
                  label:val.name,
                  value:val.name,
                  type:'RESOURCE'
              }))
              setFilterData(suggestions);
            }
        },{
            q:value
        })
      }  else if(type === 'Resource Category') {
            get(`resource/searchCategory`,(e,r)=>{
            if(r) {
              let suggestions = r.resourcesCategories.map(val=>({
                  label:val.name,
                  value:val.name,
                  type:'RESOURCECATEGORY'
              }))
              setFilterData(suggestions);
            }
        },{
            q:value
        })
      }
      
      else if(type === 'Vendor') {
        get(`vendor/search.onboarded`,(e,r)=>{
        if(r) {
          let suggestions = r.vendorDetails.map(val=>({
              label:val.name,
              value:`${val.vendorMappingId}`,
              type:'VENDOR'
          }))
          setFilterData(suggestions);
        }
    },{
        query:value
    })
  }
       
    }

    let onKeyPress = (e) => {
        if(e.key === 'Enter') {
            let value = searchKey.current.value;
            console.log(type);
            if(value && type==='Custom') {
                addAndRemoveFilter({type:'NAME',value,label:value});
                searchKey.current.value=''
            } else if(value && type==='Custom Wbs') {
                addAndRemoveFilter({type:'CUSTOMWBS',value,label:value});
                searchKey.current.value=''
            } else if (value && type === 'Custom Text') {
                addAndRemoveFilter({type:'CUSTOMTEXT',value,label:value});

            }
        }
    }

    let mapSuggestionItem = (item,index) => {
        return(
            <div onClick={()=>{
                if(item.type==='TASKITEM' && item.url) {
                    HISTORY.push(`/app/task/${item.categoryId}?name=${item.categoryName}`)
                } else {
                    addAndRemoveFilter({type:item.type,value:item.value,label:item.label,id:item.id?`${item.id}`:undefined});
                    setFilterData([]);
                    searchKey.current.value ='';
                }
               
            }} className="suggestion-modal-item-nui" key={index}>
                <p style={{fontWeight:500}}>{item.label}</p>
                {item.des?<p style={{fontSize:11,color:'var(--text-light-color)'}}>{item.des}</p>:null}
                {item.other?<p style={{fontSize:11,color:'var(--text-light-color)'}}>{item.other}</p>:null}
            </div>
        )
    }


    let addAndRemoveFilter = (item) => {
        let items = [...props.filters];
        items = items.filter(val=>val.value!==item.value);
        items.push(item);
        props.onFilterChange(items)
       
    }


    return(
        <div style={{position:'relative',width:396}}>
            <div style={{display:'flex',position:'relative'}}>
                <FilterSearch onKeyPress={onKeyPress} ref={searchKey} type={type} onChange={search} />
                <Types onChangeType={(type)=>{setType(type);setFilterData([]);searchKey.current.value ='';}} type={type} types={props.types} />
            </div>
            {
                filterData.length>0 && searchKey.current.value.length>0?
                <div className="suggestion-modal" style={{marginTop:-13,border:'none'}}>
                    {filterData.map(mapSuggestionItem)}
                </div>
                :null
            }
        </div>
    )
}

DynamicFilter.defaultProps = {
    types:[]
}

export {DynamicFilter}
