import React from "react"
import { get } from "../../../../Model/Network/Config/Axios"
import { showToast } from "../../Toast";
import CustomSearch from "../Common/CustomSearch";


let baseUrl = {
    'PROJECT':'project/customLevels/getLevelItems',
    "TASK_CATEGORY":`project/task/taskCategoryLevels/getLevelItems`,
    "TASK":`project/task/taskLevels/getLevelItems`,
    "RESOURCE":`resource/resourceLevels/getLevelItems`
}

let postUrlPath = {
    'PROJECT':'project/customLevels/addLevelItem',
    "TASK_CATEGORY":`project/task/taskCategoryLevels/addLevelItem`,
    "TASK":`project/task/taskLevels/addLevelItem`,
    "RESOURCE":`resource/resourceLevels/addLevelItem`
}

class ProjectCustomTag extends React.Component {

        constructor(p) {
            super(p);
            this.state = {
                items:[],
                customs:{}
            }
        }
      
        componentDidMount() {
            get(`setting/miscConfig/getHierarchyLevel/${this.props.type}`,(e,r)=>{
                if(r) {
                    this.setState({
                        ...this.state,
                        items:r.hierarchyLevels
                    })
                }
            })
            if(this.props.handleRef) {
                this.props.handleRef(this);
            }
        }
    
       
    
    
    
         mapItem = (item,index) => {
             let nextPath='';
             let post = postUrlPath[this.props.type]
             if(this.props.type === 'PROJECT') {
                nextPath=`${this.props.projectId}/${item.hierarchyLevelId}`;
                post= post + `/${this.props.projectId}`
             } else {
                nextPath=`${item.hierarchyLevelId}`
             }
             let parentId = undefined;
             if(index>0 && this.state.customs[index-1]) {
                parentId = this.state.customs[index-1].levelId;
             }

             return(
                <CustomSearch onClick={(val)=>{
                    let obj = {...this.state.customs};
                    obj[index] = val;
                    console.log(obj);
                    this.setState({
                        ...this.state,
                        customs:obj
                    })
                }} parentId={parentId} post={post} type={this.props.type} path={`${baseUrl[this.props.type]}/${nextPath}`} id={item.hierarchyLevelId} handleRef={val=>this[`${item.name}::${index}`]=val} title={item.name} key={index} projectId={this.props.projectId} />
            )
        }
    
    
         getValue = (cb) => {
             let data = [];
             let count = 0;
             this.state.items.forEach((val,index)=>{
                let value = this[`${val.name}::${index}`].getValue();
                if(!value) {
                    showToast({
                        type:'error',
                        message:`${val.name} is required`
                    })
                    return;
                }
                data.push({name:value.name,levelId:value.levelId});
                count++;
             })
             if(cb) {
               cb(data);
             }
    
         }

    render() {
        return(
            <>
                {this.state.items.map(this.mapItem)}
            </>
        )
}
}
    



export {ProjectCustomTag}