import React from 'react';
import { connect } from 'react-redux';
import Props from 'prop-types';
import Left from './leftArrow.png';
import Right from './rightArrow.png';
import { get } from '../../../Utils/Crypto';
import filter from './filer.png';
import HeaderModal from './headerModal';
import filter2 from './filter.png';

let COUNT = 10;

class Tables extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            headerModal: false
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
        window.addEventListener('click', this.onClick)

    }


    componentWillUnmount() {
        window.removeEventListener('click', this.onClick)
    }

    onClick = (e) => {
        if (this.state.headerModal && !this.profile.contains(e.target)) {
            this.setState({
                ...this.state,
                headerModal: false
            })
        }
    }

    setPageZero = () => {
        this.setState({
            ...this.state,
            page: 0
        })
    }


    mapHeader = (item, index) => {

        let common = {
            fontWeight: 600,
            color: 'var(--text-light-color)',
            paddingBottom: 5,
            fontSize: 12,
            cursor: 'pointer',
            position: 'relative',
            ...item.style
        }

        if (item.width) {
            common = {
                ...common,
                width: item.width
            }
        } else if (item.flex) {
            common = {
                ...common,
                flex: item.flex
            }
        } else {
            common = {
                ...common,
                flex: 1
            }
        }

        if (item.isFilter) {
            common = {
                ...common,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        }



        return (
            <div key={index} style={common} >
                {item.name}
                {item.isFilter ? <img onClick={() => {
                    if (this.state.headerModal === index) {
                        this.setState({
                            ...this.state,
                            headerModal: undefined
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            headerModal: index
                        })
                    }

                }} src={(this.props.filter && this.props.filter.searchKey === item.search) ? filter2 : filter} alt="" style={{ height: 14, width: 14, marginLeft: 10 }} /> : null

                }
                {
                    this.state.headerModal === index ?
                        <HeaderModal
                            data={item.data}
                            search={item.search}
                            onclose={() => {
                                this.setState({
                                    ...this.state,
                                    headerModal: false
                                })
                            }}
                            filter={this.props.filter}
                            checked={this.props.filter && this.props.filter.searchKey}
                        />
                        : null
                }
            </div>
        )
    }


    render() {

        if (this.props.count) {
            COUNT = this.props.count;
        }

        let start = this.state.page * COUNT + 1;
        let end = (this.state.page + 1) * COUNT;


        if (end > this.props.data.length) {
            end = this.props.data.length;
        }




        let data = this.props.data;
        if (this.props.pagination) {
            data = data.slice(start - 1, end);
        }

        let expanded = get(`expanded`);

        return (
            <div style={{
                width: '100%',
                marginTop: 5,
                marginBottom: 30
            }} className="table">

                {/* Header */}

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 30,
                    borderBottom: '1px solid var(--border-color)',
                    ...this.props.fixedHeader ? {
                        position: 'fixed',
                        left: expanded ? 200 : 80,
                        right: 30,
                        height: 60,
                        borderBottom: '1px solid var(--border-color)',
                        zIndex: 1,
                        backgroundColor: '#fff',
                        top: 181,
                        paddingLeft: 30
                    } : {

                        }

                }} ref={ref => this.profile = ref}>
                    {this.props.header.map(this.mapHeader)}
                </div>

                {/* Body */}

                <div className="table-body">
                    {data.map(this.props.mapItem)}
                </div>

                {/* Footer */}

                {
                    this.props.pagination ?



                        <div className="table-footer">
                            <div style={{
                                marginLeft: 'auto',
                                display: 'flex',
                                alignItems: 'center'
                            }}>

                                <p style={{
                                    fontSize: 16,
                                    color: 'var(--text-color)'
                                }}>
                                    <span style={{
                                        fontWeight: 600,
                                        paddingRight: 5
                                    }}>{start}</span>
                            -
                            <span style={{
                                        fontWeight: 600,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>{end}</span>
                             of
                            <span style={{
                                        fontWeight: 600,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>{this.props.data.length}</span>
                                </p>
                                <div style={{
                                    display: 'flex',
                                    border: '1px solid var(--border-color)',
                                    height: 30,
                                    width: 60,
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        borderRight: '1px solid var(--border-color)',
                                        height: 30,
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer'
                                    }} onClick={() => {
                                        if (this.state.page > 0) {
                                            this.setState({
                                                ...this.state,
                                                page: this.state.page - 1
                                            })
                                        }
                                    }}>
                                        <img src={Left} alt="" style={{
                                            height: 14,
                                            width: 14,
                                        }} />
                                    </div>
                                    <div style={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer'
                                    }} onClick={() => {
                                        if (end < this.props.data.length)
                                            this.setState({
                                                ...this.state,
                                                page: this.state.page + 1
                                            })
                                    }}>
                                        <img src={Right} alt="" style={{
                                            height: 14,
                                            width: 14
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }

            </div>
        )
    }

}


Tables.propsTypes = {
    header: Props.array,
    data: Props.array,
    mapItem: Props.func,
    pagination: Props.bool
}

Tables.defaultProps = {
    header: [],
    data: [],
    mapItem: () => { },
    pagination: true
}

const mapStateToProps = (state) => {
    return {
        filter: state.Filter.filter
    }
}

const Table = connect(mapStateToProps, null)(Tables);

export { Table }