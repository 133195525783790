import   { useEffect, useRef, useState } from 'react';
import { get, post } from '../../../../../../Model/Network/Config/Axios';
import { RightModal } from '../../../../../Common/Modal/RightModal';
import {Button} from '../../../../../Common/Button';
import Option from './Options';
import { showToast } from '../../../../../Common/Toast';
import _ from 'lodash';
import { FunctionalInput } from '../../../../../Common/FloatInput/FunctionalInput';
import {ToggleButton} from '../../../../../Common/Toggle/index'
import { MainModal } from '../../../../../NCommon/Modal';


const AddUser = (props) => {


    const [projects,setProject] = useState([]);
    const [module,setModule] = useState([]);
    const [write,setWrite] = useState(false);
    const [superUser,setSuperUser] = useState(false);
    let Name=useRef(),Email=useRef(),Mobile=useRef();

    const {item} = props;


    useEffect(()=>{
        get(`setting/accessManagement/modules`,(e,r)=>{
            if(r) {

                setModule(r.modules.filter(val=>val!=='NONE').map(val=>{
                    return {
                        ...val,
                        name:val,
                        key:val,
                        active:_.includes(props.item.roles,val)
                    }
                }))
               
            }
        })
        get(`project/all`,(e,r)=>{
            if(r) {
                setProject(r.projects.map(val=>{
                    return{
                        ...val,
                        key:val.projectId,
                        active:_.includes(item.projects,val.projectId)
                    }
                }))
               
            }
        })
    },[props])


   


let addUser = () => {
    let name = Name.current.value;
    let email = Email.current.value;
    let mobile = Mobile.current.value;
    let roles = module.filter(val=>val.active).map(val=>val.name)
    let project =projects.filter(val=>val.active).map(val=>val.key);

    if(!name) {
        showToast({
            type:'error',
            message:"Please enter the name"
        })
        return;
    }

    if(!email) {
        showToast({
            type:'error',
            message:"Please enter the email address"
        })
        return;
    }

    if(!mobile) {
        showToast({
            type:'error',
            message:"Please enter the mobile number"
        })
        return
    }

    if(roles.length  === 0) {
        showToast({
            type:'error',
            message:"Select atleast one module"
        })
        return
    }

    if(project.length === 0) {
        showToast({
            type:'error',
            message:"Select atleast one organisation"
        })
        return;
    }

    let data = {
        email,
        name,
        mobile,
        roles,
        projects:project,
        write,
        writeModules:roles,
        super:superUser
    }

    post(`setting/accessManagement/update/${props.item.id}`,data,(e,r)=>{
        if(r) {
            showToast({
                type:'success',
                message:"User added successfully"
            })
            props.init();
        }
    })

    
}
   

    return(
        <MainModal 
            open={props.item}
            title="Edit User"
            desc="Fill up the details and add user"
            close={()=>props.onClose(false)}
            >
                <FunctionalInput defaultValue={props.item.name} disabled={true} title="Name" type="text" ref={Name} />
                <FunctionalInput defaultValue={props.item.email} disabled={true} type="text" title="Email" ref={Email} />
                <FunctionalInput defaultValue={props.item.mobile} disabled={false} type="number" title="Mobile" ref={Mobile} />
                <Option title="Select Project" desc="Select project to give access" options={projects} onChange={setProject}  />
                <Option title="Select Module" desc="Select Module to give access" options={module} onChange={setModule}  />
                <ToggleButton title="Read/write Access" text="Do you want to enable write access" active={write} onChange={setWrite} />
                <ToggleButton title="Super User" text="Do you want to make this user as super" active={superUser} onChange={setSuperUser} />
                <Button text="Update User" onClick={addUser} style={{marginTop:20}} /> 

        </MainModal>
    )

}

export default AddUser