import React from 'react';
import { Button } from '../../../../../Common/Button';
import { post } from '../../../../../../Model/Network/Config/Axios';
import { SearchSelect } from '../../../../../Common/Search/SearchSelect/SearchSelect';
import { showToast } from '../../../../../Common/Toast';
import { FloatInput } from '../../../../../Common/FloatInput';
import { SearchTaskMappingItemN } from '../../../../../Common/Search/SearchTaskMappingItemN';
import { Info } from '../../../../../Common/Info';
import { RightModal } from '../../../../../Common/Modal/RightModal';


class Index extends React.Component {


    state = {
        buttonloading: false
    }


    update = () => {
        let predecessorRelation = this.pRelation.getValue();
        if (!predecessorRelation) {
            showToast({
                type: 'error',
                message: 'Please select the predecessor relation'
            })
            return
        }
        let lagDay = this.lag.getValue();
        if (!lagDay) {
            showToast({
                type: 'error',
                message: 'Please select the lag day'
            })
        }
        let { taskMappingId } = this.props;
        this.setState({
            ...this.state,
            buttonloading: true
        })
        post(`project/task/taskMapping.update/${taskMappingId.id}`, {
            predecessorRelation: predecessorRelation.value,
            responsiblePerson: taskMappingId.responsiblePerson? taskMappingId.responsiblePerson.code:null,
            approver: taskMappingId.approver? taskMappingId.approver.code:null,
            predecessorTaskId: this.predecessor.getValue().length > 0 ? this.predecessor.getValue().map(val => val.value) : null,
            lagDay: +lagDay,
            totalDays: +taskMappingId.totalDaysRequired,
            totalLabourRequired: +taskMappingId.totalLabourRequired,
            projectId: +this.props.id

        }, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Predecessor update sucessfully'
                })
                this.setState({
                    ...this.state,
                    buttonloading: false
                })
                this.props.close();
            }
            if (e) {
                showToast({
                    type:'error',
                    message:e.data.message
                })
                this.setState({
                    ...this.state,
                    buttonloading: false
                })
               
            }
        })
    }
    


    render() {
        if(!this.props.show) {
            return <></>
        }
        const {  id, taskMappingId } = this.props;
        return (
            <RightModal
                title="Change Predecessor"
                desc="Change Predecessor of this task"
                onClose={this.props.close}
                show={this.props.show}
            >
                <Info text={taskMappingId.wbsItemTaskName} style ={{marginLeft:'-10%',marginRight:'-10%',marginTop:-20,marginBottom:40}} />
                <div style={{
                    display: 'flex',
                    flex: 1
                }}>
                    <div style={{
                        flex: 1,
                    }}>
                        <SearchTaskMappingItemN
                            title="Select Predecessor"
                            prefill={taskMappingId.predecessorId? {
                                label:taskMappingId.predecessorName,
                                value:taskMappingId.predecessorId
                            }  : false}
                            projectId={id}
                            handleRef={ref => this.predecessor = ref}

                        />
                    </div>

                </div>

                <div style={{
                    flex: 1
                }}>
                    <SearchSelect
                        title="Predecessor Relation"
                        handleRef={ref => this.pRelation = ref}
                        value={{
                            key: taskMappingId.predecessorRelation,
                            value: taskMappingId.predecessorRelation
                        }}
                        items={[
                            {
                                key: 'START',
                                value: 'START'
                            },
                            {
                                key: 'FINISH',
                                value: 'FINISH'
                            },
                            {
                                key: 'NONE',
                                value: 'NONE'
                            }
                        ]}
                    />
                </div>

                <div>
                    <FloatInput
                        type="number"
                        handleRef={ref => {
                            this.lag = ref;
                            this.lag.setValue(taskMappingId.lagDay)
                        }}
                        title="+/- days wrt predecessor"
                    />
                </div>

                <Button
                    text="Update"
                    loading={this.state.buttonloading}
                    style={{
                        marginTop: 40
                    }}
                    onClick={() => {
                        this.update()
                    }}
                />
            </RightModal>
        )
    }
}

export default Index;