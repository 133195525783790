import {Switch,Route} from 'react-router-dom';
import List from './List';
import TimeLine from './TimeLine';

const Employee = () => {

    return(
            <Switch>
                <Route path="/app/project/images/:id/employee" component={List} exact />
                <Route path="/app/project/images/:id/employee/:code/details" component={TimeLine} exact />
            </Switch>
    )
}

export default Employee;