import React from 'react';
import Moment from 'moment';
import { SearchSelect } from '../../Common/Search/SearchSelect/SearchSelect';
import { EmployeeSearch } from '../../Common/Search/SearchEmployee';
import { showToast } from '../../Common/Toast';
import { Button } from '../../Common/Button';
import { FloatInput } from '../../Common/FloatInput';
import { MultiAdhocTask } from '../../Common/Search/MultiAdhocTask';
import { SearchTaskMappingItemN } from '../../Common/Search/SearchTaskMappingItemN';
import { post } from '../../../Model/Network/Config/Axios';
import { SearchVendor } from '../../Common/Search/SeachVendor';


class Index extends React.Component {

    constructor(props) {
        super(props);
        let details = this.props.details;
        details = details.tasksMapping;
        this.state = {
            buttonLoading: false,
            weekValue:undefined,
            dayValue:undefined,
            monthValue:undefined,
            recurring:false,
            type:undefined

        }
    }

    componentDidMount() {
        let item = this.props.details.tasksMapping;
        this.lagDay.setValue(item.lagDay);
        this.buffer.setValue(item.buffer?item.buffer:0)
        this.totalDays.setValue(item.totalDaysRequired);
        if(item.vendor) {
            this.vendor.setValue(item.vendor);
            this.vendor.setName(item.vendor.name);
        }
        if(item.actualStartDate) {
            this.actualStartDate.setValue(Moment(item.actualStartDate).format('YYYY-MM-DD'))
        }
        if(item.actualCompletionDate) { 
            this.actualEndDate.setValue(Moment(item.actualCompletionDate).format('YYYY-MM-DD'))
        }
        this.setState({
            ...this.state,
            recurring:item.recurring
        },()=>{
            this.nextCall();
        })
    }

    nextCall = () => {
       if(this.state.recurring) {
            this.setState({
                ...this.state,
                type:this.props.details.tasksMapping.recurringType
            },()=>{
                this.nextCall1()
            })
       }
     }

     nextCall1 = () =>{
        let type = this.props.details.tasksMapping.recurringType;
        let value = this.props.details.tasksMapping.recurringValue;       
     }


    getProjectId = () => {
        return this.props.projectId;
    }

    update = () => {
        let item = this.props.details.tasksMapping;
        let predecessorRelation = this.pRelation.getValue();
        let responsiblePerson = this.responsiblePerson.getValue();
        let approver = this.approver.getValue();
        let predecessorTaskId = this.ptaskId.getValue();
        let lagDay = this.lagDay.getValue();
        let totalDays = this.totalDays.getValue();
        let totalLabourRequired = this.totalLabour.getValue();
        let pred = predecessorTaskId.map(val => val.value);
        let buffer = +this.buffer.getValue();
        let vendor = this.vendor.getValue();

        let actualStartDate = this.actualStartDate.getValue()?this.actualStartDate.getValue():null;
        let actualEndDate = this.actualEndDate.getValue()?this.actualEndDate.getValue():null;

        if (actualStartDate) {
            actualStartDate = Moment(actualStartDate, 'YYYY-MM-DD');
            actualStartDate = Moment(actualStartDate).valueOf();
        }
        if (actualEndDate) {
            actualEndDate = Moment(actualEndDate, 'YYYY-MM-DD');
            actualEndDate = Moment(actualEndDate).valueOf();
        }

        if (actualStartDate && actualEndDate) {
            let c1 = Moment(this.actualStartDate.getValue(), 'YYYY-MM-DD');
            let c2 = Moment(this.actualEndDate.getValue(), 'YYYY-MM-DD');
            if (Moment(c1).valueOf() > Moment(c2).valueOf()) {
                showToast({
                    type: 'error',
                    message: 'Start Date is not to be greater than end date'
                })
                return;
            }
        }

        let data = {
            predecessorRelation: predecessorRelation.key,
            responsiblePerson: responsiblePerson? responsiblePerson.code ? responsiblePerson.code : responsiblePerson.employeeCode:null,
            approver: approver? approver.code ? approver.code : approver.employeeCode:null,
            predecessorTaskId: pred,
            lagDay: +lagDay,
            totalDays: +totalDays,
            totalLabourRequired: +totalLabourRequired,
            projectId: +this.getProjectId(),
            actualStartDate,
            actualEndDate,
            buffer
        }

        let adhoc = this.multiAdhoc.getValue();
        if (adhoc.length > 0) {
            data.requiredAdhocTasks = this.multiAdhoc.getValue().map(val => val.label)
        }
        if(vendor) {
            data.vendorId = `${vendor.vendorMappingId?vendor.vendorMappingId:vendor.id}`
        }

        this.setState({
            ...this.state,
            buttonLoading: true
        })

        if(this.state.recurring) {
            data.recurring=true
            data.recurringType = this.state.type;
            
        }


        post(`project/task/taskMapping.update/${item.id}`, data, (e, r) => {
            if (r) {
                showToast({
                    type: 'success',
                    message: 'Task update successfully'
                })
                this.setState({
                    ...this.state,
                    buttonLoading: false
                })
                this.props.onClose();

            }
            if (e) {
                showToast({
                    type: 'error',
                    message: e.data.message
                })
                this.setState({
                    ...this.state,
                    buttonLoading: false
                })
            }
        })


    }

    render() {
        let { details } = this.props;
        let item = details.tasksMapping;
        let pid = null;
        pid = item.predecessorTask ? {label:item.predecessorTask.wbsItemTaskName,value:item.predecessorTask.id} : false;
        return (
            <div style={{
                flex: 1,
                height: 'calc( 100vh - 150px)',
                position: 'relative'
            }}>


                <div style={{
                    display: 'flex'
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>

                        <SearchSelect
                            title="Predecessor Relation"
                            value={{
                                key: item.predecessorRelation,
                                value: item.predecessorRelation
                            }}
                            handleRef={ref => this.pRelation = ref}
                            items={[
                                {
                                    key: 'START',
                                    value: 'START'
                                },
                                {
                                    key: 'FINISH',
                                    value: 'FINISH'
                                },
                                {
                                    key: 'NONE',
                                    value: 'NONE'
                                }
                            ]}
                        />
                    </div>
                    <div style={{
                        flex: 1,
                    }}>
                        <EmployeeSearch
                            title="Responsible Person"
                            value={item.responsiblePerson}
                            handleRef={ref => this.responsiblePerson = ref}
                        />
                    </div>
                </div>
                <div style={{
                    display: 'flex'
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <EmployeeSearch
                            value={item.approver}
                            title="Approver"
                            handleRef={ref => this.approver = ref}
                        />
                    </div>

                </div>
                <div style={{
                    display: 'flex',
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <FloatInput
                            type="date"
                            handleRef={ref => this.actualStartDate = ref}
                            title="Actual Start Date"
                        />
                    </div>
                    <div style={{
                        flex: 1,

                    }}>
                        <FloatInput
                            type="date"
                            handleRef={ref => this.actualEndDate = ref}
                            title="Actual End Date"
                        />
                    </div>
                </div>
                <div style={{
                    display: 'flex'
                }}>
                    <div style={{
                        flex: 1,
                        marginRight: 20
                    }}>
                        <FloatInput
                            handleRef={ref => this.totalDays = ref}
                            title="Total Days"
                        />
                    </div>
                    <div style={{
                        flex: 1,
                    }}>
                        <FloatInput
                            handleRef={ref => this.totalLabour = ref}
                            title="Total Labour required"
                        />
                    </div>
                </div>
                <div style={{
                    display: 'flex'
                }}>
                    <div style={{
                        display: 'flex',
                        marginRight: 20,
                        flex: 1
                    }}>
                        <FloatInput
                            handleRef={ref => this.lagDay = ref}
                            title="+/- days wrt predecessor"
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flex: 1
                    }}>
                        <FloatInput
                            handleRef={ref => this.buffer = ref}
                            title="Buffer"
                        />
                    </div>
                </div>

                <MultiAdhocTask
                            handleRef={ref => this.multiAdhoc = ref}
                            title="Adhoc Task"
                            projectId={this.getProjectId()}
                        />      

                <SearchTaskMappingItemN
                            handleRef={ref => this.ptaskId = ref}
                            projectId={this.getProjectId()}
                            title="Predecessor Task"
                            prefill={pid}

                        />

                <SearchVendor
                    title="Search Vendor (optional)"
                    handleRef={ref=>this.vendor=ref}

                />
                 {
                    this.state.recurring ?
                    <>
                        <SearchSelect title="Type of Recurring" value={{value:this.props.details.tasksMapping.recurringType}} onChange={(type)=>{
                             this.setState({
                                type
                            })
                        }} items={[{value:'DAILY',key:'DAILY'},{value:"WEEKLY",key:"Weekly"},{key:'FORTNIGHTLY',value:'FORTNIGHTLY'},{value:"MONTHLY",key:'MONTHLY'},{value:"QUARTERLY",key:'QUARTERLY'},{key:'SEMIANNUAL',value:'SEMIANNUAL'},{value:'ANNUALLY',key:'Yearly'}]} />

                      
                    </>
                    
                    
                    
                    :null
                }

                <div>
                    <Button
                        text="Update Details"
                        style={{
                            marginTop: 30
                        }}
                        onClick={() => {
                            this.update()
                        }}
                        loading={this.state.buttonLoading}
                    />
                </div>
            </div>
        )
    }
}

export default Index;