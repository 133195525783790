import React from 'react';
import { buildrun } from '../../Component/Common/Images';
import { showToast } from '../../Component/Common/Toast/index';
import { post } from '../../Model/Network/Config/Axios';
import Input from '../Common/Input';



class Index extends React.Component {


    render() {
        return(
    <div style={{ display: 'flex',  flex: 1,  height: '100vh', boxSizing: 'border-box', }} >
           <div style ={{
               width:'50%',
               padding:'20px',
               boxSizing:'border-box',
               height:'100vh'
           }}>
               <div style ={{ display:'flex',justifyContent:'space-between',height:60}}>
                   <div style ={{ display:'flex', alignItems:'center' }}>
                       <img src={buildrun} alt="BuildRun" style ={{height:60,width:60}} />
                       <p style ={{fontWeight:'900',fontSize:24,textTransform:'uppercase',marginTop:10,color:'var(--text-color)'}}>BuildRun</p>
                   </div>
               </div>

               <div style={{height:'calc(100vh - 180px)',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                   <h2 style ={{fontWeight:'900',fontSize:38,color:'var(--text-color)'}}>Forgot</h2>
                   <p style ={{fontSize:14,marginTop:8,fontWeight:'300',color:'var(--text-light-color)'}}>Login to your account for manage your construction</p>
                   <div style ={{display:'flex',position:'relative',width:500,marginTop:50}}>
                       <p style ={{borderTop:'0.5px solid #eee',height:1,width:200}} />
                       <p style={{fontSize:13,letterSpacing:1,width:170,position:'relative',top:-10,textAlign:'center',textTransform:'uppercase',fontWeight:'500',color:'var(--text-light-color)'}}>Login with Email</p>
                       <p style ={{borderTop:'0.5px solid #eee',height:1,width:200}} />

                   </div>
                   <div style={{width:500,margin:"0px auto",marginTop:50}}>

                       <Input type='email' handleRef={ref=>this.resetEmail=ref} title="Enter Email" placeholder="Email to sent link"/>
                       <div onClick={()=>{
                           this.props.history.push('/')
                       }} style={{textAlign:'right',fontWeight:600,color:'var(--active-color)',cursor:'pointer'}}>Already have Credentials ?</div>

                       <div onClick={()=>{
                           console.log('hi karan')
                        let email = this.resetEmail.getValue();
                        if(!email) {
                            showToast({
                                type:'error',
                                message:"Please enter the email"
                            })
                            return
                        }
                        post(`auth/otp`, { username: this.resetEmail.getValue() }, (e, r) => {
                            if (r) {
                                alert("Reset Link sent successfully")
                                this.props.history.push(`/twofactor?email=${email}`)
                            } else {
                                showToast({
                                    type:'error',
                                    message:e.data.message
                                })
                            }
                        })
                       }} className="buttons" style={{marginTop:40}}>Send Reset Link</div>

                       <p style ={{marginTop:20,fontSize:14,letterSpacing:0.3}}>By Login  <span style={{color:'var(--active-color)',cursor:'pointer'}}>Privacy Policy</span> and <span style={{color:'var(--active-color)',cursor:'pointer'}}>Terms of Service</span> apply to the respective user</p>

                   </div>

               </div>

           </div>

           <div style ={{
               flex:1,
               backgroundColor:'#f2f3f7',
               height:'100vh',
               display:'flex',
               alignItems:'center',
               justifyContent:'center',
               flexDirection:'column'
           }}>
               <div>

               </div>
               <p style ={{
                   textAlign:'center',
                   color:'var(--text-light-color)',
                   letterSpacing:0.4
               }}>
                   Buildrun is a true construction process automation platform that helps builders plan, deploy,   <br/>  track & manage end to end project development
               </p>
               <h2 style={{fontWeight:900,color:'var(--text-color)',marginTop:10}}>Digitize-Your-Construction</h2>
           </div>


       </div>
        )
    }
}


export default Index