import React from 'react';
import { Route, Switch } from 'react-router-dom';


/* pages */
import Policy from './Policy';


class Index extends React.Component {


    render() {
        return (
            <div style={{
                height: 'calc( 100vh - 55px )',
                overflowY: 'auto'
            }}>
                <Switch>
                    <Route path='/app/policy' component={Policy} exact />
                </Switch>
            </div>
        )
    }
}

export default Index;