import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import { get, post } from '../../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../../Common/PageLoader';
import { ToggleButton } from '../../../../../Common/Toggle';
import { HeaderWrapper } from '../../../../../NCommon/HeaderWrapper';
import { NavigationWrapper } from '../../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../../NCommon/PageWrapper';
import Data from "../../AllSetting/data";


const Index = () => {

    let HISTORY = useHistory();
    let [loading,setLoading] = useState(true);
    const [config,setConfig] = useState({
        managementDate:false,
        shutteringAutomation:false
    });

    useEffect(()=>{
        get(`setting/miscConfig/hierarchyTypes`,(e,r)=>{
            if(r) {
                setLoading(false);
            }
        })
    },[])

    useEffect(()=>{
        INIT();
    },[])

    let INIT = () => {
        get(`auth/config`,(e,r)=>{
            if(r) {
                  setConfig({
                    managementDate:r.organization.managementDate,
                    shutteringAutomation:r.organization.shutteringAutomation,
                    galleryImage:r.organization.galleryImage
                  })
            }
        })
    }

    let mapModule = (item,index) => {
        return(
            <div className="box-outer-layout" key={index} style={{marginBottom:20}}>
                <div className="box-inner-layout" style={{
                    minHeight:120,
                    background:"#fff"
                }}>
                    <p style={{fontSize:16}}>{item.name}</p>
                    <p style={{
                        color:'var(--active-color)',
                        fontWeight:'bold',
                        marginTop:100
                    }} onClick={()=>{
                        HISTORY.push(item.path)
                    }}>View</p>
                </div>
            </div>
        )
    }

    if(loading) {
        return <PageLoader />
    }
    return(
        <>  
            <HeaderWrapper />
            <PageWrapper>
                <NavigationWrapper items={[{name:'All Setting'}]} />
                <div className="flex-wrap">
                    {Data.map(mapModule)}
                </div>
                <NavigationWrapper items={[{name:'Config Setting'}]} />
                <div className='title-container-1'>
                    <ToggleButton title="Management Date" text="Enable or disable of management date" onChange={()=>{
                        post(`auth/updateConfig`,{
                            managementDate:!config.managementDate,
                            shutteringAutomation:config.shutteringAutomation
                        },(e,r)=>{
                            if(r) {
                                INIT();
                            }
                        })
                    }} active={config.managementDate} />
                    <ToggleButton title="Shuttering" text="Enable or disable of shuttering" active={config.shutteringAutomation}  
                        onChange={()=>{
                            post(`auth/updateConfig`,{
                                managementDate:config.managementDate,
                                shutteringAutomation:!config.shutteringAutomation
                            },(e,r)=>{
                                if(r) {
                                    INIT();
                                }
                            })
                        }}
                    />
                     <ToggleButton title="Image Gallery" text="Switch on toggle to enable photo upload from image gallery" active={config.galleryImage}  
                        onChange={()=>{
                            post(`auth/updateConfig`,{
                                managementDate:config.managementDate,
                                shutteringAutomation:config.shutteringAutomation,
                                galleryImage:!config.galleryImage
                            },(e,r)=>{
                                if(r) {
                                    INIT();
                                }
                            })
                        }}
                    />
                </div>
            </PageWrapper>
        </>
    )
}

export default Index;