import ReactApexChart from "react-apexcharts"
import Props from 'prop-types';
import Moment from 'moment';
/*
	<LineGraph height={400} title="Graph" data={[{name:"Hello",data:[121,222]}]} items={["Min Price","Max Price"]}/>
*/
function LineGraph(props) {
	return (
		<div>
			<ReactApexChart options={{
				chart: {
					type: 'line',
					height: props.height,
					toolbar: {
						tools: {
							download: false,
							selection: false,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true,
							customIcons: []
						},
						autoSelected: 'zoom'
					}
				},
				title: {
					text: props.title,
					align: 'left'
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight'
				},
				grid: {
					row: {
						colors: ['#f3f3f3', 'transparent'],
						opacity: 0.5
					},
				},
				xaxis: {
					categories: props.items,
					
				},
				fill: {
					opacity: 1
				},
				yaxis:{
					show:true,
					labels:{
						formatter:(value)=>{
							return Moment(value).format('DD-MM-YY')
						}
					}
				}
			}} series={props.data} type="line" height={props.height} />
		</div>
	)
}


LineGraph.propTypes = {
	data : Props.array.isRequired,
	items : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired
}

LineGraph.defaultProps = {
	y: "date",
	title:'Project Completion Date',
	height:500
}

export {
	LineGraph
}

export default LineGraph
