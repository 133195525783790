import { useRef, useState } from "react";
import { RightModal } from "../../../Common/Modal/RightModal"
import { Button } from '../../../Common/Button';
import { showToast } from "../../../Common/Toast";
import { post } from "../../../../Model/Network/Config/Axios";
import { SearchDrawingCategory } from "../../../Common/Search/SearchDrawingCategory";
import { FunctionalInput } from "../../../Common/FloatInput/FunctionalInput";


const MapDrawing = (props) => {

    let DRAWING = useRef(),LAGDAY=useRef();
    let [buttonLoading,setButtonLoading] = useState(false);


    let map = () =>{
        let drawingCategoryId = DRAWING.current.getValue()?DRAWING.current.getValue().id:undefined,lagDays=LAGDAY.current.value;
        if(!drawingCategoryId) {
            showToast({
                type:'error',
                message:'Please select the drawing category'
            })
            return;
        }
        if(!lagDays) {
            showToast({
                type:'error',
                message:"Please select the lagday"
            })
        }

        let data = {
            drawingCategoryId,
            lagDays:+lagDays,
            taskId:props.id
        }
        setButtonLoading(true);
        post(`project/task/drawing/addDrawingTemplate`,data,(e,r)=>{
            if(r) {
                setButtonLoading(false);
                showToast({
                    type:'success',
                    message:"Drawing template map successfully"
                })
                props.init();
            } else {
                setButtonLoading(false);
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
        
    }

    return(
        <RightModal
            title="Map Drawing"
            desc="Fill up the details and map drawing"
            show={true}
            onClose={props.onClose}
        >
            <SearchDrawingCategory handleRef={ref=>DRAWING.current=ref} title="Drawing Category" />
            <FunctionalInput title="Lag Day" type="number" ref={LAGDAY} />
            <Button text="Map" style={{marginTop:30}} onClick={map} loading={buttonLoading} />

        </RightModal>
    )
}

export default MapDrawing;