import { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import {AccessButton} from '../../../Common/Button/index';
import { EmployeeModal } from "../../../Common/ModalComponent/EmployeeModal";
import { get, post } from "../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../Common/PageLoader";
import { showToast } from "../../../Common/Toast";
import { HeaderWrapper } from "../../../NCommon/HeaderWrapper";
import { PageWrapper } from "../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../NCommon/NavigationWrapper";
import { useProject } from "../../../Hooks/ProjectDetails";
import {Table} from '../../../NCommon/Tables/index';
import { Card } from "../../../NCommon/Ui/CardLayout";
import { List } from "../../../NCommon/Ui/List";
import { TableMenu } from "../../../NCommon/Tables/TableMenu";


const Index = () => {

    const [modal,setModal] = useState(false);  
    const [details,setDetails] = useState({});  
    const [loading,setLoading] = useState(true);
    const {id} = useParams();
    const project = useProject();

    useEffect(()=>{
        INIT(id)
    },[id])

    let INIT = (projectId) => {
        get(`project/details/${projectId}`, (e, r) => {
            if (r) {
               setDetails(r);
               setLoading(false);
            }
        })
    }

    let ADDTAG = (id,value) => {
        let employees = details.whatsappEmpList.map(val=>val.id);
        employees.push(value.id);
        post(`project/whatsapp.update/${id}`,{employees},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:'User Map successfully'
                })
                INIT(id);
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }
    let REMOVETAG = (item) => {
        let employees = details.whatsappEmpList.filter(val=>val.id!==item.id);
        employees=employees.map(val=>val.id);
        post(`project/whatsapp.update/${id}`,{employees},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:'User remove successfully'
                })
                INIT(id);
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card key={index} title={item.name} desc={item.mobile}>
                <div onClick={(e)=>{
                    e.stopPropagation();
                    REMOVETAG(item);
                }} style={{fontSize:14,fontWeight:'600',color:'var(--red-color)',marginTop:5}}>Remove</div>
            </Card>
        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.name}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.mobile}</p>
                </div>
                <TableMenu items={[{name:'Remove',read:true,onClick:(e)=>{e.stopPropagation();REMOVETAG(item);}}]} />
            </List>
        )
    }

    if(loading) {
        return <PageLoader />
    }

    return(
        <>
            <HeaderWrapper />
            <PageWrapper>
                <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`Whatsapp ( ${details.whatsappEmpList.length} )` }]}>
                    <AccessButton text="Tag Employee" inverted={true} addsign={true} onClick={()=>{setModal(true)}} />
                </NavigationWrapper>
                <Table
                    DATA={details.whatsappEmpList}
                    mapCardLayout={mapCardLayout}
                    mapListLayout={mapListLayout}
                    headers={[
                        {
                            name:'Details',
                            flex:1
                        },
                        {
                            name:'Mobile Number',
                            flex:1
                        },
                        {
                            name:'actions',
                            width:50
                        }
                    ]}
                 />
            </PageWrapper>
            {modal?<EmployeeModal onClose={()=>{setModal(false)}} onSelect={(value)=>{
                setModal(false)
                ADDTAG(id,value)
            }} />:null}
        </>
    )
}

export default Index;