import React from 'react';
import { CSVLink } from "react-csv";
import { axios } from '../../../Model/Network/Config/Axios';
import { Button } from '../Button';
import { showToast } from '../Toast';




class UploadCsv extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    _onChange = () => {
        let file = this.file.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            this.setState({
                ...this.state,
                loading: true
            })

            axios({
                method: 'post',
                url: `https://api.buildrun.app/main/admin/${this.props.endPoint}`,
                data: formData
            })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    if (res) {
                        showToast({
                            type: 'success',
                            message: res.data.message
                        })
                        this.props.onComplete();
                    }

                })
                .catch(res => {
                    this.setState({
                        ...this.state,
                        loading: false
                    })
                    showToast({
                        type: 'error',
                        message: res.data.message
                    })
                })



        } else {
            showToast({
                type: 'error',
                message: 'Please select a  file'
            })
        }
    }




    render() {



        return (

            <div style={{
                marginTop: -20
            }}>
                <div style={{
                    display: 'flex'
                }}>
                    <p>{this.props.title} </p>
                    <CSVLink
                        filename={this.props.fileName}
                        style={{
                            textDecoration: 'none',
                            marginLeft: 10
                        }}
                        data={this.props.csvData}>Download me</CSVLink>
                </div>



                <div className="input" style={{
                    flex: 1
                }}>
                    <p style={{
                        fontSize: 12,
                        color: 'var(--title-color)',
                        fontWeight: 600,
                        marginTop: 15
                    }}>
                        {this.props.name} :
                </p>

                    <div style={{
                        height: 40,
                        border: '1px solid var(--border-color)',
                        marginTop: 8,
                        width: '100%'
                    }}>
                        <input type="file" style={{
                            width: '94%',
                            border: 'none',
                            height: '94%',
                            padding: '10px',
                            fontSize: 16
                        }}
                            ref={ref => this.file = ref}

                            autoComplete="off"
                        />
                    </div>
                </div>

                <Button
                    style={{
                        marginTop: 30,
                        width: 200
                    }}
                    loading={this.state.loading}
                    text="Upload Csv"
                    onClick={this._onChange}
                />

            </div>
        )
    }
}


UploadCsv.defaultProps = {
    csvData: []
}


export { UploadCsv };