import React from 'react';
import Model from './Model';
import Input from '../Common/Input/index';
import { connect } from 'react-redux';
import { attempLogin } from '../../Redux/Actions/Login';
import './index.css';
import { buildrun } from '../../Component/Common/Images';
import { showToast } from '../../Component/Common/Toast/index';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.Model = new Model();
        this.state = {
            forgot:false
        }

    }

    login = () => {
        let email = this.email.getValue();
        if (!email) {
            showToast({
                type:'error',
                message:'Please enter the username'
            })
            return;
        }

        let password = this.password.getValue();
        if (!password) {
            showToast({
                type:'error',
                message:"Please enter the password"
            })
            return;
        }

        this.props.login({ email, password });
    }


    render() {

        if (this.props.config.isTwoAuthRequired) {
            this.props.history.push(`/twofactor?email=${this.email.getValue()}`)
        }

        if (this.props.config.authenticated) {
            this.props.history.push('/app/project')
        }

        return (
            <div style={{ display: 'flex',  flex: 1,  height: '100vh', boxSizing: 'border-box', }} 
                 onKeyPress={e => {
                if (!this.state.forgot) {
                    if (e.key === 'Enter') {
                        this.login()
                    }
                }
            }}>
                <div style ={{
                    width:'50%',
                    padding:'30px',
                    boxSizing:'border-box',
                    height:'100vh'
                }}>
                    <div style ={{ display:'flex',justifyContent:'space-between',height:60}}>
                        <div style ={{ display:'flex', alignItems:'center' }}>
                            <img src={buildrun} alt="BuildRun" style ={{height:60,width:60}} />
                            <p style ={{fontWeight:'900',fontSize:24,textTransform:'uppercase',marginTop:10,color:'var(--text-color)'}}>BuildRun</p>
                        </div>
                    </div>
 
                    <div style={{width:'calc(100% - 150px)',height:'calc(100vh - 200px)',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column', background:"#fff", borderRadius: "16px", padding:"25px", marginTop: "10px"}}
                     className="box-shadow">
                        <h2 style ={{fontWeight:'900',fontSize:38,color:'var(--text-color)'}}>Login</h2>
                        <p style ={{fontSize:14,marginTop:8,fontWeight:'300',color:'var(--text-light-color)'}}>Login to your account for manage your construction</p>
                        <div style ={{display:'flex',position:'relative',width:500,marginTop:50}}>
                            <p style ={{borderTop:'0.5px solid #eee',height:1,width:200}} />
                            <p style={{fontSize:13,letterSpacing:1,width:170,position:'relative',top:-10,textAlign:'center',textTransform:'uppercase',fontWeight:'500',color:'var(--text-light-color)'}}>Login with Email</p>
                            <p style ={{borderTop:'0.5px solid #eee',height:1,width:200}} />

                        </div>
                        <div style={{width:500,margin:"0px auto",marginTop:50}}>

                            <Input type='text' handleRef={ref=>this.email=ref} title="Enter Email" placeholder="Email"/>
                            <Input type='password' handleRef={ref=>this.password=ref} title="Enter Password" placeholder="Password" />
                            <div onClick={()=>{
                                this.props.history.push('/reset')
                            }} style={{textAlign:'right',fontWeight:600,color:'var(--active-color)',cursor:'pointer'}}>Forgot Password ?</div>

                            <div onClick={this.login} className="buttons" style={{marginTop:40}}>Login</div>

                            <p style ={{marginTop:20,fontSize:14,letterSpacing:0.3}}>By Login  <span style={{color:'var(--active-color)',cursor:'pointer'}}>Privacy Policy</span> and <span style={{color:'var(--active-color)',cursor:'pointer'}}>Terms of Service</span> apply to the respective user</p>

                        </div>

                    </div>

                </div>

                <div style ={{
                    flex:1,
                    backgroundColor:'#f2f3f7',
                    height:'100vh',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    flexDirection:'column'
                }}>
                    <div>

                    </div>
                    <p style ={{
                        textAlign:'center',
                        color:'var(--text-light-color)',
                        letterSpacing:0.4
                    }}>
                        Buildrun is a true construction process automation platform that helps builders plan, deploy,   <br/>  track & manage end to end project development
                    </p>
                    <h2 style={{fontWeight:900,color:'var(--text-color)',marginTop:10}}>Digitize-Your-Construction</h2>
                </div>


            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        config: state.config
    }
}


const mapDisptachToProps = (dispatch) => {
    return {
        login: (obj) => { dispatch(attempLogin(obj)) }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(Login);