import { forwardRef } from "react";
import search from './search.png';
import './filterInput.css';

const FilterSearch = forwardRef((props,ref)=>{

    function clearInputValue() {
        ref.current.value = "";
    }

    return(
        <div 
            style={{
                display:'flex',
                alignItems:'center',
                background:'#eff3f6',
                height:35,
                padding:'5px 8px'
            }}
        >
            <img src={search} alt="" style={{height:20,width:20}} />
            <input onChange={(e)=>{
                if(props.onChange) {
                    props.onChange(e.target.value);
                }
            }} onKeyPress={props.onKeyPress} ref={ref} className='filter-input' placeholder={`${props.placeholder} ${props.type}`} />
            <span
                style={{
                    cursor: "pointer",
                    fontSize: 20    
                }}
                onClick={() => {clearInputValue(); props.onChange("")}}
            >&times;</span>
        </div>
    )
})


FilterSearch.defaultProps = {
    placeholder : 'Search by'
}

export default FilterSearch;