import React from 'react';
import _ from 'lodash';
import Moment from 'moment';
import { get } from '../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../Common/PageLoader';
import { BottomHeader } from '../../../Common/TopHeader/BottomHeader';
import { RightModal } from '../../../Common/Modal/RightModal';
import { Table } from '../../../Common/Tables';
import TaskOpen from './TaskOpen';
import { UploadCsv } from '../../../Common/UploadCsv';
import ReactTooltip from 'react-tooltip';
import { TopHeader } from '../../../Common/TopHeader/TopHeader';
import { get as fetch_details } from '../../../../Utils/Crypto';




class ProjectTask extends React.Component {


    constructor(props) {
        super(props);
        let project = fetch_details('PROJECT_DETAILS')
        this.state = {
            loading: true,
            taskMappings: [],
            modal: false,
            cloneTask: false,
            taskOpen: undefined,
            upload: false,
            predecessor: false,
            filters: [],
            type: {
                label: 'By Name',
                value: 'NAME'
            },
            projectDetails: {},
            project,
        }
    }

    getId = () => {
        return this.props.match.params.id;
    }





    componentDidMount() {
        this.init();
    }




    init = () => {

        get(`project/snag/snagTasks/${this.getId()}`, (e, r) => {
            if (r) {

                let task = _.uniqBy(r.tasksMappings, 'task.id');
                let wbs = _.uniqBy(r.tasksMappings, 'wbsItemMapping.id')

                this.suggestion = {
                    'NAME': r.tasksMappings.map(item => ({
                        firstCriteria: item.wbsItemTaskName,
                        value: item.wbsItemTaskName
                    })),
                    'TASK': task.map(item => ({
                        firstCriteria: item.task.name,
                        value: item.task.name
                    })),
                    "WBS": wbs.map(item => ({
                        firstCriteria: item.wbsItemMapping.name,
                        value: item.wbsItemMapping.name
                    }))
                }


                this.setState({
                    ...this.state,
                    taskMappings: r.tasksMappings,
                    loading:false
                })
            }
        })
    }



    mapItem = (item, index) => {
        let details = (
            <div className="table-item" style={{
                minHeight: 70
            }}>
                <ReactTooltip />

                <div style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    height: 70
                }} onClick={() => {
                    if (item.id === this.state.taskOpen) {
                        this.setState({
                            ...this.state,
                            taskOpen: undefined
                        })
                    } else {

                        this.setState({
                            ...this.state,
                            taskOpen: item.id
                        })
                    }
                }}>
                    <div style={{
                        width: 420
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <div style={{
                                width: 20,
                                marginTop: -2
                            }}>
                                <p
                                    data-tip={item.isCriticalTask ? 'Criticial Task' : 'Regular Task'}

                                    style={{
                                        width: 12,
                                        height: 12,
                                        borderRadius: 32,
                                        ...item.isCriticalTask ?
                                            {
                                                backgroundColor: 'var(--red-color)'
                                            } : {
                                                backgroundColor: 'var(--success-color)'
                                            }
                                    }} />
                            </div>
                            <div style={{
                                marginLeft: 10
                            }}>
                                <p style={{
                                    fontWeight: '600',
                                    fontSize: 18,
                                    color: 'var(--text-color)',
                                    width: 420
                                }} className="hide-text">
                                    {item.wbsItemTaskName}

                                </p>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <p style={{
                                        fontWeight: '500',
                                        fontSize: 14,
                                        color: 'var(--text-light-color)',
                                        marginTop: 5
                                    }}>
                                        Predecessor : {item.predecessorTask ? item.predecessorTask.wbsItemTaskName : 'NA'}
                                    </p>

                                    {
                                        this.props.isPredecessorEdit ?

                                            <div
                                                className="penciledit"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.props.onChangePredecessor()
                                                }}
                                                style={{
                                                    marginLeft: '6px',
                                                    width: 16,
                                                    height: 16,
                                                    marginTop: 6,
                                                    marginLeft: 10,
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                    backgroundSize: 'cover',
                                                    top: -2
                                                }}
                                            />
                                            : null
                                    }


                                </div>

                            </div>

                        </div>

                    </div>


                    <div style={{
                        flex: 1,
                        textAlign: 'center'
                    }}>
                        {Moment(item.plannedStartDate).format('DD-MM-YYYY')}
                    </div>
                    <div style={{
                        flex: 1,
                        textAlign: 'center'
                    }}>
                        {Moment(item.plannedCompletionDate).format('DD-MM-YYYY')}
                    </div>
                    <div style={{
                        flex: 1,
                        textAlign: 'center'
                    }}>
                        {Moment(item.actualStartDate).format('DD-MM-YYYY')}
                    </div>
                    <div style={{
                        flex: 1,
                        textAlign: 'center'
                    }}>
                        {Moment(item.actualCompletionDate).format('DD-MM-YYYY')}
                    </div>
                    <div style={{
                        textAlign: 'center',
                    }}>
                        <div style={{

                            padding: "5px 12px",
                            margin: '0px 12px',
                            borderRadius: 4,
                            fontSize: 14

                        }}
                            className={item.workDone ? `task-success` : item.workStarted ? `task-progress` : `task-incomplete`}>
                            {item.workDone ? "Completed" : item.workStarted ? "In-Progress" : "Not Started"}
                        </div>
                    </div>
                </div>
                <div className="table-right" style={{
                    width: 120
                }}>

                </div>
            </div>
        )

        if (item.id === this.state.taskOpen) {
            return (
                <TaskOpen key={index} item={item}>
                    {details}
                </TaskOpen>
            )
        }

        return details;
    }



    addFilter = (value) => {
        if (value) {
            let type = this.state.type.value;
            let filters = this.state.filters;
            filters = filters.filter(item => {
                if (item.type === type && item.value === value)
                    return false;
                else return true;
            })
            filters.push({
                type, value
            })
            this.setState({
                ...this.state,
                filters
            })
        }
    }

    deleteSelectValue = item => {
        let filters = this.state.filters;
        filters = filters.filter(mItem => {
            if (item.type === mItem.type && item.value === mItem.value)
                return false;
            else return true;
        })
        this.setState({
            ...this.state,
            filters
        })
    }

    filter = emps => {
        let filters = this.state.filters;
        let filterData = {}
        filters.forEach(f => {
            let items = filterData[f.type];
            items = items ? items : [];
            items.push(f.value)
            filterData[f.type] = items;
        })
        emps = emps.filter(item => {
            let wbsFilter = filterData["NAME"];
            if (wbsFilter) {

                if (!_.includes(wbsFilter, item.wbsItemTaskName))
                    return false;
            }
            let taskFilter = filterData["TASK"];
            if (taskFilter) {

                if (!_.includes(taskFilter, item.task.name))
                    return false;
            }
            let wbsFilters = filterData["WBS"];
            if (wbsFilters) {

                if (!_.includes(wbsFilters, item.wbsItemMapping.name))
                    return false;
            }


            return true;
        })
        return emps;

    }

    render() {

        if (this.state.loading) {
            return <PageLoader />
        }


        let data = this.filter(this.state.taskMappings);

        return (
            <div style={{
                flex: 1,
                position: 'relative',
                height: '100vh',
                overflowY: 'auto'
            }}>
                <TopHeader
                    title={`${this.state.project.name}`}
                    desc={`${this.state.project.address}`}
                    items={[]}
                />
                <BottomHeader
                    title="Snag Task"
                    desc="Snag Task map to this project"
                >

                    <div style={{
                        position: 'absolute',
                        right: 20,
                        top: 15,
                        display: 'flex'
                    }}>




                    </div>
                </BottomHeader>
                <div className="container" style={{
                    padding: 25
                }}>
                    
                    <Table
                        data={data}
                        mapItem={this.mapItem}
                        header={[
                            {
                                name: 'Details',
                                width: 420,

                            },

                            {
                                name: 'Start Date',
                                flex: 1,
                                style: {
                                    textAlign: 'center'
                                }
                            },
                            {
                                name: 'End Date',
                                flex: 1,
                                style: {
                                    textAlign: 'center'
                                }
                            },
                            {
                                name: 'Actual Start Date',
                                flex: 1,
                                style: {
                                    textAlign: 'center'
                                }
                            },
                            {
                                name: 'Actual End Date',
                                flex: 1,
                                style: {
                                    textAlign: 'center'
                                }
                            },
                            {
                                name: 'Work Status',
                                width: 140,
                                style: {
                                    textAlign: 'center'
                                }
                            },
                            {
                                name: '',
                                width: 120
                            }
                        ]}
                    />
                </div>





                {
                    this.state.upload ?
                        <RightModal
                            show={this.state.upload}
                            onClose={() => {
                                this.setState({
                                    ...this.state,
                                    upload: false
                                })
                            }}
                            title="Upload Csv"
                            desc="Fill up the all csv and upload it"
                        >
                            <UploadCsv
                                title="Please upload your csv to create task Mapping.For format details"
                                name="Select File"
                                type="task"
                                endPoint="project/task/task.upload"
                                fileName="taskMapping.csv"
                                csvData={[
                                    ["taskname", "wbsitemname", "predecessortasks", "predecessorrelation", "responsibleperson", "approver", "lagday", "totaldaysrequired", "totallaboursrequired"],
                                ]}
                            />
                        </RightModal>
                        : null

                }


            </div>
        )
    }
}

export default ProjectTask;