import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import { PageLoader } from '../../../../Common/PageLoader';
import { useProject } from '../../../../Hooks/ProjectDetails';
import { HeaderWrapper } from '../../../../NCommon/HeaderWrapper';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import Insight from './Insight';
import Info from './Info';
import TimeLine from './TimeLine'
import { post } from '../../../../../Model/Network/Config/Axios';
import Task from './Containers/Task';

const Index = () => {

    const [loading,setLoading] = useState(true);
    const [data,setData] = useState({});
    const [complete,setComplete] = useState({});
    const project = useProject();
    const {id} = useParams();
   

    useEffect(()=>{
      post(`/project/task/taskAnalytics/${id}`,{type:"PROJECT"},(e,r)=>{
        if(r) {
            setData(Object.keys(r.tasks));
            setComplete(r.tasks)
            setLoading(false);
        }
      })
    },[])


    if(loading) {
        return <PageLoader />
    }

    return(
    <>
       <HeaderWrapper 
            hideCard={true}
        />
       <PageWrapper>
            <NavigationWrapper items={[{name:"All Project",path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:'Analytics'}]} />
            <Info data={data} complete={complete} />
            <TimeLine />
            <Task task={complete[data[0]]} />
            <Insight />
       </PageWrapper>
    </>
    )
}

export default Index;