import {Switch,Route} from 'react-router-dom';
import AccessManagement from './AccessManagement';
import Resource from './Resource';
import Drawing from './Drawing';
import Hinderance from './Hinderance/Category/index';

const Index = () => {
    return(
        <Switch>
            <Route path="/app/others/access" component ={AccessManagement} />
            <Route path="/app/others/resource" component={Resource} />
            <Route path="/app/others/drawing" component={Drawing} />
            <Route path="/app/others/hinderance" component={Hinderance} />
        </Switch>
    )
}

export default Index;