
import {useParams} from 'react-router-dom';
import Moment from 'moment';
import {  useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { useProject } from '../../../Hooks/ProjectDetails';
import './index.css';
import { get } from '../../../../Model/Network/Config/Axios';
import AddEvent from './AddEvent';
import ViewEvent from './ViewEvent';
import { Header } from '../../../NCommon/Header';
import { HeaderWrapper } from '../../../NCommon/HeaderWrapper';
import { PageLoader } from '../../../Common/PageLoader';
import { PageWrapper } from '../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../NCommon/NavigationWrapper';
import { RefObject } from '../../../../Utils/Refrence';



const CalendarView = () => {

    const projectDetails = useProject();
    const [holidays,setHolidays] = useState([]);
    const [addEvent,setAddEvent] = useState(false);
    const [showEvent,setShowEvent] = useState(false);
    const [,setForceUpdate] = useState(Date.now())
    const [count,setCount] = useState(0);
    const [loading,setLoading] =useState(true);
    let {id} = useParams();
    let calendarApi = useRef();


    useEffect(()=>{
        INIT()
    },[])

    useEffect(()=>{
        if(calendarApi.current) {
            RefObject.setRef(calendarApi.current.getApi());
            setForceUpdate();
        }
    },[calendarApi,calendarApi.current,count])



    let INIT = () => {
        get(`project/holiday/fetchHolidays/${id}`,(e,r)=>{
            if(r) {
                setHolidays(r.holidays.map(val=>({
                    start:Moment(val.date).format('YYYY-MM-DD'),
                    end:Moment(val.date).format('YYYY-MM-DD'),
                    title:val.title,
                    id:val.id,
                    description:val.description
                })))
                setLoading(false);
                setAddEvent(false);
            }
        })
    }

    return(
       <>
        <Header title="Holiday Calendar" />
        {
            loading ?
            <PageLoader />
            :
            <>
                <HeaderWrapper 
                    calendarView={true}
                    showFilter={false}
                />
                <PageWrapper>
                    <div style={{marginTop:-20}}/>
                    <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${projectDetails.name}`,path:`/app/project/mapping/${id}`},{name:`Holiday`}]} />
                    <div style={{margin:'0px -20px',background:"#fff"}}>
                     <FullCalendar
                        plugins={[ dayGridPlugin,interactionPlugin ]}
                        ref={ref=>{
                            calendarApi.current=ref;
                            setCount(1)
                        }}
                        initialView="dayGridMonth" 
                        eventClick={(e)=>{
                            setShowEvent(e.event)
                        }}               
                        dateClick={(e)=>{
                            setAddEvent(e)
                        }}
                        events={holidays}
                    />
                    </div>
                </PageWrapper>
            </>
        }
       

        {
            addEvent?
            <AddEvent 
                close={()=>{
                    setAddEvent(false);
                }}
                id={id}
                details={addEvent}
                init={()=>{
                    INIT();
                    setAddEvent(false)
                }}
            />
            :null
        }

        {
            showEvent?
            <ViewEvent
                details={showEvent}
                close={()=>{setShowEvent(false)}}
                init={()=>{
                    INIT();
                    setShowEvent(false)
                }}
                id={id}
            />
            : null
        }
        
      </>
    )
}

export default CalendarView