import { SimpleFlex } from "../../../../Common/Flex/SimpleFlex";
import { KeyValue } from "../../../../Common/KeyValue";

const Index = ({details}) => {

    return(
        <>
          <SimpleFlex>
          <         KeyValue title="Name" desc={details.name} style={{flex:1}} />
                            <KeyValue title="Description" desc={details.description} style={{flex:1}} />
                        </SimpleFlex>
                        <SimpleFlex>
                            <KeyValue title="Category" desc={details.category} style={{flex:1}} />
                            <KeyValue title="Glcode" desc={details.glCode} style={{flex:1}} />
                        </SimpleFlex>
                        <SimpleFlex>
                            <KeyValue title="Resource Type" desc={details.resourceType} style={{flex:1}} />
                            <KeyValue title="Sub Resource Type" desc={details.subType} style={{flex:1}} />
                        </SimpleFlex>
                        <SimpleFlex>
                            <KeyValue title="Available Quantity" desc={details.availableQty} style={{flex:1}} />
                            <KeyValue title="Price" desc={details.price} style={{flex:1}} />
                        </SimpleFlex>
                        <SimpleFlex>
                            <KeyValue title="HSN" desc={details.hsn?details.hsn:'-'} style={{flex:1}} />
                            <KeyValue title="SKU" desc={details.sku?details.sku:"-"} style={{flex:1}} />
                        </SimpleFlex>
                        <SimpleFlex>
                            <KeyValue title="Gst Percentage" desc={details.gstRate?details.gstRate:'-'} style={{flex:1}} />
                            <KeyValue title="Material code" desc={details.code?details.code:"-"} style={{flex:1}} />            
         </SimpleFlex>               
        </>
    )
}

export default Index;