import {  useState } from "react";
import { useDispatch } from "react-redux";
import gauge from './gauge.png';
import bar from './bar.png';
import { AuthActions } from "../../../Redux/Reducers";
import { get,set } from "../../../Utils/Crypto";
import list from './list.png'
import './index.css';


const GraphView = () => {

    const [type,setType] = useState(get('graph-view')?get('graph-view'):'gauge');
    let dispatch = useDispatch();

    return(
        <div style={{display:'flex'}}>
            <div onClick={()=>{
                set('graph-view','gauge');
                dispatch({
                    type:AuthActions.Graph,
                    data:'gauge'
                })
                setType('gauge')
                }} className={`tableview ${type==='gauge'?'active':null}`} style={{marginRight:10}}>
                <img src={gauge}  alt="" />
            </div>
            <div onClick={()=>{
                set('graph-view','bar')
                dispatch({
                    type:AuthActions.Graph,
                    data:'bar'
                })
                setType('bar')
                }} className={`tableview ${type==='bar'?'active':null}`} style={{marginRight:10}}>
                <img src={bar}  alt=""  />
            </div>
            <div onClick={()=>{
                set('graph-view','insight')
                dispatch({
                    type:AuthActions.Graph,
                    data:'insight'
                })
                setType('insight')
                }} className={`tableview ${type==='insight'?'active':null}`} style={{marginRight:10}}>
                <img src={list}  alt=""  />
            </div>
        </div>
    )
}

GraphView.defaultProps = {
    hideCalendar:true
}




export {GraphView}