import { useRef,useState } from "react";
import papa from 'papaparse';
import axios from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { FunctionalFile } from "../../../../Common/FloatInput/FunctionalFile";
import { RightModal } from "../../../../Common/Modal/RightModal"
import { showToast } from "../../../../Common/Toast";
import { DownloadCsv } from "../../../../../Utils/Downloader";
import { TableSkeleton } from "../../../../NCommon/TableView";



const Index = (props) => {


    const [uploading,setUploading] = useState(false);
    const [length,setLength] = useState(0);
    const [completed,setCompleted] = useState(0);
    const [done,setDone] = useState(false);
    const [items,setItems] = useState([]);
    let FILEVALUE = useRef();

    let upload = () => {
        let file = FILEVALUE.current.getValue();
        if(!file) {
            showToast({
                type:'error',
                message:"Please select file"
            })
        } else {
            papa.parse(file, {
                download: true,
                delimiter: ",",	// auto-detect
                newline: "",
                header: true,
                transformHeader: undefined,
                dynamicTyping: false,
                preview: 0,
                encoding: "",
                worker: false,
                comments: false,
                complete: (result, file) => {
                   let final_sheet = []
                   final_sheet = result.data.map(val => {
                       let {categoryName,categoryDescription,resourceType,bufferLeadTime,sub_resource_type,glcode,code,name,description,hsn,sku,price,units,gstRate,prPoLeadTime,poGrnLeadTime,roleId,levelItemId,major} = val
                       return {
                           categoryName,
                           categoryDescription,
                           resourceType,
                           sub_resource_type,
                           glcode,
                           code,
                           name,
                           description,
                           hsn,
                           sku,
                           price:+price,
                           units,
                           gstRate:+gstRate,
                           prPoLeadTime:+prPoLeadTime,
                           poGrnLeadTime:+poGrnLeadTime,
                           roleId:roleId?+roleId:null,
                           levelItemId:levelItemId?+levelItemId:null,
                           bufferLeadTime:+bufferLeadTime,
                           major:major==='True'?true:false
                       }
                   })
                  bulkUpload(final_sheet)
                  setUploading(true);
                },
                delimitersToGuess: [',', '\t', '|']
            })
        }
    }

    let bulkUpload =  (data) => {
        startUpload(data);  
        setLength(data.length);  
    }

    let startUpload = async  (data) => {
        let count = 0;
        let items = [];
        for(const item in data) {
         const res =  await init(data[item]);
         if(typeof res ==='string') {
            items.push({
                name:data[item].name,
                error:res
            })
         } else {
            count = res+count;
         }
        }
        setItems(items);
        setUploading(false);
        setCompleted(count)
        setDone(true);
        showToast({
            type:'success',
            message:"Resource Mapping successfully"
        })
    }

    let init = async (data) => {
      let res= await axios({
            method:'POST',
            url:`https://api.buildrun.app/main/admin/resource/uploadResource`,
            data:{
                ...data
            }
        })
        .then(()=>{
            return 1;
        })
        .catch(e=>{
            return e.data.message;
        })
        return res;
      }


    let mapItem = (item,index) => {
        return(
            <div key={index} className="flex flex-v-center" style={{border:'1px solid var(--border-color)',fontSize:14,height:45}}>
                <div style={{flex:1,paddingLeft:10,height:'inherit',borderRight:'1px solid var(--border-color)'}} className="flex flex-v-center">
                    {item.name}
                </div>
                <div style={{flex:2,paddingLeft:10}} className="flex flex-v-center">
                    {item.error}
                </div>
            </div>
        )
    }

    let download = () => {
        let baseData = [[
            "Name",
            "Error"
        ]]

        items.forEach(val=>{
            baseData.push([
                val.name,
                val.error
            ])
        })

        DownloadCsv("Error.csv",baseData);
    }

    return(
        <RightModal size="M" onClose={props.onClose} title="Upload" desc="Bulk Upload Resource Item" show={true}>
            <FunctionalFile ref={FILEVALUE} title="Upload Csv" />
            <Button loading={uploading} onClick={upload} style={{marginTop:20,width:350}} text="Upload" />
            <div style={{marginTop:20}}>
                <p onClick={()=>{
                    let file = [["categoryName","categoryDescription","resourceType","sub_resource_type","glcode","code","name","description","hsn","sku","price","units","gstRate","prPoLeadTime","poGrnLeadTime","bufferLeadTime","major","roleId","levelItemId"]]
                    DownloadCsv('resourceType.csv',file)
                }} style={{fontSize:14,cursor:'pointer',fontWeight:'600'}}>Download Csv for format</p>
            </div>
            {
                done?
                <div className="flex" style={{marginTop:20}}>
                    <div  style={{width:200,height:80,borderRadius:4,border:'1px solid var(--border-color)',flexDirection:'column',padding:20}} className="flex  flex-h-center box-shadow">
                        <p style={{color:'var(--text-light-color)',fontSize:16}}>Total</p>
                        <p style={{fontWeight:'600',fontSize:28}}>{length} Items</p>
                    </div>
                    <div  style={{width:200,height:80,borderRadius:4,border:'1px solid var(--border-color)',flexDirection:'column',marginLeft:20,padding:20}} className="flex  flex-h-center box-shadow">
                        <p style={{color:'var(--text-light-color)',fontSize:16}}>Completed</p>
                        <p style={{fontWeight:'600',fontSize:28}}>{completed} Items</p>
                    </div>
                </div>
                :null
            }

           {items.length!==0?
           <>
            <div style={{display:'flex',justifyContent:'flex-end'}}>
                <div onClick={download} style={{fontSize:14,color:'var(--red-color)',fontWeight:'bold',cursor:'pointer'}}>
                    Download Report
                </div>
            </div>
            <TableSkeleton 
                    items={items}
                    mapItem={mapItem}
                    header={[
                    {
                        name:"Name",
                        style:{
                            flex:1
                        }
                    }, 
                    {
                        name:"Error",
                        style:{
                            flex:2
                        }
                    }
                ]} />   
            </>
            :null} 

        </RightModal>
    )
}

export default Index;