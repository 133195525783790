import {Switch,Route,useParams} from 'react-router-dom';
import StoreList from './StoreList';


const Index = () => {

    return(
        <Switch>
            <Route path="/app/project/inventory/:id/store" component={StoreList} exact />
        </Switch>
    )
}

export default Index;