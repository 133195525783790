import React from 'react';
import { get } from '../../../Model/Network/Config/Axios';

class FindPlaces extends React.Component {

    constructor(props) {
        super(props);
        this.presentor = props.presentor;
        this.state = {
            cname: '',
            places: [],
            selectCity: ''
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }

    getValue = () => {
        return this.state.cname;
    }


    setValue = (cname) => {
        this.setState({
            ...this.state,
            cname
        })
    }

    handleChange = (e) => {
        this.setState({
            cname: e.target.value
        }, () => this.handleCall(this.state.cname))
    }


    mapItem = (item, index) => {
        return (
            <div key={index} onClick={() => {
                this.setState({
                    cname: item,
                    places: []
                })
                this.props.onCitySelect(item);
            }
            } style={{
                borderBottom: '1px solid var(--border-color)',
                padding: '8px 15px',
                cursor: 'pointer'
            }}>{item}</div>
        )
    }

    handleCall = (name) => {
        if (name.length > 0) {
            get(`/project/places?q=${name}`, (e, r) => {
                if (r) {
                    this.setState({
                        places: r.places
                    })
                }
            })
        } else {
            this.setState({
                places: [],
                cname: ''
            })
        }
    }


    render() {
        return (
            <div style={{ ...this.props.style,flex:1 }} className="input">
                <p style={{
                    color: 'var(--text-color)',
                    fontSize: 13,
                    fontWeight: 600,
                    marginTop: 15
                }}>Address:</p>
                <div style={{
                    position: 'relative',
                    width: '100%'
                }}>
                    <input className="finput" type="text" value={this.state.cname} onChange={this.handleChange} autoComplete="off" placeholder="Search Your Address here" />
                    {
                        this.state.places.length > 0 && this.state.cname.length > 0 ?
                            <div className="suggestion-modal">
                                {this.state.places.map(this.mapItem)}
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }
}

export { FindPlaces }
