import ReactApexChart from "react-apexcharts"
import Props from 'prop-types'
import { memo } from "react"

/*
	<StackGraph height={400} title="Graph" data={[{name:"Hello",data:[121,222]]} items={["Min Price","Max Price"]}/>
*/

const StackGraph = memo((props) => {
	return (
		<div>
			<ReactApexChart options={{
				title: {
						text: props.title,
						align: 'left'
					},
				chart: {
					type: 'bar',
					height: props.height,
					animations:{
						enabled:false
					},
					toolbar: {
						tools: {
							download: props.download,
							selection: false,
							zoom: false,
							zoomin: false,
							zoomout: false,
							pan: false,
							reset: false,
							customIcons: []
						},
						autoSelected: 'zoom'
					},
					events:{
						dataPointSelection: (event, chartContext, config) => {
							if(props.onClick) {
								props.onClick(config.dataPointIndex);
							}
							console.log(chartContext, config,event);
						  }
					}
	
				},
				xaxis: {
					categories: props.categories,
					position:props.position
				  },
				plotOptions: {
					bar: {
						horizontal: props.horizontal,
						columnWidth: '75%',
						endingShape: 'rounded',
						borderRadius:4,
						distributed: props.distributed,
					},
					
				},
				
				dataLabels: {
					enabled: false,
					...props.dataLabels
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['#fff']
				},
				colors: props.colors
				
			}} series={props.data} type="bar" height={props.height} />
		</div>
	)
})


StackGraph.propTypes = {
	data : Props.array.isRequired,
	title:Props.string.isRequired,
	y:Props.string.isRequired,
	height:Props.number.isRequired,
	colors:Props.array
}

StackGraph.defaultProps = {
	y: "Price",
	title:'',
	height:500,
	categories:[],
	colors:['#0073ea','#0073ea'],
	position:'bottom',
	dataLabels:{},
	horizontal:true,
	download:true,
	distributed:false
}

export {
	StackGraph
}

export default StackGraph
