import Props from 'prop-types';

const KeyValue = (props) => {

        let { title, desc } = props;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 10,
                ...props.style
            }}>
                <p style={{
                    fontSize: 13,
                    color: 'var(--text-light-color)',

                }}>{title}</p>
                <p style={{
                    fontSize: 15,
                    marginTop: 3,
                }}>{desc}</p>
            </div>
        )
    
}



KeyValue.defaultProps = {
    title: '',
    desc: ''
}


KeyValue.propsTypes = {
    title: Props.any,
    desc: Props.any
}


export { KeyValue };