import {useParams,useLocation,Route,Switch} from 'react-router-dom';
import { Header } from '../../../NCommon/Header';
import Graph from './Graph';
import Dpr from './Dpr'
import AllTemplate from './AllTemplate';
import Export from './Export';
import Custom from './Custom';
import DATA from './data.js';
import Type from './Type/index';
import Resource from './Resource/index';
import { useEffect, useState } from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import Vendor from './Vendor/index';
import ProjectTaskData from '../ProjectTaskData'

const Index = () => {
    const {id} = useParams();
    const location = useLocation().pathname;
    const [reports,setReports] = useState([]);
   
    useEffect(()=>{
        INIT();
    },[])

    let INIT = () =>{
        get(`reports/available`,(e,r)=>{
            if(r) {
                setReports(r.reports);
            }
        })
    }

    let items = [
        {   name:"Project Overview",
            link:`/app/project/analytics/${id}`,
            active:location===`/app/project/analytics/${id}`
        },
        {
            name:'Project Progress',
            link:`/app/project/analytics/${id}/type`,
            active:location===`/app/project/analytics/${id}/type`
        },
        {
            name:'By Resource',
            link:`/app/project/analytics/${id}/resource`,
            active:location===`/app/project/analytics/${id}/resource`
        },
        {
            name:'Task Progress',
            link:`/app/project/analytics/${id}/vendor`,
            active:location===`/app/project/analytics/${id}/vendor`
        },
        {   
            name:'Dpr',
            link:`/app/project/analytics/${id}/dpr`,
            active:location===`/app/project/analytics/${id}/dpr`
        },
        {
            name:'Export Report',
            link:`/app/project/analytics/${id}/export`,
            active:location===`/app/project/analytics/${id}/export`
        },
        {
            name:'All Templates',
            link:`/app/project/analytics/${id}/template`,
            active:location===`/app/project/analytics/${id}/template`
        },
        {
            name:'Mis Report',
            link:`/app/project/analytics/${id}/mis`,
            active:location===`/app/project/analytics/${id}/mis`
        }
        ]

    reports.forEach(val=>{
        items.push({
            name:`${DATA[val.reportType].name}`,
            link:`/app/project/analytics/${id}/${DATA[val.reportType].path}`,
            active:location.includes(`/app/project/analytics/${id}/${DATA[val.reportType].path}`)
        })
    })
    


    return(
        <>
          <Header 
            items={items} />
          <Switch>
              <Route path="/app/project/analytics/:id" component={Graph} exact/>
              <Route path="/app/project/analytics/:id/type" component={Type} exact />
              <Route path="/app/project/analytics/:id/resource" component={Resource} exact />
              <Route path="/app/project/analytics/:id/vendor" component={Vendor} exact />
              <Route path="/app/project/analytics/:id/dpr" component={Dpr} exact />
              <Route path="/app/project/analytics/:id/export" component={Export} exact />
              <Route path="/app/project/analytics/:id/template" component={AllTemplate} exact />
              <Route path="/app/project/analytics/:id/custom" component={Custom} />
              <Route path="/app/project/analytics/:id/mis" component={ProjectTaskData} />
          </Switch>
		</>
    )
}

export default Index;