import { useEffect, useRef, useState } from "react";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../../Common/Modal/RightModal"
import { SearchUnit } from "../../../../Common/Search/FetchUnit";
import {Button} from '../../../../Common/Button/index'
import { showToast } from "../../../../Common/Toast";
import { post,get } from "../../../../../Model/Network/Config/Axios";
import { AddRoles } from "../../../../Common/Roles";
import { SearchTaskN } from "../../../../Common/Search/SearchTaskN";
import { useDictionaryValue } from "../../../../Hooks/DictionaryHooks";
import { SearchSelect } from "../../../../Common/Search/SearchSelect/SearchSelect";
import { MainModal } from "../../../../NCommon/Modal";



const Edit = (props) => {

    let NAME = useRef(),DESCRIPTION=useRef(),HSN=useRef(),SKU=useRef(),PRICE=useRef(),UNIT=useRef(),GST=useRef(),CODE=useRef(),PRPOLEADTIME=useRef(),POGRNLEADTIME=useRef();
    let ROLE=useRef(),WASTERATIO=useRef(),AGE=useRef(),TASK=useRef(),MAJOR=useRef(),BUFFERLEADTIME=useRef();
    let [update,setUpdate] = useState({});
    let getDictionaryValue = useDictionaryValue();

    useEffect(()=>{
        get(`resource/resourceDetails/${props.id}`,(e,r)=>{
            if(r) {
                NAME.current.value = r.name;
                DESCRIPTION.current.value = r.description;
                HSN.current.value = r.hsn?r.hsn:"";
                SKU.current.value = r.sku;
                PRICE.current.value = r.price;
                CODE.current.value = r.code;
                GST.current.value = r.gstRate;
                PRPOLEADTIME.current.value = r.prPoLeadTime;
                POGRNLEADTIME.current.value = r.poGrnLeadTime;
                WASTERATIO.current.value = r.wastageRatio;
                BUFFERLEADTIME.current.value = r.bufferLeadTime
                UNIT.current.setValue(r.units)
                if(r.role && r.role.id) {
                    ROLE.current.setValue(r.role.name);
                }
                if(r.age) {
                    AGE.current.value = r.age;
                }
            } 
        })
    },[props.id])

    let submit = () => {
        let name = NAME.current.value;
        let description = DESCRIPTION.current.value;
        let price = PRICE.current.value;
        let units = UNIT.current.getValue();
       

        if(!name) {
            showToast({
                type:'error',
                message:'Please enter the name'
            })
            return
        }
        if(!description) {
            showToast({
                type:'error',
                message:'Please enter the description'
            })
            return
        }
        if(!price) {
            showToast({
                type:'error',
                message:'Please enter the price'
            })
            return
        }
        if(!units) {
            showToast({
                type:'error',
                message:'Please enter the units'
            })
            return
        }
       
        post(`resource/updateResource`,{...update,resourceId:+props.id},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Resource update successfully"
                })
                props.init();
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }

    return(
        <MainModal
            title={props.name}
            desc="Edit the details and update it"
            close={props.onClose}
            open={props.open}
        >
            <FunctionalInput ref={NAME} onChange={(name)=>{
                setUpdate({
                    ...update,
                    name
                })
            }} title="Name" />
            <FunctionalInput ref={DESCRIPTION} title="Description"  onChange={(description)=>{
                setUpdate({
                    ...update,
                    description
                })
            }}/>
            <FunctionalInput ref={HSN} title="Hsn (optional)"  onChange={(hsn)=>{
                setUpdate({
                    ...update,
                    hsn
                })
            }}
             />
            <FunctionalInput ref={SKU} title="Sku (optional)"  onChange={(sku)=>{
                setUpdate({
                    ...update,
                    sku
                })
            }}/>
            <FunctionalInput ref={PRICE} title="Price"  onChange={(price)=>{
                setUpdate({
                    ...update,
                    price:+price
                })
            }} />
            <SearchUnit title="Unit" handleRef={ref=>UNIT.current=ref}  onChange={(units)=>{
                setUpdate({
                    ...update,
                    units
                })
            }} />
            <FunctionalInput ref={GST} title="Gst Percentage (optional)" type="number" onChange={(gstRate)=>{
                setUpdate({
                    ...update,
                    gstRate:+gstRate
                })
            }}/>
            <FunctionalInput ref={CODE} title="Material Code (optional)" type="text"  onChange={(code)=>{
                setUpdate({
                    ...update,
                    code
                })
            }}/>
            <FunctionalInput ref={PRPOLEADTIME} title="Pr to po lead time (optional)" type="text"  onChange={(prPoLeadTime)=>{
                setUpdate({
                    ...update,
                    prPoLeadTime:+prPoLeadTime
                })
            }} />
            <FunctionalInput ref={POGRNLEADTIME} title="Po to grn lead time (optional)" type="text" onChange={(poGrnLeadTime)=>{
                setUpdate({
                    ...update,
                    poGrnLeadTime:+poGrnLeadTime
                })
            }}/>
            <FunctionalInput ref={BUFFERLEADTIME} title="Buffer Lead time" type='number' onChange={(bufferLeadTime)=>{
                setUpdate({
                    ...update,
                    bufferLeadTime:+bufferLeadTime
                })
            }} />

            <FunctionalInput ref={WASTERATIO} title="Waste Ratio(optional)" onChange={(wastageRatio)=>{
                setUpdate({
                    ...update,
                    wastageRatio:+wastageRatio
                })
            }} />
            <SearchSelect onChange={val=>{
                setUpdate({
                    ...update,
                    major:val==='True'?true:false
                })
            }} handleRef={ref=>MAJOR.current=ref} title="Is Major Material ?" items={[{key:true,value:'True'},{key:false,value:'False'}]} />
            { props.type==='LABOUR' && props.subType==='SKILLED'?
            <AddRoles handleRef={ref=>ROLE.current=ref} placeholder="Search role in the company directory" title="Role" onSelect={(id)=>{
                setUpdate({
                    ...update,
                    role:+id
                })
            }}/>
            : null
            }
             {
                props.subType ==='SHUTTER' ?
                <>
                    <FunctionalInput ref={AGE}  onChange={(age)=>{
                        setUpdate({
                         ...update,
                            age:+age
                        })
                 }} title="Age(optional)" type="number"  />
                    <SearchTaskN
                        title={`Search ${getDictionaryValue('Task')}(optional)`}
                        handleRef={ref=>TASK.current=ref} 
                        type="REGULAR"
                        onClick={(item)=>{
                            setUpdate({
                                ...update,
                                taskId:+item.id
                            })
                        }}
                />
                </>
                :null
            }
            <Button text="Update" style={{marginTop:30}} onClick={submit} />
        </MainModal>
    )
}

export default Edit;