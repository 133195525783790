import { KeyValue } from '../../../Common/KeyValue';
import { RightModal } from "../../../Common/Modal/RightModal";

const ViewAttendance = ({details, close}) => {
    const { displayData } = details;

    const mapItem = (item, index) => {
        let date = new Date(item.date);
        date = date.toString("en-IN");
        return (
            <div style={{borderBottom: "solid 1px", marginBottom: 10}} key={`${item.details.id}${index}`}>
                <KeyValue 
                    title="Date-Time"
                    desc={date}
                />
                <KeyValue 
                    title="ID" 
                    desc={item.details.id} 
                />
                <KeyValue 
                    title="Description" 
                    desc={`${item.details.category} | ${item.details.subType} | ${item.details.resource}`} 
                />
                <KeyValue 
                    title="User"
                    desc={`${item.user.name} | ${item.user.email}`}
                />
                <KeyValue
                    title="Vendor"
                    desc={`${item.vendor.name} | ${item.vendor.email} | ${item.vendor.mobile}`}
                />
            </div>
        )
    }

    return(
        <RightModal
            show={true}
            title={`Attendance Record - ${new Date(displayData[0].date).toUTCString().slice(0, 16)}`}
            onClose={close}
        >
            {displayData.map(mapItem)}   
        </RightModal>
    )
}

export default ViewAttendance;