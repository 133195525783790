import { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import { deleteApi, get } from "../../../../../../Model/Network/Config/Axios";
import { AccessButton,Button } from "../../../../../Common/Button";
import { Modal } from "../../../../../Common/Modal/Modal";
import { PageLoader } from "../../../../../Common/PageLoader";
import { showToast } from "../../../../../Common/Toast";
import { useProject } from "../../../../../Hooks/ProjectDetails";
import { HeaderWrapper } from "../../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../../NCommon/PageWrapper";
import { Table } from "../../../../../NCommon/Tables/index";
import { TableMenu } from "../../../../../NCommon/Tables/TableMenu";
import { Card } from "../../../../../NCommon/Ui/CardLayout";
import { List } from "../../../../../NCommon/Ui/List";
import AddStore from './AddStore';


const Index = () => {

    const [loading,setLoading] = useState(true);
    const [store,setStore] =useState({});
    const [add,setAdd] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [buttonLoading,setButtonLoading] = useState(false);
    let {id} = useParams();
    let project = useProject();

    useEffect(()=>{
        INIT();
    },[id])

    let INIT = () =>{
        get(`project/stores/allStores/${id}`,(e,r)=>{
            if(r) {
                setStore(r.stores);
                setLoading(false);
            }
        })
    }

    let mapCardLayout = (item,index) => {
        return(
            <Card 
                key={index}
                title={item.name}
                desc={item.details}
                isOpen={true}
                onDelete={()=>{setDeleteModal(item)}}
                >
                <p className="single-line" style={{fontWeight:'400',fontSize:13,color:'var(--text-light-color)'}}>{item.address}</p>
            </Card>
        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <div className="table-title">{item.name}</div>
                    <div className="table-bottom">{item.details}</div>
                </div>
                <div className="flex-1">
                    <div className="table-text">{item.address}</div>
                </div>
                <TableMenu items={[{name:"View"},{name:'Delete',onClick:()=>{setDeleteModal(item)}}]} />
            </List>
        )
    }

    if(loading) {
        return <PageLoader />
    }

    return(
        <>
            <HeaderWrapper />
            <PageWrapper>
                <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{name:`${project.name}`,path:`app/project/inventory/${id}`},{name:'Store'}]}>
                    <AccessButton onClick={()=>{setAdd(true)}} text="Create Store" addsign={true?1:0} inverted={true?1:0} />
                </NavigationWrapper>
                <Table
                    DATA={store}
                    mapCardLayout={mapCardLayout}
                    mapListLayout={mapListLayout}
                    headers={[
                        {
                            name:'Store Name',
                            flex:1
                        },
                        {
                            name:'Address',
                            flex:1
                        },
                        {
                            width:50,
                            name:'actions'
                        }
                    ]}
                />
            </PageWrapper>

            {
                add ?
                <AddStore
                    onClose={()=>{
                        setAdd(false)
                    }}
                    init={()=>{
                        setAdd(false);
                        INIT();
                    }}
                />
                :null
            }

            {
                deleteModal ?
                <Modal
                    show={deleteModal}
                    title={`Delete ${deleteModal.name}`}
                    desc="By clicking button below delete selected store"
                    style={{
                        minHeight: 200
                    }}
                    onClose={() => { setDeleteModal(false) }}
                >

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginTop: 30
                    }}>

                        <p style={{
                            fontSize: 18,
                            color: 'var(--light-color)'
                        }}>
                            By clicking below you will be deleting selected store and data related to this store
                          </p>

                        <Button
                            text="Delete"
                            style={{
                                backgroundColor: 'var(--red-color)',
                                borderColor: 'var(--red-color)',
                                marginTop: 30
                            }}
                            loading={buttonLoading}
                            onClick={()=>{
                                setButtonLoading(true);
                                deleteApi(`project/stores/removeStore/${id}/${deleteModal.id}`, (e, r) => {
                                    showToast({
                                        type:'success',
                                        message:'Store Delete Successfully'
                                    })
                                    setButtonLoading(false);
                                    setDeleteModal(false);
                                    INIT();
                                })
                            }}
                        />
                    </div>
                </Modal>
                : null       
            }
        </>
    )
}

export default Index;