import React from 'react';
import query from 'stringquery'
import { buildrun } from '../../Component/Common/Images';
import { showToast } from '../../Component/Common/Toast';
//import { connect } from 'react-redux';
import { post } from '../../Model/Network/Config/Axios';
import Input from '../Common/Input';


class TwoFactor extends React.Component {



    login = () => {

    
        let {email} = query(this.props.location.search)


        let pass = this.password.getValue();
        let cpass = this.confirm.getValue();

        if(pass.length < 6) {
            showToast({
                type:"error",
                message:"Enter a password length greater than 6"
            })
            return
        }
        if(pass !== cpass) {
            showToast({
                type:'error',
                message:"Password did not match"
            })
            return
        }

        post(`auth/forgot`, {
            "username": email,
            otp: this.otp.getValue(),
            password: this.password.getValue(),
            confirm: this.confirm.getValue()
        }, (e, r) => {
            if (r) {
                showToast({
                    type:'success',
                    message:'Password change successfully'
                })
                this.props.history.push('/')
            } else {
                showToast({
                    type:'errors',
                    message:e.data.message
                })
            }
        })
    }


    render() {
        return (
            <div style={{
                display: 'flex',
                flex: 1,
                height: '100vh',
                boxSizing: 'content-box',
            }} onKeyPress={e => {
                if (e.key === 'Enter') {
                    this.login()
                }
            }}>

                 <div style ={{
                    width:'50%',
                    padding:'20px',
                    boxSizing:'border-box',
                    height:'100vh'
                }}>
                    <div style ={{ display:'flex',justifyContent:'space-between',height:60}}>
                        <div style ={{ display:'flex', alignItems:'center' }}>
                            <img src={buildrun} alt="BuildRun" style ={{height:60,width:60}} />
                            <p style ={{fontWeight:'900',fontSize:24,textTransform:'uppercase',marginTop:10,color:'var(--text-color)'}}>BuildRun</p>
                        </div>
                    </div>
 
                    <div style={{height:'calc(100vh - 180px)',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                        <h2 style ={{fontWeight:'900',fontSize:38,color:'var(--text-color)'}}>Enter Details</h2>
                        <p style ={{fontSize:14,marginTop:8,fontWeight:'300',color:'var(--text-light-color)'}}>Enter otp and new password to login again in your account</p>
                        <div style ={{display:'flex',position:'relative',width:500,marginTop:50}}>
                            <p style ={{borderTop:'0.5px solid #eee',height:1,width:200}} />
                            <p style={{fontSize:13,letterSpacing:1,width:170,position:'relative',top:-10,textAlign:'center',textTransform:'uppercase',fontWeight:'500',color:'var(--text-light-color)'}}>Login with Email</p>
                            <p style ={{borderTop:'0.5px solid #eee',height:1,width:200}} />

                        </div>
                        <div style={{width:500,margin:"0px auto",marginTop:50}}>

                            <Input type='text' handleRef={ref=>this.otp=ref} title="Enter Otp" placeholder="Email"/>
                            <Input type='password' handleRef={ref=>this.password=ref} title="Enter Password" placeholder="Password" />
                            <Input type='password' handleRef={ref=>this.confirm=ref} title="Confirm Password" placeholder="Confirm Password" />

                            <div onClick={()=>{
                               this.props.history.push('/')
                            }} style={{textAlign:'right',fontWeight:600,color:'var(--active-color)',cursor:'pointer'}}>Already Credentials ?</div>

                            <div onClick={this.login} className="buttons" style={{marginTop:40}}>Login</div>

                            <p style ={{marginTop:20,fontSize:14,letterSpacing:0.3}}>By Login  <span style={{color:'var(--active-color)',cursor:'pointer'}}>Privacy Policy</span> and <span style={{color:'var(--active-color)',cursor:'pointer'}}>Terms of Service</span> apply to the respective user</p>

                        </div>

                    </div>

                </div>

                <div style ={{
                    flex:1,
                    backgroundColor:'#f2f3f7',
                    height:'100vh',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    flexDirection:'column'
                }}>
                    <div>

                    </div>
                    <p style ={{
                        textAlign:'center',
                        color:'var(--text-light-color)',
                        letterSpacing:0.4
                    }}>
                        Buildrun is a true construction process automation platform that helps builders plan, deploy,   <br/>  track & manage end to end project development
                    </p>
                    <h2 style={{fontWeight:900,color:'var(--text-color)',marginTop:10}}>Digitize-Your-Construction</h2>
                </div>

{/* 

                <div style={{
                    width: '38%',
                    backgroundColor: 'var(--background-color)',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    justifyContent: "center"

                }}>

                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <img src="" alt="" />
                        <div style={{
                            width: '65%'
                        }}>

                            <p style={{
                                fontSize: 36,
                                color: 'var(--text-color)'
                            }}>Login to <b style={{
                                fontWeight: 800,
                                letterSpacing: 0.5
                            }}>BuildRun</b></p>

                            <p style={{
                                color: 'var(--text-light-color)',
                                fontWeight: 400,
                                marginTop: 2,
                                fontSize: 12
                            }}>Manage your something here</p>

                            <div style={{
                                marginTop: 130
                            }}>
                                <Input
                                    title="Enter Otp"
                                    type="email"
                                    handleRef={ref => {
                                        this.otp = ref
                                    }}
                                />

                                <Input
                                    title="New Password"
                                    type="password"
                                    handleRef={ref => {
                                        this.password = ref
                                    }}
                                />

                                <Input
                                    title="Confirm Password"
                                    type="password"
                                    handleRef={ref => {
                                        this.confirm = ref
                                    }}
                                />



                            </div>

                            <p style={{
                                textAlign: 'right',
                                width: '100%',
                                color: 'var(--text-light-color)',
                                fontWeight: 500,
                                marginTop: 30,
                                cursor: 'pointer',
                                marginLeft: 20,
                                fontSize: 14
                            }}>Forgot Your Password ?</p>


                            <div style={{
                                width: '100%',
                                backgroundColor: 'var(--primary-color)',
                                color: '#fff',
                                fontWeight: 600,
                                height: 42,
                                cursor: 'pointer',
                                marginTop: 50,
                                fontSize: 14,
                                boxShadow: '2px 5px 8px -5px rgba(0,0,0,0.63)',
                                padding: '0px 10px'
                            }} className="login-button flex-center" onClick={this.login} >
                                Login
                        </div>



                        </div>
                    </div>
                </div>

                <div style={{
                    width: '60%'
                }}>

                </div> */}

            </div>
        )
    }
}

export default TwoFactor;