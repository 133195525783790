import { useEffect, useRef, useState } from "react";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../../Common/Modal/RightModal";
import { Button } from "../../../../Common/Button";
import { showToast } from "../../../../Common/Toast";
import { post } from "../../../../../Model/Network/Config/Axios";


const Index = ({details,init,onClose,projectId}) => {

    let [buttonLoading,setButtonLoading] = useState(false);
    let MAX=useRef();

    useEffect(()=>{
        MAX.current.value = details.max;
    },[])


    let update = () => {
       
        let max = MAX.current.value;

        if(!max) {
            showToast({
                type:'error',
                message:"Please enter the max quanity"
            })
            return;
        }
        let data = {
            max:+max,
            projectId:+projectId,
            inventoryId:details.inventoryId,

        }
        setButtonLoading(true);

        post(`project/inventory/updateInventory`,data,(e,r)=>{
            if(r) {
                setButtonLoading(false)
                showToast({
                    type:'success',
                    message:"Inventory updated successfully"
                })
                init();
            }
        })
    }
    return(
        <RightModal
            title="Edit Max Quantity"
            desc={`Change the details of ${details.name}`}
            show={true}
            onClose={onClose}
        >
            <FunctionalInput title="Max Quantity" ref={MAX}/>
            <Button text="Update" style={{marginTop:30}} onClick={update} loading={buttonLoading}/>
        </RightModal>
    )
}

export default Index;