import {  useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import { get, post } from "../../../../../Model/Network/Config/Axios";
import { AccessButton } from "../../../../Common/Button";
import { PageLoader } from "../../../../Common/PageLoader";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { useTableCount } from "../../../../Hooks/TableCount";
import GenerateRequest from './GenerateRequest';
import { useProject } from "../../../../Hooks/ProjectDetails";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import {RequestItem} from "../Common/index";
import CreatePr from './CreatePr';
import BulkPr from './BulkPr';
import DeleteRequest from "./DeleteRequest";
import MarkComplete from "./MarkComplete";
import { TaskDateFilter } from "../../../../NCommon/DateFilters/TaskDateFilter";
import { showToast } from "../../../../Common/Toast";

const Index = () => {

    const [loading,setLoading] = useState(true);
    const [generate,setGenerate] = useState(false);
    const [selected,setSelected] = useState([]);
    const [deleteModal,setDeleteModal] = useState(undefined);
    const [complete,setComplete] = useState(undefined);
    const [pr,setPr] = useState(false);
    const [dates,setDates] = useState(undefined);
    const [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    });
    const count = useTableCount();
    const project = useProject();
    let {id} = useParams();

    useEffect(()=>{
        INIT({page:0});
    },[count,dates])

    let INIT = (data) => {
         if(!data) {
             data = {page:0}
         }
         data.size = `${count}`
         data.offset = (data.page*count).toString();
         let reqData = {};
         if(dates) {
            reqData ={
                start:dates.startDate,
                end:dates.endDate
            }
         }
        get(`project/inventory/procure/pendingRequests/${id}?size=${data.size}&offset=${data.offset}`,(e,r)=>{
            if(r) {
                setMData({
                    items:r.requests,
                    total:r.total,
                    page:data.page
                })
                setLoading(false);
            }
        },{...reqData})
    }

    let mapListLayout = (item,index) => {
        return(
            <RequestItem
                key={index}
                checkbox={true}
                item={item}
                active={_.includes(selected,item.id)}
                tableItems={[{name:'Create Pr',onClick:(e)=>{e.stopPropagation();setPr(item)}},{name:'Mark Done',onClick:()=>{setComplete(item)}},{name:'Delete',onClick:(e)=>{e.stopPropagation();setDeleteModal(item)}}]}
                onClick={()=>{
                    let allItem = [...selected];
                    if(_.includes(selected,item.id)) {
                        allItem = allItem.filter(val=>val!==item.id);
                    } else {
                        allItem.push(item.id);
                    }
                    setSelected(allItem);
                }}
            />
        )
    }

    return(
        <>
            <HeaderWrapper dynamicFilter={true} hideCard={true}>
                <TaskDateFilter onSelect={setDates} />
            </HeaderWrapper>
            <PageWrapper defaultView={'list'} style={{paddingBottom:100}} id="pagination-table-header" scroll={true}>
                <NavigationWrapper items={[{name:'All Project',path:'/app/project'},{path:`/app/project/mapping/${id}`,name:`${project.name}`},{name:`Pending Request (${mData.total})`}]}>
                    <AccessButton onClick={()=>{setGenerate(true)}} addsign={true?1:0} inverted={true?1:0} text="Create Request" />
                    <AccessButton onClick={()=>{
                        setLoading(true);
                        post(`/project/pr.automation.run/${id}`,{},(e,r)=>{
                            if(r) {
                                showToast({
                                    type:'success',
                                    message:'Sync successfully'
                                })
                                setLoading(false);
                            }
                        })
                    }} style={{marginLeft:20}}  inverted={true?1:0} text="Sync" />
                </NavigationWrapper>
               {
                   loading ?
                   <PageLoader />:
                   <PaginationTable
                   DATA={mData.items}
                   mapListLayout={mapListLayout}
                   mapCardLayout={()=>{}}
                   next={(page)=>{
                    INIT({page})
                }}
                   defaultView={'list'}
                   page={mData.page}
                
                   total={mData.total}
                   headers={[
                       {
                           name:'checkbox',
                           width:25,
                           onClick:()=>{

                           }
                       },
                       {
                           name:'Resource Details',
                           flex:1
                       },
                       {
                           name:'Store Name',
                           flex:1,
                       },
                       {
                           name:'Quantity',
                           flex:0.5
                       },
                      
                       {
                           name:'Price',
                           flex:0.5
                       },
                       {
                           name:'Delivery Date',
                           flex:0.5
                       },
                       {
                           name:'actions',
                           width:50
                       }
                   ]}
               />
               }
            </PageWrapper>
            <GenerateRequest open={generate} close={()=>{setGenerate(false)}} INIT={()=>{INIT({page:mData.page});setGenerate(false)}} />
            <CreatePr open={pr} item={pr} close={()=>{setPr(false)}} INIT={()=>{INIT({page:mData.page});setGenerate(false)}} />
            <BulkPr selected={selected} />
            <DeleteRequest INIT={()=>{INIT({page:mData.page});setDeleteModal(false)}} show={deleteModal} close={()=>{setDeleteModal(false)}}/>
            <MarkComplete INIT={()=>{INIT({page:mData.page});setComplete(false)}} show={complete} close={()=>{setComplete(false)}}/>

        </>
    )
}

export default Index;