import Props from 'prop-types';
import { Link ,useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Profile from './Profile';
import { useState } from 'react';
import syncIcon from './sync.png';
import { showToast } from '../Toast';
import { patch } from '../../../Model/Network/Config/Axios';

const TopHeader = (props) => {


    let { title, desc, items } = props;
    const [sync,setSync] = useState(false);
    let {pathname} = useLocation();
    const config = useSelector(state=>({
        config:state.config
    }))

    let headerMap = (item, index) => {
        return (
            <div key={index} style={{
                paddingLeft: 18,
                margin: 0,
                paddingTop: 5,
            }
            }>
                <Link to={item.link} style={{
                    textDecoration: 'none',
                    display: 'block',
                    ...item.active ?
                        {
                            color: 'var(--active-color)',
                            fontWeight: 700,
                            borderBottom: '2px solid var(--active-color)',
                            paddingBottom: 12,
                        } :
                        {
                            color: 'var(--text-light-color)',
                            fontWeight: 600,
                            paddingBottom: 14
                        }
                }}>
                    {item.name}
                </Link>
            </div >
        )
    }
    let syncProject = () => {
        let id = pathname.split("/");
            id = id[4] ? +id[4] : null;
            setSync(true);
            showToast({
                type:'success',
                message:"Project sync is running in background"
            })
            setTimeout(()=>{
                patch(`project/sync/${id}`, {}, (e, r) => {
                    if (r) {
                      showToast({
                        type: "success",
                        message: "Project sync succesfully",
                      });
                     setSync(false);
                    }
                  });
            },3000)
    }


    return (
        <div style={{
            flex: 1
        }}>
            <div style ={{ display:'flex',  alignItems:'center',  justifyContent:'space-between' }}>
                <div style={{padding: '18px 15px 18px 30px',display:'flex',justifyContent:'center',flexDirection:'column'}}>
                    <h1 style={{ fontWeight:'900', fontSize: 24,color: 'var(--text-color)'}}>{title}</h1>
                    <p style={{fontSize: 13,color: 'var(--text-light-color)' }}>{desc}</p>
               </div>

                <div style ={{
                    display:'flex',
                    alignItems:'center',
                    paddingRight:18
                }}>
                    {
                     pathname.includes('/app/project/') ?
                        <div className="flex flex-v-center flex-h-center" >
                          <img src={syncIcon} alt="" style={{height:18,width:18,marginRight:10}}  className={sync ? "image" : null} />
                          <p style={{ marginRight: 20,fontSize:15,fontWeight:600,cursor:'pointer',textTransform:'uppercase'}} onClick={syncProject}>
                            Sync
                         </p>
                      </div>
                     : null
                    }
                    <div style ={{fontWeight:700,fontSize:13,color: "var(--text-color)",}}>
                        {config.config.name}
                    </div>
                    <Profile {...props} data={config.config} />
                </div>

            </div>


            <div style={{
                display: 'flex',
                margin:'0px 10px'
            }}>
                {items.map(headerMap)}
            </div>
        </div>
    )
}

TopHeader.propsTypes = {
    title: Props.string.isRequired,
    desc: Props.string.isRequired,
    items: Props.array
}

TopHeader.defaultProps = {
    items: []
}



export { TopHeader }