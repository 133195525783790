import { useState } from 'react';
import {useParams} from 'react-router-dom';
import { useProject } from '../../../../../Hooks/ProjectDetails';
import { NavigationWrapper } from '../../../../../NCommon/NavigationWrapper';
import { Button } from '../../../../../Common/Button';
import { post,get } from '../../../../../../Model/Network/Config/Axios';

const Index = () =>{

    const [loading1,setLoading1] = useState(false);
    let {id} = useParams();
    let project = useProject();

   

    return(
        <div>
            <NavigationWrapper items={[{name:`All Project`,path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`MPR AP Report`}]} />
            <div className="title-container-1 flex">
                
                <Button loading={loading1} onClick={()=>{
                         get(`reports/custom/ate/reports/gSheet/imsDataSync/${id}`,(e,r)=>{
                            if(r){
                                if(r.authenticationRequired) {
                                    post(`reports/custom/ate/reports/authenticationToken/${id}`,{},(e,res)=>{
                                        if(res) {
                                            setLoading1(false);
                                            window.open(res.authenticationUrl,'_blank')
                                        }
                                    })
                                }
                            }
                         })
                     }} text="Sync" style={{width:350,marginTop:20,marginLeft:20}} />

            </div>
        </div>
    )
}

export default Index;