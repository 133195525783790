import { useSidebarExpanded } from "../../Hooks/ExpandedHook"



const BottomPopUp = (props) => {

    let expaned = useSidebarExpanded();

    if(!props.show) {
        return (<></>)
    }

    return(
        <div style={{
            position:'absolute',
            height:60,
            left:expaned?240:80,
            bottom:0,
            right:0,
            padding:'10px 15px',
            borderTop:'1px solid var(--border-color)',
            background:'#fff',
            zIndex:2,
            display:'flex',
            alignItems:'center'
        }} className="box-shadow">
            {props.children}
        </div>
    )

}

export {BottomPopUp}