import Moment from 'moment';
import {  useState } from 'react';
import Gauge from '../../../../Common/Graphs/GaugeChart';
import { KeyValue } from '../../../../Common/KeyValue';
import { useProject } from "../../../../Hooks/ProjectDetails";



const Index = (props) => {

    const project = useProject();
    const [data] = useState(props.data);
    const [complete] = useState(props.complete);

   

    let tItem = complete[data[0]]

    let onTime = 0;
    if(tItem.total===tItem.notStarted) {
        onTime=0;
    } else {
       onTime = 1-(tItem.delayed)/(tItem.total-tItem.notStarted)
    }
    const days = Moment(project.estimatedFinishDate).diff(project.originalFinishDate,'days');
    return(
       <>
        <div className="flex">
                    <div style={{flex:1,marginRight:20}} className="title-container-1">
                        <div style={{display:'flex'}}>
                            <div style={{flex:1}}>
                                <p style={{fontWeight:600,fontSize:15,marginBottom:20}}>Project Completion Date</p>
                                <KeyValue title={'Project Creation Date'} desc={Moment(project.createdAt).format('DD-MM-YYYY')}  />
                                <KeyValue title={'Planned Finish Date'} desc={Moment(project.plannedFinishDate).format('DD-MM-YYYY')}  />
                                <KeyValue title={'Estimated Finish Date'} desc={Moment(project.estimatedFinishDate).format('DD-MM-YYYY')}  />
                                <KeyValue title={'Original Finish Date'} desc={Moment(project.originalFinishDate).format('DD-MM-YYYY')}  />
                            </div>
                            <div style={{flex:1,marginLeft:'auto'}}>
                                {
                                    days >0 ?
                                    <div className='flex flex-v-center flex-h-center' style={{background:'var(--red-color)',width:120,marginLeft:'auto',height:30,borderRadius:4,fontSize:13,fontWeight:'bold',color:'#fff',padding:'0px 10px'}}>{days} days Delay</div>
                                    :
                                    <div className='flex flex-v-center flex-h-center' style={{background:'var(--success-color)',width:120,marginLeft:'auto',height:30,borderRadius:4,fontSize:13,fontWeight:'bold',color:'#fff',padding:'0px 10px'}}>
                                        No Delay
                                    </div>
                                }
                            </div>
                        </div>
                            </div>
                    <div  style={{flex:2,paddingLeft:10,paddingRight:10}} className="title-container-1">
                         <p style={{fontWeight:600,fontSize:15,marginBottom:10}}>Task Status</p>
                         <div style={{display:'flex',flexWrap:'wrap'}}>
                            <div style={{width:'25%'}}>
                                <Gauge level={5} percent={(project.completedTasks)/(project.totalTasks)} />
                                <p style={{textAlign:'center',fontSize:12,fontWeight:'bold'}}>Current Project Completion</p>
                            </div>
                            <div style={{width:'25%'}}>
                                <Gauge level={5} percent={(tItem.expectedCompletedButNotDone)/(project.totalTasks)} />
                                <p style={{textAlign:'center',fontSize:12,fontWeight:'bold'}}>Expected Project Completion</p>
                            </div>
                            <div style={{width:'25%'}}>
                                <Gauge level={5} percent={onTime} />
                                <p style={{textAlign:'center',fontSize:12,fontWeight:'bold'}}>On Time Completion</p>
                            </div>
                         </div>
                    </div>
        </div>
        </>
    )
}


export default Index;