import { useRef, useState,useEffect } from "react";
import { get, post } from "../../../../../Model/Network/Config/Axios";
import { AccessButton } from "../../../../Common/Button";
import { PageLoader } from "../../../../Common/PageLoader";
import { useTableCount } from "../../../../Hooks/TableCount";
import { Header } from "../../../../NCommon/Header";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { Card } from "../../../../NCommon/Ui/CardLayout";
import { List } from "../../../../NCommon/Ui/List";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import AddHinderance from "./AddHinderance";

const Index = () => {


    const [loading,setLoading] = useState(false);
    const [data,setData] = useState({
        items:[],
        page:0,
        total:0
    });
    const [add,setAdd] = useState(false);
    let count = useTableCount();

    useEffect(()=>{
       INIT();
    },[count])

    let INIT = (mData) => {
        if(!mData) {
            mData = {page:0}
        }
        mData.size = count;
        mData.offset = (data.page*count).toString();
        get(`setting/hindrance/getAllHindrances`,(e,r)=>{
            if(r) {
                setData({
                    ...data,
                    items:r.hindrances,
                    total:r.total?r.total:data.total,
                    page:mData.page

                })
                setLoading(false);
            }
        },mData)
    }


    let mapCardLayout = (item,index) => {
        return(
            <Card key={index} title={item.title} desc={item.description} height={120} />
            )
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <p className="table-title">{item.title}</p>
                    <p className="table-bottom">{item.description}</p>
                </div>  
            </List>
        )
    }


   
    return(
        <>
            <Header title="Hinderance" />
            {
                loading ?
                <PageLoader />
                :
                <>
                    <HeaderWrapper />
                    <PageWrapper>
                        <NavigationWrapper items={[{name:`Hinderance (${data.total})`}]}>
                            <AccessButton text="Add Hinderance" onClick={()=>{setAdd(true)}} inverted={true?1:0} addsign={true?1:0} />
                        </NavigationWrapper>
                        <PaginationTable
                                DATA={data.items}
                                next={(page)=>{
                                    INIT({page})
                                }}
                                mapListLayout={mapListLayout}
                                mapCardLayout={mapCardLayout}
                                total={data.total}
                                page={data.page}
                                headers={[
                                    {
                                        name:'Details',
                                        flex:2
                                    }
                                ]}
                                />
                    </PageWrapper>
                </>
            }
            <AddHinderance open={add} close={()=>{setAdd(false);INIT()}} />
        </>
    )
}

export default Index;