import { useState } from 'react';
import {useParams} from 'react-router-dom';
import Moment from 'moment';
import {Calendar} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useProject } from '../../../../../Hooks/ProjectDetails';
import { NavigationWrapper } from '../../../../../NCommon/NavigationWrapper';
import { Button } from '../../../../../Common/Button';
import { post } from '../../../../../../Model/Network/Config/Axios';
import { showToast } from '../../../../../Common/Toast';
import { DownloadDirectCsv } from '../../../../../../Utils/Downloader';

const Index = () =>{

    const [value, setValue] = useState(Moment().toDate());
    const [loading,setLoading] = useState(false);
    let {id} = useParams();
    let project = useProject();

    let onClick = () => {
        setLoading(true);
        post(`reports/custom/ate/reports/threeMonthsMajorConsumableReport/${id}`,{start:Moment(value).valueOf()},(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:'Report export successfully'
                })
                DownloadDirectCsv("Threemonthmajorcosumable.csv",r)
            }
            setLoading(false);
        })
    }

    return(
        <div>
            <NavigationWrapper items={[{name:`All Project`,path:'/app/project'},{name:`${project.name}`,path:`/app/project/mapping/${id}`},{name:`Three month Major consumable`}]} />
            <div className="title-container-1">
                <p style={{fontSize:14,marginBottom:10,fontWeight:'600'}}>Select Date</p>
                <Calendar
                    date={value}
                    onChange={setValue}
                    className="single-date-range"
                />
                <Button loading={loading} onClick={onClick} text="Export Report" style={{width:350,marginTop:20}} />

            </div>
        </div>
    )
}

export default Index;