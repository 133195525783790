import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useTableCount } from "../../Hooks/TableCount";
import TableLayout from './TableLayout';
import { AuthActions } from "../../../Redux/Reducers";
import { set } from "../../../Utils/Crypto";


const Table = ({DATA,mapCardLayout,mapListLayout,headers,pagination,defaultView,tableStyle}) => {

    const [page,setPage] = useState(0);
    let dispatch = useDispatch();
    let count = Number(useTableCount());    
    let view = useSelector(config=>config.config.tableview)
    view = defaultView?defaultView:view
    let data = DATA;
    let start = count*page+1, end = (count*page)+count;
    if(end>DATA.length) {
        end = DATA.length;
    }

    if(pagination) {
         data = data.slice(start-1,end);
    } 


    let type = () => {
        if(view === 'card') {
            return(
                <div style={{display:'flex',flexWrap:'wrap',marginTop:15}}>
                    {data.map(mapCardLayout)}
                </div>
            )
        } else if(view === 'list') {
            return(
                <TableLayout 
                    data={data}
                    mapItem={mapListLayout}
                    headers={headers}
                    style={tableStyle}
                />
            )
        }
    }
    return(
        <div>
            {type()}
            {pagination?
            <div style={{height:50,marginTop:10,background:'',margin:10,display:'flex',alignItems:'center',justifyContent:'flex-start',padding:10}}>
                 <div style={{fontSize:14,marginRight:20}}>
                    Data Per Page 
                    <select onChange={e=>{
                        let value = e.target.value;
                        set('table-count',value)
                        dispatch({
                            type:AuthActions.Count,
                            data:value
                        })
                    }} value={count} style={{marginLeft:10}}>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={150}>150</option>
                        <option value={200}>200</option>
                    </select>
                </div>
                <div style={{fontSize:15}}>
                    Showing <span style={{fontWeight:'600',marginLeft:5}}>{start}</span> - <span style={{fontWeight:'600',marginRight:5}}>{end}</span> of <span style={{fontWeight:600}}>{DATA.length}</span>
                    <span style={{
                        background:'#fff',
                        margin:'0px 5px',
                        padding:"3px 15px",
                        fontWeight:'600',
                        borderRadius:2,
                        cursor:'pointer'
                    }} onClick={()=>{
                        if(page>0) {
                            setPage(page-1)
                        }
                    }}>Prev</span>
                    <span style={{
                        background:'#fff',
                        margin:'0px 5px',
                        padding:"3px 15px",
                        fontWeight:'600',
                        borderRadius:2,
                        cursor:'pointer'
                    }} className="box-shadow" onClick={()=>{
                        if(DATA.length>(page+1)*count){
                            setPage(page+1)
                        }
                    }}>Next</span>
                </div>
               
            </div>
            :null}
        </div>
    )
}

Table.defaultProps = {
    pagination:true,
    onScroll:()=>{

    }
}

export {Table}