import { useEffect, useState } from "react";
import Moment from 'moment';
import { useParams} from 'react-router-dom';
import { get } from "../../../../../Model/Network/Config/Axios";
import { LineGraph } from "../../../../Common/Graphs";




const Index = () => {

    let {id} = useParams();
    let [date,setDate] = useState([]);
    let [planned,setPlanned] = useState([]);
    let [estimated,setEstimated] = useState([]);


    useEffect(()=>{
        INIT();
    },[])

    let INIT = () =>{
        get(`project/timelines/${id}`,(e,r)=>{
            if(r) {
                let t1=[],t2=[],t3=[];
                r.timelines.slice(0,r.timelines.length).forEach(val=>{
                    t1.push(Moment(val.date,'DD-MM-YYYY').format('DD-MM-YY'));
                    t2.push(val.estimatedFinishDate);
                    t3.push(val.plannedFinishDate)
                })
                setDate(t1);
                setPlanned(t3);
                setEstimated(t2);
            }
        })
    }



    return(
        <div className="title-container" style={{margin:0,marginTop:20,minHeight:100}}>
            <LineGraph
                items={date}
                data={[
                    {
                        name: "Estimated Finish Date",
                        data: estimated
                      },
                      {
                        name: "Planned Finish date",
                        data: planned
                      }
                ]}
            />
        </div>
    )
}

export default Index;