import { FloatFile, FloatInput } from "../../../Common/FloatInput";
import { Button } from '../../../Common/Button';
import { showToast } from "../../../Common/Toast/index";
import { post } from "../../../../Model/Network/Config/Axios";
import { useRef, useState } from "react";
import { SimpleFlex } from "../../../Common/Flex/SimpleFlex";
import { FunctionalInput } from "../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../Common/Modal/RightModal";


const AddVendor = ({onComplete,onClose}) => {


    const [buttonLoading,setButtonLoading] = useState(false);

    let vName=useRef(),vEmail=useRef(),vCode=useRef(),vMobile=useRef(),vBankName=useRef(),vAccountNumber=useRef(),vIfsc=useRef(),vVpa=useRef(),vAddress=useRef(),
        vCity=useRef(),vPincode=useRef(),vState=useRef(),vGstin=useRef(),vPan=useRef(),vLogo=useRef(),vBenName=useRef();

    ;


    let addVendor = () => {

        let name = vName.current.value;
        let email = vEmail.current.value;
        let code =vCode.current.value;
        let mobile =vMobile.current.value;
        let benName = vBenName.current.value;
        let bankName =  vBankName.current.value;
        let account = vAccountNumber.current.value;
        let ifsc = vIfsc.current.value
        let vpa =vVpa.current.value;
        let address =vAddress.current.value;
        let city = vCity.current.value;
        let pincode = vPincode.current.value;
        let state =  vState.current.value;
        let gstin = vGstin.current.value;
        let pan = vPan.current.value;
        let logo = vLogo.current.getFileUrl()


    
        if(!name) {
            showToast({
                type:'error',
                message:'Please enter the vendor name'
            })
            return;
        }

        if(!email) {
            showToast({
                type:'error',
                menubar:'Please enter the valid email'
            })
            return;
        }

        if(!mobile) {
            showToast({
                type:'error',
                message:"Please enter the mobile number"
            })
            return
        }

        if(!code) {
            showToast({
                type:'error',
                message:"Please enter the Vendor Code"
            })
            return;
        }

        if(!address) {
            showToast({
                type:'error',
                message:"Please enter the address of vendor"
            })
            return;
        }

        if(!city) {
            showToast({
                type:'error',
                message:"Please enter the city of vendor"
            })
            return;
        }

        let data = {
            name,
            code,
            email,
            mobile,
            address:{
                address,
                city,
                state,
                pincode,
                gstin
            },
            bank:{
                benName,
                bankName,
                ifsc,
                account,
                vpa
            },
            pan,
            logo
        }

        setButtonLoading(true);
        post(`vendor/add`,data,(e,r)=>{
            if(r){
                showToast({
                    type:"success",
                    message:"Vendor added successfully"
                })
                onComplete();
            }
        })
    }

    return(
        <RightModal
             show={true}
             onClose={onClose}
             title="Add Vendor"
             desc="Fill up the details and add vendor"
             size="M"
         >
            <SimpleFlex>
                <FunctionalInput title="Vendor Name" type="text" ref={vName} style={{marginRight:20}} />
                <FunctionalInput title="Email" type="text" ref={vEmail} />
            </SimpleFlex>

            <SimpleFlex>
                <FunctionalInput title="Vendor Code" type="text" ref={vCode} style={{marginRight:20}} />
                <FunctionalInput title="Mobile Number" type="text" ref={vMobile} />
            </SimpleFlex>

            <FloatInput title="Beneficiary Name (optional)" type="text" ref={vBenName} />

            <SimpleFlex>
                <FunctionalInput title="Bank Name (optional)" type="text" ref={vBankName} style={{marginRight:20}} />
                <FunctionalInput title="Bank Account Number (optional)" type="text" ref={vAccountNumber} />
            </SimpleFlex>

            <SimpleFlex>
                <FunctionalInput title="Bank Ifsc Code (optional)" type="text" ref={vIfsc} style={{marginRight:20}} />
                <FunctionalInput title="Vpa ( Upi Address ) (optional)" type="text" ref={vVpa} />
            </SimpleFlex>

            <SimpleFlex>
                <FunctionalInput title="Address" type="text" ref={vAddress}style={{marginRight:20}} />
                <FunctionalInput title="City" type="text" ref={vCity} />
            </SimpleFlex>

            <SimpleFlex>
                <FunctionalInput title="State (optional)" type="text" ref={vState} style={{marginRight:20}}/>
                <FunctionalInput title="Pin Code (optional)" type="text" ref={vPincode} />
            </SimpleFlex>


            <SimpleFlex>
                <FunctionalInput title="Gstin (optional)" type="text" ref={vGstin} style={{marginRight:20}} />
                <FunctionalInput title="Pan (optional)" type="text" ref={vPan} />
            </SimpleFlex>
          
            <FloatFile title="Logo" handleRef={ref=>vLogo.current=ref} />

            <Button 
                text="Add Vendor" 
                style={{marginTop:30}} 
                onClick={addVendor} 
                loading={buttonLoading}
            />

    </RightModal>
    )
}


export default AddVendor;