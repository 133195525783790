import { useSelector } from "react-redux"


const useDictionary = () => {

    let items = useSelector(state=>{
        return {
            items:state.config.config.items
        }
    })
    return items.items;
}



const useDictionaryValue = () => {
    let items = useSelector(state=>{
        return {
            items:state.config.config.items
        }
    })

    let lowerCaseDictionary = {};
    
    if(items && items.items) {
    Object.keys(items.items).forEach((val)=>{
        lowerCaseDictionary[val.toLowerCase()] = items.items[val]
    })
    }
    const getDictionaryValue = (value) => {
        if(lowerCaseDictionary) {
            return lowerCaseDictionary[value.toLowerCase()]?lowerCaseDictionary[value.toLowerCase()]:value;
        } else {
            return value
        }
    }
    return getDictionaryValue;
}


const withDictionaryHook = (Component) => {
    return function WrappedComponent(props) {
        const getDictionaryValue = useDictionaryValue();
        return <Component {...props} getDictionaryValue={getDictionaryValue} />;
      }
}


export {useDictionary,useDictionaryValue,withDictionaryHook}

