import Project from '../Custom/Project';
import Wbs from '../Custom/Wbs';
import Task from '../Custom/Task';
import Drawing from '../Custom/Drawing';
import TaskCategory from '../Custom/TaskCategory'
import Resource from '../Custom/Resource';

const data = [
    {
        name:'Project Levels',
        path:'/app/setting/project',
        component:Project,
        type:'PROJECT'
    },
    {
        name:'Wbs Levels',
        path:'/app/setting/wbs',
        component:Wbs,
        type:'WBS'
    },
    {
        name:'Task Category',
        path:'/app/setting/task.category',
        component:TaskCategory,
        type:'TASK_CATEGORY'
    },
    {
        name:'Task Levels',
        path:'/app/setting/task',
        component:Task,
        type:'TASK'
    },
    {
        name:'Drawing Levels',
        path:'/app/setting/drawing',
        component:Drawing,
        type:'DRAWING'
    },
    {
        name:'Resource Levels',
        path:'/app/setting/resourcelevel',
        component:Resource,
        type:'RESOURCE'
    }
]

export default data;