import { useState } from "react";
import { ImageModal } from "./ImageModal";

const Carousel = (props) => {
  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(undefined);

  let taskNameErr = (name) => {
      if (name.length > 90) {
          let splitArr = name.split("_");
          let finalName = "";
          for (let i = 0; i < splitArr.length; i++) {
              if ((finalName+splitArr[i]).length > 90) {
                  if (finalName.length < 50) {
                      return (finalName+splitArr[i]).substring(0, 90);
                  } else {
                      return finalName;
                  }
              } else {
                  finalName += splitArr[i];
              }
          }
      } else {
          return name;
      }
  };

  let mapPhotos = (item, index) => {
    return (
      <div
        style={{
          padding: 5,
          width: props.sizeIncr ? "60%" : "14%",
          boxSizing: "border-box",
        }}
        onClick={() => {
          setIndex(index);
          setModal(true);
        }}
        key={index}
      >
        <img
          onClick={() => {
            setIndex(index);
            setModal(true);
          }}
          src={item.url}
          key={index}
          style={{
            height: props.showOuterDetails ? 140 : 40,
            width: "100%",
            borderRadius: 2,
            cursor: "pointer",
            objectFit: "cover",
          }}
        />
        {props.showOuterDetails && item.taskName ? (
          <p style={{ fontSize: 12, textAlign: "center" }}>{taskNameErr(item.taskName)}</p>
        ) : null}
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
      }}
      onClick={(e) => (props.showOuterDetails ? null : e.stopPropagation())}
    >
      {props.items.map(mapPhotos)}
      {modal ? (
        <ImageModal
          items={props.items}
          index={index}
          close={() => {
            setModal(false);
          }}
          showDetails={props.showDetails}
        />
      ) : null}
    </div>
  );
};

Carousel.defaultProps = {
  showOuterDetails: true,
};

export { Carousel };
