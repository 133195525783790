import Props from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useAccess } from '../../Hooks/ReadWriteConfig';

const Button = (props) => {


    let { onClick, text, style, loading, inverted, addsign } = props;

    if (loading) {
        return (
            <div style={{
                backgroundColor: 'var(--button-color)',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 600,
                borderRadius: 2,
                cursor: 'pointer',
                fontSize: 11,
                border: '1px solid var(--button-color)',
                minWidth: 130,
                boxShadow: '0 1px 0 0 rgb(171 181 189 / 50%), 0 3px 6px 0 rgb(0 0 0 / 20%)',
                height: 45,
                ...style
            }}>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }

    return (
        <>
            <ReactTooltip />

            <div
                {...props}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    ...style,

                }} onClick={onClick} className={`flex flex-v-center flex-h-center button ${inverted ? 'button-invert' : 'btn'}`}
            >
                {addsign ?
                    <p style={{
                        fontSize: 22,
                        paddingRight: 5,
                        fontWeight: 500,
                        position: 'relative',
                        top: -2.5
                    }}>+</p> :
                    null
                }
                {text}
            </div>
        </>
    )
}

Button.PropsTypes = {
    text: Props.string.isRequired,
  
}

Button.defaultProps = {
    onClick: () => { },
    loading: undefined

}



const AccessButton = (props) => {


    let { onClick, text, style, loading, inverted, addsign } = props;
    let access = useAccess();


    if(!access) {
        return <div />
    }

    if (loading) {
        return (
            <div style={{
                backgroundColor: 'var(--button-color)',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 600,
                borderRadius: 2,
                cursor: 'pointer',
                fontSize: 11,
                border: '1px solid var(--button-color)',
                minWidth: 130,
                boxShadow: '0 1px 0 0 rgb(171 181 189 / 50%), 0 3px 6px 0 rgb(0 0 0 / 20%)',
                height: 38,
                ...style
            }}>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }

    return (
        <>
            <ReactTooltip />
            <div
                {...props}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height:38,
                    padding:'0px 10px',
                    ...style,

                }} onClick={onClick} className={`flex flex-v-center flex-h-center button ${inverted ? 'button-invert' : 'btn'}`}
            >
                {addsign ?
                    <p style={{
                        fontSize: 22,
                        paddingRight: 5,
                        fontWeight: 500,
                        position: 'relative',
                        top: -2.5
                    }}>+</p> :
                    null
                }
                {text}
            </div>
        </>
    )
}

AccessButton.PropsTypes = {
    text: Props.string.isRequired,
    inverted:Props.bool
  
}

AccessButton.defaultProps = {
    onClick: () => { },
    loading: undefined

}


const ReadAccess  = (props) => {
    const access = useAccess();
    if(!access) {
        return <div />
    }
    return(
        <>
            {props.children}
        </>
    )
}

const LineItemButton = (props) => {
    const access = useAccess();
    if(!access) {
        return <div/>
    }
    return(
        <p onClick={props.onClick} style={{
            ...props.style
        }} className="hoverable">{props.title}</p>
    )
}

export { Button,AccessButton,ReadAccess ,LineItemButton}