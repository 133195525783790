import { useRef, useState } from "react";
import { useParams} from 'react-router-dom';
import { post } from "../../../../../Model/Network/Config/Axios";
import { Button } from "../../../../Common/Button";
import { SearchVendor } from "../../../../Common/Search/SeachVendor";
import { EmployeeSearch } from "../../../../Common/Search/SearchEmployee";
import { SearchResourceCategory } from "../../../../Common/Search/SearchResourceCategory";
import { SearchTaskCategory } from "../../../../Common/Search/SearchTaskCategory";
import { SearchTaskN } from "../../../../Common/Search/SearchTaskN";
import { SearchWbsMappingItemN } from "../../../../Common/Search/SearchWbsMappingItemN";
import { showToast } from "../../../../Common/Toast";
import { useDictionaryValue } from "../../../../Hooks/DictionaryHooks";
import { MainModal } from "../../../../NCommon/Modal";




const Index = (props) => {

    let {id} = useParams();
    const [category,setCategory] = useState('');
    let getDictionaryValue = useDictionaryValue();
    let WBS = useRef(),TASK=useRef(),RESPONSIBLE=useRef(),APPROVER=useRef(),VENDOR=useRef(),RESOURCE=useRef();


    let add = () => {
        let resourceId = RESOURCE.current.getValue();
        let wbsItemMappingId = WBS.current.getValue();
        let taskId = TASK.current.getValue();
        let responsiblePersonId = RESPONSIBLE.current.getValue();
        let approverId = APPROVER.current.getValue();
        let responsibleVendorId = VENDOR.current.getValue();

        if(!resourceId) {
            showToast({
                type:'error',
                message:'Please select the resource'
            })
            return;
        }

        if(!wbsItemMappingId) {
            showToast({
                type:'error',
                message:'Please select the Wbs Item'
            })
            return;
        }

        if(!taskId) {
            showToast({
                type:'error',
                message:'Please select the task Item'
            })
            return;
        }

        if(!responsiblePersonId) {
            showToast({
                type:'error',
                message:'Please select the responsible person'
            })
            return;
        }

        if(!approverId) {
            showToast({
                type:'error',
                message:'Please select the approver person'
            })
            return;
        }

        


        let data = {
            resourceId:resourceId.id,
            wbsItemMappingId:wbsItemMappingId.id,
            taskId:taskId.id,
            responsiblePersonId: responsiblePersonId? responsiblePersonId.id:null,
            approverId: approverId? approverId.id:null,
            responsibleVendorId: responsibleVendorId?responsibleVendorId.vendorMappingId:null
        }


        post(`project/resourceView/shuttering/postConfig/${id}`,data,(e,r)=>{
            if(r) {
                showToast({
                    type:'success',
                    message:"Shuttering added successfully"
                })
                props.complete()
            }
        })        
    }

    return(
        <MainModal
            open={props.show}
            title="Add Shuttering"
            close={props.close}
        >
            <SearchResourceCategory 
                title="Search Resource"
                handleRef={ref=>RESOURCE.current=ref}
            />
            <SearchWbsMappingItemN 
                title="Search Wbs Item"
                handleRef={ref=>WBS.current=ref}
                projectId={id}
            />
            <SearchTaskCategory
                title={`Search ${getDictionaryValue('Task')} Category`}
                handleRef={()=>{}}
                onChange={setCategory}
                />
            <SearchTaskN
                title={`Search ${getDictionaryValue('Task')}`}
                handleRef={ref=>TASK.current=ref} 
                category={category}
                categorySearch={true}
                type="REGULAR"
                />
             <EmployeeSearch
                title="Responsible Person"
                placeholder="Search Responsible Person"
                handleRef={ref => RESPONSIBLE.current = ref}
            /> 
             <EmployeeSearch
                title="Approver Person"
                placeholder="Search Approver Person"
                handleRef={ref => APPROVER.current = ref}
            />   
            <SearchVendor
                 title="Search Vendor (optional)"
                 placeholder="Search Vendor"
                 handleRef={ref => VENDOR.current = ref}
            />
            <Button text="Add" style={{marginTop:20}} onClick={add} />

        </MainModal>
    )
}


export default Index;