import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';



class SearchTaskCategory extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            attributeTypes: [],
            value: '',
            suggestion: []
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
        get(`project/task/taskCategory.all`, (e, r) => {
            if (r) {
                this.setState({
                    ...this.state,
                    attributeTypes: r.taskCategoryDetails
                })
            }

        })
    }


    onSearch = (val) => {
        let suggestion = this.state.attributeTypes.filter(i => i.name.toLowerCase().includes(val.toLowerCase()));
        if (val.length > 0) {
            this.setState({
                ...this.state,
                suggestion
            })
        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }

    }


    getValue = () => {
        return this.state.value;
    }


    render() {
        return (
            <div style={{
                position: 'relative'
            }}>
                <FloatInput
                    title={this.props.title}
                    type="text"
                    handleRef={ref => this.attribute = ref}
                    onChange={(val) => {
                        this.onSearch(val)
                    }}
                />
                {
                    this.state.suggestion.length > 0 &&
                    <div className="suggestion-modal" style={{top:68}}>
                        {this.state.suggestion.map((item, index) => (
                            <div key={index}onClick={() => {
                                this.setState({
                                    ...this.state,
                                    suggestion: [],
                                    value: item
                                })
                                if(this.props.onChange) {
                                    this.props.onChange(item)
                                }
                                this.attribute.setValue(item.name);
                            }} style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}} className="suggestion-modal-item">
                                <p>{item.name}</p>
                                <p style={{fontSize:11,color:'var(--text-light-color)'}}>{item.details}</p>
                                
                            </div>
                        ))}

                    </div>
                }
            </div>
        )
    }
}

export { SearchTaskCategory };