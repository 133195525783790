import {useParams,useLocation,Switch,Route} from 'react-router-dom';
import { get as fetch_details } from '../../../../Utils/Crypto';
import Employee  from './Employee';
import Wbs from './Wbs';
import TimeLine from './TimeLine'
import { Header } from '../../../NCommon/Header';


const Index = () => {

    let project = fetch_details('PROJECT_DETAILS');
    let {id} = useParams();
    let {pathname} = useLocation();

    return (
            <>
                <Header
                    title={`${project.name}`}
                    desc={`${project.address}`}
                    items={[
                        {
                            name:'By TimeLine',
                            link:`/app/project/images/${id}/timeline`,
                            active: pathname.includes(`/app/project/images/${id}/timeline`)
                            
                        },
                        {
                            name:'By Employee',
                            link:`/app/project/images/${id}/employee`,
                            active: pathname.includes(`/app/project/images/${id}/employee`)
                        },
                        {
                            name:'By Wbs Item',
                            link:`/app/project/images/${id}/wbs`,
                            active: pathname.includes(`/app/project/images/${id}/wbs`)
                        },
                        
                    ]}
                />
                 <Switch>
                    <Route path="/app/project/images/:id/employee" component={Employee}  />
                    <Route path="/app/project/images/:id/wbs" component={Wbs} />
                    <Route path="/app/project/images/:id/timeline" component={TimeLine} />
                </Switch>
            </>
        )
}

export default Index;