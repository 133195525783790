import { useEffect, useState } from "react";
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {  post } from "../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../Common/PageLoader";
import ViewResource from './ViewResource';
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { PaginationTable } from "../../../../NCommon/Tables/PaginationTable";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { useProject } from "../../../../Hooks/ProjectDetails";
import { useTableCount } from "../../../../Hooks/TableCount";
import { List } from '../../../../NCommon/Ui/List';
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";
import ResolveConflict from "./ResolveConflict";


const Index = () => {


    const [loading,setLoading] = useState(true);
    const [show,setShow] = useState(false);
    const [filters,setFilters] = useState([]);
    const [conflict,setConflict] = useState(false);

    let [mData,setMData] = useState({
        page:0,
        total:0,
        items:[]
    })
    let {id} = useParams();
    let details = useProject();
    let count = useTableCount();


    const [status,setStatus] = useState([
        {
            name: "Progress",
            active: false,
            color: "#ebb840",
            key:'PROGRESS'
          },
          {
            name: "Acquired",
            active: false,
            color: "#404959",
            key:'ACQUIRED'
          },
          {
            name: "Not Started",
            active: false,
            color: "#ff944d",
            key:'NOTSTARTED'
          },
          {
            name: "Completed",
            active: false,
            color: "#68d083",
            key:'COMPLETED'
          },
          {
            name: "Critical",
            active: false,
            color: "#ea3a3c",
            key:'CRITICAL'
          },
    ])

    const [resourcesType,setResourceType] = useState([
        {
            name: "Labour",
            active: false,
            color: "#ebb840",
            key:'LABOUR'
          },
          {
            name: "Material",
            active: false,
            color: "#404959",
            key:'MATERIAL'
          },
          {
            name: "Machines",
            active: false,
            color: "#ff944d",
            key:'MACHINES'
          },
    ])
    

    useEffect(()=>{
        INIT({page:0})
    },[count,status,resourcesType])

    let INIT = (data) => {
        if(!data) {
            data={
                page:0
            }
        }
        data = {
            ...data
        }
        if(data.filter) {
            data.orFilters = data.filter;
            delete data.filter
        }
        let types = data.orFilters? [...data.orFilters]:[];
        resourcesType.forEach(val=>{
            if(val.active) {
                types.push({key:'RESOURCECATEGORYTYPE',val:val.key})
            }
        })
        let otherNodes =[];
        status.forEach(val=>{
            if(val.active) {
              otherNodes.push({key:val.key,val:''})
            }
        })
        data.size = `${count}`;
        data.offset = `${data.page*count}`;
        data.andFilters=otherNodes;
        data.orFilters = types;
        post(`project/resourceView/fetchConflictResources/${id}`,data,(e,r)=>{
           if(r) {
             setLoading(false);
             setMData({
                 ...mData,
                 page:data.page,
                 total:r.total?r.total:mData.total,
                 items:r.resources
             })
           }
       })
    }


    let mapListLayout = (item,index) => {
        return(
            <List key={index} onClick={()=>{setShow(item)}} style={{height:75}}> 
                <div className="flex-2">
                    <p className="table-title">{item.name}</p>
                    <p className="table-bottom">{item.wbsTaskName}</p>
                    <p className="table-bottom">reason :{item.reason}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.category}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.resourceType}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.subType}</p>
                </div>
                <div className="flex-1">
                    <p className="table-text">{item.qty} {item.units}</p>
                </div>
                <TableMenu items={[{name:'Resolve',onClick:(e)=>{e.stopPropagation(); setConflict(item)}}]} />
            </List>
        )
    }

    let next = ({page}) => {
        let items = [...filters];
        let filter = [];
        items.forEach(item=>{
            filter.push({
                key:item.type,
                val:item.type==='TASK'?`${item.id}`: item.type === 'WBS' ? `${item.id}`:item.value
                               
              })
        })
        INIT({page,filter})
    }
    
   
    return(
        <>
           
            {
                loading ?
                <PageLoader />:
                <>
                     <HeaderWrapper 
                        filters={[...filters]} 
                        dynamicFilter={true} 
                        onFilterChange={items=>{
                            let filter = [];
                            items.forEach(item=>{
                                filter.push({
                                    key:item.type,
                                    val:item.type==='TASK'?`${item.id}`: item.type === 'WBS' ? `${item.id}`:item.value
                                  })
                            })
                            setFilters(items)
                            INIT({page:0,filter})
                        }} 
                        status={status}
                        onChangeStatusFilter={setStatus}
                        statusFilter={true}
                        otherStatus={{
                            name:"Category",
                            items:resourcesType
                        }}
                        setOtherStatus={setResourceType}
                        types={["Resource","Task","Wbs Mapping","Responsible Person","Approver","Custom Text"]} 
                        hideCard={true}
                        />
                    <PageWrapper defaultView="list" id="pagination-table-header" scroll={true}>
                        <NavigationWrapper items={[{name:`${details.name}`,path:`/app/project/mapping/${id}`},{name:`Resource (${mData.total})`}]} />
                        <PaginationTable
                              DATA={mData.items}
                              next={(page)=>{
                                next({page})
                              }}
                              mapCardLayout={()=>{}}
                              mapListLayout={mapListLayout}
                              total={mData.total}
                              page={mData.page}
                              headers={[
                               
                                  {
                                      name:'Details',
                                      flex:2
                                  },
                                  {
                                      name:'Category',
                                      flex:1
                                  },
                                  {
                                      name:'Resource Type',
                                      flex:1
                                  },
                                  {
                                      name:'Sub Type',
                                      flex:1
                                  },
                                  {
                                      name:'Total Quantity',
                                      flex:1
                                  },
                                  {
                                      name:'actions',
                                      width:50
                                  }
                              ]}
                              defaultView={'list'}
                        />
                    </PageWrapper>
            {
              show ?
              <ViewResource 
                  resource={show}
                  onClose={()=>{
                      setShow(false);
                  }}
                  projectId={id}
              />
              : null      
            }     
            <ResolveConflict show={conflict} close={()=>{setConflict(false);INIT({page:mData.page})}} />
           
                </>
            }
        </>
    )
}
export default Index;