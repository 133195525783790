import  { useEffect, useRef, useState } from 'react';
import { PageLoader } from '../PageLoader';
import { get, axios } from '../../../Model/Network/Config/Axios';
import { AccessButton, Button } from '../Button';
import { RightModal } from '../Modal/RightModal';
import EditDetails from './EditDetails';
import { showToast } from '../Toast';
import { LineHeader } from '../LineHeader';
import TaskResource from './TaskResource';
import TaskDetails from './TaskDetails';
import TaskCheckList from './TaskCheckList';
import TaskApprovalTime from './TaskApprovalTime';
import { FunctionalInput } from '../FloatInput/FunctionalInput';
import { CenterModal } from '../Modal/CenterModal';
import TaskDrawing from './TaskDrawing';

const Index = (props) => {

    const [loading,setLoading] = useState(true);
    const [details,setDetails ] = useState({});
    const [approvalDetails,setApprovalDetails ] = useState([]);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [images,setImages] = useState([]);
    const [key,setKey] = useState('details');
    const [edit,setEdit] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    let REASON = useRef(); 

    let getId = () => {
        return props.details.id;
    }
    let getProjectId = () => {
        return props.projectId
    }
    useEffect(()=>{
        INIT()
    },[])

   let INIT = () => {
        get(`project/task/taskMappingDetails/${getId()}`, (e, r) => {
            if (r) {
                let images = [];
                Object.keys(r.checklists).forEach(val => {
                    Object.keys(r.checklists[val]).forEach(i => {
                        images.push({
                            url:r.checklists[val][i].photo,
                            taskName:props.details.wbsItemTaskName,
                            date:r.checklists[val][i].date,
                            remarks:r.checklists[val][i].workDesc,
                            employeeName:r.tasksMapping.responsiblePerson.name
                        })
                        images.push(...r.checklists[val][i].photos.map(url=>({
                            url,
                            taskName:props.details.wbsItemTaskName,
                            date:r.checklists[val][i].date,
                            remarks:r.checklists[val][i].workDesc,
                            employeeName:r.tasksMapping.responsiblePerson.name
                        })))
                    })
                })
                let filterImages = images.map(val => ({
                    ...val
                }))
                setDetails(r);
                setImages(filterImages);
                setLoading(false);
            }
        })
        get(`project/task/taskMappingApprovals/${getId()}`, (e, r) => {
            if (r) {
                setApprovalDetails(r.approvalDetails);
            }
        })
    }
    return (
            <CenterModal onClose={()=>{
                props.onClose()
            }} header={props.details.wbsItemTaskName}>

                {
                   loading ?
                   <div style={{
                       paddingTop: 50
                   }}>
                       <PageLoader />
                   </div>         
                    :
                    <div style={{
                        padding: 20,
                        paddingTop: 100
                    }}>
                        <div style={{
                            position: 'absolute',
                            right: 20,
                            top: 85,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <AccessButton
                                text="Edit"
                                inverted={true?1:0}
                                style={{
                                    width: 100,
                                }}
                                onClick={() => {
                                    setEdit(true);
                                }}
                            />
                            <AccessButton
                                text="Delete"
                                style={{
                                    width: 100,
                                    backgroundColor: 'var(--red-color)',
                                    borderColor: 'var(--red-color)',
                                    marginLeft: 10,
                                    borderRadius:4
                                }}
                                onClick={() => {
                                    setDeleteModal(true);
                                }}
                            />
                        </div>
                        <LineHeader 
                            items={[
                                {
                                    name:'Details',
                                    active:key === 'details',
                                    onClick:()=>{
                                        setKey('details')
                                    }
                                },
                                {
                                    name:'Checklist',
                                    active:key === 'checklist',
                                    onClick:()=>{
                                        setKey('checklist')
                                    }
                                },
                                {
                                    name:'Approval Timeline',
                                    active:key === 'approval',
                                    onClick:()=>{
                                        setKey('approval')
                                    }
                                },
                                {
                                    name:'Resource',
                                    active:key==='resource',
                                    onClick:()=>{
                                        setKey('resource')
                                    }
                                },
                                {
                                    name:'Drawing',
                                    active:key==='drawing',
                                    onClick:()=>{
                                        setKey('drawing')
                                    }
                                }
                            ]}
                        />
                       
                        {
                            key === 'details' ? <TaskDetails getId={getId()} images={images} tasksMapping={details.tasksMapping} /> : null
                        }
                        {
                            key === 'checklist' ? <TaskCheckList details={details} /> : null
                        }
                        {
                            key === 'approval' ? <TaskApprovalTime approvalDetails={approvalDetails} /> : null
                        }
                        {
                            key === 'resource' ? <TaskResource  taskId={getId()}  projectId = {getProjectId()} />:null
                        }
                        {
                            key === 'drawing' ? <TaskDrawing taskId={getId()} projectId={getProjectId()} />:null
                        }

                    </div>     
                }

                 {
                  edit ?
                  <RightModal
                      title={details.tasksMapping.task.name}
                      desc="Edit Details"
                      show={edit}
                      onClose={() => {
                          setEdit(false);
                      }}
                  >
                      <EditDetails
                          id={getId()}
                          details={details}
                          onClose={() => {
                              INIT();
                              props.init();
                              setEdit(false);
                          }}
                          projectId={getProjectId()}
                      />
                  </RightModal>
                  : null          
                 }       

                {
                     deleteModal ?
                        <RightModal
                            show={deleteModal}
                            title="Delete Task"
                            desc={details.wbsItemTaskName}
                            onClose={() => {
                                setDeleteModal(false);
                            }}
                        >
                            <FunctionalInput type="text" title="Enter Reason" ref={REASON} />
                            <Button
                                loading={buttonLoading}
                                text="Delete Task"
                                style={{
                                    marginTop: 20,
                                    backgroundColor: 'var(--red-color)',
                                    borderColor: 'var(--red-color)',
                                    width: 350
                                }}
                                onClick={() => {
                                    let reason = REASON.current.value;
                                    if (!reason) {
                                        showToast({
                                            type: 'error',
                                            message: 'Please enter the reason'
                                        })
                                        return
                                    }
                                    setButtonLoading(true);
                                    axios.delete(`/project/task/taskMapping.delete/${getId()}?reason=${reason}&projectId=${getProjectId()}`, {
                                        data: {
                                            reason
                                        }
                                    })
                                        .then(res => {
                                            setDeleteModal(false);
                                            setButtonLoading(false);
                                            props.onClose();
                                            props.init();
                                            showToast({
                                                type: 'success',
                                                message: "Task delete successfully"
                                            })
                                        })

                                }}
                            />
                        </RightModal> :
                        null
                }
            </CenterModal>
        )
}

export default Index;