import { useRef, useState } from "react";
import { RightModal } from "../../../Common/Modal/RightModal"
import { SearchResourceCategory } from "../../../Common/Search/SearchResourceCategory";
import { Button } from '../../../Common/Button';
import { showToast } from "../../../Common/Toast";
import { post } from "../../../../Model/Network/Config/Axios";


const MapResource = (props) => {

    let RESOURCE = useRef();
    let [buttonLoading,setButtonLoading] = useState(false);


    let map = () =>{
        let resource = RESOURCE.current.getValue();

        if(!resource) {
            showToast({
                type:'error',
                message:'Please select the resource'
            })
            return;
        }
      

        let data = {
            resourceId:resource.id,
            qty:0,
            taskId:props.id
        }
        setButtonLoading(true);
        post(`project/task/resource/mapResourceTemplate`,data,(e,r)=>{
            if(r) {
                setButtonLoading(false);
                showToast({
                    type:'success',
                    message:"Resource template map successfully"
                })
                props.init();
            }
        })
    }

    return(
        <RightModal
            title="Map Resource"
            desc="Fill up the details and map resource"
            show={true}
            onClose={props.onClose}
        >
            <SearchResourceCategory title="Resource" handleRef={ref=>RESOURCE.current=ref} />
            <Button text="Map" style={{marginTop:30}} onClick={map} loading={buttonLoading} />

        </RightModal>
    )
}

export default MapResource;