import { useState } from "react";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import {EmailShareButton,WhatsappShareButton,WhatsappIcon,EmailIcon} from 'react-share'
import { Card } from "../../../../NCommon/Ui/CardLayout";


const Index = () => {

    let [data] = useState([
        {
            name:'BuildRun Employee Android App',
            url:`https://play.google.com/store/apps/details?id=com.buildrunnew`,
        },
        {
            name:'BuildRun Employee Desktop Version',
            url:`https://buildrun-employee.netlify.app/`
        }
    ])

    let mapItem = (item,index) => {
        return(
            <Card onClick={()=>{window.open(item.url,"_blank")}}  key={index} title={item.name} height={150}>
                <p style={{fontWeight:'600',fontSize:14,marginBottom:10,color:'var(--text-light-color)'}}>Share Link</p>
                <div style={{display:'flex'}}>
                    <WhatsappShareButton title={item.name} url={item.url} separator="::">
                        <WhatsappIcon round={true} size={32}/>
                    </WhatsappShareButton>
                    <EmailShareButton title={item.name} url={item.url} separator="::">
                        <EmailIcon round={true} size={32} style={{marginLeft:10}} />
                    </EmailShareButton>
                </div>
            </Card>
        )
    }

    return(
        <>
           <PageWrapper>
                <NavigationWrapper items={[{name:'All Setting',path:'/app/setting'},{name:"Resource"}]} />     
                <div className="flex">
                    {data.map(mapItem)}
                </div>          
              
           </PageWrapper>
        </>
    )
}

export default Index;