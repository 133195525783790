import React from "react"
import { SearchSelect } from "../Search/SearchSelect/SearchSelect"


class WeekDays extends  React.Component {


componentDidMount() {
    if(this.props.handleRef) {
        this.props.handleRef(this);
    }
}

setValue = (value) => {
    this.select.setValue({value})
}


render() {
        return(
            <>
                <SearchSelect 
                    handleRef={ref=>this.select=ref}
                    title="Select Day" 
                    onChange={(val)=>{
                        this.props.select(val)
                    }} items={[{value:"Monday",key:"Monday"},{value:"Tuesday",key:'Tuesday'},{value:'Wednesday',key:'Wednesday'},{value:'Thursday',key:'Thursday'},{value:'Friday',key:'Friday'},{value:'Saturday',key:'Saturday'},{value:'Sunday',key:'Sunday'}]} />
                     
            </>
        )
    }
}

export {WeekDays}