import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';
import { downarrow } from '../../Images';





class FetchSubResource extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            taskMappedWbsItems: [],
            suggestion: [],
            result:[]
        }
    }

    
    componentDidUpdate(props) {
        if(props.type!==this.props.type) {
            get(`resource/subTypes/${this.props.type}`,(e,r)=>{
                if(r) {
                    this.setState({
                        ...this.state,
                        result:r.subResourceTypes
                    })
                }
            })
        }
    }

    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
    }

    onSearch = (val) => {
        if (val.length > 0) {
            this.setState({
                ...this.state,
                suggestion:this.state.result.filter(res=>{
                    if(res.toLowerCase().includes(val.toLowerCase())) {
                        return true;
                    }
                    return false
                })
            })
        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }
    }

    setValue = (name) => {
        this.setState({
            ...this.state,
            name
        })
        this.parent.setValue(name)
    }
    getValue = () => {
        return this.state.name
    }

    render() {
        return (
            <div style={{
                flex: 1,
                position: 'relative'
            }} >
                <FloatInput 
                    title={this.props.title} 
                    handleRef={ref => this.parent = ref} 
                    placeholder={this.props.placeholder}
                    onClick={()=>{this.setState({
                        ...this.state,
                        suggestion:this.state.result
                    })}}
                    onChange={val => {
                    this.onSearch(val);
                    }} 
                    />
                <img src={downarrow} alt="" style={{height:25,width:25,position:'absolute',top:35,right:0}} />
                {
                    this.state.suggestion.length > 0 ?
                        <div className="suggestion-modal" style={{marginTop:15}}>
                            {this.state.suggestion.map((item, index) => (
                                <div key={index} className="suggestion-modal-item" onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        suggestion: [],
                                        name: item
                                    })
                                    this.parent.setValue(item);
                                }}>
                                    <p style={{
                                        fontSize: 14,
                                        fontWeight:600
                                    }}>{item}</p>

                                </div>
                            ))}

                        </div>
                        :
                        null
                }

            </div >
        )
    }
}


export { FetchSubResource }