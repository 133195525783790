import  { useEffect, useState } from 'react';
import { get } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import TaskChecklist from '../../../../Common/TaskItem/TaskCheckList';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { PageWrapper } from '../../../../NCommon/PageWrapper';



const Index = (props) => {

    const [loading,setLoading] = useState(true);
    const [details,setDetails] = useState({});

    let getId = () => {
        return props.match.params.id;
    }

    useEffect(()=>{
        INIT()
    },[])

    let INIT = () => {
        get(`project/task/taskMappingDetails/${getId()}`, (e, r) => {
            if (r) {
                setDetails(r);
                setLoading(false);
            }
        })
    }
    if(loading){
        return <PageLoader />
    }
    
    return (
        <PageWrapper>  
            <NavigationWrapper items={[{name:"Checklist"}]} />
            <div className="title-container-1">
                <TaskChecklist details={details} />
            </div>
        </PageWrapper>
    )     
    
}

export default Index;