import {Switch,Route} from 'react-router-dom';
import Resource from './Category/index';
import SubCategory from './SubCategory';

const Index = () => {

    return(
        <Switch>
            <Route path="/app/others/resource" component={Resource} exact />
            <Route path="/app/others/resource/:id/:type/:subType" component={SubCategory} exact />
        </Switch>
    )
}

export default Index;