import { useRef, useState } from 'react';
import Moment from 'moment';

/* common */
import { post } from '../../../../../Model/Network/Config/Axios';
import { Button } from '../../../../Common/Button';
import { showToast } from '../../../../Common/Toast';
import { FunctionalInput } from '../../../../Common/FloatInput/FunctionalInput';
import { RightModal } from '../../../../Common/Modal/RightModal';
import { SimpleFlex } from '../../../../Common/Flex/SimpleFlex';
import { MultipleProject } from '../../../../Common/Search/MultipleProject';
import { MultipleRoles } from '../../../../Common/Search/MultipleRoles';
import { EmployeeSearch } from '../../../../Common/Search/SearchEmployee';
import { validateEmail } from '../../../../../Utils/ValidateEmail';


const AddEmployee = (props) => {

    const [buttonLoading,setButtonLoading] = useState(false);
    let FirstName = useRef(),Projects=useRef(),Designation=useRef(),LastName=useRef(),Email=useRef(),Mobile=useRef(),Code=useRef(),Manager=useRef(),Dob=useRef(),Role=useRef(null);


    let addEmployee = () => {

        let firstName = FirstName.current.value;
        let lastName =  LastName.current.value;
        let email = Email.current.value;
        let mobile =Mobile.current.value;
        let code = Code.current.value;
        let manager = Manager.current.getValue()?Manager.current.getValue().employeeCode:undefined;
        let designation = Designation.current.value;

        let roles = Role.current.getValue();


        if (!firstName) {
            showToast({
                type: 'error',
                message: 'Please enter the first Name'
            })
            return;
        }

        if (!lastName) {
            showToast({
                type: 'error',
                message: 'Please enter the last name'
            })
            return;
        }

        if (!email || !validateEmail(email)) {
            showToast({
                type: 'error',
                message: "Please enter the email"
            })
            return
        }

        if (!mobile || mobile.length!==10) {
            showToast({
                type: 'error',
                message: "Please enter the valid mobile number"
            })
            return
        }

        if (!code) {
            showToast({
                type: 'error',
                message: 'Please enter the employee code'
            })
            return
        }

        if(!roles.length) {
            showToast({
                type:'error',
                message:"Please select atleast one role"
            })
            return;
        }

        setButtonLoading(true);
        let data = {
            firstName,
            lastName,
            email,
            mobile,
            code,
            manager,
            dob: Moment().valueOf(),
            name: firstName + " " + lastName,
            projects: Projects.current.getValue()?Projects.current.getValue().map(val=>val.value):[],
            designation
        }


        if(roles.length) {
            data.role =  roles[0].value;
            data.roles = roles.map(val=>val.value);
        }


        post(`/employees/add`, data, (e, r) => {
            if (r) {
                showToast({
                    type:'success',
                    message:"Employee Added Successfully"
                })
                props.onClose();
            }
            if (e) {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
            setButtonLoading(false);

        })


    }

        return (
            <RightModal
               show={true}
               title="Add Employee"
               desc="Fill up the details and add employee"
               onClose={props.onClose}
               size="M"
            >
                <SimpleFlex>
                    <FunctionalInput title="First Name" placeholder="Enter First Name" style={{marginRight:20}} type="text" ref={FirstName} />
                    <FunctionalInput title="Last Name" placeholder="Enter Last Name" type="text" ref={LastName} />
                </SimpleFlex>
                <SimpleFlex>
                    <FunctionalInput title="Email Address" placeholder="Enter email address" style={{marginRight:20}} type="text" ref={Email} />
                    <FunctionalInput title="Mobile Number" placeholder="Enter Mobile Number" type="number" ref={Mobile} />
                </SimpleFlex>
                <FunctionalInput title="Designation" placeholder="Enter the Designation" type="text" ref={Designation} />
                <FunctionalInput title="Employee Code" placeholder="Enter Employee Code" type="text" ref={Code}  />
                <EmployeeSearch title="Manager Code (optional)" placeholder="Search Manager Code" type="text" ref={Manager} />
                <MultipleRoles desc="First Role Selected in list will be default Role" title="Select Roles" handleRef={ref=>Role.current=ref} />
                <MultipleProject handleRef={ref=>Projects.current=ref} title="Select Project"/>
                <Button
                    style={{
                        marginTop: 30,
                        height: 42
                    }}
                    text="Add Employee"
                    onClick={addEmployee}
                    loading={buttonLoading}
                />

            </RightModal>
        )
    
}

export default AddEmployee;