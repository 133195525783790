import {useLocation,Switch,Route} from 'react-router-dom';
import { Header } from '../../../NCommon/Header';
import AllSetting from './AllSetting';
import Connector from './Connector';
import Dictionary from './Dictionary';
import Resource from './Resources';
import Tagging from './Tagging';

const Index = () => {
    
    let {pathname} = useLocation();
    return(
        <>
            <Header
                items={[
                    {
                        name:'Setting',
                        link:'/app/setting',
                        active:pathname === `/app/setting`
                    },
                    {
                        name:'Tagging',
                        link:`/app/setting/tag`,
                        active:pathname===`/app/setting/tag`
                    },
                    {
                        name:'Connector',
                        link:'/app/setting/connector',
                        active:pathname==='/app/setting/connector'
                    },
                    {
                        name:'Dictionary',
                        link:'/app/setting/dictionary',
                        active:pathname==='/app/setting/dictionary'
                    },
                    {
                        name:'Resource',
                        link:'/app/setting/resource',
                        active:pathname ===`/app/setting/resource`
                    }
                ]}
            />
            <Switch>
                <Route path="/app/setting/resource" component={Resource}  />
                <Route path="/app/setting/connector" component={Connector} />
                <Route path="/app/setting/dictionary" component={Dictionary} />
                <Route path="/app/setting/tag" component={Tagging} />
                <Route path="/app/setting" component={AllSetting} />
            </Switch>
            
        </>
    )
}

export default Index;