import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { FloatInput } from '../../FloatInput';



class SearchResourceCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            suggestion: [],
        }

    }

    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }

    }

    onSearch = (val) => {
        if (val.length > 0) {
            get(`resource/searchResource`, (e, r) => {
                if (r) { 
                    if(r.resources.length>0) {
                        this.setState({
                            ...this.state,
                            suggestion:r.resources,
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            suggestion:[]
                        })
                    }
                }
            }, {
                q: val
            })

        } else {
            this.setState({
                ...this.state,
                suggestion: []
            })
        }
    }

    setRef= (name) => {
        this.parent.setValue(name);
    }

    setValue = (name) => {
        this.setState({
            name
        })
    }


    getValue = () => {
        return this.state.name ;
    }

    render() {


        return (
            <div style={{
                flex: 1,
                position: 'relative'
            }} >
                <FloatInput placeholder={this.props.placeholder} title={this.props.title} handleRef={ref => this.parent = ref} onChange={val => {
                    this.onSearch(val);
                }} />
                {
                    this.state.suggestion.length > 0 ?
                        <div className="suggestion-modal" style={{top:68}}>
                            {this.state.suggestion.map((item, index) => (
                                <div key={index} className="suggestion-modal-item"  onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        suggestion: [],
                                        name: item
                                    })
                                    this.parent.setValue(`${item.name} (${item.units})`);
                                    if(this.props.onSelect) {
                                        this.props.onSelect(item);
                                    }
                                }}>
                                    {item.name} ({item.units}) <br></br>
                                    {item.resourceType} ({item.subType})
                                </div>
                            ))}

                        </div>
                        :
                        null
                }

            </div >
        )
    }
}


export { SearchResourceCategory }