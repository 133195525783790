import { useEffect, useState } from "react";
import { get } from "../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../Common/PageLoader";
import { NavigationWrapper } from "../../../../NCommon/NavigationWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { Card } from "../../../../NCommon/Ui/CardLayout";


const Index = () => {

    let [apps,setApps] = useState([]);
    let [loading,setLoading] = useState(true);


    useEffect(()=>{
        get(`/marketplace/availableApps`,(e,r)=>{
            if(r) {
                setApps(r.availableApps);
                setLoading(false);
            }
        })
    },[])

    if(loading) {
        return(
            <PageLoader />
        )
    }

    let mapApp = (i,index) => {
        let item = apps[i];
        return(
            <Card height={100} key={index} title={item.name} desc={item.description}>
                <div style={{marginTop:8}}>
                    <p onClick={(e)=>{
                        e.stopPropagation();
                        window.open('https://enterprise.dice.tech/',"_blank")
                    }} style={{color:'var(--theme-main-color)',fontWeight:'600',fontSize:15,cursor:'pointer'}}>View</p>
                </div>
            </Card>
        )
    }


    if(loading) {
        return <PageLoader />
    }

    return(
        <>
            <PageWrapper>
                <NavigationWrapper items={[{name:'All Settings',path:'/app/setting'},{name:'Connector'}]} />
                 <div className="flex flex-wrap">
                     {
                         Object.keys(apps).map(mapApp)
                     }
                 </div>
            </PageWrapper>
        </>
    )
}

export default Index;