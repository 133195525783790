import { get } from "../../Utils/Crypto"
// import * as Crypto from "../../Utils/Crypto";

const initalState = {
    authenticated: false
}

const AuthActions = {
    Login: "Auth_Login",
    Change: "Auth_Office",
    Logout: "Auth_Logout",
    Config: "Auth_Config",
    TwoFactor: "Two_Factor",
    Dictionary:"DICTIONARY",
    Table:'Table_View',
    Count:'Table_Count',
    Graph:'Graph_View'
}


const initialStore = () => {
    const loggedIn = get('loggedin');

    if (loggedIn && loggedIn === 'yes') {
        let tableview = get('table-view')?get('table-view'):'card';
        let tableCount = get('table-count')?get('table-count'):50;
        let graphview = get('graph-view')?get('graph-view'):'gauge';
        return {
            ...initalState,
            authenticated: true,
            name: get('name'),
            isTwoAuthRequired: false,
            tableview,
            tableCount,
            graphview

        }
    } else {
        return {
            ...initalState,
            authenticated: false,
            isTwoAuthRequired: false,
            tableview:'card',
            tableCount:50,
            graphview:'gauge'
        }
    }
}



export const config = (state = initialStore(), action) => {
    let data = action.data;

    switch (action.type) {

        case AuthActions.Login:
            return {
                ...state,
                authenticated: true,
                name: data.name,
                lastLogin: data.login
            }
        case AuthActions.Config:
            return {
                ...initialStore(),
                config: data,
            }
        case AuthActions.TwoFactor:
            return {
                ...initialStore(),
                isTwoAuthRequired: true

            }
        case AuthActions.Dictionary:
            return{
                ...state,
                dictionary:data
            }
        case AuthActions.Table:
            return {
                    ...state,
                    tableview:data
             }
         case AuthActions.Graph:
            return {
                    ...state,
                    graphview:data
             }
        case AuthActions.Count:
            return {
                ...state,
                tableCount:data
            }
        default:
            return state;
    }



}



export { AuthActions };