import {Switch,Route} from 'react-router-dom';
import Setting from '../Custom/Setting';
import Data from './data';

const Index = () => {

    return(
        <Switch>
            <Route path="/app/setting" component={Setting} exact />
            {
                Data.map((val,index)=>{
                    return(
                        <Route
                            key={index}
                            path={val.path}
                            component={val.component}
                            {...val}
                        />
                    )
                })
            }
        </Switch>
    )
}

export default Index;