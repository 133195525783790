import React from 'react';
import { get } from '../../../../Model/Network/Config/Axios';
import { MultiSearchSelect } from '../../MultiSearchSelect';


class MultipleRoles extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            items:[],
            selected:[],
            allItems:[]
        }
    }


    componentDidMount() {
        if(this.props.handleRef) {
            this.props.handleRef(this);
        }
        if(this.props.prefill){
            this.multi.prefill(this.props.prefill)
        }
        get(`/roles/all`,(e,r)=>{
            if(r) {
                this.setState({
                    ...this.state,
                    allItems:r.roles.map(val=>({
                        label:val.name,
                        value:val.id
                    }))
                })
            }
        })
    }


    onChange = (val) => {
        if(val.length>0) {
                    this.setState({
                        ...this.state,
                        items:this.state.allItems.filter(item=>item.label.toLowerCase().includes(val.toLowerCase()))
                    })
           
        } else {
            this.setState({
                ...this.state,
                items:[]
            })
        }
    }


    getValue = () => {
        return this.multi.getValue();
    }

    render() {
        return(
            <div style ={{
                flex:1
            }}>
                <MultiSearchSelect
                    title={this.props.title}
                    items={this.state.items}
                    desc={this.props.desc}
                    onChange={this.onChange}
                    selected={this.state.selected}
                    setItems={()=>{
                        this.setState({
                            ...this.state,
                            items:[]
                        })
                    }}
                    handleRef={ref=>this.multi=ref}
                    onFocus={false}
                />
            </div>
        )
    }
}


export {MultipleRoles}