import { useEffect, useRef, useState } from "react"
import { filter } from "../../Common/Images"
import { Chips } from "../../Common/Item";



const StatusFilter = ({status,onChangeStatusFilter,otherStatus,setOtherStatus,singleSelect}) => {


    const [open,setOpen] = useState(false);
    const [count,setCount] = useState(0);
    let REF = useRef();

    useEffect(()=>{
        window.addEventListener('click',handleClick);
        return ()=>{
            window.removeEventListener('click',handleClick)
        }
    },[])

    let handleClick = (e) => {
        if(!open  && REF.current && !REF.current.contains(e.target)) {
            setOpen(false);
        }
    }

    useEffect(()=>{
        let counter = 0;
        status.forEach(val=>{
            if(val.active) {
                counter +=1;
            }
        })
        if(otherStatus && otherStatus.items) {
            otherStatus.items.forEach(val=>{
                if(val.active) {
                    counter +=1;
                }
            })
        }

        setCount(counter);

    },[status,otherStatus])

    let mapStatus = (item,index) => {
        return (
            <Chips
                key={index}
                val={item}
                onClick={()=>{
                    let items = [...status];

                    if(singleSelect) {
                        items = items.map(val=>{
                            if(item.key === val.key) {
                                return {...val,active:true}
                            }
                            return {...val,active:false}
                        })
                    } else {
                        items = items.map(val=>{
                            if(item.key === val.key) {
                                return {...val,active:!val.active}
                            }
                            return {...val}
                        })
                    }

                    onChangeStatusFilter(items)
                }}
            />
        )
    }

    let mapOther = (item,index) => {
        return (
            <Chips
                key={index}
                val={item}
                onClick={()=>{
                    let items = [...otherStatus.items];
                    items = items.map(val=>{
                        if(item.key === val.key) {
                            return {...val,active:!val.active}
                        }
                        return {...val}
                    })
                    setOtherStatus(items)
                }}
            />
        )
    }

    const onChangeClearStatus = () => {
        let items = [...status];
        items = items.map(val => {
            return { ...val, active: false }
        })
        onChangeStatusFilter(items)
    }
    return (
        <>
            <div ref={REF} className="flex flex-v-center" style={{ height: 'inherit', position: 'relative' }}>
                <div onClick={() => { setOpen(true) }} className="flex flex-v-center" style={{ border: '1px solid var(--border-color)', cursor: 'pointer', fontSize: 16, justifyContent: 'space-between', borderRadius: 4, height: 28, width: 65, padding: '0px 5px' }}>
                    <img src={filter} style={{ height: 12, width: 12 }} />
                    <span style={{ fontWeight: 600, fontSize: 14 }}>Filter</span>
                    {
                        count ?
                            <div className="flex flex-v-center flex-h-center" style={{position:'absolute',right:0,top:-4,height:15,width:15,borderRadius:'50%',color:'#fff',fontWeight:'bold',fontSize:9,background:'var(--theme-main-color)'}}>
                                {count}
                            </div>
                            :null
                    }
                </div>
                {open?
                    <div className="box-wrapper-shadow" style={{position:'absolute',top:40,left:0,width:540,borderRadius:12,border:'1px solid var(--border-color)',padding:20,zIndex:100,background:'#fff'}}>
                        <p style={{fontWeight:'600',fontSize:14}}>By Status :</p>
                        <div style={{display:'flex',flexWrap:'wrap',marginTop:20}}>
                            {status.map(mapStatus)}
                        </div>
                        {
                            otherStatus ?
                                <>
                                    <p style={{fontWeight:'600',fontSize:14}}>By {otherStatus.name} :</p>
                                    <div style={{display:'flex',flexWrap:'wrap',marginTop:20}}>
                                        {otherStatus.items.map(mapOther)}
                                    </div>
                                </>
                                :null
                        }
                    </div>
                    :null
                }
            </div>
            {count > 0 && <span style={{
                height: "28px",
                width: "65px",
                marginLeft: 10,
                borderRadius: 2,
                padding: "6px 18px",
                cursor: 'pointer',
                fontSize: 13,
                fontWeight: '600',
                border: '1px solid #e6ebf0',
                marginTop: 4,

            }} onClick={onChangeClearStatus}>
                Clear
            </span>}
        </>
    )
}

StatusFilter.defaultProps = {
    singleSelect:false
}

export {StatusFilter}