import  { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import Moment from 'moment';
import { get } from '../../../../../Model/Network/Config/Axios';
import { PageLoader } from '../../../../Common/PageLoader';
import { Table } from '../../../../Common/Tables';
import TaskOpen from '../../../../Common/TaskItem/TaskOpen';
import { PageWrapper } from '../../../../NCommon/PageWrapper';
import { NavigationWrapper } from '../../../../NCommon/NavigationWrapper';
import { useDictionaryValue } from '../../../../Hooks/DictionaryHooks';

const Index = () => {

    const [tasksMappings,setTaskMappings] = useState([]);
    const [taskOpen,setTaskOpen] = useState(false);
    const [loading,setLoading] = useState(true);
    let {id} = useParams();
    let getDictionaryValue  = useDictionaryValue();

    useEffect(()=>{
        INIT(id)
    },[id])


    let INIT = (employeeId) => {
        get(`employees/tasksAsApprover/${employeeId}`, (e, r) => {
            if (r) {
                setTaskMappings(r.tasksMappings);
                setLoading(false);
            }
        })
    }



   let mapItem = (item, index) => {
        let details = (
            <div key={index} className="table-item">
                <div style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    height: 60
                }} onClick={() => {
                    if (item.id === taskOpen) {
                        setTaskOpen(false);
                    } else {
                        setTaskOpen(item.id);
                    }
                }}>
                    <div style={{
                        flex: 2,
                        fontWeight: '600',
                        fontSize: 16,
                        color: 'var(--text-color)'
                    }}>
                        {item.wbsItemTaskName}
                    </div>

                    <div style={{
                        flex: 1,
                        textAlign: 'center'
                    }}>
                        {item.responsiblePerson ? item.responsiblePerson.name : '-'}
                    </div>
                    <div style={{
                        flex: 1,
                        textAlign: 'center'
                    }}>
                        {Moment(item.plannedStartDate).format('DD-MM-YYYY')}
                    </div>
                    <div style={{
                        flex: 1,
                        textAlign: 'center'
                    }}>
                        {Moment(item.plannedCompletionDate).format('DD-MM-YYYY')}
                    </div>
                </div>
                <div className="table-right" style={{
                    width: 200
                }}>
                    <p className="hoverable" onClick={() => {
                        setTaskOpen(item.id);
                    }}>View</p>
                </div>
            </div>
        )

        if (item.id === taskOpen) {
            return (
                <TaskOpen key={index} item={item} id={item.id}>
                    {details}
                </TaskOpen>
            )
        }

        return details;
    }




    if (loading) {
            return <PageLoader />
     }
     return (
            <>
                <PageWrapper>
                    <NavigationWrapper items={[{name:'All Employees',path:'/app/employees'},{name:`${getDictionaryValue('task')}`}]} />
                    <div className="title-container-1">
                    <Table
                        data={tasksMappings}
                        mapItem={mapItem}
                        header={[
                            {
                                name: 'Details',
                                flex: 2,

                            },
                            {
                                name: 'Responsible Person',
                                flex: 1,
                                style: {
                                    textAlign: 'center'
                                }
                            },
                            {
                                name: 'Start Date',
                                flex: 1,
                                style: {
                                    textAlign: 'center'
                                }
                            },
                            {
                                name: 'End Date',
                                flex: 1,
                                style: {
                                    textAlign: 'center'
                                }
                            },
                            {
                                name: '',
                                width: 200
                            }
                        ]}
                    />
                    </div>
                </PageWrapper>
            </>
        )
}

export default Index;