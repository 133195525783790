import { SearchSelect } from "../Search/SearchSelect/SearchSelect"


const HourList = (props) => {



    return(
        <>
            <SearchSelect 
                title="Select Hour" 
                onChange={(val)=>{
                    props.select(val)
                }} items={[
                    {value:"00",key:'00'},
                    {value:'01',key:'01'},
                    {value:'02',key:'02'},
                    {value:'03',key:'03'},
                    {value:'05',key:'05'},
                    {value:'06',key:'06'},
                    {value:'07',key:'07'},
                    {value:'08',key:'08'},
                    {value:'09',key:'09'},
                    {value:'10',key:'10'},
                    {value:'11',key:'11'},
                    {value:'12',key:'12'},
                    {value:'13',key:'13'},
                    {value:'14',key:'14'},
                    {value:'15',key:'15'},
                    {value:'16',key:'16'},
                    {value:'17',key:'17'},
                    {value:'18',key:'18'},
                    {value:'19',key:'19'},
                    {value:'20',key:'20'},
                    {value:'21',key:'21'},
                    {value:'22',key:'22'},
                    {value:'23',key:'23'},
                    
                    ]} />
                 
        </>
    )
}

export {HourList}