import {  useRef, useState } from "react";
import { FunctionalInput } from "../../../../Common/FloatInput/FunctionalInput";
import { RightModal } from "../../../../Common/Modal/RightModal";
import { Button } from "../../../../Common/Button";
import { showToast } from "../../../../Common/Toast";
import { post } from "../../../../../Model/Network/Config/Axios";
import { SearchTaskMappingItemN } from "../../../../Common/Search/SearchTaskMappingItemN";



const Index = ({details,projectId,inventoryId,init,onClose}) => {

    let [buttonLoading,setButtonLoading] = useState(false);
    let QTY = useRef(),TASK=useRef();
    
    let update = () => {
        let availableQty = QTY.current.value;
        let task = TASK.current.getValue()

        if(!availableQty) {
            showToast({
                type:'error',
                message:"Please enter the available quantity"
            })
            return;
        }

        if(!task.length) {
            showToast({
                type:'error',
                message:"Please enter the available quantity"
            })
            return;
        }


        let data = {
            qty:+availableQty,
            projectId:+projectId,
            inventoryId:+inventoryId,
            resourceMappingId:+details.resourceMappingId,
            taskMappingId:TASK.current.getValue()[0].value
        }
        setButtonLoading(true);

        post(`project/inventory/reassignPendingReturn`,data,(e,r)=>{
            if(r) {
                setButtonLoading(false)
                showToast({
                    type:'success',
                    message:"Inventory updated successfully"
                })
                init();
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
    }
    return(
        <RightModal
            title="Transfer Resource"
            desc={`Change the details of ${details.name}`}
            show={true}
            onClose={onClose}
        >
            <FunctionalInput ref={QTY} title="Quantity" />
            <SearchTaskMappingItemN 
                projectId={projectId}
                title="Search Task"
                handleRef={ref=>TASK.current=ref}

            />
            <Button text="Transfer" style={{marginTop:30}} onClick={update} loading={buttonLoading}/>
        </RightModal>
    )
}

export default Index;