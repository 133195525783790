import Props from 'prop-types';

const BottomHeader = (props) => {

    let { title, desc } = props;
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: '18px 30px',
            height: 76,
            position: 'relative',
            ...props.style
        }}>
            <div>
                <h6 style={{
                    fontSize: 17,
                    color: 'var(--text-color)'
                }}>{title}</h6>
                <p style={{
                    fontSize: 14,
                    color: 'var(--text-light-color)'
                }}>{desc}</p>
            </div>
            <div>
             {props.children}
            </div>
        </div>
    )
}

BottomHeader.propsTypes = {
    title: Props.string,
    desc: Props.string
}

export { BottomHeader };