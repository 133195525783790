import { useEffect, useState } from "react";
import { useParams} from 'react-router-dom';
import Moment from 'moment';
import { deleteApi, get } from "../../../../../Model/Network/Config/Axios";
import { PageLoader } from "../../../../Common/PageLoader";
import { HeaderWrapper } from "../../../../NCommon/HeaderWrapper";
import { PageWrapper } from "../../../../NCommon/PageWrapper";
import { Table } from "../../../../NCommon/Tables";
import { Card } from "../../../../NCommon/Ui/CardLayout";
import { List } from "../../../../NCommon/Ui/List";
import { TableMenu } from "../../../../NCommon/Tables/TableMenu";
import { showToast } from "../../../../Common/Toast";
import { MainModal } from "../../../../NCommon/Modal";



const Index = () => {

    const [loading,setLoading] = useState(true);
    const [items,setItems] = useState([]);
    const [view,setView] = useState(false);
    const [data,setData] = useState({fieldDataList:[]});
    const [open,setOpen] = useState(false);
    let {id} = useParams();

    useEffect(()=>{
        INIT();
    },[])

    useEffect(()=>{
        if(view) {
            get(`project/export/${id}/getTemplateDetails/${view.id}`,(e,r)=>{
                if(r) {
                    setData(r)
                    setOpen(true);
                }
            })
        }
    },[view])

    let INIT = () => {
        get(`project/export/taskExport/${id}`,(e,r)=>{
            if(r) {
                setItems(r.excels)
            }
        })
        setLoading(false);
    }

    let mapCardLayout =(item,index) => {
        return(
            <Card onDelete={()=>{
                    deleteApi(`project/export/taskExport/${id}/${item.id}`,(e,r)=>{
                        if(r) {
                            showToast({
                                type:'success',
                                message:'Template Delete Successfully'
                            })  
                            INIT();
                        }
                })
            }} onCustom={()=>{setView(item)}} isOpen={true} custom={"View"} key={index} title={item.title} desc={`Updated At : ${Moment(item.updatedAt).format('DD-MM-YYYY')}`} />
        )
    }

    let mapListLayout = (item,index) => {
        return(
            <List key={index}>
                <div className="flex-1">
                    <div className="table-title">{item.title}</div>
                </div>
                <div className="flex-1">
                    <div className="table-text">{Moment(item.updatedAt).format('DD-MM-YYYY')}</div>
                </div>
                <TableMenu items={[
                    {name:'View',onClick:()=>{
                        setView(item)
                    }},
                    {name:'Delete',onClick:()=>{
                    deleteApi(`project/export/taskExport/${id}/${item.id}`,(e,r)=>{
                        if(r) {
                            showToast({
                                type:'success',
                                message:'Template Delete Successfully'
                            })
                            INIT();
                        }
                    })
                }}]} />
            </List>
        )
    }

    let mapItem = (item,index) => {
        return(
            <div key={index} style={{display:'flex',width:400,border:'1px solid var(--border-color)',fontWeight:'600',height:35,borderRadius:4,marginBottom:10}}>
                <div style={{width:175,height:'inherit',borderRight:'1px solid var(--border-color)',display:'flex',alignItems:'center',paddingLeft:10,fontSize:14}}>
                    {item.name}
                </div>
                <div style={{width:175,height:'inherit',display:'flex',alignItems:'center',paddingLeft:10,fontSize:14}}>
                    {item.type}
                </div>
            </div>
        )
    }

    if(loading) {
        return <PageLoader />
    }

    return(
        <>
            <HeaderWrapper />
            <PageWrapper>
                <Table
                    mapCardLayout={mapCardLayout}
                    mapListLayout={mapListLayout}
                    DATA={items}
                    headers={
                        [
                            {
                                name:'Details',
                                flex:1
                            },
                            {
                                name:'Updated At',
                                flex:1
                            },
                            {
                                name:'actions',
                                width:50
                            }
                        ]
                    }
                />
            </PageWrapper>
            <MainModal
                title={data.title}
                open={open}
                close={()=>{
                    setData({fieldDataList:[]});
                    setOpen(false)
                }}
            >
                {data.fieldDataList.map(mapItem)}
            </MainModal>
        </>
    )
}

export default Index;